<!--
  PACKAGE_NAME : src\pages\esp\user\login.vue
  FILE_NAME : login
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 유저 로그인
-->
<template>
  <div ref="amorWrapper" class="amor_wrapper">
    <div id="viewport" class="viewport">
      <div class="visual-left">
        <img
          v-if="!bannerLoading && getBannerImg"
          :src="getBannerImg"
          @error="setBannerErrorHandled"
          class="visual-bg"
          alt="login banner image"
        />
        <div class="visual-footer" :style="{ color: theme.cpRightFontColor }">{{ theme.cpRightText }}</div>
      </div>
      <div class="login-wrap">
        <div ref="loginGroup" class="login-group">
          <div ref="loginBoard" class="login-board fade-in-up">
            <header class="login-header fade-in-up">
              <h1 class="login-text">{{ getI18n('COMPONENTS.LOGIN', '로그인') }}</h1>
            </header>
            <div class="login-cont fade-in-up">
              <form class="login-form">
                <div class="input id id-box fade-in-up">
                  <label for="ecsloginId">{{ getI18n('COMPONENTS.LOGIN_ID', '아이디') }}</label>
                  <input
                    type="text"
                    :class="errMsg.loginId ? 'on' : ''"
                    :placeholder="getI18n('COMPONENTS.LOGIN_ID', '아이디')"
                    :readonly="!isIdFocused"
                    value=""
                    autocomplete="inputLoginId"
                    v-model="loginId"
                    ref="inputLoginId"
                    @keyup="handleIdKeyup"
                    @focus="isIdFocused = true"
                    @blur="isIdFocused = false"
                  />
                  <i class="login-bak-id"></i>
                </div>
                <div class="login-password-infobox on fade-in-up">
                  <div :class="errMsg.loginId ? 'id-info-txt on' : 'id-info-txt'">{{ errMsg.loginId }}</div>
                </div>
                <div class="input pwd pwd-box fade-in-up">
                  <label for="ecsloginPwd">{{ getI18n('COMPONENTS.PASSWORD', '패스워드') }}</label>
                  <input
                    :class="errMsg.password ? 'on' : ''"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    value=""
                    :placeholder="getI18n('COMPONENTS.PASSWORD', '패스워드')"
                    ref="inputPassword"
                    autocomplete="inputPassword"
                    @keyup="handlePwdKeyup"
                    v-model="password"
                    :readonly="!isPwdFocused"
                    @focus="isPwdFocused = true"
                    @blur="isPwdFocused = false"
                  />
                  <i class="login-bak-pw"></i>
                  <button v-show="password.length" type="button" class="hide-password-button" @click="handlePasswordToggleClick">
                    <img v-if="showPassword" class="inline-block" src="@/assets/images/eye.png" alt="패스워드 표시" />
                    <img v-else class="inline-block" src="@/assets/images/eye-off.png" alt="패스워드 숨기기" />
                  </button>
                </div>
                <div class="login-password-infobox fade-in-up">
                  <div :class="errMsg.password ? 'pwd-info-txt on' : 'pwd-info-txt'">{{ errMsg.password }}</div>
                </div>

                <div class="check-type ck-box fade-in-up">
                  <dx-check-box v-model="isSaveId" id="saveId" />
                  <label for="saveId">{{ getI18n('COMMON.WORD.SAVE_ID', '아이디 저장') }}</label>
                </div>

                <div class="pw-admin-btn-box fade-in-up">
                  <button type="button" class="btn-login" @click="loginProcess">
                    {{ getI18n('COMPONENTS.LOGIN', '로그인') }}
                  </button>
                </div>
              </form>
            </div>

            <footer class="login-footer fade-in-up">
              <div class="msg-error-box fade-in-up" v-if="errMsg.result">
                {{ errMsg.result }}
              </div>
              <div class="msg-box fade-in-up" v-else>
                {{ getI18n('COMMON.MESSAGE.FORGOT_PASSWORD', '패스워드를 분실한 경우에는 관리자에게 문의하세요.') }}
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { isSuccess, encryptPassword } from '@/utils/common-lib';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { ActionTypes } from '@/store/types';
  import { isInputValidByByteLength } from '../../../utils/common-lib';

  export default {
    components: { DxCheckBox },
    data() {
      return {
        errorMessages: {
          loginId: this.$_lang('COMMON.MESSAGE.ENTER_ID', { defaultValue: '아이디를 입력해 주세요.' }),
          password: this.$_lang('COMMON.MESSAGE.ENTER_PASSWORD', { defaultValue: '패스워드를 입력해 주세요.' }),
        },
        errMsg: {
          loginId: null,
          password: null,
          result: null,
        },
        isIdFocused: false, // 아이디 입력창 포커스 여부
        isPwdFocused: false, // 패스워드 입력창 포커스 여부
        showPassword: false, // 패스워드 표시 여부
        isSaveId: false,
        loginId: '',
        password: '',
        theme: {
          cpRightText: this.$_theme.copyright,
          cpRightFontColor: this.$_theme.copyrightColor,
        },
        bannerLoading: true, // 배너 이미지 로딩 여부
        bannerImage: null, // 배너 이미지
        bannerErrorHandled: false, // 배너 이미지 에러 핸들링 여부
      };
    },
    computed: {
      /**
       * 배너 이미지 가져오기
       * @return {string|string|string}
       */
      getBannerImg() {
        // FIXME : 배너 이미지는 스토어 초기화 이전에 호출되므로 초기데이터 호출을 하는 API 별도 구성 필요.
        const bannerImg = this.bannerImage ? this.bannerImage : this.$store.getters.getThemeSetting?.bannerImg;
        return bannerImg ? this.$_getAttachFileURL(null, bannerImg) : '@/assets/images/login/mask_group.png';
      },
    },
    methods: {
      /**
       * @description 다국어 메시지 반환
       * @param key {string}
       * @param defaultValue {string}
       * @param params {Object}
       * @return {string}
       */
      getI18n(key, defaultValue, params) {
        return this.$_lang(key, { defaultValue: defaultValue, ...params });
      },
      /**
       * @description 아이디 입력 키 이벤트
       * @param e
       */
      handleIdKeyup(e) {
        this.handleKeyup(e, 'loginId', this.$refs.inputPassword.focus.bind(this.$refs.inputPassword));
      },
      /**
       * @description 패스워드 입력 키 이벤트
       * @param e
       */
      handlePwdKeyup(e) {
        this.handleKeyup(e, 'password', this.loginProcess.bind(this));
      },
      /**
       * @description 키 입력 이벤트
       * @param e
       * @param field
       * @param callback
       */
      handleKeyup(e, field, callback) {
        if (this[field].trim() === '') {
          this.errMsg[field] = this.errorMessages[field];
        } else {
          this.errMsg[field] = null;
          if (e.key === 'Enter') {
            callback();
          }
        }
      },
      /**
       * @description 입력값 유효성 체크
       * @param field
       * @return {boolean}
       */
      validateInput(field) {
        if (!this[field] || this[field].trim() === '') {
          this[field] = '';
          this.errMsg[field] = this.errorMessages[field];
          field === 'loginId' ? this.$refs.inputLoginId.focus() : this.$refs.inputPassword.focus();
          return false;
        }
        return true;
      },
      /**
       * @description 입력값 바이트 길이 유효성 체크
       * @param field
       * @param maxByte
       * @return {boolean}
       */
      validateStringByte(field, maxByte) {
        if (!isInputValidByByteLength(this[field], maxByte)) {
          this[field] = '';
          this.errMsg[field] = this.$_lang('COMMON.MESSAGE.ENTER_BYTE_CHECK', {
            maxBytes: maxByte,
            defaultValue: `입력한 문자열은 ${maxByte} 바이트까지 입력 가능합니다.`,
          });
          field === 'loginId' ? this.$refs.inputLoginId.focus() : this.$refs.inputPassword.focus();
          return false;
        }
        return true;
      },
      /**
       * @description 로그인 Payload 생성
       * @param loginId
       * @param password
       * @return {{loginId: *, loginPwd: *}}
       */
      createLoginPayload(loginId, password) {
        return {
          loginId: loginId,
          loginPwd: encryptPassword(loginId, password, this.$store.getters.getEncryptionType),
        };
      },
      /**
       * 로그인 에러 처리
       * @param error
       */
      handleLoginError(error) {
        this.$log.debug('handleLoginError', error);
        this.errMsg.result = 'API 서버 오류, 관리자에게 문의해 주세요.';
      },
      /**
       * 로그인 처리
       * @return {Promise<void>}
       */
      async loginProcess() {
        const loginId = this.loginId.trim();
        const password = this.password.trim();

        // 입력값 바이트 길이 유효성 체크
        if (!this.validateStringByte('loginId', 32) || !this.validateStringByte('password', 256)) {
          return;
        }

        // 입력값 유효성 체크
        if (!this.validateInput('loginId') || !this.validateInput('password')) {
          return;
        }

        // 아이디 저장 여부 체크
        if (this.isSaveId) {
          localStorage.setItem('loginId', loginId);
          localStorage.setItem('isSaveId', 'Y');
        } else {
          localStorage.removeItem('loginId');
          localStorage.removeItem('isSaveId');
        }

        this.errMsg.result = null;
        const payload = this.createLoginPayload(loginId, password);

        try {
          const res = await this.$store.dispatch(ActionTypes.LOGIN, payload);
          if (isSuccess(res)) {
            await this.$router.push({ path: '/' });
          } else {
            this.errMsg.result = res.data.header.resMsg;
          }
        } catch (error) {
          this.handleLoginError(error);
        }
      },
      /**
       * 배너 이미지 에러 핸들러
       * @param e
       */
      setBannerErrorHandled(e) {
        if (!this.bannerErrorHandled) {
          e.target.src = require('@/assets/images/login/mask_group.png');
          this.bannerErrorHandled = true;
        }
      },
      /**
       * @description 패스워드 토글 클릭 이벤트
       */
      handlePasswordToggleClick() {
        this.showPassword = !this.showPassword;
      },
    },
    async mounted() {
      // 테마 설정 초기화
      const uiThemeData = this.$store.getters.getThemeSetting;
      if (uiThemeData) {
        this.theme.cpRightText = uiThemeData.cpRightText;
        this.theme.cpRightFontColor = uiThemeData.cpRightFontColor;
      }

      // 배너 이미지 오류 처리
      if (!this.bannerImage) {
        this.bannerLoading = '@/assets/images/login/mask_group.png';
      }
      this.bannerLoading = false;

      this.isSaveId = localStorage.getItem('isSaveId') === 'Y';
      // 아이디 저장 체크박스 여부에 따라 포커스 이동
      if (this.isSaveId) {
        this.loginId = localStorage.getItem('loginId') || '';
        this.$refs.inputPassword.focus();
      } else {
        this.$refs.inputLoginId.focus();
      }
    },
  };
</script>
<style scoped src="@/assets/css/login.css"></style>
