import DateColumnReport from '@/pages/report/wizard-report/date-column-report';
import DefaultReport from '@/pages/report/wizard-report/default-report-view';

export default function DataReportRoutes(commonComponents) {
  return [
    {
      path: '/data-report/:menuId',
      name: 'DefaultReport',
      components: { default: DefaultReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/date-column-report/:menuId',
      name: 'DateColumnReport',
      components: { default: DateColumnReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
