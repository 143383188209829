import Login from '@/pages/esp/user/login.vue';
import PasswordChange from '@/pages/esp/user/password-change.vue';
import UserManagementList from '@/pages/esp/user/management/list.vue';
import UserManagementConfig from '@/pages/esp/user/management/config.vue';

export default function UserRoutes(commonComponents) {
  return [
    {
      path: '/login',
      name: 'Login',
      components: { singlePage: Login },
      meta: { requiresAuth: false, noTrack: true },
    },
    {
      path: '/password-change',
      name: 'PasswordChange',
      components: { singlePage: PasswordChange },
      meta: { requiresAuth: true, noTrack: true },
    },
    {
      path: '/esp/user/management/list',
      name: 'UserManagementList',
      components: { default: UserManagementList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/user/management/config',
      name: 'UserManagementConfig',
      components: { default: UserManagementConfig, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
  ];
}
