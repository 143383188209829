<!--
  PACKAGE_NAME : src/pages/ewm/hr/agent/appointment/popup
  FILE_NAME : modal-join-agent.vue
  AUTHOR : devyoon91
  DATE : 2024-07-23
  DESCRIPTION : 신규입사 등록 팝업
-->
<template>
  <div class="container">
    <DxPopup
      :ref="joinAgentPopup.ref"
      :title="joinAgentPopup.title"
      :visible="isOpen"
      :show-title="true"
      :min-width="joinAgentPopup.minWidth"
      :width="joinAgentPopup.width"
      :min-height="joinAgentPopup.minHeight"
      :height="joinAgentPopup.height"
      :resize-enabled="false"
      :drag-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      @hiding="closeModal"
    >
      <template #content>
        <div>
          <table class="table_form line-bin">
            <tbody>
              <tr>
                <th scope="row">
                  <label for="label5">소속</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.deptList"
                    v-model="formData.deptCd"
                    display-expr="pathNmFull"
                    value-expr="codeId"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.deptValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">상담원ID</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxTextBox v-model="formData.agtid" :width="200" styling-mode="outlined">
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.agtIdValidateMessage" />
                    </DxValidator>
                  </DxTextBox>
                </td>
                <th scope="row">
                  <label for="label5">상담원명</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxTextBox v-model="formData.agtNm" :width="200" styling-mode="outlined">
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.agtNmValidateMessage" />
                    </DxValidator>
                  </DxTextBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">직급</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.jikgupList"
                    v-model="formData.jikgupCd"
                    display-expr="pathNmFull"
                    value-expr="codeId"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.jikgupValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
                <th scope="row">
                  <label for="label5">직책</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.jikweeList"
                    v-model="formData.jikweeCd"
                    display-expr="pathNmFull"
                    value-expr="codeId"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.jikweeValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">업무 그룹</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.workgroupList"
                    v-model="formData.workgroupCd"
                    display-expr="pathNmFull"
                    value-expr="codeId"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.workgroupValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
                <th scope="row">
                  <label for="label5">계정 권한</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.authIdList"
                    v-model="formData.authId"
                    display-expr="authNm"
                    value-expr="id"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.authIdValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">입사일</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxDateBox
                    v-model="formData.baseDt"
                    placeholder="yyyy.MM.dd"
                    styling-mode="outlined"
                    width="120"
                    type="date"
                    dateSerializationFormat="yyyy-MM-dd"
                    display-format="yyyy.MM.dd"
                    :invalidDateMessage="validationRoles.invalidDateMessage"
                    :disabled="false"
                    :hint="validationRoles.dateBoxHint"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.dateBoxValidateMessage" />
                    </DxValidator>
                  </DxDateBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">내선 번호</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxTextBox v-model="formData.agtdn" :width="200" styling-mode="outlined">
                    <DxValidator>
                      <!-- // TODO : EWM 신규 입사자 내선 번호 필수값이 아니므로 일단 주석처리 -->
                      <!--                      <DxRequiredRule :message="validationRoles.agtdnValidateMessage" />-->
                      <DxNumericRule :message="validationRoles.agtdnNumericValidateMessage" />
                    </DxValidator>
                  </DxTextBox>
                </td>
                <th scope="row">
                  <label for="label5">CTI 연동 여부</label>
                </th>
                <td colspan="1" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.espCtiUseFlList"
                    v-model="formData.espCtiUseFl"
                    display-expr="label"
                    value-expr="value"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.espCtiUseFlValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">로그인 연동 여부</label>
                </th>
                <td colspan="2" class="clearfix">
                  <DxSelectBox
                    :items="selectBox.espLoginFlList"
                    v-model="formData.espLoginFl"
                    display-expr="label"
                    value-expr="value"
                    styling-mode="outlined"
                    width="200"
                  >
                    <DxValidator>
                      <DxRequiredRule :message="validationRoles.espLoginFlValidateMessage" />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="page-sub-box mt-10">
            <div class="bottom-btn-wrap text-center">
              <DxButton
                text="등록"
                :width="120"
                :height="40"
                class="default filled txt_S medium"
                :use-submit-behavior="true"
                @click="saveModal"
              />
              <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="closeModal" />
            </div>
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import { DxPopup } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/utils/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxNumericRule } from 'devextreme-vue/form';

  export default {
    components: { DxButton, DxValidator, DxRequiredRule, DxNumericRule, DxPopup, DxTextBox, DxSelectBox, DxDateBox },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        codeMap: {},
        workDt: new Date(),
        formData: {
          agtid: '',
          agtNm: '',
          agtdn: '',
          baseDt: '',
          deptCd: '',
          jikgupCd: '',
          jikweeCd: '',
          workgroupCd: '',
          authId: '',
          espCtiUseFl: '',
          espLoginFl: '',
        },
        joinAgentPopup: {
          ref: 'joinAgentPopup',
          title: '상담원 등록',
          minWidth: 800,
          width: 800,
          minHeight: 500,
          height: 550,
        },
        validationRoles: {
          agtIdValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_AGENT_ID', {
            defaultValue: '상담원ID를 입력하세요.',
          }),
          agtNmValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_AGENT_NM', {
            defaultValue: '상담원명을 입력하세요.',
          }),
          agtdnValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_AGENT_DN', {
            defaultValue: '내선번호를 입력하세요.',
          }),
          deptValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_DEPT', {
            defaultValue: '부서를 선택하세요.',
          }),
          jikgupValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_JIKGUP', {
            defaultValue: '직급을 선택하세요.',
          }),
          jikweeValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_JIKWEE', {
            defaultValue: '직책을 선택하세요.',
          }),
          authIdValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_AUTH_ID', {
            defaultValue: '계정 권한을 선택하세요.',
          }),
          workgroupValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_WORKGROUP', {
            defaultValue: '업무 그룹을 선택하세요.',
          }),
          invalidDateMessage: this.$_lang('INVALID_DATE_FORMAT', {
            defaultValue: '날짜 형식이 올바르지 않습니다.',
          }),
          dateBoxHint: this.$_lang('REQUIRED_DATE', {
            defaultValue: '날짜를 선택하세요.',
          }),
          dateBoxValidateMessage: this.$_lang('REQUIRED_DATE', {
            defaultValue: '날짜를 선택하세요.',
          }),
          espCtiUseFlValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_USED_CTI_FL', {
            defaultValue: 'CTI 연동 여부를 선택하세요.',
          }),
          espLoginFlValidateMessage: this.$_lang('EWM.MESSAGE.REQUIRED_LOGIN_FL', {
            defaultValue: '로그인 연동 여부를 선택하세요.',
          }),
          agtdnNumericValidateMessage: this.$_lang('NUMBER_ENTER', {
            defaultValue: '숫자만 입력하세요.',
          }),
        },
        selectBox: {
          deptList: [], // 부서 리스트
          jikgupList: [], // 직급 리스트
          jikweeList: [], // 직책 리스트
          workgroupList: [], // 업무 그룹 리스트
          authIdList: [], // 계정 권한 리스트
          espLoginFlList: this.$_enums.common.stringUsedFlag.values, // 로그인 여부 리스트
          espCtiUseFlList: this.$_enums.common.stringUsedFlag.values, // CTI 연동 여부 리스트
        },
      };
    },
    watch: {
      isOpen: {
        handler: function (newVal) {
          // 모달 팝업이 열릴 때 초기화
          if (newVal) {
            this.initData();
          }
        },
        deep: true,
      },
    },
    computed: {},
    methods: {
      /**
       * @description 모달 팝업 닫기
       */
      closeModal() {
        this.$emit('closeModal');
      },
      /**
       * @description 모달 팝업 저장
       */
      async saveModal(e) {
        if (!e.validationGroup.validate().isValid) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
          const payLoad = {
            actionName: 'EWM_PERS_APPT_SAVE',
            data: {
              agtid: this.formData.agtid,
              agtNm: this.formData.agtNm,
              agtdn: this.formData.agtdn,
              paCategoryCd: this.$_enums.ewm.personnelAppointmentCategory.NEW_HIRE.value,
              baseDt: this.formData.baseDt,
              afterAgentInfo: {
                deptCd: this.formData.deptCd,
                jikgupCd: this.formData.jikgupCd,
                jikweeCd: this.formData.jikweeCd,
                workgroupCd: this.formData.workgroupCd,
                authId: this.formData.authId,
              },
              espLoginFl: this.formData.espLoginFl,
              espCtiUseFl: this.formData.espCtiUseFl,
            },
          };

          this.CALL_EWM_API(payLoad).then(res => {
            if (isSuccess(res)) {
              this.$_Msg(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
              this.$emit('saveModal');
            } else {
              this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          });
        }
      },

      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept,root_ewm_hr_jikgup,root_ewm_hr_jikwee,root_ewm_hr_workgroup');
      },
      /** @description : 권한 데이터 전체 조회 **/
      getAuthIdList() {
        const payload = {
          actionName: 'AUTH_LIST',
          loading: false,
        };

        return this.CALL_API(payload)
          .then(res => {
            if (isSuccess(res)) {
              this.selectBox.authIdList = res.data.data;
            }
          })
          .catch(() => {
            this.$_Toast('ERROR_AUTH_DATA_FETCH', { defaultValue: '권한 데이터 조회 오류.' });
          });
      },
      /**
       * @description : 데이터 초기화
       */
      initData() {
        this.formData = {
          agtid: '',
          agtNm: '',
          agtdn: '',
          baseDt: '',
          deptCd: '',
          jikgupCd: '',
          jikweeCd: '',
          workgroupCd: '',
          authId: '',
          espCtiUseFl: '',
          useFl: '',
          espLoginFl: '',
        };
      },
    },
    created() {},
    mounted() {
      this.getAuthIdList();
      this.initCodeMap().then(() => {
        this.selectBox.deptList = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_dept']);
        this.selectBox.jikgupList = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_jikgup']);
        this.selectBox.jikweeList = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_jikwee']);
        this.selectBox.workgroupList = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_workgroup']);
      });
    },
  };
</script>

<style lang="scss" scoped></style>
