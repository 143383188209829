import ExamGroupReport from '@/pages/ewm/exam/exam-report/group-report/index.vue';
import ExamQuestionReport from '@/pages/ewm/exam/exam-report/question-report/index.vue';
import ExamScoreReport from '@/pages/ewm/exam/exam-report/score-report/index.vue';
import ExamScheduleConfig from '@/pages/ewm/exam/implementation/exam-schedule/config.vue';
import ExamScheduleExamSheet from '@/pages/ewm/exam/implementation/exam-schedule/exam-sheet.vue';
import ExamScheduleList from '@/pages/ewm/exam/implementation/exam-schedule/list.vue';
import ExamStatus from '@/pages/ewm/exam/implementation/exam-status/index.vue';
import ExamStatusSheetManager from '@/pages/ewm/exam/implementation/exam-status/sheet-manager.vue';
import ExamStatusSheetTarget from '@/pages/ewm/exam/implementation/exam-status/sheet-target.vue';
import ExamQuestion from '@/pages/ewm/exam/question/index.vue';
import ExamSheetSettingConfig from '@/pages/ewm/exam/setting/sheet/config.vue';
import ExamSheetSettingList from '@/pages/ewm/exam/setting/sheet/list.vue';
import ExamSheetSettingPreview from '@/pages/ewm/exam/setting/sheet/preview.vue';

export default function ExamRoutes(commonComponents) {
  return [
    // Exam Report
    {
      path: '/ewm/exam/exam-report/group-report',
      name: 'ExamGroupReport',
      components: { default: ExamGroupReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/exam-report/question-report',
      name: 'ExamQuestionReport',
      components: { default: ExamQuestionReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/exam-report/score-report',
      name: 'ExamScoreReport',
      components: { default: ExamScoreReport, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Exam Implementation -> Exam Schedule
    {
      path: '/ewm/exam/implementation/exam-schedule/config',
      name: 'ExamScheduleConfig',
      components: { default: ExamScheduleConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-schedule/exam-sheet',
      name: 'ExamScheduleExamSheet',
      components: { default: ExamScheduleExamSheet, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-schedule/list',
      name: 'ExamScheduleList',
      components: { default: ExamScheduleList, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Exam Implementation -> Exam Status
    {
      path: '/ewm/exam/implementation/exam-status',
      name: 'ExamStatus',
      components: { default: ExamStatus, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-status/sheet-manager',
      name: 'ExamStatusSheetManager',
      components: { default: ExamStatusSheetManager, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-status/sheet-target',
      name: 'ExamStatusSheetTarget',
      components: { default: ExamStatusSheetTarget, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Exam Question
    {
      path: '/ewm/exam/question',
      name: 'ExamQuestion',
      components: { default: ExamQuestion, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Exam Setting -> Sheet
    {
      path: '/ewm/exam/setting/sheet/config',
      name: 'ExamSheetSettingConfig',
      components: { default: ExamSheetSettingConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/setting/sheet/list',
      name: 'ExamSheetSettingList',
      components: { default: ExamSheetSettingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/setting/sheet/preview',
      name: 'ExamSheetSettingPreview',
      components: { default: ExamSheetSettingPreview, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}