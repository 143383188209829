<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <Search
      codeKey="search_task_type"
      :searchObj="searchType.obj"
      :useKeywordType="true"
      @change-search-type="$_changeSearchType"
      @onSearchClick="$_searchData($event, 'search_task_type')"
    >
      <template v-slot:before>
        <DxSelectBox
          v-model="searchType.customTypes.taskId"
          placeholder="Task 선택"
          :items="getTaskList"
          display-expr="taskNm"
          value-expr="id"
          :styling-mode="stylingMode"
          :width="120"
          :height="30"
          @value-changed="$_changeSearchCustomType('taskId', $event)"
        />
      </template>
    </Search>
    <esp-dx-data-grid :data-grid="dataGrid" ref="subTaskGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        tasks: [],
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            taskId: null,
          },
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          refName: 'subTaskGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '650', // 주석처리시 100%
          apiActionNm: {
            update: 'EUC_SUB_TASK_UPDATE',
            loading: true,
          },
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 100,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '작업명',
              dataField: 'taskId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.tasks,
                    displayExpr: 'taskNm',
                    valueExpr: 'id',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.subTaskGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
            },
            {
              caption: '처리방법',
              dataField: 'approach',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '소스',
              dataField: 'sourceFd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '타겟',
              dataField: 'targetFd',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
            },
            {
              caption: '설명',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '순서',
              dataField: 'subOrd',
              width: 90,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    onValueChanged: () => {
                      this.onChangedViewCd(options.data);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
            {
              caption: '수정자',
              dataField: 'editId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '수정일',
              dataField: 'editDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      getTaskList() {
        return [{ id: null, taskNm: '전체' }, ...this.tasks];
      },
    },
    methods: {
      async onDeleteData() {
        const selectedRowsData = this.$refs.subTaskGrid.selectedRowsData;
        if (selectedRowsData?.length) {
          if (
            await this.$_Confirm(
              this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }),
            )
          ) {
            const ids = selectedRowsData.map(row => {
              return { id: row.id };
            });

            const payload = {
              actionName: 'EUC_SUB_TASK_DELETE',
              data: { data: ids },
              loading: true,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              this.$_Toast(this.$_lang('CMN_SUC_DELETE'), { icon: 'success' });
              this.$refs.subTaskGrid.refreshData();
            }
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }), {
            icon: 'warning',
          });
        }
      },
      async onChangedViewCd(data) {
        let viewFl;
        if (data.viewFl === 'Y') {
          //사용이면
          viewFl = 'N'; //미사용으로 스위치
        } else {
          //미사용이면
          viewFl = 'Y'; //사용으로 스위치
        }

        if (data.id) {
          const payload = {
            actionName: 'EUC_SUB_TASK_UPDATE',
            data: [
              {
                id: data.id,
                viewFl,
              },
            ],
            loading: true,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_UPDATE'), { icon: 'success' });
            this.$refs.subTaskGrid.getInstance.refresh();
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR'), { icon: 'error' });
          }
        } else {
          data.viewFl = viewFl;
        }
      },
      async selectDataList(sort = '+taskId,+subOrd') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'EUC_SUB_TASK_LIST_ALL',
              data: params,
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_EUC_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.editDt) {
          return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },

      async selectTasks() {
        const params = {
          sort: '+taskOrd',
        };

        const payload = {
          actionName: 'EUC_TASK_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.tasks = res.data.data;
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.selectTasks();
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>