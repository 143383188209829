<template>
	<transition>
		<div class="flex space-x-6">
			<div class="w-1/2">
				<div class="sub_title_txt my-4 flex">
					<h2>문항내용</h2>
				</div>
				<div v-html="contentData.questionNm"></div>
			</div>
			<div>
				<div class="sub_title_txt my-4 flex">
					<h2>문항보기</h2>
				</div>
				<DxDataGrid
					class="grid-box"
					ref="examReportQuestion"
					:allow-column-reordering="true"
					:data-source="examReportQuestion.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_lang('CMN_NO_DATA')"
					:selected-row-keys="examReportQuestion.selectedRowKeys"
					@selection-changed="onGridSelectionChanged"
					width="500"
					height="250"
				>
					<DxFilterRow :visible="true" />
					<DxSelection mode="single" />

					<DxColumn
						caption="보기순서"
						data-field="questionNumber"
						alignment="center"
						:allowEditing="false"
						sortOrder="asc"
						width="110"
					/>
					<DxColumn
						caption="문항 보기 정보"
						data-field="description"
						alignment="center"
						:allowEditing="false"
						:allow-sorting="false"
					/>
					<DxColumn caption="배점" data-field="score" alignment="center" width="100" />
					<DxColumn caption="응답 인원" data-field="answerCnt" alignment="center" width="100" />
				</DxDataGrid>
				<div class="sub_title_txt my-4 flex">
					<h2>응답인원</h2>
				</div>
				<DxDataGrid
					class="grid-box"
					ref="examReportQuestionDetail"
					:allow-column-reordering="true"
					:data-source="examReportQuestionDetail.dataSource"
					:allow-column-resizing="true"
					:column-resizing-mode="'nextColumn'"
					:show-borders="false"
					:show-column-headers="true"
					:show-column-lines="true"
					:show-row-lines="true"
					:row-alternation-enabled="false"
					:hover-state-enabled="true"
					:word-wrap-enabled="true"
					:no-data-text="this.$_lang('CMN_NO_DATA')"
					width="500"
					height="300"
				>
					<DxFilterRow :visible="true" />
					<DxSelection mode="single" />

					<DxColumn caption="부서정보" alignment="center" data-field="deptNmPath" />
					<DxColumn caption="상담사" alignment="center" data-field="agtNm" width="100" />
					<DxColumn caption="점수" alignment="center" data-field="score" width="100" />
					<DxColumn caption="입력값" alignment="center" data-field="answer" width="100" />
				</DxDataGrid>
			</div>
		</div>
	</transition>
</template>

<script>
import { DxDataGrid, DxColumn, DxSelection, DxFilterRow, DxLookup, DxLoadPanel } from 'devextreme-vue/data-grid';
import {isSuccess} from "@/utils/common-lib";

export default {
	components: {
		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxLookup,
		DxLoadPanel,
	},
	props: {
		contentData: Object,
	},
	watch: {},
	data() {
		return {
			stylingMode: 'filled',
			examReportQuestion: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			examReportQuestionDetail: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
		};
	},
	computed: {},
	methods: {
		async onGridSelectionChanged(rowData) {
			const payload = {
				actionName: 'EWM_EXAM_REPORT_QUESTION_USERS_LIST',
				data: {
					questionId: rowData.selectedRowsData[0].questionId,
					answer: rowData.selectedRowsData[0].questionNumber,
					scheId: this.contentData.scheId,
				},
				loading: false,
				useErrorPopup: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
        this.examReportQuestionDetail.dataSource = res.data.data;
			}
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			const payload = {
				actionName: 'EWM_EXAM_REPORT_QUESTION_DETAILS',
				path: '/' + this.contentData.scheId + '/question' + '/' + this.contentData.questionId,
				loading: false,
				useErrorPopup: true,
			};
			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
        this.examReportQuestion.dataSource = res.data.data;
			}
		},
	},
	created() {
		this.selectFormData();
	},
	mounted() {},
};
</script>
