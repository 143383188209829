import InfoPushAction from '@/pages/euc/info-push/action';
import InfoPushActionUpdate from '@/pages/euc/info-push/action/update';

export default function InfoPushActionRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/action',
            name: 'InfoPushAction',
            components: { default: InfoPushAction, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/action/update',
            name: 'InfoPushActionUpdate',
            components: { default: InfoPushActionUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}