<template>
  <esp-dx-data-grid
      :data-grid="dataGrid"
      ref="pickupEtcGrid"
      :auto-width="true"
      @init-new-row="onInitNewRow"
      @saving="onSaving"
  ></esp-dx-data-grid>
</template>
<script>
import {DxSelectBox} from 'devextreme-vue/select-box';
import {isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid
  },
  props: {
    option: {
      default: () => {
        return {
          width: '80%',
          height: '85%',
          minWidth: null,
          minHeight: null,
          saveBtnVisible: false,
          cancelBtnVisible: true,
        };
      },
      type: Object,
    },

    deviceInfo: {
      type: Object,
    },
    serverInfo: {
      type: String,
    },
    isOpened: {
      type: Boolean,
    },
    authority: {
      type: String,
    },
    cmList: {
      type: Array,
    },
    partitionList: {
      type: Array,
    },
  },
  watch: {
    async isOpened() {
      if (!this.isOpened) {
        this.refreshGrid();
      }
    },
  },
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        disableTotalCount: true,
        refName: 'pickupEtcGrid',
        keyExpr: 'id',
        showActionButtons: {
          excel: false, // 엑셀 버튼
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                elementAttr: {class: 'btn_XS white light_filled trash'},
                width: 60,
                height: 30,
                onClick: () => {
                  this.onDeletePickupGroup();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '생성',
                elementAttr: {class: 'btn_XS default filled'},
                width: 60,
                height: 30,
                onClick: () => {
                  this.onCreatePickupGroup();
                },
              },
              location: 'after',
            }
          ],
        },
        page: {
          enabled: false,
        },
        columns: [
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            width: 100,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              if (this.cmList.length === 1) {
                options.value = this.cmList[0].cmNm;
              }
              const selectBox = new DxSelectBox({
                propsData: {
                  placeholder: this.$_lang('COMPONENTS.SELECT'),
                  items: this.cmList,
                  displayExpr: 'description',
                  valueExpr: 'cmNm',
                  value: options.value,
                  stylingMode: this.stylingMode,
                  onValueChanged: e => {
                    this.$refs.pickupEtcGrid.getInstance.cellValue(
                        options.rowIndex,
                        options.columnIndex,
                        e.value,
                    );
                  },
                },
              });
              selectBox.$mount();
              container.append(selectBox.$el);
            },
          },
          {
            caption: '그룹명',
            dataField: 'name',
            i18n: 'COMPONENTS.GROUP_NAME',
            width: 150,
            requiredRule: {},
          },
          {
            caption: '그룹번호',
            i18n: 'UC.WORD.GROUP_NUMBER',
            dataField: 'pattern',
            width: 120,
            allowEditing: true,
            validationRules: [
              {
                type: 'custom',
                validationCallback: (e) => {
                  return /^(?=.*\d)[\d#*]*$/.test(e.value);
                },
                message: '당겨받기 패턴은 숫자 및 특수문자 # * 만 사용가능합니다.',
              },
            ],
          },

          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            width: 150,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              const selectBox = new DxSelectBox({
                propsData: {
                  placeholder: this.$_lang('COMPONENTS.SELECT'),
                  items: this.partitionList,
                  displayExpr: 'name',
                  valueExpr: 'name',
                  value: options.value,
                  stylingMode: this.stylingMode,
                  onValueChanged: e => {
                    this.$refs.pickupEtcGrid.getInstance.cellValue(
                        options.rowIndex,
                        options.columnIndex,
                        e.value,
                    );
                  },
                },
              });
              selectBox.$mount();
              container.append(selectBox.$el);
            },
            requiredRule: {},
          },
          {
            caption: '설명',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            requiredRule: {},
          },
        ],
      },
    };
  },
  methods: {
    /**
     * @description 그리드 저장 시 이벤트 (제거하지 마시오)
     * saving 에서 event 를 cancel 하기 때문에 그리드에 저장되지 않아서 정의함
     */
    onSaving() {},
    /**
     * @description 그리드 신규 row 추가 시 이벤트
     */
    onInitNewRow(e) {
      //교환기 정보 하나만 있을 경우 값 픽스
      if (this.cmList.length === 1) {
        e.data.cmNm = this.cmList[0].cmNm;
      }
    },
    async onDeletePickupGroup() {
      const data = this.$refs.pickupEtcGrid.getInstance.getSelectedRowsData();
      if (data.length === 0) {
        await this.$_Msg('삭제할 데이터가 없습니다.');
        return false;
      }

      data.forEach(item => {
        const index = this.$refs.pickupEtcGrid.getInstance.getRowIndexByKey(item.id);
        this.$refs.pickupEtcGrid.getInstance.deleteRow(index);
      });
      this.$refs.pickupEtcGrid.getInstance.saveEditData();
    },
    /**
     * @description 그리드 데이터 초기화
     */
    refreshGrid() {
      this.dataGrid.dataSource = [];
      this.$refs.pickupEtcGrid.getInstance.cancelEditData();
    },
    /**@description  당겨받기 등록 메서드 */
    async onCreatePickupGroup() {
      const data = this.$refs.pickupEtcGrid.getInstance.getItems();
      if (this.$refs.pickupEtcGrid.hasEditData()) {
        await this.$_Msg('수정중인 항목이있습니다. ');
        return;
      }
      if (data.length === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
        return;
      } else if (data.length > 10) {
        this.$_Msg('최대 10개까지 생성가능합니다.');
        return;
      }

      let flag = true;
      let prevCm = '';
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          prevCm = data[i].cmNm;
          continue;
        }
        if (prevCm !== data[i].cmNm) {
          flag = false;
        }
        prevCm = data[i].cmNm;
      }

      if (!flag) {
        this.$_Msg(this.$_lang('UC.MESSAGE.CHECK_EXCHANGE_TYPE'));
        return;
      }

      const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }
      
      const parseData = data.map(item => {
        return {
          NAME: item.name,
          PATTERN: item.pattern,
          ROUTEPARTITION: item.routepartitionname,
          DESCRIPTION: item.description,
        };
      });

      if (!parseData?.length) {
        this.$_Msg(this.$_lang('UC.MESSAGE.CMN_NO_DATA'));
        return;
      }

      const payload = {
        actionName: 'EUC_PICKUPGROUP_INSERT',
        data: {
          cmNm: prevCm,
          loginId: this.$store.getters.getLoginId,
          list: JSON.stringify(parseData)
        },
        loading: true,
      };
      
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
        this.$emit('closeModal', true);
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
    },
  },
};
</script>