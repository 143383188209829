<!--
  PACKAGE_NAME : src/pages/euc/chineseWall
  FILE_NAME : modal-dept-list.vue
  AUTHOR : jhcho
  DATE : 24. 12. 9.
  DESCRIPTION :
-->
<template>
  <DxTreeList
      id="deptList"
      ref="deptList"
      :data-source="tree.deptList"
      :root-value="-1"
      :expanded-row-keys="tree.expandedRowKeys"
      :selected-row-keys="tree.selectedRowkeys"
      :focused-row-key="tree.focusedRowKey"
      :auto-expand-all="true"
      :show-column-headers="true"
      :show-row-lines="true"
      :show-column-lines="false"
      :show-borders="false"
      :column-auto-width="true"
      key-expr="id"
      parent-id-expr="parentId"
      @selection-changed="selectionChangedData"
      height="calc(100% - 50px)"
  >
    <DxSearchPanel
        placeholder=""
        :visible="true"
        :searchVisibleColumnsOnly="true"
        :width="300"
    />
    <DxSelection :recursive="tree.recursive" :mode="tree.mode"  :allow-select-all="true" />
    <DxScrolling mode="virtual"/>
    <DxColumn
        caption="부서"
        data-field="deptNm"
        alignment="left"
        :visible="true"
    />
  </DxTreeList>
</template>
<script>
import {DxColumn, DxScrolling, DxSearchPanel, DxSelection, DxTreeList} from "devextreme-vue/tree-list";

export default {
  components: {DxSearchPanel, DxSelection, DxTreeList, DxColumn, DxScrolling},
  props: {
    modalData: Object,
    isOpened: Boolean,
  },
  data() {
    return {
      tree: {
        originDeptList: [],
        deptList: [],
        sortList: [],
        expandedRowKeys: [],
        selectedRowkeys: [],
        focusedRowKey: null,
        selectedRowsData: [],
        recursive: true,
        mode: 'multiple', // 'single' or 'multiple'
        selectionMode: 'all', // 'all' or 'excludeRecursive' or 'leavesOnly'
        allowDropInsideItem: false,
        allowReordering: true,
        showDragIcons: true,
        selectedData: null,
      },
    }
  },
  watch: {
    isOpened: {
      handler: function (val) {
        if (val) {
          this.setTreeList();
        }
      },
      immediate: true
    }
  },
  methods: {
    getInstance() {
      return this.$refs.deptList.instance;
    },
    afterLineValidation(deptIds) {
      const target = this.modalData.targetList.find((item) => deptIds.includes(item.deptId));
      const nonTarget = this.modalData.nonTargetList.find((item) => deptIds.includes(item.deptId));
      if(target) {
        this.$_Msg(target.deptNm + '은 이미 대상 부서에 포함되어 있으므로 선택할 수 없습니다.');
        return false;
      } else if(nonTarget) {
        this.$_Msg(nonTarget.deptNm + '은 이미 비대상 부서에 포함되어 있으므로 선택할 수 없습니다.');
        return false;
      } else {
        return true;
      }
    },
    nonTargetValidation(deptIds) {
      const target = this.modalData.targetList.find((item) => deptIds.includes(item.deptId));
      const afterLine = this.modalData.afterLineList.find((item) => deptIds.includes(item.deptId));
      if(target) {
        this.$_Msg(target.deptNm + '은 이미 대상 부서에 포함되어 있으므로 선택할 수 없습니다.');
        return false;
      } else if(afterLine) {
        this.$_Msg(afterLine.deptNm + '은 이미 후속라인 부서에 포함되어 있으므로 선택할 수 없습니다.');
        return false;
      } else {
        return true;
      }

    },
    targetListValidation(deptIds) {
      const nonTarget = this.modalData.nonTargetList.find((item) => deptIds.includes(item.deptId));
      const afterLine = this.modalData.afterLineList.find((item) => deptIds.includes(item.deptId));
      if(nonTarget) {
        this.$_Msg(nonTarget.deptNm + '은 이미 비대상 부서에 포함되어 있으므로 선택할 수 없습니다.');
        return false;
      } else if(afterLine) {
        this.$_Msg(afterLine.deptNm + '은 이미 후속라인 부서에 포함되어 있으므로 선택할 수 없습니다.');
        return false;
      } else {
        return true;
      }
    },
    addSelectedRowData(e) {
      const selectedRowKey = e.currentSelectedRowKeys[0];
      const selectedRowsData = e.component.getSelectedRowsData(this.tree.selectionMode);
      const rows = selectedRowsData.filter((d) => d.deptId === selectedRowKey || d.parentId === selectedRowKey);

      const deptIds = rows.map((item) => item.id);
      let isValidation = true;
      // @DESCRIPTION: 부서 선택시 유효성 체크 우선 제외
      // switch (this.modalData.gbcd) {
      //   case 'TAGET':
      //     isValidation = this.targetListValidation(deptIds);
      //     break;
      //   case 'NONTGT':
      //     isValidation = this.nonTargetValidation(deptIds);
      //     break;
      //   case 'AFLINE':
      //     isValidation = this.afterLineValidation(deptIds);
      //     break;
      // }

      if(!isValidation) {
        this.tree.selectedRowkeys = this.tree.selectedRowkeys.filter((item) => !deptIds.includes(item));
      }
    },
    /** @description: 트리메뉴 변경 이벤트 */
    selectionChangedData(e) {
      if(e.currentSelectedRowKeys?.length) {
        this.addSelectedRowData(e);
      }
    },
    setTreeList() {
      this.tree.selectedRowkeys = [];
      const data = this.modalData.deptList.map(d => {
        const id = d.deptId;
        return { ...d, id };
      });
      this.tree.originDeptList = data;
      this.tree.deptList = data.filter(d => d.viewCd === 1);
      switch (this.modalData.gbcd) {
        case 'TAGET':
          this.modalData.targetList.forEach((item) => this.tree.selectedRowkeys.push(item.deptId));
          break;
        case 'NONTGT':
          this.modalData.nonTargetList.forEach((item) => this.tree.selectedRowkeys.push(item.deptId));
          break;
        case 'AFLINE':
          this.modalData.afterLineList.forEach((item) => this.tree.selectedRowkeys.push(item.deptId));
          break;
      }
    },
    clearSelection() {
      this.tree.selectedRowkeys = [];
      this.tree.selectedRowsData = [];
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>

</style>