<!--
  PACKAGE_NAME : src/pages/cc/ivr/service-templates/modal-svc-tp.vue
  FILE_NAME : modal-svc-tp
  AUTHOR : hmlee
  DATE : 2024-10-21
  DESCRIPTION : 서비스템플릿 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
        <div class="contents-box">
          <div class="conts-row">
            <div class="conts-box">
              <div class="conts-title">서비스 템플릿 명 *</div>
              <div class="conts-input">
                <DxTextBox
                  v-model="formData.svctpNm"
                  :max-length="limitNumberTexts.maxLengths.svctpNm"
                  :styling-mode="stylingMode"
                  class="mar_ri10"
                  :width="350"
                  @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'svctpNm')"
                >
                  <DxValidator>
                    <DxRequiredRule message="서비스 템플릿 명은 필수입니다."/>
                  </DxValidator>
                </DxTextBox>
                {{
                  limitNumberTexts.textLengths.svctpNm ? limitNumberTexts.textLengths.svctpNm : (formData.svctpNm ? formData.svctpNm.length : 0)
                }}/{{
                  limitNumberTexts.maxLengths.svctpNm
                }}자
              </div>
            </div>
          </div>

          <div class="conts-row">
            <div class="conts-box">
              <div class="conts-title">설명</div>
              <div class="conts-input">
                <DxTextArea
                  v-model="formData.description"
                  :max-length="limitNumberTexts.maxLengths.description"
                  :styling-mode="stylingMode"
                  class="mar_ri10 alB"
                  :width="350"
                  :height="85"
                  @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'description')"
                >
                </DxTextArea>
                {{
                  limitNumberTexts.textLengths.description ? limitNumberTexts.textLengths.description : (formData.description ? formData.description.length : 0)
                }}/{{
                  limitNumberTexts.maxLengths.description
                }}자
              </div>
            </div>
          </div>

          <div class="conts-row">
            <div class="conts-box">
              <div class="conts-title">순서</div>
              <div class="conts-input">
                <DxNumberBox
                  v-model="formData.svctpOrd"
                  :min="1"
                  :show-spin-buttons="true"
                  :width="350"
                  :styling-mode="stylingMode"
                />
              </div>
            </div>
          </div>

          <div class="conts-row">
            <div class="conts-box">
              <div class="conts-title">사용여부</div>
              <div class="conts-input">
                <DxSwitch
                  @value-changed="onChangedViewFl($event)"
                  :value="getViewFl"
                  switched-off-text="OFF"
                  switched-on-text="ON"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
        width: '120',
        height: '40',
        useSubmitBehavior: true,
        onClick: this.saveModal,
      }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
        width: '120',
        height: '40',
        onClick: this.closeModal,
      }"
    />
  </DxPopup>
</template>

<script>
import {DxPopup, DxPosition, DxToolbarItem} from 'devextreme-vue/popup';

import {DxTextBox} from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import {DxNumberBox} from 'devextreme-vue/number-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import DxSwitch from "devextreme-vue/switch";
import {DxValidator, DxRequiredRule} from 'devextreme-vue/validator';
import { isSuccess } from "@/utils/common-lib";

export default {
  components: {
    DxPopup, DxPosition, DxToolbarItem,
    DxNumberBox,
    DxTextBox,
    DxTextArea,
    DxSelectBox,
    DxSwitch,
    DxValidator,
    DxRequiredRule,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    svctpData: { // Template Object
      default: null,
      type: Object,
    },
    maxOrd: Number,
  },
  watch: {},
  data() {
    return {
      modal: {
        title: this.$_lang('CC.WORD.SVC_TP_TITLE', { defaultValue: '서비스 템플릿' })
          +' '+this.$_lang('COMPONENTS.SETTING', { defaultValue: '설정' }),
        minWidth: '300',
        width: '600',
        minHeight: '300',
        height: '400',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
      },
      stylingMode: 'outlined',    //[outlined, filled, underlined]
      formData: {             // Add/Update 시 사용할 객체
        svctpNm: '',
        description: '',
        svctpOrd: 0,
        viewFl: 'Y'
      },
      formFormat: {
        svctpNmSize: 30,
        descriptionSize: 200,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          svctpNm: 30,
          description: 200,
        },
      },
    }
  },
  computed: {
    /** @description: 모달 타이틀 출력 여부 */
    showTitle() {
      return !!this.modal.title;
    },
    /** @description: 사용여부 */
    getViewFl() {
      return this.formData.viewFl === 'Y';
    },
  },
  methods: {
    /** @description: 모달 저장 */
    async saveModal(e) {
      if (!e.validationGroup.validate().isValid) {
        return false;
      }

      if( this.svctpData.length > 0 && this.formData.viewFl === 'N') {
        let checkIvrDnisByTemplate = false;
        const payload = {
          actionName: 'CC_IVR_DNIS_LIST',
          data: {params: {svctpId: this.formData.id}},
          useErrorPopup: true,
        }

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length > 0) {
            checkIvrDnisByTemplate = true;
          }
        }

        if (checkIvrDnisByTemplate) {
          return this.$_Toast('IVR Dnis에 할당된 템플릿은 미표시 처리할 수 없습니다.');
        }
      }

      const data = {
        data: [this.formData]
      };

      if (await this.$_Confirm('템플릿 정보를 저장 하시겠습니까?')) {
        // INSERT 외에는 모두 UPDATE API
        let params = {};
        if (this.svctpData.length === 0 && this.maxOrd + 1 !== data.data[0].svctpOrd) {
          params = { isReordFl: true };
        }
        const payload = {
          actionName: 'CC_IVR_SVC_TP_MERGE',
          data: {...data, ...params},
          useErrorPopup: true,
        }

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.$emit('saveModal');
        }
      }
    },
    /** @description: 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },
    onChangedViewFl(e) {
      if (e.value)
        this.formData.viewFl = 'Y'
      else
        this.formData.viewFl = 'N'
    },
  },
  created() {

  },
  mounted() {
    if( Object.keys(this.svctpData).length === 0 ) { //등록
      this.formData.viewFl = 'Y';
      this.formData.svctpOrd = this.maxOrd + 1;
    } else { //수정
      this.formData = { ...this.svctpData };
    }
  },
  beforeDestroy() {
  },
}
</script>

<style scoped>
.contents-box {
  width: 100%;
  padding: 20px 10px;
  background-color: #FFF;
}

.conts-row:not(:last-child) {
  padding-bottom: 20px;
}

.conts-row .conts-box {
  display: inline-block;
}

.conts-row .conts-box > div {
  display: inline-block;
}

.conts-row .conts-box .conts-title {
  width: 120px;
  display: inline-block;
  vertical-align: top;
}

.conts-row .conts-box .conts-input {
  display: -webkit-inline-box;
}
</style>