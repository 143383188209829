<template>
  <div>
    <div class="ewm page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준일</div>
              <DxDateBox
                :edit-enabled="false"
                :styling-mode="stylingMode"
                width="110"
                height="30"
                v-model="searchType.customTypes.startDt"
                type="date"
                display-format="yyyy.MM.dd"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="mt-1">~</div>
              <DxDateBox
                :styling-mode="stylingMode"
                width="110"
                height="30"
                v-model="searchType.customTypes.endDt"
                type="date"
                display-format="yyyy.MM.dd"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="10"
                :min="searchType.customTypes.startDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="조회시간은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="pl-2 mt-2">조회시간</div>
              <DxDateBox
                placeholder="시작시간"
                :openOnFieldClick="true"
                :styling-mode="stylingMode"
                display-format="HH:mm"
                date-serialization-format="HH:mm"
                type="time"
                :interval="60"
                width="90"
                height="30"
                v-model="searchType.customTypes.startTime"
                dateOutOfRangeMessage="종료시간은 시작시간보다 크거나 같아야 합니다."
                :max="searchType.customTypes.endTime"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="mt-2">~</div>
              <DxDateBox
                placeholder="종료시간"
                :openOnFieldClick="true"
                :styling-mode="stylingMode"
                display-format="HH:mm"
                date-serialization-format="HH:mm"
                type="time"
                :interval="60"
                width="90"
                height="30"
                v-model="searchType.customTypes.endTime"
                dateOutOfRangeMessage="종료시간은 시작시간보다 크거나 같아야 합니다."
                :min="searchType.customTypes.startTime"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="조회시간은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="pl-2 mt-2">적용분</div>
              <DxSelectBox
                placeholder="분간격"
                :data-source="searchType.customTypes.minute"
                v-model="searchType.customTypes.checkMinute"
                display-expr="name"
                value-expr="value"
                :styling-mode="stylingMode"
                width="80"
                height="30"
              />
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>
      <esp-dx-data-grid
        :data-grid="dataGrid"
        ref="worktimeScheGrid"
        @row-click="
          {
          }
        "
      />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import {formatDate, getPastFromToday, isSuccess, setCalculateDate} from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxSelectBox,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            //startDt: getPastFromToday(15, 'days'),
            //endDt: getPastFromToday(-14, 'days'),
            startDt: getPastFromToday(1, 'months'),
            endDt: getPastFromToday(0, 'days'),
            startTime: '09:00',
            endTime: '18:00',
            checkMinute: 15,
            minute: [
              { name: '15', value: 15 },
              { name: '30', value: 30 },
            ],
          },
          paramsData: {},
        },
        originColumns: [
          {
            caption: '부서정보',
            dataField: 'pathNmFull',
            alignment: 'center',
            visible: true,
            width: 150,
            allowEditing: false,
            allowHeaderFiltering: true,
          },
          {
            caption: '상담사',
            dataField: 'agtid',
            alignment: 'center',
            visible: true,
            width: 120,
            allowEditing: false,
            allowHeaderFiltering: true,
            calculateCellValue: rowData => {
              return `${rowData.agtNm} [ ${rowData.agtid} ]`;
            },
          },
          {
            caption: '기준일',
            dataField: 'workDt',
            alignment: 'center',
            visible: true,
            width: 120,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '근무유형',
            dataField: 'workNm',
            alignment: 'center',
            visible: true,
            width: 120,
            allowEditing: false,
            allowHeaderFiltering: true,
          },
          {
            caption: '근무시간',
            dataField: 'workTime',
            alignment: 'center',
            visible: true,
            width: 120,
            allowEditing: false,
            allowHeaderFiltering: false,
            calculateCellValue: rowData => {
              return `${formatDate(rowData.workStartTime, 'HHmm', 'HH:mm')} ~ 
									${formatDate(rowData.workEndTime, 'HHmm', 'HH:mm')}`;
            },
          },
        ],
        dataGrid: {
          keyExpr: 'workScheduleId',
          refName: 'worktimeScheGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            //rowClick: true,
          },
          excel: {
            title: '시간별준수도', 
          },
          showActionButtons: {
            excel: {
              allowExportSelectedData: false,
            },
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            // {
            // 	caption: '부서정보',
            // 	dataField: 'pathNmFull',
            // 	alignment: 'center',
            // 	visible: true,
            // 	width: 150,
            // 	allowEditing: false,
            // 	allowHeaderFiltering: true,
            // },
            // {
            // 	caption: '상담사',
            // 	dataField: 'agtid',
            // 	alignment: 'center',
            // 	visible: true,
            // 	width: 120,
            // 	allowEditing: false,
            // 	allowHeaderFiltering: true,
            //     calculateDisplayCellValue: rowData => {
            //         return `${rowData.agtNm} [ ${rowData.agtid} ]`;
            //     }
            // },
            // {
            // 	caption: '기준일',
            // 	dataField: 'workDt',
            // 	alignment: 'center',
            // 	visible: true,
            // 	width: 120,
            // 	allowEditing: false,
            // 	allowHeaderFiltering: false,
            // },
            // {
            // 	caption: '근무유형',
            // 	dataField: 'workNm',
            // 	alignment: 'center',
            // 	visible: true,
            // 	width: 120,
            // 	allowEditing: false,
            // 	allowHeaderFiltering: false,
            // },
            // {
            // 	caption: '근무시간',
            // 	dataField: 'workTime',
            // 	alignment: 'center',
            // 	visible: true,
            // 	width: 120,
            // 	allowEditing: false,
            // 	allowHeaderFiltering: false,
            // 	calculateDisplayCellValue: rowData => {
            // 		return `${formatDate(rowData.workStartTime, 'HHmm', 'HH:mm')} ~
            // 					${formatDate(rowData.workEndTime, 'HHmm', 'HH:mm')}`;
            // 	}
            // },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      onChangeMinuteType() {
        const dynamicColumns = [...this.originColumns];

        const startTime = parseInt(this.searchType.customTypes.startTime.slice(0, 2));
        const endTime = parseInt(this.searchType.customTypes.endTime.slice(0, 2));

        for (let i = startTime; i <= endTime - 1; i++) {
          const formatHour = i < 10 ? '0' + i : '' + i;

          const hourColumn = {
            multiHeaderNm: formatHour,
            columns: [],
          };

          for (let j = 0; j < 60; j += this.searchType.customTypes.checkMinute) {
            const time = `${formatHour}${j < 10 ? '0' : ''}${j}`;
            hourColumn.columns.push({
              caption: `${j < 10 ? '0' : ''}${j}`,
              dataField: time,
              alignment: 'center',
              visible: true,
              width: 50,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                let divSt = document.createElement('div');
                if (options.value == 900 || options.value == 1800) {
                  container.classList.add('bg-blue-300');
                  divSt.innerHTML = options.value;
                } else if (options.value == -1) {
                  container.classList.add('bg-red-500');
                  divSt.innerHTML = '-';
                } else if (options.value == 0) {
                  divSt.innerHTML = '';
                } else {
                  container.classList.add('bg-yellow-400');
                  divSt.innerHTML = options.value;
                }
                container.append(divSt);
              },
            });
          }
          dynamicColumns.push(hourColumn);
        }

        this.$set(this.dataGrid, 'columns', dynamicColumns);
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {

        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        const oneMonthAgo = setCalculateDate(
          'month',
          formatDate(vm.searchType.customTypes.endDt, 'YYYYMMDD', 'YYYY-MM-DD'),
          -1,
        );
        const validStrDate = setCalculateDate('day', oneMonthAgo, -1);
        const cvtStrDate = new Date(formatDate(vm.searchType.customTypes.startDt, 'YYYYMMDD', 'YYYY-MM-DD'));

        if (validStrDate > cvtStrDate) {
          this.$_Toast('최대 1달 기간만 검색이 가능합니다.');
          return;
        }

        const payload = {
          actionName: 'EWM_WORKTIME_SCHEDULE_SEARCH',
          data: {
            frWorkDt: vm.searchType.customTypes.startDt,
            toWorkDt: vm.searchType.customTypes.endDt,
            startTime: formatDate(vm.searchType.customTypes.startTime, 'HH:mm', 'HHmm'),
            endTime: formatDate(vm.searchType.customTypes.endTime, 'HH:mm', 'HHmm'),
            timeInterval: vm.searchType.customTypes.checkMinute,
          },
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.onChangeMinuteType();

          res.data.data.forEach(item => {
            const timesIntervals = item.timesIntervals;
            Object.keys(timesIntervals).forEach(date => {
              item[date] = timesIntervals[date];
            });
            delete item.timesIntervals;
          });
          this.dataGrid.dataSource = res.data.data;
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
