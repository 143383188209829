<!--
  PACKAGE_NAME : src/pages/euc/device/jabber
  FILE_NAME : modal-smartcopy.vue
  AUTHOR : jhcho
  DATE : 25. 2. 10.
  DESCRIPTION :
-->
<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
      <tr>
        <th>{{ $_lang('UC.WORD.DISPLAY_NAME', { defaultValue: '화면표시' }) }}</th>
        <td>{{ this.rowInfo.displayname }}</td>
        <th>{{ $_lang('UC.WORD.USER_NO') }}</th>
        <td>{{ this.rowInfo.userid }}</td>
        <th>{{ $_lang('COMPONENTS.DEPT') }}</th>
        <td>{{ this.rowInfo.department }}</td>
      </tr>
      <tr>
        <th>외부발신번호</th>
        <td>
          <DxTextBox
              v-model="e164mask"
              placeholder="외부발신번호 입력"
              :styling-mode="config.stylingMode"
              :width="200"
              @value-changed="onChangeInput($event, 'e164mask')"
          >
            <DxValidator @validated="onValidated($event, 'e164mask')">
              <DxCustomRule
                  type="custom"
                  :validation-callback="validateInput"
                  message="숫자 또는 X만 입력가능합니다."
              />
            </DxValidator>
          </DxTextBox>
        </td>
        <th>CSS 유형</th>
        <td>
          <DxSelectBox
              placeholder="CSS 유형"
              :data-source="getCSSList"
              display-expr="name"
              value-expr="name"
              v-model="rowInfo.css"
              styling-mode="outlined"
          >
          </DxSelectBox>
        </td>
        <th>착신 CSS</th>
        <td>
          <DxSelectBox
              placeholder="착신 CSS"
              :data-source="getAllForwardCssList"
              display-expr="name"
              value-expr="name"
              v-model="rowInfo.forwardnCSS"
              styling-mode="outlined"
          >
          </DxSelectBox>
        </td>

      </tr>
      <tr>
        <th>{{ $_lang('UC.WORD.TYPE') }}</th>
        <td colspan="3">
          <DxCheckBox
              v-model="rowInfo.csfFlag"
              class="checkbox fl"
              text="PC JABBER"
              :height="30"
              style="padding-top: 10px"
              @value-changed="changeRowInfo($event, 'csf')"
          />
          <DxCheckBox
              v-model="rowInfo.botFlag"
              class="checkbox fl"
              text="Android"
              :height="30"
              style="padding-top: 10px; margin-left: 10px"
              @value-changed="changeRowInfo($event, 'bot')"
          />
          <DxCheckBox
              v-model="rowInfo.tctFlag"
              class="checkbox fl"
              text="IPhone"
              :height="30"
              style="padding-top: 10px; margin-left: 10px"
              @value-changed="changeRowInfo($event, 'tct')"
          />
          <DxCheckBox
              v-model="rowInfo.tabFlag"
              class="checkbox fl"
              text="TABLET"
              :height="30"
              style="padding-top: 10px; margin-left: 10px"
              @value-changed="changeRowInfo($event, 'tab')"
          />
        </td>
        <th>{{ $_lang('UC.WORD.NUMBER') }}</th>
        <td>
          <DxTextBox
              v-model="dn"
              :placeholder="$_lang('UC.MESSAGE.DN_ENTER')"
              :styling-mode="config.stylingMode"
              :width="200"
              @value-changed="onChangeInput($event, 'dn')"
          >
            <DxValidator @validated="onValidated($event, 'dn')">
              <DxRequiredRule message="내선번호 입력은 필수입니다."/>
              <DxNumericRule message="숫자만 입력 가능합니다."/>
            </DxValidator>
          </DxTextBox>

          <DxButton
              :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
              styling-mode="contained"
              type="default"
              style="margin-left: 10px"
              @click="onUpdateDn()"
          />
        </td>
      </tr>
      </thead>
    </table>
    <table class="table_form line-bin">
      <tbody>
      <esp-dx-data-grid :data-grid="dataGrid" ref="smartcopyGrid" :auto-width="true"></esp-dx-data-grid>
      <div style="padding: 0">
        <DxCheckBox
            v-model="isRemoveCd"
            @value-changed="isRemoveFlag"
            class="checkbox fl"
            :text="$_lang('UC.WORD.CHECK_EXISTING_DEVICE')"
            :height="30"
            style="padding-top: 10px"
        />
      </div>
      </tbody>
    </table>
  </div>
</template>
<script>
import {DxNumericRule} from 'devextreme-vue/data-grid';
import {cloneObj, isSuccess} from '@/utils/common-lib';
import DxButton from 'devextreme-vue/button';
import {DxCheckBox} from 'devextreme-vue/check-box';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxRequiredRule, DxValidator, DxCustomRule} from 'devextreme-vue/validator';
import {DxSelectBox} from "devextreme-vue/select-box";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    DxSelectBox,
    DxButton,
    DxCheckBox,
    DxTextBox,
    DxRequiredRule,
    DxValidator,
    DxNumericRule,
    DxCustomRule,
    EspDxDataGrid
  },
  props: {
    option: {
      default: () => {
        return {
          width: '50%',
          height: '85%',
          minWidth: null,
          minHeight: null,
          saveBtnVisible: false,
          cancelBtnVisible: true,
        };
      },
      type: Object,
    },
    rowInfo: {
      type: Object,
    },
    isOpened: {
      type: Boolean,
    },
    devicePoolList: {
      type: Array,
    },
    modelList: {
      type: Array,
    },
  },
  watch: {
    isOpened() {
      if (this.isOpened) {
        const dn = this.rowInfo?.telephonenumber || '';
        this.dn = dn;
        this.originDn = dn;

        this.checkCSSPattern();
        this.checkAllForwardCSSPattern();
        this.userId = this.rowInfo.userid;
        if (!this.userId) {
          this.rowInfo.csfFlag = false;
          this.rowInfo.botFlag = false;
          this.rowInfo.tctFlag = false;
          this.rowInfo.tabFlag = false;
          this.$_Msg('내선번호가 없습니다. 내선번호를 확인해주세요.');
        } else {
          if(!this.rowInfo?.css) {
            this.rowInfo.css = 'None';
          }
          this.checkGroupPattern(this.dn);
          this.selectIptCode();
          this.setDataSource();
        }

      } else {
        this.resetGridDataSource();
      }
    },
  },
  data() {
    return {
      isDnChange: false,
      originDn: '',
      flag: {
        select: false,
        rowUpdate: false,
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        isRemoveCd: true,
      },
      validationStatus: {
        e164mask: true,
        dn: true,
      },
      dn: '',
      userId: '',
      e164mask: '',
      isRemoveCd: true,
      smartcopyList: [],
      cssList: [],
      allForwardCssList: [],
      componentkey: 0,
      searchKeyword: '',
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        disableTotalCount: true,
        keyExpr: 'mac',
        refName: 'smartcopyGrid',
        dataSource: [],
        showActionButtons: {
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('UC.WORD.APPLY', { defaultValue: '적용' }),
                elementAttr: {
                  class:
                      'dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr',
                },
                height: 30,
                onClick: this.smartCopy,
              },
              location: 'after',
            },
          ],
        },
        page: {
          enabled: false,
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '유형',
            i18n: 'UC.WORD.TYPE',
            dataField: 'type',
            allowEditing: false,
            allowSorting: false,
          },
          {
            caption: 'MAC',
            dataField: 'mac',
            allowEditing: false,
            allowSorting: false,
          },
          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'dn',
            allowEditing: false,
            allowSorting: false,
          },
          {
            caption: '화면표시',
            i18n: 'UC.WORD.DISPLAY_NAME',
            dataField: 'label',
            allowSorting: false,
          },
          {
            caption: '이력표시',
            dataField: 'display',
            allowSorting: false,
          },
          {
            caption: '설명',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            allowSorting: false,
          },
          {
            caption: '모델',
            i18n: 'UC.WORD.MODEL',
            dataField: 'model',
            allowEditing: false,
            allowSorting: false,
          },
        ],
      },
    };
  },
  computed: {
    getCSSList() {
      return this.cssList;
    },
    getAllForwardCssList() {
      return this.allForwardCssList;
    }
  },
  methods: {
    /** @description 조건 만족여부 설정 */
    onValidated(e, type) {
      if (type === 'dn') {
        this.validationStatus.dn = e.isValid;
      } else {
        this.validationStatus.e164mask = e.isValid;
      }
    },
    /**@description 그리드 데이터 초기화 */
    async resetGridDataSource() {
      this.dataGrid.dataSource = [];
      await this.$refs.smartcopyGrid.clearSelection();
    },
    /**@description 기존단말 삭제여부 체크박스 선택 이벤트 */
    isRemoveFlag(event) {
      this.isRemoveCd = event.value;
    },
    /**@description 유형별 checkbox 선택 이벤트 */
    async changeRowInfo(event, type) {
      this.rowInfo[type + 'Flag'] = event.value;
      if (this.rowInfo.telephonenumber != null) {
        await this.setDataSource();
      }
    },
    /**@description 내선 입력창 change 이벤트 */
    onChangeInput(event, type) {
      if (type === 'dn') {
        this.isDnChange = this.originDn !== event.value;
        this.dn = event.value;
      } else {
        this.e164mask = event.value;
      }
    },
    /**@description 내선 변경 후 grid data 변경 */
    async onChangeGridData() {
      const gridData = this.dataGrid.dataSource;
      const payload = {
        actionName: 'EUC_END_USER_SELECT',
        data: {
          id: this.rowInfo.id
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        const data = res.data.data[0];
        if (gridData?.length) {
          gridData.forEach(grid => {
            grid.mac = grid.type + this.userId;
            grid.dn = this.dn;
            grid.label = data.label;
            grid.display = data.display;
            grid.description = data.description;
          });
        }
      }

    },
    /**@description IPT CODE 가져오는 메서드 */
    async selectIptCode(sort = '+codeOrd') {
      const payload = {
        actionName: 'EUC_CODE_SELECT',
        data: {
          sort,
          codeKey: 'smartcopybasicmodel',
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.smartcopyList = res.data.data.filter(item => item.parentId !== null);
      }
    },
    /** @description : Modal Open 이후 grid DataSource Set 함수 */
    async setDataSource() {
      const data = this.rowInfo;
      const dataSource = [];

      if (data.csfFlag) {
        const item = this.smartcopyList.filter(data => data.codeNm === 'CSF');
        dataSource.push({
          type: 'CSF',
          mac: 'CSF' + data.userid,
          description: data?.description || '',
          label: data?.label || '',
          targetMac: item?.[0]?.codeValue || '',
          dn: data.telephonenumber,
          model: item?.[0]?.description || '',
          display: data?.display || ''
        });
      }
      if (data.botFlag) {
        const item = this.smartcopyList.filter(data => data.codeNm === 'BOT');
        dataSource.push({
          type: 'BOT',
          mac: 'BOT' + data.userid,
          description: data?.description || '',
          label: data?.label || '',
          targetMac: item?.[0]?.codeValue || '',
          dn: data.telephonenumber,
          model: item?.[0]?.description || '',
          display: data?.display || ''
        });
      }
      if (data.tctFlag) {
        const item = this.smartcopyList.filter(data => data.codeNm === 'TCT');
        dataSource.push({
          type: 'TCT',
          mac: 'TCT' + data.userid,
          description: data?.description || '',
          label: data?.label || '',
          targetMac: item?.[0]?.codeValue || '',
          dn: data.telephonenumber,
          model: item?.[0]?.description || '',
          display: data?.display || ''
        });
      }
      if (data.tabFlag) {
        const item = this.smartcopyList.filter(data => data.codeNm === 'TAB');
        dataSource.push({
          type: 'TAB',
          mac: 'TAB' + data.userid,
          description: data?.description || '',
          label: data?.label || '',
          targetMac: item?.[0]?.codeValue || '',
          dn: data.telephonenumber,
          model: item?.[0]?.description || '',
          display: data?.display || ''
        });
      }
      this.$set(this.dataGrid, 'dataSource', dataSource);
    },
    /**@description smartcopy 메서드 */
    async smartCopy() {
      const gridData = this.dataGrid.dataSource;
      if(this.isDnChange) {
        await this.$_Msg('내선번호가 변경되었습니다. <br />변경된 내선번호를 적용 후 다시 시도해주세요.', { icon: 'error' });
        return;
      }

      if (this.$refs.smartcopyGrid.hasEditData()) {
        await this.$_Msg('수정중인 항목이있습니다. ');
        return;
      }

      if (!gridData?.length) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
        return;
      }
      if (!gridData[0]?.dn) {
        await this.$_Msg('내선번호가 없습니다. 내선번호 확인 후 다시 시도해주세요.');
        return;
      }
      if (!this.validationStatus.e164mask) {
        await this.$_Msg('외부 발신번호를 입력하세요.');
        return;
      }

      const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }
      const assignedList = this.devicePoolList.map(item => {
        return {USER: item.appuser, DEVICE_POOL: item.name};
      });
      const assignedModels = this.modelList.map(item => item.name).join(',');

      const list = gridData.map(data => {
        return {
          TYPE: data.type,
          TARGET_MAC: data.targetMac,
          MAC: data.mac,
          DESCRIPTION: data.description,
          DN: [
            { INDEX: '1', LABEL: data?.label ? data.label : data.dn, DISPLAY: data.display, NUMBER: data.dn, CSS: this.rowInfo.css, E164MASK: this.e164mask },
          ],
        };
      });

      const params = {
        cmNm: this.rowInfo.cmNm,
        loginId: this.$store.getters.getLoginId,
        loginUserId: this.rowInfo.userid,
        remove: this.isRemoveCd ? 'Y' : 'N',
        assignedModels: assignedModels,
        assignedList: JSON.stringify(assignedList),
        list: JSON.stringify(list),
        assignedUser: null
      };

      const assignedUser = list[0].DN[0].NUMBER;
      params.assignedUser = await this.checkApplicationUser(assignedUser);

      const payload = {
        actionName: 'EUC_DEVICE_SMARTCOPY',
        data: params,
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
        this.$emit('closeModal');
      } else {
        await this.$_Msg(res.data.header.resMsg);
      }
    },
    /** @description ENDUSER 내선번호 변경 */
    async onUpdateDn() {
      if (!this.validationStatus.dn) {
        await this.$_Msg('내선번호는 숫자만 입력 가능합니다.');
        return;
      }

      if (!this.dn) {
        await this.$_Msg('내선번호를 입력하세요.');
        return;
      }

      if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE'))) {
        const copyRow = this.rowInfo;
        copyRow.telephonenumber = this.dn;
        const payload = {
          actionName: 'EUC_END_USER_UPDATE',
          data: [copyRow],
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.originDn = this.dn;
          this.isDnChange = false;
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
          await this.onChangeGridData();
          await this.checkGroupPattern(this.dn);
          await this.checkCSSPattern();
          await this.checkAllForwardCSSPattern();
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      }
    },
    async checkApplicationUser(data) {
      const code = this.$_getCode('inout_type').find(d => d.codeValue === '10')?.codeId;
      if (code) {
        const payload = {
          actionName: 'EUC_DEVICE_GROUP_APPLICATION_CHECK',
          data: {
            pattern: data,
            inoutTypeCd: code
          },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if(isSuccess(res)) {
          return res.data.data[0];
        } else {
          return null;
        }
      } else {
        this.$_Msg('inout_type 코드값이 없습니다.');
        throw new Error('inout_type 코드값이 없습니다.');
      }
    },
    async checkGroupPattern(data) {
      this.e164mask = '';
      const params = {
        pattern: data
      };

      const value = this.$_getSystemData('inout_type_emask').configValue;
      const code = this.$_getCode('inout_type').find(d => d.codeId === Number(value))?.codeId;
      if (code) {
        params.inoutTypeCd = code;
      }

      const payload = {
        actionName: 'EUC_DEVICE_GROUP_CHECK',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data.length > 0) {
          this.e164mask = res.data.data[0];
        }
      }
    },
    validateInput(e) {
      const value = e.value;
      const regex = /^\d+[0-9X]*$/;
      return regex.test(value);
    },
    async selectCSSList() {
      const payload = {
        actionName: 'EUC_CSS_GROUP_SELECT',
        data: {
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        const data = res.data.data;
        this.cssList = cloneObj(data);
        this.allForwardCssList = cloneObj(data);
      }
    },
    checkCSSPattern() {
      // 유사성 점수를 계산하고 1점이면 rowInfo.css 업데이트
      const updateCSS = (item) => {
        const score = this.calculateSimilarity(item?.pattern);
        if (score === 1) {
          this.rowInfo.css = item?.name;
        }
        return score;
      };

      if (!this.cssList?.length) {
        this.rowInfo.css = 'NONE';
        return;
      }

      if (this.cssList.length === 1) {
        const score = updateCSS(this.cssList[0]);
        if (score !== 1) {
          this.rowInfo.css = 'NONE';
        }
        return;
      }

      const configValue = Number(this.$_getSystemData('inout_type_css')?.configValue);
      let scoreCount = 0;
      this.cssList.sort((a, b) => {
        const scoreA = configValue === a.inoutTypeCd ? updateCSS(a) : 0;
        const scoreB = configValue === b.inoutTypeCd ? updateCSS(b) : 0;

        if (scoreA !== scoreB) {
          scoreCount++;
          return scoreB - scoreA;
        } else {
          return a.cssOrd - b.cssOrd;
        }
      });
      if(scoreCount === 0) {
        this.rowInfo.css = 'NONE';
      }
    },
    checkAllForwardCSSPattern() {
      // 유사성 점수를 계산하고 1점이면 rowInfo.css 업데이트
      const updateAllForwardCSS = (item) => {
        const score = this.calculateSimilarity(item?.pattern);
        if (score === 1) {
          this.rowInfo.forwardnCSS = item?.name;
        }
        return score;
      };

      if (!this.allForwardCssList?.length) {
        this.rowInfo.forwardnCSS = 'NONE';
        return;
      }

      if (this.allForwardCssList.length === 1) {
        const score = updateAllForwardCSS(this.allForwardCssList[0]);
        if (score !== 1) {
          this.rowInfo.forwardnCSS = 'NONE';
        }
        return;
      }

      const configValue = Number(this.$_getSystemData('inout_type_all_trans')?.configValue);
      let scoreCount = 0;
      this.allForwardCssList.sort((a, b) => {
        const scoreA = configValue === a.inoutTypeCd ? updateAllForwardCSS(a) : 0;
        const scoreB = configValue === b.inoutTypeCd ? updateAllForwardCSS(b) : 0;

        if (scoreA !== scoreB) {
          scoreCount++;
          return scoreB - scoreA;
        } else {
          return a.cssOrd - b.cssOrd;
        }
      });

      if(scoreCount === 0) {
        this.rowInfo.forwardnCSS = 'NONE';
      }
    },
    calculateSimilarity(pattern) {
      if(!pattern) {
        return 0;
      }
      const regex = new RegExp(pattern);
      return regex.test(this.dn) ? 1 : 0;
    }
  },
  async created() {
    await this.selectCSSList();
    await this.selectIptCode();
  },
};
</script>