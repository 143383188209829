<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
      <tr>
        <th>{{ $_lang('UC.WORD.EXCHANGER') }}</th>
        <td>{{ getCmNm(this.deviceInfo.cmname) }}</td>
        <th>{{ $_lang('UC.WORD.DEVICE_NAME') }}</th>
        <td>{{ this.deviceInfo.name }}</td>
        <th>{{ $_lang('UC.WORD.NUMBER') }}</th>
        <td>{{ this.rowInfo.number }}</td>
        <th>{{ $_lang('UC.WORD.PARTITION') }}</th>
        <td>{{ this.rowInfo.routepartitionname }}</td>
      </tr>
      </thead>
    </table>
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div v-if="authority !== 'user'" style="padding-top: 50px">
        <Search
            :key="componentkey"
            codeKey="search_type_pickup"
            :searchObj="searchType.obj"
            :useKeywordType="true"
            @change-search-type="$_changeSearchType"
            @onSearchClick="$_searchData($event, 'search_type_pickup')"
        >
        </Search>
      </div>
    </div>
    <table class="table_form line-bin">
      <tbody>
        <tr>
          <td class="pickupGrid">
            <esp-dx-data-grid
                :data-grid="dataGrid"
                ref="pickupGrid"
                :auto-width="true"
                :selected-row-keys="dataGrid.selectedRowKeys"
                @content-ready="onContentReady"
                @selection-changed="onSelectionChanged"
            ></esp-dx-data-grid>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Search from '@/components/common/search.vue';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    Search,
    EspDxDataGrid
  },
  props: {
    option: {
      default: () => {
        return {
          width: '50%',
          height: '85%',
          minWidth: null,
          minHeight: null,
          saveBtnVisible: false,
          cancelBtnVisible: true,
        };
      },
      type: Object,
    },

    deviceInfo: {
      type: Object,
    },
    rowInfo: {
      type: Object,
    },
    isOpened: {
      type: Boolean,
    },
    cmList: {
      type: Array,
    },
    partitionList: {
      type: Array,
    },
    gridNumber: {
      type: String,
    },
    authority: {
      type: String,
    },
    pickupKeyword: {
      type: String,
    },
  },
  watch: {
    async isOpened() {
      if (this.isOpened) {
        await this.selectDataList();
      } else {
        this.resetGridDataSource();
      }
    },
  },
  data() {
    return {
      flag: {
        select: false,
        rowUpate: false,
      },
      // searchTexts.value
      componentkey: 0,
      searchKeyword: '',
      stylingMode: 'outlined', //outlined, underlined, filled
      searchType: {
        obj: {
          searchTexts: {
            value: '',
          },
        },
        defaultObj: {id: 'ROOT', codeValue: 'ROOT', codeNm: '전체'},
        defaultValue: 'ROOT',
        customTypes: {
          cm: null,
          model: null,
          status: null,
        },
        paramsData: null,
      },
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'pickupGrid',
        dataSourceDefaultSortColumn: '+callpickupGroupOrd,+id',
        apiActionNm: {
          select: 'EUC_PICKUP_LIST_ALL'
        },
        customEvent: {
          rowPrepared: true,
          contentReady: true,
          selectionChanged: true,
        },
        showActionButtons: {
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                // text: '적용',
                text: this.$_lang('UC.WORD.APPLY'),
                elementAttr: {
                  class: 'dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled',
                },
                width: 60,
                height: 30,
                onClick: () => {
                  this.onClickPickup();
                },
              },
              location: 'before',
            },
          ]
        },
        columns: [
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            visible: this.authority !== 'user',
            allowEditing: false,
            calculateCellValue: row => this.getCmNm(row?.cmNm),
          },
          {
            caption: '그룹명',
            i18n: 'COMPONENTS.GROUP_NAME',
            dataField: 'name',
            visible: this.authority !== 'user',
            allowEditing: false,
            requiredRule: {
              message: '필수 항목입니다.',
            },
          },
          {
            caption: '그룹번호',
            i18n: 'UC.WORD.GROUP_NUMBER',
            dataField: 'pattern',
            visible: this.authority !== 'user',
            allowEditing: false,
          },
          {
            caption: '설명',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            allowEditing: false,
          },
          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            visible: this.authority !== 'user',
            allowEditing: false,
          },
          {
            caption: '등록 내선',
            i18n: 'UC.WORD.REG_NUMBER',
            dataField: 'directorylist',
            allowEditing: false,
          },
          {
            caption: '수정일',
            i18n: 'COMPONENTS.MODIFY_DATE',
            dataField: 'editDt',
            visible: this.authority !== 'user',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.regDt),
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /** @description : 교환기 이름 변환 메서드 */
    getCmNm(cm) {
      return this.cmList.find((d => d.cmNm === cm))?.description || '';
    },
    /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
    setCustomTypes(key, value) {
      this.searchType.customTypes[key] = value;
    },
    /**@description 그리드 데이터 초기화 */
    resetGridDataSource() {
      this.dataGrid.dataSource = [];
      this.$refs.pickupGrid.clearSelection();
    },
    /** @description: 그리드 content ready */
    onContentReady(e) {
      let keys = [];
      const gridData = e.component.getDataSource()._items;
      for (let i = 0; i < gridData.length; i++) {
        if (this.rowInfo.pickupgroupname === gridData[i].name) {
          keys.push(gridData[i].id);
        }
      }
      e.component.selectRows(keys);
    },
    /**@description 그리드 row 선택 변경 이벤트 */
    onSelectionChanged(e) {
      let gridData = [];
      if (e.currentSelectedRowKeys.length > 0 && e.currentSelectedRowKeys.length === 1) {
        let currentSelectedRowKey = e.currentSelectedRowKeys;
        e.component.selectRows(currentSelectedRowKey);
        if (e.selectedRowsData.length > 1) {
          e.selectedRowsData.forEach(item => {
            if (item.id === e.currentSelectedRowKeys[0]) {
              gridData.push(item);
            }
          });
          this.dataGrid.selectedRowsData = gridData;
        } else if (e.selectedRowsData.length === 1) {
          this.dataGrid.selectedRowsData = e.selectedRowsData;
        }
      }
    },
    /**@description : 당겨받기 적용버튼 클릭 메서드 */
    async onClickPickup() {
      const selectedRowCnt = this.dataGrid.selectedRowsData?.length;
      if (selectedRowCnt > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
      } else if (selectedRowCnt === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED'));
      } else if (selectedRowCnt === 1) {
        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const pickupName = this.dataGrid.selectedRowsData[0].name;
        this.$emit('closePickup', this.rowInfo, pickupName, this.gridNumber);
      } else {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER'));
      }
    },
  },
};
</script>