<!--
  PACKAGE_NAME : src/pages/esp/monitoring/redis
  FILE_NAME : redis-info.vue
  AUTHOR : jhcho
  DATE : 25. 2. 12.
  DESCRIPTION :
-->
<template>
  <div class="w-full h-full">
    <table class="table_form line-bin">
      <colgroup>
        <col style="width:150px;"/>
        <col style="width:auto;"/>
        <col style="width:150px;"/>
        <col style="width:auto;"/>
        <col style="width:150px;"/>
        <col style="width:auto;"/>
        <col style="width:150px;"/>
        <col style="width:auto;"/>
      </colgroup>

      <thead class="sub_title_txt">
      <tr>
        <td class="flex items-center">
          <color-badge v-if="redisData" color="green" >양호</color-badge>
          <color-badge v-else color="red" >불량</color-badge>
          <h2>서버 정보</h2>
          <button class="btn-icon" type="button" style="border: 0;" @click="selectDataList">
            <img src="@/assets/images/icon/refresh_icon.png" alt="새로고침 아이콘"/>
          </button>
        </td>
      </tr>
      </thead>
      <tbody v-if="redisData">
      <tr>
        <th>
          <label>Redis 버전</label>
        </th>
        <td>
          {{ redisData.redisVersion }}
        </td>
        <th>
          <label>운영 모드</label>
        </th>
        <td>
          {{ redisData.redisMode }}
        </td>
        <th>
          <label>PID</label>
        </th>
        <td>
          {{ redisData.processId }}
        </td>
        <th>
          <label>운영체제</label>
        </th>
        <td>
          {{ redisData.os }}
        </td>
      </tr>
      <tr>
        <th>
          <label>TCP 포트</label>
        </th>
        <td>
          {{ redisData.tcpPort }}
        </td>
        <th>
          <label>실행 시간</label>
        </th>
        <td>
          {{ Number(redisData.uptimeInDays).toLocaleString() }}일
        </td>
        <th>
          <label>설정 파일</label>
        </th>
        <td>
          {{ redisData.configFile }}
        </td>
        <th>
          <label>연결된 클라이언트</label>
        </th>
        <td>
          {{ Number(redisData.connectedClients).toLocaleString() }}
        </td>
      </tr>
      <tr>
        <th>
          <label>시스템 메모리</label>
        </th>
        <td>
          {{ Number(bytesToGB(redisData.totalSystemMemory)).toLocaleString() }}GB
        </td>
        <th>
          <label>사용중인 메모리</label>
        </th>
        <td>
          {{ Number(bytesToMB(redisData.usedMemory)).toLocaleString() }}MB
        </td>
        <th>
          <label>요청된 명령 수</label>
        </th>
        <td>
          {{ Number(redisData.totalConnectionsReceived).toLocaleString() }}
        </td>
        <th>
          <label>처리된 명령 수</label>
        </th>
        <td>
          {{ Number(redisData.totalCommandsProcessed).toLocaleString() }}
        </td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr>
        <td colspan="8" class="text-center">Redis 연결에 실패하였습니다.</td>
      </tr>
      </tbody>
    </table>
    <RedisItems v-if="redisData"/>
  </div>
</template>
<script>
import ColorBadge from "./color-badge.vue";
import RedisItems from './redis-items.vue';
import {isSuccess} from "@/utils/common-lib";

export default {
  components: {
    ColorBadge,
    RedisItems
  },
  data() {
    return {
      isLoading: false,
      redisData: null,
    }
  },
  computed: {},
  methods: {
    async selectDataList() {
      this.redisData = null;
      const payload = {
        actionName: 'REDIS_INFO_SELECT',
        data: {},
        loading: true
      };

      const res = await this.CALL_API(payload);

      if (isSuccess(res)) {
        this.redisData = res.data.data[0];
      }
    },
    bytesToMB(bytes) {
      return bytes / (1024 * 1024);
    },
    bytesToGB(bytes) {
      return bytes / (1024 * 1024 * 1024);
    }
  },
  mounted() {
    this.selectDataList();
  },
}
</script>
<style scoped>
.table_form th {
  text-align: center !important;
}

.table_form tbody th, /* tbody 내의 th에 대해 */
.table_form tbody td { /* tbody 내의 td에 대해 */
  border: 1px solid #ddd; /* 하단 경계선 추가 */
}
</style>