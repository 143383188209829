<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">등록일</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.startDt"
              type="date"
              display-format="yyyy-MM-dd"
              dateSerializationFormat="yyyyMMdd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.endDt"
              maxLength="7"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.endDt"
              type="date"
              display-format="yyyy-MM-dd"
              dateSerializationFormat="yyyyMMdd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="7"
              :min="searchType.customTypes.startDt"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @toolbar-preparing="onToolbarPreparing" @saving="e => onSave(e)" />

      <modal-add-agent
        :isOpen="popupOptions.visible"
        :selectedIdList="popupOptions.selectedIdList"
        @closeModal="onClose(false)"
        @saveModal="onSaveAgent"
      />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
  import { DxDataGrid } from 'devextreme-vue/data-grid';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import {formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxRequiredRule,
      DxValidator,
      ModalAddAgent,
    },
    data() {
      return {
        popupOptions: {
          visible: false,
          selectedIdList: [],
        },
        codeMap: {},
        codes: {
          addscoreTypeId: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          addscoreTypeDetailId: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            startDt: getPastFromToday(11, 'months'),
            endDt: getPastFromToday(-1, 'months'),
          },
          paramsData: {},
        },
        addscore: [],
        dataGrid: {
          keyExpr: 'id',
          refName: 'addscoreTargetGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellPrepared: false,
            checkDataBeforeSaving: false,
            editorPreparing: false,
            initNewRow: true,
            rowInserted: false,
            rowInserting: false,
            rowPrepared: true,
            saving: true,
            toolbarPreparing: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              // {
              // 	widget: 'dxButton',
              // 	options: {
              // 		icon: '',
              // 		text: '삭제',
              // 		elementAttr: { class: 'btn_XS white light_filled trash' },
              // 		width: 60,
              // 		height: 30,
              // 		onClick() {
              // 			vm.onDeleteData();
              // 		},
              // 	},
              // sortIndex: 10,
              // 	location: 'before',
              // },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptNmPath',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
            },
            {
              caption: '상담사명',
              dataField: 'agtNm',
              visible: false,
            },
            {
              caption: '상담사',
              dataField: 'agtId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtId}]`;
              },
            },
            {
              caption: '가감점구분',
              dataField: 'addscoreTypeId',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              // lookup: {},
              calculateDisplayCellValue: rowData => {
                if (!rowData.addscoreTypeId) {
                  return '';
                } else {
                  const foundItem = this.codes.addscoreTypeId.dataSource.find(e => rowData.addscoreTypeId == e.codeId).codeNm;
                  return `${foundItem}`;
                }
              },
              calculateSortValue: data => {
                return this.codes.addscoreTypeId.dataSource.find(e => data.addscoreTypeId == e.codeId).codeNm;
              },
              editCellTemplate: (container, editOptions) => {
                const dropDownBox = new DxDropDownBox({
                  propsData: {
                    dataSource: this.codes.addscoreTypeId.dataSource,
                    deferRendering: false,
                    showClearButton: false,
                    displayExpr: 'codeNm',
                    valueExpr: 'codeId',
                    value: editOptions.data.addscoreTypeId,
                    stylingMode: this.stylingMode,
                    width: '100%',
                    dropDownOptions: {
                      minWidth: 500,
                    },
                    opened: false,
                    contentTemplate: (dropBox, container) => {
                      const dataGrid = new DxDataGrid({
                        propsData: {
                          keyExpr: 'addscoreTypeId',
                          dataSource: this.addscore,
                          columns: [
                            {
                              caption: '가감점구분',
                              dataField: 'addscoreTypeNm',
                              alignment: 'center',
                              visible: true,
                            },
                            {
                              caption: '가감점상세구분',
                              dataField: 'addscoreTypeDtNm',
                              alignment: 'center',
                              visible: true,
                            },
                            {
                              caption: '사유명',
                              dataField: 'addscoreName',
                              alignment: 'center',
                              visible: true,
                            },
                            {
                              caption: '가감점',
                              dataField: 'addscore',
                              alignment: 'center',
                              visible: true,
                              cellTemplate: function (container, options) {
                                const classStyle = document.createElement('div');
                                if (options.value > 0 || options.value < 0) {
                                  const color = options.value > 0 ? 'blue' : 'red';
                                  classStyle.style.color = color;
                                }
                                classStyle.innerText = options.value;

                                container.append(classStyle);
                              },
                            },
                          ],
                          selection: {
                            mode: 'single',
                          },
                          width: '100%',
                          height: '100%',
                          onSelectionChanged: e => {
                            const selectedData = e.selectedRowsData[0];
                            editOptions.component.cellValue(editOptions.rowIndex, 'addscoreTypeId', selectedData.addscoreTypeId);
                            editOptions.component.cellValue(
                              editOptions.rowIndex,
                              'addscoreTypeDetailId',
                              selectedData.addscoreTypeDetailId,
                            );
                            editOptions.component.cellValue(editOptions.rowIndex, 'addscoreName', selectedData.addscoreName);

                            const curAddscoreTypeId = vm.codes.addscoreTypeId.dataSource.find(
                              code => code.codeId == selectedData.addscoreTypeId,
                            );

                            if (curAddscoreTypeId && curAddscoreTypeId.codeNm != '상시') {
                              editOptions.component.cellValue(editOptions.rowIndex, 'endYmd', editOptions.data.startYmd);
                            }
                          },
                        },
                      });
                      dataGrid.$mount();
                      container.append(dataGrid.$el);
                    },
                  },
                });
                dropDownBox.$mount();
                container.append(dropDownBox.$el);
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '가감점상세구분',
              dataField: 'addscoreTypeDetailId',
              width: 160,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              lookup: {},
              calculateSortValue: data => {
                const findVal = this.codes.addscoreTypeDetailId.dataSource.find(e => data.addscoreTypeDetailId == e.codeId);
                return findVal ? findVal.codeNm : '';
              },
            },
            {
              caption: '사유',
              dataField: 'addscoreName',
              height: 40,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              multiHeaderNm: '기준일',
              columns: [
                {
                  caption: '시작기준',
                  dataField: 'startYmd',
                  width: 110,
                  height: 40,
                  alignment: 'center',
                  dataType: 'date',
                  visible: true,
                  allowEditing: true,
                  allowFiltering: true,
                  allowHeaderFiltering: false,
                  calculateDisplayCellValue: rowData => {
                    if (rowData.startYmd) {
                      return formatDate(rowData.startYmd, 'YYYYMMDD', 'YYYY-MM-DD');
                    }
                  },
                  requiredRule: {
                    message: '필수 항목입니다.',
                  },
                  customRule: {
                    message: '기준일이 유효하지 않습니다.',
                    callback: obj => {
                      return !(obj.data.startYmd && obj.data.endYmd && obj.data.startYmd > obj.data.endYmd);
                    },
                  },
                  setCellValue(newData, value, currentRowData) {
                    const dateE = new Date(currentRowData.endYmd);
                    const dateS = new Date(value);
                    if (dateS > dateE) {
                      newData.startYmd = null;
                    } else {
                      newData.startYmd = value;
                    }

                    const curAddscoreTypeId = vm.codes.addscoreTypeId.dataSource.find(e => currentRowData.addscoreTypeId === e.codeId);

                    if (curAddscoreTypeId && curAddscoreTypeId.codeNm !== '상시') {
                      newData.endYmd = value;
                    }
                  },
                },
                {
                  caption: '종료기준',
                  dataField: 'endYmd',
                  width: 110,
                  height: 40,
                  alignment: 'center',
                  dataType: 'date',
                  visible: true,
                  allowEditing: true,
                  allowFiltering: true,
                  allowHeaderFiltering: false,
                  calculateDisplayCellValue: rowData => {
                    if (rowData.endYmd) {
                      return formatDate(rowData.endYmd, 'YYYYMMDD', 'YYYY-MM-DD');
                    }
                  },
                  editCellTemplate: (container, options) => {
                    const dateS = new Date(options.data.startYmd);
                    if (!options.data.addscoreTypeId) {
                      container.append('');
                    } else if (options.data.addscoreTypeId == 1316) {
                      const dateBox = new DxDateBox({
                        propsData: {
                          value: options.value,
                          stylingMode: this.stylingMode,
                          type: 'date',
                          displayFormat: 'yyyy-MM-dd',
                          disabled: options.data.addscoreTypeId != 1316,
                          min: dateS,
                          onValueChanged: e => {
                            options.component.cellValue(options.rowIndex, 'endYmd', e.value);
                          },
                        },
                      });
                      dateBox.$mount();
                      container.append(dateBox.$el);
                    } else {
                      container.append(options.data.endYmd);
                    }
                  },
                  requiredRule: {
                    message: '필수 항목입니다.',
                  },
                  customRule: {
                    message: '기준일이 유효하지 않습니다.',
                    callback: obj => {
                      return obj.data.startYmd && obj.data.endYmd && obj.data.startYmd > obj.data.endYmd ? false : true;
                    },
                  },
                },
              ],
            },
            {
              caption: '등록자',
              dataField: 'regId',
              width: 120,
              height: 40,
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.regNm} [ ${rowData.regId} ]`;
              },
            },
            {
              caption: '등록자명',
              dataField: 'regNm',
              visible: false,
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              width: 120,
              height: 40,
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: false,
              calculateDisplayCellValue: rowData => {
                return formatDate(rowData.regDt, 'YYYYMMDD', 'YYYY-MM-DD');
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /* onRowPrepared(e) {
			if (e.rowType === 'data') {
				const addscoreTypeId = e.data.addscoreTypeId;
				let allowEdit = false;

				//console.log('addscoreTypeId', e);

				if (addscoreTypeId) {
					const curAddscoreTypeId = vm.codes.addscoreTypeId.dataSource.find(code => code.codeId == addscoreTypeId);

					console.log('curAddscoreTypeId', curAddscoreTypeId);

					if (curAddscoreTypeId && curAddscoreTypeId.codeNm == '상시') {
						allowEdit = true;
					}
				}

				e.columns.find(item => item.dataField === 'endYmd').allowEditing = allowEdit;

				//e.component.columnOption('endYmd', 'allowEditing', allowEdit);
			}
		}, */
      /** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
      onToolbarPreparing(e) {
        const toolbarItems = e.toolbarOptions.items;

        toolbarItems.forEach(item => {
          if (item.name === 'saveButton') {
            item.location = 'before';
            item.sortIndex = 40;
            item.options.icon = '';
            item.options.text = '저장';
            item.showText = 'always';
            item.options.elementAttr = { class: 'btn_XS default filled ' };
            item.options.width = '60';
            item.options.height = '30';
            item.options.visible = true;
          } else if (item.name === 'addRowButton') {
            item.location = 'before';
            item.sortIndex = 30;
            item.options.icon = '';
            item.options.text = '추가';
            item.showText = 'always';
            item.options.elementAttr = { class: 'btn_XS default filled add1' };
            item.options.width = '60';
            item.options.height = '30';
            item.options.visible = true;
            item.options.onClick = () => this.onOpen();
          } else if (item.name === 'revertButton') {
            item.location = 'before';
            item.sortIndex = 50;
            item.options.icon = '';
            item.options.text = '취소';
            item.options.elementAttr = { class: 'btn_XS white light_filled ' };
            item.showText = 'always';
            item.options.width = '60';
            item.options.height = '30';
            item.options.visible = true;
            item.options.onClick = () => this.$refs.addscoreTargetGrid.getInstance.cancelEditData();
          }
        });

        toolbarItems.push({
          widget: 'dxButton',
          options: {
            icon: '',
            text: '삭제',
            showText: 'always',
            elementAttr: { class: 'btn_XS white light_filled trash' },
            width: 60,
            height: 30,
            onClick: () => vm.onDeleteData(),
          },
          location: 'before',
          sortIndex: 60,
        });

        toolbarItems.unshift({
          location: 'after',
          widget: 'dxTemplate',
          template: 'totalCount',
        });
      },
      onOpen() {
        this.popupOptions.visible = true;
        this.popupOptions.selectedIdList = [];
      },
      onClose() {
        this.popupOptions.visible = false;
        this.popupOptions.selectedIdList = [];
      },
      onSaveAgent(selDataList) {
        let data = [];
        selDataList.forEach(item => {
          data.push({
            deptNmPath: item.deptNmPath,
            agtId: item.agtid,
            agtNm: item.agtNm,
            regId: this.$store.getters.getLoginId,
            regNm: this.$store.getters.getLoginNm,
            regDt: new Date(),
          });
        });
        for (let i = 0; i < data.length; i++) {
          this.$refs.addscoreTargetGrid.getInstance.addRow();
        }
        data.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs.addscoreTargetGrid.getInstance.cellValue(index, key, value);
          });
        });

        this.popupOptions.visible = false;
      },
      /** @description : 저장 */
      async onSave(e) {
        e.cancel = true;

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        if (!e.changes.length) {
          return;
        }

        let bfData = [];
        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            if (d.type === 'update') {
              let rowIndex = e.component.getRowIndexByKey(d.key);
              let row = e.component.getVisibleRows()[rowIndex];
              let updateData = row.data;

              bfData.push(updateData);
            } else if (d.type === 'insert') {
              if (Object.keys(d.data).length !== 0) {
                let updateData = { ...d.data };
                updateData['id'] = null;

                bfData.push(updateData);
              }
            }
          });
        }

        let data = [];
        bfData.forEach(d => {
          data.push({
            id: d.id,
            agtId: d.agtId,
            addscoreTypeId: d.addscoreTypeDetailId,
            addscoreName: d.addscoreName,
            startYmd: formatDate(d.startYmd, 'YYYYMMDD', 'YYYY-MM-DD'),
            endYmd: formatDate(d.endYmd, 'YYYYMMDD', 'YYYY-MM-DD'),
          });
        });

        const payload = {
          actionName: 'EWM_ADDSCORE_TARGET_SAVE',
          data: { data: data },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

          this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 삭제 */
      async onDeleteData() {
        const selectedRowsData = this.$refs.addscoreTargetGrid.selectedRowsData;
        const sendData = selectedRowsData.map(x => x.id);

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        } else {
          if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
            return;
          }

          const payload = {
            actionName: 'EWM_ADDSCORE_TARGET_DELETE',
            data: sendData,
            loading: true,
          };

          const res = await this.CALL_EWM_API(payload);

          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
            this.selectDataList();
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.$refs.addscoreTargetGrid.getInstance.cancelEditData();

        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load() {
            const payload = {
              actionName: 'EWM_ADDSCORE_TARGET_LIST',
              data: {
                startDt: vm.searchType.customTypes.startDt,
                endDt: vm.searchType.customTypes.endDt,
              },
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              res.data.data.forEach(r => {
                r.addscoreTypeDetailId = r.addscoreTypeId;
                if (r.addscoreTypeId > 1317) {
                  r.addscoreTypeId = 1317;
                }
              });

              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.addscoreTargetGrid.totalCount = rtnData.totalCount;

              return rtnData;
            }
          },
        });
      },
      async addscoreDataList() {
        const payload = {
          actionName: 'EWM_ADDSCORE_LIST',
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          res.data.data.forEach(r => {
            r.addscoreTypeDetailId = r.addscoreTypeId;
            r.addscoreTypeId = r.addscoreTypeId > 1317 ? 1317 : r.addscoreTypeId;
            r.addscoreTypeNm = this.codes.addscoreTypeId.dataSource.find(c => c.codeId === r.addscoreTypeId).codeNm;
            r.addscoreTypeDtNm =
              r.addscoreTypeId == 1317
                ? this.codes.addscoreTypeDetailId.dataSource.find(c => c.codeId === r.addscoreTypeDetailId).codeNm
                : '';
          });

          this.addscore = res.data.data;
        }
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_addscore_type');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          this.codes.addscoreTypeId.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_addscore_type'], 2);
          this.codes.addscoreTypeDetailId.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_addscore_type'], 3);

          this.$refs.addscoreTargetGrid.getInstance.columnOption('addscoreTypeId', 'lookup', this.codes.addscoreTypeId);
          this.$refs.addscoreTargetGrid.getInstance.columnOption('addscoreTypeDetailId', 'lookup', this.codes.addscoreTypeDetailId);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
        this.addscoreDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
