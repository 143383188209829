export default {
  MESSAGE: {
    SYSTEM_ERROR: 'There is a system error. Please contact the administrator.',
    SYSTEM_ROUTE_ERROR: 'Routing information not found. <br/> ( ACTION_NAME : {actionName} )',
    CHECK_DEPT_DEPTH: 'Adding departments is only possible up to 4 levels.',
    CHECK_MENU_DEPTH: 'Adding menus is only possible up to 4 levels.',
    CHANGED_ACTIVE: 'The selected account has been changed to active status.',
    CMN_CFM_DELETE: 'Are you sure you want to delete?',
    CMN_CFM_DELETE_SELECTED: 'Are you sure you want to delete the selected data?',
    CMN_CFM_DUPLICATE_SELECTED: 'Are you sure you want to copy the selected data?',
    CMN_CFM_SAVE: 'Are you sure you want to save?',
    CMN_ERROR: 'An error occurred while processing data.',
    CMN_ERROR_INTERNAL_SERVER: 'Server error.',
    CMN_ERROR_TIMEOUT: 'The set request time ({timeout}) ms has been exceeded.',
    CMN_NO_ADD: 'No more data can be added.',
    CMN_NO_CHANGED: 'No data has been changed.',
    CMN_NO_DATA: 'No data available.',
    CMN_NOT_SELECTED: 'No target selected.',
    CMN_ONE_SELECT: 'Please select only one target.',
    CMN_PREPARING: 'Preparing.',
    CMN_SUC_DELETE: 'Deleted successfully.',
    CMN_SUC_SAVE: 'Saved successfully.',
    CMN_SUC_UPDATE: 'Changed successfully.',
    CMN_SUC_UPLOAD: 'Uploaded successfully.',
    CMN_SUCCESS: 'Processed successfully.',
    CMN_NO_AUTH: '{value}No permission.',
    DISALLOWED_FILE: 'This file type is not allowed.',
    EXIST_ACCOUNT: 'An account already exists for the selected target.',
    EXIST_PERSONAL: 'A personnel data target for the selected employment status already exists.',
    EXIST_UNCHANGED_DATA: 'There is data that has not been changed.',
    CANNOT_BE_DEL_ACCOUNT_AUTH: 'Cannot delete as there are granted permissions to the user.',
    OVER_SIZE_FILE: 'The file exceeds the allowed size({size}) and cannot be uploaded.',
    OVER_LICENSE_COUNT: 'You have exceeded the number of licenses <br/>that can be registered and cannot register any more accounts.',
    PWD_INVALID: 'Invalid password.',
    PWD_NON_EMPTY: 'Please enter the password without spaces.',
    PWD_NON_KR: 'You cannot use Korean in your password.',
    REQUIRED_VALUE: 'This is a required value.',
    REQUIRED_VALUE_IS: '[{value}] is a required value.',
    REQUIRED_FOR_CHILD_CODE: 'You must enter a sub-code value.',
    REQUIRED_IF_CHILD_CODE_PRESENT: 'If you have entered a sub-code value, <br/>you must add a sub-code name data.',
    REQUIRED_DEPT_SEARCH: 'Selection of the department for search exposure is required.',
    REQUIRED_DEPT_ADMIN: 'If the user type is "Department Admin", <br/>selection of the department is required.',
    REQUIRED_FILE_ADDITION: 'Please add a file to upload.',
    REQUIRED_PARENT_CODE: 'You must add parent code name data.',
    REQUIRED_PARENT_DEPT: 'Select the parent department to which you want to add sub-departments.',
    REQUIRED_PARENT_MENU: 'Select the parent menu to add sub-menus.',
    REQUIRED_SELECT_USER: 'Please select a user.',
    REQUIRED_SELECT_DEPT: 'Please select a department first.',
    REQUIRED_DATE: 'Please select a date.',
    REQUIRED_SORT_KEY: 'Please set the sortKey.',
    SEARCH_DATE_RANGE_ERROR: 'The end date must be greater than or equal to the start date.',
    REQUIRED_DOWNLOAD_REASON: 'Enter the reason for downloading.',
    INVALID_DATE_FORMAT: 'The date format is not valid.',
    PLEASE_SELECT_DATE: 'Please select a date.',
    SEARCH_TIME_MAX_RANGE_ERROR: 'The start time must be less than or equal to the end time.',
    SEARCH_TIME_MIN_RANGE_ERROR: 'The end time must be greater than or equal to the start time.',
    REQUIRED_VALUE_VALIDATION_ERROR: 'Please input required value.',
    PLEASE_SAVE_THE_CHANGES: 'Please save the changes.',
    EMPLOYEE_NUMBER_ALREADY_IN_USE: 'Employee number already in use.',
    ERROR_AUTH_DATA_FETCH: 'Error in fetching permission data.',
    NUMBER_ENTER: 'Please Enter Only Numbers.',
    PLEASE_SELECT_TARGET: 'Please select a target.',
    PLEASE_SELECT_TO: 'Please select a {target}.',
    PLEASE_SELECT_SETTING: 'Please select a setting.',
    CALL_API_FAIL: 'Data retrieval failed. cause : [{message}]',
    ACTIVATE_SELECTED_ACCOUNT: 'Do you want to activate the selected account?',
    PASSWORD_MISMATCH: 'Passwords do not match. Please verify.',
    NOT_DUPLICATE_VALUE_IS: '[{value}] Values cannot be duplicated.',
    FORGOT_PASSWORD: 'If you forgot your password, please contact the administrator.',
    ENTER_ID: 'Please enter the ID.',
    ENTER_PASSWORD: 'Please enter the password.',
    CONFIRM_DELETE_WITH_SUB_PERMISSIONS: 'Deleting will also remove all sub-permissions. <br>Do you want to continue?',
    MAIN_SETTING_MENU_TYPE_ERROR: 'Invalid menu type. (Only General Pages and Reports are allowed.)',
    WARNING_FORCE_LOGOUT: 'You will be automatically logged out soon. Would you like to stay logged in?',
    FORCE_LOGOUT: 'You have been logged out due to inactivity.',
    NOT_FOUND_SEARCH_DATA: 'No search results exist.',
    PASSWORD_POLICY_NONE:
      'Combination of {minCategories} or more uppercase letters, lowercase letters, numbers, and special characters<br>' +
      'Minimum {minLength} characters, maximum {maxLength} characters<br>' +
      'Consecutive letters or numbers cannot exceed {consecutiveLength} characters<br>' +
      'Available special characters {specialChars}<br>' +
      'ex) Abc123!@#',
    PASSWORD_POLICY_ID:
      'Combination of {minCategories} or more uppercase letters, lowercase letters, numbers, and special characters<br>' +
      'Minimum {minLength} characters, maximum {maxLength} characters<br>' +
      'Consecutive letters or numbers cannot exceed {consecutiveLength} characters<br>' +
      'ID cannot be included<br>' +
      'Available special characters {specialChars}<br>' +
      'ex) Abc123!@#',
    PASSWORD_POLICY_NAME:
      'Combination of {minCategories} or more uppercase letters, lowercase letters, numbers, and special characters<br>' +
      'Minimum {minLength} characters, maximum {maxLength} characters<br>' +
      'Consecutive letters or numbers cannot exceed {consecutiveLength} characters<br>' +
      'Name cannot be included<br>' +
      'Available special characters {specialChars}<br>' +
      'ex) Abc123!@#',
    PASSWORD_POLICY:
      'Combination of {minCategories} or more uppercase letters, lowercase letters, numbers, and special characters<br>' +
      'Minimum {minLength} characters, maximum {maxLength} characters<br>' +
      'Consecutive letters or numbers cannot exceed {consecutiveLength} characters<br>' +
      'ID cannot be included<br>' +
      'Name cannot be included<br>' +
      'Available special characters {specialChars}<br>' +
      'ex) Abc123!@#',
    LOADING: 'Loading...',
    CANNOT_ADD_MENU: 'This menu cannot be added.',
    ALREADY_ADDED_MENU: 'This menu is already added.',
    DELETE_WITH_SUB: 'The sub {target} will also be deleted. Do you want to proceed?',
    TEXT_BOX_LIMIT: 'You can enter up to {limit} characters.',
    MENU_AUTH_ADD_DESC:
      'When a menu is first registered, menu permissions are automatically added to the registrant’s group. <br />' +
      ' Additional settings can be managed in the permission menu.',
    PASSWORD_EXPIRED_WARNING:
      'You have not changed your password for {pwdChgDay} days. <br />' +
      'To protect your personal information, please update your password.',
    PASSWORD_TEMPORARY_WARNING: 'Password change is required. <br /> Please update your password to protect your personal information.',
    PASSWORD_INVALID: 'Invalid password.',
    DUPLICATE_ID: 'The ID is already in use.',
    IMMUTABLE_KEY_WARNING: 'The key value cannot be changed. Please delete and re-register.',
    LOWER_CASE_AND_UNDERSCORE_ONLY: 'Only lowercase letters and underscores (_) are allowed.',
    VALIDATION_ERROR: 'Validation error',
    ENTER_ROOT_GROUP_KEY: 'Please enter the group key following \'root_\'.',
    GROUP_KEYS_MUST_BE_SAME: 'Group keys within the same group must all be identical.',
    REQUIRED_MIN_SELECTION: 'Please select a minimum of {minLength} items.',
    REQUIRED_MAX_SELECTION: 'Please select no more than {maxLength} items.',
    ESP_CHAT_TOOLTIP: ' - Managing public holidays: Add a temporary holiday on the 27th <br/> - Check inbound group statistics: Display yesterday service level',
    ESP_CHAT_INIT_MESSAGE: 'How can we help you?',
    ENTER_ROUTE_ALIAS_VALID: "Please enter in the format 'Alias_'. (Except for 'esp' alias)",
    ENTER_ACTION_NAME: 'Please enter ACTION_NAME.',
    AUTH_MAIN_PAGE_ERROR_TITLE: 'You don\'t have permissions for the main page.',
    AUTH_MAIN_PAGE_ERROR_BODY: 'Use the "Permission Management" menu or contact the administrator.',
    ENTER_BYTE_CHECK: 'The input string can be up to {maxBytes} bytes.',
  },
  WORD: {
    ADD_MAIN_SETTING: 'Add main settings',
    SETTING_TARGET: 'Setting target',
    MAIN_PAGE_TYPE: 'Main Page type',
    MAIN_PAGE_ID: 'Main Page ID',
    SITE: 'Site',
    SITE_NAME: 'Site Name',
    TENANT: 'Tenant',
    TENANT_NAME: 'Tenant Name',
    CONTENT: 'Content',
    SAVE_ID: 'Save ID',
    TYPE: 'Type',
    HOLIDAY_NAME: 'Holiday Name',
    HOLIDAY: 'Holiday',
    LOAD_HOLIDAY: 'Load Holiday',
    COMMON: 'Common',
    MENU_SEARCH: 'Menu Search',
    MY_FAVORITE_MENU: 'My Favorite Menu',
    REMAINING_SESSION_TIME: 'Remaining Session Time',
    INIT_SESSION: 'Init Session',
    ENTER_QUERY: 'Enter Query',
    INSERT_FORM: 'Insert Form',
    IN_PROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    FAILED: 'Failed',
    STEP: 'Step',
  },
};
