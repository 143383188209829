import Vue from 'vue'
import Vuex from 'vuex'
import { state } from './state';
import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';

import createPersistedState from 'vuex-persistedstate';
import persistedStateStore from './modules/persistedstate-store.js';

Vue.use(Vuex);

let storeInstance = null;

/**
 * @description Vuex Store 생성
 * @return {Vuex.Store}
 */
export async function createStore() {
    const store = new Vuex.Store({
        modules: {
            persistedStateStore,
        },
        plugins: [
            (store) => {
                store.subscribe((mutation, state) => {
                    const isProd = process.env.NODE_ENV === 'production';
                    if (!isProd) {
                        console.log(`[Mutation]: ${mutation.type} : `, mutation.payload);
                    }
                });
            },
            // vuex-persistedstate 를 사용하여 store 의 상태를 localstorage 에 저장
            createPersistedState({
                paths: [
                    'persistedStateStore',
                ]
            })
        ],
        state,
        mutations,
        actions,
        getters,
    });

    storeInstance = store; // 인스턴스 전역변수에 저장
    return store;
}

/**
 * @description Vuex Store 인스턴스 반환
 * @return {Vuex.Store}
 */
export function getStoreInstance() {
    if (!storeInstance) {
        throw new Error('[Vuex Error] Vuex store 초기화 오류');
    }
    return storeInstance;
}
