<!--
  PACKAGE_NAME : src/pages/euc/chineseWall
  FILE_NAME : dept-tag-box.vue
  AUTHOR : jhcho
  DATE : 24. 12. 9.
  DESCRIPTION :
-->
<template>
  <div class="dx-tag-content m-1" @click="removeTag(dept)">
    <span>{{dept.deptNm}}</span>
    <div class="dx-tag-remove-button"></div>
  </div>
</template>
<script>
export default {
  props: {
    dept: {
      type: Object,
      default: () => {
        return {
          deptId: '',
          deptNm: '',
          type: ''
        }
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    async removeTag(dept) {
      const msg = `${dept.deptNm} 부서를 삭제하시겠습니까?`;
      if(await this.$_Confirm(msg)) {
        this.$emit('onRemoveDept', dept);
      }
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>
</style>