import ApprovalRoutes from "@/router/ewm/approval";
import EducationRoutes from "@/router/ewm/education";
import EvaluationRoutes from "@/router/ewm/evaluation";
import ExamRoutes from "@/router/ewm/exam";
import HistoryRoutes from "@/router/esp/history";
import KpiRoutes from "@/router/ewm/kpi";
import OpRoutes from "@/router/ewm/op";
import PersonalRoutes from "@/router/ewm/personal";
import ProductivityRoutes from "@/router/ewm/productivity";
import SurveyRoutes from "@/router/ewm/surveys";
import EwmHRRoutes from "@/router/ewm/hr";

export default function EwmRoutes(commonComponents) {
  return [
    ...ApprovalRoutes(commonComponents),
    ...EducationRoutes(commonComponents),
    ...EvaluationRoutes(commonComponents),
    ...ExamRoutes(commonComponents),
    ...EwmHRRoutes(commonComponents),
    ...HistoryRoutes(commonComponents),
    ...KpiRoutes(commonComponents),
    ...OpRoutes(commonComponents),
    ...PersonalRoutes(commonComponents),
    ...ProductivityRoutes(commonComponents),
    ...SurveyRoutes(commonComponents),
  ];
}