<!--
  PACKAGE_NAME : src\pages\cc\skl\group
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2025-03-21
  DESCRIPTION : 운영관리 > CIT > 스킬그룹 관리
-->
<template>
  <div class="container">
    <div class="flex flex-wrap">
      <!-- Left Layer -->
      <div class="flex-initial w-3/5 pad_le20">
        <esp-dx-data-grid :data-grid="sklGroupDataGrid" id="sklGroupDataGrid" :ref="sklGroupDataGrid.refName" @row-click="getMSklGroupSklList($event, false)" />
      </div>
      <!-- Left Layer -->

      <!-- Right Layer -->
      <div class="flex-initial w-2/5 pad_le20 pad_ri20">
        <esp-dx-data-grid :data-grid="sklDataGrid" id="sklDataGrid" :ref="sklDataGrid.refName" @selection-changed="handleSelectionChanged" @saving="handleSaving"/>
      </div>
      <!-- Right Layer -->
    </div>

    <!-- 질의 등록/수정 -->
    <modal-skl-group
      v-if="modal.visible"
      :is-open="modal.visible"
      :saveType="modal.saveType"
      :contentData="modal.contentData"
      @saveModal="handleSaveModal('save')"
      @closeModal="handleCloseModal('save')"
    />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import ModalSklGroup from "@/pages/cc/skl/group/modal-skl-group.vue";
  import { isSuccess, cloneObj } from '@/utils/common-lib';

  export default {
    components: {
      EspDxDataGrid,
      EspDxButton,
      ModalSklGroup,
    },
    data() {
      return {
        sklGroupDataGrid: {
          callApi: 'CALL_CC_API', // Core 제외한 API 호출 설정 / Core API 호출은 생략 가능
          refName: 'sklGroupDataGrid', // 그리드 참조명
          dataSource: [], // dataSource 설정
          showBorders: true,
          toolbarOptions: {
            visible: true, // 툴바 노출 여부
          },
          showActionButtons: { // 상단 버튼 노출 설정값
            update: false, // 추가/저장/취소 한번에 설정 / true이거나 생략시 add, save, cancel 개별 설정 사용 가능 / true가 기본
            add: false, // 추가 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            save: false, // 저장 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            cancel: false, // 취소 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            delete: false, // 삭제 / true가 기본
            customButtons: [ // 커스텀 버튼 / []가 기본
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('', { defaultValue: '스킬그룹 추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 120,
                  height: 30,
                  onClick: () => {
                    this.modal.contentData = {
                      sklGroupOrd: this.sklGroupDataGrid.dataSource.length !== 0
                        ? Math.max(...this.sklGroupDataGrid.dataSource.map(obj => obj.sklGroupOrd))+1
                        : 1
                    };
                    this.handleOpenModal('add');
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteSklGroup();
                  },
                },
                location: 'before',
              },
            ],
          },
          selecting: {
            mode: 'single', // 행 단일/멀티 선택 타입 : ['single', 'multiple']
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          },
          page: { // paging, pager 설정(하나라 합침) / true가 기본
            enabled: false,
          },
          columns: [
            {
              caption: '사이트', // 타이틀
              i18n: 'COMMON.WORD.SITE_NM', // 다국어 처리
              dataField: 'siteId', // 컬럼명
              width: 120,
              lookup: { // SelectBox
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '센터',
              i18n: 'COMMON.WORD.TENANT_NM',
              dataField: 'tenantId',
              width: 130,
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '스킬그룹명',
              i18n: '',
              dataField: 'sklGroupNm',
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  this.modal.contentData = options.data;
                  this.handleOpenModal('update');
                });
                container.append(aTag);
              },
            },
            {
              caption: '스킬개수',
              i18n: '',
              dataField: 'msklGroupSkl.length',
              width: 110,
            },
            {
              caption: '순서',
              i18n: '',
              dataField: 'sklGroupOrd',
              width: 80,
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              width: 110,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },

        sklDataGrid: {
          callApi: 'CALL_CC_API', // Core 제외한 API 호출 설정 / Core API 호출은 생략 가능
          refName: 'sklDataGrid', // 그리드 참조명
          keyExpr: 'sklid',
          dataSource: [], // dataSource 설정
          selectedRowKeys: [],
          showBorders: true,
          toolbarOptions: {
            visible: true, // 툴바 노출 여부
          },
          showActionButtons: { // 상단 버튼 노출 설정값
            update: true, // 추가/저장/취소 한번에 설정 / true이거나 생략시 add, save, cancel 개별 설정 사용 가능 / true가 기본
            add: false, // 추가 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            save: true, // 저장 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            cancel: false, // 취소 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            delete: false, // 삭제 / true가 기본
            customButtons: [ // 커스텀 버튼 / []가 기본
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 60,
                  height: 30,
                  onClick: async () =>  {
                    if (!this.sklDataInstance.hasEditData()) {
                      return this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
                    }
                    await this.initSklListData(this.selectSklGroupSklList, this.selectSklGroupId);
                    this.sklDataInstance.cancelEditData();
                  },
                },
                location: 'before',
              },
            ],
          },
          page: { // paging, pager 설정(하나라 합침) / true가 기본
            enabled: false,
          },
          columns: [
            {
              caption: '스킬',
              i18n: '',
              dataField: 'skl',
              allowEditing: false,
              width: '50%',
            },
            {
              caption: '스킬명',
              i18n: '',
              dataField: 'sklNm',
              allowEditing: false,
              width: '50%',
            },
            {
              caption: '우선순위',
              i18n: '',
              dataField: 'priority',
              width: 110,
              lookup: {
                dataSource: this.$_getCode('ers_am_priority'),
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
              },
            },
          ],
        },

        modal: {
          visible: false,
          saveType: null,
          contentData: null,
        },

        sklDataInstance: null,      // sklDataGrid Instance
        sklList: [],                // 모든 스킬 List
        selectSklGroupSklList: [],  // 선택한 스킬그룹별 스킬 List
        selectSklGroupId: null,     // 선택한 스킬그룹 Id
        sklGroupClickFl: false,     // 스킬그룹 선택 여부
      };
    },
    methods: {
      /** @description 스킬그룹 리스트 조회 */
      async getSklGroupList() {
        const payload = {
          actionName: 'CC_SKL_GROUP_LIST',
          data: {
            sort: '+sklGroupOrd,+sklGroupNm'
          },
          loading: true
        }

        const res = await this.CALL_CC_API(payload);

        if(isSuccess(res)) {
          this.sklGroupDataGrid.dataSource = res.data.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR'));
        }
      },

      /** @description 스킬 리스트 조회 */
      async getSklList() {
        const payload = {
          actionName: "CC_SKL_LIST",
          data: {
            sort: '+sklOrd'
          },
          loading: true,
        }

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.sklList = res.data.data.map(({ skl, sklid, sklNm }) => ({ skl, sklid, sklNm }));
        }
      },

      /**
       * @description 스킬그룹별 스킬 맵핑 리스트 조회
       * @param e eventData
       * @param refreshFl 새로고침 여부
       * */
      async getMSklGroupSklList(e, refreshFl) {
        if (this.selectSklGroupId === e.data.id && !refreshFl) {
          return;
        }

        this.sklGroupClickFl = true;

        if (this.sklDataInstance.hasEditData()) {
          this.sklDataInstance.cancelEditData();
        }

        this.selectSklGroupSklList = cloneObj(e.data.msklGroupSkl);
        this.selectSklGroupId = e.data.id;

        this.initSklListData(this.selectSklGroupSklList, this.selectSklGroupId);

        this.$nextTick(() => {
          this.sklGroupClickFl = false;
        });
      },

      /**
       * @description 스킬 리스트 초기화
       * @param data 스킬 데이터
       * @param sklGroupId 스킬그룹 ID
       * */
      initSklListData(data, sklGroupId) {
        const selectedRowKeys = [];
        this.sklDataGrid.dataSource = this.sklList.map(skl => {
          const foundSkl = data.find(item => item.sklid === skl.sklid);
          foundSkl ? selectedRowKeys.push(foundSkl.sklid) : null;
          return foundSkl ? { ...skl, ...foundSkl, id: null } : { ...skl, id: null, sklGroupId, priority: "49" };
        });
        this.sklDataGrid.selectedRowKeys = selectedRowKeys;
      },

      /** @description 스킬선택 변경 이벤트 */
      async handleSelectionChanged(e) {
        if (this.sklGroupClickFl) {
          return;
        }
        const dataSource = this.sklDataInstance.getDataSource().items();
        const selectFl = e.currentSelectedRowKeys.length!==0;
        const priority = selectFl ? "1" : "49";
        const rowKeys = selectFl ? e.currentSelectedRowKeys : e.currentDeselectedRowKeys;

        for (let i = 0; i < rowKeys.length; i++) {
          for (let j = 0; j < dataSource.length; j++) {
            if (dataSource[j].sklid === rowKeys[i]) {
              const rowIndex = dataSource.findIndex(item => item.sklid === rowKeys[i]);

              if (!selectFl || (selectFl && this.sklDataInstance.cellValue(rowIndex,'priority') === "49")) {
                this.sklDataInstance.cellValue(rowIndex,'priority',priority)
              }

              break;
            }
          }
        }
      },

      /** @description 스킬그룹 삭제 이벤트 */
      async deleteSklGroup() {
        let selectedRows = this.$refs.sklGroupDataGrid.getInstance.getSelectedRowsData();

        if (selectedRows.length <= 0) {
          this.$_Msg(this.$_lang('CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))) {
          const payload = {
            actionName: 'CC_SKL_GROUP_DELETE',
            data: selectedRows,
            loading: false,
          };

          const res = await this.CALL_CC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다' }));

            this.sklDataGrid.dataSource = [];
            this.sklDataGrid.selectedRowKeys = [];
            this.selectSklGroupSklList = [];
            this.selectSklGroupId =  null;

            await this.getSklGroupList();
          } else {
            this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            return false;
          }
        }
      },

      /** @description 스킬그룹별 스킬 저장 이벤트 */
      async handleSaving(e) {
        e.cancel = true;

        let data = cloneObj(e.component.getSelectedRowsData());

        data.forEach(sklData => {
          const changeData = e.changes.find(changeData => changeData.key === sklData.sklid);
          if (changeData) {
            Object.assign(sklData, changeData.data);
          }
        });

        const payload = {
          actionName: 'CC_SKL_GROUP_SKL_UPDATE',
          data: {
            data: data,
            sklGroupId: this.selectSklGroupId
          },
          loading: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          await this.getSklGroupList();
          let data = { data: { id: this.selectSklGroupId, msklGroupSkl: this.sklGroupDataGrid.dataSource.find(item => item.id === this.selectSklGroupId).msklGroupSkl } };
          await this.getMSklGroupSklList(data, true);
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다' }));
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /**
       * @description 팝업 열기
       * @param saveType 저장 팝업 타입(add, update)
       * */
      handleOpenModal(saveType) {
        this.modal.saveType = saveType;
        this.modal.visible = true;
      },

      /** @description 팝업 저장 */
      handleSaveModal() {
        this.$_Toast(this.$_lang('COMMON.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        this.getSklGroupList();
        this.handleCloseModal();
      },

      /** @description 팝업 닫기 */
      handleCloseModal() {
        this.modal.contentData = null;
        this.modal.visible = false;
      },
    },

    /** @description 라이프사이클 created 호출되는 메서드 */
    created() {
      this.getSklList();
    },

    /** @description 라이프사이클 mounted 호출되는 메서드 */
    mounted() {
      this.sklDataInstance = this.$refs.sklDataGrid.getInstance;

      // esp-dx-data-grid 2개 이상 사용시 grid height 1개만 적용돼서 추가작업
      const headerPanel = this.$refs.sklDataGrid.getHeightElement('.dx-datagrid-header-panel'); // 상단 툴팁 영역
      const headers = this.$refs.sklDataGrid.getHeightElement('.dx-datagrid-headers'); // 트리 헤더 영역
      const noDataElementHeight =  this.$refs.sklDataGrid.getGridTopOffset + headerPanel + headers;
      const noDataElement = document.querySelector('#sklDataGrid .dx-empty');
      if (noDataElement) {
        noDataElement.style.height = 'calc(100vh - ' + (noDataElementHeight) + 'px)';
      }

      this.getSklGroupList();
    },
  };
</script>

<style lang="scss" scoped>
  .pad_ri20{
    padding-right: 20px !important;
  }
</style>