<template>
  <div>
    <input type="file" ref="excelUpload" style="display: none" @change="onExcelUpload"/>
    <input type="file" ref="iniUpload" style="display: none" @change="onIniUpload"/>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
      <tr>
        <th>{{ $_lang('UC.WORD.EXCHANGER') }}</th>
        <td>{{ getCmName(this.deviceInfo.cmname) }}</td>
        <th>{{ $_lang('UC.WORD.DEVICE_NAME') }}</th>
        <td>{{ this.deviceInfo.name }}</td>
        <td class="fl" colspan="2">
          <DxButton
              v-if="this.authority !== 'user'"
              :text="$_lang('UC.WORD.EXCEL_DOWNLOAD')"
              @click="onFileExport('excel')"
              :width="120"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
              styling-mode="contained"
              type="default"
          />
          <DxButton
              v-if="this.authority !== 'user'"
              :text="$_lang('UC.WORD.EXCEL_UPLOAD')"
              @click="onFileImport('excel')"
              :width="120"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
              styling-mode="contained"
              type="default"
          />
          <DxButton
              v-if="this.authority !== 'user'"
              :text="$_lang('UC.WORD.EXCHANGER_SELECT')"
              @click="selectExpansion"
              :width="120"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
              styling-mode="contained"
              type="default"
          />
        </td>
        <td class="fr" colspan="2">
          <DxButton
              v-if="this.authority !== 'user'"
              :text="$_lang('UC.WORD.EXCHANGER_CHECK')"
              @click="onCtiSelectExp"
              :width="120"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
              styling-mode="contained"
              type="default"
          />
          <DxButton
              :text="$_lang('UC.WORD.EXCHANGER_APPLY')"
              @click="onCtiSetExp"
              :width="120"
              :height="30"
              class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
              styling-mode="contained"
              type="default"
          />
        </td>
      </tr>
      </thead>
    </table>
    <template v-if="count === 2">
      <table class="table_form line-bin fl" style="width: 49.5%">
        <tbody>
        <tr>
          <td>
            <esp-dx-data-grid
                :data-grid="getDataGrid(0)"
                :ref="getDataGridRef(0)"
                :auto-width="true"
                @row-updating="onRowUpdating"
                @cell-prepared="onCellPrepared"
            ></esp-dx-data-grid>
          </td>
        </tr>
        </tbody>
      </table>
      <table class="table_form line-bin fr" style="width: 49.5%">
        <tbody>
        <tr>
          <td>
            <esp-dx-data-grid
                :data-grid="getDataGrid(1)"
                :ref="getDataGridRef(1)"
                :auto-width="true"
                @row-updating="onRowUpdating"
                @cell-prepared="onCellPrepared"
            ></esp-dx-data-grid>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-if="count === 4">
      <table class="table_form line-bin">
        <tr>
          <!-- @TODO 추후에 화면 확인 후 수정해야함 -->
          <table class="table_form line-bin fl" style="width: 49.5%">
            <tbody>
            <tr>
              <td>
                <esp-dx-data-grid
                    :data-grid="getDataGrid(0)"
                    :ref="getDataGridRef(0)"
                    :auto-width="true"
                    @row-updating="onRowUpdating"
                ></esp-dx-data-grid>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table_form line-bin fr" style="width: 49.5%">
            <tbody>
            <tr>
              <td>
                <esp-dx-data-grid
                    :data-grid="getDataGrid(1)"
                    :ref="getDataGridRef(1)"
                    :auto-width="true"
                    @row-updating="onRowUpdating"
                ></esp-dx-data-grid>
              </td>
            </tr>
            </tbody>
          </table>
        </tr>
        <tr>
          <th class="alC">1페이지</th>
        </tr>
        <tr>
          <table class="table_form line-bin fl" style="width: 49.5%">
            <tbody>
            <tr>
              <td>

                <esp-dx-data-grid
                    :data-grid="getDataGrid(2)"
                    :ref="getDataGridRef(2)"
                    :auto-width="true"
                    @row-updating="onRowUpdating"
                ></esp-dx-data-grid>
              </td>
            </tr>
            </tbody>
          </table>
          <table class="table_form line-bin fr" style="width: 49.5%">
            <tbody>
            <tr>
              <td>
                <esp-dx-data-grid
                    :data-grid="getDataGrid(3)"
                    :ref="getDataGridRef(3)"
                    :auto-width="true"
                    @row-updating="onRowUpdating"
                ></esp-dx-data-grid>
              </td>
            </tr>
            </tbody>
          </table>
        </tr>
      </table>
    </template>
    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="false"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="reasonModalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isModal="modal.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => isOpenModal(false),
        }"
      />
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: $_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => onDownloadReason(),
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxLookup} from 'devextreme-vue/data-grid';
import {DxColumn} from 'devextreme-vue/tree-list';
import {isSuccess} from '@/utils/common-lib';
import DxButton from 'devextreme-vue/button';
import ExcelJS, {Workbook} from 'exceljs';
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    DxPopup,
    DxToolbarItem,
    DxSelectBox,
    DxColumn,
    DxButton,
    DxLookup,
    ModalDownloadReason,
    EspDxDataGrid
  },
  props: {
    option: {
      default: () => {
        return {
          width: '80%',
          height: '85%',
          minWidth: null,
          minHeight: null,
          saveBtnVisible: false,
          cancelBtnVisible: true,
        };
      },
      type: Object,
    },

    deviceInfo: {
      type: Object,
    },
    isOpened: {
      type: Boolean,
    },
    authority: {
      type: String,
    },
    cmList: {
      type: Array,
    },
  },
  watch: {
    deviceInfo() {
    },
    async isOpened() {
      if (this.isOpened) {
        await this.mountedData();
      } else {
        this.resetGridDataSource();
      }
    },
  },
  data() {
    return {
      expansionData: {
        '': '',
        LIST: '',
      },
      selectField: [
        {
          codeNm: 'None',
          codeValue: 'None',
        },
        {
          codeNm: 'BLF',
          codeValue: 'BLF',
        },
        {
          codeNm: 'SD',
          codeValue: 'SD',
        },
      ],
      flag: {
        select: false,
        check: false,
        rowUpdate: false,
      },
      excelData: {
        excel: [],
        ini: [],
      },
      count: 4, //FIXME 전화기 확장모듈 모델이 따른 grid개수 , 이후 DB에서 조회된 값 할당 필요
      editing: true,
      dataGrid: {},
      userGrid: [],
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
    };
  },
  computed: {},
  methods: {
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
        this.modal.contentData = null;
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.initData = componentInitData; //set init modal templet
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    async onDownloadReason() {
      const reason = this.$refs.reasonModalRef.reason;
      if (reason.trim() === '') {
        this.$_Msg(
            this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
              defaultValue: '다운로드 사유를 입력하세요.',
            }),
        );
      } else {
        const user = {
          userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
          userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
          deptNm: this.$store.getters.getUserInfo?.deptNm || '',
          gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
        };

        const payload = {
          actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
          data: [
            {
              ...user,
              reason,
              fileType: 'EXCEL',
              fileNm: this.dataGrid.excel?.title,
            },
          ],
          loading: false,
        };
        await this.CALL_API(payload);
        this.onExcelDownload();
        this.isOpenModal(false);
      }
    },
    getDataGrid(value) {
      return this.dataGrid[`dataGrid_${value}`];
    },
    getDataGridRef(value) {
      return `refDataGrid_${value}`;
    },
    /**@description : 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      for (let i = 0; i < this.count; i++) {
        this.$set(this.dataGrid, `dataGrid_${i}`, {
          refName: `refDataGrid_${i}`,
          dataSource: [],
          apiActionNm: {
            update: '',
          },
          customEvent: {
            rowUpdating: true,
            cellPrepared: true,
            saving: true,
          },
          showActionButtons: {
            excel: false, // 엑셀 버튼
          },
          columns: [
            {
              caption: '순번',
              i18n: 'UC.WORD.INDEX',
              dataField: 'index',
              allowEditing: false,
            },
            {
              caption: '전화번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'number',
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: function (e) {
                    return e.value === '' || (!isNaN(e.value) && e.value !== null);
                  },
                  message: this.$_lang('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },
            {
              caption: '표시',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
            },
            {
              caption: '타입',
              i18n: 'UC.WORD.TYPE',
              dataField: 'field',
              visible: this.authority !== 'user',
              lookup: {
                dataSource: [],
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
              },
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routePartition',
              visible: this.authority !== 'user',
              editorType: 'dxSelectBox',
              cellTemplate: (container, options) => {
                let selData = options.data.allocRoutePartitionList;
                let data = [];
                let ref = 'refDataGrid_' + i;
                if (selData?.length > 0) {
                  for (let i = 0; i < selData.length; i++) {
                    data.push({codeNm: selData[i], codeValue: selData[i]});
                  }
                }
                const selectBox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_lang('COMPONENTS.SELECT'),
                    items: data,
                    displayExpr: 'codeNm',
                    valueExpr: 'codeValue',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      const idx = options.data.index;
                      this.changePartition(e.value, idx);
                      this.$refs[ref].getGridInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                      this.$refs[ref].getGridInstance.saveEditData();
                    },
                  },
                });
                selectBox.$mount();
                container.append(selectBox.$el);
              },
            },
          ],
        });
      }
    },
    async selectExpansion() {
      this.flag.check = false;
      const payload = {
        actionName: 'EUC_DEVICE_EXPANSION_SELECT',
        data: {
          mac: this.deviceInfo.name,
          cmNm: this.deviceInfo.cmname,
          loginId: this.$store.getters.getLoginId,
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const deviceInfo = res.data.data[0];
        if (JSON.parse(deviceInfo).CAUSE && JSON.parse(deviceInfo).CAUSE.toUpperCase() !== 'NONE') {
          await this.$_Msg(JSON.parse(deviceInfo).CAUSE);
        }

        this.editing = true;
        const data = JSON.parse(res.data.data[0]);
        let size = data.LIST.length / this.count;
        const arr = [];
        for (let i = 0; i < data.LIST.length; i += size) {
          arr.push(data.LIST.slice(i, i + size));
        }
        if (arr.length > 0) {
          for (let i = 0; i < this.count; i++) {
            this.dataGrid[`dataGrid_${i}`].dataSource = arr[i];
          }
        }
        if (this.authority === 'user') {
          this.userGrid = this.getGridDataSource();
        }
      } else {
        if (res.data.data?.length) {
          await this.$_Msg(JSON.parse(res.data.data[0]).CAUSE);
        } else {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR'));
        }
      }
    },
    /**@description : 라이프사이클 mounted시 호출되는 메서드 */
    async mountedData() {
      for (let i = 0; i < this.count; i++) {
        this.dataGrid[`dataGrid_${i}`].columns[3].lookup.dataSource = this.$_getCode('euc_expansion_dial_type');
      }
      await this.selectExpansionType();
      await this.selectExpansion();
    },
    /**
     * @description : 확장모듈 교환기 체크
     */
    async onCtiSelectExp() {
      const arr = this.getGridDataSource();
      if (this.AllGridCheckEdit()) {
        await this.$_Msg('수정중인 내용이 있습니다. 확인후 다시 시도해주세요.');
        return false;
      }
      if (arr.length === 0 || typeof arr === 'undefined') {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
        return;
      }
      const list = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].routePartition === null) {
          arr[i].routePartition = '';
        }
        if (!arr[i]?.index && !arr[i]?.number) {
          if (!arr[i]?.label) {
            arr[i].label = arr[i].number;
          }
          list.push(arr[i]);
        }
      }

      list.forEach(obj => {
        // 해당 KEY제거 안하면 서버에서 에러발생, 이중 배열구조라서 JSON변환을 두번해야함. 필요없으니 삭제
        delete obj['allocRoutePartitionList'];
      });

      const payload = {
        actionName: 'EUC_DEVICE_EXPANSION_CHECK',
        data: {
          cmNm: this.deviceInfo.cmname,
          mac: this.deviceInfo.name,
          list: JSON.stringify(list),
          loginId: this.$store.getters.getLoginId,
        },
        loading: true,
      };

      if (list?.length) {
        const res = await this.CALL_EUC_API(payload);

        if (isSuccess(res)) {
          const cause = res.data.data[0]?.CAUSE;
          if (cause.toUpperCase() !== 'NONE') {
            await this.$_Msg(cause);
          }
          const selectData = res.data.data[0].LIST;
          const indexArr = selectData.map((item) => item.index);
          arr.forEach(data => {
            selectData.forEach(obj => {
              if (data.index === obj.index) {
                data.index = obj.index;
                data.field = obj.field;
                data.label = obj.label;
                data.number = obj.number;
                data.allocRoutePartitionList = obj.allocRoutePartitionList;
                if (obj.allocRoutePartitionList.length > 0 && obj.routePartition === '') {
                  data.routePartition = obj.allocRoutePartitionList[0];
                } else {
                  data.routePartition = obj.routePartition;
                }
              } else if (data.index !== obj.index && !indexArr.includes(data.index)) {
                //교환기 통하여 체크 되지않은 부분은 초기화.
                data.label = '';
                data.number = '';
                data.routePartition = '';
                data.allocRoutePartitionList = [];
              }
            });
          });
          this.flag.rowUpdate = false;
          this.flag.check = true;
          //selectRow 제거 및 css 변경
          for (let i = 0; i < this.count; i++) {
            const ref = 'refDataGrid_' + i;
            const gridElement = this.$refs[ref].$el;
            const selectedRows = gridElement.querySelectorAll('.dx-single-selection');
            selectedRows.forEach(row => {
              row.classList.remove('dx-selection');
              row.classList.remove('dx-single-selection');
            });
            await this.$refs[ref].getGridInstance.clearSelection();
          }
        } else {
          const cause = res.data.data[0].CAUSE;
          if (cause.toUpperCase() !== 'NONE') {
            await this.$_Msg(cause);
          } else {
            await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER'));
          }
        }
      } else {
        this.flag.check = true; //빈값을 등록 할 경우 예외처리 위해 사용
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED'));
      }
    },
    /** @description 교환기 등록 */
    async onCtiSetExp() {
      if (this.authority !== 'user') {
        if (this.flag.rowUpdate || this.AllGridCheckEdit()) {
          this.$_Msg(
              this.$_lang('UC.MESSAGE.CHANGED_DATA') + this.$_lang('UC.MESSAGE.REQUIRED_EXCHANGER_CHECK'),
          );
          return;
        }

        if (!this.flag.check) {
          this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_EXCHANGER_CHECK'));
          return;
        }
      }

      const parseArr = this.getGridDataSource();
      const list = [];

      for (let i = 0; i < parseArr.length; i++) {
        if (parseArr[i].routePartition === null) {
          parseArr[i].routePartition = '';
        }
        if (parseArr[i].index != null && parseArr[i].label != null) {
          list.push(parseArr[i]);
        }
      }
      list.forEach(obj => {
        // 해당 KEY제거 안하면 Backend에서 에러발생
        delete obj['allocRoutePartitionList'];
      });

      const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      //FIXME 부서,권한 받아올수있게되면 수정필요
      const payload = {
        actionName: 'EUC_DEVICE_EXPANSION_SET',
        data: {
          type: this.authority === 'user' ? 'USER' : 'MANAGER',
          cmNm: this.deviceInfo.cmname,
          mac: this.deviceInfo.name,
          list: JSON.stringify(list),
          loginId: this.$store.getters.getLoginId,
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.flag.check = false;
        const arr = this.getGridDataSource();
        const cause = res.data.data[0].CAUSE;
        if (cause.toUpperCase() !== 'NONE') {
          this.$_Msg(cause);
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
        }
        const selectData = res.data.data[0].LIST;
        arr.forEach(data => {
          selectData.forEach(obj => {
            if (data.index === obj.index) {
              data.index = obj.index;
              data.field = obj.field;
              data.label = obj.label;
              data.number = obj.number;
              data.routePartition = obj.routePartition;
              data.allocRoutePartitionList = obj.allocRoutePartitionList;
            }
          });
        });
        if (this.authority !== 'user') {
          this.editing = false;
          this.$refs[this.getDataGridRef(0)].getGridInstance.refresh();
        } else {
          await this.selectExpansion();
        }
      } else {
        const cause = res.data.data[0].CAUSE;
        if (cause.toUpperCase() !== 'NONE') {
          this.$_Msg(cause);
        } else {
          this.$_Msg('COMMON.MESSAGE.CMN_ERROR');
        }
      }
    },
    getCmName(cm) {
      let cmNm = '';
      for (let i = 0; i < this.cmList.length; i++) {
        if (this.cmList[i].cmNm === cm) {
          cmNm = this.cmList[i].description;
        }
      }
      if (cmNm !== '' && cmNm !== null) {
        return cmNm;
      } else {
        return '';
      }
    },
    onRowUpdating() {
      this.flag.rowUpdate = true;
    },
    /** @description 확장모듈 그리드 데이터 리턴 */
    getGridDataSource() {
      const arr = [];
      for (let i = 0; i < this.count; i++) {
        for (let j = 0; j < this.dataGrid[`dataGrid_${i}`].dataSource.length; j++) {
          arr.push(this.dataGrid[`dataGrid_${i}`].dataSource[j]);
        }
      }

      if (arr.length === 0 || typeof arr === 'undefined') {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR'));
        return false;
      } else {
        return arr;
      }
    },
    /** @description 그리드 데이터 초기화 */
    resetGridDataSource() {
      for (let i = 0; i < this.count; i++) {
        this.dataGrid[`dataGrid_${i}`].dataSource = [];
      }
    },
    /**@description 파티션 selectBox change 이벤트
     * @param value
     * @param idx
     */
    async changePartition(value, idx) {
      let arr = this.getGridDataSource();

      arr.forEach(data => {
        if (data.index === idx) {
          data.routePartition = value;
        }
      });
    },
    /**@description 엑셀파일 업로드 메서드 */
    async onExcelUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(file);
        const worksheet = workbook.getWorksheet(1);
        const allRow = [];
        const firstCellInfo = ['B', 'C', 'D'];
        const secondCellInfo = ['E', 'F', 'G'];
        worksheet.eachRow((row, rowNumber) => {
          if (rowNumber > 3 && rowNumber < worksheet.rowCount) {
            //4번째 row부터 실행
            const firstPageData = {
              index: '',
              number: '',
              label: '',
              field: '',
              routePartition: '',
              allocRoutePartitionList: [],
            };
            const secondPageData = {
              index: '',
              number: '',
              label: '',
              field: '',
              routePartition: '',
              allocRoutePartitionList: [],
            };
            row._cells.forEach(item => {
              const address = item._address;
              const half = Math.floor(address.length / 2);
              const cellName = address.substring(0, half);
              // let cellNumber = address.substring(half);
              firstCellInfo.forEach(data => {
                if(cellName === data) {
                  switch (cellName) {
                    case 'B':
                      firstPageData.index = item._value.model.value.toString();
                      break;
                    case 'C':
                      firstPageData.number = item._value.model.value.toString();
                      break;
                    case 'D':
                      firstPageData.label = item._value.model.value.toString();
                      break;
                  }
                }
              });
              secondCellInfo.forEach(data => {
                if(cellName === data) {
                  switch (cellName) {
                    case 'E':
                      secondPageData.index = item._value.model.value.toString();
                      break;
                    case 'F':
                      secondPageData.number = item._value.model.value.toString();
                      break;
                    case 'G':
                      secondPageData.label = item._value.model.value.toString();
                      break;
                  }
                }
              });
            });
            allRow.push(firstPageData);
            allRow.push(secondPageData);
          }
        });

        allRow.sort((a, b) => {
          if (parseInt(a.index) > parseInt(b.index)) return 1;
          if (parseInt(a.index) === parseInt(b.index)) return 0;
          if (parseInt(a.index) < parseInt(b.index)) return -1;
        });
        this.excelData.excel = allRow;
        this.editing = true;
        const gridData = this.getGridDataSource();
        for (let i = 0; i < gridData.length; i++) {
          for (let j = 0; j < allRow.length; j++) {
            if (gridData[i].index === allRow[j].index) {
              allRow[j].field = gridData[i].field;
            }
          }
        }

        this.setGridDataSource(allRow);
      }
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.column.index !== 0 && e.column.index !== 4) {
          e.column.allowEditing = this.editing;
        }
      }
    },
    /**@description 화면에 그려진 그리드 데이터 엑셀로 다운받는 함수 */
    onExcelDownload() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet(this.$_lang('UC.WORD.EXPANSION_MODULE'));
      const headerRowNumber = 3;
      const data = this.getGridDataSource();
      const size = data.length / this.count;
      const startRowNumber = 2;
      const endRowNumber = startRowNumber * 2 + size;
      const columns = [];
      const headerColumn = ['', '순번', '전화번호', '표시', '순번', '전화번호', '표시'];

      const fileName = this.deviceInfo?.description ? this.deviceInfo.description : this.deviceInfo.name;
      worksheet.getRow(1).height = 4;
      worksheet.spliceRows(headerRowNumber, 0, headerColumn);
      // F2F2F2
      this.setExcelStyle(worksheet, startRowNumber);

      for (let i = 1; i < worksheet.getRow(headerRowNumber).actualCellCount + 1; i++) {
        if (i !== 1) {
          worksheet.getRow(headerRowNumber).getCell(i).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F2F2F2'},
          };
        }
      }
      for (let i = 0; i < data.length - size; i++) {
        columns.push([
          '',
          data[i].index,
          data[i].number,
          data[i].label,
          data[i + size].index,
          data[i + size].number,
          data[i + size].label,
        ]);
      }
      worksheet.addRows(columns);

      for (let i = 1; i <= worksheet.columnCount; i++) {
        const eachColumn = worksheet.getColumn(i);
        if (i === 1) {
          eachColumn.width = 0.5;
        } else if (i === 2 || i === 5) {
          eachColumn.width = 5;
        } else if (i === 3 || i === 6) {
          eachColumn.width = 25;
        } else {
          eachColumn.width = 32;
        }
        eachColumn.alignment = {horizontal: 'center'};
      }
      this.setExcelStyle(worksheet, endRowNumber);

      for (let i = startRowNumber; i <= endRowNumber; i++) {
        for (let j = 2; j <= 7; j++) {
          const cell = worksheet.getRow(i).getCell(j);
          cell.border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'},
          };
        }
      }

      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName + `.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },

    /**@description 전체 그리드 수정여부 체크 */
    AllGridCheckEdit() {
      let check = false;
      for (let i = 0; i < this.count; i++) {
        let refs = this.getDataGridRef(i);
        check = this.$refs[refs].hasEditData();
        if (check) {
          break;
        }
      }
      return check;
    },
    onIniUpload() {
    },
    /**
     * @description 엑셀 스타일 설정
     */
    setExcelStyle(sheet, rowNum) {
      const worksheet = sheet;
      worksheet.mergeCells('B' + rowNum + ' : D' + rowNum);
      worksheet.mergeCells('E' + rowNum + ' : G' + rowNum);
      worksheet.getCell('B' + rowNum).value = '1페이지';
      worksheet.getCell('E' + rowNum).value = '2페이지';
      worksheet.getCell('B' + rowNum).font = {size: 11, bold: true, color: {argb: '000000'}};
      worksheet.getCell('E' + rowNum).font = {size: 11, bold: true, color: {argb: '000000'}};
      worksheet.getCell('B' + rowNum).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'ED7D31'},
      };
      worksheet.getCell('E' + rowNum).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'ED7D31'},
      };
    },
    setGridDataSource(array) {
      const arr = [];
      const size = array.length / this.count;
      for (let i = 0; i < array.length; i += size) {
        arr.push(array.slice(i, i + size));
      }
      if (arr.length > 0) {
        for (let i = 0; i < this.count; i++) {
          this.dataGrid[`dataGrid_${i}`].dataSource = arr[i];
        }
      }
    },
    /**@description 타입별 파일업로드 분기 메서드 */
    onFileImport(type) {
      if (type === 'excel') {
        this.$refs.excelUpload.click();
      } else if (type === 'ini') {
        this.$refs.iniUpload.click();
      }
    },
    /**@description 타입별 파일다운로드 분기 메서드 */
    onFileExport(type) {
      if (type === 'excel') {
        this.onOpenModal(
            'ModalDownloadReason',
            {
              title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', {defaultValue: '다운로드 사유'}),
              width: '600',
              height: '400',
            },
            '1',
        );
      }
    },

    /** @description 확장모듈 타입 설정   */
    async selectExpansionType() {
      const payload = {
        actionName: 'EUC_EXPANSION_LIST_ALL',
        data: {
          sort: '+moduleOrd,+id',
          loginId: this.$store.getters.getLoginId
        },
        loading: true,
      };
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const data = res.data.data;
        const moduleName = this.deviceInfo.addonmodules;

        let type = '';
        for (let i = 0; i < data.length; i++) {
          if (data[i].moduleNm === moduleName) {
            type = data[i].moduleType;
          }
        }

        if (type !== '' && type === '2') {
          this.count = 4;
        } else {
          this.count = 2;
        }
      } else {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER'));
      }
    },
  },
  async created() {
    this.createdData();
  },
};
</script>