<template>
  <div class="page-sub-box ewm">
    <!-- Search -->
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="page_search_box line_bottom_1px">
        <div class="flex space-x-2 inner">
          <div class="mt-2">기준년월</div>
          <DxDateBox
            :edit-enabled="false"
            :styling-mode="stylingMode"
            width="100"
            height="30"
            v-model="searchType.customTypes.dayStart"
            type="date"
            display-format="yyyy-MM"
            dateSerializationFormat="yyyyMMdd"
            dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            :max="searchType.customTypes.dayEnd"
            maxLength="7"
            invalid-date-message="입력 데이터가 유효하지 않습니다."
            :calendar-options="{
              zoomLevel: 'year',
              minZoomLevel: 'year',
              maxZoomLevel: 'year',
            }"
            @value-changed="onDayStartChanged"
          >
            <DxValidator validation-group="validationSearch">
              <DxRequiredRule message="기준년월은 필수입니다." />
            </DxValidator>
          </DxDateBox>
          <div class="mt-1">~</div>
          <DxDateBox
            :styling-mode="stylingMode"
            width="100"
            height="30"
            v-model="searchType.customTypes.dayEnd"
            type="date"
            display-format="yyyy-MM"
            dateSerializationFormat="yyyyMMdd"
            dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            maxLength="7"
            :min="searchType.customTypes.dayStart"
            invalid-date-message="입력 데이터가 유효하지 않습니다."
            :calendar-options="{
              zoomLevel: 'year',
              minZoomLevel: 'year',
              maxZoomLevel: 'year',
            }"
            @value-changed="onDayEndChanged"
          >
            <DxValidator validation-group="validationSearch">
              <DxRequiredRule message="기준년월은 필수입니다." />
            </DxValidator>
          </DxDateBox>
          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
        </div>
      </div>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />

    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
  import { DxColumn, DxDataGrid, DxSelection } from 'devextreme-vue/data-grid';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import {cloneObj, formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import ModalProcessStatus from '@/pages/ewm/exam/implementation/exam-schedule/modal-process-status.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;

  export default {
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,
      DxDataGridx: DxDataGrid,
      DxSelection,
      DxColumn,

      DxPopup,
      DxToolbarItem,
      ModalProcessStatus,
      DxRequiredRule,
      DxValidator,
    },
    props: {},
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: [],
        },
        dataGrid: {
          keyExpr: 'scheId',
          refName: 'examSchedule',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onAddData();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '시험구분',
              dataField: 'examSheetType',
              alignment: 'center', // left center right
              visible: true,
              width: 130,
              allowEditing: true,
              allowSorting: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'examSheetType'),
              lookup: {
                dataSource: this.$_enums.ewm.examSheetType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '시험계획명',
              dataField: 'scheNm',
              alignment: 'left', // left center right
              visible: true,
              allowEditing: true,
              allowSorting: true,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onUpdateData(options.data);
                });
                container.append(aTag);
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '진행상태',
              dataField: 'examProcess',
              alignment: 'center', // left center right
              visible: true,
              width: 170,
              allowEditing: true,
              allowSorting: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'examProcess'),
              lookup: {
                dataSource: this.$_enums.ewm.examProcess.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => {
                const prevButton = new DxButton({
                  propsData: {
                    template: '<span class="mdi mdi-chevron-left"></span>',
                    height: 30,
                    type: 'button',
                    hint: '이전 상태로 변경',
                    elementAttr: { class: 'btn_XS white outlined' },
                    disabled: options.data.examProcess === this.$_enums.ewm.examProcess.PROGRESS_READY.value,
                    onClick: () => {
                      vm.onChangeProcess(options, 'PREV');
                    },
                  },
                });
                prevButton.$mount();

                const valueDivTag = document.createElement('div');
                valueDivTag.innerText = options.displayValue;
                valueDivTag.setAttribute('class', 'pt-2');

                const nextButton = new DxButton({
                  propsData: {
                    template: '<span class="mdi mdi-chevron-right"></span>',
                    height: 30,
                    type: 'button',
                    hint: '다음 상태로 변경',
                    elementAttr: { class: 'btn_XS white outlined mr-0' },
                    disabled: options.data.examProcess === this.$_enums.ewm.examProcess.FINISH.value,
                    onClick: () => {
                      vm.onChangeProcess(options, 'NEXT');
                    },
                  },
                });
                nextButton.$mount();

                const divTag = document.createElement('div');
                divTag.setAttribute('class', 'flex justify-between justify-items-center gap-x-2');
                divTag.appendChild(prevButton.$el);
                divTag.appendChild(valueDivTag);
                divTag.appendChild(nextButton.$el);
                container.append(divTag);
              },
            },
            {
              caption: '기준년월',
              dataField: 'scheYmd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              dataType: 'date',
              format: 'yyyy-MM',
              editorOptions: {
                dateType: 'date',
                calendarOptions: {
                  zoomLevel: 'year',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'year',
                },
              },
            },
            {
              caption: '시험일시',
              dataField: 'examDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData => {
                return `${formatDate(rowData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')}
                                ${formatDate(rowData.examStartTime, 'HHmm', 'HH:mm')} ~
                                ${formatDate(rowData.examEndTime, 'HHmm', 'HH:mm')}`;
              },
            },
            {
              caption: '이의제기기간',
              dataField: 'examFeedbackDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowFiltering: true,
              calculateCellValue: rowData => {
                return `${formatDate(rowData.examFeedbackStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')}
                                ~ ${formatDate(rowData.examFeedbackEndDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')}`;
              },
            },
            {
              caption: '시험지',
              dataField: 'preview',
              alignment: 'center', // left center right
              visible: true,
              width: 70,
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, options) => {
                let button = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'search',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.value,
                    onClick: () => {
                      vm.onExamSheetData(options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
            {
              caption: '문항',
              dataField: 'questionsCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '높음',
              dataField: 'levelUpperCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '보통',
              dataField: 'levelMiddleCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '낮음',
              dataField: 'levelLowerCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              width: 100,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              allowHeaderFiltering: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'viewFl'),
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === 'Y',
                    disabled: options.data.examProcess !== this.$_enums.ewm.examProcess.PROGRESS_READY.value ? true : false, // 대기일때만 변경가능
                    onValueChanged: () => vm.onChangeViewFl(options),
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '변경이력',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    elementAttr: { class: 'btn_XS white light_filled mr-0' },
                    text: '변경이력',
                    width: 80,
                    height: 30,
                    onClick: () => {
                      vm.onProcessStatus(options.data.scheId);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
            {
              caption: '시작',
              dataField: 'examStartDt',
              alignment: 'center',
              visible: false,
              sortOrder: 'desc',
            },
          ],
        },
      };
    },
    watch : {
      $route(to, from) {
        if(from.fullPath === '/ewm/exam/implementation/exam-schedule/config'){

        this.selectDataList();
      }
    },
    computed: {},
  },
    methods: {
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd == 'examSheetType') {
          return this.$_enums.ewm.examSheetType.values.find(e => data.examSheetType == e.value).label;
        } else if (clsCd == 'examProcess') {
          return this.$_enums.ewm.examProcess.values.find(e => data.examProcess == e.value).label;
        } else if (clsCd == 'viewFl') {
          return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl == e.value).label;
        }
      },
      /** @description: 추가 페이지 이동 이벤트 */
      onAddData() {
        let params = { updateYn: false };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/exam/implementation/exam-schedule/config' });
      },
      /** @description: 설문제목 클릭 이동 이벤트 */
      onUpdateData(data) {
        let params = { data: data, updateYn: true };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/exam/implementation/exam-schedule/config' });
      },
      /** @description: 시험지 미리보기 클릭 이동 이벤트 */
      onExamSheetData(data) {
        let params = { data: data };
        this.$store.commit('setDetailParams', params);
        this.$router.push({ path: '/ewm/exam/implementation/exam-schedule/exam-sheet' });
      },
      /** @description: 변경이력 모달 이벤트 */
      async onProcessStatus(id) {
        const payload = {
          actionName: 'EWM_EXAM_SCHE_LOG',
          path: '/' + id,
          loading: false,
          useErrorPopup: true,
        };
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.onOpenModal(
            'ModalProcessStatus',
            {
              title: '진행상태 변경이력',
              buttons: {
                cancel: { text: '닫기' },
              },
              width: 700,
              height: 520,
            },
            cloneObj(res.data.data),
          );
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 데이터 삭제 이벤트 */
      async onDeleteData() {
        const selectedRowKeys = this.$refs.examSchedule.selectedRowsData;

        if (!selectedRowKeys?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const checkExamProcessCd = selectedRowKeys.findIndex(e => e.examProcess !== this.$_enums.ewm.examProcess.PROGRESS_READY.value);
        if (checkExamProcessCd > -1) {
          this.$_Msg(`진행상태가 [대기]인 데이터만 삭제 할 수 있습니다.`);
          return;
        }

        let deletedIds = selectedRowKeys.map(d => d.scheId);

        if (await this.$_Confirm('선택한 시험지를 삭제하시겠습니까?')) {
          let payload = {
            actionName: 'EWM_EXAM_SCHE_DELETE',
            data: {
              data: {
                idList: deletedIds,
              },
            },
            loading: true,
          };
          let res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
            this.$refs.examSchedule.getInstance.refresh();
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          return false;
        }
      },

      examProcessNextStep(currentStep) {
        const currentKey = this.$_enums.ewm.examProcess.values.find(c => c.value === currentStep).key;
        const nextKey = currentKey + 1;
        const nextValue = this.$_enums.ewm.examProcess.values.find(c => c.key === nextKey).value;
        if (!nextValue) {
          return;
        }
        return nextValue;
      },

      examProcessPreviousStep(currentStep) {
        const currentIndex = this.$_enums.ewm.examProcess.values.find(c => c.value === currentStep).key;
        const previousIndex = (currentIndex - 1 + this.$_enums.ewm.examProcess.values.length) % this.$_enums.ewm.examProcess.values.length;
        return this.$_enums.ewm.examProcess.values.find(c => c.key === previousIndex).value;
      },

      /** @description : 상태 변경 */
      async onChangeProcess(options, type) {
        // 현재 시험진행상태
        const examProcessCurrentStep = options.data.examProcess;
        let changeExamProcess = '';
        if (type == 'PREV') {
          if (
            examProcessCurrentStep === this.$_enums.ewm.examProcess.COMPLETE.value &&
            !(await this.$_Confirm('시험 로그 데이터가 초기화됩니다. 변경하시겠습니까?'))
          ) {
            return;
          }
          if (
            examProcessCurrentStep === this.$_enums.ewm.examProcess.FEEDBACK_COMPLETE.value &&
            !(await this.$_Confirm('등록한 피드백정보가 초기화됩니다. 변경하시겠습니까?'))
          ) {
            return;
          }
          changeExamProcess = this.examProcessPreviousStep(examProcessCurrentStep);
        } else {
          changeExamProcess = this.examProcessNextStep(examProcessCurrentStep);
        }

        const payload = {
          actionName: 'EWM_EXAM_SCHE_UPDATE',
          data: {
            scheId: options.row.data.scheId,
            examProcess: changeExamProcess,
          },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$set(options.row.data, 'examProcess', changeExamProcess);

          options.component.repaintRows(options.rowIndex);

          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: 사용여부 전환 이벤트 */
      async onChangeViewFl(options) {
        if (options.row.data.examProcess !== this.$_enums.ewm.examProcess.PROGRESS_READY.value) return;

        const viewFl = options.value === 'Y' ? 'N' : 'Y';

        this.$set(options.row.data, 'viewFl', viewFl);

        const payload = {
          actionName: 'EWM_EXAM_SCHE_UPDATE',
          path: '/' + options.row.data.scheId + '/' + 'view-fl' + '/' + options.row.data.viewFl,
        };
        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-scheId') {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'scheId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            vm.searchType.paramsData['startDt'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['endDt'] = vm.searchType.customTypes.dayEnd;
            params = { ...params, ...vm.searchType.paramsData };
            const payload = {
              actionName: 'EWM_EXAM_SCHE_LIST',
              data: params,
              loading: false,
              useErrorPopup: true,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.examSchedule.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      onDayStartChanged(e) {
        this.searchType.customTypes.dayStart = e.value.slice(0, 6) + '01';
      },
      onDayEndChanged(e) {
        this.searchType.customTypes.dayEnd = e.value.slice(0, 6) + '29';
      },
    },
    created() {
      vm = this;
    },
    mounted() {
      this.searchType.paramsData = null;
      this.onChangeSearchDay();
      this.selectDataList();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    padding-right: 10px;
  }
</style>
