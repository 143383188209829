export const initialState = {
	user: null, // 로그인 유저 정보
	commonCode: null, // 공통코드 목록
	systemCodeList: null, // 시스템 설정 코드 목록
	routingInfo: null, // 라우팅 정보(API 목록)
	menuList: null, // 메뉴 목록
	siteList: null, // 사이트 목록
	tenantList: null, // 테넌트(센터) 목록
	holidayMap: null, // 공휴일 관리 목록(Map 객체)
	isShowMyLayerLayout: false, // FIXME : 제거 대상
	themeSetting: null, // 테마 설정
	encryptionType: null, // 암호화 타입
	favoriteMenu: null, // 즐겨찾기 메뉴 목록
	stompClient: null, // STOMP 클라이언트
	sessionId: null, // 세션 ID
};

// 상태 초기화 대상
export const resetStateKeys = [
	'user', 'commonCode', 'systemCodeList', 'menuList', 'siteList',
	'tenantList', 'holidayMap', 'isShowMyLayerLayout', 'themeSetting', 'encryptionType',
	'favoriteMenu', 'stompClient', 'sessionId',
]

export const state = { ...initialState};