import PersonalAgentCase from '@/pages/ewm/personal/agent/case/index.vue';
import PersonalAgentTime from '@/pages/ewm/personal/agent/time/index.vue';
import PersonalBoard from '@/pages/ewm/personal/board/index.vue';
import PersonalBwCall from '@/pages/ewm/personal/bw-call/index.vue';
import PersonalEducation from '@/pages/ewm/personal/education/index.vue';
import PersonalEvaluation from '@/pages/ewm/personal/evaluation/index.vue';
import PersonalEvaluationDetail from '@/pages/ewm/personal/evaluation/evaluation-detail.vue';
import PersonalExam from '@/pages/ewm/personal/exam/index.vue';
import TargetExamSheet from '@/pages/ewm/personal/exam/target-exam-sheet.vue';
import TargetExamSheetResult from '@/pages/ewm/personal/exam/target-exam-sheet-result.vue';
import TargetSheet from '@/pages/ewm/personal/exam/target-sheet.vue';
import PersonalKpiSchedule from '@/pages/ewm/personal/kpi-schedule/index.vue';
import PersonalSurvey from '@/pages/ewm/personal/survey/index.vue';
import PersonalSurveyDetail from '@/pages/ewm/personal/survey/survey-detail.vue';
import PersonalVacation from '@/pages/ewm/personal/vacation/index.vue';
import PersonalVacationDetail from '@/pages/ewm/personal/vacation/vacation-detail.vue';
import PersonalWorkSchedule from '@/pages/ewm/personal/work-schedule/index.vue';
import ScheduleDetail from '@/pages/ewm/personal/work-schedule/schedule-detail.vue';
import PersonalWorktimeEdit from '@/pages/ewm/personal/worktime-edit/index.vue';
import WorktimeDetail from '@/pages/ewm/personal/worktime-edit/worktime-detail.vue';

export default function PersonalRoutes(commonComponents) {
  return [
    // Personal -> Agent
    {
      path: '/ewm/personal/agent/case',
      name: 'PersonalAgentCase',
      components: { default: PersonalAgentCase, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/agent/time',
      name: 'PersonalAgentTime',
      components: { default: PersonalAgentTime, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Board
    {
      path: '/ewm/personal/board',
      name: 'PersonalBoard',
      components: { default: PersonalBoard, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> BW Call
    {
      path: '/ewm/personal/bw-call',
      name: 'PersonalBwCall',
      components: { default: PersonalBwCall, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Education
    {
      path: '/ewm/personal/education',
      name: 'PersonalEducation',
      components: { default: PersonalEducation, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Evaluation
    {
      path: '/ewm/personal/evaluation',
      name: 'PersonalEvaluation',
      components: { default: PersonalEvaluation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/evaluation/evaluation-detail',
      name: 'PersonalEvaluationDetail',
      components: { default: PersonalEvaluationDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Exam
    {
      path: '/ewm/personal/exam',
      name: 'PersonalExam',
      components: { default: PersonalExam, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam/target-exam-sheet',
      name: 'TargetExamSheet',
      components: { default: TargetExamSheet, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam/target-exam-sheet-result',
      name: 'TargetExamSheetResult',
      components: { default: TargetExamSheetResult, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam/target-sheet',
      name: 'TargetSheet',
      components: { default: TargetSheet, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> KPI Schedule
    {
      path: '/ewm/personal/kpi-schedule',
      name: 'PersonalKpiSchedule',
      components: { default: PersonalKpiSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Survey
    {
      path: '/ewm/personal/survey',
      name: 'PersonalSurvey',
      components: { default: PersonalSurvey, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/survey/survey-detail',
      name: 'PersonalSurveyDetail',
      components: { default: PersonalSurveyDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Vacation
    {
      path: '/ewm/personal/vacation',
      name: 'PersonalVacation',
      components: { default: PersonalVacation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/vacation/vacation-detail',
      name: 'PersonalVacationDetail',
      components: { default: PersonalVacationDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Work Schedule
    {
      path: '/ewm/personal/work-schedule',
      name: 'PersonalWorkSchedule',
      components: { default: PersonalWorkSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/work-schedule/schedule-detail',
      name: 'ScheduleDetail',
      components: { default: ScheduleDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Personal -> Worktime Edit
    {
      path: '/ewm/personal/worktime-edit',
      name: 'PersonalWorktimeEdit',
      components: { default: PersonalWorktimeEdit, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/worktime-edit/worktime-detail',
      name: 'WorktimeDetail',
      components: { default: WorktimeDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}