<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준일자</div>
              <div>
                <DxDateBox
                  styling-mode="outlined"
                  width="120"
                  height="30"
                  v-model="searchType.customTypes.dayStart"
                  type="date"
                  dateSerializationFormat="yyyyMMdd"
                  display-format="yyyy.MM.dd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  :max="searchType.customTypes.dayEnd"
                  maxLength="10"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                >
                  <DxValidator>
                    <DxRequiredRule message="기준일자는 필수입니다." />
                  </DxValidator>
                </DxDateBox>
              </div>
              <div class="ui-datepicker period">
                <span class="dash">~</span>
              </div>
              <div>
                <DxDateBox
                  styling-mode="outlined"
                  width="120"
                  height="30"
                  v-model="searchType.customTypes.dayEnd"
                  type="date"
                  dateSerializationFormat="yyyyMMdd"
                  display-format="yyyy.MM.dd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  maxLength="10"
                  :min="searchType.customTypes.dayStart"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                >
                  <DxValidator>
                    <DxRequiredRule message="기준일자는 필수입니다." />
                  </DxValidator>
                </DxDateBox>
              </div>

              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="changeCancel" />
            </div>
          </DxValidationGroup>
        </div>
      </div>

      <div class="flex gap-x-6">
        <div class="w-3/5 relative">
          <esp-dx-data-grid :ref="dataGrid.refName" :data-grid="dataGrid" @row-click="onRowClick" />
        </div>
        <div class="w-2/5 pt-14">
          <div class="border-2">
            <DxValidationGroup ref="formValidationGroup">
              <table class="table_form line-bin">
                <colgroup>
                  <col style="width: 120px" />
                  <col style="width: auto" />
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row">
                    <label for="label01">면담일자 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <DxDateBox
                      styling-mode="outlined"
                      :width="120"
                      type="date"
                      dateSerializationFormat="yyyy-MM-dd"
                      display-format="yyyy-MM-dd"
                      v-model="formData.interviewYmd"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="면담일자는 필수입니다." />
                      </DxValidator>
                    </DxDateBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">면담구분 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <DxSelectBox
                      :input-attr="{ 'aria-label': '적용부서' }"
                      :styling-mode="config.stylingMode"
                      :items="codes.interviewTypeCd.dataSource"
                      display-expr="codeNm"
                      value-expr="codeId"
                      placeholder="면담구분 선택"
                      width="100%"
                      class="mr-4"
                      v-model="formData.interviewTypeCd"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="면담구분은 필수입니다." />
                      </DxValidator>
                    </DxSelectBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">면담대상자 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <div class="flex">
                      <DxTextBox
                        :styling-mode="config.blockStylingMode"
                        class="mr-4"
                        width="40%"
                        v-model="formData.agtid"
                        :read-only="true"
                        :disabled="updateYn"
                      >
                        <DxValidator>
                          <DxRequiredRule message="면담대상자는 필수입니다." />
                        </DxValidator>
                      </DxTextBox>
                      <DxTextBox
                        :styling-mode="config.blockStylingMode"
                        class="mr-4 items-center"
                        width="40%"
                        v-model="formData.agtNm"
                        :read-only="true"
                        :disabled="updateYn"
                      >
                        <DxValidator>
                          <DxRequiredRule message="면담대상자는 필수입니다." />
                        </DxValidator>
                      </DxTextBox>
                      <DxButton icon="search" :height="30" :disabled="updateYn" @click="onOpen" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">제목 <span class="icon_require">필수항목</span></label>
                    <br />{{ formData.interviewHeader ? formData.interviewHeader.length : 0 }} /
                    {{ limitNumberTexts.maxLengths.interviewHeader }}자
                  </th>
                  <td>
                    <DxTextBox
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      v-model="formData.interviewHeader"
                      :max-length="limitNumberTexts.maxLengths.interviewHeader"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'interviewHeader')"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="제목은 필수입니다." />
                      </DxValidator>
                    </DxTextBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">내용</label>
                    <br />{{ formData.interviewDetail ? formData.interviewDetail.length : 0 }} /
                    {{ limitNumberTexts.maxLengths.interviewDetail }}자
                  </th>
                  <td>
                    <DxTextArea
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      :height="120"
                      v-model="formData.interviewDetail"
                      :max-length="limitNumberTexts.maxLengths.interviewDetail"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'interviewDetail')"
                      :disabled="updateYn"
                    >
                    </DxTextArea>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">조치사항</label>
                    <br />{{ formData.interviewFeedback ? formData.interviewFeedback.length : 0 }} /
                    {{ limitNumberTexts.maxLengths.interviewFeedback }}자
                  </th>
                  <td>
                    <DxTextArea
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      :height="120"
                      v-model="formData.interviewFeedback"
                      :max-length="limitNumberTexts.maxLengths.interviewFeedback"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'interviewFeedback')"
                      :disabled="updateYn"
                    >
                    </DxTextArea>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">면담의견</label>
                    <br />{{ formData.interviewComment ? formData.interviewComment.length : 0 }} /
                    {{ limitNumberTexts.maxLengths.interviewComment }}자
                  </th>
                  <td>
                    <DxTextArea
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      :height="120"
                      v-model="formData.interviewComment"
                      :max-length="limitNumberTexts.maxLengths.interviewComment"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'interviewComment')"
                      :disabled="updateYn"
                    >
                    </DxTextArea>
                  </td>
                </tr>
                </tbody>
              </table>
            </DxValidationGroup>
          </div>
        </div>
      </div>
      <!-- Agent Modal -->
      <modal-add-agent
        :isOpen="modal.agent.popupVisible"
        :showModalTitle="true"
        :selectedIdList="modal.agent.selectedIdList"
        :minimumSelectionLength="modal.agent.minimumSelectionLength"
        :maximumSelectionLength="modal.agent.maximumSelectionLength"
        saveBtnTxt="선택"
        @closeModal="onClose(false)"
        @saveModal="onSave"
      />
      <!-- Agent Modal -->
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import {cloneObj, formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxTextBox,
      DxSelectBox,
      DxTextArea,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      ModalAddAgent,
    },
    data() {
      return {
        updateYn: true,
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          blockStylingMode: 'filled', //outlined, underlined, filled
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          initData: {},
          contentData: null,
          componentName: '',
          agent: {
            popupVisible: false,
            selectedIdList: [],
            targetSelectedIdList: [],
            minimumSelectionLength: 0,
            maximumSelectionLength: 1,
          },
        },
        codes: {
          interviewTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            interviewHeader: 30,
            interviewDetail: 1000,
            interviewFeedback: 1000,
            interviewComment: 1000,
          },
        },
        dataGrid: {
          keyExpr: 'interviewId',
          refName: 'interviewGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true, // 행 클릭 이벤트
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: this.onAdd,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                disabled: this.updateYn,
                options: {
                  icon: '',
                  text: '저장',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick: this.onSaving,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                disabled: this.updateYn,
                options: {
                  icon: '',
                  text: '취소',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 60,
                  height: 30,
                  onClick: this.changeCancel,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: this.onDeleteData,
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '면담일자',
              dataField: 'interviewYmd',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
            },
            {
              caption: '면담구분',
              dataField: 'interviewTypeCd',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateSortValue: data => this.codes.interviewTypeCd.dataSource.find(e => data.interviewTypeCd === e.codeId).codeNm,
            },
            {
              caption: '제목',
              dataField: 'interviewHeader',
              alignment: 'left',
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              visible: true,
            },
            {
              caption: '면담자',
              dataField: 'interviewManager',
              alignment: 'center',
              visible: false,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '면담자',
              dataField: 'interviewManager2',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => {
                return `${rowData.interviewManagerAgtNm} [${rowData.interviewManager}]`;
              },
            },
            {
              caption: '면담대상자',
              dataField: 'agtid',
              alignment: 'center',
              visible: false,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtid}]`;
              },
            },
            {
              caption: '면담대상자',
              dataField: 'agtid2',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtid}]`;
              },
            },
          ],
        },
        formDataInit: {
          interviewYmd: null,
          interviewTypeCd: null,
          agtid: null,
          agtNm: null,
          interviewHeader: null,
          interviewDetail: null,
          interviewFeedback: null,
          interviewComment: null,
        },
        formData: {},
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
      formValidationGroup: function () {
        return this.$refs['formValidationGroup'].instance;
      },
    },
    methods: {
      /** @description : 추가 메서드 */
      onAdd() {
        this.updateYn = false;
        this.formData = cloneObj(this.formDataInit);
        this.modal.agent.targetSelectedIdList = [];
      },
      onRowClick(e) {
        this.$_dxUtil.setGridSingleSelection(e);

        this.updateYn = false;
        this.formData = e.data;
        this.modal.agent.targetSelectedIdList = [e.data.agtid];
      },
      /** @description : 저장 메서드 */
      async onSaving(e) {
        if (!vm.formValidationGroup.validate().isValid) {
          return false;
        }

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        const payload = {
          actionName: 'EWM_HR_INTERVIEW_SAVE',
          data: { items: [this.formData] },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.changeCancel();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 취소 메서드 */
      changeCancel() {
        vm.formValidationGroup.reset();
        this.updateYn = true;
        this.formData = cloneObj(this.formDataInit);
        this.modal.agent.targetSelectedIdList = [];
        this.selectDataList();
      },
      /** @description : 삭제 메서드 */
      async onDeleteData() {
        const selectedRowsData = this.$refs.interviewGrid.getInstance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const items = selectedRowsData.map(x => ({ interviewId: x.interviewId }));

        const payload = {
          actionName: 'EWM_HR_INTERVIEW_DELETE',
          data: { items: items },
          loading: true,
        };
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.changeCancel();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 면담대상자 오픈 메서드 */
      onOpen() {
        this.modal.agent.popupVisible = true;
        this.modal.agent.selectedIdList = this.modal.agent.targetSelectedIdList;
        this.modal.agent.maximumSelectionLength = 1;
      },
      /** @description : 면담대상자 닫기 */
      onClose() {
        this.modal.agent.popupVisible = false;
        this.modal.agent.selectedIdList = [];
      },
      /** @description : 면담대상자 저장 */
      onSave(value) {
        this.modal.agent.targetSelectedIdList = [value[0].agtid];
        this.formData.agtid = value[0].agtid;
        this.formData.agtNm = value[0].agtNm;
        this.onClose();
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        let startDt = formatDate(vm.searchType.customTypes.dayStart, 'YYYYMMDD', 'YYYY-MM-DD');
        let endDt = formatDate(vm.searchType.customTypes.dayEnd, 'YYYYMMDD', 'YYYY-MM-DD');

        const payload = {
          actionName: 'EWM_HR_INTERVIEW_SEARCH',
          data: {
            startDt: startDt,
            endDt: endDt,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
        }
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_agent_interview');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.formData = cloneObj(this.formDataInit);

        this.initCodeMap().then(() => {
          this.codes.interviewTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_agent_interview'], 2);
          this.$refs.interviewGrid.getInstance.columnOption('interviewTypeCd', 'lookup', this.codes.interviewTypeCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
