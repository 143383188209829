<!--
  PACKAGE_NAME : src/pages/euc/phone/request/items
  FILE_NAME : items.vue.vue
  AUTHOR : jhcho
  DATE : 2024-05-21
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="itemsDetailGrid" @init-new-row="onInitNewRow" @reorder="onReorder"/>
  </div>
</template>
<script>
import {isSuccess, formatDate} from '@/utils/common-lib';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    EspDxDataGrid,
  },
  data() {
    return {
      requestItems: [],
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'itemsDetailGrid',
        apiActionNm: {
          select: 'EUC_PHONE_REQUEST_TYPE_DETAIL_SELECT',
          update: 'EUC_PHONE_REQUEST_TYPE_DETAIL_UPDATE',
        },
        customEvent: {
          initNewRow: true,
          reorder: true,
        },
        dragging: {
          sortColumn: 'detailOrd',
          allowReordering: true,
          dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
        },
        columns: [
          {
            caption: '유형',
            dataField: 'typeId',
            lookup: {
              dataSource: [],
              displayExpr: 'typeNm',
              valueExpr: 'id',
            },
          },
          {
            caption: '상세 유형',
            dataField: 'detailNm',
            requiredRule: {},
          },
          {
            caption: '설명',
            dataField: 'description',
            requiredRule: {},
          },
          {
            caption: '자동화여부',
            dataField: 'autoFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
          {
            caption: '순서',
            dataField: 'detailOrd',
            visible: false,
            requiredRule: {},
          },
          {
            caption: '사용여부',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            allowEditing: false,
          },
          {
            caption: '수정일',
            i18n: 'COMPONENTS.MODIFY_DATE',
            dataField: 'editDt',
            allowEditing: false,
            calculateCellValue: data => this.formatDt(data.editDt),
          },
        ],
      },
    };
  },
  methods: {
    async onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const newOrderIndex = visibleRows[e.toIndex].data.detailOrd;

      const params = {
        id: e.itemData.id,
        detailOrd: newOrderIndex - 1,
      };

      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_DETAIL_UPDATE',
        data: [params],
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.$refs.itemsDetailGrid.refreshData();
      }
    },
    /**
     * @description 새로운 행이 초기화될 때 호출되는 메서드
     * @param {Object} data - 새로운 행 데이터
     */
    onInitNewRow(data) {
      data.data.viewFl = 'Y';
    },
    /**
     * @description 타입 데이터 조회
     * */
    async selectTypeDataList() {
      const params = {
        sort: '+typeOrd',
        viewFl: 'Y',
      };

      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_SELECT',
        data: params,
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.dataGrid.columns.find(column => column.dataField === 'typeId').lookup.dataSource = res.data.data;
      }
    },
    /** @description: 일자 데이터 출력 */
    formatDt(date) {
      if (date) {
        return formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
  async created() {
    await this.selectTypeDataList();
  },
};
</script>
<style scoped></style>