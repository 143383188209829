<template>
  <div class="content-themes">
    <div class="page-sub-box">
      <esp-dx-data-grid :data-grid="dataGrid" ref="dashboardManageGrid" @saving="onSaveData" />
    </div>
  </div>
</template>
<script>
  import { formatDate, isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    data() {
      let vm = this;
      return {
        selectedRowIndex: null,
        selectedRowData: null,
        page: {
          sizeList: [],
          size: null,
        },
        searchType: {
          paramsData: null,
        },
        dataGrid: {
          refName: 'dashboardManageGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: false, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          repaintChangesOnly: false,
          dataSource: [],
          apiActionNm: {},
          customEvent: {
            saving: true,
            reorder: true,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          dragging: {
            sortColumn: 'id',
            allowReordering: true,
            dropFeedbackMode: 'push',
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10, //page.size,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            changes: [], //null값으로 두면 에러남
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, //scrolling infinite 시 복수 선택 에러 방지
          },
          columns: [
            {
              caption: '기본여부',
              dataField: 'defaultFl', //defaultFl
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // asc desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true, // 컬럼 fix 시 사용
              cellTemplate: (container, options) => {
                let div = document.createElement('div');
                if (options.value !== 1) {
                  div.innerHTML = `-`;
                } else {
                  div.innerHTML = `기본`;
                }
                container.append(div);
              },
            },
            {
              caption: '보고서 대시보드명',
              dataField: 'dashboardTemplateNm', //dashboardId
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              width: 200,
              requiredRule: {
                messege: '보고서 대시보드명은 필수입니다.',
              },
            },
            {
              caption: '메인 설정 사용여부',
              dataField: 'delFl',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              repaintChangesOnly: true,
              fixed: true,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {
                messege: '사용여부는 필수입니다.',
              },
            },
            {
              caption: '설정',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              fixedPosition: 'left',
              cellTemplate: (container, options) => {
                const btnTag = document.createElement('button');
                btnTag.className = 'btn_XS white light_filled';
                btnTag.innerText = '설정';
                if (!Object.prototype.hasOwnProperty.call(options.data, 'id')) {
                  btnTag.disabled = true;
                }
                const route = {
                  name: 'EditDashboard',
                  params: { templateId: options.data.id },
                };
                btnTag.addEventListener('click', () => {
                  this.$router.push(route);
                });
                container.append(btnTag);
              },
            },
            {
              caption: '수정자',
              dataField: 'editId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '수정 일시',
              dataField: 'editDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              calculateCellValue: this.editDt,
              cellTemplate: (container, options) => {
                let div = document.createElement('div');
                if (options.value !== 'Invalid date') {
                  div.innerHTML = `${options.value.split(' ')[0]}
                                ${options.value.split(' ')[1]}`;
                }
                container.append(div);
              },
            },
            {
              caption: '등록자',
              dataField: 'regId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '등록 일시',
              dataField: 'regDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              calculateCellValue: this.regDt,
              cellTemplate: (container, options) => {
                let div = document.createElement('div');
                if (options.value !== 'Invalid date') {
                  div.innerHTML = `${options.value.split(' ')[0]}
                                 ${options.value.split(' ')[1]}`;
                }
                container.append(div);
              },
            },
          ],
        },
        stylingMode: 'outlined', //outlined, underlined, filled
      };
    },
    methods: {
      /** @description : 그리드 순서 변경 이벤트 */
      async onReorder(e) {
        const visibleRows = e.component.getVisibleRows();

        if (this.dataGrid.dragging && this.dataGrid.dragging.sortColumn) {
          const newOrderIndex = visibleRows[e.toIndex].data[this.dataGrid.dragging.sortColumn];
          let updateData = {};
          const key = this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id';
          updateData[key] = e.itemData[key];
          updateData[this.dataGrid.dragging.sortColumn] = newOrderIndex;

          let payload = {
            actionName: 'DASHBOARD_MANAGE_LIST_UPDATE',
            data: { data: [updateData] },
            loading: true,
          };

          let res = await this.CALL_REPORT_API(payload);
          if (res.status == '200') {
            if (res.data.header.resCode === 'success') {
              this.$_Toast('저장되었습니다.');
              await e.component.refresh();
            } else {
              this.$_Msg('저장 실패');
            }
          } else {
            this.$_Msg('저장 실패');
          }
        }
      },
      /** @description: 데이터 조회 메서드 */
      async selectDataList(sort = '-regDt') {
        let vm = this;

        vm.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);

            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...vm.searchType.paramsData };

            let payload = {
              actionName: 'DASHBOARD_MANAGE_LIST_ALL',
              data: params,
              loading: false,
              useErrorPopup: true,
            };

            let res = await vm.CALL_REPORT_API(payload);

            if (isSuccess(res)) {
              return res.data.data;
            } else {
              vm.$_Toast('서버 연결에 실패 하였습니다.');
            }
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          // totalCount: opts => {
          //   return new Promise((resolve, reject) => {
          //     resolve(0);
          //   });
          // },
        });
      },
      /** @description: 그리드 리스트 refresh 메서드 */
      refreshDashboardManageGridList() {
        this.$refs.dashboardManageGrid.refreshData();
      },
      /** @description: 데이터 저장 메서드 */
      async onSaveData(e) {
        e.cancel = true;
        if (e.changes.length) {
          let data = [];
          if (e.changes && e.changes.length > 0) {
            for (let d of e.changes) {
              if (d.type === 'update') {
                let updateData = { ...d.data };
                updateData[this.dataGrid.keyExpr] = d.key;
                data.push(updateData);
              } else if (d.type === 'insert') {
                if (Object.keys(d.data).length !== 0) {
                  if (this.dataGrid.keyExpr) {
                    let updateData = { ...d.data };
                    updateData[this.dataGrid.keyExpr] = null;
                    updateData['type'] = d.type;
                    updateData['id'] = null;
                    data.push(updateData);
                  } else {
                    data.push({ id: null, ...d.data });
                  }
                }
              }
            }
          }

          let payload = {
            actionName: 'DASHBOARD_MANAGE_LIST_UPDATE',
            data: { data },
            loading: true,
          };

          let res = await this.CALL_REPORT_API(payload);

          if (res.status == '200') {
            if (res.data.header.resCode === 'success') {
              this.refreshDashboardManageGridList();
              e.component.cancelEditData();
            } else {
              this.$_Msg('저장 실패');
              e.component.cancelEditData();
            }
          } else {
            this.$_Msg('저장 실패');
            e.component.cancelEditData();
          }
        }
      },
      /** @description: 데이터 삭제 메서드 */
      async onDeleteData() {
        if (this.$refs.dashboardManageGrid.selectedRowsData.length === 0) {
          this.$_Msg('삭제할 데이터를 선택해주세요.');
          return false;
        }

        let deletedIds = this.$refs.dashboardManageGrid.selectedRowsData.map(d => {
          return { id: d.id };
        }); //삭제할 데이터

        if (await this.$_Confirm('선택한 데이터를 삭제하시겠습니까?')) {
          let payload = {
            actionName: 'DASHBOARD_MANAGE_LIST_DELETE',
            data: { data: deletedIds },
            loading: true,
          };

          let res = await this.CALL_REPORT_API(payload);

          if (res.status == '200') {
            if (res.data.header.resCode === 'success') {
              if (await this.$_Toast('데이터가 삭제되었습니다.')) {
                this.createdData();
              }
            } else {
              this.$_Msg('삭제 실패');
            }
          } else {
            this.$_Msg('삭제 실패');
          }
        } else {
          return false;
        }
      },
      /** @description: 라이프 사이클 created시 호출되는 메서드 */
      createdData() {
        this.selectDataList();
      },
      regDt(rowData) {
        return formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },
      editDt(rowData) {
        return formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },
    },
    created() {
      this.createdData();
    },
  };
</script>

<style scoped>
  .search-top-wrap > div {
    display: inline-block;
  }
  .search-top-wrap > div:not(:last-child) {
    margin-right: 5px;
  }
  .search-top-wrap > div:first-child {
    margin-right: 20px;
  }
  .search-bottom-bottom-wrap > div {
    display: inline-block;
  }
  .search-bottom-bottom-wrap .search-bottom-left-wrap > div {
    display: inline-block;
  }
  .search-bottom-bottom-wrap .search-bottom-left-wrap > div:not(:first-child) {
    margin-left: 5px;
  }

  .search-bottom-bottom-wrap .search-bottom-right-wrap {
    position: absolute;
    right: 0;
  }
  .search-bottom-bottom-wrap .search-bottom-right-wrap .batch-btn {
    height: 30px;
    padding: 0 20px;
    display: inline-block;
    border: 1px solid gray;
    border-radius: 3px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
  }
</style>

<style>
  .container .dx-pager .dx-pages {
    text-align: center;
    float: none;
  }
</style>
