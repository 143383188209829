<template>
  <div class="container">
    <div class="page-sub-box tree-box tree-header mar_b10">
      <div class="head-btn-box01 top-toolbar-box">
        <div class="head-btn-left">
          <DxButton
              template="<span class='mdi mdi-folder-open'></span>"
              type="button"
              :hint="this.$_lang('COMPONENTS.EXPAND_LIST')"
              class="btn_XS white outlined"
              :height="30"
              @click="onOpenTree"
          />
          <DxButton
              template="<span class='mdi mdi-folder'></span>"
              type="button"
              :hint="this.$_lang('COMPONENTS.COLLAPSE_LIST')"
              class="btn_XS white outlined"
              :height="30"
              @click="onFoldTree"
          />
          <DxButton
              :text="this.$_lang('COMPONENTS.ADD')"
              type="button"
              class="btn_XS default filled"
              :height="30"
              @click="isOpenModal('add', true, null)"
          />
        </div>
      </div>
    </div>

    <div class="page-sub-box tree-box tree-contents">
      <div class="layout-cut-box clearfix tree-box00">
        <div class="layout-cut-left tree-box01">
          <!--트리메뉴 영역 정의-->
          <!-- <div class="treemenu-set"> -->
          <DxTreeList
              id="deptList"
              ref="deptList"
              :data-source="tree.deptList"
              :root-value="-1"
              :expanded-row-keys="tree.expandedRowKeys"
              :selected-row-keys="tree.selectedRowkeys"
              :focused-row-key="tree.focusedRowKey"
              :auto-expand-all="true"
              :show-column-headers="true"
              :show-row-lines="true"
              :show-column-lines="false"
              :show-borders="true"
              :column-auto-width="true"
              key-expr="id"
              parent-id-expr="parentId"
              @selection-changed="selectionChangedData"
              :on-row-click="onClickRow"
              :no-data-text="noDataText(tree.deptList.length)"
              :width="500"
              :height="600"
          >
            <DxSearchPanel
                :placeholder="this.$_lang('UC.WORD.DEPT_NAME_SEARCH')"
                :visible="true"
                :searchVisibleColumnsOnly="true"
                :width="300"
            />

            <DxSelection :recursive="tree.recursive" :mode="tree.mode"/>

            <DxScrolling mode="virtual"/>

            <DxColumn caption="KB증권" data-field="deptNm" cell-template="deptNmTemplate" :allow-sorting="false"/>

            <template #deptNmTemplate="{ data }">
              {{ data.value }} {{ data.data.viewFl === 'N' ? '(' + $_lang('UC.WORD.UNUSED') + ')' : '' }}
            </template>

            <DxColumn
                caption="부서코드"
                i18n="UC.WORD.DEPT_CODE"
                data-field="deptCode"
                cell-template="deptCodeTemplate"
                alignment="center"
                :visible="true"
                :width="100"
                :allow-sorting="false"
            />
            <template #deptCodeTemplate="{ data }">
              <a style="cursor: pointer" @click="isOpenModal('info', true, data)">
                <span style="color: blue; text-decoration: underline">{{ data.value }}</span>
              </a>
            </template>
            <DxColumn
                caption="코드개수"
                data-field="count"
                cell-template="countTemplate"
                alignment="center"
                :visible="true"
                :width="100"
                :allow-sorting="false"
            />
            <template #countTemplate="{ data }">
              <span>{{ data.value }}</span>
            </template>
          </DxTreeList>
        </div>
      </div>
      <DxCheckBox
          class="checkbox"
          v-model="config.isViewFl"
          :text="this.$_lang('UC.WORD.VIEW_DEPT_USE')"
          @value-changed="isUsedFlag"
          :height="30"
      />
    </div>
    <DxPopup
        v-model="modal.add.isOpened"
        :show-title="true"
        :title="modal.add.title"
        :width="modal.add.width"
        :height="modal.add.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.add.isOpened"
        @hiding="isOpenModal('add', false)"
    >
      <ModalAddPickupDept
          :isOpened="this.modal.add.isOpened"
          :cmList="this.cmList"
          :partitionList="this.partitionList"
          :contentData="this.modal.add.contentData"
          @closeModal="closeModal('add')"
      />
    </DxPopup>
    <DxPopup
        v-model="modal.info.isOpened"
        :show-title="true"
        :title="modal.info.title"
        :width="modal.info.width"
        :height="modal.info.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.info.isOpened"
        @hiding="isOpenModal('info', false)"
    >
      <ModalInfoPickup
          :isOpened="this.modal.info.isOpened"
          :rowInfo="this.rowInfo"
          :contentData="this.modal.info.contentData"
          :cmList="this.cmList"
          :dataType="this.dataType"
          :type="this.type"
          :partitionList="this.partitionList"
          @closeModal="closeModal('info')"
      />
    </DxPopup>
  </div>
</template>

<script>
import ModalAddPickupDept from './modal-add-pickup-dept.vue';
import ModalInfoPickup from './modal-info-pickup.vue';
import {
  DxTreeList,
  DxSearchPanel,
  DxSelection,
  DxColumn,
  DxScrolling,
} from 'devextreme-vue/tree-list';
import {DxButton} from 'devextreme-vue/button';
import {DxCheckBox} from 'devextreme-vue/check-box';
import {DxPopup} from 'devextreme-vue/popup';

import {isSuccess} from '@/utils/common-lib';

export default {
  components: {
    ModalAddPickupDept,
    ModalInfoPickup,
    DxTreeList,
    DxSearchPanel,
    DxSelection,
    DxColumn,
    DxScrolling,
    DxButton,
    DxCheckBox,
    DxPopup,
  },
  props: {
    cmList: {
      type: Array,
    },
    partitionList: {
      type: Array,
    },
  },
  data() {
    return {
      config: {
        pageSetting: {
          config: {},
          formData: {},
        },
        stylingMode: 'outlined', //[outlined, filled, underlined]
        isViewFl: true,
      },
      modal: {
        info: {
          title: this.$_lang('UC.WORD.DEPT_PICKUP_INFO'),
          width: '50%',
          height: '750',
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        add: {
          title: this.$_lang('UC.WORD.CREATE_DEPT_PICKUP'),
          width: '70%',
          height: '650',
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
      },
      rowInfo: {},
      type: 'DEPT',
      dataType: 'CODE',
      tree: {
        originDeptList: [],
        deptList: [],
        sortList: [],
        expandedRowKeys: [],
        selectedRowkeys: [],
        focusedRowKey: null,
        selectedRowsData: [],
        recursive: false,
        mode: 'multiple',
        selectionMode: 'all', // 'all' or 'excludeRecursive' or 'leavesOnly'
        allowDropInsideItem: false,
        allowReordering: true,
        showDragIcons: true,
        selectedData: null,
      },
      formData: {
        data: {},
        limitLength: {
          deptNm: 35,
        },
        iconData: [],
      },
    };
  },
  computed: {
    /** @description: 상위부서 값 가져오기 */
    nameTree() {
      let topDeptTree = '-';
      const isCheckLineBar = this.formData.data.nameTree.includes('|');
      if (isCheckLineBar) {
        const lastIndex = this.formData.data.nameTree.lastIndexOf('|');
        topDeptTree = this.formData.data.nameTree.substring(0, lastIndex).replaceAll('|', ' > ');
      }
      return topDeptTree;
    },
  },
  methods: {
    /** @description: 팝업 오픈시 메서드 */
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.initData = componentInitData; //set init modal templet
      this.modal.contentData = data;

      if (this.modal.initData.name === 'subDept') {
        if (!this.tree.focusedRowKey) {
          return this.$_Msg(this.$_lang('REQUIRED_PARENT_DEPT'));
        }

        if (data?.deptDepth > 4) {
          return this.$_Msg(this.$_lang('CHECK_DEPT_DEPTH'));
        }
      }
    },
    /** @description: 트리 펼치기 */
    onOpenTree() {
      this.tree.expandedRowKeys = this.tree.deptList.map(d => {
        return d.id;
      });
    },
    /** @description: 트리 접기 */
    onFoldTree() {
      this.tree.expandedRowKeys = [];
    },
    /** @description: 사용중인 부서만 보기 체크 */
    isUsedFlag(e) {
      if (e.value) {
        this.tree.deptList = this.tree.deptList.filter(d => d.viewFl === 'Y');
      } else {
        this.tree.deptList = this.tree.originDeptList;
      }
    },
    /** @description: 트리메뉴 변경 이벤트 */
    selectionChangedData(e) {
      this.tree.selectedRowsData = e.component.getSelectedRowsData(this.tree.selectionMode);
    },
    /** @description : 트리 로우 데이터 클릭 이벤트 */
    onClickRow(e) {
      //단일 선택 색상 설정
      this.$_dxUtil.setGridSingleSelection(e);

      let rowData = e.data;

      if (rowData) {
        this.formData.data = rowData;
        this.tree.focusedRowKey = rowData.id;
      }
    },
    async selectDept(params) {
      const payload = {
        actionName: 'EUC_PICKUPGROUPDEPT_SELECT',
        data: {
          sort: '+depth,+deptOrd',
          ...params
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        //기존 테이블과 view 테이블의 PK 컬럼명이 달라 id로 맞춰줌
        const data = res.data.data.map(d => {
          return {
            ...d,
            id: d.deptId,
          }
        });
        this.tree.originDeptList = data; //all Data
        this.tree.deptList = data?.filter(d => d.viewFl === 'Y'); //view filter data
      }
    },
    /**@description 모달 오픈 메서드 */
    async isOpenModal(type, flag, data) {
      const selectedRowsData = this.tree.selectedRowsData;

      switch (type) {
        case 'add':
          if (selectedRowsData.length === 0) {
            return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
          }
          this.modal[type].contentData = selectedRowsData;
          this.modal[type].isOpened = flag;
          break;
        case 'info':
          if (flag) {
            this.modal[type].contentData = data?.data || null;
          }
          this.modal[type].isOpened = flag;
          break;
        default:
          break;
      }
    },
    /**@description 모달 클로즈 메서드 */
    closeModal(type) {
      this.modal[type].contentData = null;
      this.modal[type].isOpened = false;
    },
    /** @description: 데이터 초기화 */
    clearData() {
      this.tree.focusedRowKey = null;
      this.tree.selectedRowkeys = [];
      this.formData.data = {};
    },
    /** @description: treeList refresh 이벤트 */
    refreshTreeList() {
      if (this.$refs.deptList) {
        this.$refs.deptList.instance.refresh();
        this.$refs.deptList.instance.deselectAll(); //선택 해제
      }
    },
    /** @description: 부서 데이터 없을 경우 출력 메서드 */
    noDataText(length) {
      if (length === 0) {
        return '추가된 부서가 없습니다.';
      }
    },
  },
  created() {
    this.clearData(); //데이터 초기화
    this.selectDept();
    this.refreshTreeList(); //트리리스트 refresh
  },
};
</script>
<style scoped>
.top-toolbar-box {
  margin: 0;
}
</style>
<style>
/** 트리리스트 검색 패널 관련 */
#deptList .dx-toolbar .dx-toolbar-after {
  margin: 0 auto;
  padding-left: 0;
  left: 0;
  right: 0;
}

#deptList .dx-placeholder {
  padding-left: 20px;
}

#deptList .dx-treelist-search-panel {
  margin: 0;
}

#deptList .dx-toolbar-after {
  padding: 0;
}

#deptList .dx-treelist-header-panel {
  padding: 0;
  width: 200px;
}
</style>
