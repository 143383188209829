<!--
  PACKAGE_NAME : src\pages\esp\history\work.vue
  FILE_NAME : work
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 작업이력
-->
<template>
  <div>
    <div class="page-sub-box">
      <div class="page_search_box line_bottom_1px">
        <div class="inner alL">
          <DateRangeBox ref="dateRangeBox" label="검색 일자" :start-dt="dayStart" :end-dt="dayEnd" />
          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>

    <DxPopup
      :title="codeDiff.title"
      :show-close-button="true"
      :hide-on-outside-click="true"
      :width="800"
      :height="450"
      :visible="codeDiff.visible"
      @hiding="isOpenModal(false, null)"
    >
      <template #default>
        <div style="height: 100%; width: 100%">
          <CodeDiff
            :old-string="codeDiff.preContent"
            :new-string="codeDiff.content"
            diff-style="word"
            :context="10"
            output-format="side-by-side"
            max-height="100%"
            language="json"
          />
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import { getPastFromToday, isSuccess } from '@/utils/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import moment from 'moment';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import CodeDiff from '@/plugins/code-diff';
  import { DxPopup } from 'devextreme-vue/popup';

  export default {
    components: { DxPopup, EspDxDataGrid, DateRangeBox, DxButton, CodeDiff },
    data() {
      return {
        dayStart: getPastFromToday(7, 'days'),
        dayEnd: moment().format('YYYYMMDD'),
        codeDiff: {
          title: '변경 내용',
          visible: false,
          preContent: '',
          content: '',
        },
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'refWorkHistoryDataGrid', // 그리드 컴포넌트 참조명
          excel: {
            title: '작업이력', // 엑셀 다운로드 시 파일명
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: true, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true, // 페이징 사용 유무
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: true, // 페이지 정보 표시 여부
            showNavigationButtons: true, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'none', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            // TODO : 김병윤 과장, 메뉴명은 기존 3뎁스에서 더 추가되어 보여야 하므로 수정필요(현재는 임시조치)
            {
              caption: '메뉴명',
              dataField: 'menuNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '액션명',
              dataField: 'actionNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '경로',
              dataField: 'path',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '구분',
              dataField: 'method',
              alignment: 'center',
              width: 100,
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '사용자ID',
              dataField: 'loginId',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '사용자명',
              dataField: 'loginNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              caption: '등록일시',
              dataField: 'eventDt',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '변경데이터',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const button = new DxButton({
                  propsData: {
                    elementAttr: { class: 'btn_XS white light_filled mr-0' },
                    text: '상세보기',
                    width: 80,
                    height: 30,
                    onClick: () => {
                      this.isOpenModal(true, options.data);
                    },
                  },
                });

                button.$mount();
                container.append(button.$el);
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    methods: {
      /**
       * JSON 문자열을 파싱하여 객체로 변환
       *
       * @param jsonString
       * @return {*}
       */
      parsedJson(jsonString) {
        try {
          return JSON.parse(jsonString);
        } catch (e) {
          // JSON 파싱에 실패하면 원본 문자열을 반환
          console.error('JSON 파싱 실패:', e);
          return jsonString;
        }
      },
      /**
       * 그리드 검색 이벤트
       * @return {Promise<{data, totalCount}>}
       */
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = '-id';
            }
            params = { ...params };

            const payload = {
              actionName: 'WORK_HISTORY_LIST',
              data: {
                ...params,
                eventDt: vm.$refs.dateRangeBox.getStringDateTime(),
              },
              loading: false,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.refWorkHistoryDataGrid.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              vm.$_Toast(vm.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
          },
        });
      },
      isOpenModal(visible, data) {
        if (data !== null) {
          this.codeDiff.title = `변경정보(${data.menuNm})`;
          this.codeDiff.preContent = JSON.stringify(this.parsedJson(data.preContent), null, 2);
          this.codeDiff.content = JSON.stringify(this.parsedJson(data.content), null, 2);
        }
        this.codeDiff.visible = visible;
      },
    },
    created() {},
    mounted() {
      this.onSearch();
    },
  };
</script>

<style lang="scss" scoped></style>