import Index from '@/pages/report/bi/index';

export default function BiRoutes(commonComponents) {
  return [
    {
      path: '/report/bi/index',
      name: 'Index',
      components: { default: Index, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
