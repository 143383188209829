<!--
  PACKAGE_NAME : src\pages\esp\auth\modal-add-member.vue
  FILE_NAME : modal-add-member
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 권한 사용자 추가 모달
-->
<template>
  <div>
    <esp-dx-modal-popup
      :option="{
        title: '권한 추가',
        width: '50vw',
        height: 'calc(100vh - 300px)',
        minWidth: '50vw',
        minHeight: 'calc(100vh - 300px)',
      }"
      :isOpen="popupVisible"
      @closeModal="onClose(false)"
      @saveModal="onSave"
    >
      <template #content>
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
      </template>
    </esp-dx-modal-popup>
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import EspDxModalPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import { isSuccess } from '../../../utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';

  export default {
    components: { EspDxModalPopup, EspDxDataGrid },
    data() {
      return {
        rowData: '',
        popupVisible: false, // Popup 표시여부
        selectionAuthId: null, // 선택된 권한 ID
        dataGrid: {
          refName: 'addMemberGrid',
          showBorders: false, //border 유무
          showColumnLines: false, //컬럼 세로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: 'calc(100vh - 476px)', // 주석처리시 100%
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
          },
          editing: {
            allowUpdating: false,
            allowAdding: false,
          },
          columns: [
            {
              caption: '이름',
              dataField: 'loginNm',
            },
            {
              caption: '아이디',
              dataField: 'loginId',
            },
            {
              caption: '권한',
              dataField: 'authNm',
            },
            {
              caption: '계정 상태',
              dataField: 'memberState',
              lookup: {
                dataSource: this.$_enums.common.memberState.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    methods: {
      /**
       * @description 모달 오픈 이벤트
       * @param authId {number} 권한 ID
       */
      async handleOpenModal(authId = null) {
        this.popupVisible = true;
        this.selectionAuthId = authId;
        await this.getMember(authId);
      },
      /**
       * @description 모달 닫기 이벤트
       * @param isVisible {boolean} 모달 표시 여부
       */
      onClose(isVisible) {
        this.popupVisible = isVisible;
      },
      /**
       * @description 저장 이벤트
       */
      async onSave() {
        const selectedRowsData = this.$refs.addMemberGrid.getInstance.getSelectedRowsData();
        if (selectedRowsData.length <= 0) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        const res = await this.saveMember(selectedRowsData);
        if (isSuccess(res)) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.popupVisible = false;
          this.dataGrid.dataSource = [];
          this.$emit('saveAuthMemberModal');
        }
      },
      /**
       * @description 사용자 추가
       * @param selectedRowsData
       * @return {Promise<*|e.Response<*, Record<string, *>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<*>>}
       */
      async saveMember(selectedRowsData) {
        const payload = {
          actionName: 'MEMBER_LIST_INSERT',
          data: selectedRowsData.map(member => {
            return {
              id: member.id,
              authId: this.selectionAuthId,
              loginId: member.loginId,
            };
          }),
          useErrorPopup: true,
        };

        return await this.CALL_API(payload);
      },
      /**
       * @description 사용자 리스트 조회
       * @param authId {string} 권한 ID
       */
      async getMember(authId = null) {
        if (authId === null) {
          this.dataGrid.dataSource = [];
          return;
        }

        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            const payload = {
              actionName: 'MEMBER_LIST_ALL',
              data: {
                ...params,
                authId: `<>${vm.selectionAuthId}`, // 선택된 권한 사용자 제외
                excludeAuthIdFL: vm.$_enums.common.stringUsedFlag.YES.value, // 사용자 권한 검색 제외
                memberStateCd: `<>${vm.$_enums.common.memberState.DELETED.value}`, // 삭제된 계정 제외
                authIdIsNullFl: vm.$_enums.common.stringUsedFlag.YES.value, // 권한 없는 사람도 조회
              },
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
    },
  };
</script>

<style scoped></style>
