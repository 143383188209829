<template>
  <div id="sideBar" :class="isShow ? 'w-1/5' : 'hidden'">
    <!-- 버튼[보고서추가, 그룹추가] -->
    <div class="mt-5 mb-1.5">
      <DxButton text="보고서" class="btn_XS default filled add1" type="button" :height="30" @click="openAddReportModal" />
      <DxButton text="그룹 추가" class="btn_XS white light_filled" :height="30" @click="openAddCategoryModal" />
    </div>
    <!-- BI 보고서 목록 -->
    <DxTreeList
      id="bi-report-tree"
      ref="biMenuTreeList"
      key-expr="nodeId"
      parent-id-expr="parentId"
      display-expr="menuNm"
      :root-value="-1"
      :data-source="biMenu.list"
      :show-borders="true"
      :show-column-lines="false"
      :column-auto-width="true"
      :show-column-headers="true"
      :columns="biMenu.columns"
      :auto-expand-all="true"
      height="calc(100vh - 220px)"
      width="100%"
      :selectedRowKeys="selectedRowKeys"
      @selection-changed="handleSelectChangedReport"
      @cell-click="handleClickBiMenuTreeList"
    >
      <DxSorting mode="none" />
      <DxSelection mode="single" />
    </DxTreeList>
    <!-- 컨텍스트 메뉴 -->
    <DxContextMenu
      :ref="biMenu.contextRef"
      :data-source="biMenu.contexts"
      showEvent="dxclick"
      target="#bi-report-tree .more_icon"
      @item-click="handleClickContextItem"
    />

    <!-- 보고서/그룹 추가 및 수정 팝업 -->
    <component
      :is="biMenu.popup.componentNm"
      :isOpen="biMenu.popup.show"
      :option="biMenu.popup.options"
      @saveModal="processContextMenuByType"
      @closeModal="closeBiMenuModal"
    >
      <template #content>
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 90px" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <!-- 그룹 선택은 "보고서 추가" 일 시 에만 사용 -->
            <tr v-if="isAddReportPopup">
              <th class="th-bold">그룹선택*</th>
              <td>
                <DxSelectBox
                  placeholder="그룹 선택"
                  :items="biMenu.categories"
                  display-expr="name"
                  v-model="biMenu.popup.selectedGroup"
                  value-expr="id"
                  width="200px"
                  :styling-mode="stylingMode"
                  :height="30"
                >
                  <DxValidator ref="validGroupNm">
                    <DxRequiredRule message="데이터를 선택해주세요." />
                  </DxValidator>
                </DxSelectBox>
              </td>
            </tr>
            <!-- 항상 보이는 영역이며, 보고서 추가: "보고서명" | 그룹 추가: "그룹명" -->
            <tr>
              <th class="th-bold">{{ biMenu.popup.menuLabel }}*</th>
              <td>
                <DxTextBox width="100%" :placeholder="biMenu.popup.menuLabel" :styling-mode="stylingMode" v-model="biMenu.popup.menuNm">
                  <DxValidator ref="validMenuNm">
                    <DxRequiredRule message="데이터를 입력해주세요." />
                  </DxValidator>
                </DxTextBox>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </component>

    <!--	보고서 전달 유저	-->
    <DxPopup
      :show-title="true"
      :title="modal.initData?.title || null"
      :min-width="modal.initData?.width || null"
      :width="modal.initData?.width || null"
      :min-height="modal.initData?.height || null"
      :height="modal.initData?.height || null"
      :drag-enabled="true"
      :resize-enabled="false"
      :show-close-button="true"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      :isOpen="modal.isOpened"
      @hiding="handleToggleSendModal(false)"
    >
      <template #content>
        <component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData" />
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal?.initData?.buttons?.save !== undefined"
        :options="{
          elementAttr: { class: 'default filled txt_S medium' },
          text: modal?.initData?.buttons?.save?.text || '',
          useSubmitBehavior: true,
          validationGroup: 'modalValidationGroup',
          width: '120',
          height: '40',
          onClick: e => handleSaveSendReport(e, modal.sendData),
        }"
      />

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="!!modal?.initData?.buttons?.cancel"
        :options="{
          elementAttr: { class: 'white filled txt_S medium' },
          text: modal?.initData?.buttons?.cancel?.text || '',
          width: '120',
          height: '40',
          onClick: () => handleToggleSendModal(false),
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
  import { DxColumn, DxSearchPanel, DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxButton } from 'devextreme-vue/button';
  import DxContextMenu from 'devextreme-vue/context-menu';
  import { DxFilterRow, DxPaging, DxScrolling, DxSorting } from 'devextreme-vue/data-grid';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import UrlDateTable from '@/pages/report/bi/url-date-table.vue';
  import SearchBox from '@/components/report/search-box.vue';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import DxSwitch from 'devextreme-vue/switch';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import DxTreeView from 'devextreme-vue/tree-view';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import CustomDxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import ModalShareMember from '@/pages/report/bi/modal-share-member.vue';
  import { getResData, isEmpty, isSuccess } from '@/utils/common-lib';

  export default {
    components: {
      DateRangeBox,
      UrlDateTable,
      SearchBox,
      DxSearchPanel,
      DxDropDownBox,
      DxSwitch,
      DxColumn,
      DxPaging,
      DxScrolling,
      DxFilterRow,
      DxSorting,
      DxSelection,
      DxTreeList,
      DxToolbarItem,
      DxButton,
      DxTextBox,
      DxRequiredRule,
      DxValidator,
      DxTreeView,
      DxDateBox,
      DxSelectBox,
      DxPopup,
      CustomDxPopup,
      DxContextMenu,
      ModalShareMember,
    },
    props: {
      getJsonWorkbook: {
        type: Function,
        default: () => {},
      },
      isShow: {
        type: Boolean,
        default: true,
      },
      getTableNames: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        stylingMode: 'outlined', // dev-style
        biMenu: {
          categories: [], // 카테고리
          list: [], // 카테고리 + 보고서 목록
          columns: [
            { dataField: 'menuNm', caption: 'BI보고서 목록', width: '80%' },
            {
              dataField: '',
              caption: '',
              width: '20%',
              cellTemplate: (container, options) => {
                if (options.data.menuDepth < 3) return;
                let div = document.createElement('div');
                div.innerHTML = `<pre> <button class="more_icon btn-icon more" style="float:right;" /></pre>`;
                container.append(div);
              },
            },
          ],
          selectedItem: null,
          contextRef: 'biMenuContext',
          contexts: [
            { id: 'delete-group', text: '그룹 삭제' },
            { id: 'edit-group-name', text: '그룹명 수정' },
            { id: 'add-report', text: '보고서 추가' },
            { id: 'copy-report', text: '보고서 복사' },
            { id: 'delete-report', text: '보고서 삭제' },
            { id: 'share-report', text: '보고서 전달' },
            { id: 'edit-report-name', text: '보고서 수정' },
          ],
          // 그룹 or 보고서
          popup: {
            key: 0, // 모달 초기화를 위해 사용
            componentNm: null,
            show: false,
            type: 'add-report', // add-group, add-report, update-group, update-report
            groupNm: '',
            menuNm: '',
            menuLabel: '',
            selectedGroup: null,
            options: {
              title: '보고서 추가',
              width: '500',
              height: '250',
              minWidth: null,
              minHeight: null,
              useSaveBtn: true,
              useCancelBtn: true,
            },
          },
        },
        selectedRowKeys: [],
        previousSelectedKeys: [],
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
      };
    },
    computed: {
      contextMenuInstance() {
        return this.$refs[this.biMenu.contextRef].instance;
      },
      biMenuInstance() {
        return this.$refs.biMenuTreeList.instance;
      },
      /**
       * [보고서 추가] 또는 [그룹 추가] 버튼 클릭 시
       * "그룹 선택" selectbox 보이기 여부를 체크하기 위한 함수
       * @returns {boolean}
       */
      isAddReportPopup() {
        return this.biMenu.popup.type.indexOf('report') > -1;
      },
    },
    methods: {
      /**
       * BI 보고서 전달 API
       * @param selectedRowsData
       * @returns {Promise<void>}
       */
      async fetchShareBiReport(selectedRowsData) {
        let userList = selectedRowsData.map(item => item.loginNm);
        const { id: reportId, menuNm } = this.biMenu.selectedItem;

        const strUsers = userList.join(', ');
        let msg = `<pre><span style="font-weight: bold;text-align: center;font-size:15px;">[${menuNm}]</span> 보고서 전달 \n`;
        let userMsg = `[${strUsers}] 님에게`;
        if (selectedRowsData.length > 1) userMsg = `[${userList[0]}]님 외 ${userList.length - 1} 명에게`;
        msg += `${userMsg} 보고서를 전달하시겠습니까?</pre>`;

        if (await this.$_Confirm(msg)) {
          const memberList = selectedRowsData.map(row => row.loginId);
          const payload = { memberList: memberList, id: reportId, name: menuNm, tableList: this.getTableNames() };
          const res = await this.CALL_REPORT_API({
            actionName: 'BI_REPORT_SHARE',
            data: {
              data: payload,
              workLog: {
                content: payload,
                preContent: null,
              },
            },
            loading: true,
          });

          const toastMsgCode = isSuccess(res) ? 'CMN_SUC_SAVE' : 'CMN_ERROR';
          this.$_Toast(this.$_lang(toastMsgCode));
        }
      },

      /** 공유하기 모달 저장 버튼 클릭 시 실행 */
      async handleSaveSendReport(e, sendData) {
        //해당 모달 컴포넌트에서 데이터 저장
        const res = await new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${sendData.modal}:onSaveData`, e, resolve, reject);
        });

        try {
          if (res.status === 200 && sendData.modal === 'ModalShareMember') {
            await this.fetchShareBiReport(res.selectedRowsData);
          }
        } catch (e) {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },

      /** BI 그룹 추가 */
      async saveCategory() {
        if (this.$refs.validMenuNm.instance.validate().isValid === false) {
          return;
        }

        const payload = { categoryNm: this.biMenu.popup.menuNm };
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_CATEGORY_INSERT',
          data: {
            data: payload,
            workLog: {
              content: payload,
              preContent: null,
            },
          },
          loading: true,
        });

        if (isSuccess(res)) {
          await this.initBiMenu();
          this.closeBiMenuModal();
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE'));
        }
        // 오류 발생 시
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },

      /** BI 그룹명 수정 */
      async updateCategoryName() {
        const name = this.biMenu.popup.menuNm;
        if (name === '') return this.$_Msg('변경하려는 그룹 명칭을 입력해야합니다.');
        if (this.biMenu.selectedItem.menuNm === name) return this.$_Msg('변경하려는 그룹 명칭이 기존과 동일합니다.');

        const { id, menuNm } = this.biMenu.selectedItem;
        const payload = { id: this.biMenu.selectedItem.id, categoryNm: name };
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_CATEGORY_UPDATE',
          data: {
            data: payload,
            workLog: {
              content: payload,
              preContent: { id: id, categoryNm: menuNm },
            },
          },
          loading: true,
        });

        if (isSuccess(res)) {
          await this.initBiMenu();
          this.closeBiMenuModal();
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE'));
        }
        // 오류 발생 시
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },

      /** 그룹 or 보고서 모달 초기화 함수 */
      closeBiMenuModal() {
        this.biMenu.popup.show = false;
        this.biMenu.popup.menuNm = null;
        this.biMenu.popup.groupNm = null;
      },

      /** BI 보고서 정보 수정 (이름, 그룹) */
      async updateReportInfo() {
        const { id, menuNm, categoryId } = this.biMenu.selectedItem;
        const payload = { id, categoryId: this.biMenu.popup.selectedGroup, menuNm: this.biMenu.popup.menuNm };
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_INFO_MODIFY',
          data: {
            data: payload,
            workLog: {
              content: payload,
              preContent: { id, menuNm, categoryId },
            },
            id: this.biMenu.selectedItem.id,
            categoryId: this.biMenu.popup.selectedGroup,
            menuNm: this.biMenu.popup.menuNm,
          },
          loading: true,
        });

        if (isSuccess(res)) {
          await this.initBiMenu();
          this.closeBiMenuModal();
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE'));
        }
        // 오류 발생 시
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },

      /** BI 보고서 생성 */
      async saveNewReport() {
        const { menuNm, selectedGroup: categoryId } = this.biMenu.popup;
        if (isEmpty(categoryId)) return this.$_Msg('그룹을 선택해야합니다.');
        if (isEmpty(menuNm)) return this.$_Msg('추가하려는 보고서명칭을 입력해야합니다.');

        const payload = { name: menuNm, categoryId: categoryId, content: this.getJsonWorkbook() };
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_INSERT',
          data: {
            data: payload,
            workLog: {
              content: { ...payload, content: this.getJsonWorkbook().length },
              preContent: null,
            },
          },
          loading: true,
        });

        if (isSuccess(res)) {
          await this.initBiMenu();
          this.initBiMenuModal(false);
          const data = getResData(res).at(0);
          this.biMenu.selectedItem = data;
          this.biMenuInstance.selectRows([data.id], true);
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE'));
        }
        // 오류 발생 시
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },

      /** [BI 보고서 또는 그룹] 추가 및 수정 : context 아이콘 관련 로직 처리하는 함수 */
      async processContextMenuByType() {
        const popupType = this.biMenu.popup.type;
        if ('add-report' === popupType) {
          await this.saveNewReport(); // 보고서 추가
        } else if ('edit-report-name' === popupType) {
          await this.updateReportInfo(); // 보고서명 및 상위 카테고리 변경
        } else if ('add-group' === popupType) {
          await this.saveCategory(); // 카테고리 추가
        } else if ('edit-group-name' === popupType) {
          await this.updateCategoryName(); // 카테고리명 변경
        } else if ('copy-report' === popupType) {
          await this.saveCopyReport(); // 보고서 복사
        } else if ('share-report' === popupType) {
          this.openSendReportModal(); // 보고서 전달
        } else {
          console.warn('존재하지 않는 타입: ', popupType);
          return false;
        }
      },

      /** BI 보고서 목록 선택, SpreadJS 워크북 데이터 셋팅 */
      async handleSelectChangedReport(e) {
        this.$emit('openWorkBook', e);
      },

      /** @description BI보고서 목록에 대한 "컨텍스트 메뉴"를 동적으로 처리 후 출력 => 메뉴 선택이 변경되는 경우 */
      handleClickBiMenuTreeList(e) {
        if (e.column === undefined || e.column.dataField === 'menuNm') return;
        // ContextMenu Visible 처리
        const item = e.row.data;
        this.biMenu.selectedItem = item; //Context Event 처리 시 사용하기 위해 저장
        const isDefaultCategory = item.isDefaultCategory;
        const isCategory = item.isCategory;

        // 보여줘야할 옵션만 배열에 담기
        let checkIds = [];
        if (isDefaultCategory) {
          // 기본 그룹: "보고서 추가"
          checkIds = ['add-report'];
        } else if (isCategory) {
          // 커스텀 그룹: "그룹 삭제", "그룹명 수정", "보고서 추가"
          checkIds = ['delete-group', 'edit-group-name', 'add-report'];
        } else {
          // BI 보고서 메뉴: "보고서명 수정", "보고서 삭제", "보고서 복사", "보고서 전달"
          // TODO: 보고서 공유에서 보고서 전달로 메뉴명이 바꼈지만, share로 되어있는 메서드는 그대로 사용하고 있음.
          checkIds = ['edit-report-name', 'delete-report', 'copy-report', 'share-report'];
        }

        // 각 context 별 visible 처리
        this.biMenu.contexts.forEach((v, i) => {
          this.contextMenuInstance.option(`items[${i}].visible`, checkIds.includes(v.id));
        });
      },

      /** 그룹 삭제 */
      async deleteCategory(categoryId) {
        if (await this.$_Confirm('해당 그룹을 삭제하시겠습니까? <br>(삭제시 그룹에 포함된 모든 리포트가 삭제됩니다.) ')) {
          const res = await this.CALL_REPORT_API({
            actionName: 'BI_CATEGORY_DELETE',
            path: `/${categoryId}`,
            data: {
              workLog: {
                content: null,
                preContent: this.biMenu.categories.find(d => d.id === categoryId),
              },
            },
            loading: true,
          });
          if (isSuccess(res)) {
            await this.initBiMenu();
            return this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          }
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },

      /** 보고서 복사 */
      async saveCopyReport() {
        const name = this.biMenu.popup.menuNm;
        const categoryId = this.biMenu.popup.selectedGroup;
        const payload = { id: this.biMenu.selectedItem.id, categoryId, name };
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_COPY',
          data: {
            data: payload,
            workLog: {
              content: payload,
              preContent: null,
            },
          },
          loading: true,
        });
        if (isSuccess(res)) {
          await this.initBiMenu();
          return this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
        }
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },

      /** BI 보고서 삭제 */
      async deleteReport(item) {
        if (await this.$_Confirm(`보고서 [${item.name}] 를 삭제하시겠습니까?`)) {
          const res = await this.CALL_REPORT_API({
            actionName: 'BI_REPORT_DELETE',
            path: `/${item.id}`,
            data: {
              workLog: {
                content: null,
                preContent: { id: item.id, name: item.name, categoryId: item.categoryId },
              },
            },
            loading: true,
          });

          if (isSuccess(res)) {
            await this.initBiMenu();
            this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },

      /**
       * BI 메뉴 우측에 있는 팝오버 메뉴 클릭 함수 (contextId 에 따른 분기 처리)
       * @param {object} e - 이벤트 객체로, 클릭된 컨텍스트 메뉴 항목에 대한 정보를 포함합니다.
       * @returns {void}
       */
      async handleClickContextItem(e) {
        const contextId = e.itemData.id;
        const selectedBiMenu = this.biMenu.selectedItem;
        if (contextId === 'edit-group-name') {
          this.openCategoryModal(selectedBiMenu);
        } else if (contextId === 'delete-group') {
          await this.deleteCategory(selectedBiMenu.id);
        } else if (contextId === 'copy-report') {
          this.openCopyReportModal();
        } else if (contextId === 'delete-report') {
          await this.deleteReport(selectedBiMenu);
        } else if (contextId === 'share-report') {
          this.openSendReportModal();
        } else if (contextId === 'add-report') {
          this.openAddReportModal(selectedBiMenu);
        } else if (contextId === 'edit-report-name') {
          this.openUpdateReportInfoModal(selectedBiMenu);
        }
      },

      /** '보고서 정보' 수정 모달 오픈 */
      openUpdateReportInfoModal(item) {
        this.biMenu.popup.menuNm = item.menuNm;
        this.biMenu.popup.selectedGroup = item.categoryId;

        this.initBiMenuModal(true);
        this.biMenu.popup.type = 'edit-report-name';
        this.biMenu.popup.options.title = '보고서명 변경';
        this.biMenu.popup.options.height = '250';
        this.biMenu.popup.menuLabel = '보고서명';
        this.biMenu.popup.show = true;
      },

      /** 해당 페이지 내에서 사용하는 모달 타입 별 토글 처리 */
      openSendReportModal() {
        // 공유 모달
        this.modal.currentComponent = 'ModalShareMember';
        this.modal.initData = {
          title: `전달 가능 계정`,
          width: 1200,
          height: 600,
          buttons: {
            save: { text: '확인' },
            cancel: { text: '취소' },
          },
        };
        this.modal.sendData = { modal: 'ModalShareMember' };
        this.handleToggleSendModal(true);
      },

      /** '그룹명 수정' 모달 오픈 */
      openCategoryModal(item) {
        this.initBiMenuModal(true);
        this.biMenu.popup.type = 'edit-group-name';
        this.biMenu.popup.menuLabel = '그룹명';
        this.biMenu.popup.menuNm = item.menuNm;
        this.biMenu.popup.options.title = '그룹명 수정';
        this.biMenu.popup.show = true;
      },

      /** '그룹 추가' 모달 오픈 */
      openAddCategoryModal() {
        this.initBiMenuModal(true);
        this.biMenu.popup.type = 'add-group';
        this.biMenu.popup.options.title = '그룹 추가';
        this.biMenu.popup.options.height = '200';
        this.biMenu.popup.menuLabel = '그룹명';
        this.biMenu.popup.show = true;
      },

      /** '보고서 추가' 모달 오픈 */
      openAddReportModal(category) {
        this.biMenu.popup.selectedGroup = category.id;
        this.initBiMenuModal(true);
        this.biMenu.popup.type = 'add-report';
        this.biMenu.popup.options.title = '보고서 추가';
        this.biMenu.popup.options.height = '250';
        this.biMenu.popup.menuLabel = '보고서명';
        this.biMenu.popup.show = true;
      },

      /** '보고서 복사' 모달 오픈 */
      openCopyReportModal() {
        this.initBiMenuModal(true);
        this.biMenu.popup.type = 'copy-report';
        this.biMenu.popup.options.title = '보고서 복사';
        this.biMenu.popup.options.height = '250';
        this.biMenu.popup.menuLabel = '보고서명';
        this.biMenu.popup.show = true;
      },

      /** '보고서 전달' 모달 토글 함수 */
      handleToggleSendModal(isOpen) {
        this.modal.isOpened = isOpen;
        if (!isOpen) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },

      /** BiMenu 모달 초기화 */
      initBiMenuModal(bool) {
        if (bool === false) {
          this.biMenu.popup.componentNm = null;
          this.biMenu.popup.menuNm = null;
          this.biMenu.popup.selectedGroup = null;
          return false;
        }
        this.biMenu.popup.componentNm = 'CustomDxPopup';
      },

      /** BI 카테고리 및 보고서 조회 후 트리 구조로 변경하여 변수 할당 */
      async initBiMenu() {
        // 그룹 리스트
        const categoriesRes = await this.CALL_REPORT_API({
          actionName: 'BI_CATEGORY_LIST_BY_USER',
          loading: true,
        });

        this.biMenu.categories = getResData(categoriesRes);
        const categories = this.biMenu.categories.map(item => ({
          id: item.id,
          nodeId: `GROUP_${item.id}`,
          parentId: -1,
          menuNm: item.name,
          isCategory: true,
          isDefaultCategory: item.defaultFl === 'Y',
        }));

        // 보고서 리스트
        const biReportRes = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_LIST_BY_USER',
          loading: true,
        });

        //TODO: biMenu.selectedItem 사용 하는 곳에서 name, menuNm 헷갈리게 호출해서 우선 중복 선언
        const biReports = getResData(biReportRes).map(item => ({
          id: item.id,
          nodeId: item.id,
          name: item.name,
          menuNm: item.name,
          parentId: `GROUP_${item.categoryId}`,
          categoryId: item.categoryId,
          defaultFl: 'N',
        }));

        this.biMenu.list = [...categories, ...biReports];
      },
      /** End method */
    },
    created() {},
    mounted() {},
  };
</script>
<style scoped>
  .th-bold {
    font-weight: 400;
  }
</style>
<style>
  #sideBar .dx-treelist-empty-space {
    margin-right: 0px;
  }

  #sideBar .dx-treelist-headers .dx-treelist-table .dx-row > td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  #sideBar .btn-icon {
    min-height: 12px;
  }
</style>
