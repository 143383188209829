<template>
	<div class="container">
    <div class="ewm page-sub-box">
      <div class="flex items-center justify-between pt-4">
        <div>
          <DxButton 
            class="btn_XS white outlined" 
            text="목록" 
            type="button" 
            :height="30" 
            @click="onCancelForm" 
          />
          <DxButton 
            class="btn_XS white outlined" 
            text="이전 글" 
            type="button" 
            :height="30" 
            :disabled="nowIndex <= 0"
            @click="goPrev" 
          />
          <DxButton 
            class="btn_XS white outlined" 
            text="다음 글" 
            type="button" 
            :height="30" 
            :disabled="nowIndex + 1 == total.length"
            @click="goNext" 
          />
        </div>
        <div v-if="this.nowData.regId == this.$store.getters.getLoginId || !this.personalCheck">
          <DxButton class="btn_XS default filled modify" text="수정" type="button" :height="30" @click="onEditForm" />
          <DxButton class="btn_XS white light_filled trash" text="삭제" type="button" :height="30" @click="onDeleteForm" />
        </div>
      </div>
      <div class="maxHeight mt-2 space-y-6">
        <div class="filter border-2">
          <div class="flex items-center justify-between p-4">
            <div class="sub_title_txt">
              <h2 class="tracking-wide">[ {{ nowData.categoryNm }} ] {{ nowData.title }}</h2>
            </div>
            <div class="flex space-x-6">
              <div>등록자</div>
              <div>|</div>
              <div>{{ nowData.regId }}({{ nowData.regNm }})</div>
              <div>등록일시</div>
              <div>|</div>
              <div>{{ formatDateTime(nowData.regDt) }}</div>
            </div>
          </div>
        </div>
        <div class="m-4 contentsHeight">
            <div>{{ nowData.content.replace(/<\/?[^>]+(>|$)/g, "") }}</div>
        </div>
        <div v-if="nowData.fileGroupId">
          <div>첨부파일</div>
          <div v-for="(item, i) in nowFileList" class="m-2 space-x-2">
            <a @click="downloadFile(item)" class="pt-1 ml-2 text-lg">{{ item.originalFileFullName }}</a>
            <span>({{ (item.fileSize / 1024).toFixed(2) }}KB)</span>
          </div>
        </div>
        <div>
          <div>댓글 {{ nowData.commentCnt }} 개</div>
          <div v-for="(data, d) in boardCommentData" class="p-2 m-2 border-2">
            <div v-if="editStates[data.id]">
              <div class="flex items-center space-x-2">
                <DxTextArea
                  styling-mode="outlined"
                  class="border-2 autoHeight"
                  :show-clear-button="true"
                  width="100%"
                  v-model="formData[data.id].boardComment"
                  :max-length="limitNumberTexts.maxLengths.boardComment"
                  @key-up="$_checkLimitTextLength($event, formData[data.id], limitNumberTexts, 'boardComment')"
                >
                </DxTextArea>
                <DxButton 
                  class="btn_XS default filled" 
                  text="저장" 
                  type="button" 
                  :height="30" 
                  :disabled="!formData[data.id].boardComment || formData[data.id].boardComment.length < 1"
                  @click="onEditCommentForm(data)" 
                />
                <DxButton class="btn_XS white outlined" text="취소" type="button" :height="30" @click="onCancelCommentForm(data.id)" />
              </div>
            </div>
            <div v-else>
              <div class="flex items-center justify-between">
                <div class="flex space-x-4">
                  <div>{{ data.agtNm }}({{ data.agtId }})</div>
                  <div>{{ formatDateTime(data.regDt) }}</div>
                </div>
                <div>
                  <DxDropDownButton
                    :items="dropDownButton.items"
                    :stylingMode="dropDownButton.stylingMode"
                    :drop-down-options="{ width: 50 }"
                    @item-click="onChangedDropDownButton($event, data)"
                  />
                </div>
              </div>
              <div>
                {{ data.boardComment }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter">
        <div class="flex items-center p-4 space-x-4">
          <DxTextArea
            styling-mode="outlined"
            class="border-2 autoHeight"
            placeholder="댓글 입력"
            :show-clear-button="true"
            width="100%"
            v-model="formData.newComment"
            :max-length="limitNumberTexts.maxLengths.newComment"
            @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'newComment')"
          >
          </DxTextArea>
          <DxButton 
            class="btn_XS default filled" 
            :disabled="!formData.newComment || formData.newComment.length < 1" 
            text="등록" type="button" 
            :height="30" 
            @click="onAddCommentForm" 
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDropDownButton } from 'devextreme-vue/drop-down-button';
import { DxTextArea } from 'devextreme-vue/text-area';
import { isSuccess } from '@/utils/common-lib';
import moment from "moment";

export default {
  components: {
    DxButton,
    DxDropDownButton,
    DxTextArea, 
  },
  data() {
    return {
      dropDownButton: {
        items: ['수정', '삭제'],
        showArrowIcon: false,
      },
      personalCheck: false, //마이페이지에서 조회
      total: [],  //전체 게시글
      nowIndex: 0,  //현재 게시글 인덱스
      nowData: {}, //현재 게시글
      nowFileList: [], //현재 게시글의 파일
      boardCommentData: [], //현재 게시글의 댓글
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          newComment: 500,
          boardComment: 500,
        },
      },
      formData: {
        newComment: '',
        boardComment: '',
      },
      codes: {
        categoryCd: [],
      },
      editStates: {},
    };
  },
  computed: {
    
  },
  methods: {
    formatDateTime(dateTime){
      return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
    },
    goPrev() {
      if (this.nowIndex == 0) {
        this.$_Msg('첫번째 글입니다.');
      } else {
        --this.nowIndex;
        this.nowData = this.total[this.nowIndex];
        this.makeFormData();
      }
    },
    goNext() {
      if (this.nowIndex < this.total.length - 1) {
        this.nowIndex++;
        this.nowData = this.total[this.nowIndex];  
        this.makeFormData();
      } else {
        this.$_Msg('마지막 글입니다.');
      }
    },
    onCancelForm() {
      console.log(this.personalCheck)
      if(this.personalCheck){
        this.$router.push({ path: '/ewm/personal/board' });
      }else{
        this.$router.push({ path: '/ewm/op/board' });
      }
    }, 
    onEditForm() {
      if (this.nowData.regId != this.$store.getters.getLoginId) {
        this.$_Msg('등록자가 아니며 수정할 수 없습니다.');
        return;
      }

      let params = { updateYn: true, formData: this.nowData };
			this.$store.commit('setDetailParams', params);
			this.$router.push({ path: '/ewm/op/board/config' });
    }, 
    async onDeleteForm() {
      if (this.nowData.regId != this.$store.getters.getLoginId) {
        this.$_Msg('등록자가 아니며 삭제할 수 없습니다.');
        return;
      }

			const sendData = [this.nowData.id]; 

			if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			let payload = {
				actionName: 'EWM_OP_BOARD_DELETE',
				data: sendData,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload); 

			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
        this.onSseCheck();
				this.onCancelForm();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
    }, 
    async onAddCommentForm(){
      if (
        !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
          title: `<h2>알림</h2>`,
        }))
      ) {
        return;
      }

      let params = {
        boardId: this.nowData.id,
        agtId: this.$store.getters.getLoginId,
        boardComment: this.formData.newComment,
      };

      const payload = {
        actionName: 'EWM_OP_BOARD_COMMENT_SAVE',
        data: params,
        loading: true,
      };
      
      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        this.formData.newComment = '';
        this.makeFormData();
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      }
    },
    onChangedDropDownButton(e, data) {
      if (data.regId != this.$store.getters.getLoginId) {
        this.$_Msg('등록자가 아니며 수정 또는 삭제할 수 없습니다.');
        return;
      }

			if (e.itemData === this.dropDownButton.items[0]) {
        this.$set(this.editStates, data.id, true);
        this.$set(this.formData, data.id, {boardComment: data.boardComment});
			} else if (e.itemData === this.dropDownButton.items[1]) {
				this.onDeleteCommentForm(data);
			}
		},
    async onEditCommentForm(data){
      if (
        !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
          title: `<h2>알림</h2>`,
        }))
      ) {
        return;
      }

      let params = {
        id: data.id,
        boardId: data.boardId,
        agtId: data.agtId,
        boardComment: this.formData[data.id].boardComment,
        delFl: data.delFl,
        regDt: data.regDt,
        regId: data.regId,
        editDt: data.editDt,
        editId: data.editId,
      };

      const payload = {
        actionName: 'EWM_OP_BOARD_COMMENT_UPDATE',
        data: params,
        loading: true,
      };
      
      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        this.onCancelCommentForm(data.id);
        this.makeFormData();
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      }
    },
    onCancelCommentForm(id){
      this.$set(this.editStates, id, false);
      this.$delete(this.formData, id);
    },
    async onDeleteCommentForm(data){
      if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

      let payload = {
				actionName: 'EWM_OP_BOARD_COMMENT_DELETE',
				data: { id: data.id },
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
				this.makeFormData();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
    },
    downloadFile(fileData){
      this.$_downloadAttachFile(fileData.fileGroupId, fileData.fileName);
    },
    async sseNotify(getLoginId) {
      //임시 sse 연결 (글 조회 시 notify)
			const ssePayload = {
				actionName: 'EWM_SSE_NOTIFY',
				path: '/ewm-unread-notice-count/' + getLoginId,
				loading: false,
			};
			const sseRes = await this.CALL_EWM_API(ssePayload);
			if (isSuccess(sseRes)) {
				// console.log('sseeeeeeeeeeeeeeeeeeeNotifyyyyyyyyyyyyy',sseRes.data.data);
			}
    },
    async onSseCheck(){
      //임시 sse 연결 (삭제시 전체)
			const ssePayload = {
				actionName: 'EWM_SSE_CONNECT_USERS',
				loading: false,
			};
			const sseRes = await vm.CALL_EWM_API(ssePayload);
			if (isSuccess(sseRes)) {
				// console.log('sseeeeeeeeeeeeeeeeeeeConnectUsersssssssss',sseRes.data.data);
			}
		},
    async commentData() {
      const payload = {
        actionName: 'EWM_OP_BOARD_COMMENT_LIST',
        data: {
          boardId: this.nowData.id,
        },
        loading: false,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.boardCommentData = res.data.data;
      }
    },
    async readLog() {
      const payload = {
        actionName: 'EWM_OP_BOARD_READ',
        data: {
          boardId: this.nowData.id,
          agtId: this.$store.getters.getLoginId,
        },
        loading: false,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.commentData();  //연계댓글
        this.sseNotify(payload.data.agtId);  //sse 읽음 체크
      }
    },
    async makeFormData(){
      this.readLog();  //조회수 증가
      if(this.nowData.fileGroupId){
        this.nowFileList = await this.$_getListAttachFile(this.nowData.fileGroupId);
      }
      this.nowData.categoryNm = this.codes.categoryCd.filter(c => c.codeId == this.nowData.categoryCd)[0].codeNm;
    },
    async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_op_board_categorycd');
		},
    async createdData() {
      this.reqParams = this.$store.getters.getDetailParams;
      if (!this.reqParams) {
        this.$_goPrePage();
        return;
      }
      this.personalCheck = this.reqParams.personalCheck? this.reqParams.personalCheck: false;
      this.nowIndex = this.reqParams.index;
      this.total = this.reqParams.total;
      this.nowData = this.total[this.nowIndex];

      await this.initCodeMap().then(() => {
				this.codes.categoryCd = this.$_fetchCodesByDepth(this.codeMap['root_ewm_op_board_categorycd'], 2);
			});
      
      this.makeFormData();
    },
  },
  created() {
    this.createdData();
  },
  mounted() {

  },
};
</script>
<style scoped> 
.maxHeight {
  height: calc(100vh - 300px);
  overflow: auto;
}
.filter {
	background-color: #f9f9f9;
}
.contentsHeight {
  min-height: 300px; 
}
.autoHeight {
  min-height: 50px; 
  height: auto; 
}
</style>