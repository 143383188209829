import InfoPushImages from '@/pages/euc/info-push/images';
import InfoPushImagesUpdate from '@/pages/euc/info-push/images/update';

export default function InfoPushImagesRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/images',
            name: 'InfoPushImages',
            components: { default: InfoPushImages, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/images/update',
            name: 'InfoPushImagesUpdate',
            components: { default: InfoPushImagesUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}