<!--
  PACKAGE_NAME : src/pages/euc/chinese-wall/policy
  FILE_NAME : modal-organization-user.vue
  AUTHOR : jhcho
  DATE : 24. 12. 11.
  DESCRIPTION :
-->
<template>
  <esp-dx-data-grid :data-grid="dataGrid" ref="userGrid" />
</template>
<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid
  },
  props: {
    modalData: Object,
    isOpened: Boolean,
  },
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'userGrid',
        dataSource: [],
        showActionButtons: {
          delete: false
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '사번',
            dataField: 'userNo',
            width: 140,
            allowEditing: false,
          },
          {
            caption: '부서',
            dataField: 'nameTree',
            alignment: 'left',
            allowEditing: false,
          },
          {
            caption: '직위',
            dataField: 'gradeNm',
            alignment: 'center',
            width: 140,
            allowEditing: false,
          },
          {
            caption: '이름',
            dataField: 'userNm',
            width: 140,
            allowEditing: false,
          },
          {
            caption: '내선번호',
            dataField: 'dn',
            width: 140,
            allowEditing: false,
          },
        ],
      },
    }
  },
  watch: {
    isOpened: {
      handler: function (val) {
        if (val) {
          this.setGridList();
        }
      },
      immediate: true
    }
  },
  methods: {
    setGridList() {
      if(this.modalData?.selectedData) {
        const selectionFilter = [];

        if(this.modalData.parentComponent === 'ModalBlock') {
          const blockDetailPolicyList = this.modalData?.selectedData?.blockDetailPolicyList?.filter((d) => d.targetGb === 'USER');
          blockDetailPolicyList?.forEach((d) => {
            if(selectionFilter?.length) {
              selectionFilter.push('or');
            }
            selectionFilter.push(['userNo', '=', d.targetCd]);
          });
        } else {
          if(this.modalData.selectedData?.userCd) {
            const userData = this.modalData.userList.find((user) => user.userNo === this.modalData.selectedData.userCd);
            selectionFilter.push(['userNo', '=', userData.userNo]);
          }
        }
        if(selectionFilter?.length) {
          this.dataGrid.selectionFilter = selectionFilter;
          this.dataGrid.selecting.deferred = true;
        }
      }

      this.$set(this.dataGrid, 'dataSource', this.modalData.userList);
    },
    getInstance() {
      return this.$refs.userGrid.getInstance;
    },
    clearSelection() {
      this.$set(this.dataGrid, 'dataSource', []);
      this.$refs.userGrid.clearSelection();
    },
  },
}
</script>
<style scoped></style>