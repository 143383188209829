import Line from '@/pages/euc/device/line';

export default function LineRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/line',
            name: 'Line',
            components: { default: Line, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}