<template>
	<transition>
		<!-- <div class="page_search_box line_bottom_1px">
			<div class="flex space-x-2 inner">
				<div class="mt-2">
					기준년월
				</div>
				<DxDateBox
					:styling-mode="stylingMode"
					width="100"
					height="30"
					v-model="searchType.customTypes.dayStart"
					type="date"
					dateSerializationFormat="yyyyMMdd"
					display-format="yyyy.MM"
					:disabled="true"
				/>
			</div>
		</div> -->

		<DxDataGrid
			class="grid-box"
			key-expr="id"
			ref="kpiScheGrid"
			:data-source="kpiScheGrid.dataSource"
      :allow-column-resizing="true"
      :column-resizing-mode="'nextColumn'"
      :show-borders="false"
      :show-column-headers="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="false"
      :hover-state-enabled="true"
      :word-wrap-enabled="true"
      :no-data-text="cntNoData"
      :selected-row-keys="kpiScheGrid.selectedRowKeys"
      @selection-changed="onSelectionChanged"
			width="100%"
		>
			<DxScrolling mode="virtual" />
			<DxSelection mode="single" :allow-select-all="true" show-check-boxes-mode="always" select-all-mode="allPages" />
			<DxHeaderFilter :visible="true" />

			<DxColumn
				data-field="scheYmd"
				caption="기준년월"
				:allow-sorting="false"
				alignment="center"
				:allow-header-filtering="false"
				:allow-filtering="true"
				data-type="date"
				format="yyyy-MM"
				:editor-options="{
					dateType: 'date',
					calendarOptions: {
						zoomLevel: 'year',
						minZoomLevel: 'decade',
						maxZoomLevel: 'year',
					},
				}"
			/>
			<DxColumn caption="생산성평가계획명" data-field="scheNm" alignment="center" :allowEditing="false" :allowFiltering="true" />
			<DxColumn
				caption="스케줄적용여부"
				data-field="agtScheApply"
				alignment="center"
				:allowEditing="false"
				:allowFiltering="true"
			/>
		</DxDataGrid>
	</transition>
</template>

<script>
import {DxDateBox} from 'devextreme-vue/date-box';
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxHeaderFilter,
  DxLoadPanel,
  DxLookup,
  DxPaging,
  DxScrolling,
  DxSelection,
  DxSorting,
} from 'devextreme-vue/data-grid';
import {cloneObj, isSuccess} from '@/utils/common-lib';

let vm = this;

export default {
	components: {
		DxDateBox,
		DxDataGrid,
		DxColumn,
		DxPaging,
		DxSelection,
		DxFilterRow,
		DxHeaderFilter,
		DxLookup,
		DxLoadPanel,
		DxSorting,
		DxScrolling,
	},
	props: {
		contentData: Object,
	},
	watch: {},
	data() {
		return {
      cntNoData : "",
			stylingMode: 'outlined', //outlined, underlined, filled
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					dayStart: null,
				},
				paramsData: {},
			},
			kpiScheGrid: {
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
			},
		};
	},
	computed: {},
	methods: {
		async selectDataList() {
			const payload = {
				actionName: 'EWM_PRODUCTIVITY_SCHEDULE_LIST',
				data: {
					frScheYmd: vm.searchType.customTypes.dayStart,
					toScheYmd: vm.searchType.customTypes.dayStart,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.kpiScheGrid.dataSource = res.data.data;
        if(this.kpiScheGrid.dataSource.length === 0){
          this.cntNoData = this.$_lang('CMN_NO_DATA')
        }
			}
		},
		/** @description: 그리드 선택시 변경 관련 이벤트 */
		onSelectionChanged(e) {
			this.kpiScheGrid.selectedRowsData = e.selectedRowsData;
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		async createdData() {
			vm = this;

			this.searchType.customTypes.dayStart = this.contentData.dayStart;
			await this.selectDataList();
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.$watch(
				() => {
					return this.kpiScheGrid.selectedRowsData;
				},
				val => {
					val = cloneObj(val);
					this.$emit('input', { val });
				},
			);
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
