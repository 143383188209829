import AvayaDevice from '@/pages/euc/device/avaya/index.vue';

export default function AvayaRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/avaya',
            name: 'AvayaDevice',
            components: { default: AvayaDevice, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}