<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" ref="cdrGrid" @init-new-row="onInitNewRow"></esp-dx-data-grid>
  </div>
</template>
<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import commonEnum from "@/configs/enums/common";

export default {
  components: {
    EspDxDataGrid,
  },
  watch: {},
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: 'CDR 목록'
        },
        refName: 'cdrGrid',
        dataSourceDefaultSortColumn: '+cdrOrd,-regDt',
        apiActionNm: {
          select: 'EUC_DEVICE_CDR_LIST_ALL',
          update: 'EUC_DEVICE_CDR_UPDATE',
          delete: 'EUC_DEVICE_CDR_DELETE',
        },
        customEvent: {
          initNewRow: true,
        },
        columns: [
          {
            caption: '코드값',
            dataField: 'groupCd',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: 'CDR 등록 명',
            dataField: 'cdrNm',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: 'CDR 등록 값',
            dataField: 'cdrCd',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '설명',
            dataField: 'description',
          },
          {
            caption: '순서',
            dataField: 'cdrOrd',
          },
          {
            caption: '사용 여부',
            dataField: 'viewFl',
            lookup: {
              dataSource: commonEnum.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
          {
            caption: '등록일',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: this.formatDt,
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
    onInitNewRow(e) {
      e.data.viewFl = 'Y';
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.regDt) {
        return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
};
</script>