import InfoPushContent from '@/pages/euc/info-push/content';
import InfoPushContentUpdate from '@/pages/euc/info-push/content/update';

export default function InfoPushContentRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/content',
            name: 'InfoPushContent',
            components: { default: InfoPushContent, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/content/update',
            name: 'InfoPushContentUpdate',
            components: { default: InfoPushContentUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}