import { EventBus } from '@/event-bus';
import { espApi } from '@/api';
import { responseConsoleLogger, errorPopup } from '@/api/error-handle';
import { CustomError } from '@/api/errors';
import i18n from '@/plugins/vue-i18n';

/**
 * routing 정보 조회
 * @param state - store state
 * @param payload
 * @return {T}
 * @throws {Error} - actionName이 store.routingInfo에 없는 경우
 */
export const getRoutingInfo = ({ state }, payload) => {
  let routingInfo = state.routingInfo.find(d => d.actionNm === payload.actionName);

  // actionname이 store.routingInfo 에 없는 경우( 예) 미표시된 API URL )
  if (routingInfo === undefined || payload.actionName === null || payload.actionName === undefined || payload.actionName === '') {
    const routeErrorMsg = i18n.t('COMMON.MESSAGE.SYSTEM_ROUTE_ERROR', {
      defaultValue: `라우팅 정보를 찾을 수 없습니다. <br/> (ACTION_NAME : ${payload.actionName})`,
      actionName: payload.actionName,
    });

    let userMessage;

    // 운영 환경에서는 사용자 메시지만 표시
    if (process.env.NODE_ENV === 'production') {
      userMessage = i18n.t('COMMON.MESSAGE.SYSTEM_ERROR', {
        defaultValue: '시스템에 일시적인 문제가 발생했습니다. <br/> 잠시 후 다시 시도해주세요.',
      });
    } else {
      userMessage = routeErrorMsg;
    }
    throw new CustomError(userMessage, 500);
  }
  return routingInfo;
};

/**
 * API 호출 path 설정 (기존 path + 추가 path)
 *
 * @param path 기존 API path
 * @param addPath 추가할 path
 * @return {string} requestPath
 */
export const setRoutingPath = (path, addPath) => {
  let requestPath = path;
  if (addPath) {
    // payload?.path가 문자열인지 확인, number 또는 object인 경우 문자열로 변환
    if (typeof addPath === 'string' && addPath.startsWith('/')) {
      requestPath += addPath; // 문자열이고 '/'로 시작하는 경우
    } else {
      requestPath += '/' + String(addPath); // 문자열이 아니면 String()으로 변환
    }
    return requestPath;
  }
  return requestPath;
};

/**
 * ESP API 호출
 * @param state
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const CALL_API = async ({ state }, payload) => {
  try {
    const routingInfo = getRoutingInfo({ state }, payload);

    // API 호출 시 필요한 정보
    const requestParam = {
      host: routingInfo.host,
      path: setRoutingPath(routingInfo?.path, payload?.path),
      timeout: payload.timeout || routingInfo.timeout,
      method: routingInfo.method,
      actionName: payload.actionName,
      data: payload.data,
      responseType: payload.responseType,
      headers: payload.headers,
      onDownloadProgress: payload.onDownloadProgress,
    };

    payload.loading && EventBus.$emit('app:progress', true);

    const res = await espApi.callApi(requestParam);

    // 디버그 로거
    responseConsoleLogger(payload.actionName, res);

    payload.loading && EventBus.$emit('app:progress', false);
    return res;
  } catch (error) {
    payload.loading && EventBus.$emit('app:progress', false);

    // 에러 팝업 처리
    errorPopup(error, payload);
    return error.response || error;
  }
};

/**
 * JWT 토큰 갱신
 * @param commit
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const REFRESH_TOKEN = async ({ commit }) => {
  try {
    const res = await espApi.getToken();
    if (res.data.header.resCode === 'success') {
      const promises = [commit('setUser', res.data.data), commit('setPersistedAccessToken', res.data.data.accessToken)];
      await Promise.all(promises);
    }
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * 시스템 설정 초기화
 * @param commit
 * @param payload
 * @return {Promise<AxiosResponse<*>|*|e.Response|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse>}
 * @constructor
 */
export const INIT_SYSTEM_SETTING = async ({ commit }) => {
  try {
    const res = await espApi.getSystemSettings();
    if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
      commit('setSystemCodeList', res.data.data);
    }
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * 테마 설정 초기화
 * @param commit
 * @return {Promise<AxiosResponse<*>|*>}
 * @constructor
 */
export const INIT_THEME_SETTING = async ({ commit }) => {
  try {
    const res = await espApi.getThemeSettings();
    if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
      commit('setThemeSetting', res.data.data);
    }
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * 즐겨찾기 메뉴 초기화
 * @param commit
 * @return {Promise<*>}
 * @constructor
 */
export const INIT_FAVORITE_MENU = async ({ commit }) => {
  try {
    const res = await espApi.getFavoriteMenu();
    if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
      commit('setFavoriteMenu', res.data.data);
    }
    return res;
  } catch (error) {
    return error;
  }
};
