<!--
  PACKAGE_NAME : src/pages/cc/ivr/service-templates/list.vue
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2023-09-07
  DESCRIPTION : 서비스 템플릿 관리
-->
<template>
  <div class="h100 w100">
    <!-- Main Layer -->
    <div class="main d-flex">
        <!-- Left Tree Layer -->
        <!-- 서비스코드 템플릿 -->
        <div class="fl h100 w25 pr5">
          <esp-dx-data-grid
            :data-grid="dataGrid"
            :ref="dataGrid.refName"
            @row-click="onClickRowSvcTp"
            @select="selectIvrSvcTp"
          />
        </div>
        <!-- /Left Tree Layer -->

        <!-- Right Layer -->
        <!-- 서비스코드 설정 -->
        <div class="fr h100 w75 pr5">
          <esp-tree-list
            :tree-list="treeList"
            :ref="treeList.refName"
            @init-new-row="onInitNewRowIvrSvc"
            @onEditCanceling="onEditCancelingIvrSvc"
            @row-saved="onSavedIvrSvc"
            @row-removed="onRowRemovedIvrSvc"
          />
        </div>
        <!-- /Right Layer -->
      </div>
    <!-- /Main Layer -->

    <!-- 서비스템플릿 설정 -->
    <modal-svc-tp
      v-if="modal.ivrSvcTp.visible"
      :is-open="modal.ivrSvcTp.visible"
      :svctp-data="modal.ivrSvcTp.data"
      :maxOrd="modal.maxOrd"
      @saveModal="onSaveModal('ivrSvcTp')"
      @closeModal="onCloseModal('ivrSvcTp')"
    />

  </div>
</template>

<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import EspTreeList from "@/components/devextreme/esp-dx-tree-list-v2.vue";
import ModalSvcTp from '@/pages/cc/ivr/service-templates/modal-svc-tp.vue';

import EspAddButton from "@/components/common/buttons/esp-add-button.vue";
import EspDeleteButton from "@/components/common/buttons/esp-delete-button.vue";

import {isSuccess} from "@/utils/common-lib";
import {mountComponent} from "@/utils/devextreme-util";

export default {
  components: {
    ModalSvcTp,
    EspDxDataGrid,
    EspTreeList,
    EspAddButton,
    EspDeleteButton,
  },
  props: {},
  watch: {},
  data() {
    return {
      stylingMode: 'outlined',
      modal: {
        ivrSvcTp: {
          visible: false,
          data: {},
          maxOrd: 0,                // 순서 조정시 사용할 max 값
        },
      },
      clickedRow: [], //서비스템플릿 클릭한 row
      clickedRowIndex: undefined, //서비스템플릿 클릭한 row index(삭제 확인)
      childrenCntList: [], // 순서값 계산을 위한 변수(자식 Cnt값)
      dataGrid: { //서비스템플릿 그리드
        callApi: "CALL_CC_API",
        refName: 'svcTpGrid',
        dataSource: [],
        showBorders: true,
        rowHeight: 30,
        apiActionNm: {},
        showActionButtons: {
          select: true,
          copy: false,
          update: false,
          delete: false,
          customButtons: [ // 그리드 커스텀 버튼 생성
            {
              template: (data, index, element) => {
                mountComponent(
                  element,
                  EspAddButton,
                  {},
                  {
                    handleClick: () => this.onOpenModal('ivrSvcTp') //서비스코드 설정 팝업 오픈
                  },
                  this,
                );
              },
              location: 'before',
            },
            {
              template: (data, index, element) => {
                mountComponent(
                  element,
                  EspDeleteButton,
                  {},
                  {
                    handleClick: () => this.deleteTemplate()
                  },
                  this,
                );
              },
              location: 'before',
            },
          ],
        },
        page: {
          enabled: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
        },
        selecting: {
          mode: 'single',
        },
        columns: [
          {
            caption: 'ID',
            dataField: 'id',
            visible: false,
            allowEditing: false,
          },
          {
            caption: '서비스템플릿',
            i18n: 'CC.WORD.SVC_TP_TITLE',
            dataField: 'svctpNm',
            alignment: 'left',
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = options.value;
              aTag.addEventListener('click', () => {
                this.onOpenModal('ivrSvcTp', options.data); //서비스코드 설정 팝업 오픈
              });
              container.append(aTag);
            },
          },
          {
            caption: '설명',
            i18n: 'CC.WORD.DESCRIPTION',
            dataField: 'description',
            visible: false,
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'svctpOrd',
            visible: false,
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            width: '100px',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ]
      },
      treeList: { //서비스코드
        callApi: "CALL_CC_API",
        refName: 'serviceList',
        dataSource: [],
        sortKey: "servicecodeOrd",
        dataSourceDefaultSortColumn: "servicecodeOrd",
        rowHeight: 30,
        showBorders: true,
        showColumnLines: true,
        apiActionNm: {
          // select: "CC_IVR_DNIS_LIST",
          merge: "CC_IVR_SVC_MERGE",
          delete: "CC_IVR_SVC_DELETE"
        },
        showActionButtons: {
          select: false,
        },
        rowDragging: {
          enabled: true,
        },
        columns: [
          {
            caption: "id",
            dataField: "id",
            allowSorting: false,
            visible: false,
            allowEditing: false,
          },

          {
            caption: "서비스코드명",
            i18n: "CC.WORD.SERVICECODE_NM",
            dataField: "servicecodeNm",
            allowSorting: false,
            alignment: "left",
            requiredRule: {},
          },
          {
            caption: "서비스코드",
            i18n: "CC.WORD.SERVICECODE",
            dataField: "servicecode",
            width: 150,
            allowSorting: false,
            requiredRule: {},
          },
          {
            caption: "단계",
            i18n: "CC.COMPONENTS.STEP",
            dataField: "depth",
            width: 80,
            allowSorting: false,
            allowEditing: false,
            cellTemplate: (container, options) => {
              container.append(options.value + this.$_lang('CC.COMPONENTS.STEP', { defaultValue: '단계' }));
            },
          },
          {
            caption: "DTMF",
            dataField: "dtmf",
            width: 80,
            allowSorting: false,
          },
          {
            caption: "공지여부",
            i18n: "CC.WORD.NOTICE_FLAG",
            dataField: "noticeFl",
            width: 100,
            allowSorting: false,
            allowEditing: true,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              valueExpr: "value",
              displayExpr: "label"
            }
          },
          {
            caption: "설명",
            i18n: "CC.WORD.DESCRIPTION",
            dataField: "description",
            width: 120,
            allowSorting: false,
            alignment: "left",
          },
          {
            caption: "사용여부",
            i18n: "COMPONENTS.USE_STATUS",
            dataField: "viewFl",
            width: 100,
            allowSorting: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              valueExpr: "value",
              displayExpr: "label"
            }
          },
          {
            caption: "삭제",
            i18n: "COMPONENTS.DELETE",
            cellTemplate: "removeTemplate",
            allowEditing: false,
            width: 80,
          },
          {
            caption: "순서",
            dataField: "servicecodeOrd",
            sortOrder: "asc",
            allowSorting: false,
            visible: false,
            allowEditing: false,
          },
        ]
      },
    }
  },
  computed: {},
  methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(notice, ibg, setting)
     * @param settingData 모달로 넘길 설정 데이터 */
    onOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      if( settingData ){  //수정시에 설정
        this.modal[settingType].data = settingData;
      }else {
        this.modal[settingType].data = {};
      }
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    onSaveModal(settingType) {
      this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

      this.selectIvrSvcTp(); //서비스템플릿 조회

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /**
     * @description : 팝업 닫기
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    onCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description : 서비스 템플릿 데이터 조회 */
    async selectIvrSvcTp(sort = "+svctpOrd") {
      const params = {sort};
      const payload = {
        actionName: 'CC_IVR_SVC_TP_LIST',
        data: params,
        useErrorPopup: true,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess) {
        const data = res.data.data[0].content;
        this.dataGrid.dataSource = data;
        this.modal.maxOrd = data.reduce((max, cur) => cur.svctpOrd > max.svctpOrd ? cur : max)?.svctpOrd;
      }
    },
    /** @description : 서비스템플릿 목록 로우 클릭 이벤트 */
    async onClickRowSvcTp(row) {
      this.clickedRow = row.data;
      this.clickedRowIndex = row.rowIndex;

      this.selectIvrSvc(); //서비스코드 조회
    },
    /**  @description: 서비스 템플릿 삭제 */
    async deleteTemplate(e) {

      //DNIS 설정 여부 확인
      let checkIvrDnisBySvcTp = false;
      console.log('this.clickedRow : ', this.clickedRow);
      if (this.clickedRow.length === 0) {
        return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
      }

      let payload = {
        actionName: 'CC_IVR_DNIS_LIST',
        data: { svctpId: this.clickedRow.id },
      }

      let res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        if (res.data.data.length > 0) {
          checkIvrDnisBySvcTp = true;
        }
      }

      if (checkIvrDnisBySvcTp) {
        return this.$_Msg(this.$_lang('CC.MESSAGE.NO_DELETE_DNIS_SVC_TP', { defaultValue : '대표번호에 게시 중인 서비스 템플릿이 있어 삭제할 수 없습니다.'}));
      } else {
        if (await this.$_Confirm(this.$_lang('CC.MESSAGE.CFM_DELETE_SELECTED_WITH_CHILD', { value: this.$_lang('CC.WORD.SERVICECODE', {defaultValue: '서비스코드'}), defaultValue: `템플릿에 할당 된 서비스코드 정보도 삭제됩니다. 삭제하시겠습니까?` }))) {
          let payload = {
            actionName: 'CC_IVR_SVC_TP_DELETE',
            data: [this.clickedRow.id],
          }

          let res = await this.CALL_CC_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다' }));
            this.initPage();
          }
        }
      }
    },
    /** @description: 서비스코드 조회 */
    async selectIvrSvc() {
      if (this.clickedRow.length === 0) {
        return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
      }

      const params = { svctpId: this.clickedRow.id, sort: '+servicecodeOrd' };
      const payload = {
        actionName: 'CC_IVR_SVC_LIST',
        data: params,
        useErrorPopup: true,
      }
      const res = await this.CALL_CC_API(payload);
      if (isSuccess) {
        const data = res.data.data;
        this.treeList.dataSource = data;
      }
    },
    /** @description: 서비스코드 행 추가시 */
    onInitNewRowIvrSvc(e) {
      e.component.expandRow(78);
      e.component.refresh();
      e.data.svctpId = this.clickedRow.id;
      e.data.noticeFl = 'N';
      e.data.viewFl = 'Y';
      e.data.servicecodeOrd = 1;
      e.data.depth = 1;

      if (e.data.parentId !== -1) {
        let parentNode = e.component.getVisibleRows().find(row => row.data.id === e.data.parentId);
        let cnt = 1;
        cnt += parentNode.node.children.length;
        e.data.depth += parentNode.data.depth;

        const foundIndex = this.childrenCntList.findIndex(item => item.id === e.data.parentId);
        if (foundIndex !== -1) {
          cnt += this.childrenCntList[foundIndex].cnt++;
        } else {
          this.childrenCntList.push({id: e.data.parentId, cnt: 1});
        }
        e.data.servicecodeOrd = cnt;

      } else {
        e.data.servicecodeOrd = e.component.getVisibleRows().filter(row => (row.data.parentId === null || row.data.parentId === -1)).length + 1
      }
    },
    /** @description: 서비스코드 그리드 취소 이벤트 */
    async onEditCancelingIvrSvc(e) {
      this.childrenCntList = [];
    },
    /** @description: 서비스코드 저장 */
    onSavedIvrSvc() {
      this.selectIvrSvc();
    },
    /** @description: 서비스코드 삭제 */
    onRowRemovedIvrSvc() {
      this.selectIvrSvc();
    },
    /** @description : 페이지 인입시 메서드 */
    async initPage() {
      await this.selectIvrSvcTp(); //서비스템플릿 조회
      this.treeList.dataSource = [];
      this.clickedRow = [];
    },
  },
  created() {
    this.$_styleControl('service-templates-style', false);
  },
  mounted() {
    this.initPage();
  },
  beforeDestroy() {
    this.$_styleControl('service-templates-style', true);
  },
}
</script>

<style lang="scss" scoped>

::v-deep .layout-cut-left {
  width: 25%;
  padding-top: 4px;
  padding-right: 20px;
  margin-right: 0px;
}

::v-deep .layout-cut-right {
  width: 75%;
  height: 100%;
  padding-top: 4px;
}

::v-deep .layout-cut-right .dx-treelist .dx-treelist-header-panel {
  padding-top: 10px;
}

::v-deep .dx-treelist .dx-treelist-header-panel {
  padding: 0px;
}
</style>