<!--
  PACKAGE_NAME : src\pages\ai\esp-chat
  FILE_NAME : esp-chat-panel
  AUTHOR : hpmoon
  DATE : 2025-02-05
  DESCRIPTION : ESP Chat
-->
<template>
  <div ref="espChatPanel">
    <transition name="slide-fade">
      <div v-show="isOpen" class="esp-chat-layout" >
        <dx-resizable
          class="resizable-area"
          :class="{ 'extension': isExpanded && !isResizable, 'un-extension': !isExpanded && !isResizable }"
          height="100%"
          handles="left"
          @resize-start="handleResizeStart"
        >
          <div class="header" >
            <esp-dx-button
              v-show="!isExpanded"
              class="border-unset"
              prefix-icon="expand"
              color="white"
              @click="toggleSize"
            />

            <esp-dx-button
              v-show="isExpanded"
              class="border-unset"
              prefix-icon="collapse"
              color="white"
              @click="toggleSize"
            />

            <esp-dx-button
              :disabled="queryList.length === 0 || loading"
              class="border-unset mar_le5"
              prefix-icon="refresh"
              color="white"
              @click="initEspChat"
            />

            <esp-dx-popover
              class="mar_la"
              target="espChatTooltips"
              :html="$_lang('COMMON.MESSAGE.ESP_CHAT_TOOLTIP', { defaultValue: ' - 공휴일 관리하기: 27일에 임시공휴일 추가해줘 <br/> - 인입그룹 통계 조회하기: 어제 서비스 레벨 보여줘' })"
            />
          </div>
          <div>
            <dx-scroll-view
              id="resultScrollView"
              ref="resultScrollView"
              width="100%"
              :height="queryListScrollViewHeight"
              class="mar_b10 scroll-view-back"
              @scroll="handleScroll"
            >
              <div v-if="queryList.length === 0">
                <div class="notice-box" :style="{height: queryListScrollViewHeight}">
                  <span v-html="$_lang('COMMON.MESSAGE.ESP_CHAT_INIT_MESSAGE', { defaultValue: '무엇을 도와드릴까요?' })"/>
                </div>
              </div>
              <div v-else>
                <esp-chat-result
                  v-for="(queryData) in queryList"
                  :session_id="session_id"
                  :data="queryData"
                  :key="queryData.index"
                  :lastMsgIndex="lastMsgIndex"
                  @loading="loading = $event"
                  @scrollBottom="setScrollBottom"
                  @setFormData="query = $event"
                />
              </div>
            </dx-scroll-view>

            <div class="chat-area pad_le10">
              <dx-text-area
                id="queryArea"
                :placeholder="$_lang('COMMON.WORD.ENTER_QUERY', { defaultValue: '질문 입력' })"
                v-model="query"
                width="calc(100% - 62px)"
                styling-mode="outlined"
                :auto-resize-enabled="true"
                value-change-event="input"
                @value-changed="setScrollViewHeight"
                @key-down="handleTextAreaKeyDown"
                min-height="44px"
                max-height="300px"
                class="mar_ri10"
              />

              <esp-dx-button
                class="mar_ri10"
                :disabled="!enableEnter"
                prefix-icon="send"
                height="44px"
                width="44px"
                @click="enterQuery"
              />
            </div>
          </div>
        </dx-resizable>
      </div>
    </transition>

    <dx-load-panel
      :position="{ of: '#resultScrollView' }"
      :visible="loadingPanel && isOpen"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="false"
    />
  </div>
</template>

<script>
  import DxResizable from 'devextreme-vue/resizable';
  import { DxScrollView } from "devextreme-vue/scroll-view";
  import EspChatResult from "@/pages/ai/esp-chat/esp-chat-result.vue";
  import DxTextArea from "devextreme-vue/text-area";
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import EspDxPopover from "@/components/devextreme/esp-dx-popover.vue";

  export default {
    components: {
      DxResizable,
      DxScrollView,
      EspChatResult,
      DxTextArea,
      DxLoadPanel,
      EspDxButton,
      EspDxPopover,
    },

    watch: {
      /** @description 답변 완료 감지 */
      loading(loadingFlag) {
        if (!loadingFlag) {
          this.setScrollBottom();
        }
      },
    },

    data() {
      return {
        session_id: '', // 세션 아이디
        scopedAttribute: '', // CSS 스코프 식별자
        initFl: true, // init 여부
        isOpen: false, // ESP Chat 패널 open 여부
        isExpanded: false, // ESP Chat 패널 확장 여부
        isResizable: false, // ESP Chat 패널 Resize 여부
        queryListScrollViewHeight: '0px', // 질의 결과 영역 ScrollView 높이
        query: '',  // 질문 내용
        queryList: [], // 질문 리스트
        keyIndex: 0, // Result Component Key Index
        lastMsgIndex: 0, // 마지막 질답 Index
        loading: false, // 로딩중 Flag
        loadingPanel: true, // 로딩패널 표시여부
        offset: 0, // 채팅 이력 조회 페이지값
        endHistoryDataFl: false, // 채팅 이력 존재 여부 Flag
        isTooltip: false, // 툴팁 표시 여부
      };
    },

    computed: {
      /** @description 입력 버튼 활성화 여부 */
      enableEnter() {
        return this.query.trim() !== '' && !this.loading;
      },
    },

    methods: {
      /** @description ESP Chat Toggle */
      toggleEspChat(flag) {
        this.isOpen = flag;
        if (flag) {
          this.setScrollViewHeight();
          if (this.initFl) {
            this.setScrollBottom();
            this.initFl = false;
          }
        }
      },

      /** @description ESP Chat 세션 초기화 */
      async initEspChat() {
        this.loadingPanel = true;

        const payload = {
          actionName: 'ESP_CHAT_REFRESH',
          data: {
            id: this.session_id
          },
        };

        const res = await this.CALL_ESP_CHAT_API(payload);
        if (res.status === 200) {
          this.session_id = res.data;
          this.keyIndex = 0;
          this.lastMsgIndex = 0;
          this.query = '';
          this.queryList = [];
          this.endHistoryDataFl = true;
          this.loadingPanel = false;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          this.loadingPanel = false;
          return false;
        }
      },

      /** @description ESP Chat Size Toggle */
      toggleSize() {
        this.isExpanded = !this.isExpanded;
        this.isResizable = false;
      },

      /** @description ESP Chat Resize */
      handleResizeStart(){
        this.isResizable = true;
      },

      /** @description 질문 입력 메서드 */
      enterQuery() {
        if (this.enableEnter) {
          this.queryList.push({ user: this.query, index: ++this.keyIndex, historyFl: false });
          this.lastMsgIndex = this.keyIndex;
          this.query = '';
          this.loading = true;

          this.setScrollBottom();
        }
      },

      /** @description Scroll View Handler */
      async handleScroll(e) {
        if(e.scrollOffset.top === 0 && !this.loadingPanel && !this.endHistoryDataFl) {
          const scrollHeight = this.$refs.resultScrollView.instance.scrollHeight();
          this.loadingPanel = true;

          const payload = {
            actionName: 'ESP_CHAT_SELECT',
            data: {
              person_id: this.$store.getters.getLoginId,
              offset: (this.offset + 10) * 2,
            },
          };

          const res = await this.CALL_ESP_CHAT_API(payload);
          if (res.status === 200) {
            if (res.data.messages.length === 0) {
              this.endHistoryDataFl = true;
              this.loadingPanel = false;
              return false;
            }
            this.offset = this.offset + 10;
            res.data.messages.reverse().forEach(d => {
              d.index = ++this.keyIndex;
              d.historyFl = true;
              this.queryList.unshift(d);
            });
            this.loadingPanel = false;

            this.$nextTick(() => {
              const resultScrollView = this.$refs.resultScrollView.instance;
              resultScrollView.scrollTo(resultScrollView.scrollHeight() - scrollHeight - 100);
            });
          } else {
            this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            this.loadingPanel = false;
            return false;
          }
        }
      },

      /** @description ScrollView 높이 Setting 메서드 */
      setScrollViewHeight() {
        this.$nextTick(() => {
          this.queryListScrollViewHeight = `calc(100vh - 140px - ${ this.heightElement(`#queryArea[${this.scopedAttribute}]`) }px)`;
          this.$nextTick(() => {
            const resultScrollView = this.$refs.resultScrollView.instance;

            const scrollHeight = resultScrollView.scrollHeight(); // 전체 스크롤 높이
            const scrollOffset = resultScrollView.scrollOffset(); // 현재 스크롤 위치
            const clientHeight = this.heightElement(`#resultScrollView[${this.scopedAttribute}]`); // 보이는 영역 높이

            if (Math.ceil(scrollOffset.top + clientHeight + 25) >= scrollHeight) {
              resultScrollView.scrollTo(resultScrollView.scrollHeight());
            }
          });
        });
      },

      /** @description Element 높이 계산 메서드 */
      heightElement(e) {
        const divElement = document.querySelector(e);
        const computedStyle = window.getComputedStyle(divElement);
        const divHeight = divElement.offsetHeight;
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return divHeight + marginTop + marginBottom;
      },

      /** @description ScrollView 하단 스크롤 메서드 */
      setScrollBottom() {
        this.$nextTick(() => {
          const resultScrollView = this.$refs.resultScrollView.instance;
          resultScrollView.scrollTo(resultScrollView.scrollHeight());
        });
      },

      /** @description TextArea KeyDown 이벤트 처리 메서드 */
      handleTextAreaKeyDown(e) {
        if (e.event.key === 'Enter') {
          e.event.preventDefault(); // 기본 Enter 키 동작 방지
          if (e.event.shiftKey) {
            // Shift + Enter일 경우 줄바꿈 처리
            const textArea = e.event.target;
            const cursorPosition = textArea.selectionStart;
            this.query = `${ this.query.slice(0, cursorPosition) }\n${ this.query.slice(cursorPosition) }`;
            this.$nextTick(() => {
              // 커서를 줄바꿈 후 위치로 설정
              textArea.selectionStart = textArea.selectionEnd = cursorPosition + 1;
            });

          } else {
            // 단순 Enter일 경우 enterQuery 메서드 실행
            this.enterQuery();
          }
        }
      },
    },

    async created() {
      const payload = {
        actionName: 'ESP_CHAT_SELECT',
        data: {
          person_id: this.$store.getters.getLoginId,
          offset: 0,
        },
      };

      const res = await this.CALL_ESP_CHAT_API(payload);
      if (res.status === 200) {
        this.session_id = res.data.id;
        res.data.messages.forEach(d => {
          d.index = ++this.keyIndex;
          d.historyFl = true;
        });
        this.lastMsgIndex = this.keyIndex;
        this.queryList = res.data.messages;
        this.loadingPanel = false;
        this.setScrollBottom();
      } else {
        // TODO : API가 아직 완벽히 준비되지 않아 init시 오류 알럿 임시로 막아놓음
        // this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        this.loadingPanel = false;
        return false;
      }
    },

    mounted() {
      this.scopedAttribute = this.$refs.espChatPanel.attributes[0].name;
    },
  };
</script>

<style lang="scss" scoped>
  .esp-chat-layout {
    position: absolute;
    top: calc(100% + 29px);
    right: 0;
    height: calc(100vh - 80px);
    background: #fff;
    border: 1px #efefef solid;
    box-shadow: 0 4px 8px rgba(51, 51, 51, 0.1);
    border-radius: 3px;
    transition: width 0.3s ease;
    z-index: 100;
  }

  .esp-chat-layout .extension {
    transition: width 0.3s ease;
    width: 70vw !important;
  }

  .esp-chat-layout .un-extension {
    transition: width 0.3s ease;
    width: 30vw !important;
  }

  .resizable-area {
    transform: unset !important;
    max-width: 70vw;
    min-width: 30vw;
  }

  .header {
    display: flex;
    height: 40px;
    border-bottom: 1px solid #CECECE;
    align-items: center;
    padding: 0 10px 0 5px;
    left: 5px;
    width: calc(100% - 5px);
  }

  .mar_la {
    margin-left: auto;
  }

  .scroll-view-back {
    background-color: #FAFAFA;
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out !important;
  }

  .slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1) !important;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }

  .chat-area {
    display: flex;
    align-items: center;
  }

  .notice-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .border-unset {
    border: unset !important;
  }

  ::v-deep {
    #queryArea .dx-placeholder {
      top: 50%;
      transform: translate(0, -50%);
    }

    .dx-resizable-handle-left {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      cursor: ew-resize;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      z-index: 10;
    }

    .dx-resizable-handle-left:hover {
      background-color: rgba(0, 0, 0, 0.2);
      transition: background-color 0.2s ease;
    }
  }
</style>
