// vuex store type(상수)를 정의

/**
 * @description vuex store type(상수)를 정의, actions/index.js 참고
 */
export const ActionTypes = Object.freeze({
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  CALL_API: 'CALL_API',
  CALL_EWM_API: 'CALL_EWM_API',
  CALL_EUC_API: 'CALL_EUC_API',
  CALL_REPORT_API: 'CALL_REPORT_API',
  CALL_CC_API: 'CALL_CC_API',
  CALL_FORECASTING_API: 'CALL_FORECASTING_API',
  CALL_LLM_TESTER_API: 'CALL_LLM_TESTER_API',
  CALL_ESP_CHAT_API: 'CALL_ESP_CHAT_API',
  CALL_EMC_API: 'CALL_EMC_API',
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  VERIFY_TOKEN_AND_MEMBER_STATE: 'VERIFY_TOKEN_AND_MEMBER_STATE',
  LOGIN_DIRECT: 'LOGIN_DIRECT',
  INIT_SYSTEM_SETTING: 'INIT_SYSTEM_SETTING',
  INIT_THEME_SETTING: 'INIT_THEME_SETTING',
  WEBSOCKET_CONNECT: 'WEBSOCKET_CONNECT',
  INIT_FAVORITE_MENU: 'INIT_FAVORITE_MENU',
});

