<!--
  PACKAGE_NAME : src\pages\report\ewm
  FILE_NAME : forecasting-fail-history-chart
  AUTHOR : hpmoon
  DATE : 2024-10-29
  DESCRIPTION : 보고서 > 예측 > 예측모델 변경이력 > 예측 실패 인입 이력
-->
<template>
  <DxPopup
      :show-title="true"
      :title="modal.title"
      :min-width="modal.minWidth"
      :width="modal.width"
      :min-height="modal.minHeight"
      :height="modal.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :hide-on-outside-click="modal.closeOnOutsideClick"
      :show-close-button="modal.showCloseButton"
      :visible="isOpen"
      @hiding="closeModal"
  >
    <template #content>
      <div>
        <DxChart
          width="100%"
          id="chart"
          :sticky-hovering="false"
          :data-source="dataSource"
          @legend-click="onLegendClick"
        >
          <DxCommonSeriesSettings argument-field="dt_date" type="line">
            <DxPoint hover-mode="allArgumentPoints" :size="8" />
          </DxCommonSeriesSettings>
          <DxArgumentAxis>
            <DxLabel :customize-text="customizeArgumentText" />
          </DxArgumentAxis>
          <DxValueAxis :tick-interval="1000">
            <DxLabel format="fixedPoint" />
          </DxValueAxis>
          <DxSeries value-field="forecasted_offered_calls" :name="$_lang('FORECASTING.WORD.FORECASTED_OFFER', {defaultValue: '예측 콜'})" color="#4e91e0" />
          <DxSeries value-field="actual_offered_calls" :name="$_lang('FORECASTING.WORD.ACTUAL_OFFER', {defaultValue: '실제 콜'})" color="#f4a533" />
          <DxScrollBar :visible="true" position="bottom" />
          <DxZoomAndPan argument-axis="both" />
          <DxLegend
            position="outside"
            vertical-alignment="bottom"
            horizontal-alignment="right"
            :row-item-spacing="1"
            :customizeItems="customizeLegendItems"
          />
          <DxExport :enabled="false" />
          <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
        </DxChart>
      </div>
    </template>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { DxArgumentAxis, DxChart, DxCommonSeriesSettings, DxExport, DxLabel, DxLegend, DxPoint, DxScrollBar, DxSeries, DxTooltip, DxValueAxis, DxZoomAndPan } from "devextreme-vue/chart";

export default {
  components: {
    DxPopup,
    DxArgumentAxis,
    DxChart,
    DxCommonSeriesSettings,
    DxExport,
    DxLabel,
    DxLegend,
    DxPoint,
    DxScrollBar,
    DxSeries,
    DxTooltip,
    DxValueAxis,
    DxZoomAndPan,
  },

  props: {
    isOpen: Boolean,
    data: Object,
  },

  watch: {

  },

  data() {
    return {
      modal: {
        title: this.$_lang('FORECASTING.WORD.FORECAST_FAIL_OFFER_HISTORY', {defaultValue: '예측 실패 인입 이력'}),
        minWidth: '900',
        width: '900',
        minHeight: '480',
        height: '480',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        closeOnOutsideClick: false,
      },

      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
      },

      dataSource: [],
    };
  },

  computed: {

  },

  methods: {
    /** @description 데이터 조회 */
    async selectDataList() {
      const payload = {
        actionName: 'FORECASTING_LIST',
        data: {
          frequency: 'daily',
          start_date: this.$_commonlib.formatDate(this.data.fail_start_date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD'),
          end_date: this.$_commonlib.formatDate(this.data.fail_end_date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD'),
          base_data: 'forecast'
        },
        loading: true,
      }

      const res = await this.CALL_FORECASTING_API(payload);
      if(res.status === 200) {
        this.dataSource = res.data;
      } else {
        this.$_Msg(this.$_lang('CMN_ERROR'));
      }
    },

    /** @description 차트 ToolTip 커스텀 */
    customizeTooltip(pointInfo) {
      return {
        html: `
        <div id="forecasting-report-tooltip">
            <div class='forecasting-report-tooltip-header'>${this.formatDt(pointInfo.argumentText)}</div>
            <div class='forecasting-report-tooltip-body'>
                <div class='series-name'><span class='top-series-name'>${pointInfo.seriesName}</span>: </div>
                <div class='value-text'><span class='top-series-value'>${pointInfo.valueText
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></div>
            </div>
        </div>
        `,
      };
    },

    /** @description 차트 Legend 클릭 이벤트 */
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },

    /** @description 차트 ArgumentText 커스텀 */
    customizeArgumentText(e) {
      return this.formatDt(e.value);
    },

    /** @description 차트 Legend 커스텀 */
    customizeLegendItems(items) {
      items.forEach(item => {
        item.marker.size = 8;
      });
    },

    /** @description 날짜 변환 메서드 */
    formatDt(date) {
      return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD');
    },

    /** @description 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },
  },

  /** @description 라이프사이클 created시 호출되는 메서드 */
  created() {
    this.selectDataList();
  },

  /** @description 라이프사이클 mounted시 호출되는 메서드 */
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
</style>