<template>
  <div style="height: 100%; width: 100%">
    <DxBox height="100%" direction="row" width="100%">
      <DxItem :ratio="1">
        <template #default>
          <dx-scroll-view id="xml-editor-scroll-view" ref="scrollViewWidget">
            <codemirror ref="cmEditor" v-model="code" :options="cmOptions" @ready="onCmReady" @focus="onCmFocus" @input="onCmCodeChange" />
          </dx-scroll-view>
        </template>
      </DxItem>
    </DxBox>
  </div>
</template>

<script>
  import { codemirror } from 'vue-codemirror';
  import { DxBox, DxItem } from 'devextreme-vue/box';
  import { DxScrollView } from 'devextreme-vue/scroll-view';

  // import base style
  import 'codemirror/lib/codemirror.css';
  // import language js
  import 'codemirror/mode/javascript/javascript.js';
  import 'codemirror/mode/xml/xml.js';
  import 'codemirror/mode/vue/vue.js';

  // import theme style
  import 'codemirror/theme/monokai.css';
  import 'codemirror/theme/base16-dark.css';
  import 'codemirror/theme/paraiso-light.css';
  import 'codemirror/theme/3024-day.css';
  import 'codemirror/theme/3024-night.css';

  // require active-line.js
  import 'codemirror/addon/selection/active-line.js';

  // styleSelectedText
  import 'codemirror/addon/selection/mark-selection.js';

  // hint
  import 'codemirror/addon/hint/show-hint.js';
  import 'codemirror/addon/hint/show-hint.css';
  import 'codemirror/addon/hint/javascript-hint.js';
  import 'codemirror/addon/hint/xml-hint.js';
  import 'codemirror/addon/edit/closetag.js';

  // highlightSelectionMatches
  import 'codemirror/addon/scroll/annotatescrollbar.js';
  import 'codemirror/addon/search/matchesonscrollbar.js';
  import 'codemirror/addon/search/searchcursor.js';
  import 'codemirror/addon/search/match-highlighter.js';

  // keyMap
  import 'codemirror/mode/clike/clike.js';
  import 'codemirror/addon/edit/matchbrackets.js';
  import 'codemirror/addon/comment/comment.js';
  import 'codemirror/addon/dialog/dialog.js';
  import 'codemirror/addon/dialog/dialog.css';
  import 'codemirror/addon/search/search.js';
  import 'codemirror/keymap/sublime.js';

  // foldGutter
  import 'codemirror/addon/fold/foldgutter.css';
  import 'codemirror/addon/fold/brace-fold.js';
  import 'codemirror/addon/fold/comment-fold.js';
  import 'codemirror/addon/fold/foldcode.js';
  import 'codemirror/addon/fold/foldgutter.js';
  import 'codemirror/addon/fold/indent-fold.js';
  import 'codemirror/addon/fold/markdown-fold.js';
  import 'codemirror/addon/fold/xml-fold.js';

  export default {
    components: {
      codemirror,
      DxBox,
      DxItem,
      DxScrollView,
    },
    props: {
      beautyType: String,
      value: String,
      mode: String,
      readOnly: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      value(code) {
        if ([null, undefined].includes(code)) {
          this.code = '';
        } else {
          this.code = code.trim();
        }
      },
    },
    data() {
      return {
        scrollview: {
          showScrollbar: 'onScroll',
          scrollByContent: true,
          scrollByThumb: true,
          pullDown: false,
        },
        code: '',
        cmOptions: {
          readOnly: this.readOnly,
          tabSize: 4,
          indentUnit: 4,
          indentWithTabs: true,
          styleActiveLine: false,
          styleSelectedText: false,
          line: true,
          lineNumbers: true,
          foldGutter: true,
          fixedGutter: true,
          firstLineNumber: 1,
          gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
          highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
          mode: 'application/xml',
          // hint.js options
          hintOptions: {
            completeSingle: false,
          },
          keyMap: 'sublime',
          matchBrackets: true,
          showCursorWhenSelecting: true,
          theme: 'default',
          extraKeys: { Ctrl: 'autocomplete' },
          lineWrapping: true,
          autoCloseTags: true,
        },
        themeItems: ['default', 'monokai', 'paraiso-light', 'base16-dark', '3024-day', '3024-night'],
      };
    },
    methods: {
      onCmReady(cm) {
        // console.log('the editor is readied!', cm);
      },
      onCmFocus(cm) {
        // console.log('the editor is focused!', cm);
      },
      onCmCodeChange(newCode) {
        this.code = newCode;
        this.$emit('input', this.code);
      },
    },
    computed: {
      codemirror() {
        return this.$refs.cmEditor.codemirror;
      },
    },
    created() {},
    mounted() {},
  };
</script>

<style scoped>
  .vue-codemirror {
    height: 94%;
    position: relative;
  }

  #xml-editor-scroll-view {
    height: auto;
  }
</style>

<style>
  .CodeMirror {
    border: 1px solid #eee;
    height: auto;
  }

  .CodeMirror-code {
    min-height: 600px;
  }

  .CodeMirror-focused .cm-matchhighlight {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAFklEQVQI12NgYGBgkKzc8x9CMDAwAAAmhwSbidEoSQAAAABJRU5ErkJggg==);
    background-position: bottom;
    background-repeat: repeat-x;
  }

  .cm-matchhighlight {
    background-color: lightgreen;
  }

  .CodeMirror-gutters {
    left: 0 !important;
  }
</style>
