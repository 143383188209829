<!--
  PACKAGE_NAME : src/pages/cc/cti/ibg-category/modal
  FILE_NAME : modal-skl.vue
  AUTHOR : hmlee
  DATE : 2024-07-09
  DESCRIPTION : 인입그룹 추가 모달 컴포넌트
-->
<template>
  <transition>
    <div class="page-sub-box acco-home sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid :data-grid="dataGrid" ref="ibgGrid"></esp-dx-data-grid>
      </div>
    </div>
  </transition>
</template>

<script>
import Search from '@/components/common/search.vue';
import {DxDataGrid, DxColumn, DxSelection, DxScrolling, DxPaging, DxPager} from 'devextreme-vue/data-grid';

import {DxTextBox} from 'devextreme-vue/text-box';
import {DxButton} from 'devextreme-vue/button';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {isSuccess} from "@/utils/common-lib";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
  components: {
    EspDxDataGrid,
    Search,
    DxDataGridx: DxDataGrid,
    DxColumn,
    DxSelection,
    DxScrolling,
    DxPaging,
    DxPager,

    DxTextBox,
    DxButton,
    DxSelectBox,
  },
  props: {
    modalData: Object,
  },
  watch: {},
  data() {
    return {
      duplicatedIds: {}, // 중복된 id를 담는 객체
      dataGrid: {
        refName: 'gridRefName',
        allowColumnResizing: true, //컬럼 사이즈 허용
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        apiActionNm: {},
        showActionButtons: {
          select: false,
          copy: false,
          delete: false,
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick' // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple' // single multiple
        },
        scrolling: { // 미사용시 주석처리
          mode: 'standard' //스크롤 모드 : ['infinite', 'standard', 'virtual']
        },
        remoteOperations: { // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: { // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: null,
          pageIndex: 0 // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: "compact", //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },

        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: this.$_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}),
            dataField: 'siteId',
            width: 110,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: "siteNm",
              valueExpr: "id",
            },
          },
          {
            caption: this.$_lang('COMMON.WORD.TENANT', {defaultValue: '센터'}),
            dataField: 'tenantId',
            width: 110,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: "tenantNm",
              valueExpr: "id",
            },
          },
          {
            caption: `${this.$_lang('CC.WORD.IBG', {defaultValue: '인입그룹'})} ID`,
            dataField: 'id',
            width: 100,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'left', // left or right
          },
          {
            caption: this.$_lang('CC.WORD.CTI_NAME', {defaultValue: 'CTI 이름'}),
            dataField: 'ibg',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: this.$_lang('CC.WORD.IBG', {defaultValue: '인입그룹'}),
            dataField: 'ibgNm',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
        ]
      },
    }
  },
  computed: {},
  methods: {
    /** @description : 이미 추가한 하위 id를 가져오는 메서드 */
    async selectChildIds() {
      const payload = {
        actionName: 'CC_IBG_CATEGORY_LIST',
      }

      let res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.duplicatedIds = (res.data.data)?.map(d => d.ibgId);
      }
    },
    /** @description : 하위 인입그룹 조회 메서드 */
    async selectDataList(sort = '+id') {
      const vm = this;

      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        async load(loadOptions) {
          const params = vm.$_getDxDataGridParam(loadOptions);

          if (!params.sort)
            params.sort = sort;

          params.viewFl = 'Y';

          //params.id = `<>5012,5013,5016,5020`;
          if (Object.keys(vm.duplicatedIds).length > 0) {
            params.id = `<>${vm.duplicatedIds}`;
          }

          const payload = {
            actionName: 'CC_IBG_LIST',
            data: params,
          }

          const res = await vm.CALL_CC_API(payload);
          if (isSuccess(res)) {
            let rtnData = {
              data: res.data.data,
              totalCount: res.data.header.totalCount,
            }

            vm.$refs.ibgGrid.totalCount = rtnData.totalCount;

            return rtnData;
          }

        }
      });
    },
    /** @description : 그리드 데이터 없을시 출력되는 메서드 */
    noDataText(length) {
      if (length === 0) return this.$_lang('COMMON.MESSAGE.CMN_NO_DATA', {defaultValue: '데이터가 없습니다.'});
      else return '';
    },
    /** @description : 라이프사이클 created 호출되는 메서드 */
    async createdData() {
      await this.selectChildIds();
      await this.selectDataList();
    },
    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
      this.$_eventbus.$on('ModalIbg:onSaveData', async (e, resolve) => {

        let maxIbgCtgOrd = this.modalData.ibgCtgOrd;
        let gridList = this.$refs.ibgGrid.selectedRowsData.map(d => {
          maxIbgCtgOrd++;
          return {
            parentId: this.modalData.parentId,
            ibgId: d.id,
            depth: this.modalData.depth,
            ibgCtgOrd: maxIbgCtgOrd,
          };
        });

        if (gridList.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
          return false;
        }

        const payload = {
          actionName: 'CC_IBG_CATEGORY_MERGE',
          data: gridList,
        }
        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          resolve(200);
        }
      });
    },
    /** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
    beforeDestroyedData() {
      this.$_eventbus.$off('ModalIbg:onSaveData');
    },

  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
  beforeDestroy() {
    this.beforeDestroyedData();
  },
  destroyed() {
  },
}
</script>

<style scoped>
.sub_new_style01 .page_search_box .inner div {
  display: inline-block;
}

.sub_new_style01 .page_search_box .inner > div {
  vertical-align: middle;
  margin-right: 10px;
}

.page_search_box {
  margin-bottom: 0;
}
</style>