<!--
  PACKAGE_NAME : src/pages/euc/chineseWall
  FILE_NAME : dept-attributes.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="fr my-2">
      <DxButton
          @click="handleExcelDownload()"
          text="다운로드"
          :width="80"
          :height="30"
          class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
          styling-mode="outlined"
          type="default"
      />
      <DxButton
          @click="onSettingSave()"
          text="설정적용"
          :width="80"
          :height="30"
          class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled"
          styling-mode="outlined"
          type="default"
      />
    </div>

    <table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
      <colgroup>
        <col style="width: 150px" />
        <col style="width: auto" />
        <col style="width: 130px" />
      </colgroup>
      <thead class="bg-gray-100 dark:bg-gray-700">
      <tr>
        <th scope="col" class="py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          구분
        </th>
        <th scope="col" class="py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          부서
        </th>
        <th scope="col" class="py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          추가
        </th>
      </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td class="py-4 px-6 text-center text-gray-700 whitespace-nowrap dark:text-white">대상</td>
        <td class="py-4 px-6 text-left text-gray-500 whitespace-normal dark:text-white flex flex-wrap">
          <DeptTagBox
              v-for="(item, index) in targetList"
              :key="`target_${index}`"
              :dept="item"
              @onRemoveDept="removeDept"
          />
        </td>
        <td class="py-4 px-6 text-center whitespace-nowrap">
          <DxButton text="부서 추가" class="btn_XS default filled add1" :height="30" @click="addDept('TAGET')" />
        </td>
      </tr>
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td class="py-4 px-6 text-center text-gray-700 whitespace-nowrap dark:text-white">비대상</td>
        <td class="py-4 px-6 text-left text-gray-500 whitespace-normal dark:text-white flex flex-wrap">
          <DeptTagBox
              v-for="(item, index) in nonTargetList"
              :key="`nonTarget_${index}`"
              :dept="item"
              @onRemoveDept="removeDept"
          />
        </td>
        <td class="py-4 px-6 text-center whitespace-nowrap">
          <DxButton text="부서 추가" class="btn_XS default filled add1" :height="30" @click="addDept('NONTGT')" />
        </td>
      </tr>
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <td class="py-4 px-6 text-center text-gray-700 whitespace-nowrap dark:text-white">후선</td>
        <td class="py-4 px-6 text-left text-gray-500 whitespace-normal dark:text-white flex flex-wrap">
          <DeptTagBox
              v-for="(item, index) in afterLineList"
              :key="`afterLine_${index}`"
              :dept="item"
              @onRemoveDept="removeDept"
          />
        </td>
        <td class="py-4 px-6 text-center whitespace-nowrap">
          <DxButton text="부서 추가" class="btn_XS default filled add1" :height="30" @click="addDept('AFLINE')" />
        </td>
      </tr>
      </tbody>
    </table>
    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="modalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isOpened="modal.isOpened"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: $_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
						width: '120',
						height: '40',
						onClick: onModalSave
					}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => isOpenModal(false)
					}"
      />
    </DxPopup>
  </div>
</template>
<script>
import { isSuccess } from "@/utils/common-lib";
import DxButton from "devextreme-vue/button";
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";
import DeptTagBox from "./dept-tag-box.vue";
import ModalDeptList from "./modal-dept-list.vue";
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import {Workbook} from "exceljs";

export default {
  props: {
    deptList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DxPopup,
    DxToolbarItem,
    DxButton,
    DeptTagBox,
    ModalDeptList,
    ModalDownloadReason
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        sendData: null,
      },
      dataList: [],
      targetList: [],
      nonTargetList: [],
      afterLineList: [],
    }
  },
  methods: {
    async onSettingSave() {
      const payload = {
        actionName: 'EUC_CHINESEWALL_DEPT_ATTRIBUTES_UPDATE',
        data: [
          ...this.targetList,
          ...this.nonTargetList,
          ...this.afterLineList
        ],
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }), { icon: 'success' })
      } else {
        this.$_Msg(res.data.header?.resMsg || this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
      }
    },
    handleExcelDownload() {
      const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
      if (useDownReason) {
        this.onOpenModal(
            'ModalDownloadReason',
            {
              title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
              width: '600',
              height: '400',
            },
            {},
        );
      } else {
        this.onExcelDownload();
      }
    },
    onExcelDownload() {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('차이니즈월 부서 속성');
      const headerRowNumber = 4;
      const fileName = '차이니즈월 부서 속성';
      const headerColumn = ['구분', '부서'];

      // Header row 추가
      worksheet.mergeCells('A2:B2');
      worksheet.spliceRows(headerRowNumber, 0, headerColumn);
      // 병합된 셀의 스타일 설정
      const cell = worksheet.getCell('A2');
      cell.value = '차이니즈월 부서 속성';
      cell.font = {
        size: 22,
        bold: true,
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };

      // 셀 너비 설정
      worksheet.columns = [
        { key: 'category', width: 30 },
        { key: 'department', width: 30 }
      ];

      // 데이터 입력
      let currentRowNumber = headerRowNumber + 1; // 데이터 시작 행
      const mergeRanges = []; // 병합 범위를 저장할 배열

      // targetList 데이터 처리
      this.targetList.forEach((item, index) => {
        worksheet.addRow({ category: '대상', department: item.deptNm });
        if (index === 0) mergeRanges.push({ start: currentRowNumber, end: currentRowNumber + this.targetList.length - 1, label: '대상' });
        currentRowNumber++;
      });

      // nonTargetList 데이터 처리
      this.nonTargetList.forEach((item, index) => {
        worksheet.addRow({ category: '비대상', department: item.deptNm });
        if (index === 0) mergeRanges.push({ start: currentRowNumber, end: currentRowNumber + this.nonTargetList.length - 1, label: '비대상' });
        currentRowNumber++;
      });

      // afterLineList 데이터 처리
      this.afterLineList.forEach((item, index) => {
        worksheet.addRow({ category: '후선', department: item.deptNm });
        if (index === 0) mergeRanges.push({ start: currentRowNumber, end: currentRowNumber + this.afterLineList.length - 1, label: '후선' });
        currentRowNumber++;
      });

      const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
      // 구분 셀 병합 처리
      mergeRanges.forEach(range => {
        worksheet.mergeCells(`A${range.start}:A${range.end}`);
        worksheet.getCell(`A${range.start}`).alignment = { vertical: 'middle', horizontal: 'center' };
        worksheet.getCell(`A${range.start}`).border = {
          top: borderStyle,
          left: borderStyle,
          bottom: borderStyle,
          right: borderStyle,
        };
        worksheet.getCell(`B${range.start}`).border = {
          top: borderStyle,
          left: borderStyle,
          bottom: borderStyle,
          right: borderStyle,
        };
      });

      // 헤더 스타일 설정
      worksheet.getRow(headerRowNumber).eachCell(cell => {
        cell.font = { name: '맑은 고딕', size: 11, bold: true };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: borderStyle,
          left: borderStyle,
          bottom: borderStyle,
          right: borderStyle,
        };
      });

      // 엑셀 파일 저장
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName + `.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    },
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      if(!bool) {
        this.$refs.modalRef.clearSelection();
      }
      this.modal.isOpened = bool;
    },
    async selectDataList(sort = '+regDt') {
      const payload = {
        actionName: 'EUC_CHINESEWALL_DEPT_ATTRIBUTES_SELECT',
        data: {
          sort
        },
        loading: false,
      }

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const mapDeptDetails = (item) => {
          const matchedDept = this.deptList.find((dept) => dept.deptId === item.deptId);
          return {
            deptNm: matchedDept?.deptNm || item.deptTitle,
            deptId: item.deptId,
            deptCd: item.deptCd,
            deptTitle: item.deptTitle,
            codeTree: item.codeTree,
            parentId: item.parentId,
            gbcd: item.gbcd,
            deptOrd: matchedDept?.deptOrd || 0,
          };
        };

        const dataList = res.data.data.map(mapDeptDetails);
        dataList.sort((a, b) => a.deptOrd - b.deptOrd);

        const filterByGbcd = (gbcd) => dataList.filter((item) => item.gbcd === gbcd);

        this.targetList = filterByGbcd('TAGET');
        this.nonTargetList = filterByGbcd('NONTGT');
        this.afterLineList = filterByGbcd('AFLINE');
      }
    },
    removeDept(dept) {
      switch (dept.gbcd) {
        case 'TAGET':
          this.targetList = this.targetList.filter((item) => item.deptId !== dept.deptId);
          break;
        case 'NONTGT':
          this.nonTargetList = this.nonTargetList.filter((item) => item.deptId !== dept.deptId);
          break;
        case 'AFLINE':
          this.afterLineList = this.afterLineList.filter((item) => item.deptId !== dept.deptId);
          break;
      }
    },
    addDept(gbcd) {
      let title = '';
      switch (gbcd) {
        case 'TAGET':
          title = '대상 부서 선택';
          break;
        case 'NONTGT':
          title = '비대상 부서 선택';
          break;
        case 'AFLINE':
          title = '후선 부서 선택';
          break;
      }
      const sendData = {
        gbcd,
        deptList: this.deptList,
        targetList: this.targetList,
        nonTargetList: this.nonTargetList,
        afterLineList: this.afterLineList,
      }

      this.onOpenModal(
          'ModalDeptList',
          {
            title,
            width: '50vw',
            height: 'calc(100vh - 100px)',
            buttons:{
              save : { text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }) },
              cancel : { text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }) },
            },
          },
          sendData
      );
    },
    async makeSaveHistory(reason = '') {
      const user = {
        userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
        userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
        deptNm: this.$store.getters.getUserInfo?.deptNm || '',
        gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
      };

      const payload = {
        actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
        data: [
          {
            ...user,
            reason,
            fileType: 'EXCEL',
            fileNm: '차이니즈월 부서 속성',
          },
        ],
        loading: true,
      };

      const res = await this.CALL_API(payload);
      return isSuccess(res);
    },
    async onModalSave() {
      if(this.modal.currentComponent === 'ModalDownloadReason') {
        const reason = this.$refs.modalRef.reason;
        if (reason.trim() === '') {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', { defaultValue: '다운로드 사유를 입력하세요.' }));
        } else {
          const result = await this.makeSaveHistory(reason);
          if(result) {
            this.onExcelDownload();
            this.isOpenModal(false);
          } else {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
          }
        }
      } else {
        const instance = this.$refs.modalRef.getInstance();
        const selectedRows = instance.getSelectedRowsData('excludeRecursive');
        const selectedKeys = selectedRows.map((row) => row.deptId);
        const deptList = this.deptList.filter((dept) => selectedKeys.includes(dept.deptId));
        const changedData = deptList.map((dept) => {
          return {
            deptId: dept.deptId,
            deptNm: dept.deptNm,
            deptCd: dept.deptCode,
            deptTitle: `KBSTCK_${dept.deptCode}`,
            codeTree: dept.codeTree,
            parentId: dept.parentId,
            gbcd: this.modal.sendData.gbcd,
            deptOrd: dept.deptOrd,
          }
        });
        changedData.sort((a, b) => a.deptOrd - b.deptOrd);
        switch (this.modal.sendData.gbcd) {
          case 'TAGET':
            this.targetList = changedData;
            break;
          case 'NONTGT':
            this.nonTargetList = changedData;
            break;
          case 'AFLINE':
            this.afterLineList = changedData;
            break;
        }
      }

      this.isOpenModal(false);
    }
  },
  mounted() {
    this.selectDataList();
  },
}
</script>