<template>
  <div class="ewm px-6">
    <esp-dx-data-grid
      :data-grid="dataGrid"
      ref="subjectGrid"
      @cell-hover-changed="onCellHoverChanged"
      @editing-start="onEditingStart"
      @saving="e => onSaving(e)"
    >
    </esp-dx-data-grid>

    <DxPopover width="auto" :visible="defaultVisible" :target="hoverTarget" position="top">
      <div v-html="popover_description"></div>
    </DxPopover>

    <!-- Agent Modal -->
    <modal-add-agent
      :isOpen="modal.agent.popupVisible"
      :useSaveBtn="canEdit"
      :showModalTitle="true"
      :selectedIdList="modal.agent.selectedIdList"
      @closeModal="onClose(false)"
      @saveModal="onSave"
      :key="modalAddAgentKey"
    />
    <!-- Agent Modal -->

    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : false
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.height
              : 40
            : 40,
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.height
              : 40
            : 40,
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxPopover } from 'devextreme-vue/popover';
  import { formatDate, isSuccess, lPadZero } from '@/utils/common-lib';

  import ModalEducationList from '@/pages/ewm/education/implementation/schedule/modal-education-list.vue'; //강의목록
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue'; //인사팝업

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxPopover,
      DxPopup,
      DxToolbarItem,
      ModalEducationList,
      ModalAddAgent,
    },
    props: {
      server: Object,
    },
    data() {
      return {
        modalAddAgentKey: 0,
        containerElement: null,
        canEdit: this.server.processCd === this.$_enums.ewm.eduEducationProcess.READY.value,
        defaultVisible: false,
        popover_caption: '',
        popover_description: '',
        hoverTarget: '',
        codes: {
          typeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          placeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        config: {
          stylingMode: 'outlined',
          textStylingMode: 'filled',
          updateYn: true,
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
          agent: {
            agtData: [],
            agtIdList: [],
            popupVisible: false,
            useSaveBtn: true,
            selectedIdList: [],
            rowData: [],
          },
        },
        subjectIdFilter: [],
        deleteData: [],
        dataGrid: {
          // keyExpr: 'id',
          refName: 'subjectGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          height: '280', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellHoverChanged: true,
            cellPrepared: false,
            editingStart: true,
            rowInserted: false,
            rowInserting: false,
            saving: true,
            rowPrepared: false,
            rowUpdated: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  disabled: !this.canEdit,
                  onClick: this.onAddEdu,
                },
                location: 'before',
                sortIndex: 10,
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  disabled: !this.canEdit,
                  onClick: this.onDeleteData,
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: this.canEdit,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              dataField: 'scheId',
              height: 10,
              visible: false,
            },
            {
              dataField: 'subjId',
              height: 10,
              visible: false,
            },
            {
              caption: '강의명',
              dataField: 'eduNm',
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
            },
            {
              caption: '진행방식',
              dataField: 'divisionCd',
              width: 90,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              lookup: {
                dataSource: this.$_enums.ewm.eduEducationProcessDivision.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '강의시간',
              dataField: 'eduTime',
              dataType: 'number',
              width: 90,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              calculateDisplayCellValue: rowData => {
                return `${lPadZero(Math.floor(rowData.eduTime / 60), 2)}:${lPadZero(rowData.eduTime % 60, 2)}`;
              },
            },
            {
              caption: '강의유형',
              dataField: 'typeCd',
              width: 100,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              lookup: {},
            },
            {
              caption: '강사 [ID]',
              dataField: 'teachers',
              width: 110,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('div');
                if (!options.data.teachers) {
                  aTag.innerText = '';
                } else if (options.data.teachers.length === 1) {
                  aTag.innerText = `${options.data.teachers[0].teacherName}[${options.data.teachers[0].teacherId}]`;
                } else if (options.data.teachers.length > 1) {
                  aTag.innerText = `${options.data.teachers.length}명`;
                } else {
                  aTag.innerText = '';
                }
                container.append(aTag);
              },
            },
            {
              caption: '대상자관리',
              dataField: 'targetPerson',
              width: 100,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                if (!options.data.targetPerson) {
                  aTag.innerText = `0명`;
                } else {
                  aTag.innerText = `${options.data.targetPerson}명`;
                }
                aTag.addEventListener('click', () => {
                  this.onOpen(options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '시작일',
              dataField: 'eduDt',
              dataType: 'date',
              format: "yyyy-MM-dd",
              width: 110,
              height: 40,
              alignment: 'center',
              allowSorting: false,
              allowEditing: true,
              editorOptions: {
                maxLength: '10',
                max: this.server.ymdEnd,
                min: this.server.ymdStart,
              },
              requiredRule: {
                message: '필수 항목입니다. 교육기간 내로 입력하세요.',
              },
            },
            {
              caption: '시작시간',
              dataField: 'subjectTimeStart',
              width: 90,
              height: 40,
              alignment: 'center',
              allowEditing: true,
              allowSorting: false,
              calculateDisplayCellValue: rowData => {
                if (!rowData.subjectTimeStart) {
                  return (rowData.subjectTimeStart = null);
                } else if (rowData.subjectTimeStart.length === 6) {
                  rowData.subjectTimeStart = rowData.subjectTimeStart.substring(0, 4);
                }

                return formatDate(rowData.subjectTimeStart, 'HHmm', 'HH:mm');
              },
              editorOptions: {
                maxLength: '4',
              },
              patternRule: {
                pattern: /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/,
                message: '24시간제 시간으로 입력하세요(0000~2359)',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '종료시간',
              dataField: 'subjectTimeEnd',
              width: 90,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              calculateDisplayCellValue: rowData => this.calculateEduSubEndTime(rowData),
            },
            {
              caption: '첨부파일',
              dataField: 'attacheFiles',
              width: 90,
              height: 40,
              alignment: 'center',
              allowEditing: false,
              allowSorting: false,
              cellTemplate: (container, options) => {
                if (!options.data.fileGroupId) {
                  let aTag = document.createElement('div');
                  aTag.innerText = `-`;
                  container.append(aTag);
                } else {
                  const button = new DxButton({
                    propsData: {
                      icon: 'download',
                      value: options.data.fileGroupId,
                      onClick: () => {
                        this.downloadFile(options.data.fileGroupId);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '교육장소',
              dataField: 'placeCd',
              width: 100,
              height: 40,
              alignment: 'center',
              allowEditing: true,
              allowSorting: false,
              lookup: {},
            },
          ],
        },
      };
    },
    computed: {
      ewmCodes() {
        return this.$_enums.ewm;
      },
    },
    watch: {
      'server.processCd': function(newVal, oldVal) {
        this.canEdit = newVal === this.$_enums.ewm.eduEducationProcess.READY.value;

        this.dataGrid.showActionButtons.customButtons[0].options.disabled = !this.canEdit;
        this.dataGrid.showActionButtons.customButtons[1].options.disabled = !this.canEdit;
        this.dataGrid.editing.allowUpdating = this.canEdit;
        this.config.updateYn = this.canEdit;
        this.modalAddAgentKey++;
      },
    },
    methods: {
      /** @description : 강사컬럼 마우스 hover 이벤트 */
      onCellHoverChanged(e) {
        this.defaultVisible = false;
        this.hoverTarget = e.cellElement;
        if (e.rowType === 'data' && e.eventType === 'mouseover') {
          if (e.column.dataField === 'teachers') {
            if (e.data.teachers.length > 0) {
              let teacherText = '';
              e.data.teachers.forEach(val => (teacherText += `<li>${val.teacherName}[${val.teacherId}]</li>`));
              this.popover_caption = '강사';
              this.popover_description = `<ul class="teacher-list">${teacherText}</ul>`;
              this.defaultVisible = true;
            }
          }
        }
      },
      onEditingStart(e) {
        if (e.column.dataField === 'placeCd') {
          if (e.data.divisionCd === this.$_enums.ewm.eduEducationProcessDivision.ONLINE.value) {
            e.cancel = true;
          }
        }
      },
      /** @description : 저장 */
      async onSaving(e) {
        e.cancel = true;

        if (!e.changes.length) {
          return;
        }

        let data = [];
        let totalTime = 0;

        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            if (d.type === 'update') {
              let rowIndex = e.component.getRowIndexByKey(d.key);
              let row = e.component.getVisibleRows()[rowIndex];
              let updateData = row.data;

              data.push(updateData);
            } else if (d.type === 'insert') {
              if (Object.keys(d.data).length !== 0) {
                let updateData = { ...d.data };
                updateData['id'] = null;
                updateData['scheId'] = this.server.id;

                data.push(updateData);
              }
            }
          });
        }

        data.forEach(f => {
          if (f.subjectTimeStart.length === 4) {
            f.subjectTimeStart = f.subjectTimeStart + '00';
          }
        });

        // 전체 row 일정 중복 체크
        let totalData = [];

        e.component.getVisibleRows().forEach(row => {
          totalData.push(row.data);
        });

        const groupedData = totalData.reduce((acc, curr) => {
          const eduDt = curr.eduDt;
          if (!acc[eduDt]) {
            acc[eduDt] = [];
          }
          acc[eduDt].push(curr);
          return acc;
        }, {});

        for (const group of Object.values(groupedData)) {
          for (let i = 0; i < group.length; i++) {
            const { subjectTimeStart: start1, subjectTimeEnd: end1 } = group[i];
            for (let j = i + 1; j < group.length; j++) {
              const { subjectTimeStart: start2, subjectTimeEnd: end2 } = group[j];

              if ((start1 >= start2 && start1 < end2) || (start2 >= start1 && start2 < end1)) {
                return this.$_Msg(`강의 시간이 겹칩니다.`);
              }
            }
          }
        }

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        e.component.getVisibleRows().forEach(row => {
          totalTime += row.data.eduTime;
        });

        const payload = {
          actionName: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_SAVE',
          data: {
            data: data,
            totalEduTime: totalTime,
          },
          loading: true,
        };
        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.selectDataList();
          this.server.eduTime = totalTime;
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 삭제 */
      async onDeleteData() {
        if (!this.config.updateYn) {
          this.$_Msg(`진행 중인 교육입니다.`);
          return;
        }

        const selectedRowsData = this.$refs.subjectGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = this.$refs.subjectGrid.getInstance.getSelectedRowsData();

        const totalTime = sendData.reduce((sum, item) => sum + item.eduTime, 0);
        const params = sendData.map(row => {
          return {
            id: row.id,
            scheId: row.scheId,
            totalEduTime: totalTime,
          }
        });

        const payload = {
          actionName: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_DELETE',
          data: params,
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          await this.selectDataList();
          this.server.eduTime = totalTime;
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 강의 추가 */
      onAddEdu() {
        if (!this.server.id) {
          this.$_Msg(`계획 저장 후 강의를 선택 할 수 있습니다.`);
          return;
        }

        if (!this.config.updateYn) {
          this.$_Msg(`진행 중인 교육입니다.`);
          return;
        }

        this.subjectIdFilter = this.$refs.subjectGrid.getInstance.getVisibleRows().map(row => row.data.subjId);
        this.onOpenModal(
          'ModalEducationList',
          {
            title: '강의 추가',
            buttons: {
              save: { text: '선택' },
              cancel: { text: '닫기' },
            },
            width: '1200',
            height: '600',
          },
          this.subjectIdFilter,
        );
      },
      /** @description: 강의 row 추가 */
      async onConfirmModal() {
        let objectArray = [];
        this.modal.contentData.forEach(i => {
          if (!this.subjectIdFilter.includes(i.subjId)) {
            objectArray.push(i);
          }
        });

        let loopIdx = 0;
        while (loopIdx < objectArray.length) {
          this.$refs.subjectGrid.getInstance.addRow();
          loopIdx++;
        }

        objectArray.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs.subjectGrid.getInstance.cellValue(index, key, value);
          });
        });

        //this.subjectIdFilter = this.modal.contentData.map(item => item.subjId);

        this.isOpenModal(false);
      },
      /** @description: 대상자 추가 */
      async onOpen(data) {
        if (!data.id) {
          this.$_Msg(`저장 후 대상자를 선택 하세요.`);
          return;
        }

        this.modal.agent.rowData = data;
        const payload = {
          actionName: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_TARGET_LIST',
          data: {
            subjectRegId: data.id,
          },
          loading: false,
          useErrorPopup: true,
        };
        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.modal.agent.agtData = res.data.data;
          this.modal.agent.agtIdList = res.data.data.map(item => item.agtId);

          this.modal.agent.selectedIdList = this.modal.agent.agtIdList;
          this.modal.agent.useSaveBtn = this.config.updateYn;
          this.modal.agent.popupVisible = true;
        } else {
          return () => {
            throw 'Data Loading Error';
          };
        }
      },
      onClose() {
        this.modal.agent.popupVisible = false;
        this.modal.agent.selectedIdList = [];
      },
      /** @description: 대상자 저장 */
      async onSave(value) {
        if (!this.canEdit) {
          return;
        }

        if (!value.length) {
          return;
        }

        let currentTargets = this.modal.agent.agtData;
        currentTargets.forEach(t => {
          if (value.findIndex(v => v.agtid === t.agtId) < 0) {
            t.delFl = 'Y';
          } else {
            t.delFl = 'N';
          }
        });
        let sendData = currentTargets.filter(t => t.delFl === 'Y');
        currentTargets = currentTargets.filter(t => t.delFl !== 'Y');

        const selectedTeachers = [];
        value.forEach(v => {
          if (currentTargets.findIndex(t => v.agtid === t.agtId) < 0) {
            selectedTeachers.push({
              id: null,
              scheId: this.modal.agent.rowData.scheId,
              subjectRegId: this.modal.agent.rowData.id,
              agtId: v.agtid,
              joinCd: 980,
              delFl: 'N',
            });
          }
        });

        sendData = [...sendData, ...selectedTeachers];

        const payload = {
          actionName: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_TARGET_SAVE',
          data: { data: sendData },
          loading: true,
          useErrorPopup: true,
        };
        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
        this.onClose();
      },
      /**
       * 파일 다운로드
       *
       * @param fileGroupId 파일 그룹 아이디
       */
      async downloadFile(fileGroupId) {
        const fileList = await this.$_getListAttachFile(fileGroupId);

        if (!fileList || fileList.length === 0) {
          this.$_Msg('다운로드 할 파일이 없습니다.');
          return;
        }

        fileList.forEach(fileInfo => {
          this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
        });
      },
      calculateEduSubEndTime(rowData) {
        if (rowData.subjectTimeStart) {
          const startTime = rowData.subjectTimeStart;
          const eduTime = rowData.eduTime;

          const startHour = parseInt(startTime.substring(0, 2));
          const startMinute = parseInt(startTime.substring(2, 4));

          const endTimeMinutes = startHour * 60 + startMinute + eduTime;
          const endHour = Math.floor(endTimeMinutes / 60);
          const endMinute = endTimeMinutes % 60;

          rowData.subjectTimeEnd = lPadZero(endHour, 2) + '' + lPadZero(endMinute, 2) + '00';
          return formatDate(rowData.subjectTimeEnd, 'HHmm', 'HH:mm');
        } else {
          return '';
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        const payload = {
          actionName: 'EWM_EDUCATION_SCHEDULE_SUBJECT_REG_LIST',
          data: {
            scheId: this.server.id,
          },
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          rtnData = res.data.data;
          if (this.canEdit) {
            this.$refs.subjectGrid.getInstance.cancelEditData();
          }
        }
        this.config.updateYn = this.canEdit;
        this.dataGrid.dataSource = rtnData;
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_edu_education_type,root_ewm_edu_education_place');
      },
    },
    mounted() {
      this.canEdit = this.server.processCd === this.$_enums.ewm.eduEducationProcess.READY.value;
      this.initCodeMap().then(() => {
        this.codes.typeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_type'], 2);
        this.codes.placeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_education_place'], 2);
        this.$refs.subjectGrid.getInstance.columnOption('typeCd', 'lookup', this.codes.typeCd);
        this.$refs.subjectGrid.getInstance.columnOption('placeCd', 'lookup', this.codes.placeCd);
      });

      this.dataGrid.showActionButtons.customButtons[0].options.disabled = !this.canEdit;
      this.dataGrid.showActionButtons.customButtons[1].options.disabled = !this.canEdit;
      this.dataGrid.editing.allowUpdating = this.canEdit;
      this.selectDataList();
      this.containerElement = document.getElementById('containerElement');
    },
  };
</script>
