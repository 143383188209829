<template>
	<transition>
		<table class="table_form line-bin">
			<caption>
				<strong>평가표구성</strong>
			</caption>
			<colgroup>
				<col style="width:120px;" />
				<col style="width:auto;" />
			</colgroup>

			<tbody>
				<tr v-if="formData.parentId">
					<th scope="row">
						<label for="label5">상위구성</label>
					</th>
					<td>
						<DxSelectBox
							:data-source="formData.questionParentGroup"
							:input-attr="{ 'aria-label': '상위구성' }"
							:styling-mode="config.stylingMode"
							v-model="formData.parentId"
							display-expr="questionGroupNm"
							value-expr="id"
							placeholder="상위구성 선택"
							:width="270"
							class="mar_ri10"
						>
							<DxValidator>
								<DxRequiredRule message="상위구성은 필수입니다." />
							</DxValidator>
						</DxSelectBox>
					</td>
				</tr>
				<tr>
					<th scope="row">
						<label for="label5">구성명 <span class="icon_require">필수항목</span></label>
					</th>
					<td>
						<DxTextBox
							v-model="formData.questionNm"
							:max-length="limitNumberTexts.maxLengths.questionNm"
							:styling-mode="config.stylingMode"
							class="mar_ri10"
							:show-clear-button="true"
							:width="270"
							@key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'questionNm')"
						>
							<DxValidator>
								<DxRequiredRule message="구성명은 필수입니다." />
							</DxValidator>
						</DxTextBox>
						{{
							limitNumberTexts.textLengths.questionNm
								? limitNumberTexts.textLengths.questionNm
								: formData.questionNm
								? formData.questionNm.length
								: 0
						}}/{{ limitNumberTexts.maxLengths.questionNm }}자
					</td>
				</tr>
			</tbody>
		</table>
	</transition>
</template>

<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import {isSuccess} from "@/utils/common-lib";

export default {
	components: {
		DxSelectBox,
		DxTextBox,
		DxValidator,
		DxRequiredRule,
	},
	props: {
		modalData: Object,
	},
	watch: {
		formData: {
			//data with key and list to send to parent component
			handler(val) {
				let filterData = val;
				if (filterData) {
					this.$emit('input', filterData);
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			formData: {
				sheetId: null,
				questionTypeCd: null,
				questionNm: null,
				parentId: null,
				questionOrder: null,
				delFl: 'N',
				regId: this.$store.getters.getLoginId,
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					questionNm: 85,
				},
			},
		};
	},
	computed: {},
	methods: {
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			this.formData = { ...this.formData, ...this.modalData };
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {
			this.$_eventbus.$on('ModalQuestionlType:onSaveData', async (e, resolve) => {
				if (!e.validationGroup.validate().isValid) {
					return false;
				}

				if (!(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' })))) {
					return false;
				}

				let payload = {
					actionName: 'EWM_EVALUATION_SHEET_QUESTION_SAVE',
					data: this.formData,
					loading: true,
				};
				let res = await this.CALL_EWM_API(payload);
				if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          resolve(res);
				} else {
					this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			});
		},
		/** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
		beforeDestroyedData() {
			this.$_eventbus.$off('ModalQuestionlType:onSaveData');
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	beforeDestroy() {
		this.beforeDestroyedData();
	},
	destroyed() {},
};
</script>
