<template>
  <div class="page-sub-box">
    <div class="msg-error">
      <strong>{{ mainTitle }}</strong>
      <p>{{ subTitle }}</p>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        mainTitle: this.$_lang('COMMON.MESSAGE.AUTH_MAIN_PAGE_ERROR_TITLE', { defaultValue: '메인페이지 권한 설정되어 있지 않은 사용자입니다.' }),
        subTitle: this.$_lang('COMMON.MESSAGE.AUTH_MAIN_PAGE_ERROR_BODY', {
          defaultValue: '"권한관리" 메뉴를 이용하거나, 관리자에게 문의하세요.',
        }),
      };
    },
    methods: {
      goMain() {
        this.$router.push({ path: '/' });
      },
      setErrorMessage(authMenuMainPages) {
        const authMenuMainPage = authMenuMainPages.find(page => page.targetId === 'ALL');
        return !authMenuMainPage;
      },
    },
    mounted() {
      const authMenuMainPages = this.$store.getters.getUserMainPages;
      const alongPageType = this.setErrorMessage(authMenuMainPages);

      // 제대로 메인 설정이 되어 있는경우 다시 메인으로 이동
      if (!alongPageType) {
        this.goMain();
      }
    },
  };
</script>
<style scoped>
  .msg-error {
    padding-top: 300px;
    text-align: center;
  }

  .msg-error strong {
    font-size: 3em;
  }

  .msg-error p {
    font-size: 1.7em;
  }
</style>
