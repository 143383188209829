<!--
  PACKAGE_NAME : src/pages/euc/device/cisco
  FILE_NAME : modal-set-sd.vue
  AUTHOR : jhcho
  DATE : 25. 2. 13.
  DESCRIPTION :
-->
<template>
  <esp-dx-data-grid
      :data-grid="dataGrid"
      ref="sdGrid"
      :auto-width="true"
      :selected-row-keys="dataGrid.selectedRowKeys"
  ></esp-dx-data-grid>
</template>
<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import {getUCServerPath} from "@/pages/euc/common-uc";

export default {
  components: {
    EspDxDataGrid
  },
  props: {
    option: {
      default: () => {
        return {
          width: '50%',
          height: '85%',
          minWidth: null,
          minHeight: null,
          saveBtnVisible: false,
          cancelBtnVisible: true,
        };
      },
      type: Object,
    },
    deviceInfo: {
      type: Object,
    },
    sdData: {
      type: Array,
    },
    isOpened: {
      type: Boolean,
    },
    cmList: {
      type: Array,
    },
    idx: {
      type: String,
    },
    authority: {
      type: String,
    },
  },
  watch: {
    async isOpened() {
      if (this.isOpened) {
        await this.selectDataList();
      } else {
        this.resetGridDataSource();
      }
    },
  },
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'sdGrid',
        dataSourceDefaultSortColumn: '+number',
        showActionButtons: {
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('UC.WORD.APPLY'),
                elementAttr: { class: 'btn_XS default filled ' },
                height: 30,
                onClick: () => {
                  this.onSetSd();
                },
              },
              location: 'after',
            },
          ],
        },
        page: {
          enabled: false,
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowAdding: false,
        },
        columns: [
          {
            caption: '유형',
            i18n: 'UC.WORD.TYPE',
            dataField: 'field',
            width: 80,
            allowEditing: false,
            allowSorting: false,
            cellTemplate: async (container, options) => {
              const imgTag = document.createElement('img');
              imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_LINE_IMAGE_DIR/${options.value}.png`);
              container.append(imgTag);
            },
          },
          {
            caption: '전화번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'number',
            width: 150,
            allowSorting: false,
            calculateDisplayCellValue: rowData => rowData?.number || '',
            validationRules: [
              {
                type: 'custom',
                validationCallback: function (e) {
                  if (e.value === '') {
                    return true;
                  }
                  return !isNaN(e.value) && e.value !== null && e.value !== '';
                },
                message: this.$_lang('UC.MESSAGE.NUMBER_ENTER'),
              },
            ],
          },

          {
            caption: '레이블',
            i18n: 'UC.WORD.LABEL',
            dataField: 'label',
            allowEditing: true,
            allowSorting: false,
            fixedPosition: 'left', // left or right
            calculateDisplayCellValue: rowData => rowData?.number || '',
          },
        ],
      },
      stylingMode: 'outlined', //outlined, underlined, filled
      searchType: {
        obj: {},
        defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
        defaultValue: 'ROOT',
        searchTexts: {
          value: this.pickupKeyword,
        },
        customTypes: {
          cm: null,
          model: null,
          status: null,
        },
        paramsData: null,
      },
    };
  },
  methods: {
    async selectDataList() {
      this.$set(this.dataGrid, 'dataSource', this.sdData);
    },
    /** @description 그리드 데이터 초기화 */
    resetGridDataSource() {
      this.$set(this.dataGrid, 'dataSource', []);
      this.$refs.sdGrid.clearSelection();
    },
    /**@description : 적용버튼 클릭 */
    async onSetSd() {
      if (this.$refs.sdGrid.hasEditData()) {
        await this.$_Msg('수정중인 항목이있습니다. ');
        return false;
      }

      const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      const gridData = this.dataGrid.dataSource.map(d => {
        if (d.number !== null && d.number !== '' && (d.label === null || d.label === '')) {
          d.label = d.number;
        }
        return { ...d };
      });

      if (this.authority === 'manager') {
        this.$emit('closeSd', null, gridData, 'sd');
      } else {
        this.$emit('closeSd', gridData, this.idx);
      }
    },
  },
};
</script>