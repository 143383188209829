import Vue from 'vue';
import App from './App.vue';
import EspCommonPlugin from './plugins/esp-common-plugin';
import Swal from './plugins/sweetalert2-plugin';
import VueLogger from 'vuejs-logger';
import vClickOutside from 'v-click-outside';
import i18n from './plugins/vue-i18n';
import DevExtremePlugin from './plugins/devextreme-plugin';
import mixin from "@/plugins/mixin";
import VueJWT from 'vuejs-jwt';

import '@mdi/font/css/materialdesignicons.css'; //icon 관련 css
import '@/assets/css/dxCustom.css'; //DevExpress 관련 custom
import '@/assets/css/base.css'; //기본 css 모음
import '@/assets/scss/tailwindcss.scss';
import { createRouter } from './router';
import { createStore } from './store';
import { initSystemStore } from './router/router-utils';

const packageJson = require('../package.json');
const appVersion = packageJson.version; // 버전 정보 가져오기

const env = process.env.NODE_ENV;
const logLevel = env === 'development' ? 'debug' : env === 'info' ? 'debug' : env === 'production' ? 'error' : 'info';

// Logger 설정
const options = {
  isEnabled: true,
  logLevel: logLevel,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true,
};

// Vue 설정
Vue.config.productionTip = false; // false로 설정하면 배포시 Vue.js 생성 메시지를 제거
Vue.use(VueLogger, options); // Logger 설정
Vue.use(Swal); // sweetalert2 플러그인
Vue.use(vClickOutside); // v-click-outside 전역 디렉티브 사용
Vue.use(VueJWT); // JWT 토큰 설정
Vue.use(EspCommonPlugin); // 공통 플러그인 설정(store 초기화 문제로 App 생성 이후에 use 등록)

// 앱초기화
(async function initializeApp() {
  try {
    const [store, router] = await Promise.all([
      createStore(), // 스토어 생성
      createRouter(), // 라우터 생성
    ]);

    await initSystemStore(); // store 초기화

    // store 초기화 문제로 App 생성 이후 use 등록
    Vue.mixin(mixin); // mixin 설정
    Vue.use(DevExtremePlugin, { store, i18n }); // DevExtreme 플러그인 사용, Vuex store 사용

    // Vue 인스턴스 생성
    const v = new Vue({
      router, // 위에서 준비된 router 사용
      store,
      i18n,
      render: h => h(App),
    }).$mount('#app');

    // ESP Version
    Vue.prototype.$espAppVersion = appVersion;

    // window popup에서 부모 vue 객체에 접근하기 위해 사용
    window.app = v;
    window.isSSL = window.location.protocol === 'https:';
    window.espAppVersion = appVersion;
  } catch (error) {
    console.error('Failed to initialize app:', error); // 초기화 실패 시 오류 출력
  }
})();
