<!--
  PACKAGE_NAME : src\pages\report\bi\modal-select-template.vue
  FILE_NAME : modal-select-template
  AUTHOR : dwlee
  DATE : 2024-12-11
  DESCRIPTION : BI 보고서 템플릿 선택 모달
-->
<template>
  <div>
    <div class="h-12">
      <DxButton text="템플릿 추가" class="btn_XS default filled add1" type="button" :height="30" @click="handleAddTemplate" />
      <DxButton text="템플릿 삭제" class="btn_XS white light_filled trash" :height="30" @click="handleDeleteTemplate" />
      <input type="file" ref="fileInput" class="hidden" accept=".xlsx, .xls" @change="handleFileChange" />
    </div>
    <div class="mb-3">
      <table class="table_form line-bin h-14">
        <colgroup>
          <col style="width: 130px" />
          <col style="width: auto" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">
              <label>템플릿</label>
            </th>
            <td>
              <div>
                <DxSelectBox
                  placeholder="선택"
                  :items="templateList"
                  display-expr="name"
                  value-expr="id"
                  :width="242"
                  :value="templateId"
                  :styling-mode="stylingMode"
                  @value-changed="handleChangeTemplate"
                >
                  <DxValidator ref="validator">
                    <DxRequiredRule :message="$_lang('COMMON.MESSAGE.PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' })" />
                  </DxValidator>
                </DxSelectBox>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-show="!templateId" class="w-full border flex items-center justify-center" style="height: 451px">
      <p class="text-center">템플릿을 선택하세요.</p>
    </div>
    <div v-show="templateId">
      <gc-spread-sheets ref="spreadSheet" style="width: 860px; height: 451px" @workbookInitialized="initWorkbook" />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { getResData, isSuccess } from '@/utils/common-lib';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';

  export default {
    name: 'ModalSelectTemplate',
    components: {
      DxRequiredRule,
      DxValidator,
      DxButton,
      DxSelectBox,
    },
    data() {
      return {
        stylingMode: 'outlined',
        templateId: null,
        templateList: [],
        spreadJS: {
          instance: null,
          workbook: null,
          excelIO: null,
        },
      };
    },
    methods: {
      /**
       * spreadJS 임포트
       * 해당 컴포넌트를 사용하는 부모 컴포넌트는 해당 함수를 호출해야 함
       * */
      initSpreadJS(instance, excelIO) {
        this.spreadJS.instance = instance;
        this.spreadJS.excelIO = excelIO;
      },
      /** 워크북 초기화 */
      async initWorkbook(workbook) {
        this.spreadJS.workbook = workbook;
      },
      /** 템플릿 목록 조회 */
      async getTemplateList() {
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_TEMPLATE_LIST_BY_USER',
          loading: true,
        });
        this.templateList = getResData(res);
      },
      /** 템플릿 추가 */
      handleAddTemplate() {
        this.$refs.fileInput.click();
      },
      /** 파일 변경 */
      async handleFileChange(e) {
        const file = e.target.files[0];
        if (!file) {
          return;
        }
        try {
          this.spreadJS.workbook.clearSheets(); // 시트 초기화

          const reader = new FileReader();
          reader.onload = e => {
            const data = new Uint8Array(e.target.result);
            const blob = new Blob([data], { type: file.type });
            this.spreadJS.excelIO.open(
              blob,
              json => {
                // 스프레드 JS 적용
                this.setWorkbook(json);
                // DB에 저장
                this.saveTemplate(file.name, json);
              },
              error => {
                console.error('File loading error:', error);
                this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
              },
            );
          };
          reader.readAsArrayBuffer(file);
        } catch (error) {
          console.error('File loading error:', error);
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** 템플릿 삭제 */
      async handleDeleteTemplate(e) {
        if (this.templateId === null && !e.validationGroup.validate().isValid) {
          return this.$_Toast(this.$_lang('PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' }));
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' })))) {
          return false;
        }

        const res = await this.CALL_REPORT_API({
          actionName: 'BI_TEMPLATE_DELETE',
          path: `/${this.templateId}`,
          data: {},
          loading: true,
        });

        if (isSuccess(res)) {
          await this.initTemplate();
          return this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
        }

        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },
      /** 템플릿 초기화 */
      async initTemplate() {
        await this.getTemplateList();
        this.templateId = null;
        this.$refs.fileInput.value = '';
        this.$refs.validator.instance.reset();
      },
      /** 템플릿 변경 */
      async handleChangeTemplate(e) {
        this.templateId = e.value;

        if (!this.templateId) {
          this.$_Toast(this.$_lang('REPORT.NOT_SELECTED_TEMPLATE', { defaultValue: '템플릿을 선택해주시기 바랍니다.' }));
          return;
        }

        const template = this.templateList.find(item => item.id === this.templateId);

        if (!template.content) {
          const res = await this.CALL_REPORT_API({
            actionName: 'BI_TEMPLATE_REPORT_SELECT',
            path: `/${this.templateId}`,
            data: {},
            loading: true,
          });

          if (!isSuccess(res)) {
            return;
          }

          template.content = getResData(res)[0].content;
        }

        const json = JSON.parse(template.content);
        this.setWorkbook(json);
      },
      /** 템플릿 미리보기 적용 */
      setWorkbook(json) {
        this.spreadJS.workbook.fromJSON(json);
        this.spreadJS.workbook.getActiveSheet().setColumnCount(200);
      },
      /** 템플릿 저장 */
      async saveTemplate(name, json) {
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_TEMPLATE_SAVE',
          data: {
            name: name,
            content: JSON.stringify(json),
          },
          loading: true,
        });

        if (isSuccess(res)) {
          const target = getResData(res)[0];
          this.templateList.push(target);
          this.templateId = target.id;
          return this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        }
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      },
      /** 템플릿 적용 */
      async getSelectedTemplate() {
        if (this.templateId === null && !this.$refs.validator.instance.validate().isValid) {
          return this.$_Toast(this.$_lang('PLEASE_SELECT_TARGET', { defaultValue: '대상을 선택해주세요.' }));
        }

        if (!(await this.$_Confirm('새 템플릿을 불러오면 현재 문서의 내용이 모두 삭제되고 덮어 씌워집니다. 계속 하시겠습니까?'))) {
          return false;
        }

        return this.templateList.find(item => item.id === this.templateId);
      },
    },
    async mounted() {
      await this.getTemplateList();
    },
  };
</script>

<style scoped></style>
