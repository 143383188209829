<!--
  PACKAGE_NAME : src\pages\esp\system\alarm\config.vue
  FILE_NAME : setting
  AUTHOR : hjsim
  DATE : 2023-08-03
  DESCRIPTION : 알람 설정
-->
<template>
  <div class="page-sub-box pad_top20">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import { isSuccess } from '@/utils/common-lib';
  import ArrayStore from 'devextreme/data/array_store';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid },
    props: {},
    watch: {},
    data() {
      return {
        targetList: [],
        dataGrid: {
          refName: 'alarmSetGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          repaintChangesOnly: true,
          dataSourceDefaultSortColumn: '+ibgOrd',
          dataSource: [],
          apiActionNm: {
            update: 'ALARM_UPDATE',
          },
          customEvent: {
            reorder: true,
          },
          showActionButtons: {
            select: true,
            copy: false,
            delete: false,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10, //page.size,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            changes: [], //null값으로 두면 에러남
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          excel: {
            title: '인입그룹',
            autoFilterEnabled: true,
            cellwidth: 30,
          },
          showContextMenuItems: {
            excel: true,
            insert: false,
            copy: false,
            cellClipboard: true,
            rowClipboard: true,
          },
          columns: [
            {
              caption: '알림유형*',
              dataField: 'displayCd',
              alignment: 'center', // left center right
              visible: true,
              width: '10%',
              allowEditing: true,
              sortOrder: 'none', // asc desc none
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true, // 컬럼 fix 시 사용
              lookup: {
                dataSource: () => {
                  return this.$_getCode('alarm_display_type');
                },
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
            },
            {
              caption: '알림명*',
              dataField: 'alarmName',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '알림 대상자*',
              dataField: 'notifyTarget',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
              lookup: {
                dataSource: [],
                displayExpr: 'targetName',
                valueExpr: 'id',
              },
            },
            {
              caption: '메시지',
              dataField: 'contentTemplate',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '사용여부',
              dataField: 'useFl',
              alignment: 'center',
              visible: true,
              width: '10%',
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              repaintChangesOnly: true,
              fixed: true,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 알람 대상자 데이터 셋팅  */
      async getTargetList() {
        const payload = {
          actionName: 'ALARM_TARGET_LIST',
          loading: false,
          useErrorPopup: true,
        };

        let rtnData = [];
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        return rtnData;
      },
      /** @description: 데이터 조회 메서드 */
      async selectDataList() {
        const vm = this;
        vm.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load() {
            const payload = {
              actionName: 'ALARM_INFO_LIST',
              loading: false,
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };
            const res = await vm.CALL_API(payload);

            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data.filter(d => d.id),
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /**
       * @description: 알람 대상자 리스트 셋팅
       * @return {Promise<void>}
       */
      async setAlarmTargetList() {
        const dataList = await this.getTargetList();
        this.dataGrid.columns[2].lookup.dataSource = new ArrayStore({
          key: 'id',
          data: dataList,
        });
      },
    },
    created() {},
    mounted() {
      this.setAlarmTargetList().then(() => {
        this.selectDataList();
      });
    },
  };
</script>

<style scoped></style>
