<!--
  PACKAGE_NAME : src/pages/euc/chinese-wall
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 24. 12. 10.
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab title="부서 속성 설정">
        <DeptAttributes v-if="tabIndex === 0" :dept-list="deptList" />
      </Tab>
      <Tab title="차단 정책 설정">
        <block-policy v-if="tabIndex === 1" :user-list="userList" :dept-list="deptList" />
      </Tab>
      <Tab title="예외 정책 설정">
        <exception-policy v-if="tabIndex === 2" :user-list="userList" :dept-list="deptList" />
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tab from "@/components/common/tab.vue";
import Tabs from "@/components/common/tabs.vue";
import DeptAttributes from './dept-attributes.vue';
import BlockPolicy from './policy/block.vue';
import ExceptionPolicy from './policy/exception.vue';
import {isSuccess} from "@/utils/common-lib";

export default {
  components: {
    Tabs,
    Tab,
    DeptAttributes,
    BlockPolicy,
    ExceptionPolicy
  },
  data() {
    return {
      isLoading: true,
      userList: [],
      deptList: [],
      tabIndex: 0,
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path] || 0,
      },
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
    /** @description: 부서 데이터 조회 */
    async selectDeptList() {
      const payload = {
        actionName: 'DEPT_LIST_ALL_VIEW',
        data: {},
        loading: false,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        return res.data.data;
      } else {
        return [];
      }
    },
    async selectUserList() {
      const payload = {
        actionName: 'USER_LIST_ALL_VIEW',
        data: {
          sort: '+deptId,+workgroupCd',
          viewCd: 1
        },
        loading: true
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        return res.data.data;
      } else {
        return [];
      }
    },
  },
  async created() {
    const [users, depts] = await Promise.all([this.selectUserList(), this.selectDeptList()]);
    this.userList = users;
    this.deptList = depts;
  }
}
</script>