<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\modal-page-initial-settings.vue
  FILE_NAME : modal-page-initial-settings
  AUTHOR : bykim
  DATE : 2024-01-18
  DESCRIPTION : 근무스케줄 등록 초기설정 페이지
-->
<template>
  <div class="modal-container">
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">스케쥴 적용 기간*</div>
        <div class="dx-field-value">
          <esp-dx-date-range-box ref="dateRangeBox" :startDt="paramData.startDate" :endDt="paramData.endDate" :labelHide="labelHide" />
        </div>
      </div>
    </div>
    <div class="tabs-container">
      <tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="2" :disabled="true" tab-css-class="customTabCSS">
        <tab title="조직기준">
          <esp-dx-data-grid :data-grid="teamGrid" :ref="teamGrid.refName" v-if="tabIndex === 0" @row-click="handleGridRowClick" />
        </tab>
        <tab title="직원기준">
          <esp-dx-data-grid :data-grid="agentGrid" :ref="agentGrid.refName" v-if="tabIndex === 1" @row-click="handleGridRowClick" />
        </tab>
      </tabs>
    </div>
    <div class="toolbar-bottom">
      <div class="toolbar-item">
        <dx-button
          text="다 음"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="handleBtnPageNext"
        />
        <dx-button text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="handleBtnPageClose" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import EspDxDateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';

  const nextPage = 'modal-page-select-agent';

  export default {
    components: {
      EspDxDateRangeBox,
      EspDxDataGrid,
      Tabs,
      Tab,
      DxButton,
    },
    props: {
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-initial-settings',
          nextPageName: nextPage,
          prevPageName: '',
          agentData: {},
          workCategory: {},
        }),
        type: Object,
      },
    },
    data() {
      return {
        tabIndex: 0,
        labelHide: true,
        paramData: {
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-initial-settings',
          nextPageName: nextPage,
          prevPageName: '',
          agentData: {},
          workCategory: {},
        },
        teamGrid: {
          refName: 'teamGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          dataSource: [], // 그리드 데이터
          height: '340', // 주석처리시 100%
          showActionButtons: {
            delete: false,
          },
          page: {
            enabled: false, // 페이징 사용 유무
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptNmFull',
              allowHeaderFiltering: false,
            },
            {
              caption: '대상자 수',
              dataField: 'count',
              dataType: 'number',
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              calculateCellValue: e => {
                if (e.count === null || e.count === undefined) {
                  return 0;
                }
                return e.count;
              },
            },
          ], // 컬럼 정보
        },
        agentGrid: {
          refName: 'agentGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          dataSource: [], // 그리드 데이터
          height: '340', // 주석처리시 100%
          showActionButtons: {
            delete: false,
          },
          page: {
            enabled: false, // 페이징 사용 유무
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          },
          columns: [
            {
              caption: '부서',
              dataField: 'deptNmPath',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '상담원',
              dataField: 'agent',
              allowGrouping: false,
              calculateCellValue: this.setAgentNameField,
            },
            {
              caption: '직급',
              dataField: 'jikgupNm',
              allowGrouping: false,
            },
            {
              caption: '직위',
              dataField: 'jikweeNm',
              allowGrouping: false,
            },
            {
              caption: '업무그룹',
              dataField: 'workgroupNm',
              allowGrouping: false,
            },
          ], // 컬럼 정보
        },
      };
    },
    watch: {
      /**
       * @description : 상담사 데이터 변경 감지
       */
      appointmentData: {
        handler: function (newVal, oldVal) {
          this.paramData = newVal;
        },
        deep: true,
      },
    },
    methods: {
      /**
       * @description : 다음 페이지 이동
       */
      handleBtnPageNext() {
        this.paramData.nextPageName = nextPage;

        if (this.tabIndex === 0) {
          this.paramData.deptCdList = this.$refs[this.teamGrid.refName].getInstance.getSelectedRowKeys();
        } else if (this.tabIndex === 1) {
          this.paramData.agtIdList = this.$refs[this.agentGrid.refName].getInstance.getSelectedRowKeys();
        }

        if (!(this.paramData.deptCdList?.length > 0 || this.paramData.agtIdList?.length > 0)) {
          this.$_Toast('대상을 선택하세요.');
          return;
        }

        const startDate = this.$refs.dateRangeBox.getStartDate();
        const endDate = this.$refs.dateRangeBox.getEndDate();

        if (
          startDate === null ||
          startDate === undefined ||
          endDate === null ||
          endDate === undefined
        ) {
          this.$_Toast('적용기간을 선택하세요.');
          return;
        }

        this.paramData = {
          ...this.paramData,
          startDate: startDate,
          endDate: endDate,
        };
        console.log('paramData', this.paramData);
        this.$emit('pageNext', this.paramData);
      },
      /**
       * @description : 페이지 닫기
       */
      handleBtnPageClose() {
        this.$emit('pageClose');
      },
      /**
       * @description : 상담사 정보 합치기
       * @param rowData
       * @return {string}
       */
      setAgentNameField(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData?.agtNm} [${rowData?.agtid}]`;
      },
      /**
       * @description : 상담원 데이터 조회
       * @return {Promise<*|*[]>}
       */
      async getAgent() {
        let vm = this;
        this.agentGrid.dataSource = new CustomStore({
          key: 'agtid',
          async load() {
            const payload = {
              actionName: 'EWM_AGENT_SEARCH_LIST_ALL',
              data: [],
              useErrorPopup: true,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            }
            return [];
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /**
       * @description : 탭 선택 이벤트
       * @param index
       * @return {Promise<void>}
       */
      async tabSelectedIndex(index) {
        this.tabIndex = index;
      },
      /**
       * @description : 현재 상담원의 부서코드별 상담원 수 조회
       * @return {Promise<void>}
       */
      async setNumberOfAgents() {
        const vm = this;
        this.teamGrid.dataSource = new CustomStore({
          key: 'deptCd',
          async load() {
            const payload = {
              actionName: 'EWM_DEPT_NUMBER_OF_AGENTS',
              useErrorPopup: true,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            }
            return [];
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /**
       * @description : 그리드 행 클릭 이벤트
       */
      handleGridRowClick(e) {
        let keys = e.component.getSelectedRowKeys();
        let index = keys.indexOf(e.key);

        if (index > -1) {
          keys.splice(index, 1);
        } else {
          keys.push(e.key);
        }
        e.component.selectRows(keys);
      },
    },
    async mounted() {
      this.paramData = this.appointmentData;
      await this.getAgent(); // 상담원 그리드 데이터 조회
      await this.setNumberOfAgents(); // 현재 상담원의 부서코드별 상담원 수 조회
    },
  };
</script>

<style lang="scss" scoped>
  .tabs-container {
    height: 450px;
    margin-top: 10px;
  }

  .modal-container {
    display: flex;
    flex-direction: column; /* 위에서 아래로 정렬 */
    height: 100%; /* 컨테이너가 부모에 꽉 차도록 설정 */
  }

  .toolbar-bottom {
    margin-top: auto; /* 푸터 영역처럼 항상 맨 아래로 위치 */
    padding-top: 10px; /* 추가 여백 */
    display: flex; /* Flexbox 적용 */
    justify-content: center; /* 자식 요소(버튼들)를 수평 중앙 정렬 */
  }

  .toolbar-item {
    gap: 8px;
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
  }
</style>
