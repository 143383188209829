<template>
  <div v-if="isVisible" class="progress-loader" :class="containerClass">
    <div class="progress-container">
      <div class="progress-status">
        <div class="current-step-info">
          <div class="step-info">
            <span class="step-label">{{ currentStep?.label }}</span>
            <div class="status-wrapper">
              <span v-if="isCurrentStepInProgress" class="step-status">
                {{ $_lang('COMMON.WORD.IN_PROGRESS') }}
                <span class="progress-percentage">(0%)</span>
              </span>
              <span v-else-if="getStepStatus(currentStepIndex) === 'completed'" class="step-status">
                <strong>{{ $_lang('COMMON.WORD.COMPLETED') }}</strong>
                <span class="progress-percentage">(100%)</span>
              </span>
              <span v-else-if="currentStep?.error" class="step-status error">
                {{ $_lang('COMMON.WORD.FAILED') }}
              </span>
            </div>
          </div>
          <span class="step-count">{{ currentStepIndex + 1 }} / {{ steps.length }}</span>
        </div>

        <div class="progress-bar-container">
          <div class="progress-bar-bg">
            <div class="progress-segments">
              <div
                v-for="(step, index) in steps"
                :key="index"
                class="progress-segment"
                :class="{
                  'in-progress': index === currentStepIndex,
                  'completed': index < currentStepIndex || getStepStatus(index) === 'completed',
                  'pending': index > currentStepIndex
                }"
                :style="{
                  width: `${stepsProgress[index]}%`,
                  backgroundColor: themeColor,
                  opacity: getSegmentOpacity(index)
                }"
              ></div>
            </div>
          </div>
        </div>

        <div v-if="currentStep?.error" class="error-message">{{ currentStep.error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EspProgressLoader',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      required: true
    },
    showBackground: {
      type: Boolean,
      default: true
    },
    useThemeColor: {
      type: Boolean,
      default: true
    },
    targetSelector: {
      type: String,
      default: 'body'
    }
  },
  data() {
    // steps 진행률 초기 계산
    const stepsProgress = this.calculateInitialProgress(this.steps);

    return {
      isVisible: this.visible,
      currentStepIndex: 0,
      stepsStatus: [],
      stepsProgress
    }
  },
  computed: {
    themeColor() {
      if (!this.useThemeColor) return '#fca40b';
      return this.$store.getters.getThemeSetting?.themeColor || '#fca40b';
    },
    totalProgress() {
      // 완료된 단계의 진행률만 합산
      return this.stepsProgress
        .slice(0, this.currentStepIndex)
        .reduce((sum, progress) => sum + progress, 0);
    },
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
    isCurrentStepInProgress() {
      return this.getStepStatus(this.currentStepIndex) !== 'completed' && !this.currentStep?.error;
    },
    containerClass() {
      return {
        'no-background': !this.showBackground,
        'position-absolute': this.targetSelector !== 'body'
      }
    }
  },
  watch: {
    // steps가 변경되면 진행률 재계산
    steps: {
      immediate: true,
      handler(steps) {
        this.calculateStepsProgress(steps);
      }
    }
  },
  methods: {
    /**
     * @description 특정 단계의 상태를 반환한다
     * @param {number} index 확인할 단계의 인덱스
     * @returns {string} 단계 상태 ('error'|'completed'|'in-progress'|'pending')
     */
    getStepStatus(index) {
      if (this.steps[index].error) return 'error';
      if (this.stepsStatus[index] === 'completed') return 'completed';
      if (this.currentStepIndex === index) return 'in-progress';
      return 'pending';
    },

    /**
     * @description 특정 단계에 에러를 설정한다
     * @param {number} index 에러를 설정할 단계의 인덱스
     * @param {string} errorMessage 에러 메시지
     */
    setStepError(index, errorMessage) {
      this.$set(this.steps[index], 'error', errorMessage);
      this.currentStepIndex = index;
    },

    /**
     * @description 특정 단계의 에러를 제거한다
     * @param {number} index 에러를 제거할 단계의 인덱스
     */
    clearStepError(index) {
      this.$set(this.steps[index], 'error', null);
    },

    /**
     * @description 특정 단계를 시작한다
     * @param {number} index 시작할 단계의 인덱스
     */
    startStep(index) {
      this.currentStepIndex = index;
      // 현재 단계의 progress를 0으로 초기화
      this.updateStepProgress(index, 0);
    },

    /**
     * @description 특정 단계를 완료 처리한다
     * @param {number} index 완료할 단계의 인덱스
     */
    completeStep(index) {
      this.$set(this.stepsStatus, index, 'completed');
      // 현재 단계의 진행률을 더함
      this.updateStepProgress(index, this.stepsProgress[index]);

      if (index < this.steps.length - 1) {
        this.startStep(index + 1);
      } else {
        this.complete();
      }
    },

    /**
     * @description 모든 단계를 완료 처리하고 로더를 숨긴다
     */
    complete() {
      setTimeout(() => {
        this.isVisible = false;
      }, 1000);
    },

    /**
     * @description 모든 상태를 초기화한다
     */
    reset() {
      this.currentStepIndex = 0;
      this.stepsStatus = new Array(this.steps.length).fill('pending');
      this.steps.forEach((step, index) => {
        this.clearStepError(index);
      });
    },

    /**
     * @description 프로그레스 로더를 표시한다
     */
    show() {
      this.isVisible = true;
      this.reset();
    },

    /**
     * @description 특정 단계의 진행률을 업데이트한다
     * @param {number} index 업데이트할 단계의 인덱스
     * @param {number} progress 진행률 (0-100)
     */
    updateStepProgress(index, progress) {
      if (index >= 0 && index < this.steps.length) {
        this.$set(this.stepsProgress, index, progress);
      }
    },

    /**
     * @description 각 스텝별 진행률을 계산하여 저장
     * @param {Array} steps 진행 단계 배열
     */
    calculateStepsProgress(steps) {
      if (!steps.length) {
        this.stepsProgress = [];
        return;
      }

      let definedProgressSteps = 0;
      let remainingWeight = 100;

      // 미리 정의된 progress 값이 있는 스텝 처리
      this.stepsProgress = steps.map(step => {
        if (step.progress !== undefined) {
          definedProgressSteps++;
          remainingWeight -= step.progress;
          return step.progress;
        }
        return 0;
      });

      // 남은 진행률을 균등 분배
      const remainingSteps = steps.length - definedProgressSteps;
      if (remainingSteps > 0) {
        const progressPerStep = remainingWeight / remainingSteps;
        this.stepsProgress = this.stepsProgress.map(progress =>
          progress || progressPerStep
        );
      }
    },

    calculateInitialProgress(steps) {
      if (!steps.length) return [];

      let definedProgressSteps = 0;
      let remainingWeight = 100;

      // 미리 정의된 progress 값 처리
      const progress = steps.map(step => {
        if (step.progress !== undefined) {
          definedProgressSteps++;
          remainingWeight -= step.progress;
          return step.progress;
        }
        return 0;
      });

      // 남은 진행률 균등 분배
      const remainingSteps = steps.length - definedProgressSteps;
      if (remainingSteps > 0) {
        const progressPerStep = remainingWeight / remainingSteps;
        return progress.map(p => p || progressPerStep);
      }

      return progress;
    },

    getSegmentOpacity(index) {
      if (this.getStepStatus(index) === 'completed') return 1;
      if (index === this.currentStepIndex) return 0.6;
      if (index > this.currentStepIndex) return 0.2; // 다음 단계들은 희미하게 표시
      return 0;
    }
  }
}
</script>

<style scoped>
.progress-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.progress-loader.no-background {
  background: transparent;
}

.progress-loader.position-absolute {
  position: absolute;
}

.progress-container {
  min-width: 400px;
  max-width: 500px;
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.progress-status {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.current-step-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step-info {
  display: flex;
  align-items: center;
}

.step-label {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.step-status {
  margin-left: 8px;
  font-size: 0.85rem;
  color: #666;
}

.step-status.error {
  color: #ff4444;
}

.step-count {
  font-size: 0.9rem;
  color: #666;
}

.progress-bar-container {
  width: 100%;
}

.progress-bar-bg {
  width: 100%;
  height: 8px;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
}

.progress-segments {
  display: flex;
  width: 100%;
  height: 100%;
}

.progress-segment {
  height: 100%;
  transition: all 0.3s ease;
}

.error-message {
  font-size: 0.85rem;
  color: #ff4444;
  text-align: center;
}

.progress-percentage {
  margin-left: 4px;
  font-size: 0.85rem;
  color: #666;
}
</style>