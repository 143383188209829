import AuthRoutes from "@/router/esp/auth";
import UserRoutes from "@/router/esp/user";
import HistoryRoutes from "@/router/esp/history";
import HRRoutes from "@/router/esp/hr";
import MonitoringRoutes from "@/router/esp/monitoring";
import SummaryRoutes from "@/router/esp/summary";
import SystemRoutes from "@/router/esp/system";

export default function EspRoutes(commonComponents) {
    return [
        ...AuthRoutes(commonComponents),
        ...HistoryRoutes(commonComponents),
        ...HRRoutes(commonComponents),
        ...MonitoringRoutes(commonComponents),
        ...SummaryRoutes(commonComponents),
        ...SystemRoutes(commonComponents),
        ...UserRoutes(commonComponents),
    ];
}