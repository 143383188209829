import EvaluationSchedule from '@/pages/ewm/evaluation/implementation/schedule/index.vue';
import EvaluationScheduleDetail from '@/pages/ewm/evaluation/implementation/schedule/detail.vue';
import EvaluationScheduleRegister from '@/pages/ewm/evaluation/implementation/schedule/register.vue';
import EvaluationStatus from '@/pages/ewm/evaluation/implementation/status/index.vue';
import EvaluationStatusEvaluation from '@/pages/ewm/evaluation/implementation/status/evaluation.vue';
import EvaluationReportDepartment from '@/pages/ewm/evaluation/report/department/index.vue';
import EvaluationReportPersonal from '@/pages/ewm/evaluation/report/personal/index.vue';
import EvaluationReportQuestion from '@/pages/ewm/evaluation/report/question/index.vue';
import EvaluationSheetSetting from '@/pages/ewm/evaluation/setting/sheet/index.vue';
import EvaluationSheetRegister from '@/pages/ewm/evaluation/setting/sheet/register.vue';

export default function EvaluationRoutes(commonComponents) {
  return [
    // Implementation -> Schedule
    {
      path: '/ewm/evaluation/implementation/schedule',
      name: 'EvaluationSchedule',
      components: { default: EvaluationSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/schedule/detail',
      name: 'EvaluationScheduleDetail',
      components: { default: EvaluationScheduleDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/schedule/register',
      name: 'EvaluationScheduleRegister',
      components: { default: EvaluationScheduleRegister, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Implementation -> Status
    {
      path: '/ewm/evaluation/implementation/status',
      name: 'EvaluationStatus',
      components: { default: EvaluationStatus, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/status/evaluation',
      name: 'EvaluationStatusEvaluation',
      components: { default: EvaluationStatusEvaluation, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Report
    {
      path: '/ewm/evaluation/report/department',
      name: 'EvaluationReportDepartment',
      components: { default: EvaluationReportDepartment, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/report/personal',
      name: 'EvaluationReportPersonal',
      components: { default: EvaluationReportPersonal, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/report/question',
      name: 'EvaluationReportQuestion',
      components: { default: EvaluationReportQuestion, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Setting -> Sheet
    {
      path: '/ewm/evaluation/setting/sheet',
      name: 'EvaluationSheetSetting',
      components: { default: EvaluationSheetSetting, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/setting/sheet/register',
      name: 'EvaluationSheetRegister',
      components: { default: EvaluationSheetRegister, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}