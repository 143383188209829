<template>
	<transition>
		<DxDataGrid
			class="grid-box"
			key-expr="id"
			:data-source="gridDataSource"
			:show-borders="false"
			:show-column-headers="true"
			:show-column-lines="true"
			:show-row-lines="true"
			:row-alternation-enabled="false"
			:hover-state-enabled="true"
			:word-wrap-enabled="true"
			:no-data-text="this.$_lang('CMN_NO_DATA')"
			width="100%"
			height="350"
		>
			<DxLoadPanel :enabled="true" />
			<DxPaging :enabled="false" />
			<DxScrolling mode="standard" />
			<DxSorting mode="multiple" />

			<DxColumn
				caption="상태"
				data-field="processCd"
				:allowEditing="false"
				:allow-sorting="true"
				alignment="center"
				:visible="true"
				width="230"
				height="40"
				:calculate-sort-value="data => calculateSortValue(data, 'processCd')"
			>
				<DxLookup :data-source="this.$_enums.ewm.evalProcess.values" value-expr="value" display-expr="label" />
			</DxColumn>
			<DxColumn
				caption="변경일시"
				data-field="regDt"
				:allowEditing="false"
				:allow-sorting="true"
				alignment="center"
				:visible="true"
				width="230"
				:calculate-display-value="
					rowData => formatDate(rowData.regDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
				"
			/>
			<DxColumn
				caption="수정자"
				data-field="regId"
				:allowEditing="false"
				:allow-sorting="true"
				alignment="center"
				:visible="true"
				width="100"
				height="40"
				:calculate-display-value="rowData => `${rowData.regAgtNm} [${rowData.regId}]`"
			/>
		</DxDataGrid>
	</transition>
</template>

<script>
import {formatDate, isSuccess} from '@/utils/common-lib';
import { DxDataGrid, DxColumn, DxLookup, DxScrolling, DxSorting, DxLoadPanel, DxPaging } from 'devextreme-vue/data-grid';


let vm;

export default {
	components: {
		DxDataGrid,
		DxColumn,
		DxLoadPanel,
		DxLookup,
		DxPaging,
		DxScrolling,
		DxSorting,
	},
	props: {
		modalData: Object,
	},
	watch: {},
	data() {
		return {
			codeMap: {},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			searchType: {
				paramsData: {},
			},
			gridDataSource: null,
		};
	},
	computed: {
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
    formatDate,
		/** @description : 소트설정 */
		calculateSortValue(data, clsCd) {
			if (clsCd === 'processCd') {
				return this.$_enums.ewm.evalProcess.values.find(e => data.processCd === e.value).label;
			}
		},
		async selectDataList() {
			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_PROCESS_HISTORY_LIST',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.gridDataSource = res.data.data;
			}
		},
		/** @description : 코드 초기화 */
		async initCodeMap() {
			//this.codeMap = await this.$_getCodeMapList('root_ewm_eval_process');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		createdData() {
			vm = this;
			this.searchType.paramsData.scheId = this.modalData.scheId;
		},
		/** @description : 라이프사이클 mounted 호출되는 메서드 */
		mountedData() {},
		/** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
		beforeDestroyedData() {
			//this.$_eventbus.$off('ModalQuestionlType:onSaveData');
		},
	},
	created() {
		this.createdData();
		this.selectDataList();
	},
	mounted() {
		this.mountedData();
	},
	beforeDestroy() {
		this.beforeDestroyedData();
	},
	destroyed() {},
};
</script>
