<!--
  PACKAGE_NAME : src/pages/euc/device/avaya
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid" />
  </div>
</template>

<script>
  import { isSuccess } from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import commonEnum from "@/configs/enums/common";

  export default {
    components: {
      EspDxDataGrid,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        dataGrid: {
          callApi: 'CALL_EUC_API',
          excel: {
            title: '전화 정보 목록',
          },
          refName: 'deviceGrid',
          dataSourceDefaultSortColumn: '-regDt,-id',
          apiActionNm: {
            select: 'EUC_DEVICE_AVAYA_SELECT',
            delete: 'EUC_DEVICE_AVAYA_DELETE',
            update: 'EUC_DEVICE_AVAYA_UPDATE',
          },
          showActionButtons: {
            customButtons: []
          },
          columns: [
            {
              caption: '모델',
              dataField: 'model',
              cellTemplate: async (container, options) => {
                const imgTag = document.createElement('img');
                const imgData = this.$_getAttachFileURL(null, options.value);
                imgTag.setAttribute('src', imgData);
                container.append(imgTag);
              },
            },
            {
              caption: '타입',
              dataField: 'modelType',
            },
            {
              caption: '내선번호',
              dataField: 'uniqueId',
              allowEditing: false,
              cellTemplate: (container, options) => {
                const { modelType } = options.data;
                if (modelType === 'IP') {
                  const aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.onUpdateData(options.data);
                  });
                  container.append(aTag);
                } else {
                  container.append(options.value);
                }
              },
            },
            {
              caption: '릴리즈 버전',
              dataField: 'release',
            },
            {
              caption: '서버 번호',
              dataField: 'svrAes.svr.svrInx',
            },
            {
              caption: '교환기',
              dataField: 'svrAes.aes.cm.cmNm',
            },
            {
              caption: 'AES명',
              dataField: 'svrAes.aes.aesNm',
            },
            {
              caption: 'IP',
              dataField: 'ip',
            },
            {
              caption: '로그인',
              dataField: 'state',
              allowEditing: false,
              cellTemplate: (container, options) => {
                const stateEl = document.createElement('div');
                const color = options.value === 'true' ? '#21b6bb' : '#808080';

                stateEl.style.display = 'inline-block';
                stateEl.style.width = '18px';
                stateEl.style.height = '18px';
                stateEl.style.borderRadius = '50%';
                stateEl.style.backgroundColor = color;

                container.append(stateEl);
              },
            },
            {
              caption: 'MCID',
              dataField: 'inauth',
              lookup: {
                dataSource: commonEnum.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value'
              },
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              allowEditing: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    methods: {
      getCustomButtons() {
        const buttons = [];
        const useRestart = this.$_getSystemData('use_device_restart')?.configValue === 'Y';
        if (useRestart) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: '재시작',
              elementAttr: { class: 'btn_XS default filled ' },
              width: 80,
              height: 30,
              onClick: () => {
                this.onDeviceRestart();
              },
            },
            location: 'before',
          });
        }

        const useLogin = this.$_getSystemData('use_device_login')?.configValue === 'Y';
        if (useLogin) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: '로그인',
              elementAttr: { class: 'btn_XS default filled ' },
              width: 80,
              height: 30,
              onClick: () => {
                this.onDeviceLogin();
              },
            },
            location: 'before',
          });
        }

        return buttons;
      },
      /** @description : 단말기 재시작 실행 처리 메서드 */
      onDeviceRestart() {
        const selectedRowsData = this.$refs.deviceGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg('단말기를 선택해주세요.');
          return false;
        }

        const adimPort = this.$_getSystemData('adim_port')?.configValue;
        const groups = selectedRowsData.reduce((acc, obj) => {
          const { svrAes } = obj;
          const { svr, aes } = svrAes;
          const url = `${svr?.svrIp}:${adimPort}_${aes?.cm?.cmNm}`;
          acc[url] = acc[url] ?? [];
          acc[url].push(obj);
          return acc;
        }, {});

        const apiArry = [];
        for (const url in groups) {
          const params = url.split('_');
          const payload = {
            actionName: 'EUC_DEVICE_AVAYA_RESTART',
            data: {
              url: params[0],
              params: {
                INTERVAL: '5',
                STATIONS: groups[url].map(item => item.uniqueId),
                CM: params[1],
              },
            },
            loading: true,
          };
          apiArry.push(this.CALL_EUC_API(payload));
        }
        Promise.all(apiArry).then(() => {
          this.$_Toast(
              this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }),
              { icon: 'success' }
          );
        });
      },
      /** @description : 단말기 로그인 실행 처리 메서드 */
      async onDeviceLogin() {
        const length = this.$refs.deviceGrid.selectedRowsData.length;
        if (length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        } else if (length > 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT', { defaultValue: '하나의 대상만 선택해주세요.' }));
          return false;
        }

        const device = this.$refs.deviceGrid.selectedRowsData[0];
        const { uniqueId, svrAes } = device;
        const { svr } = svrAes;
        const url = `${svr?.svrIp}:${svr?.ucgwPort}`;
        const payload = {
          actionName: 'EUC_DEVICE_AVAYA_LOGIN',
          data: {
            url,
            params: {
              device: uniqueId,
            },
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(
              this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }),
              { icon: 'success' }
          );
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
        }
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD');
        }
      },
      /** @description : 단말 정보 상세 페이지 이동 메서드 */
      async onUpdateData(data) {
        const { svr } = data?.svrAes;
        const efgwPort = this.$_getSystemData('efgw_port')?.configValue;

        const payload = {
          actionName: 'EUC_DEVICE_AVAYA_DETAIL',
          data: {
            params: {
              url: `${svr?.svrIp}:${efgwPort}`,
              uniqueId: data.uniqueId,
            },
          },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        const { result, fileData } = res.data;

        if (res.status === 200) {
          if (result === 'success') {
            this.$store.commit('setDetailParams', { deviceData: data, fileData });
            this.$router.push({ path: '/euc/device/avaya/detail' });
          } else if (result === 'not found') {
            this.$_Msg(this.$_lang('UC.MESSAGE.NOT_FOUND_AVAYA_DEVICE_FILE', { defaultValue: '해당 장치의 파일 데이터가 존재하지 않습니다.' }));
          } else {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.SYSTEM_ERROR', { defaultValue: '시스템 오류 입니다. 관리자에게 문의하세요.' }));
        }
      },
    },
    created() {
      this.dataGrid.showActionButtons.customButtons.concat(this.getCustomButtons());
    },
  };
</script>