export default {
  ADD: '추가',
  REGISTER: '등록',
  CONFIRM: '확인',
  CANCEL: '취소',
  CLOSE: '닫기',
  COPY: '복사',
  DELETE: '삭제',
  DELETE_SELECTED: '선택 삭제',
  DETAIL: '상세보기',
  DOWNLOAD: '다운로드',
  DUPLICATE_SELECTED: '선택 복사',
  EDIT: '수정',
  FIRST_PAGE: '첫페이지',
  LAST_PAGE: '마지막페이지',
  MOVE: '이동',
  NEXT: '다음',
  NEXT_PAGE: '다음페이지',
  PREV: '이전',
  PREV_PAGE: '이전페이지',
  REFRESH: '새로고침',
  SAVE: '저장',
  APPROVAL: '승인',
  COMPLETE: '완료',
  SEARCH: '검색',
  SEARCH_RESULT: '검색 결과',
  SELECT: '선택',
  SELECT_ALL: '전체선택',
  UNSELECT_ALL: '전체해제',
  UPDATE: '변경',
  UPLOAD: '업로드',
  TITLE: '제목',
  NAME: '이름',
  DATE: '날짜',
  DEPT: '부서',
  GROUP: '그룹',
  TYPE: '구분',
  DESCRIPTION: '설명',
  ALL: '전체',
  USE_STATUS: '사용여부',
  DELETE_STATUS: '삭제여부',
  ORDER: '순서',
  REGISTRANT: '등록자',
  REGISTRATION_DATE: '등록일',
  REGISTRATION_DATE_TIME: '등록일시',
  MODIFIER: '수정자',
  MODIFY_DATE: '수정일',
  MODIFY_DATE_TIME: '수정일시',
  TARGET: '대상',
  NON_TARGET: '비대상',
  OTHERS: '기타',
  GROUP_NAME: '그룹명',
  INDIVIDUAL: '개인',
  CONTAINS: '포함',
  BETWEEN: '사이',
  ENDS_WITH: '으로 끝남',
  EQUAL: '동일',
  GREATER_THAN: '보다 큼',
  GREATER_THAN_OR_EQUAL: '보다 크거나 같음',
  LESS_THAN: '보다 작음',
  LESS_THAN_OR_EQUAL: '보다 작거나 같음',
  NOT_CONTAINS: '포함하지 않음',
  NOT_EQUAL: '동일하지 않음',
  STARTS_WITH: '으로 시작함',
  IS_BLANK: '비어있음',
  IS_NOT_BLANK: '비어있지 않음',
  AND: '그리고',
  OR: '또는',
  NOT_AND: '그리고 아님',
  NOT_OR: '또는 아님',
  EXPAND_LIST: '목록 펼치기',
  COLLAPSE_LIST: '목록 접기',
  DOWNLOAD_REASON: '다운로드 사유',
  SEARCH_PERIOD: '검색기간',
  SEARCH_DATE: '조회 기간',
  SEARCH_TARGET: '조회 대상',
  FILE: '파일',
  FILE_SELECT: '파일 선택',
  METHOD: '메서드',
  RESULT: '결과',
  PHONE_NUMBER: '전화번호',
  GRADE: '직위',
  PERSONAL_INFORMATION: '인사정보',
  PERSONAL_MANAGEMENT: '인사관리',
  PATH: '경로',
  USER_STATE: '계정 상태',
  CATEGORY: '카테고리',
  MOVE_TO_TOP: '맨 위로 이동',
  MOVE_UP: '위로 이동',
  MOVE_DOWN: '아래로 이동',
  MOVE_TO_BOTTOM: '맨 아래로 이동',
  SAVE_ORDER: '순서 저장',
  SETTING: '설정',
  NOT_SETTING: '미설정',
  ADD_SETTING: '설정 추가',
  REMOVE_SETTING: '설정 해제',
  RESET: '초기화',
  PRETTIFY_CODE: '코드정리',
  DROP_FILE: '또는 여기에 파일을 드롭하세요',
  PRE_LISTENING: '미리듣기',
  LISTENING: '듣기',
  LOGIN: '로그인',
  VIEW_STATUS: '표시여부',
  SHOW_VIEW_FL: '사용중인 {value}만 보기',
  ITEM: '항목',
  STEP: '단계',
  EXCEL: '엑셀',
  EXCEL_DOWNLOAD: '엑셀 다운로드',
  ALL_DOWNLOAD: '전체 다운로드',
  SELECTED_DATA_DOWNLOAD: '선택한 데이터 다운로드',
  SUM: '합계',
  AVG: '평균',
  COUNT: '개수',
  MIN: '최소',
  MAX: '최대',
  ADD_ROW: '행 추가',
  COPY_ROW: '행 복사',
  CELL_CLIPBOARD: '열 클립보드',
  ROW_CLIPBOARD: '행 클립보드',
  LOAD: '조회',
  USER: '사용자',
  LOGIN_ID: '아이디',
  PARENT_MENU_NAME: '상위 메뉴명',
  MENU: '메뉴',
  MENU_INFO: '메뉴 정보',
  MENU_NAME: '메뉴명',
  MENU_TYPE: '메뉴 유형',
  CHILD_MENU: '하위 메뉴',
  PAGE_PATH: '페이지 경로',
  PAGE_DATA: '페이지 데이터',
  MENU_ICON: '메뉴 아이콘',
  MENU_ORDER: '메뉴 순서',
  AUTH_ADD: '권한 추가',
  MENU_ADD: '메뉴 추가',
  ROOT_MENU_ADD: '대메뉴 추가',
  FILTER: '필터',
  SEND: '전송',
  INCREASE: '상승',
  DECREASE: '하락',
  EXPANSION: '확대',
  PREVIEW: '미리보기',
  PRINT: '인쇄',
  QUESTION: '질문',
  RETURN: '돌아가기',
  MORE: '더보기',
  PASSWORD_CONFIRM: '패스워드 확인',
  PASSWORD_CHANGE: '패스워드 변경',
  PASSWORD: '패스워드',
  REQUIRED_ENTRY: '필수항목',
  AUTH: '권한',
  NORMAL: '정상',
  ABSENCE: '결근',
  LATE: '지각',
  KEY: '키',
  VALUE: '값',
  GROUP_KEY: '그룹키',
  CODE_NAME: '코드명',
  CODE_VALUE: '코드값',
  AGENT: '상담원',
  WORK_DATE: '근무일자',
  WORK_BASIC_TIME: '기본근무시간',
  WORK_OVER_TIME: '시간외근무시간',
  ROUTE_HOST_ALIAS: '호스트 별칭',
  ROUTE_ACTION_NAME: '액션명',
  ROUTE_TIMEOUT_MS: '타임아웃(ms)',
  ROUTE_WORK_LOG_FL: '작업이력 저장 여부',
  WORKER: '작업자',
  WORK_DATE_TIME: '작업일시',
};
