import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * @description 성과 - 성과지표 평가방식 Enum 클래스
 */
class PerformEvalDivision extends EnumEntry {
  ABSOLUTE = {
    ...EnumMap,
    key: 'PERFORM_EVAL_DIVISION_ABSOLUTE',
    value: 'ABSOLUTE',
    label: '절대평가',
    group: 'PERFORM_EVAL_DIVISION',
    group_label: '평가방식',
    equals: value => {
      return this.ABSOLUTE.value === value;
    },
  };

  DISTRIBUTION = {
    ...EnumMap,
    key: 'PERFORM_EVAL_DIVISION_DISTRIBUTION',
    value: 'DISTRIBUTION',
    label: '분포도평가',
    group: 'PERFORM_EVAL_DIVISION',
    group_label: '평가방식',
    equals: value => {
      return this.DISTRIBUTION.value === value;
    },
  };

  values = [this.ABSOLUTE, this.DISTRIBUTION];

  /**
   * @description 평가방식 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 평가방식 Enum 클래스
 */
class EvalDivision extends EnumEntry {
  BASIC = {
    ...EnumMap,
    key: 'EVAL_DIVISION_BASIC',
    value: 'BASIC',
    label: '일반',
    group: 'EVAL_DIVISION',
    group_label: '평가방식',
    equals: value => {
      return this.BASIC.value === value;
    },
  };

  QUALITY = {
    ...EnumMap,
    key: 'EVAL_DIVISION_QUALITY',
    value: 'QUALITY',
    label: '품질평가',
    group: 'EVAL_DIVISION',
    group_label: '평가방식',
    equals: value => {
      return this.QUALITY.value === value;
    },
  };

  COACHING = {
    ...EnumMap,
    key: 'EVAL_DIVISION_COACHING',
    value: 'COACHING',
    label: '코칭',
    group: 'EVAL_DIVISION',
    group_label: '평가방식',
    equals: value => {
      return this.COACHING.value === value;
    },
  };

  values = [this.BASIC, this.QUALITY, this.COACHING];

  /**
   * @description 평가방식 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 정답유형 Enum 클래스
 */
class AnswerType extends EnumEntry {
  CHOICE = {
    ...EnumMap,
    key: 'EXAM_ANSWER_TYPE_CHOICE',
    value: 'CHOICE',
    label: '객관식',
    group: 'answerType',
    group_label: '정답유형',
    equals: value => {
      return this.CHOICE.value === value;
    },
  };
  OX = {
    ...EnumMap,
    key: 'EXAM_ANSWER_TYPE_OX',
    value: 'OX',
    label: 'OX',
    group: 'answerType',
    group_label: '정답유형',
    equals: value => {
      return this.OX.value === value;
    },
  };
  SHORT = {
    ...EnumMap,
    key: 'EXAM_ANSWER_TYPE_SHORT',
    value: 'SHORT',
    label: '단답형',
    group: 'answerType',
    group_label: '정답유형',
    equals: value => {
      return this.SHORT.value === value;
    },
  };
  LONG = {
    ...EnumMap,
    key: 'EXAM_ANSWER_TYPE_LONG',
    value: 'LONG',
    label: '서술형',
    group: 'answerType',
    group_label: '정답유형',
    equals: value => {
      return this.LONG.value === value;
    },
  };

  values = [this.CHOICE, this.OX, this.SHORT, this.LONG];

  /**
   * @description 정답유형 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 시험채점유형 Enum 클래스
 */
class ExamScoringType extends EnumEntry {
  BASE = {
    ...EnumMap,
    key: 'EXAM_SCORING_TYPE_BASE',
    value: 'BASE',
    label: '기본',
    group: 'ExamScoring',
    group_label: '시험채점유형',
    equals: value => {
      return this.BASE.value === value;
    },
  };

  ANSWER = {
    ...EnumMap,
    key: 'EXAM_SCORING_TYPE_ANSWER',
    value: 'ANSWER',
    label: '정답기준채점',
    group: 'ExamScoring',
    group_label: '시험채점유형',
    equals: value => {
      return this.ANSWER.value === value;
    },
  };

  WRONG = {
    ...EnumMap,
    key: 'EXAM_SCORING_TYPE_WRONG',
    value: 'WRONG',
    label: '오답기준채점',
    group: 'ExamScoring',
    group_label: '시험채점유형',
    equals: value => {
      return this.WRONG.value === value;
    },
  };

  values = [this.BASE, this.ANSWER, this.WRONG];

  /**
   * @description 시험채점유형 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 시험난이도 Enum 클래스
 */
class ExamLevel extends EnumEntry {
  UPPER = {
    ...EnumMap,
    key: 'EXAM_LEVEL_UPPER',
    value: 'UPPER',
    label: '높음',
    group: 'examLevel',
    group_label: '시험난이도',
    equals: value => {
      return this.UPPER.value === value;
    },
  };

  MIDDLE = {
    ...EnumMap,
    key: 'EXAM_LEVEL_MIDDLE',
    value: 'MIDDLE',
    label: '보통',
    group: 'examLevel',
    group_label: '시험난이도',
    equals: value => {
      return this.MIDDLE.value === value;
    },
  };

  LOWER = {
    ...EnumMap,
    key: 'EXAM_LEVEL_LOWER',
    value: 'LOWER',
    label: '낮음',
    group: 'examLevel',
    group_label: '시험난이도',
    equals: value => {
      return this.LOWER.value === value;
    },
  };

  values = [this.UPPER, this.MIDDLE, this.LOWER];

  /**
   * @description 시험난이도 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 시험진행상태 Enum 클래스 <br>
 * 순서를 결정해야하므로 key는 number 타입을 사용해 순서를 표시한다.
 */
class ExamProcess extends EnumEntry {
  PROGRESS_READY = {
    ...EnumMap,
    key: 1,
    value: 'PROGRESS_READY',
    label: '대기',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.PROGRESS_READY.value === value;
    },
  };

  PROGRESS = {
    ...EnumMap,
    key: 2,
    value: 'PROGRESS',
    label: '시험진행',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.PROGRESS.value === value;
    },
  };

  COMPLETE = {
    ...EnumMap,
    key: 3,
    value: 'COMPLETE',
    label: '시험완료',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.COMPLETE.value === value;
    },
  };

  MARKING = {
    ...EnumMap,
    key: 4,
    value: 'MARKING',
    label: '채점진행',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.MARKING.value === value;
    },
  };

  MARKING_COMPLETE = {
    ...EnumMap,
    key: 5,
    value: 'MARKING_COMPLETE',
    label: '채점완료',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.MARKING_COMPLETE.value === value;
    },
  };

  FEEDBACK = {
    ...EnumMap,
    key: 6,
    value: 'FEEDBACK',
    label: '피드백',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.FEEDBACK_COMPLETE.value === value;
    },
  };

  FEEDBACK_COMPLETE = {
    ...EnumMap,
    key: 7,
    value: 'FEEDBACK_COMPLETE',
    label: '피드백완료',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.FEEDBACK_COMPLETE.value === value;
    },
  };

  FINISH = {
    ...EnumMap,
    key: 8,
    value: 'FINISH',
    label: '마감',
    group: 'EXAM_PROCESS',
    group_label: '시험진행상태',
    equals: value => {
      return this.FINISH.value === value;
    },
  };

  values = [
    this.PROGRESS_READY,
    this.PROGRESS,
    this.FINISH,
    this.FEEDBACK_COMPLETE,
    this.FEEDBACK,
    this.COMPLETE,
    this.MARKING,
    this.MARKING_COMPLETE,
  ];

  /**
   * @description 시험진행상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 시험종류 Enum 클래스
 */
class ExamSheetType extends EnumEntry {
  BASIC = {
    ...EnumMap,
    key: 'EXAM_SHEET_TYPE_BASIC',
    value: 'BASIC',
    label: '일반',
    group: 'SHEET_TYPE',
    group_label: '시험종류',
    equals: value => {
      return this.BASIC.value === value;
    },
  };

  ADVANCE = {
    ...EnumMap,
    key: 'EXAM_SHEET_TYPE_ADVANCE',
    value: 'ADVANCE',
    label: '진급시험',
    group: 'SHEET_TYPE',
    group_label: '시험종류',
    equals: value => {
      return this.ADVANCE.value === value;
    },
  };

  values = [this.BASIC, this.ADVANCE];

  /**
   * @description 시험종류 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 참석 플래그 Enum 클래스
 */
class Join extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'JOIN_YES',
    value: 979,
    label: '참석',
    group: 'JOIN',
    group_label: '참석여부',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'JOIN_NO',
    value: 980,
    label: '불참',
    group: 'JOIN',
    group_label: '참석여부',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 참석여부 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 휴일근무배정 Enum 클래스
 */
class HolidayWorkAssignment extends EnumEntry {
  ALL_INCLUDED = {
    ...EnumMap,
    key: 'HOLIDAY_WORK_ASSIGNMENT_ALL_INCLUDED',
    value: 'Y',
    label: '휴일포함',
    group: 'HOLIDAY_WORK_ASSIGNMENT',
    group_label: '휴일배정여부',
    equals: value => {
      return this.ALL_INCLUDED.value === value;
    },
  };

  NOT_INCLUDED = {
    ...EnumMap,
    key: 'HOLIDAY_WORK_ASSIGNMENT_NOT_INCLUDED',
    value: 'N',
    label: '휴일제외',
    group: 'HOLIDAY_WORK_ASSIGNMENT',
    group_label: '휴일배정여부',
    equals: value => {
      return this.NOT_INCLUDED.value === value;
    },
  };

  values = [this.ALL_INCLUDED, this.NOT_INCLUDED];

  /**
   * @description 휴일배정여부 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 근무종류 Enum 클래스
 * @enum {EnumMap}
 */
class WorkCategory extends EnumEntry {
  BASIC = {
    ...EnumMap,
    key: 'WORK_CATEGORY_BASIC',
    value: 'BASIC',
    label: '일반',
    group: 'WORK_CATEGORY',
    group_label: '근무종류',
    equals: value => {
      return this.BASIC.value === value;
    },
  };

  OVER_TIME = {
    ...EnumMap,
    key: 'WORK_CATEGORY_OVER_TIME',
    value: 'OVER_TIME',
    label: '시간외',
    group: 'WORK_CATEGORY',
    group_label: '근무종류',
    equals: value => {
      return this.OVER_TIME.value === value;
    },
  };

  values = [this.BASIC, this.OVER_TIME];

  /**
   * @description 근무종류 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 근무상태 Enum 클래스
 */
class WorkStatus extends EnumEntry {
  WORK_DAY = {
    ...EnumMap,
    key: 'WORK_STATUS_WORK_DAY',
    value: 'WORK_DAY',
    label: '근무',
    group: 'WORK_STATUS',
    group_label: '근무상태',
    equals: value => {
      return this.WORK_DAY.value === value;
    },
  };

  DAY_OFF = {
    ...EnumMap,
    key: 'WORK_STATUS_DAY_OFF',
    value: 'DAY_OFF',
    label: '휴무',
    group: 'WORK_STATUS',
    group_label: '근무상태',
    equals: value => {
      return this.DAY_OFF.value === value;
    },
  };

  NON_WORK_DAY = {
    ...EnumMap,
    key: 'WORK_STATUS_NON_WORK_DAY',
    value: 'NON_WORK_DAY',
    label: '비근무',
    group: 'WORK_STATUS',
    group_label: '근무상태',
    equals: value => {
      return this.NON_WORK_DAY.value === value;
    },
  };

  values = [this.WORK_DAY, this.DAY_OFF, this.NON_WORK_DAY];

  /**
   * @description 근무상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 초과근무수당계산방식 Enum 클래스
 */
class ExtraAllowanceCalculator extends EnumEntry {
  MULTIPLIER = {
    ...EnumMap,
    key: 'EXTRA_ALLOWANCE_CALCULATOR_MULTIPLIER',
    value: 'MULTIPLIER',
    label: '배율',
    group: 'EXTRA_ALLOWANCE_CALCULATOR',
    group_label: '초과근무수당계산방식',
    equals: value => {
      return this.MULTIPLIER.value === value;
    },
  };

  FIXED_HOURLY_WAGE = {
    ...EnumMap,
    key: 'EXTRA_ALLOWANCE_CALCULATOR_FIXED_HOURLY_WAGE',
    value: 'FIXED_HOURLY_WAGE',
    label: '고정시급',
    group: 'EXTRA_ALLOWANCE_CALCULATOR',
    group_label: '초과근무수당계산방식',
    equals: value => {
      return this.FIXED_HOURLY_WAGE.value === value;
    },
  };

  FIXED_DAILY_WAGE = {
    ...EnumMap,
    key: 'EXTRA_ALLOWANCE_CALCULATOR_FIXED_DAILY_WAGE',
    value: 'FIXED_DAILY_WAGE',
    label: '고정일급',
    group: 'EXTRA_ALLOWANCE_CALCULATOR',
    group_label: '초과근무수당계산방식',
    equals: value => {
      return this.FIXED_DAILY_WAGE.value === value;
    },
  };

  values = [this.MULTIPLIER, this.FIXED_HOURLY_WAGE, this.FIXED_DAILY_WAGE];

  /**
   * @description 초과근무수당계산방식 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 근무지 Enum 클래스
 */
class WorkLocation extends EnumEntry {
  CENTER = {
    ...EnumMap,
    key: 'WORK_LOCATION_CENTER',
    value: 'CENTER',
    label: '센터',
    group: 'WORK_LOCATION',
    group_label: '근무지',
    equals: value => {
      return this.CENTER.value === value;
    },
  };

  HOME = {
    ...EnumMap,
    key: 'WORK_LOCATION_HOME',
    value: 'HOME',
    label: '자택',
    group: 'WORK_LOCATION',
    group_label: '근무지',
    equals: value => {
      return this.HOME.value === value;
    },
  };

  values = [this.CENTER, this.HOME];

  /**
   * @description 근무지 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 근무시간 Enum 클래스
 */
class UserState extends EnumEntry {
  EMPLOYED = {
    ...EnumMap,
    key: 'USER_STATE_EMPLOYED',
    value: 'EMPLOYED',
    label: '재직',
    group: 'USER_STATE',
    group_label: '재직구분',
    equals: value => {
      return this.EMPLOYED.value === value;
    },
  };

  ON_LEAVE = {
    ...EnumMap,
    key: 'USER_STATE_ON_LEAVE',
    value: 'ON_LEAVE',
    label: '휴직',
    group: 'USER_STATE',
    group_label: '재직구분',
    equals: value => {
      return this.ON_LEAVE.value === value;
    },
  };

  RETIRED = {
    ...EnumMap,
    key: 'USER_STATE_RETIRED',
    value: 'RETIRED',
    label: '퇴직',
    group: 'USER_STATE',
    group_label: '재직구분',
    equals: value => {
      return this.RETIRED.value === value;
    },
  };

  WAITING = {
    ...EnumMap,
    key: 'USER_STATE_WAITING',
    value: 'WAITING',
    label: '대기',
    group: 'USER_STATE',
    group_label: '재직구분',
    equals: value => {
      return this.WAITING.value === value;
    },
  };

  values = [this.EMPLOYED, this.ON_LEAVE, this.RETIRED, this.WAITING];

  /**
   * @description 재직구분 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 인사 - 휴가유형관리 시간옵션 Enum 클래스
 * @enum {EnumMap}
 */
class HrVacationTimeType extends EnumEntry {
  ALL = {
    ...EnumMap,
    key: 'HR_VACATION_TIME_TYPE_ALL',
    value: 1233,
    label: '종일',
    group: 'HR_VACATION_TIME_TYPE',
    group_label: '시간옵션',
    equals: value => {
      return this.ALL.value === value;
    },
  };

  DIVISION = {
    ...EnumMap,
    key: 'HR_VACATION_TIME_TYPE_DIVISION',
    value: 1234,
    label: '시간구분',
    group: 'HR_VACATION_TIME_TYPE',
    group_label: '시간옵션',
    equals: value => {
      return this.DIVISION.value === value;
    },
  };

  values = [this.ALL, this.DIVISION];

  /**
   * @description 시간옵션 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 교육 - 진행방식 Enum 클래스
 * @enum {EnumMap}
 */
class EduEducationProcessDivision extends EnumEntry {
  OFFLINE = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_DIVISION_OFFLINE',
    value: 921,
    label: '오프라인',
    group: 'EDU_EDUCATION_PROCESS_DIVISION',
    group_label: '교육진행방식',
    equals: value => {
      return this.OFFLINE.value === value;
    },
  };

  ONLINE = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_DIVISION_ONLINE',
    value: 920,
    label: '온라인',
    group: 'EDU_EDUCATION_PROCESS_DIVISION',
    group_label: '교육진행방식',
    equals: value => {
      return this.ONLINE.value === value;
    },
  };

  values = [this.OFFLINE, this.ONLINE];

  /**
   * @description 교육진행방식 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 교육 - 교육진행상태 Enum 클래스
 */
class EduEducationProcess extends EnumEntry {
  READY = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_READY',
    value: 931,
    label: '대기',
    group: 'EDU_EDUCATION_PROCESS',
    group_label: '교육진행상태',
    equals: value => {
      return this.READY.value === value;
    },
  };

  PROGRESS = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_PROGRESS',
    value: 926,
    label: '교육진행',
    group: 'EDU_EDUCATION_PROCESS',
    group_label: '교육진행상태',
    equals: value => {
      return this.PROGRESS.value === value;
    },
  };

  PROGRESS_COMPLETE = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_PROGRESS_COMPLETE',
    value: 927,
    label: '교육완료',
    group: 'EDU_EDUCATION_PROCESS',
    group_label: '교육진행상태',
    equals: value => {
      return this.PROGRESS_COMPLETE.value === value;
    },
  };

  FEEDBACK = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_FEEDBACK',
    value: 928,
    label: '피드백진행',
    group: 'EDU_EDUCATION_PROCESS',
    group_label: '교육진행상태',
    equals: value => {
      return this.FEEDBACK.value === value;
    },
  };

  FEEDBACK_COMPLETE = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_FEEDBACK_COMPLETE',
    value: 929,
    label: '피드백완료',
    group: 'EDU_EDUCATION_PROCESS',
    group_label: '교육진행상태',
    equals: value => {
      return this.FEEDBACK_COMPLETE.value === value;
    },
  };

  FINISH = {
    ...EnumMap,
    key: 'EDU_EDUCATION_PROCESS_FINISH',
    value: 930,
    label: '교육마감',
    group: 'EDU_EDUCATION_PROCESS',
    group_label: '교육진행상태',
    equals: value => {
      return this.FINISH.value === value;
    },
  };

  values = [this.READY, this.PROGRESS, this.PROGRESS_COMPLETE, this.FEEDBACK, this.FEEDBACK_COMPLETE, this.FINISH];

  /**
   * @description 교육진행상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 평가 - 평가진행상태 Enum 클래스
 */
class EvalProcess extends EnumEntry {
  READY = {
    ...EnumMap,
    key: 'EVAL_PROCESS_READY',
    value: 1182,
    label: '대기',
    group: 'EVAL_PROCESS',
    group_label: '평가진행관리',
    equals: value => {
      return this.READY.value === value;
    },
  };

  PROGRESS = {
    ...EnumMap,
    key: 'EVAL_PROCESS_PROGRESS',
    value: 1177,
    label: '평가진행',
    group: 'EVAL_PROCESS',
    group_label: '평가진행관리',
    equals: value => {
      return this.PROGRESS.value === value;
    },
  };

  PROGRESS_COMPLETE = {
    ...EnumMap,
    key: 'EVAL_PROCESS_PROGRESS_COMPLETE',
    value: 1178,
    label: '평가완료',
    group: 'EVAL_PROCESS',
    group_label: '평가진행관리',
    equals: value => {
      return this.PROGRESS_COMPLETE.value === value;
    },
  };

  FEEDBACK = {
    ...EnumMap,
    key: 'EVAL_PROCESS_FEEDBACK',
    value: 1179,
    label: '피드백진행',
    group: 'EVAL_PROCESS',
    group_label: '평가진행관리',
    equals: value => {
      return this.FEEDBACK.value === value;
    },
  };

  FEEDBACK_COMPLETE = {
    ...EnumMap,
    key: 'EVAL_PROCESS_FEEDBACK_COMPLETE',
    value: 1180,
    label: '피드백완료',
    group: 'EVAL_PROCESS',
    group_label: '평가진행관리',
    equals: value => {
      return this.FEEDBACK_COMPLETE.value === value;
    },
  };

  FINISH = {
    ...EnumMap,
    key: 'EVAL_PROCESS_FINISH',
    value: 1181,
    label: '마감',
    group: 'EVAL_PROCESS',
    group_label: '평가진행관리',
    equals: value => {
      return this.FINISH.value === value;
    },
  };

  values = [this.READY, this.PROGRESS, this.PROGRESS_COMPLETE, this.FEEDBACK, this.FEEDBACK_COMPLETE, this.FINISH];

  /**
   * @description 평가진행상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 평가 - 녹취추출방식 Enum 클래스
 * @enum {EnumMap}
 */
class EvalRecDrawYype extends EnumEntry {
  AUTO = {
    ...EnumMap,
    key: 'EVAL_REC_DRAW_TYPE_AUTO',
    value: 1268,
    label: '자동',
    group: 'EVAL_REC_DRAW_TYPE',
    group_label: '녹취추출방식',
    equals: value => {
      return this.AUTO.value === value;
    },
  };

  MANUAL = {
    ...EnumMap,
    key: 'EVAL_REC_DRAW_TYPE_MANUAL',
    value: 1269,
    label: '수동',
    group: 'EVAL_REC_DRAW_TYPE',
    group_label: '녹취추출방식',
    equals: value => {
      return this.MANUAL.value === value;
    },
  };

  values = [this.AUTO, this.MANUAL];

  /**
   * @description 녹취추출방식 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 평가 - 콜구분 Enum 클래스
 * @enum {EnumMap}
 */
class EvalCallYype extends EnumEntry {
  IN = {
    ...EnumMap,
    key: 'EVAL_CALL_TYPE_IN',
    value: 1271,
    label: 'IN',
    group: 'EVAL_CALL_TYPE',
    group_label: '녹취추출방식',
    equals: value => {
      return this.IN.value === value;
    },
  };

  OUT = {
    ...EnumMap,
    key: 'EVAL_CALL_TYPE_OUT',
    value: 1272,
    label: 'OUT',
    group: 'EVAL_CALL_TYPE',
    group_label: '녹취추출방식',
    equals: value => {
      return this.OUT.value === value;
    },
  };

  values = [this.IN, this.OUT];

  /**
   * @description 녹취추출방식 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 평가 - 우수콜구분 Enum 클래스
 */
class EvalBwCall extends EnumEntry {
  BEST = {
    ...EnumMap,
    key: 'EVAL_BWCALL_BEST',
    value: 1185,
    label: '베스트콜',
    group: 'EVAL_BWCALL',
    group_label: '콜구분',
    equals: value => {
      return this.BEST.value === value;
    },
  };

  WORST = {
    ...EnumMap,
    key: 'EVAL_BWCALL_WORST',
    value: 1186,
    label: '워스트콜',
    group: 'EVAL_BWCALL',
    group_label: '콜구분',
    equals: value => {
      return this.WORST.value === value;
    },
  };

  values = [this.BEST, this.WORST];

  /**
   * @description 콜구분 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 설문 - 설문진행상태 Enum 클래스
 */
class SurProcess extends EnumEntry {
  READY = {
    ...EnumMap,
    key: 'SUR_PROCESS_READY',
    value: 1099,
    label: '대기',
    group: 'SUR_PROCESS',
    group_label: '설문진행관리',
    equals: value => {
      return this.READY.value === value;
    },
  };

  PROGRESS_TEMP = {
    ...EnumMap,
    key: 'SUR_PROCESS_PROGRESS_TEMP',
    value: 1112,
    label: '임시저장',
    group: 'SUR_PROCESS',
    group_label: '설문진행관리',
    equals: value => {
      return this.PROGRESS_TEMP.value === value;
    },
  };

  PROGRESS = {
    ...EnumMap,
    key: 'SUR_PROCESS_PROGRESS',
    value: 1100,
    label: '설문진행',
    group: 'SUR_PROCESS',
    group_label: '설문진행관리',
    equals: value => {
      return this.PROGRESS.value === value;
    },
  };

  PROGRESS_STOP = {
    ...EnumMap,
    key: 'SUR_PROCESS_PROGRESS_STOP',
    value: 1111,
    label: '중지',
    group: 'SUR_PROCESS',
    group_label: '설문진행관리',
    equals: value => {
      return this.PROGRESS_STOP.value === value;
    },
  };

  PROGRESS_COMPLETE = {
    ...EnumMap,
    key: 'SUR_PROCESS_PROGRESS_COMPLETE',
    value: 1101,
    label: '설문완료',
    group: 'SUR_PROCESS',
    group_label: '설문진행관리',
    equals: value => {
      return this.PROGRESS_COMPLETE.value === value;
    },
  };

  values = [this.READY, this.PROGRESS_TEMP, this.PROGRESS, this.PROGRESS_STOP, this.PROGRESS_COMPLETE];

  /**
   * @description 설문진행상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 결재선 유형 enum 클래스
 */
class ApprovalType extends EnumEntry {
  ATTENDANCE = {
    ...EnumMap,
    key: 'APPROVAL_TYPE_ATTENDANCE',
    value: 'ATTENDANCE',
    label: '근태변경신청',
    group: 'APPROVAL_TYPE',
    group_label: '결재선유형',
    equals: value => {
      return this.ATTENDANCE.value === value;
    },
  };

  WORK = {
    ...EnumMap,
    key: 'APPROVAL_TYPE_WORK',
    value: 'WORK',
    label: '근무변경신청',
    group: 'APPROVAL_TYPE',
    group_label: '결재선유형',
    equals: value => {
      return this.WORK.value === value;
    },
  };

  VACATION = {
    ...EnumMap,
    key: 'APPROVAL_TYPE_VACATION',
    value: 'VACATION',
    label: '휴가신청',
    group: 'APPROVAL_TYPE',
    group_label: '결재선유형',
    equals: value => {
      return this.VACATION.value === value;
    },
  };

  values = [this.ATTENDANCE, this.WORK, this.VACATION];

  /**
   * @description 결재선유형 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 결재상태(결재 단계) enum 클래스
 */
class ApprovalStatus extends EnumEntry {
  PENDING = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_PENDING',
    value: 'PENDING',
    label: '대기',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.PENDING.value === value;
    },
  };

  IN_PROGRESS = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_IN_PROGRESS',
    value: 'IN_PROGRESS',
    label: '진행중',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.IN_PROGRESS.value === value;
    },
  };

  APPROVAL = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_APPROVAL',
    value: 'APPROVAL',
    label: '승인',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.APPROVAL.value === value;
    },
  };

  APPROVAL_CANCEL = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_APPROVAL_CANCEL',
    value: 'APPROVAL_CANCEL',
    label: '승인취소',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.APPROVAL_CANCEL.value === value;
    },
  };

  RE_SUBMISSION = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_RE_SUBMISSION',
    value: 'RE_SUBMISSION',
    label: '재결재',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.RE_SUBMISSION.value === value;
    },
  };

  FORCE_APPROVAL = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_FORCE_APPROVAL',
    value: 'FORCE_APPROVAL',
    label: '전결',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.FORCE_APPROVAL.value === value;
    },
  };

  REJECTED = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_REJECTED',
    value: 'REJECTED',
    label: '반려',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.REJECTED.value === value;
    },
  };

  REVOKED = {
    ...EnumMap,
    key: 'APPROVAL_STATUS_REVOKED',
    value: 'REVOKED',
    label: '결재취소',
    group: 'APPROVAL_STATUS',
    group_label: '결재상태',
    equals: value => {
      return this.REVOKED.value === value;
    },
  };

  values = [
    this.PENDING,
    this.IN_PROGRESS,
    this.APPROVAL,
    this.APPROVAL_CANCEL,
    this.RE_SUBMISSION,
    this.FORCE_APPROVAL,
    this.REJECTED,
    this.REVOKED,
  ];

  /**
   * @description 결재상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 근무일정 변경이력 유형 enum 클래스
 */
class WorkScheduleRevisionType extends EnumEntry {
  INSERT = {
    ...EnumMap,
    key: 'WORK_SCHEDULE_REVISION_TYPE_INSERT',
    value: 0,
    label: '신규',
    group: 'WORK_SCHEDULE_REVISION_TYPE',
    group_label: '근무일정 변경이력 유형',
    equals: value => {
      return this.INSERT.value === value;
    },
  };

  UPDATE = {
    ...EnumMap,
    key: 'WORK_SCHEDULE_REVISION_TYPE_UPDATE',
    value: 1,
    label: '변경',
    group: 'WORK_SCHEDULE_REVISION_TYPE',
    group_label: '근무일정 변경이력 유형',
    equals: value => {
      return this.UPDATE.value === value;
    },
  };

  DELETE = {
    ...EnumMap,
    key: 'WORK_SCHEDULE_REVISION_TYPE_DELETE',
    value: 2,
    label: '삭제',
    group: 'WORK_SCHEDULE_REVISION_TYPE',
    group_label: '근무일정 변경이력 유형',
    equals: value => {
      return this.DELETE.value === value;
    },
  };

  UPDATE_REQUEST = {
    ...EnumMap,
    key: 'WORK_SCHEDULE_REVISION_TYPE_UPDATE_REQUEST',
    value: 3,
    label: '근무변경신청',
    group: 'WORK_SCHEDULE_REVISION_TYPE',
    group_label: '근무일정 변경이력 유형',
    equals: value => {
      return this.UPDATE_REQUEST.value === value;
    },
  };

  DELETE_REQUEST = {
    ...EnumMap,
    key: 'WORK_SCHEDULE_REVISION_TYPE_DELETE_REQUEST',
    value: 4,
    label: '근무삭제신청',
    group: 'WORK_SCHEDULE_REVISION_TYPE',
    group_label: '근무일정 변경이력 유형',
    equals: value => {
      return this.DELETE_REQUEST.value === value;
    },
  };

  values = [this.INSERT, this.UPDATE, this.DELETE, this.UPDATE_REQUEST, this.DELETE_REQUEST];

  /**
   * @description 근무일정 변경이력 유형 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 인사발령구분 Enum 클래스
 */
class PersonnelAppointmentCategory extends EnumEntry {
  NEW_HIRE = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_CATEGORY_NEW_HIRE',
    value: 'NEW_HIRE',
    label: '신규입사',
    group: 'PERSONNEL_APPOINTMENT_CATEGORY',
    group_label: '인사발령구분',
    equals: value => {
      return this.NEW_HIRE.value === value;
    },
  };

  TRANSFER = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_CATEGORY_TRANSFER',
    value: 'TRANSFER',
    label: '인사이동',
    group: 'PERSONNEL_APPOINTMENT_CATEGORY',
    group_label: '인사발령구분',
    equals: value => {
      return this.TRANSFER.value === value;
    },
  };

  RESIGNATION = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_CATEGORY_RESIGNATION',
    value: 'RESIGNATION',
    label: '퇴직',
    group: 'PERSONNEL_APPOINTMENT_CATEGORY',
    group_label: '인사발령구분',
    equals: value => {
      return this.RESIGNATION.value === value;
    },
  };

  LEAVE_OF_ABSENCE = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_CATEGORY_LEAVE_OF_ABSENCE',
    value: 'LEAVE_OF_ABSENCE',
    label: '휴직',
    group: 'PERSONNEL_APPOINTMENT_CATEGORY',
    group_label: '인사발령구분',
    equals: value => {
      return this.LEAVE_OF_ABSENCE.value === value;
    },
  };

  values = [this.NEW_HIRE, this.TRANSFER, this.RESIGNATION, this.LEAVE_OF_ABSENCE];

  /**
   * @description 인사발령구분 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 인사발령상태 Enum 클래스
 */
class PersonnelAppointmentState extends EnumEntry {
  WAITING = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_STATE_WAITING',
    value: 'WAITING',
    label: '대기',
    group: 'PERSONNEL_APPOINTMENT_STATE',
    group_label: '인사발령상태',
    equals: value => {
      return this.WAITING.value === value;
    },
  };

  SUCCESS = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_STATE_SUCCESS',
    value: 'SUCCESS',
    label: '완료',
    group: 'PERSONNEL_APPOINTMENT_STATE',
    group_label: '인사발령상태',
    equals: value => {
      return this.SUCCESS.value === value;
    },
  };

  CANCEL = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_STATE_CANCEL',
    value: 'CANCEL',
    label: '취소',
    group: 'PERSONNEL_APPOINTMENT_STATE',
    group_label: '인사발령상태',
    equals: value => {
      return this.CANCEL.value === value;
    },
  };

  FAIL = {
    ...EnumMap,
    key: 'PERSONNEL_APPOINTMENT_STATE_FAIL',
    value: 'FAIL',
    label: '실패',
    group: 'PERSONNEL_APPOINTMENT_STATE',
    group_label: '인사발령상태',
    equals: value => {
      return this.FAIL.value === value;
    },
  };

  values = [this.WAITING, this.SUCCESS, this.CANCEL, this.FAIL];

  /**
   * @description 인사발령상태 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * 근태유형 타입
 */
class AttendanceType extends EnumEntry {
  NORMAL = {
    ...EnumMap,
    key: 'ATTENDANCE_TYPE_NORMAL',
    value: 'NORMAL',
    label: '정상',
    group: 'ATTENDANCE_TYPE',
    group_label: '근태유형',
    equals: value => {
      return this.NORMAL.value === value;
    },
  };

  TARDINESS = {
    ...EnumMap,
    key: 'ATTENDANCE_TYPE_TARDINESS',
    value: 'TARDINESS',
    label: '지각',
    group: 'ATTENDANCE_TYPE',
    group_label: '근태유형',
    equals: value => {
      return this.TARDINESS.value === value;
    },
  };

  ABSENT = {
    ...EnumMap,
    key: 'ATTENDANCE_TYPE_ABSENT',
    value: 'ABSENT',
    label: '결근',
    group: 'ATTENDANCE_TYPE',
    group_label: '근태유형',
    equals: value => {
      return this.ABSENT.value === value;
    },
  };

  DAYOFF = {
    ...EnumMap,
    key: 'ATTENDANCE_TYPE_DAYOFF',
    value: 'DAYOFF',
    label: '조퇴',
    group: 'ATTENDANCE_TYPE',
    group_label: '근태유형',
    equals: value => {
      return this.DAYOFF.value === value;
    },
  };

  VACATION = {
    ...EnumMap,
    key: 'ATTENDANCE_TYPE_VACATION',
    value: 'VACATION',
    label: '휴가',
    group: 'ATTENDANCE_TYPE',
    group_label: '근태유형',
    equals: value => {
      return this.VACATION.value === value;
    },
  };

  values = [this.NORMAL, this.TARDINESS, this.ABSENT, this.DAYOFF, this.VACATION];

  /**
   * @description
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

function getEnumAllCodes() {
  return [].concat(
    new AnswerType().values,
    new ApprovalStatus().values,
    new ApprovalType().values,
    new EduEducationProcess().values,
    new EduEducationProcessDivision().values,
    new EvalBwCall().values,
    new EvalCallYype().values,
    new EvalDivision().values,
    new EvalProcess().values,
    new EvalRecDrawYype().values,
    new ExamLevel().values,
    new ExamProcess().values,
    new ExamScoringType().values,
    new ExamSheetType().values,
    new ExtraAllowanceCalculator().values,
    new HolidayWorkAssignment().values,
    new HrVacationTimeType().values,
    new Join().values,
    new PerformEvalDivision().values,
    new SurProcess().values,
    new UserState().values,
    new WorkCategory().values,
    new WorkLocation().values,
    new WorkStatus().values,
    new WorkScheduleRevisionType().values,
    new PersonnelAppointmentCategory().values,
    new PersonnelAppointmentState().values,
    new AttendanceType().values,
  );
}

export default {
  answerType: new AnswerType(),
  approvalStatus: new ApprovalStatus(),
  approvalType: new ApprovalType(),
  eduEducationProcess: new EduEducationProcess(),
  eduEducationProcessDivision: new EduEducationProcessDivision(),
  evalBwCall: new EvalBwCall(),
  evalCallYype: new EvalCallYype(),
  evalDivision: new EvalDivision(),
  evalProcess: new EvalProcess(),
  evalRecDrawYype: new EvalRecDrawYype(),
  examLevel: new ExamLevel(),
  examProcess: new ExamProcess(),
  examScoringType: new ExamScoringType(),
  examSheetType: new ExamSheetType(),
  extraAllowanceCalculator: new ExtraAllowanceCalculator(),
  getEnumAllCodes: getEnumAllCodes(),
  holidayWorkAssignment: new HolidayWorkAssignment(),
  hrVacationTimeType: new HrVacationTimeType(),
  join: new Join(),
  performEvalDivision: new PerformEvalDivision(),
  surProcess: new SurProcess(),
  userState: new UserState(),
  workCategory: new WorkCategory(),
  workLocation: new WorkLocation(),
  workStatus: new WorkStatus(),
  workScheduleRevisionType: new WorkScheduleRevisionType(),
  personnelAppointmentCategory: new PersonnelAppointmentCategory(),
  personnelAppointmentState: new PersonnelAppointmentState(),
  attendanceType: new AttendanceType(),
};
