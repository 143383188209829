import RequestType from '@/pages/euc/phone/request/type';
import RequestTypeTable from '@/pages/euc/phone/request/type/table.vue'

export default function RequestTypeRoutes(commonComponents) {
    return [
        {
            path: '/euc/phone/request/type',
            name: 'RequestType',
            components: { default: RequestType, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/phone/request/type/table',
            name: 'RequestTypeTable',
            components: { default: RequestTypeTable, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ]
}