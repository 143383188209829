import { espApi } from '@/api';
import { isSuccess } from '@/utils/common-lib';
import { getRouterInstance } from '@/router';
import { resetVuexState } from '@/router/router-utils';

/**
 * 로그인 정보 설정
 * @param commit
 * @param res
 */
const setStore = async (commit, res) => {
  const promises = [commit('setUser', res.data.data), commit('setPersistedAccessToken', res.data.data.accessToken)];
  await Promise.all(promises);
};

/**
 * 에러 핸들러
 * @param error
 * @return {*|e.Response|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse}
 */
const errorHandle = error => {
  if (error.response) {
    // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
    return error.response;
  } else if (error.request) {
    // 요청이 이루어 졌으나 응답을 받지 못했습니다.
    return error.request;
  } else {
    // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
    return error.message;
  }
};

/**
 * 로그인
 * @param commit
 * @param payload
 * @return Promise
 * @constructor
 */
export const LOGIN = async ({ commit }, payload) => {
  try {
    const res = await espApi.getLogin(payload);
    if (isSuccess(res)) {
      await setStore(commit, res);
    }
    return res;
  } catch (error) {
    return errorHandle(error);
  }
};

/**
 * 토큰 검증 및 사용자 상태 체크
 * @param commit
 * @param payload
 * @return Promise
 * @constructor
 */
export const VERIFY_TOKEN_AND_MEMBER_STATE = async ({ commit }, payload) => {
  try {
    const res = await espApi.verifyTokenAndMemberState(payload);
    if (isSuccess(res)) {
      await setStore(commit, res);
    }
    return res;
  } catch (error) {
    return errorHandle(error);
  }
};

/**
 * 로그아웃
 * @param commit
 * @param payload
 * @return {Promise<AxiosResponse<any>>}
 * @constructor
 */
export const LOGOUT = async ({ commit }, payload) => {
  try {
    await espApi.getLogout(payload);
    await resetVuexState(); // vuex 초기화
    await getRouterInstance().push({
      name: 'Login',
      params: {
        status: payload.status,
      },
    });
  } catch (error) {
    await resetVuexState(); // vuex 초기화
    await getRouterInstance().push({
      name: 'Login',
      params: {
        status: payload.status,
      },
    });
  }
};

/**
 * AES256 암호화된 로그인 ID가 있을 경우 다이렉트 로그인
 * With. KB증권
 * @param commit
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const LOGIN_DIRECT = async ({ commit }, payload) => {
  try {
    const res = await espApi.getDirectLogin(payload);
    if (isSuccess(res)) {
      await setStore(commit, res);
    }
    return res;
  } catch (error) {
    return errorHandle(error);
  }
};
