<!--
  PACKAGE_NAME : src\pages\ewm\approval\integration
  FILE_NAME : index
  AUTHOR : devyoon91
  DATE : 2024-05-30
  DESCRIPTION : 결재함 관리
-->
<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="inner alL flex items-center">
            <DateRangeBox
              ref="dateRangeBox"
              label="신청일자"
              :start-dt="searchType.customTypes.dayStart"
              :end-dt="searchType.customTypes.dayEnd"
              :on-value-changed="onDateRangeBoxValueChanged"
            />
            <DxSelectBox
              ref="approvalStatusSelectBox"
              v-model="approvalStatus"
              styling-mode="outlined"
              :items="approvalStatusSelectBox"
              display-expr="label"
              value-expr="value"
              placeholder="결재상태"
              width="100px"
              class="mr-4"
              @value-changed="onApprovalStatusChange"
            >
            </DxSelectBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
          </div>
        </div>
      </div>
      <div class="flex gap-x-6 h-auto">
        <div class="w-6/12">
          <esp-dx-data-grid :data-grid="approvalRequestGrid" :ref="approvalRequestGrid.refName" @row-click="onRequestGridRowClick" />
          <DxToolTip :ref="approversTooltipRefName" position="right">
            <div>
              <div><b>결재자:</b> {{ toolTipCurrentApprovers }}</div>
            </div>
          </DxToolTip>
        </div>
        <div class="w-6/12 gap-y-4">
          <h3 class="text-xl font-medium" style="padding-top: 6px; margin-bottom: 16px">결재 신청 정보</h3>
          <div>
            <table class="table_form line-bin">
              <colgroup>
                <col style="width: 120px" />
                <col style="width: auto" />
              </colgroup>
              <tbody>
                <tr style="height: 70px">
                  <th scope="row">
                    <label for="label01">신청자</label>
                  </th>
                  <td>
                    <div v-html="approvalDetail.requestUserInfo"></div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">신청 정보</label>
                  </th>
                  <td>
                    <DxTextArea v-model="approvalDetail.requestInfo" styling-mode="filled" :readOnly="true" class="mar_ri10 alB" />
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">신청 사유</label>
                  </th>
                  <td>
                    <DxTextArea v-model="approvalDetail.requestReason" styling-mode="filled" :readOnly="true" class="mar_ri10 alB" />
                  </td>
                </tr>
                <tr style="height: 80px">
                  <th scope="row">
                    <label for="label01">첨부 파일</label>
                  </th>
                  <td>
                    <div v-for="(item, index) in approvalDetail.fileItems" :key="index">
                      <a style="cursor: pointer" @click="onFileDownload(item)" download>{{ item.originalFileFullName }}</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="form-bottom-button-wrapper">
              <DxButton
                class="btn_XS default filled"
                text="승인"
                width="60"
                height="30"
                :visible="approvalBtnVisible"
                @click="onOpenPopup('승인', '선택한 결재 요청건을 승인합니다.')"
              />
              <DxButton
                class="btn_XS white light_filled"
                text="반려"
                width="60"
                height="30"
                :visible="rejectedBtnVisible"
                @click="onOpenPopup('반려', '반려 상태로 변경된 결재는 다시 진행할 수 없습니다.')"
              />
              <DxButton
                class="btn_XS white light_filled"
                text="전결"
                width="60"
                height="30"
                :visible="forceApprovalBtnVisible"
                @click="onOpenPopup('전결', '전결 즉시 결재 완료 처리합니다.')"
              />
              <DxButton
                class="btn_XS white light_filled"
                text="승인 취소"
                width="80"
                height="30"
                :visible="approvalCancelBtnVisible"
                @click="onCancelApproval"
              />
            </div>
          </div>
          <h3 class="text-xl font-medium myList-4">결재 진행 정보</h3>
          <div id="approval-progress">
            <esp-dx-data-grid :data-grid="processGrid" :ref="processGrid.refName" />
          </div>
        </div>
      </div>
    </div>
    <ModalPageApproval
      ref="modalPageApproval"
      :is-open="popup.visible"
      :approval-status="popup.approvalStatus"
      :description="popup.description"
      @closeModal="onCloseModal"
      @saveModal="onSaveModal"
    />
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { getPastFromToday, isEmpty, isSuccess } from '@/utils/common-lib';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import moment from 'moment';

  import DxToolTip from 'devextreme-vue/tooltip';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import ModalPageApproval from '@/pages/ewm/approval/integration/popup/modal-page-approval.vue';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: {
      EspDxDataGrid,
      ModalPageApproval,
      DxTextArea,
      DateRangeBox,
      DxButton,
      DxSelectBox,
      DxToolTip,
    },
    props: {},
    watch: {},
    data() {
      return {
        searchType: {
          customTypes: {
            dayStart: getPastFromToday(14, 'days'),
            dayEnd: moment().format('YYYYMMDD'),
          },
        },
        popup: {
          visible: false,
          approvalStatus: {},
          description: '',
        },
        toolTipCurrentApprovers: '',
        approversTooltipRefName: 'approversTooltip',
        approvalStatusSelectBox: [],
        approvalStatus: '', // 결재 상태 셀렉트 박스 값
        selectedFinalApprovalStatus: '', // 선택된 최종 결재 상태
        userApprovalStatus: '', // 조회 사용자 결재 상태
        approvalRequestGrid: {
          keyExpr: 'approvalId',
          refName: 'approvalRequestGrid',
          allowColumnResizing: false,
          columnResizingMode: 'widget',
          columnAutoWidth: false,
          focusedRowEnabled: false, // 포커스 행 표시 유무
          hoverStateEnabled: true,
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          // width:'200',     // 주석처리시 100%
          height: '645px', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true,
            initNewRow: true,
            saving: true,
            cellPrepared: true,
          },
          showActionButtons: {},
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '결재구분',
              dataField: 'approvalType',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.approvalType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '결재 상태',
              dataField: 'approvalStatus',
              alignment: 'center',
              visible: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.approvalStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '결재 상태',
              dataField: 'approvalStatusView',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '신청자',
              dataField: 'requestUser',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: this.mergeRequestUser,
            },
            {
              caption: '신청 일시',
              dataField: 'requestDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              dataType: 'date',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
          ],
        },
        processGrid: {
          keyExpr: 'approvalLogId',
          refName: 'processGridRef',
          allowColumnResizing: true,
          columnResizingMode: 'widget',
          columnAutoWidth: false,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          // width:'200',     // 주석처리시 100%
          height: '240px', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            initNewRow: true,
          },
          showActionButtons: {},
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 4,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '결재 처리',
              dataField: 'approvalStatusName',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '결재선 구분',
              dataField: 'approvalNo',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: this.makeApprovalSequenceLabel,
            },
            {
              caption: '결재 참고 사항',
              dataField: 'approvalReason',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '결재자',
              dataField: 'approvalUser',
              alignment: 'center',
              visible: true,
              allowFiltering: false,
              allowHeaderFiltering: false,
              calculateCellValue: this.mergeApprovalUser,
            },
            {
              caption: '결재 일시',
              dataField: 'approvalProcessingDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              dataType: 'date',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
          ],
        },
        approvalDetail: {
          approvalId: '',
          requestUserInfo: '',
          requestInfo: '',
          requestReason: '',
          requestDt: '',
          approvalStatus: '',
          fileItems: [],
        },
        approvalBtnVisible: true, // 승인 버튼 표시 여부
        rejectedBtnVisible: true, // 반려 버튼 표시 여부
        forceApprovalBtnVisible: true, // 전결 버튼 표시 여부
        approvalCancelBtnVisible: false, // 승인 취소 버튼 표시 여부
      };
    },
    computed: {},
    methods: {
      /**
       * 결재 처리 팝업 오픈
       * @param status 결재 상태
       * @param description 결재 상태에 따른 설명
       */
      onOpenPopup(status, description) {
        let approvalStatus = '';
        if (status === '승인') {
          approvalStatus = this.$_enums.ewm.approvalStatus.APPROVAL;
        } else if (status === '반려') {
          approvalStatus = this.$_enums.ewm.approvalStatus.REJECTED;
        } else if (status === '전결') {
          approvalStatus = this.$_enums.ewm.approvalStatus.FORCE_APPROVAL;
        } else {
          return;
        }
        this.popup.approvalStatus = approvalStatus;
        this.popup.description = description;
        this.popup.visible = true;
      },
      /**
       * 결재 처리 팝업 저장 이벤트
       */
      onSaveModal(fromData) {
        this.onApprovalProcessing({
          approvalId: this.approvalDetail.approvalId,
          approvalStatus: fromData.status,
          approvalRequestReason: fromData.contents,
          requestID: this.$store.getters.getLoginId,
        }).then(res => {
          if (isSuccess(res)) {
            this.onCloseModal();
            this.$refs.modalPageApproval.cleanContents();
            this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.onSearch();
          } else {
            this.$_Toast(res?.data?.header?.resMsg);
          }
        });
      },
      /**
       * 결재 처리
       * @param formData
       * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<*, Record<string, *>>|AuthenticatorResponse>}
       */
      async onApprovalProcessing(formData) {
        const payload = {
          actionName: 'EWM_APPROVAL_PROCESSING_UPDATE',
          path: '/' + formData.approvalId,
          data: {
            approvalStatus: formData.approvalStatus,
            approvalReason: formData.approvalRequestReason,
            requestID: formData.requestID,
          },
        };
        return await this.CALL_EWM_API(payload);
      },
      /**
       * 결재 처리 팝업 닫기 이벤트
       */
      onCloseModal() {
        this.$refs.modalPageApproval.cleanContents();
        this.popup.visible = false;
        this.popup.description = '';
      },
      /**
       * 결재신청 목록 그리드 선택 이벤트
       * @param e
       * @return {Promise<void>}
       */
      async onRequestGridRowClick(e) {
        this.approvalDetail.approvalId = e.data?.approvalId;
        this.approvalDetail.requestUserInfo = `신청자 : ${e.data?.requestNm}(${e.data?.requestId}) <br/> 부서 : ${e.data?.requestDeptNmPath}`;
        this.approvalDetail.requestDt = e.data?.requestDt;
        this.approvalDetail.requestInfo = e.data?.requestInfo?.replaceAll('|', '\r\n');
        this.approvalDetail.requestReason = e.data?.requestReason;
        this.selectedFinalApprovalStatus = e.data?.approvalStatus;
        this.userApprovalStatus = e.data?.userApprovalStatus;
        await this.selectApprovalProgressList(e.data?.approvalId);
        this.checkProcessButtonVisible();

        this.approvalDetail.fileItems = await this.$_getListAttachFile(e.data?.fileGroupId).then(res => {
          if (res?.length > 0) {
            return res;
          }
          return [];
        });
      },
      /**
       * 결재 상태 셀렉트 박스 변경 이벤트
       * @param e 이벤트 객체
       */
      onApprovalStatusChange(e) {
        this.initDetail();
        this.selectApprovalRequestList();
      },
      /**
       * 신청자 정보를 합쳐서 반환
       * @param rowData
       * @return {string}
       */
      mergeRequestUser(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.requestNm} (${rowData.requestId})`;
      },
      /**
       * 결재자 정보를 합쳐서 반환
       * @param rowData
       * @return {string}
       */
      mergeApprovalUser(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.approvarNm} (${rowData.approverId})`;
      },
      /**
       * 결재 신청 목록 조회
       * @return {Promise<*>}
       */
      async selectApprovalRequestList() {
        let vm = this;
        this.approvalRequestGrid.dataSource = new CustomStore({
          key: 'approvalId',
          async load() {
            const payload = {
              actionName: 'EWM_APPROVAL_REQUEST_LIST',
              data: {
                requestDt: `${vm.$refs.dateRangeBox.getStartDate()}000000~${vm.$refs.dateRangeBox.getEndDate()}235959`,
                approvalStatus: vm.approvalStatus,
              },
              useErrorPopup: true,
            };

            let rtnData = [];
            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              rtnData = res.data.data;
            }
            return rtnData;
          },
        });
      },
      /**
       * 결재 진행 목록 조회
       * @param approvalId
       * @return {Promise<*>}
       */
      async selectApprovalProgressList(approvalId) {
        let vm = this;
        this.processGrid.dataSource = new CustomStore({
          key: 'approvalLogId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params, sort: '-approvalProcessingDt' };
            const payload = {
              actionName: 'EWM_APPROVAL_PROGRESS_LIST',
              path: `/${approvalId}`,
              data: params,
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.processGridRef.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              vm.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /**
       * 날짜 범위 박스 변경 이벤트
       * @param e
       */
      onDateRangeBoxValueChanged(e) {
        this.initDetail();
        this.selectApprovalRequestList();
      },
      /**
       * 상세 내용 초기화
       */
      initDetail() {
        this.approvalDetail.requestUserInfo = '';
        this.approvalDetail.requestDt = '';
        this.approvalDetail.requestInfo = '';
        this.approvalDetail.requestReason = '';
        this.approvalDetail.fileItems = [];
        this.processGrid.dataSource = []; // 결재 진행 정보 초기화
      },
      /**
       * 결재 처리 버튼 표시 여부
       */
      checkProcessButtonVisible() {
        // 결재 상태가 없을 경우, 모든 버튼 비활성화
        if (isEmpty(this.userApprovalStatus)) {
          this.approvalBtnVisible = false;
          this.rejectedBtnVisible = false;
          this.forceApprovalBtnVisible = false;
          this.approvalCancelBtnVisible = false;
          return;
        }

        switch (this.userApprovalStatus) {
          case this.$_enums.ewm.approvalStatus.APPROVAL.value: // 승인
            this.approvalBtnVisible = false;
            this.rejectedBtnVisible = false;
            this.forceApprovalBtnVisible = false;
            this.approvalCancelBtnVisible = true;
            break;
          case this.$_enums.ewm.approvalStatus.REJECTED.value: // 반려
            this.approvalBtnVisible = false;
            this.rejectedBtnVisible = false;
            this.forceApprovalBtnVisible = false;
            this.approvalCancelBtnVisible = false;
            break;
          case this.$_enums.ewm.approvalStatus.FORCE_APPROVAL.value: // 전결
            this.approvalBtnVisible = false;
            this.rejectedBtnVisible = false;
            this.forceApprovalBtnVisible = false;
            this.approvalCancelBtnVisible = false;
            break;
          default:
            this.approvalBtnVisible = true;
            this.rejectedBtnVisible = true;
            this.forceApprovalBtnVisible = true;
            this.approvalCancelBtnVisible = false;
            break;
        }
      },
      /**
       * 파일 다운로드
       * @param item
       */
      onFileDownload(item) {
        this.$_downloadAttachFile(item.fileGroupId, item.fileName);
      },
      /**
       * 검색 버튼 클릭 이벤트
       */
      onSearch() {
        this.initDetail();
        this.selectApprovalRequestList();
      },
      /**
       * 승인 취소 처리
       */
      async onCancelApproval() {
        if (await this.$_Confirm('승인 취소 처리 하시겠습니까?')) {
          this.onApprovalProcessing({
            approvalId: this.approvalDetail.approvalId,
            approvalStatus: this.$_enums.ewm.approvalStatus.APPROVAL_CANCEL.value,
            approvalRequestReason: this.$_enums.ewm.approvalStatus.APPROVAL_CANCEL.label,
            requestID: this.$store.getters.getLoginId,
          }).then(res => {
            if (isSuccess(res)) {
              this.onCloseModal();
              this.$refs.modalPageApproval.cleanContents();
              this.$_Toast('승인 취소 처리 완료');
              this.onSearch();
            } else {
              this.$_Toast(res?.data?.header?.resMsg);
            }
          });
        }
      },
      /**
       * 결재선 구분 레이블 생성
       * @param e
       * @return {string} 결재선 구분 레이블 ex) 1차, 2차, 3차
       */
      makeApprovalSequenceLabel(e) {
        return `${e.approvalNo}차`;
      },
    },
    created() {},
    mounted() {
      this.initDetail();
      this.selectApprovalRequestList();
      this.approvalStatusSelectBox.push({
        label: '전체',
        value: '',
      });
      this.$_enums.ewm.approvalStatus.values.forEach(item => {
        this.approvalStatusSelectBox.push({
          label: item.label,
          value: item.value,
        });
      });
    },
  };
</script>

<style lang="scss" scoped>
  .page_search_box .inner > div:not(.box-btn-search) {
    vertical-align: middle;
    margin-right: 10px;
  }

  .ewm .dx-command-select {
    padding-left: 3px !important;
  }

  ::v-deep {
    .dx-datagrid-header-panel {
      padding: 5px 0 0 0 !important;
      margin-bottom: 5px;
    }
    .table_form td {
      padding: 5px 0 5px 10px;
    }
  }

  .form-bottom-button-wrapper {
    width: 100%;
    margin-top: 5px;
    text-align: right;
    height: 40px;
  }
</style>
