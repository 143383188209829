import RequestRoutes from "@/router/euc/phone/request";
import RequestTypeRoutes from "@/router/euc/phone/request/type";
import WorkerRoutes from "@/router/euc/phone/worker";
import ApprovalRoutes from "@/router/euc/phone/approval";

export default function PhoneRoutes(commonComponents) {
    return [
        ...RequestRoutes(commonComponents),
        ...RequestTypeRoutes(commonComponents),
        ...WorkerRoutes(commonComponents),
        ...ApprovalRoutes(commonComponents)
    ]
}