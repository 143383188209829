import EvaluationCriteria from '@/pages/ewm/productivity/implementation/evaluation-criteria/index.vue';
import EvaluationCriteriaRegister from '@/pages/ewm/productivity/implementation/evaluation-criteria/register.vue';
import EvaluationSchedule from '@/pages/ewm/productivity/implementation/evaluation-schedule/index.vue';
import ReportCase from '@/pages/ewm/productivity/report/case/index.vue';
import ReportResult from '@/pages/ewm/productivity/report/result/index.vue';
import ReportTime from '@/pages/ewm/productivity/report/time/index.vue';

export default function ProductivityRoutes(commonComponents) {
  return [
    // Productivity -> Implementation
    {
      path: '/ewm/productivity/implementation/evaluation-criteria',
      name: 'EvaluationCriteria',
      components: { default: EvaluationCriteria, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/implementation/evaluation-criteria/register',
      name: 'EvaluationCriteriaRegister',
      components: { default: EvaluationCriteriaRegister, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/implementation/evaluation-schedule',
      name: 'EvaluationSchedule',
      components: { default: EvaluationSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // Productivity -> Report
    {
      path: '/ewm/productivity/report/case',
      name: 'ReportCase',
      components: { default: ReportCase, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/report/result',
      name: 'ReportResult',
      components: { default: ReportResult, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/report/time',
      name: 'ReportTime',
      components: { default: ReportTime, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}