<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\popup\modal-page-select-agent.vue
  FILE_NAME : modal-page-select-agent
  AUTHOR : bykim
  DATE : 2024-03-19
  DESCRIPTION : 근무스케줄 등록 상담원 선택 페이지
-->
<template>
  <div class="modal-container">
    <esp-dx-data-grid
      :data-grid="dataGrid"
      :ref="dataGrid.refName"
    />
    <div class="toolbar-bottom">
      <div class="toolbar-item">
        <DxButton
          text="이 전"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="handleBtnPagePrev"
        />
        <DxButton
          text="다 음"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="handleBtnPageNext"
        />
        <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="handleBtnPageClose" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/utils/common-lib';
  import ArrayStore from 'devextreme/data/array_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: { EspDxDataGrid, DxButton },
    props: {
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-select-agent',
          nextPageName: 'modal-page-worktype',
          prevPageName: 'modal-page-initial-settings',
          agentData: {},
          workCategory: {},
          submitList: [],
        }),
      },
    },
    data() {
      return {
        propData: {},
        dataGrid: {
          refName: 'agentWorkScheduleGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          dataSource: [], // 그리드 데이터
          height: '502', // 주석처리시 100%
          showActionButtons: {
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '대상자 삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 100,
                  height: 30,
                  onClick: this.deleteAgent,
                },
                location: 'before',
              },
            ], // 그리드 커스텀 버튼 생성
          },
          page: {
            enabled: false, // 페이징 사용 유무
          },
          editing: {
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
          },
          columns: [
            {
              i18n: 'COMPONENTS.DEPT',
              caption: '부서',
              dataField: 'deptNmPath',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowEditing: false,
            },
            {
              i18n: 'COMPONENTS.AGENT',
              caption: '상담원',
              dataField: 'agent',
              allowGrouping: false,
              allowEditing: false,
              calculateCellValue: this.setAgentNameField,
            },
            {
              i18n: 'COMPONENTS.WORK_DATE',
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '근무일자',
              dataField: 'workDt',
              allowEditing: false,
            },
            {
              i18n: 'COMPONENTS.WORK_BASIC_TIME',
              caption: '일반근무시간',
              dataField: 'workBasicTime',
              allowFiltering: false,
              allowGrouping: false,
              allowEditing: false,
              calculateCellValue: this.setMergedWorkBasicTime,
            },
            {
              i18n: 'COMPONENTS.WORK_OVER_TIME',
              caption: '시간외근무시간',
              dataField: 'workOvertime',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowEditing: false,
              calculateCellValue: this.setMergedWorkOvertime,
            },
          ], // 컬럼 정보
        },
        isSelectionStopped: true,
      };
    },
    watch: {
      appointmentData: {
        handler: function() {
          this.propData =  this.appointmentData;
        },
        deep: true,
      },
    },
    methods: {
      /**
       * @description : 다음 페이지 이동
       */
      handleBtnPageNext() {
        let propData = this.appointmentData;
        propData.nextPageName = 'modal-page-worktype';
        propData.prevPageName = 'modal-page-select-agent';
        propData.submitList = [];
        let results = [];
        let rows = this.dataGrid.dataSource._array;

        if (rows.length === 0) {
          this.$_Toast('근무자가 없습니다.');
          return;
        }

        rows.forEach(row => {
          let found = results.find(r => r.agtid === row.agtid);
          if (!found) {
            results.push({ agtid: row.agtid, workDateList: [row.workDt.replace(/-/g, '')], workTypeId: '' });
          } else {
            found.workDateList.push(row.workDt.replace(/-/g, ''));
          }
        });
        propData.submitList = results;
        this.$emit('pageNext', propData);
      },
      /**
       * @description : 이전 페이지 이동
       */
      handleBtnPagePrev() {
        let propData = this.appointmentData;
        propData.prevPageName = 'modal-page-initial-settings';
        propData.deptCdList = [];
        propData.agtIdList = [];
        this.$emit('pagePrev', propData);
      },
      /**
       * @description 페이지 닫기
       */
      handleBtnPageClose() {
        this.$emit('pageClose');
      },
      /**
       * @description : 상담사 정보 합치기
       * @param rowData
       * @return {string}
       */
      setAgentNameField(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData?.agtNm} [${rowData?.agtid}]`;
      },
      /**
       * @description : 일반근무 시간 병합
       * @param rowData
       * @return {string}
       */
      setMergedWorkBasicTime(rowData) {
        if (rowData === null || rowData === undefined || rowData.workBasicStartTime === null || rowData.workBasicEndTime === null) {
          return '';
        }
        return `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
      },
      /**
       * @description : 시간외근무 시간 병합
       * @param rowData
       * @return {string}
       */
      setMergedWorkOvertime(rowData) {
        if (rowData === null || rowData === undefined || rowData.workOverTimeStartTime === null || rowData.workOverTimeEndTime === null) {
          return '';
        }
        return `${rowData.workOverTimeStartTime} ~ ${rowData.workOverTimeEndTime}`;
      },
      /**
       * @description : 상담원 조회
       */
      async getAgent() {
        let vm = this;
        const payload = {
          actionName: 'EWM_WORK_SCHEDULE_AGENT_LIST',
          data: {
            betweenWorkDt: `${vm.appointmentData.startDate}~${vm.appointmentData.endDate}`,
            deptCdList: vm.appointmentData.deptCdList === undefined ? [] : vm.appointmentData.deptCdList,
            agtIdList: vm.appointmentData.agtIdList,
          },
          useErrorPopup: true,
        };

        const dataArr = await vm.CALL_EWM_API(payload).then(res => {
          if (isSuccess(res)) {
            return res.data.data;
          }
          return [];
        });
        this.dataGrid.dataSource = new ArrayStore({
          key: 'id',
          data: dataArr,
        });
      },
      /**
       * @description : 근무대상자 삭제
       */
      async deleteAgent() {
        let selectedRowKeys = this.$refs.agentWorkScheduleGrid.getInstance.getSelectedRowKeys();
        if (selectedRowKeys.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (
          await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '선택한 데이터를 삭제하시겠습니까?' }))
        ) {
          selectedRowKeys.forEach(rowKey => {
            this.dataGrid.dataSource._array.map((item, index) => {
              if (item.id === rowKey) {
                this.dataGrid.dataSource._array.splice(index, 1);
              }
            });
          });
          this.$refs.agentWorkScheduleGrid.refreshData();
          this.$refs.agentWorkScheduleGrid.clearFilter();
          this.$refs.agentWorkScheduleGrid.clearSelection();
        }
      },
    },
    created() {},
    mounted() {
      this.getAgent();
    },
  };
</script>

<style lang="scss" scoped>
  .modal-container {
    display: flex;
    flex-direction: column; /* 위에서 아래로 정렬 */
    height: 100%; /* 컨테이너가 부모에 꽉 차도록 설정 */
  }

  .toolbar-bottom {
    margin-top: auto; /* 푸터 영역처럼 항상 맨 아래로 위치 */
    padding-top: 10px; /* 추가 여백 */
    display: flex; /* Flexbox 적용 */
    justify-content: center; /* 자식 요소(버튼들)를 수평 중앙 정렬 */
  }

  .toolbar-item {
    gap: 8px;
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
  }
</style>
