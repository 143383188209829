var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"locker_setting_list sub_new_style01 sub_ui_box1"},[_c('esp-dx-data-grid',{ref:"contactGrid",attrs:{"data-grid":_vm.dataGrid},on:{"saving":_vm.onSaving}}),_c('DxPopup',{attrs:{"show-title":true,"title":_vm.modal.initData ? _vm.modal.initData.title : null,"min-width":_vm.modal.initData ? _vm.modal.initData.width : null,"width":_vm.modal.initData ? _vm.modal.initData.width : null,"min-height":_vm.modal.initData ? _vm.modal.initData.height : null,"height":_vm.modal.initData ? _vm.modal.initData.height : null,"drag-enabled":true,"resize-enabled":true,"show-close-button":true,"close-on-outside-click":false,"visible":_vm.modal.isOpened},on:{"hiding":function($event){return _vm.isOpenModal(false)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(_vm.modal.currentComponent,{ref:"modalExcelUploadRef",tag:"component",attrs:{"modalData":_vm.modal.sendData,"groupList":_vm.groupList},model:{value:(_vm.modal.contentData),callback:function ($$v) {_vm.$set(_vm.modal, "contentData", $$v)},expression:"modal.contentData"}})]},proxy:true}]),model:{value:(_vm.modal.isOpened),callback:function ($$v) {_vm.$set(_vm.modal, "isOpened", $$v)},expression:"modal.isOpened"}},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => this.isOpenModal(false)
					}}}),_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","options":{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
						width: '120',
						height: '40',
						onClick: () => this.onSaveModal()
					}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }