<!--
  PACKAGE_NAME : src/pages/ewm/hr/agent/appointment/popup
  FILE_NAME : modal-resign-agent.vue
  AUTHOR : devyoon91
  DATE : 2024-07-15
  DESCRIPTION : 퇴사 등록 팝업
-->
<template>
  <div class="container">
    <DxPopup
      :ref="resignAgentPopup.ref"
      :title="resignAgentPopup.title"
      :visible="isOpen"
      :show-title="true"
      :min-width="resignAgentPopup.minWidth"
      :width="resignAgentPopup.width"
      :min-height="resignAgentPopup.minHeight"
      :height="resignAgentPopup.height"
      :resize-enabled="false"
      :drag-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      @hiding="closeModal"
    >
      <template #content>
        <div>
          <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @saving="onSave" @row-updating="onRowUpdating" />
          <div class="page-sub-box mt-3">
            <div class="bottom-btn-wrap text-center">
              <DxButton
                text="등록"
                :width="120"
                :height="40"
                class="default filled txt_S medium"
                :use-submit-behavior="true"
                @click="saveModal"
              />
              <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="closeModal" />
            </div>
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import DxPopup from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import {cloneObj, isSuccess} from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxButton, DxPopup },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      agentDataList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        resignAgentPopup: {
          ref: 'resignAgentPopup',
          title: '퇴사 발령',
          minWidth: 800,
          width: 800,
          minHeight: 600,
          height: 600,
        },
        beforeAgentInfos: [], // 변경전 상담원 정보
        changeDataMap: new Map(), // 변경된 데이터 저장
        dataGrid: {
          keyExpr: 'hrId', // 그리드 키값
          refName: 'refModalResignAgentGrid', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: false, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: true, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          height: '420', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
            saving: true, // 저장 이벤트
            rowUpdating: true, // 행 수정 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: true, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'multiple', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'allPages', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'always', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '퇴직 일자',
              dataField: 'baseDt',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
            {
              caption: '퇴직 사유',
              dataField: 'paReasonCd',
              alignment: 'center',
              visible: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowGrouping: false,
              lookup: {
                dataSource: [],
                valueExpr: 'codeId',
                displayExpr: 'codeNm',
              },
              validationRules: [
                {
                  type: 'required',
                  message: '필수값 입니다.',
                },
              ],
            },
          ], // 컬럼 정보
        },
      };
    },
    watch: {
      agentDataList: {
        handler: function (newVal, oldVal) {
          const agentDataArr = cloneObj(newVal);
          this.dataGrid.dataSource = agentDataArr;
          this.beforeAgentInfos = agentDataArr; // 변경전 상담원 정보 저장
        },
        deep: true,
      },
    },
    computed: {},
    methods: {
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} [${rowData.agtid}]`;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_pers_appt_resign_reason_type');
      },
      /**
       * @description : 팝업 닫기 이벤트
       */
      closeModal() {
        this.$refs.refModalResignAgentGrid.getInstance.cancelEditData();
        this.$emit('closeModal');
      },
      /**
       * @description : 팝업 저장 이벤트
       */
      saveModal() {
        // 편집모드일 경우
        if (this.beforeAgentInfos.length !== this.changeDataMap.size) {
          this.$refs.refModalResignAgentGrid.getInstance.saveEditData();
          this.$_Toast('편집모드 강제저장');
        }

        const paramsList = this.dataGrid.dataSource.map(item => {
          return {
            agtid: item.agtid,
            agtNm: item.agtNm,
            paCategoryCd: this.$_enums.ewm.personnelAppointmentCategory.RESIGNATION.value,
            paReasonCd: item.paReasonCd,
            baseDt: item.baseDt,
            beforeAgentInfo: this.beforeAgentInfos?.find(beforeAgent => beforeAgent.agtid === item.agtid),
            afterAgentInfo: this.changeDataMap?.get(item.agtid),
          };
        });

        const payLoad = {
          actionName: 'EWM_PERS_APPT_BATCH',
          data: paramsList,
          useErrorPopup: true,
        };

        this.CALL_EWM_API(payLoad).then(res => {
          if (isSuccess(res)) {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            this.$emit('saveModal');
          }
        });
      },
      /**
       * 편집모드 저장 이벤트
       * @param e
       */
      onSave(e) {},
      /**
       * 행 수정 이벤트
       * @param e
       */
      onRowUpdating(e) {
        if (Object.prototype.hasOwnProperty.call(e.newData, 'baseDt') && Object.prototype.hasOwnProperty.call(e.newData, 'paReasonCd')) {
          this.changeDataMap.set(e.oldData.agtid, e.oldData);
        }
      },
    },
    created() {},
    mounted() {
      this.initCodeMap().then(() => {
        this.dataGrid.columns[2].lookup.dataSource = this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_pers_appt_resign_reason_type']); // 퇴직 사유 코드
      });
    },
  };
</script>

<style lang="scss" scoped></style>
