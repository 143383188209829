<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="page-sub-box">
      <div style="padding-top: 25px"></div>
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 20%"/>
          <col style="width: 37%"/>
          <col style="width: 4%"/>
          <col style="width: 37%"/>
        </colgroup>
        <tr>
          <td style="vertical-align: top">
            <esp-dx-data-grid
                :data-grid="herGrid"
                ref="herGrid"
                @selection-changed="onSelectionChanged($event, 'herNum')"
            ></esp-dx-data-grid>
          </td>
          <td style="vertical-align: top">
            <esp-dx-data-grid
                :data-grid="herPilotGrid"
                ref="herPilotGrid"
                @reorder="onChangePilotOrd"
            ></esp-dx-data-grid>
            <div v-if="!this.flag.save" style="padding-top: 40px"/>
            <div v-if="this.flag.save" style="padding-top: 10px">
              <DxButton
                  text="저장"
                  :height="30"
                  class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
                  styling-mode="contained"
                  type="default"
                  @click="onUpdateHerNum"
              />
            </div>
          </td>
          <td>
            <div v-if="this.flag.save">
              <a style="cursor: pointer" @click="onAddPilot">
                <img src="@/assets/images/icon/arrow_icon01.png" alt="left arrow icon"/>
              </a>
              <div style="height: 10px"/>
              <a style="cursor: pointer" @click="onRemovePilot">
                <img src="@/assets/images/icon/arrow_icon02.png" alt="right arrow icon"/>
              </a>
            </div>
          </td>
          <td style="vertical-align: top">
            <esp-dx-data-grid :data-grid="huntpilotGrid" ref="huntpilotGrid" ></esp-dx-data-grid>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import {DxButton} from 'devextreme-vue/button';
import {isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid,
    DxButton,
  },
  data() {
    return {
      selectedHerNumber: null,
      flag: {
        search: false,
        save: true,
      },
      codeList: [],
      stylingMode: 'outlined', //outlined, underlined, filled
      herGrid: {
        title: this.$_lang('UC.WORD.HER_NUMBER_LIST', { defaultValue: 'Her 번호 리스트' }),
        callApi: 'CALL_EUC_API',
        refName: 'herGrid',
        dataSourceDefaultSortColumn: '+codeOrd',
        apiActionNm: {
          select: 'EUC_CODE_SELECT',
        },
        searchParams: {
          codeKey: 'hernum',
          viewFl: 'Y'
        },
        customEvent: {
          selectionChanged: true,
        },
        showActionButtons: {
          delete: false
        },
        page: {
          enabled: false,
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
        },
        columns: [
          {
            caption: 'Her 번호',
            dataField: 'codeValue',
            allowEditing: false,
            allowSorting: false,
          },
          {
            caption: '설명',
            dataField: 'description',
            alignment: 'left',
            allowEditing: false,
            allowSorting: false,
          },
        ],
      },
      herPilotGrid: {
        initialDataSource: false,
        title: this.$_lang('UC.WORD.HER_NUMBER_SETTING', { defaultValue: 'Her 번호 설정' }),
        callApi: 'CALL_EUC_API',
        refName: 'herPilotGrid',
        apiActionNm: {
          select: 'EUC_HUNTPILOT_SELECT',
        },
        searchParams: {
          herNum: this.selectedHerNumber
        },
        customEvent: {
          reorder: true,
        },
        dragging: {
          sortColumn: 'huntpilotOrd',
          allowReordering: true,
          dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
        },
        showActionButtons: {
          delete: false,
        },
        page: {
          enabled: false,
        },
        filterRow: {
          visible: false,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
        },
        columns: [
          {
            caption: '헌트번호',
            dataField: 'pattern',
            allowEditing: false,
            allowSorting: false,
            requiredRule: {},
          },
          {
            caption: '설명',
            dataField: 'description',
            alignment: 'left',
            allowEditing: false,
            allowSorting: false,
            requiredRule: {},
          },
        ],
      },
      huntpilotGrid: {
        initialDataSource: false,
        title: this.$_lang('UC.WORD.HUNT_PILOT', { defaultValue: '헌트 파일럿' }),
        callApi: 'CALL_EUC_API',
        refName: 'huntpilotGrid',
        apiActionNm: {
          select: 'EUC_HUNTPILOT_SELECT',
        },
        customEvent: {
          disableInitialDataSource: false,
          initNewRow: true,
          selectionChanged: true,
        },
        showActionButtons: {
          delete: false,
        },
        page: {
          enabled: false,
        },
        headerFilter: {
          visible: false,
        },
        editing: {
          allowUpdating: false,
          allowAdding: false,
        },
        columns: [
          {
            caption: '헌트번호',
            dataField: 'pattern',
            allowEditing: false,
            requiredRule: {},
          },
          {
            caption: '설명',
            dataField: 'description',
            alignment: 'left',
            allowEditing: false,
            requiredRule: {},
          },
        ],
      },
    };
  },
  methods: {
    /**
     * @description      그리드 selectRow 이벤트 함수
     * @param e
     */
    async onSelectionChanged(e) {
      if (e.selectedRowsData.length === e.component.getDataSource().items().length) {
        // 모든 행이 선택된 경우, 선택을 취소
        e.component.deselectAll();
        this.$set(this.huntpilotGrid, 'dataSource', []);
        this.$set(this.herPilotGrid, 'dataSource', []);
      }
      if (e.currentSelectedRowKeys?.length === 1) {
        e.component.selectRows(e.currentSelectedRowKeys);
      }

      this.herGrid.selectedRowCnt = e.selectedRowsData.length;
      if (this.herGrid.selectedRowCnt === 1) {
        const selectRowsData = e.selectedRowsData;
        const selectRowKeys = selectRowsData.map(data => data.codeValue);
        this.flag.save = selectRowKeys.some(value => value !== 'None');

        this.$set(this.herPilotGrid.searchParams, 'herNum', selectRowKeys[0]);
        await this.$refs.herPilotGrid.selectDataList();
        await this.$refs.huntpilotGrid.selectDataList();
      } else if (this.herGrid.selectedRowCnt === 0) {
        //하위 그리드 초기화
        this.$set(this.huntpilotGrid, 'dataSource', []);
        this.$set(this.herPilotGrid, 'dataSource', []);
      }
    },
    /** @description 헌트파일럿 호폭주 할당리스트 추가 */
    async onAddPilot() {
      const selectPilotRows = this.$refs.huntpilotGrid.getInstance.getSelectedRowsData();
      const selectHerRows = this.$refs.herGrid.getInstance.getSelectedRowsData();

      if (!selectPilotRows?.length) {
        this.$_Msg('추가할 파일럿을 선택하세요.');
        return;
      }

      const herPilotData = JSON.parse(JSON.stringify(this.$refs.herPilotGrid.getItems));
      selectPilotRows.forEach(item => {
        const existingRow = herPilotData.find(row => row.pattern === item.pattern);
        if (existingRow) {
          Object.assign(existingRow, item);
        } else {
          herPilotData.push(item);
        }
      });
      herPilotData.forEach((item, index) => {
        item.herNum = selectHerRows[0].codeValue;
        item.huntpilotOrd = index;
      });
      this.$set(this.herPilotGrid, 'dataSource', herPilotData);
      await this.$refs.huntpilotGrid.clearSelection();
    },
    async onRemovePilot() {
      const selectRows = this.$refs.herPilotGrid.getInstance.getSelectedRowsData();
      if (!selectRows?.length) {
        await this.$_Msg('제거할 파일럿을 선택하세요.');
        return;
      }
      const herPilotData = JSON.parse(JSON.stringify(this.$refs.herPilotGrid.getItems));
      const data = herPilotData.filter(aItem => !selectRows.some(bItem => bItem.id === aItem.id));

      data.forEach((item, index) => {
        item.huntpilotOrd = index;
      });
      this.$set(this.herPilotGrid, 'dataSource', data);

      await this.$refs.herPilotGrid.clearSelection();
    },
    /**
     * @description      헌트 파일럿 그리드 순서 변경 이벤트
     * @param e
     */
    onChangePilotOrd(e) {
      const data = JSON.parse(JSON.stringify(this.$refs.herPilotGrid.getItems));

      const visibleRows = e.component.getVisibleRows();
      const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
      const fromIndex = data.findIndex(item => item.id === e.itemData.id);

      const newDataSource = [...data];
      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);
      if (fromIndex < toIndex) {
        // 아래로 이동할 때
        for (let i = fromIndex; i <= toIndex; i++) {
          newDataSource[i].huntpilotOrd = i + 1;
        }
      } else {
        // 위로 이동할 때
        for (let i = toIndex; i <= fromIndex; i++) {
          newDataSource[i].huntpilotOrd = i + 1;
        }
      }

      // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
      newDataSource.forEach((item, index) => {
        item.huntpilotOrd = index + 1;
      });
      this.$set(this.herPilotGrid, 'dataSource', newDataSource);
    },
    /**
     * @description  Her 번호 저장 함수
     */
    async onUpdateHerNum() {
      if (await this.$_Confirm('Her 번호를 저장하시겠습니까?')) {
        const data = this.$refs.herPilotGrid.getDataSource;
        const herNum = this.$refs.herGrid.getInstance.getSelectedRowsData()[0].codeValue;

        const payload = {
          actionName: 'EUC_HERNUM_UPDATE',
          data: {
            herNum,
            list: data || []
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE'));
          this.$refs.herPilotGrid.clearSelection();
          this.$refs.huntpilotGrid.clearSelection();

          this.$set(this.herPilotGrid.searchParams, 'herNum', herNum);
          await this.$refs.huntpilotGrid.selectDataList();
          await this.$refs.herPilotGrid.selectDataList();
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR'));
        }
      }
    },
  },
};
</script>
<style scoped>
.sub_new_style01 .page_search_box .inner div {
  display: inline-block;
}

.table_form tbody td {
  border: 1px solid #ddd;
}

.table_form tbody th {
  border: 1px solid #ddd;
}
</style>