export default {
  ADD: 'Add',
  REGISTER: 'Register',
  CONFIRM: 'Confirm',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  COPY: 'Copy',
  DELETE: 'Delete',
  DELETE_SELECTED: 'Delete Selected',
  DETAIL: 'View Details',
  DOWNLOAD: 'Download',
  DUPLICATE_SELECTED: 'Duplicate Selected',
  EDIT: 'Edit',
  FIRST_PAGE: 'First Page',
  LAST_PAGE: 'Last Page',
  MOVE: 'Move',
  NEXT: 'Next',
  NEXT_PAGE: 'Next Page',
  PREV: 'Previous',
  PREV_PAGE: 'Previous Page',
  REFRESH: 'Refresh',
  SAVE: 'Save',
  APPROVAL: 'Approval',
  COMPLETE: 'Complete',
  SEARCH: 'Search',
  SEARCH_RESULT: 'Search Result',
  SELECT: 'Select',
  SELECT_ALL: 'Select All',
  UNSELECT_ALL: 'Unselect All',
  UPDATE: 'Update',
  UPLOAD: 'Upload',
  TITLE: 'Title',
  NAME: 'Name',
  DATE: 'Date',
  DEPT: 'Department',
  GROUP: 'Group',
  TYPE: 'Type',
  DESCRIPTION: 'Description',
  ALL: 'All',
  USE_STATUS: 'Use Status',
  DELETE_STATUS: 'Delete Status',
  ORDER: 'Order',
  REGISTRANT: 'Registerer',
  REGISTRATION_DATE: 'Register Date',
  REGISTRATION_DATE_TIME: 'Register DateTime',
  MODIFIER: 'Modifier',
  MODIFY_DATE: 'Modify Date',
  MODIFY_DATE_TIME: 'Modify DateTime',
  TARGET: 'Target',
  NON_TARGET: 'Non-target',
  OTHERS: 'Others',
  GROUP_NAME: 'Group Name',
  INDIVIDUAL: 'Individual',
  CONTAINS: 'Contains',
  BETWEEN: 'Between',
  ENDS_WITH: 'Ends with',
  EQUAL: 'Equals',
  GREATER_THAN: 'Greater than',
  GREATER_THAN_OR_EQUAL: 'Greater than or equal to',
  LESS_THAN: 'Less than',
  LESS_THAN_OR_EQUAL: 'Less than or equal to',
  NOT_CONTAINS: 'Does not contain',
  NOT_EQUAL: 'Does not equal',
  STARTS_WITH: 'Starts with',
  IS_BLANK: 'Is blank',
  IS_NOT_BLANK: 'Is not blank',
  AND: 'And',
  OR: 'Or',
  NOT_AND: 'Not And',
  NOT_OR: 'Not Or',
  EXPAND_LIST: 'Expand List',
  COLLAPSE_LIST: 'Collapse List',
  DOWNLOAD_REASON: 'Download Reason',
  SEARCH_PERIOD: 'Search Period',
  SEARCH_DATE: 'Search Date',
  SEARCH_TARGET: 'Search Target',
  FILE: 'File',
  FILE_SELECT: 'File Select',
  METHOD: 'Method',
  RESULT: 'Result',
  PHONE_NUMBER: 'Phone Number',
  GRADE: 'Grade',
  PERSONAL_INFORMATION: 'Personal Information',
  PERSONAL_MANAGEMENT: 'Personal Management',
  PATH: 'Path',
  USER_STATE: 'User State',
  CATEGORY: 'Category',
  MOVE_TO_TOP: 'Move to the top',
  MOVE_UP: 'Move up',
  MOVE_DOWN: 'Move down',
  MOVE_TO_BOTTOM: 'Move to the bottom',
  SAVE_ORDER: 'Save Order',
  SETTING: 'Setting',
  NOT_SETTING: 'Not Setting',
  ADD_SETTING: 'Add Setting',
  REMOVE_SETTING: 'Remove Setting',
  RESET: 'Reset',
  PRETTIFY_CODE: 'Prettify Code',
  DROP_FILE: 'or Drop file here',
  PRE_LISTENING: 'Pre listening',
  LISTENING: 'Listening',
  LOGIN: 'Login',
  VIEW_STATUS: 'View Status',
  SHOW_VIEW_FL: 'View only {value} in use',
  ITEM: 'Item',
  STEP: 'Step',
  EXCEL: 'Excel',
  EXCEL_DOWNLOAD: 'Excel Download',
  ALL_DOWNLOAD: 'All Download',
  SELECTED_DATA_DOWNLOAD: 'Selected Data Download',
  SUM: 'Sum',
  AVG: 'Average',
  COUNT: 'Count',
  MIN: 'Min',
  MAX: 'Max',
  ADD_ROW: 'Add Row',
  COPY_ROW: 'Copy Row',
  CELL_CLIPBOARD: 'Cell Clipboard',
  ROW_CLIPBOARD: 'Row Clipboard',
  LOAD: 'Load',
  USER: 'User',
  LOGIN_ID: 'ID',
  PARENT_MENU_NAME: 'Parent Menu Name',
  MENU: 'Menu',
  MENU_INFO: 'Menu Information',
  MENU_NAME: 'Menu Name',
  MENU_TYPE: 'Menu Type',
  CHILD_MENU: 'Child Menu',
  PAGE_PATH: 'Page Path',
  PAGE_DATA: 'Page Data',
  MENU_ICON: 'Menu Icon',
  MENU_ORDER: 'Menu Order',
  AUTH_ADD: 'Auth Add',
  MENU_ADD: 'Menu Add',
  ROOT_MENU_ADD: 'Root Menu Add',
  FILTER: 'Filter',
  SEND: 'Send',
  INCREASE: 'Increase',
  DECREASE: 'Decrease',
  EXPANSION: 'Expansion',
  PREVIEW: 'Preview',
  PRINT: 'Print',
  QUESTION: 'Question',
  RETURN: 'Return',
  MORE: 'More',
  PASSWORD_CONFIRM: 'Password Check',
  PASSWORD_CHANGE: 'Password Change',
  PASSWORD: 'Password',
  REQUIRED_ENTRY: 'Required Entry',
  AUTH: 'Auth',
  NORMAL: 'Normal',
  ABSENCE: 'Absence',
  LATE: 'Late',
  KEY: 'Key',
  VALUE: 'Value',
  GROUP_KEY: 'Group Key',
  CODE_NAME: 'Code Name',
  CODE_VALUE: 'Code Value',
  AGENT: 'Agent',
  WORK_DATE: 'Work Date',
  WORK_BASIC_TIME: 'Work Basic Time',
  WORK_OVER_TIME: 'Work Over Time',
  ROUTE_HOST_ALIAS: 'Host Alias',
  ROUTE_ACTION_NAME: 'Action Name',
  ROUTE_TIMEOUT_MS: 'Timeout(ms)',
  ROUTE_WORK_LOG_FL: 'WorkLog Save Flag',
  WORKER: 'Worker',
  WORK_DATE_TIME: 'Work DateTime',
};
