<!--
  PACKAGE_NAME : src/pages/euc/device/hunt
  FILE_NAME : /huntList.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 헌트정보 , 엑셀다운로드 시 전체다운로드만 지원
-->
<template>
  <esp-dx-data-grid :data-grid="dataGrid" ref="huntGrid" @exporting="onExporting"/>
</template>
<script>
import {isSuccess, formatDate} from '@/utils/common-lib';
import {exportDataGrid} from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    EspDxDataGrid,
  },
  props: {
    cmList: {
      type: Array,
    },
  },
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      totalCount: 0,
      excelExportAll: false, // 엑셀 전체 다운로드 플래그
      dataGrid: {
        callApi: 'CALL_EUC_API',
        keyExpr: 'customId',
        excel: {
          title: '헌트정보',
        },
        refName: 'huntGrid',
        dataSourceDefaultSortColumn: '+regDt,+id',
        apiActionNm: {
          select: 'EUC_HUNT_LIST_ALL',
          update: 'EUC_HUNT_UPDATE',
          delete: 'EUC_HUNT_DELETE'
        },
        showActionButtons: {
          excel: true,
          delete: false,
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: 'Custom key',
            dataField: 'customId',
            allowEditing: false,
            visible: false,
          },
          {
            caption: '헌트파일럿 패턴',
            dataField: 'pattern',
            allowEditing: false,
          },
          {
            caption: '헌트파일럿 설명',
            dataField: 'description',
            allowEditing: false,
          },

          {
            caption: '헌트리스트',
            dataField: 'huntlistname',
            allowEditing: false,
          },
          {
            caption: '헌트리스트 설명',
            dataField: 'listdescription',
            allowEditing: false,
          },
          {
            caption: '회선그룹',
            i18n: 'UC.WORD.LINEGROUP',
            dataField: 'name',
            allowEditing: false,
          },

          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'dnpattern',
            allowEditing: false,
          },
          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            allowEditing: false,
          },
          {
            caption: '수정일',
            i18n: 'COMPONENTS.MODIFY_DATE',
            dataField: 'editDt',
            allowEditing: false,
            calculateCellValue: this.formatDt,
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.editDt) {
        return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /** @description: 엑셀 다운로드 이벤트 */
    onExporting(e) {
      this.excelExportAll = true; //엑셀다운로드 플래그 변경
      this.totalCount = e.component.totalCount();
      //Grid Excel Export
      const title = this.dataGrid.excel.title;
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(title);

      //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
      worksheet.columns = this.dataGrid.columns.map(() => {
        return {
          width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30
        }
      });

      exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        keepColumnWidths: false,
        autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
        topLeftCell: {row: 4, column: 1},
        customizeCell: ({gridCell, excelCell}) => {
          if (gridCell.rowType === 'header') {
            //header 영역 설정
            excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'C6EFCE'}};
            excelCell.alignment = {horizontal: 'center', vertical: 'middle'};
          } else {
            //data 영역 배경색 설정
            if (excelCell.fullAddress.row % 2 === 0) {
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'F2F2F2'},
                bgColor: {argb: 'F2F2F2'},
              };
            }
          }

          const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
          excelCell.border = {
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
            top: borderStyle,
          };
        },
      })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            if (e.format === 'xlsx') {
              worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
            }
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = {size: 22, bold: true};
            titleRow.getCell(1).alignment = {horizontal: 'center', vertical: 'middle'};

            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(() => {
            const today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');
            if (e.format === 'csv') {
              workbook.csv.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], {type: 'text/csv'}), `${title}_${today}.csv`);
              });
            } else {
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], {type: 'application/octet-stream'}), `${title}_${today}.xlsx`);
              });
            }
          })
          .then(() => {
            // 다운로드 이력 저장
            e.onSaveHistory();
          });
      e.cancel = true;
    },
  },
};
</script>
