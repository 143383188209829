import AgentConfig from '@/pages/cc/emc/config/agent';
import NotReadyReasonConfig from '@/pages/cc/emc/config/nreason';
import SkillGroupConfig from '@/pages/cc/emc/config/skl';
import AgentSkillAssign from '@/pages/cc/emc/skl/assign/list';

export default function EMCConfigRoutes(commonComponents) {
  return [
    {
      path: '/cc/emc/config/agent',
      name: 'EMCAgentConfig',
      components: { default: AgentConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/emc/config/nreason',
      name: 'EMCNotReadyReasonConfig',
      components: { default: NotReadyReasonConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/emc/config/skl',
      name: 'EMCSkillGroupConfig',
      components: { default: SkillGroupConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/emc/skl/assign/list',
      name: 'EMCAgentSkillAssign',
      components: { default: AgentSkillAssign, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
