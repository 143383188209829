import ChineseWall from '@/pages/euc/chinese-wall';

export default function ChineseWallRoutes(commonComponents) {
    return [
        {
            path: '/euc/chinese-wall',
            name: 'ChineseWall',
            components: { default: ChineseWall, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}