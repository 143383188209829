<!--
  PACKAGE_NAME : src/pages/euc/device/pickup/
  FILE_NAME : index.vue
  AUTHOR : jhsim
  DATE : 2024-05-10
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" :cmList="cmList" :partitionList="partitionList" />
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import PickupList from '@/pages/euc/device/pickup/pickupList.vue';
  import PickupDeptList from '@/pages/euc/device/pickup/pickupDeptList.vue';
  import { isSuccess } from '@/utils/common-lib';

  export default {
    components: {
      Tabs,
      Tab,
      PickupList,
      PickupDeptList,
    },
    props: {},
    watch: {},
    data() {
      return {
        tabIndex: 0,
        tabs: {
          selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
        },
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
            formData: {},
          },
        },
        cmList: [],
        partitionList: [],
        tabItems: [
          {
            title: '당겨받기 관리',
            component: PickupList,
            vender: ['CISCO'],
          },
          {
            title: '부서별 생성',
            component: PickupDeptList,
            vender: ['CISCO'],
          },
        ],
      };
    },
    computed: {
      getVender() {
        return this.$_getSystemData('vender')?.configValue || 'CISCO';
      },
      getComponent() {
        return this.tabItems.filter(item => item.vender.includes(this.getVender));
      },
    },
    methods: {
      async tabSelectedIndex(index) {
        this.tabIndex = index;
      },
      async selectCmList(sort = '+cmOrd') {
        const payload = {
          actionName: 'EUC_CM_SELECT',
          data: { sort, viewFl: 'Y' },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          if (res.data.data?.length) {
            this.cmList = res.data.data.map((item) => {
              return {
                cmNm: item.cmNm,
                cmOrd: item.cmOrd,
                description: item.description,
                editId: item.editId,
                editDt: item.editDt,
                regId: item.regId,
              };
            });
          }
        }
      },
      async selectPartitionList(sort = '+regDt,+id') {
        const payload = {
          actionName: 'EUC_PARTITION_SELECT',
          data: { sort, viewFl: 'Y' },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.partitionList = res.data.data;
        }
      },
    },
    created() {
      this.selectCmList();
      this.selectPartitionList();
    },
  };
</script>
