import { EventBus } from '@/event-bus';
import enums from '@/configs/enums';
import theme from '@/configs/theme';
import EspDxFileUploader from '@/components/devextreme/esp-dx-file-uploader.vue';
import loaderManager from './esp-loader-manager';
import * as commonLib from '../utils/common-lib';
import * as dxUtil from '../utils/devextreme-util';

export default {
  install(Vue) {
    if (this.installed) {
      return;
    }
    this.installed = true;

    // 전역 객체 설정
    Vue.prototype.$_commonlib = commonLib; // 공통 라이브러리 설정
    Vue.prototype.$_eventbus = EventBus; // 이벤트 버스 설정
    Vue.prototype.$_enums = enums; // 열거형 설정
    Vue.prototype.$_theme = theme; // 테마 설정
    Vue.prototype.$_UploaderClass = Vue.extend(EspDxFileUploader); // DevExtreme 업로더 컴포넌트
    Vue.prototype.$_dxUtil = dxUtil; // DevExtreme 유틸 함수
    Vue.prototype.$_Loader = loaderManager; // 로더(로딩바) 설정
  },
};
