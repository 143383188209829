import RequestList from '@/pages/euc/phone/request/list';
import RequestUpdate from '@/pages/euc/phone/request/update';
import RequestWorker from '@/pages/euc/phone/request/worker';
import RequestHistory from '@/pages/euc/phone/request/history';
import RequestTypeRoutes from "@/router/euc/phone/request/type";

export default function RequestRoutes(commonComponents) {
    return [
        {
            /**
             * @description 전화 신청 목록 페이지
             * */
            path: '/euc/phone/request/list',
            name: 'RequestList',
            components: { default: RequestList, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 전화 신청 등록 및 수정 상세 페이지
             * */
            path: '/euc/phone/request/update',
            name: 'RequestUpdate',
            components: { default: RequestUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 작업자 관리 페이지
             * */
            path: '/euc/phone/request/worker',
            name: 'RequestWorker',
            components: { default: RequestWorker, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 작업 이력 목록 페이지
             * */
            path: '/euc/phone/request/history',
            name: 'RequestHistory',
            components: { default: RequestHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
        ...RequestTypeRoutes(commonComponents)
    ]
}