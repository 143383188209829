<template>
  <div class="themes_gp_style">
    <div class="wrap page-error ecs-error">
      <div class="ecs-error-box">
        <div class="error-header">
          <span @click="$_goMainPage()">
            <img v-if="logoUrl" :src="logoUrl" style="max-width: 200px; max-height: 30px" @error="setDefaultLogo" alt="로고 이미지" />
            <top-esp-logo v-else alt="로고 이미지" />
          </span>
        </div>

        <div class="ecs-error-box1">
          <div class="msg-error">
            <span class="txt-type01">401 ERROR</span>
          </div>

          <div class="msg-error">
            <strong>권한 없는 페이지 입니다.</strong>
            <p>{{ toPath }} <br /></p>
          </div>

          <div class="msg-btn-box">
            <div class="bottom-btn-wrap">
              <button type="button" class="ok" @click="$_goMainPage()">메인 화면으로 가기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import TopEspLogo from '@/layouts/top-esp-logo.vue';

  export default {
    components: { TopEspLogo },
    data() {
      return {
        toPath: '',
        logoUrl: null,
      };
    },
    computed: {
      /**
       * 로고 이미지 URL
       * @return {string|string|string}
       */
      getLogoUrl() {
        const logoImg = this.$store.getters.getThemeSetting?.logoImg;
        return logoImg ? this.$_getAttachFileURL(null, logoImg) : null;
      },
    },
    methods: {
      /**
       * 로고 이미지 로드 실패 시 기본 이미지로 변경
       */
      setDefaultLogo() {
        this.logoUrl = null; // 로고 URL을 제거하여 v-else 실행
      },
    },
    mounted() {
      this.logoUrl = this.getLogoUrl;
      if (this.$route.query.toPath) {
        this.toPath = `"${this.$route.query?.toPath}"`;
      }
    },
  };
</script>
