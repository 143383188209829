import SkillAssignList from '@/pages/cc/skl/assign/list';
import SkillGroupList from '@/pages/cc/skl/group/list';
import SkillAssignmentHistory from '@/pages/cc/skl/history/skl-assignment-hist';

export default function SkillRoutes(commonComponents) {
  return [
    {
      path: '/cc/skl/assign/list',
      name: 'SkillAssignList',
      components: { default: SkillAssignList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/skl/group/list',
      name: 'SkillGroupList',
      components: { default: SkillGroupList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/skl/history/skl-assignment-hist',
      name: 'SkillAssignmentHistory',
      components: { default: SkillAssignmentHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
