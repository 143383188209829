<template>
	<div class="container">
		<div class="space-y-10 p-6 h-full">
			<div class="p-2 space-y-4 border-2 border-gray-300 bg-gray-100">
				<table>
					<tbody>
						<tr class="flex w-full">
							<td class="flex w-1/4 ml-6">
								<div>시험기간</div>
								<div class="ml-6">{{ formatDate(initData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD') }}</div>
							</td>
							<td class="flex w-1/4">
								<div>시험시간</div>
								<div class="ml-9">{{ formatTime(initData.examStartTime) }} ~ {{ formatTime(initData.examEndTime) }}</div>
							</td>
							<td class="flex w-1/4">
								<div>시험시간(분)</div>
								<div class="ml-6">{{ diffMinutes() }}</div>
							</td>
							<td class="flex w-1/4">
								<div>문제 수</div>
								<div class="ml-6">{{ initData.sheetRegCnt }}</div>
							</td>
						</tr>
						<tr class="flex w-full mt-4">
							<td class="flex w-1/4 ml-6">
								<div>응시자명</div>
								<div class="ml-6">{{ targetInitData.agtNm }} [ {{ targetInitData.agtId }} ]</div>
							</td>
							<td class="flex w-1/4">
								<div>응시자 소속</div>
								<div class="ml-4">{{ targetInitData.deptNmPath }}</div>
							</td>
							<td class="flex w-1/4">
								<div class="text-xl"></div>
								<div class="ml-12 text-xl text-red-500"></div>
							</td>
							<td class="flex w-1/4">
								<div></div>
								<div class="ml-4"></div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="flex space-x-4 h-4/5">
				<div class="w-3/5">
					<div class="text-2xl p-2">{{ initData.examNm }}</div>
					<div class="border-2 px-4 h-5/6">
						<div class="flex gap-2 my-2 relative">
							<div class="absolute -left-8 -top-4">
								<div v-if="questionData.targetAnswerScore < 1">
									<img src="@/assets/images/ewm/wrong.png" class="w-20 h-20 z-50" />
								</div>
								<div
									v-else-if="questionData.targetAnswerScore > 0 && questionData.targetAnswerScore < questionData.maxScore"
								>
									<img src="@/assets/images/ewm/half_correct.png" class="w-20 h-20 z-50" />
								</div>
								<div v-else-if="questionData.targetAnswerScore === questionData.maxScore">
									<img src="@/assets/images/ewm/correct.png" class="w-20 h-20 z-50" />
								</div>
							</div>
							<div class="text-2xl">{{ questionData.questionOrd }}.</div>
							<div class="textSize" v-html="questionData.questionNm"></div>
							<div class="text-xl ml-4 text-red-500 w-32">(배점 {{ questionData.maxScore }}점)</div>
							<div v-if="questionData.fileGroupId">
								<DxButton icon="download" hint="다운로드" @click="onDownload" />
							</div>
						</div>

						<div class="h-2/5 relative overflow-y-auto">
							<div v-if="questionData.answerTypeCd == 996 || questionData.answerTypeCd == 997" class="pl-20 space-y-4">
								<div class="flex space-x-10 items-center">
									<div class="text-xl text-black items-center">정답:</div>
									<div class="border-2 border-black w-3/4 p-2 h-10">{{ questionData.targetAnswer }}</div>
								</div>
								<div class="flex space-x-4 items-center">
									<div class="text-xl text-red-500 items-center">실 정답:</div>
									<div class="border-2 border-red-400 w-3/4 p-2 h-10">{{ questionData.questions[0].detailDescription }}</div>
								</div>
							</div>

							<div v-else v-for="(item1, index1) in questionData.questions" :key="index1" class="pl-20 h-6 mb-2">
								<span v-if="item1.score > 0">
									<img src="@/assets/images/ewm/right_answer.png" class="w-6 h-6 mb-2" />
								</span>
								<span v-else class="pl-6"></span>
								<span v-if="questionData.targetAnswer && questionData.targetAnswer.length > 1" v-for="a in questionData.targetAnswer.split(',')" class="absolute">
									<img v-if="a == item1.questionNumber" src="@/assets/images/ewm/choose_answer.png" class="w-6 h-6" />
								</span>
								<span v-else-if="questionData.targetAnswer == item1.questionNumber" class="absolute">
									<img src="@/assets/images/ewm/choose_answer.png" class="w-6 h-6" />
								</span>
								<span class="text-xl">{{ item1.questionNumber }}.</span>
								<span class="text-xl ml-4">{{ item1.detailDescription }}</span>
							</div>
						</div>

						<div class="h-1/5 relative overflow-y-auto">
							<div class="flex space-x-6">
								<div class="text-lg w-20">정답해설</div>
								<div class="text-lg w-5/6">{{ questionData.description }}</div>
							</div>
							<div class="flex space-x-6 items-center">
								<div class="text-lg items-center w-20">채점 배점</div>
								<DxNumberBox
									v-model="questionData.targetAnswerScore"
									:styling-mode="stylingMode"
									:width="60"
									:height="30"
								/>
								<DxButton
									text="저장"
									:width="50"
									:height="30"
									class="btn_XS default filled txt_S medium"
									@click="targetScoring"
								/>
							</div>
						</div>
					</div>
					<div class="flex justify-center space-x-6 items-center pt-2">
						<div>
							<DxButton
								text="< 이전"
								styling-mode="outlined"
								class="btn_XS white light_filled"
								:disabled="questionData.questionOrd <= 1"
								@click="goPrev"
							/>
						</div>
						<div>{{ questionData.questionOrd }}/{{ initData.sheetRegCnt }}</div>
						<div>
							<DxButton
								text="다음 >"
								styling-mode="outlined"
								class="btn_XS white light_filled"
								:disabled="questionData.questionOrd >= initData.sheetRegCnt"
								@click="goNext"
							/>
						</div>
					</div>
				</div>

				<div class="w-2/5">
					<div class="flex text-2xl p-2 space-x-5">
						<div class="flex">
							<div class="mr-2">전체 문제</div>
							<DxButton
								@click="answerCheck('all')"
								v-text="initData.sheetRegCnt"
								class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center"
							>
							</DxButton>
						</div>
						<div class="flex">
							<div class="mr-2">채점 완료</div>
							<DxButton
								@click="answerCheck('check')"
								v-text="initData.checkCnt"
								class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center"
							>
							</DxButton>
						</div>
						<div class="flex">
							<div class="mr-2">채점 미완료</div>
							<DxButton
								@click="answerCheck('noCheck')"
								v-text="initData.uncheckCnt"
								class="bg-gray-200 rounded-full h-8 w-8 flex items-center justify-center"
							>
							</DxButton>
						</div>
					</div>

					<div class="border-2 h-5/6">
						<DxDataGrid
							key-expr="questionId"
							:data-source="formData"
							:show-borders="false"
							:show-column-lines="true"
							:show-row-lines="true"
							width="100%"
							height="100%"
							:selected-row-keys="questionSelectedRowKeys"
							@selection-changed="selectionChangedData"
						>
							<DxFilterRow :visible="false" />
							<DxSelection mode="single" />
							<DxScrolling mode="virtual" />
							<DxLoadPanel :enabled="true" />

							<DxColumn
								caption="문항순서"
								data-field="questionOrd"
								cell-template="answerCorrectTemplate"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="70"
							/>
							<DxColumn caption="답안" data-field="targetAnswer" alignment="center" :allowFiltering="false" :visible="true" />
							<DxColumn
								caption="정답유형"
								data-field="answerTypeCd"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="70"
							>
								<DxLookup :data-source="codes.answerTypeCd.dataSource" value-expr="codeId" display-expr="codeNm" />
							</DxColumn>
							<DxColumn
								caption="배점"
								data-field="maxScore"
								alignment="center"
								:allowFiltering="false"
								:visible="true"
								:width="60"
							/>
							<DxColumn caption="" cell-template="colorChangeTemplate" alignment="center" :visible="true" :width="40" />
							<template #answerCorrectTemplate="{ data }">
								<div class="flex justify-center relative">
									<div v-if="data.data.targetAnswerScore === 0" class="absolute">
										<img src="@/assets/images/ewm/wrong.png" class="w-8 h-8" />
									</div>
									<div
										v-else-if="data.data.targetAnswerScore > 0 && data.data.targetAnswerScore < data.data.maxScore"
										class="absolute"
									>
										<img src="@/assets/images/ewm/half_correct.png" class="w-8 h-8" />
									</div>
									<div v-else-if="data.data.targetAnswerScore === data.data.maxScore" class="absolute">
										<img src="@/assets/images/ewm/correct.png" class="w-8 h-8" />
									</div>
									<div class="items-center">{{ data.data.questionOrd }}.</div>
								</div>
							</template>
							<template #colorChangeTemplate="{ data }">
								<div v-if="data.data.targetAnswerScore >= 0">
									<div class="w-4 h-4 bg-green-500"></div>
								</div>
								<div v-else>
									<div class="w-4 h-4 bg-gray-400"></div>
								</div>
							</template>
						</DxDataGrid>
					</div>
				</div>
			</div>

			<div class="bottom-btn-wrap">
				<DxButton text="채점완료" :width="100" :height="40" class="btn_XS default filled txt_S medium" @click="closePreview" />
				<!-- <DxButton text="닫기" :width="100" :height="40" class="btn_XS white light_filled" @click="closePreview" /> -->
			</div>
		</div>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxDataGrid, DxColumn, DxLookup, DxFilterRow, DxSelection, DxScrolling, DxLoadPanel } from 'devextreme-vue/data-grid';
import {formatDate, isSuccess} from "@/utils/common-lib";

export default {
	components: {
		DxButton,
		DxTextBox,
		DxNumberBox,

		DxDataGrid,
		DxColumn,
		DxLookup,
		DxFilterRow,
		DxSelection,
		DxScrolling,
		DxLoadPanel,
	},
	props: {},
	watch: {},
	data() {
		return {
			select: null,
			stylingMode: 'outlined',
			initData: [], //초기
			targetInitData: [], //초기대상
			formData: [], //그리드
			questionData: [], //문항
			originFormData: [], //그리드 원본
			selectedRowIndex: 0,
			questionSelectedRowKeys: [],
			codes: {
				answerTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
		};
	},
	computed: {},
	methods: {
    formatDate,
		onDownload() {
			const fileGroupId = this.questionData.fileGroupId;
			const fileName = this.questionData.fileName;
			this.$_downloadAttachFile(fileGroupId, fileName);
		},
		formatTime(time) {
			return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
		},
		diffMinutes() {
			const startTime = new Date();
			startTime.setHours(parseInt(this.initData.examStartTime.slice(0, 2), 10));
			startTime.setMinutes(parseInt(this.initData.examStartTime.slice(2, 4), 10));
			startTime.setSeconds(0);

			const endTime = new Date();
			endTime.setHours(parseInt(this.initData.examEndTime.slice(0, 2), 10));
			endTime.setMinutes(parseInt(this.initData.examEndTime.slice(2, 4), 10));
			endTime.setSeconds(0);

			const diffInMinutes = Math.floor((endTime - startTime) / (1000 * 60));
			return diffInMinutes;
		},
		/** @description : 이전문항 */
		goPrev() {
			if (this.selectedRowIndex === 0) {
				this.$_Msg('첫번째 문항입니다.');
			} else {
				--this.selectedRowIndex;
				this.questionData = this.originFormData[this.selectedRowIndex];

				this.questionSelectedRowKeys = [this.questionData.questionId];
			}
		},
		/** @description : 다음문항 */
		goNext() {
			if (this.selectedRowIndex < this.originFormData.length - 1) {
				this.selectedRowIndex++;
				this.questionData = this.originFormData[this.selectedRowIndex];

				this.questionSelectedRowKeys = [this.questionData.questionId];
			} else {
				this.$_Msg('마지막 문항입니다.');
			}
		},
		answerCheck(type) {
			if (type === 'all') {
				//전체
				this.formData = this.originFormData;
				this.questionData = this.formData[0];
				this.selectedRowIndex = this.questionData.questionOrd - 1;
				this.questionSelectedRowKeys = [this.questionData.questionId];
			} else if (type === 'check') {
				//체크
				this.formData = this.originFormData.filter(item => item.targetAnswerScore >= 0);
				if (this.formData.length > 0) {
					this.questionData = this.formData[0];
					this.selectedRowIndex = this.questionData.questionOrd - 1;
					this.questionSelectedRowKeys = [this.questionData.questionId];
				}
			} else if (type === 'noCheck') {
				//미체크
				this.formData = this.originFormData.filter(item => item.targetAnswerScore < 0);
				if (this.formData.length > 0) {
					this.questionData = this.formData[0];
					this.selectedRowIndex = this.questionData.questionOrd - 1;
					this.questionSelectedRowKeys = [this.questionData.questionId];
				}
			}
		},
		/** @description : 로우 클릭 이벤트 */
		selectionChangedData(e) {
			this.questionSelectedRowKeys = e.selectedRowKeys;

			if (e.selectedRowsData.length > 0) {
				this.questionData = e.selectedRowsData[0];
				this.selectedRowIndex = this.questionData.questionOrd - 1;
			}
		},
		/** @description : 채점완료 */
		async targetScoring() {
			if (await this.$_Confirm('점수를 수정하시겠습니까?')) {
				const payload = {
					actionName: 'EWM_EXAM_STATUS_ANSWER_SCORE_SAVE',
					data: {
						id: this.questionData.targetAnswerId,
						scheId: this.questionData.scheId,
						sheetId: this.questionData.sheetId,
						questionId: this.questionData.questionId,
						agtId: this.questionData.agtId,
						answer: this.questionData.targetAnswer,
						score: this.questionData.targetAnswerScore,
					},
          useErrorPopup: true,
				};
				await this.CALL_EWM_API(payload);
			}
		},
		// /** @description : 채점완료 */
		// async scoringEnd() {
		// 	console.log('들어갈 전체11111111111111111111',this.formData);
		// 	let data = this.formData.map(item => ({
		// 		id: item.targetAnswerId,
		// 		scheId: item.scheId,
		// 		sheetId: item.sheetId,
		// 		questionId: item.questionId,
		// 		agtId: item.agtId,
		// 		answer: item.answer,
		// 		score: this.questionData.targetAnswerId? this.questionData.targetAnswerScore : this.questionData.targetLogScore,
		// 	}));
		// 	console.log('가공된 데이터',data);
		// 	// const payload = {
		// 	// 	actionName: 'EWM_PERSONAL_EXAM_SAVE',
		// 	// 	data: {
		// 	// 		data: data,
		// 	// 	},
		// 	// 	loading: false,
		// 	// };
		// 	// const res = await this.CALL_EWM_API(payload);
		// 	// if (res.status === 200) {
		// 	// 	if (res.data.header.resCode === 'success') {
		// 	// 		this.$_Msg('채점이 완료되었습니다.');
		// 	// 		this.$router.push({ path: '/ewm/exam/implementation/exam-status' });
		// 	// 	} else {
		// 	// 		this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
		// 	// 	}
		// 	// } else {
		// 	// 	this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
		// 	// }
		// },
		/** @description : 닫기 */
		closePreview() {
			this.$router.push({ path: '/ewm/exam/implementation/exam-status', params: { select: this.select } });
		},
		/** @description: 데이터 조회 메서드 */
		async selectFormData() {
			const payload = {
				actionName: 'EWM_EXAM_SHEET',
				path: '/' + this.initData.sheetId + '/questions',
				useErrorPopup: true,
			};

			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
        res.data.data.sort((a, b) => a.questionOrd - b.questionOrd);
        res.data.data.forEach(d => {
          d.questions.sort((s, c) => s.questionNumber - c.questionNumber);
        });

        const payload2 = {
          actionName: 'EWM_PERSONAL_EXAM_ANSWER_TARGET',
          data: {
            scheId: this.targetInitData.scheId,
            sheetId: this.targetInitData.sheetId,
            agtId: this.targetInitData.agtId,
          },
          useErrorPopup: true,
        };

        const res2 = await this.CALL_EWM_API(payload2);
        if (isSuccess(res2)) {
          this.originFormData = res.data.data.map(item => {
            const matchingAnswer = res2.data.data.find(t => t.questionId === item.questionId);
            return matchingAnswer ? { ...item, ...matchingAnswer } : item;
          });

          if (this.originFormData.length > 0) {
            this.questionData = this.originFormData[0];
            this.selectedRowIndex = this.questionData.questionOrd - 1;
            this.questionSelectedRowKeys = [this.questionData.questionId];
          }

          this.initData.sheetRegCnt = this.originFormData.length;
          this.initData.uncheckCnt = this.originFormData.filter(item => item.targetAnswerScore < 0).length;
          this.initData.checkCnt = this.originFormData.filter(item => item.targetAnswerScore >= 0).length;
        }
			}
			this.formData = this.originFormData;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_answer_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.reqParams = this.$store.getters.getDetailParams;
			this.initData = this.reqParams.sheetId;
			this.targetInitData = this.reqParams.targetData;
			this.select = this.reqParams.select ? this.reqParams.select : null;

			this.initCodeMap().then(() => {
				this.codes.answerTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_answer_type'], 2);
			});

			this.selectFormData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>

<style scoped>
.textSize {
	margin-left: 0.5em;
	font-size: 1.5rem;
	line-height: 2rem;
	max-width: 100%;
	max-height: 100%;
}

.textSize::v-deep * {
	font-size: 1.5rem;
	line-height: 2rem;
	max-width: 100%;
	max-height: 100%;
}
</style>
