var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('div',{staticClass:"progress-loader",class:_vm.containerClass},[_c('div',{staticClass:"progress-container"},[_c('div',{staticClass:"progress-status"},[_c('div',{staticClass:"current-step-info"},[_c('div',{staticClass:"step-info"},[_c('span',{staticClass:"step-label"},[_vm._v(_vm._s(_vm.currentStep?.label))]),_c('div',{staticClass:"status-wrapper"},[(_vm.isCurrentStepInProgress)?_c('span',{staticClass:"step-status"},[_vm._v(" "+_vm._s(_vm.$_lang('COMMON.WORD.IN_PROGRESS'))+" "),_c('span',{staticClass:"progress-percentage"},[_vm._v("(0%)")])]):(_vm.getStepStatus(_vm.currentStepIndex) === 'completed')?_c('span',{staticClass:"step-status"},[_c('strong',[_vm._v(_vm._s(_vm.$_lang('COMMON.WORD.COMPLETED')))]),_c('span',{staticClass:"progress-percentage"},[_vm._v("(100%)")])]):(_vm.currentStep?.error)?_c('span',{staticClass:"step-status error"},[_vm._v(" "+_vm._s(_vm.$_lang('COMMON.WORD.FAILED'))+" ")]):_vm._e()])]),_c('span',{staticClass:"step-count"},[_vm._v(_vm._s(_vm.currentStepIndex + 1)+" / "+_vm._s(_vm.steps.length))])]),_c('div',{staticClass:"progress-bar-container"},[_c('div',{staticClass:"progress-bar-bg"},[_c('div',{staticClass:"progress-segments"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"progress-segment",class:{
                'in-progress': index === _vm.currentStepIndex,
                'completed': index < _vm.currentStepIndex || _vm.getStepStatus(index) === 'completed',
                'pending': index > _vm.currentStepIndex
              },style:({
                width: `${_vm.stepsProgress[index]}%`,
                backgroundColor: _vm.themeColor,
                opacity: _vm.getSegmentOpacity(index)
              })})}),0)])]),(_vm.currentStep?.error)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.currentStep.error))]):_vm._e()])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }