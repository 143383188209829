<template>
  <div>
    <!-- Main Layer -->
    <div class="page-sub-box sub_new_style01 sub_ui_box1">
      <div class="layout-cut-rio clearfix">
        <!-- Top Layer -->
        <div class="space-y-4 py-8">
          <div class="sub_title_txt">
            <h2>시험지관리 상세</h2>
          </div>
          <table class="table_form line-bin">
            <colgroup>
              <col style="width: 130px" />
              <col style="width: auto" />
              <col style="width: 130px" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <label for="label5">시험타입 <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <DxSelectBox
                    placeholder="시험구분 선택"
                    :items="ewmCodes.examSheetType.values"
                    display-expr="label"
                    value-expr="value"
                    v-model="formData.examSheetType"
                    :styling-mode="config.stylingMode"
                    :width="150"
                    :height="30"
                  >
                    <DxValidator>
                      <DxRequiredRule message="시험타입은 필수입니다." />
                    </DxValidator>
                  </DxSelectBox>
                </td>
                <th scope="row">
                  <label for="label5">사용여부 <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <DxSelectBox
                    placeholder="사용여부 선택"
                    :items="codes.stringUsedFlag"
                    display-expr="label"
                    value-expr="value"
                    v-model="formData.viewFl"
                    :styling-mode="config.stylingMode"
                    :width="150"
                    :height="30"
                  >
                    <DxValidator>
                      <DxRequiredRule message="사용여부는 필수입니다." />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">시험지명 <span class="icon_require">필수항목</span></label>
                  <br />{{ formData.examNm ? formData.examNm.length : 0 }} / {{ limitNumberTexts.maxLengths.examNm }}자
                </th>
                <td colspan="3">
                  <DxTextBox
                    placeholder="시험지명"
                    :show-clear-button="true"
                    v-model="formData.examNm"
                    :max-length="limitNumberTexts.maxLengths.examNm"
                    @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'examNm')"
                    :width="400"
                    :height="30"
                  >
                    <DxValidator>
                      <DxRequiredRule message="시험지명은 필수입니다." />
                    </DxValidator>
                  </DxTextBox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Top Layer -->

        <esp-dx-data-grid :data-grid="dataGrid" ref="questionsGrid" @reorder="onReorder" />
      </div>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <div class="bottom-btn-wrap">
          <DxButton
            text="저 장"
            class="btn_XS default filled txt_S medium"
            :disabled="!canModify"
            :width="120"
            :height="40"
            :use-submit-behavior="true"
            @click="onSaveFormData"
          />
          <DxButton text="취 소" class="btn_XS white filled txt_S medium" :width="120" :height="40" @click="onCancelFormData" />
        </div>
      </div>
    </section>
    <!-- Main Layer -->

    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.height
              : 40
            : 40,
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.height
              : 40
            : 40,
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxButton } from 'devextreme-vue/button';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
  import { isSuccess } from '@/utils/common-lib';

  import modalAutoAdd from '@/pages/ewm/exam/setting/sheet/modal-auto-add.vue';
  import modalManualAdd from '@/pages/ewm/exam/setting/sheet/modal-manual-add.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;
  let selectedIdList = [];

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxTextBox,
      DxNumberBox,
      DxSelectBox,
      DxRequiredRule,
      DxValidator,
      DxPopup,
      DxPosition,
      DxToolbarItem,
      modalAutoAdd,
      modalManualAdd,
    },
    data() {
      return {
        codeMap: {},
        canModify: true,
        autoAddInstance: null,
        menualAddInstance: null,
        codes: {
          stringUsedFlag: [],
          questionDivisionCd: {
            //문항구분
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          questionDivisionDetailCd: {
            //문항구분상세
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          answerTypeCd: {
            //정답유형
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          levelCd: {
            //난이도
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        config: {
          updateYn: false, //등록 or 수정 여부 체크
          textStylingMode: 'filled',
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },
        initData: {
          scheCnt: null,
        },
        formData: {},
        originQuestions: [],
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: [],
          componentName: '',
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            examNm: 50,
          },
        },
        dataGrid: {
          refName: 'questionsGrid',
          keyExpr: 'questionId', // 업데이트 key값이 id가 아닌경우 셋팅
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSource: [],
          height: 'calc(100vh - 470px)', // 주석처리시 100%
          customEvent: {
            reorder: true,
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '자동추가',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 80,
                  height: 30,
                  onInitialized: e => {
                    this.autoAddInstance = e;
                  },
                  onClick() {
                    vm.onOpenModal('modalAutoAdd', {
                      title: '자동 시험문항 추출',
                      buttons: {
                        save: {
                          text: '문항추가',
                          width: 80,
                          height: 40,
                        },
                        cancel: {
                          text: '닫기',
                          width: 80,
                          height: 40,
                        },
                      },
                      width: '900',
                      height: '500',
                    });
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '수동추가',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 80,
                  height: 30,
                  onInitialized: e => {
                    this.menualAddInstance = e;
                  },
                  onClick() {
                    vm.onOpenModal(
                      'modalManualAdd',
                      {
                        title: '수동 시험문항 추출',
                        buttons: {
                          save: {
                            text: '문항추가',
                            width: 80,
                            height: 40,
                          },
                          cancel: {
                            text: '닫기',
                            width: 80,
                            height: 40,
                          },
                        },
                        width: '1100',
                        height: '700',
                      },
                      {
                        selectedIdList,
                      },
                    );
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          dragging: {
            sortColumn: 'questionOrd',
            allowReordering: true,
            dropFeedbackMode: 'push',
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          summary: {
            calculateCustomSummary: null,
            groupItems: [],
            totalItems: [
              {
                showInColumn: 'questionNm',
                column: 'score',
                displayFormat: '총 배점: {0}',
                summaryType: 'sum',
                skipEmptyValues: true,
                cssClass: 'absolute right-4 m-2 text-lg tracking-wider',
              },
              {
                showInColumn: 'questionNm',
                column: 'questionOrd',
                displayFormat: '총 문항 수: {0}',
                summaryType: 'count',
                skipEmptyValues: true,
                cssClass: 'm-2 text-lg tracking-wider',
              },
            ],
            recalculateWhileEditing: false,
            skipEmptyValues: true,
            texts: {},
          },
          columns: [
            {
              caption: '문항순서',
              dataField: 'questionOrd',
              width: 105,
              alignment: 'center',
              visible: true,
              sortOrder: 'asc',
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              allowSorting: false,
            },
            {
              caption: '문항구분',
              dataField: 'questionDivisionCd',
              width: 120,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              allowHeaderFiltering: true,
              lookup: {},
            },
            {
              caption: '문항구분상세',
              dataField: 'questionDivisionDetailCd',
              width: 130,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              allowHeaderFiltering: true,
              lookup: {},
            },
            {
              caption: '문항내용',
              dataField: 'questionNm',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const divEl = document.createElement('div');
                divEl.innerHTML = options.value;
                container.append(divEl);
              },
            },
            {
              caption: '정답유형',
              dataField: 'answerType',
              width: 130,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.answerType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '난이도',
              dataField: 'examLevel',
              width: 120,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: true,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.examLevel.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '첨부',
              dataField: 'fileId',
              width: 90,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, options) => {
                if (!options.data.fileGroupId) {
                  let aTag = document.createElement('div');
                  aTag.innerText = `-`;
                  container.append(aTag);
                } else {
                  let button = new DxButton({
                    propsData: {
                      icon: 'download',
                      hint: '다운로드',
                      value: options.value,
                      onClick: () => {
                        vm.downloadBlobFile(options.data.fileGroupId);
                      },
                    },
                  });
                  button.$mount();
                  container.append(button.$el);
                }
              },
            },
            {
              caption: '배점',
              dataField: 'score',
              width: 90,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              caption: ' ',
              dataField: 'questionId',
              width: 50,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowSorting: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, options) => {
                let button = new DxButton({
                  propsData: {
                    elementAttr: { class: 'trash' },
                    width: 30,
                    height: 30,
                    value: options.value,
                    disabled: !vm.canModify,
                    onClick: () => {
                      vm.onDeleteData(options.data);
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              },
            },
          ],
        },
      };
    },
    computed: {
      ewmCodes() {
        return this.$_enums.ewm;
      },
    },
    methods: {
      option(instance, key, value) {
        this.$data[instance]?.component.option(key, value);
      },
      setDisabledOptions(value) {
        this.option('autoAddInstance', 'disabled', value);
        this.option('menualAddInstance', 'disabled', value);
      },
      /**
       * 파일 다운로드
       *
       * @param fileGroupId
       */
      async downloadFile(fileGroupId) {
        const fileList = await this.$_getListAttachFile(fileGroupId);

        if (!fileList || fileList.length === 0) {
          this.$_Msg('다운로드 할 파일이 없습니다.');
          return;
        }

        fileList.forEach(fileInfo => {
          this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
        });
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 팝업 오픈시 메서드 */
      onOpenModal(componentNm, componentInitData, data) {
        if (this.initData.scheCnt > 0) {
          this.$_Msg(`이미 출제된 시험지 입니다.`);
          return false;
        }
        this.modal.componentName = componentNm;
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 그리드 내 문항 삭제 메서드 */
      async onDeleteData(data) {
        if (this.formData.id) {
          if (this.initData.scheCnt > 0) {
            this.$_Msg(`이미 출제된 시험지 입니다.`);
            return;
          }

          if (await this.$_Confirm('선택한 시험 문항을 삭제하시겠습니까?')) {
            const includeId = this.originQuestions.includes(data.questionId);

            if (includeId) {
              let payload = {
                actionName: 'EWM_EXAM_SHEET_DELETE_QUESTION',
                path: '/' + this.formData.id + '/question/' + data.questionId,
                loading: true,
              };
              let res = await this.CALL_EWM_API(payload);
              if (isSuccess(res)) {
                //삭제 후 ord 재정의
                let lastQuestionOrd = 1;
                let leftQuestions = this.dataGrid.dataSource.filter(item => data.questionId !== item.questionId);
                let params = {
                  sheetId: this.formData.id,
                  examSheetType: this.formData.examSheetType,
                  examNm: this.formData.examNm,
                  viewFl: this.formData.viewFl,
                  questions: [],
                };
                for (const question of leftQuestions) {
                  params.questions.push({
                    questionId: question.questionId,
                    questionOrd: lastQuestionOrd++,
                  });
                }
                const payload = {
                  actionName: 'EWM_EXAM_SHEET_UPDATE',
                  path: '/' + this.formData.id,
                  data: params,
                  loading: true,
                  useErrorPopup: true,
                };
                const res = await this.CALL_EWM_API(payload);
                if (!isSuccess(res)) {
                  this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
                }
                this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
              } else {
                this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
              }
            } else {
              this.dataGrid.dataSource = this.dataGrid.dataSource.filter(d => d.questionId !== data.questionId);
            }
          } else {
            return false;
          }
          await this.selectFormData();
        } else {
          this.dataGrid.dataSource = this.dataGrid.dataSource.filter(d => d.questionId !== data.questionId);
        }
      },
      /** @description: 팝업 확인 버튼 클릭 메서드 */
      async onConfirmModal() {
        let objectArray = [];
        let newData = []; //새로 추가되는 문항 id
        let lastQuestionOrd = 0; //현재 db에 저장되어있는 마지막 ord값 체크

        if (this.modal.contentData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return false;
        }

        if (this.modal.componentName === 'modalAutoAdd') {
          //자동추출 전 기존문항 삭제
          if (this.dataGrid.dataSource.length > 0) {
            let deletedIds = this.dataGrid.dataSource.map(d => {
              return d.questionId;
            });
            let payload = {
              actionName: 'EWM_EXAM_SHEET_REG_DELETE',
              data: {
                sheetId: Number(this.formData.id),
                questionIds: deletedIds,
              },
            };

            const res = await this.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              this.dataGrid.dataSource = [];
              selectedIdList = [];
              objectArray = this.modal.contentData;
            } else {
              this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          } else {
            //기존에 값이 없을때
            this.dataGrid.dataSource = [];
            selectedIdList = [];
            objectArray = this.modal.contentData;
          }
        } else if (this.modal.componentName === 'modalManualAdd') {
          objectArray = this.modal.contentData;
          if (this.dataGrid.dataSource.length > 0) {
            lastQuestionOrd = Math.max(...this.dataGrid.dataSource.map(q => q.questionOrd));
          }
        }

        //기존데이터 확인
        objectArray.forEach(i => {
          if (!selectedIdList.includes(i.id)) {
            newData.push(i);
          }
        });

        let newQuestionOrds = Array.from({ length: newData.length }, (_, index) => lastQuestionOrd + index + 1);

        newData.map((obj, index) => {
          this.formData.questionCnt++;
          this.formData.totalScore += obj.score;
          const newQuestion = {
            questionOrd: newQuestionOrds[index],
            questionId: obj.id,
            questionDivisionCd: obj.questionDivisionCd,
            questionDivisionDetailCd: obj.questionDivisionDetailCd,
            questionNm: obj.questionNm,
            fileId: obj.fileId,
            fileName: obj.fileName,
            fileGroupId: obj.fileGroupId,
            answerType: obj.answerType,
            examLevel: obj.examLevel,
            score: obj.score,
            scoringTypeCd: obj.scoringTypeCd,
            viewCd: obj.viewCd,
          };
          this.dataGrid.dataSource.push(newQuestion);
        });
        selectedIdList = this.dataGrid.dataSource.map(d => d.questionId);
        this.isOpenModal(false);
      },
      /** @description : 그리드 순서 변경 이벤트 */
      async onReorder(e) {
        let fromIndex = e.fromIndex;
        let toIndex = e.toIndex;
        let tempData = this.dataGrid.dataSource[fromIndex];

        // 데이터를 임시 변수에 저장
        this.$set(this.dataGrid.dataSource, fromIndex, this.dataGrid.dataSource[toIndex]);
        this.$set(this.dataGrid.dataSource, toIndex, tempData);
        this.dataGrid.dataSource.forEach((question, index) => {
          question.questionOrd = index + 1;
        });

        let params = {
          sheetId: this.formData.id,
          examSheetType: this.formData.examSheetType,
          examNm: this.formData.examNm,
          viewFl: this.formData.viewFl,
          questions: [],
        };
        for (const question of this.dataGrid.dataSource) {
          params.questions.push({
            questionId: question.questionId,
            questionOrd: question.questionOrd,
          });
        }
      },

      /** @description : 저장버튼 */
      async onSaveFormData(e) {
        if (this.initData.scheCnt > 0) {
          this.$_Msg(`이미 출제된 시험지 입니다.`);
          return;
        }

        if (!e.validationGroup.validate().isValid) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
          return;
        }

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        if (!this.formData.id) {
          //추가 페이지
          let paramsAdd = {
            sheetId: this.formData.id,
            examSheetType: this.formData.examSheetType,
            examNm: this.formData.examNm,
            viewFl: this.formData.viewFl,
          };

          const payloadAdd = {
            actionName: 'EWM_EXAM_SHEET_SAVE',
            data: paramsAdd,
            loading: true,
          };

          const resAdd = await this.CALL_EWM_API(payloadAdd);

          if (isSuccess(resAdd)) {
            let questions = this.dataGrid.dataSource.map(q => ({
              questionId: q.questionId,
              questionOrd: q.questionOrd,
            }));
            let payload2 = {
              actionName: 'EWM_EXAM_SHEET_SAVE',
              path: '/' + resAdd.data.data + '/questions',
              data: {
                questions: questions,
              },
              loading: true,
            };
            let res2 = await this.CALL_EWM_API(payload2);
            if (isSuccess(res2)) {
              this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
              await this.$router.push('/ewm/exam/setting/sheet/list');
            } else {
              this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          } else {
            this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        } else {
          //수정페이지
          let paramsUpdate = {
            examSheetType: this.formData.examSheetType,
            examNm: this.formData.examNm,
            viewFl: this.formData.viewFl,
          };

          const payloadUpdate = {
            actionName: 'EWM_EXAM_SHEET_UPDATE',
            path: '/' + this.formData.id,
            data: paramsUpdate,
            loading: true,
          };

          const resUpdate = await this.CALL_EWM_API(payloadUpdate);

          if (isSuccess(resUpdate)) {
            let questions = [];
            for (const q of this.dataGrid.dataSource) {
              if (!this.originQuestions.includes(q.questionId)) {
                questions.push({
                  questionId: q.questionId,
                  questionOrd: q.questionOrd,
                });
              }
            }
            let payload2 = {
              actionName: 'EWM_EXAM_SHEET_SAVE',
              path: '/' + this.formData.id + '/questions',
              data: {
                questions: questions,
              },
              loading: true,
            };
            let res2 = await this.CALL_EWM_API(payload2);
            if (isSuccess(res2)) {
              this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
              await this.$router.push('/ewm/exam/setting/sheet/list');
            } else {
              this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          }
        }
      },
      /** @description : 취소버튼 */
      onCancelFormData() {
        this.$router.push('/ewm/exam/setting/sheet/list');
      },
      /** @description : 데이터 호출 메서드 */
      async selectFormData() {
        if (this.formData.id) {
          //수정페이지
          const payload = {
            actionName: 'EWM_EXAM_SHEET',
            path: '/' + this.formData.id,
            loading: false,
            useErrorPopup: true,
          };
          const res = await this.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            this.formData = res.data.data[0];
            this.dataGrid.dataSource = res.data.data[0].questions;
            this.originQuestions = res.data.data[0].questions.map(d => d.questionId);
          }
        } else {
          //추가 페이지D
          this.formData.examSheetType = '';
          this.formData.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
          this.formData.examNm = '';
          this.formData.questionCnt = 0;
          this.formData.totalScore = 0;
          this.formData.questions = [];
          this.dataGrid.dataSource = this.formData.questions;
        }

        this.setDisabledOptions(!this.canModify);
        selectedIdList = this.dataGrid.dataSource.map(d => d.questionId);
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_question_division');
      },
    },
    created() {
      vm = this;
      this.reqParams = this.$store.getters.getDetailParams;
      if (!this.reqParams) {
        this.$_goPrePage();
        return;
      }

      this.initCodeMap().then(() => {
        this.codes.questionDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 2); //문항구분
        this.codes.questionDivisionDetailCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_question_division'], 3); //문항구분상세
        this.$refs.questionsGrid.getInstance.columnOption('questionDivisionCd', 'lookup', this.codes.questionDivisionCd);
        this.$refs.questionsGrid.getInstance.columnOption('questionDivisionDetailCd', 'lookup', this.codes.questionDivisionDetailCd);
      });

      this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;

      if (this.config.updateYn) {
        this.initData = this.reqParams.data;
        this.formData.id = this.reqParams.data.id;
      } else {
        this.formData.id = null;
      }

      this.canModify = !this.initData.scheCnt || this.initData.scheCnt < 1;
      this.selectFormData();
    },
    mounted() {
      this.codes.stringUsedFlag = this.$_enums.common.stringUsedFlag.values;
    },
  };
</script>
