<!--
  PACKAGE_NAME : src\pages\ai\esp-chat
  FILE_NAME : esp-chat-result
  AUTHOR : hpmoon
  DATE : 2025-02-05
  DESCRIPTION : ESP Chat Result
-->
<template>
  <div class="scroll-view-back mar_ri11 mar_b20 pad_20">
    <div class="query messages">
      <div ref="queryMessage" class="message" v-html="printQuery" />
      <span v-if="!loadingVisible">
        {{ isEmpty(time) ? '' : formatDate(time, 'YYYYMMDDHHmmssSSS').format('YYYY.MM.DD HH:mm:ss') }}
      </span>
    </div>

    <div v-if="queryStatus" class="answer messages">
      <div v-if="loadingVisible" class="message">
        <div class="typing-indicator"><span></span><span></span><span></span></div>
      </div>

      <transition v-else v-for="(item, index) in content" :key="index" name="fade">
        <div v-show="item.show" style="width: 100%">
          <div v-if="item.type === 'markdown'" class="message">
            <div class="markdown-body" v-html="converter.render(item.data)" />
          </div>
          <div v-if="item.type === 'table'">
            <esp-dx-data-grid :ref="dataGrid.refName" :data-grid="dataGrid" class="mar_bo5"/>
            <img src="@/assets/images/sub_directory.png" alt="부가 기능" />
            <esp-dx-button
              i18n="COMPONENTS.EXCEL"
              text="엑셀"
              prefix-icon="excel"
              color="green"
              @click="onClickExcelDownload()"
            />
          </div>
          <div v-if="item.type === 'form'">
            <div class="message markdown-body" v-html="converter.render(item.data)" />
            <div v-if="data.index === lastMsgIndex">
              <img src="@/assets/images/sub_directory.png" alt="부가 기능" />
              <esp-dx-button
                i18n="COMMON.WORD.INSERT_FORM"
                text="서식 삽입"
                prefix-icon="insert-form"
                color="white"
                @click="setForm(item.data)"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import MarkdownIt from 'markdown-it/dist/markdown-it';
  import ExcelJS from "exceljs";
  import saveAs from 'file-saver';
  import { exportDataGrid } from "devextreme/excel_exporter";
  import { formatDate, isEmpty } from "@/utils/common-lib";

  export default {
    components: {
      EspDxDataGrid,
      EspDxButton,
    },

    props: {
      session_id: String, // Session ID
      data: {
        user: String, // 질의 내용
        assistant: String, // 답변 내용
        insert_date: String, // 질의 일시
        index: Number, // 질답 Index
        historyFl: Boolean, // 이력 여부
      },
      lastMsgIndex: Number, // 마지막 질답 Index
    },

    data() {
      return {
        queryStatus: true, // 질의 성공여부
        content: [], // 답변 내용
        time: '', // 질의 일시
        loadingVisible: true, // 답변 대기 로딩 표출여부
        converter: new MarkdownIt({breaks: true}),// MarkDown 컨버터

        dataGrid: {
          refName: 'dataGridContainer',
          keyExpr: null,
          showBorders: true, //border 유무
          height: '100%',
          width: '100%',
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false
          },
          excel: {
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          filterRow: { visible: false }, // 행 검색 필터
          page: { enabled: false }, // paging, pager 설정(하나라 합침)
          allowColumnResizing: true,
          columnMinWidth: 100,
          scrolling: {
            scrollByContent: false,
          },
          selecting: { // 로우 선택 설정
            mode: 'single', // 행 단일/멀티 선택 타입 : ['single', 'multiple']
          },
          disableTotalCount: true, // 상단 조회 건수 노출 설정값
          showActionButtons: { // 상단 버튼 노출 설정값
            delete: false,
          },
          dataSource: [], //dataSource 설정
          columns: [],
        },
      };
    },

    computed: {
      /** @description 질문 내용 반환 */
      printQuery() {
        return this.data.user.replaceAll('\n', '<br/>');
      },
    },

    methods: {
      isEmpty,
      formatDate,

      /** @description 질의 수행 */
      async queryExecute() {
        const payload = {
          actionName: 'ESP_CHAT_QUERY',
          data: {
            session_id: this.session_id,
            query: this.data.user
          },
        };

        const res = await this.CALL_ESP_CHAT_API(payload);
        if (res.status === 200) {
          await this.setQuery(res.data);
        } else {
          this.queryStatus = false;
          const container = this.$refs.queryMessage;
          const failBox = document.createElement('div');
          failBox.classList.add('fail-box');
          failBox.innerHTML = `❌`;
          container.appendChild(failBox);
          this.loadingVisible = false;
          this.$emit('loading', this.loadingVisible);
        }
      },

      /** @description 질답 Setting */
      async setQuery(data) {
        let type = 'markdown';
        let tableData;
        data.assistant.split('\t').forEach(item => {
          if(isEmpty(item)) {
            type = data.type;
            return;
          }

          switch (type) {
            case 'table':
              tableData = this.parseMarkdownTable(item);
              this.dataGrid.dataSource = tableData.data;
              this.dataGrid.columns = tableData.columns;
              break;
            case 'form':
              break;
            default :
              break;
          }

          this.content.push({ type, data: item, show: this.data.historyFl });

          type = 'markdown';
        });
        this.time = data.insert_date;
        this.loadingVisible = false;

        if (!this.data.historyFl) {
          for (const [index, item] of this.content.entries()) {
            if(index === 0) {
              this.$nextTick(() => {
                item.show = true;
                this.$emit('scrollBottom');
              });
            } else {
              await new Promise(resolve => setTimeout(() => {
                this.$nextTick(() => {
                  item.show = true;
                  this.$emit('scrollBottom');
                });
                resolve();
              }, 700));
            }

            if(item.type === 'table') {
              this.$nextTick(() => {
                this.$refs.dataGridContainer[0].getInstance.repaint();
                this.$emit('scrollBottom');
              });
            }
          }
        }

        this.$emit('loading', this.loadingVisible);
      },

      /** @description 엑셀 다운로드 클릭 이벤트 */
      onClickExcelDownload() {
        const gridInstance = this.$refs.dataGridContainer[0].getInstance;
        const title = `answer_${formatDate(this.time, 'YYYYMMDDHHmmssSSS').format('YYYYMMDDHHmmss')}`;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        const columnsArr = [];
        this.dataGrid.columns.forEach(() => {
          columnsArr.push({ width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30 });
        });
        worksheet.columns = columnsArr;

        exportDataGrid({
          component: gridInstance,
          worksheet: worksheet,
          keepColumnWidths: false,
          loadPanel: {
            enabled: true
          },
          autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            const headerRow = worksheet.getRow(4);
            headerRow.height = 30;
          })
          .then(() => {
            const fileName = `${title}.xlsx`;
            workbook.xlsx.writeBuffer().then(buffer => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
            });
            return fileName;
          });
      },

      /** @description Markdown Table 파싱 메서드  */
      parseMarkdownTable(markdown) {
        // 각 줄로 분리
        const lines = markdown.trim().split("\n");

        // 헤더와 데이터 분리
        const headers = lines[0].split("|").map(h => h.trim()).filter(Boolean); // 헤더 추출
        const rows = lines.slice(2).map(line => line.split("|").map(cell => cell.trim()).filter(Boolean)); // 데이터 추출

        // columns 생성
        const columns = headers.map(header => ({
          caption: header,
          dataField: header,
        }));

        // data 생성
        const data = rows.map(row => {
          const obj = {};
          headers.forEach((header, index) => {
            // 숫자는 숫자로 변환
            obj[header] = isNaN(row[index]) ? row[index] : Number(row[index]);
          });
          return obj;
        });

        return { columns, data };
      },

      /** @description 서식 삽입 */
      setForm(formData) {
        this.$emit('setFormData', formData);
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    async created() {
      if (!this.data.historyFl) {
        await this.queryExecute();
      } else {
        await this.setQuery(this.data);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .mar_ri11 {
    margin-right: 11px !important;
  }

  .pad_20 {
    padding: 0 20px;
  }

  .scroll-view-back {
    background-color: #FAFAFA !important;
  }

  .markdown-body {
    background-color: #d6e8fa !important;
  }

  .messages {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
  }

  .query {
    align-items: flex-end;
  }

  .query .message {
    color: white;
    background-color: #717171;
    position: relative;
  }

  .query .message:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: #717171;
    border-bottom-left-radius: 15px;
  }

  .query .message:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    border-bottom-left-radius: 10px;
    background-color: #FAFAFA;
  }

  .answer {
    align-items: flex-start;
  }

  .answer .message {
    background: #d6e8fa;
    position: relative;
  }

  .answer .message:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #d6e8fa;
    border-bottom-right-radius: 15px;
  }
  .answer .message:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    border-bottom-right-radius: 10px;
    background-color: #FAFAFA;
  }

  .typing-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
  }

  .typing-indicator span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
    animation: typing 1.5s infinite ease-in-out;
  }

  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes typing {
    0%, 80%, 100% {
      transform: scale(0);
      opacity: 0.3;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .fade-enter-active {
    transition: all 0.5s ease-out !important;
  }

  .fade-enter{
    transform: translateX(20px);
    opacity: 0;
  }

  ::v-deep {
    .fail-box {
      position: absolute;
      left: -20px;
      transform: translateY(-65%);
    }

    .dx-datagrid-header-panel {
      padding: 0;
    }

    .dx-datagrid-header-panel, .dx-toolbar-items-container {
      background-color: #FAFAFA;
    }

    #dxDataGrid {
      max-height: 300px;
    }
  }
</style>
