<!--
  PACKAGE_NAME : src/pages/euc/phone/request/items
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-05-24
  DESCRIPTION :
-->
<template>
  <div class="pl-6 pr-6 pb-6 h-full">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="2">
      <Tab
          v-for="(item, index) in typeList"
          :title="isEditMode ? item.typeNm : item.typeNm + ' ' + dataGrids[`dataGrid${item.id}`].dataSource.length || 0"
          :key="index"
      >
        <div v-show="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
          <esp-dx-data-grid
              v-if="dataGrids[`dataGrid${item.id}`].columns.length > 0"
              :ref="`dataGrid${item.id}Ref`"
              :data-grid="dataGrids[`dataGrid${item.id}`]"
              @init-new-row="e => onInitNewRow(e, item.id)"
              @saving="isWorkerMode && onSaving($event)"
          />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import {isSuccess} from '@/utils/common-lib';
import {DxButton} from 'devextreme-vue/button';
import {workerColumns} from '@/pages/euc/phone/request/type/default-table';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  props: {
    requestData: {
      type: Object,
      default: () => {
      },
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
    isWorkerMode: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    requestData: {
      handler() {
        this.requestItems = this.requestData?.requestItems || [];
      },
      deep: true,
    },
  },
  components: {
    EspDxDataGrid,
    Tabs,
    Tab,
  },
  data() {
    return {
      requestItems: [],
      requestTypeDetail: [],
      dataGrid: {
        refName: null,
        disableTotalCount: true,
        dataSource: [],
        apiActionNm: {},
        showActionButtons: {},
        customEvent: {
          saving: true,
          initNewRow: true,
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        paging: {
          enabled: false,
        },
        pager: {
          visible: false, //페이저 표시 여부
        },
        columns: [],
      },
      tabIndex: null,
      typeList: [],
      typeDetailList: [],
      dataGrids: {},
    };
  },
  methods: {
    /**
     * @description 작업자의 내용 저장 이벤트
     * */
    async onSaving(e) {
      if (e.changes && e.changes?.length) {
        const changeData = e.changes.map(item => {
          const returnData = {
            id: item.key.id,
          };
          if (item.data?.status) {
            returnData.status = item.data.status;
          }
          if (item.data?.completeComment) {
            returnData.completeComment = item.data.completeComment;
          }
          return returnData;
        });

        const payload = {
          actionName: 'EUC_PHONE_REQUEST_ITEMS_UPDATE',
          data: changeData,
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        } else {
          this.$_Msg(
              res.data?.header?.resMsg ?? this.$_lang('COMMON.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}),
              {icon: 'error'},
          );
        }
      }
    },
    onInitNewRow(e, typeId) {
      e.data.typeId = typeId;
    },
    getRequestItems() {
      let requestItems = [];
      for (const key in this.dataGrids) {
        const refName = this.dataGrids[key].refName;
        const hasEndDt = this.dataGrids[key].columns.some(column => column.columns.some(subColumn => subColumn.dataField === 'endDt'));
        if (this.$refs[refName]) {
          requestItems = requestItems.concat(
              this.$refs[refName][0].getItems.map(item => {
                if (hasEndDt && !item?.endDt) {
                  item.endDt = new Date(Date.UTC(9999, 11, 31, 23, 59, 59, 999));
                }
                return item;
              }),
          );
        }
      }
      return requestItems;
    },
    checkAllHasEditData() {
      let hasEditData = false;
      for (const key in this.dataGrids) {
        const refName = this.dataGrids[key].refName;
        if (this.$refs[refName]?.[0].hasEditData()) {
          hasEditData = true;
          break;
        }
      }
      return hasEditData;
    },
    async selectTypeDetailList() {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_DETAIL_SELECT',
        data: {
          viewFl: 'Y',
          sort: '+detailOrd',
        },
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.typeDetailList = res.data.data;
      }
    },
    tabSelectedIndex(index) {
      this.tabIndex = index;
    },
    async selectTypeDataList() {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_SELECT',
        data: {
          sort: '+typeOrd',
          viewFl: 'Y',
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.typeList = res.data.data;
        for (const type of this.typeList) {
          const dataGridName = `dataGrid${type.id}`;
          this.dataGrids[dataGridName] = JSON.parse(JSON.stringify(this.dataGrid));
          this.dataGrids[dataGridName].refName = `${dataGridName}Ref`;

          let columns = JSON.parse(type?.requestTypeTable?.columns) || [];
          if (type?.requestTypeTable?.viewFl === 'Y' && columns?.length) {
            columns.forEach(column => {
              if (column.key === 'CONTENT') {
                column.multiHeaderNm = type.typeNm;
                column.columns.unshift(
                    ...[
                      {
                        dataField: 'typeId',
                        visible: false,
                      },
                      {
                        caption: '상세 유형',
                        i18n: 'UC.WORD.DETAIL_TYPE',
                        dataField: 'detailId',
                        width: 120,
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: true,
                        sortOrder: 'none',
                        lookup: {
                          dataSource: this.typeDetailList.filter(detail => detail.typeId === type.id),
                          displayExpr: 'detailNm',
                          valueExpr: 'id',
                        },
                        requiredRule: {
                          message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE', {defaultValue: '필수값입니다.'}),
                        },
                      },
                      {
                        caption: '설명',
                        i18n: 'COMPONENTS.DESCRIPTION',
                        dataField: 'description',
                        width: 200,
                        height: 40,
                        alignment: 'center',
                        visible: true,
                        allowEditing: false,
                        sortOrder: 'none',
                        allowHeaderFiltering: false,
                        allowGrouping: false,
                        cellTemplate: async (container, options) => {
                          if (options?.data?.detailId) {
                            const description = this.typeDetailList.find(detail => detail.id === options.data.detailId)?.description;
                            container.append(description);
                            if (!options?.data?.workerUserNo) {
                              await this.setWorker(options.data);
                            }
                          }
                        },
                      },
                    ],
                );
              }
              // 등록 당시에만 노출 되는 유저 선택 버튼
              if (!this.isEditMode) {
                column.columns = column.columns.filter(subColumn => subColumn.dataField !== 'selectUser');
              }
              const selectUserColumn = column.columns.find(subColumn => subColumn.dataField === 'selectUser');
              if (selectUserColumn) {
                selectUserColumn.headerCellTemplate = container => {
                  const icon = document.createElement('i');
                  icon.className = 'dx-icon dx-icon-user';
                  container.append(icon);
                };
                selectUserColumn.cellTemplate = (container, options) => {
                  const button = new DxButton({
                    propsData: {
                      text: this.$_lang('COMPONENTS.SELECT', {defaultValue: '선택'}),
                      elementAttr: {class: 'btn_XS default filled add1 m-0'},
                      width: 60,
                      height: 30,
                      value: options.value,
                      disabled: !this.isEditMode,
                      onClick: () => {
                        options.onSelectedUser = user => {
                          const rowIndex = options.component.getRowIndexByKey(options.row.key);
                          options.component.cellValue(rowIndex, 'userNo', user.userNo);
                          options.component.cellValue(rowIndex, 'userNm', user.userNm);
                          options.component.cellValue(rowIndex, 'deptId', user.deptId);
                          options.component.cellValue(rowIndex, 'deptNm', user.deptNm);
                          options.component.cellValue(rowIndex, 'gradeId', user.gradeId);
                          options.component.cellValue(rowIndex, 'gradeNm', user.gradeNm);
                        };
                        options.title = this.$_lang('UC.WORD.USER_SELECT', {defaultValue: '사용자 선택'});
                        this.$emit('showUserModal', options);
                      },
                    },
                  });

                  button.$mount();
                  container.append(button.$el);
                };
              }

              const endDtColumn = column.columns.find(subColumn => subColumn.dataField === 'endDt');
              if (endDtColumn) {
                endDtColumn.cellTemplate = (container, options) => {
                  if (!options?.value || options.value?.getFullYear() >= 9999) {
                    container.innerText = this.$_lang('UC.WORD.INDEFINITE', {defaultValue: '무기한'});
                  } else {
                    if (options.value) {
                      const date = options.value;
                      const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
                          '0' + date.getDate()
                      ).slice(-2)}`;
                      container.innerText = formattedDate;
                    }
                  }
                };
              }
            });

            // Request Items 세팅
            if (this.requestItems.length) {
              // 작업자 판단 유무
              let isWorker = false;
              for (const item of this.requestItems) {
                // 해당 타입에 속한 데이터만 세팅
                if (item.typeId === type.id) {
                  this.dataGrids[dataGridName].dataSource.push(item);
                }

                // 로그인 한 사람이 작업자인지 판단
                isWorker = item.workerUserNo === this.$store.getters.getLoginId;
              }

              // 기존 입력된 데이터 작업자 외 수정 불가 상태로 만들고
              for (const item of columns) {
                item.columns.forEach(item => (item.allowEditing = isWorker));
              }

              if (this.requestData.approverFl === 'Y' && isWorker) {
                // 작업자라면 작업자 컬럼 추가
                this.dataGrids[dataGridName].editing.allowAdding = false;
                this.dataGrids[dataGridName].editing.allowUpdating = true;
                columns = columns.concat(workerColumns(this));
              } else {
                this.dataGrids[dataGridName].editing.allowAdding = isWorker && this.isEditMode;
                this.dataGrids[dataGridName].editing.allowUpdating = isWorker && this.isEditMode;
              }
            }

            this.dataGrids[dataGridName].columns = columns;
          }
        }
      }
    },
    async setWorker(data) {
      const {typeId, detailId} = data;

      const workerData = await this.fetchWorkerData(typeId, detailId);
      if (workerData) {
        const workerDetails = await this.fetchWorkerDetails(workerData.userNo);
        if (workerDetails) {
          data.workerUserNo = workerDetails.userNo;
          data.workerUserNm = workerDetails.userNm;
          data.workerDeptId = workerDetails.deptId;
          data.workerDeptNm = workerDetails.deptNm;
          data.workerGradeId = workerDetails.gradeId;
          data.workerGradeNm = workerDetails.gradeNm;
        }
      }
    },
    async fetchWorkerData(typeId, detailId) {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_WORKER_SELECT',
        data: {
          /**
           *  @description : 타입별 작업자 조회가 필요할 경우 주석 해제
           * */
          // typeId,
          // detailId,
          viewFl: 'Y',
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const data = res.data.data;
        const randomIndex = Math.floor(Math.random() * data.length);
        return data[randomIndex];
      }
    },
    async fetchWorkerDetails(userNo) {
      const payload = {
        actionName: 'USER_LIST_ALL_VIEW',
        data: {userNo},
        loading: false,
      };

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        return res.data.data[0];
      }
    },
  },
  async created() {
    this.requestItems = this.requestData?.requestItems || [];
    await this.selectTypeDetailList();
    await this.selectTypeDataList();
  },
};
</script>
<style scoped></style>
