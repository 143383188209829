<!--
  PACKAGE_NAME : src/pages/esp/monitoring/redis
  FILE_NAME : status-badge.vue
  AUTHOR : jhcho
  DATE : 25. 2. 12.
  DESCRIPTION :
-->
<template>
  <span class="mr-2 rounded-md px-2 py-1 text-sm font-medium ring-1 ring-inset" :class="`bg-${color}-50 text-${color}-700 ring-${color}-600/20`">
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'green'
    },
  },
}
</script>
<style scoped>

</style>