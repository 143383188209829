<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_infopush_hist"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="e => $_searchData(e, 'search_infopush_hist')"
      >
        <template v-slot:before>
          <div class="ui-datepicker-item">
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayStart"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>
          <div class="ui-datepicker-item">
            <DxDateBox
              :styling-mode="stylingMode"
              :width="120"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <DxSelectBox
            v-model="searchType.customTypes.siteId"
            placeholder="사이트 선택"
            :items="getSiteList"
            display-expr="siteNm"
            value-expr="siteId"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('siteId', $event)"
          />
          <DxSelectBox
            v-model="searchType.customTypes.resultCd"
            placeholder="결과 선택"
            :items="getResultList"
            display-expr="codeNm"
            value-expr="id"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('resultCd', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="infoPushHistoryGrid" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { isSuccess, getPastFromToday } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      DxDateBox,
      Search,
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getSiteList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        if (params?.siteId) {
          this.setCustomTypes('siteId', params.siteId);
        } else {
          this.setCustomTypes('siteId', null);
        }

        return [{ siteId: null, site: '전체', siteNm: '전체' }, ...this.infoPushSiteList];
      },
      getResultList() {
        return [{ codeId: null, codeNm: '전체' }, ...this.$_getCode('result')];
      },
    },
    data() {
      return {
        infoPushSiteList: [],
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
            regDt: null,
            siteId: null,
            resultCd: null,
          },
          paramsData: null,
        },
        dataGrid: {
          refName: 'infoPushHistoryGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          apiActionNm: {
            delete: 'INFOPUSH_HISTORY_DELETE',
          },
          customEvent: {},
          showActionButtons: {
            delete: true,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '내선번호',
              dataField: 'device',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '부서',
              dataField: 'deptTree',
              height: 40,
              alignment: 'left',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const value = options?.value ? options.value : options?.data?.deptNm;
                container.append(value || '');
              },
            },
            {
              caption: '휴대폰 번호',
              dataField: 'mobile',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'Event',
              dataField: 'event.codeValue',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: 'IN/OUT',
              dataField: 'inout.codeValue',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '유형',
              dataField: 'categoryCd',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const result = this.$_getCode('infopush_category').find(item => item.codeId === options.value);
                container.append(result?.codeNm || '');
              },
            },
            {
              caption: '결과',
              dataField: 'resultCd',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              cellTemplate: (container, options) => {
                const result = this.$_getCode('result').find(item => item.codeId === options.value);
                const span = document.createElement('span');

                if (options.value === 566) {
                  span.setAttribute('style', 'color: red');
                }
                span.append(result.codeNm);
                container.append(span);
              },
            },
            {
              caption: '원인',
              dataField: 'result.description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    methods: {
      onChangeSearchDay() {
        const dayStart = this.$_commonlib.moment(this.searchType.customTypes.dayStart);
        const dayEnd = this.$_commonlib.moment(this.searchType.customTypes.dayEnd);
        const regDt = dayStart.format('YYYYMMDD000000') + '~' + dayEnd.format('YYYYMMDD235959');
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'INFOPUSH_HISTORY_LIST_ALL',
              data: {
                params,
              },
              loading: true,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data?.data;
              rtnData.totalCount = res.data?.header?.totalCount;
            }
            return rtnData;
          },
        });
      },
      async getInfoPushSiteList() {
        const payload = {
          actionName: 'INFOPUSH_SITE_LIST_ALL',
          data: {
            params: {
              viewCd: 1,
            },
          },
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length) {
            this.infoPushSiteList = res.data.data;
          } else {
            this.infoPushSiteList = [];
          }
        }
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      mountedData() {
        this.selectDataList();
        vm = this;
      },
      createdData() {
        this.onChangeSearchDay();
        this.getInfoPushSiteList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountedData();
    },
  };
</script>
