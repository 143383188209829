<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">접수일자</div>
              <div>
                <DxDateBox
                  styling-mode="outlined"
                  width="120"
                  height="30"
                  v-model="searchType.customTypes.dayStart"
                  type="date"
                  dateSerializationFormat="yyyyMMdd"
                  display-format="yyyy-MM-dd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  :max="searchType.customTypes.dayEnd"
                  maxLength="10"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                >
                  <DxValidator>
                    <DxRequiredRule message="기준일자는 필수입니다." />
                  </DxValidator>
                </DxDateBox>
              </div>
              <div class="ui-datepicker period">
                <span class="dash">~</span>
              </div>
              <div>
                <DxDateBox
                  styling-mode="outlined"
                  width="120"
                  height="30"
                  v-model="searchType.customTypes.dayEnd"
                  type="date"
                  dateSerializationFormat="yyyyMMdd"
                  display-format="yyyy-MM-dd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  maxLength="10"
                  :min="searchType.customTypes.dayStart"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                >
                  <DxValidator>
                    <DxRequiredRule message="기준일자는 필수입니다." />
                  </DxValidator>
                </DxDateBox>
              </div>

              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="changeCancel" />
            </div>
          </DxValidationGroup>
        </div>
      </div>

      <div class="flex gap-x-6">
        <div class="w-3/5 relative">
          <esp-dx-data-grid :ref="dataGrid.refName" :data-grid="dataGrid" @row-click="onRowClick" @exporting="handleExporting"/>
        </div>
        <div class="w-2/5 pt-14">
          <div class="border-2">
            <DxValidationGroup ref="formValidationGroup">
              <table class="table_form line-bin">
                <colgroup>
                  <col style="width: 120px" />
                  <col style="width: auto" />
                </colgroup>
                <tbody>
                <tr>
                  <th scope="row">
                    <label for="label01">일자 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <DxDateBox
                      styling-mode="outlined"
                      :width="120"
                      type="date"
                      dateSerializationFormat="yyyy-MM-dd"
                      display-format="yyyy-MM-dd"
                      v-model="formData.receivedYmd"
                      :max="formData.processedYmd"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="일자는 필수입니다." />
                      </DxValidator>
                    </DxDateBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">고객명 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <DxTextBox
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="40%"
                      v-model="formData.customerNm"
                      :max-length="limitNumberTexts.maxLengths.complaintHeader"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'complaintHeader')"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="고객명은 필수입니다." />
                      </DxValidator>
                    </DxTextBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">접수번호 </label>
                  </th>
                  <td>
                    <!-- :max-length="limitNumberTexts.maxLengths.complaintHeader" -->
                    <DxTextBox
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="40%"
                      v-model="formData.caseNo"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'complaintHeader')"
                      :disabled="updateYn"
                    >
                    </DxTextBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">유형 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <DxSelectBox
                      :input-attr="{ 'aria-label': '적용부서' }"
                      :styling-mode="config.stylingMode"
                      :items="codes.complaintTypeCd.dataSource"
                      display-expr="codeNm"
                      value-expr="codeId"
                      placeholder="유형 선택"
                      width="40%"
                      class="mr-4"
                      v-model="formData.complaintTypeCd"
                      :disabled="updateYn"
                      >
                      <DxValidator>
                        <DxRequiredRule message="유형은 필수입니다." />
                      </DxValidator>
                    </DxSelectBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">상담사 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <div class="flex">
                      <DxTextBox
                        :styling-mode="config.blockStylingMode"
                        class="mr-4"
                        width="40%"
                        v-model="formData.receiverAgtid"
                        :read-only="true"
                        :disabled="updateYn"
                      >
                        <DxValidator>
                          <DxRequiredRule message="상담사는 필수입니다." />
                        </DxValidator>
                      </DxTextBox>
                      <DxTextBox
                        :styling-mode="config.blockStylingMode"
                        class="mr-4 items-center"
                        width="40%"
                        v-model="formData.receiverAgtNm"
                        :read-only="true"
                        :disabled="updateYn"
                      >
                        <DxValidator>
                          <DxRequiredRule message="상담사는 필수입니다." />
                        </DxValidator>
                      </DxTextBox>
                      <DxButton icon="search" :height="30" :disabled="updateYn" @click="onOpen('receivedAgt')" />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">민원요지 <span class="icon_require">필수항목</span></label>
                  </th>
                  <td>
                    <DxTextBox
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      v-model="formData.complaintSmry"
                      :max-length="limitNumberTexts.maxLengths.complaintHeader"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'complaintHeader')"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="민원요지는 필수입니다." />
                      </DxValidator>
                    </DxTextBox>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">고객불만내용 <span class="icon_require">필수항목</span></label>
                    <br />{{ formData.complaintDetail ? formData.complaintDetail.length : 0 }} /
                    {{ limitNumberTexts.maxLengths.complaintDetail }}자
                  </th>
                  <td>
                    <DxTextArea
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      :height="120"
                      v-model="formData.complaintDetail"
                      :max-length="limitNumberTexts.maxLengths.complaintDetail"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'complaintDetail')"
                      :disabled="updateYn"
                    >
                      <DxValidator>
                        <DxRequiredRule message="고객불만내용은 필수입니다." />
                      </DxValidator>
                    </DxTextArea>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">처리일 </label>
                  </th>
                  <td>
                    <DxDateBox
                      styling-mode="outlined"
                      :width="120"
                      type="date"
                      dateSerializationFormat="yyyy-MM-dd"
                      display-format="yyyy-MM-dd"
                      dateOutOfRangeMessage="처리일은 일자보다 크거나 같아야 합니다."
                      :min="formData.receivedYmd"
                      v-model="formData.processedYmd"
                      :disabled="updateYn"
                    >
                    </DxDateBox>
                              
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label01">처리결과</label>
                    <br />{{ formData.processingResult ? formData.processingResult.length : 0 }} /
                    {{ limitNumberTexts.maxLengths.processingResult }}자
                  </th>
                  <td>
                    <DxTextArea
                      :styling-mode="config.stylingMode"
                      class="mr-4"
                      :show-clear-button="true"
                      width="100%"
                      :height="120"
                      v-model="formData.processingResult"
                      :max-length="limitNumberTexts.maxLengths.processingResult"
                      @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'processingResult')"
                      :disabled="updateYn"
                    >
                    </DxTextArea>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label5">처리자</label>
                  </th>
                  <td>
                    <div class="space-y-6">
                      <div class="space-x-6 flex items-center">
                        <span class="w-10">1차 </span>
                          <td class="w-full">
                            <div class="flex">
                              <DxTextBox
                                :styling-mode="config.blockStylingMode"
                                class="mr-4"
                                width="50%"
                                v-model="formData.firstHandlerAgtid"
                                :read-only="true"
                                :disabled="updateYn"
                              >
                              </DxTextBox>
                              <DxTextBox
                                :styling-mode="config.blockStylingMode"
                                class="mr-4 items-center"
                                width="50%"
                                v-model="formData.firstHandlerAgtNm"
                                :read-only="true"
                                :disabled="updateYn"
                              >
                              </DxTextBox>
                              <DxButton icon="search" :height="30" :disabled="updateYn" @click="onOpen('firstHandlerAgt')" />
                              <DxButton icon="close" :height="30" :disabled="updateYn" @click="onDeleteAgt('firstHandlerAgt')" />
                            </div>
                          </td>
                      </div>
                      <div class="space-x-6 flex items-center">
                        <span class="w-10">최종</span>
                          <td class="w-full m-0">
                            <div class="flex">
                              <DxTextBox
                                :styling-mode="config.blockStylingMode"
                                class="mr-4"
                                width="50%"
                                v-model="formData.finalHandlerAgtid"
                                :read-only="true"
                                :disabled="updateYn"
                              >
                              </DxTextBox>
                              <DxTextBox
                                :styling-mode="config.blockStylingMode"
                                class="mr-4 items-center"
                                width="50%"
                                v-model="formData.finalHandlerAgtNm"
                                :read-only="true"
                                :disabled="updateYn"
                              >
                              </DxTextBox>
                              <DxButton icon="search" :height="30" :disabled="updateYn" @click="onOpen('finalHandlerAgt')" />
                              <DxButton icon="close" :height="30" :disabled="updateYn" @click="onDeleteAgt('finalHandlerAgt')" />
                            </div>
                          </td>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </DxValidationGroup>
          </div>
        </div>
      </div>
      <!-- Agent Modal -->
      <modal-add-agent
        :isOpen="modal.agent.popupVisible"
        :showModalTitle="true"
        :selectedIdList="modal.agent.selectedIdList"
        :minimumSelectionLength="modal.agent.minimumSelectionLength"
        :maximumSelectionLength="modal.agent.maximumSelectionLength"
        saveBtnTxt="선택"
        @closeModal="onClose(false)"
        @saveModal="onSave"
      />
      <!-- Agent Modal -->
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import {cloneObj, formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import { exportDataGrid } from 'devextreme/excel_exporter';
  import ExcelJS from 'exceljs';
  import dxValidator from 'devextreme/ui/validator';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxTextBox,
      DxSelectBox,
      DxTextArea,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      ModalAddAgent,
    },
    data() {
      return {
        updateYn: true,
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled
          blockStylingMode: 'filled', //outlined, underlined, filled
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        modal: {
          isOpened: false,
          initData: {},
          contentData: null,
          componentName: '',
          agent: {
            popupVisible: false,
            selectedIdList: [],
            targetSelectedIdList: [],
            minimumSelectionLength: 0,
            maximumSelectionLength: 1,
            target: null,
          },
        },
        valid: {
          minDate: '설정하세요'
        },
        codes: {
          complaintTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            complaintDetail: 1000,
            processingResult: 1000,
          },
        },
        dataGrid: {
          keyExpr: 'complaintId',
          refName: 'complaintGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            rowClick: true, // 행 클릭 이벤트
            exporting: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick: this.onAdd,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                disabled: this.updateYn,
                options: {
                  icon: '',
                  text: '저장',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick: this.onSaving,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                disabled: this.updateYn,
                options: {
                  icon: '',
                  text: '취소',
                  elementAttr: { class: 'btn_XS white light_filled' },
                  width: 60,
                  height: 30,
                  onClick: this.changeCancel,
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: this.onDeleteData,
                },
                location: 'before',
              },
            ],
            excel: {
              elementAttr: { class: 'flex' },
            },
          },
          excel: {
            title: '고객불만처리대장',
          },
          exportButtonText: {
            //엑셀 다운로드 정보
            exportTo: '엑셀다운로드',
            exportAll: '전체 다운로드',
            exportSelectedRows: '선택한 데이터 다운로드',
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '일자',
              dataField: 'receivedYmd',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
            },
            {
              caption: '고객명',
              dataField: 'customerNm',
              alignment: 'center',
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              visible: true,
            },
            {
              caption: '접수번호',
              dataField: 'caseNo',
              alignment: 'left',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '유형',
              dataField: 'complaintTypeCd',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateSortValue: data => this.codes.complaintTypeCd.dataSource.find(e => data.complaintTypeCd === e.codeId).codeNm,
            },
            {
              caption: '민원요지',
              dataField: 'complaintSmry',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
            },
            {
              caption: '고객불만내용',
              dataField: 'complaintDetail',
              alignment: 'center',
              visible: false,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowExporting: true,
            },
            {
              caption: '상담사',
              dataField: 'receiverAgtid',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              calculateCellValue: rowData => {
                return `${rowData.receiverAgtNm} [${rowData.receiverAgtid}]`;
              },
            },
            {
              caption: '처리일',
              dataField: 'processedYmd',
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
            },
            {
              caption: '처리결과',
              dataField: 'processingResult',
              alignment: 'center',
              visible: false,
              allowSorting: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
            },
            {
              multiHeaderNm:'처리자',
              columns: [
                {
                  caption: '1차',
                  dataField: 'firstHandlerAgtid',
                  alignment: 'center',
                  visible: true,
                  allowSorting: true,
                  allowEditing: false,
                  allowFiltering: true,
                  allowHeaderFiltering: true,
                  calculateCellValue: rowData => {
                    if(rowData.firstHandlerAgtid) {
                      return `${rowData.firstHandlerAgtNm} [${rowData.firstHandlerAgtid}]`;
                    } 
                  },
                },
                {
                  caption: '최종',
                  dataField: 'finalHandlerAgtid',
                  alignment: 'center',
                  visible: true,
                  allowSorting: true,
                  allowEditing: false,
                  allowFiltering: true,
                  allowHeaderFiltering: true,
                  calculateCellValue: rowData => {
                    if(rowData.finalHandlerAgtid) {
                      return `${rowData.finalHandlerAgtNm} [${rowData.finalHandlerAgtid}]`;
                    }
                  },
                },
              ],
            },
          ],
        },
        formDataInit: {
          receivedYmd: null,
          customerNm: null,
          receiverAgtid: null,
          receiverAgtNm: null,
          caseNo: null,
          complaintTypeCd: null,
          complaintSmry: null,
          complaintDetail: null,
          processedYmd: null,
          processingResult: null,
          firstHandlerAgtid: null,
          firstHandlerAgtNm: null,
          finalHandlerAgtid: null,
          finalHandlerAgtNm: null,
        },
        formData: {},
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
      formValidationGroup: function () {
        return this.$refs['formValidationGroup'].instance;
      },
    },
    methods: {
      /** @description : 추가 메서드 */
      onAdd() {
        this.updateYn = false;
        this.formData = cloneObj(this.formDataInit);
        this.modal.agent.targetSelectedIdList = [];
      },
      onRowClick(e) {
        this.$_dxUtil.setGridSingleSelection(e);

        this.updateYn = false;
        this.formData = e.data;
        this.modal.agent.targetSelectedIdList = [e.data.agtid];
      },
      /** @description : 저장 메서드 */
      async onSaving(e) {
        if (!vm.formValidationGroup.validate().isValid) {
          return false;
        }

        if(this.formData.processingResult !== null && this.formData.processedYmd === null) {
          this.$_Msg('처리일을 선택해주세요.');
          return;
        }

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        const payload = {
          actionName: 'EWM_PESRSONAL_COMPLAINT_SAVE',
          data: { items: [this.formData] },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.changeCancel();
          console.log('res.data', res.data);
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 취소 메서드 */
      changeCancel() {
        vm.formValidationGroup.reset();
        this.updateYn = true;
        this.formData = cloneObj(this.formDataInit);
        this.modal.agent.targetSelectedIdList = [];
        this.selectDataList();
      },
      /** @description : 삭제 메서드 */
      async onDeleteData() {
        const selectedRowsData = this.$refs.complaintGrid.getInstance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const items = selectedRowsData.map(x => ({ complaintId: x.complaintId }));

        const payload = {
          actionName: 'EWM_PESRSONAL_COMPLAINT_DELETE',
          data: { items: items },
          loading: true,
        };
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.changeCancel();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 상담사 오픈 메서드 */
      onOpen(btnId) {
        this.modal.agent.popupVisible = true;
        this.modal.agent.maximumSelectionLength = 1;

        if(btnId === 'receivedAgt') {
          this.modal.agent.target = 'receiver';
          this.modal.agent.selectedIdList = this.formData.receiverAgtid;
        } else if(btnId === 'firstHandlerAgt') {
          this.modal.agent.target = 'firstHandler';
          this.modal.agent.selectedIdList = this.formData.firstHandlerAgtid;
        } else if(btnId === 'finalHandlerAgt') {
          this.modal.agent.target = 'finalHandler';
          this.modal.agent.selectedIdList = this.formData.finalHandlerAgtid;
        }
      },
      updateVisibleTrue(e) {
        const columns = e.component.option("columns").map(col => ({
          ...col,
          visible: true, 
        }));
        
        e.component.option("columns", columns);
      },
      updateVisibleFalse(e) {
        const originalColumns = e.component.option("columns").map(column => {
          // 특정 컬럼만 visible을 false로 설정
          if (column.dataField === "processingResult" || column.dataField === "complaintDetail") {
            return { ...column, visible: false };
          }
          return column;  // 나머지 컬럼은 그대로 유지
        });
                
        e.component.option("columns", originalColumns);
      },
      /** @description: 엑셀 다운로드 이벤트 */
      handleExporting(e) {
        this.updateVisibleTrue(e);

        //Grid Excel Export
        const title = this.dataGrid.excel.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        let columnsArr = [];

        
        this.dataGrid.columns.forEach((column) => {
          if(column.dataField === 'complaintDetail' || column.dataField === 'processingResult') {
            columnsArr.push({ width: 30 });
          } else {
            columnsArr.push({ width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 20 });
          }
          //다중 헤더 구조 속 컬럼 너비
          if (column.columns) {
              columnsArr.push({ width: 20 });
          } 
        });
        worksheet.columns = columnsArr;

        let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else if (gridCell.rowType === 'data') {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
              // 자동 줄바꿈 적용
              if (gridCell.column.dataField === "complaintDetail" || gridCell.column.dataField === "processingResult") {
                excelCell.alignment = { 
                  wrapText: true, 
                  vertical: 'top', 
                };
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            if (e.format === 'xlsx') {
              worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
            }
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(() => {
            let fileName;
            if (e.format === 'csv') {
              fileName = `${title}_${today}.csv`;
              workbook.csv.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
              });
            } else {
              fileName = `${title}_${today}.xlsx`;
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
              });
            }
            return fileName;
          })
          .then(fileName => {
            // 다운로드 이력 저장
            e.onSaveHistory(fileName);
            this.updateVisibleFalse(e);
          });
        e.cancel = true;
      },
      /** @description : 상담사 닫기 */
      onClose() {
        this.modal.agent.popupVisible = false;
        this.modal.agent.selectedIdList = [];
      },
      /** @description : 상담사 저장 */
      onSave(value) {
        this.modal.agent.targetSelectedIdList = [value[0].agtid];

        if(this.modal.agent.target === 'receiver') {
          this.formData.receiverAgtid = value[0].agtid;
          this.formData.receiverAgtNm = value[0].agtNm;
        } else if(this.modal.agent.target === 'firstHandler') {
          this.formData.firstHandlerAgtid = value[0].agtid;
          this.formData.firstHandlerAgtNm = value[0].agtNm;
        } else if(this.modal.agent.target === 'finalHandler') {
          this.formData.finalHandlerAgtid = value[0].agtid;
          this.formData.finalHandlerAgtNm = value[0].agtNm;
        }
        
        this.onClose();
      },
      /** @description: 상담사 선택 초기화 */
      onDeleteAgt(target) {
        if(target === 'firstHandlerAgt') {
          this.formData.firstHandlerAgtid = null;
          this.formData.firstHandlerAgtNm = null;
        } else if(target === 'finalHandlerAgt') {
          this.formData.finalHandlerAgtid = null;
          this.formData.finalHandlerAgtNm = null;
        }
      },
      
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        let startDt = formatDate(vm.searchType.customTypes.dayStart, 'YYYYMMDD', 'YYYY-MM-DD');
        let endDt = formatDate(vm.searchType.customTypes.dayEnd, 'YYYYMMDD', 'YYYY-MM-DD');

        const payload = {
          actionName: 'EWM_PESRSONAL_COMPLAINT_SEARCH',
          data: {
            startDt: startDt,
            endDt: endDt,
          },
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
        }
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_etc_complaint_type');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.formData = cloneObj(this.formDataInit);

        this.initCodeMap().then(() => {
          this.codes.complaintTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_etc_complaint_type'], 2);
          this.$refs.complaintGrid.getInstance.columnOption('complaintTypeCd', 'lookup', this.codes.complaintTypeCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.$nextTick(() => {
        if(this.$refs.EspDxDataGrid)
          this.$refs.EspDxDataGrid.onExporting = this.onExporting;
      });
      this.mountData();
    },
  };
</script>
