import ApprovalIntegrationIndex from '@/pages/ewm/approval/integration/index.vue';
import ApprovalProcessIndex from '@/pages/ewm/approval/process/index.vue';

export default function ApprovalRoutes(commonComponents) {
  return [
    {
      path: '/ewm/approval/integration/index',
      name: 'ApprovalIntegrationIndex',
      components: { default: ApprovalIntegrationIndex, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/approval/process/index',
      name: 'ApprovalProcessIndex',
      components: { default: ApprovalProcessIndex, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
