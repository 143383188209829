import Scheduling from '@/pages/euc/scheduling/task';
import SchedulingHistory from '@/pages/euc/scheduling/task/history';

export default function SchedulingRoutes(commonComponents) {
    return [
        {
            path: '/euc/scheduling/task',
            name: 'Scheduling',
            components: { default: Scheduling, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/scheduling/task/history',
            name: 'SchedulingHistory',
            components: { default: SchedulingHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}