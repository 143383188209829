<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" ref="groupGrid" @init-new-row="onInitNewRow"></esp-dx-data-grid>
  </div>
</template>
<script>
import {DxSelectBox} from 'devextreme-vue/select-box';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
import commonEnum from "@/configs/enums/common";

export default {
  components: {
    EspDxDataGrid
  },
  watch: {},
  data() {
    return {
      maxGroupOrd: 0,
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: '그룹정보'
        },
        refName: 'groupGrid',
        dataSourceDefaultSortColumn: '+groupOrd,-regDt',
        apiActionNm: {
          select: 'EUC_DEVICE_GROUP_SELECT',
          update: 'EUC_DEVICE_GROUP_UPDATE',
          delete: 'EUC_DEVICE_GROUP_DELETE',
        },
        columns: [
          {
            caption: '그룹명',
            dataField: 'groupNm',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '코드값',
            dataField: 'groupCd',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '라벨',
            dataField: 'label',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: 'DDD',
            dataField: 'ddd',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '설명',
            dataField: 'description',
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '패턴',
            dataField: 'pattern',
            customRule: {
              message: '패턴 형식을 확인해주세요.',
              callback: (e) => {
                return this.checkRegExp(e.value);
              }
            },
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: 'Regex',
            dataField: 'regex',
            customRule: {
              message: 'Regex 형식을 확인해주세요.',
              callback: (e) => {
                return this.checkRegExp(e.value);
              }
            },
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '내선자리 수',
            dataField: 'extlen',
          },
          {
            caption: 'IN/OUT',
            dataField: 'inout',
          },
          {
            caption: '유형',
            dataField: 'inoutTypeCd',
            allowEditing: false,
            editorType: 'dxSelectBox',
            cellTemplate: (container, options) => {
              const selectBox = new DxSelectBox({
                propsData: {
                  placeholder: '선택',
                  items: this.$_getCode('inout_type'),
                  displayExpr: 'codeNm',
                  valueExpr: 'id',
                  value: options.value,
                  stylingMode: this.stylingMode,
                  onValueChanged: (e) => {
                    this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                  },
                },
              });
              selectBox.$mount();
              container.append(selectBox.$el);
            },
          },
          {
            caption: '사이트',
            dataField: 'siteId',
            allowEditing: false,
            editorType: 'dxSelectBox',
            cellTemplate: (container, options) => {
              const selectBox = new DxSelectBox({
                propsData: {
                  placeholder: '선택',
                  items: this.$store.getters.getSiteList,
                  displayExpr: 'siteNm',
                  valueExpr: 'id',
                  value: options.value,
                  stylingMode: this.stylingMode,
                  onValueChanged: (e) => {
                    this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    const tenant = this.getTenantList(e.value)[0];
                    const newValue = tenant?.id || null;
                    this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex + 1, newValue);
                  },
                },
              });
              selectBox.$mount();
              container.append(selectBox.$el);
            },
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: 'Tenant',
            dataField: 'tenantId',
            allowEditing: false,
            editorType: 'dxSelectBox',
            cellTemplate: (container, options) => {
              const stateTypeList = this.getTenantList(options.data.siteId);
              const selectBox = new DxSelectBox({
                propsData: {
                  placeholder: '선택',
                  items: stateTypeList,
                  displayExpr: 'tenantNm',
                  valueExpr: 'id',
                  value: options.value,
                  stylingMode: this.stylingMode,
                  onValueChanged: (e) => {
                    this.$refs.groupGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                  },
                },
              });
              selectBox.$mount();
              container.append(selectBox.$el);
            },
            requiredRule: {
              message: '필수 항목입니다.'
            },
          },
          {
            caption: '순서',
            dataField: 'groupOrd',
          },
          {
            caption: '사용 여부',
            dataField: 'viewFl',
            lookup: {
              dataSource: commonEnum.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
          {
            caption: '등록일',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: this.formatDt,
          },
        ],
      },
    };
  },
  methods: {
    checkRegExp(str) {
      try {
        new RegExp(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    /** @description : Site의 id로 해당하는 tenant 목록 가져오는 메서드 */
    getTenantList(siteId) {
      return this.$store.getters.getTenantList.filter((tenant) => tenant.siteId === siteId)
    },
    /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
    onInitNewRow(e) {
      e.data.viewFl = 'Y';
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.regDt) {
        return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
};
</script>