import HerSetting from '@/pages/euc/her/setting.vue';
import HerHistory from '@/pages/euc/her/history.vue';

export default function HerRoutes(commonComponents) {
    return [
        {
            path: '/euc/her/setting',
            name: 'HerSetting',
            components: { default: HerSetting, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/her/history',
            name: 'HerHistory',
            components: { default: HerHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}