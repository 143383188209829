<template>
  <div id="dashboard">
    <!-- 대시보드 버튼 영역 start -->
    <div class="dashboard-button" style="width: 100%">
      <div class="button-container">
        <div id="container" class="floating-container bottom-line-down">
          <!-- 편집모드 시 대시보드 플로팅 버튼 start -->
          <div id="editStart" class="edit-start-container" @click="editStart">
            <div>
              <div class="floating-button edit" v-if="button.startEditMode.isView" @click="startEditMode">
                <img src="@/assets/images/report/dashboard_edit.png" class="w-2/5" alt="편집모드 시작" />
              </div>
            </div>
          </div>
          <!-- 편집모드 시 대시보드 플로팅 버튼 end -->
          <!-- 편집모드 아닐 시 대시보드 플로팅 버튼 start -->
          <div class="edit-end-container">
            <div id="editEnd">
              <div class="button-wrapper">
                <div class="floating-button" v-if="button.openWidgetAddTab.isView" @click="openWidgetAddTab">
                  <img src="@/assets/images/report/dashboard_add.png" class="w-2/5" />
                </div>
                <div class="floating-button" v-if="button.saveWidgetsOfEditMode.isView" @click="saveWidgetsOfEditMode">
                  <img src="@/assets/images/report/dashboard_save.png" class="w-2/5" />
                </div>
                <div class="floating-button cancel" v-if="button.cancelEditMode.isView" @click="cancelEditMode">
                  <img src="@/assets/images/report/dashboard_cancel.png" class="w-2/5" />
                </div>
              </div>
            </div>
          </div>
          <!-- 편집모드 아닐 시 대시보드 플로팅 버튼 end-->
        </div>
      </div>
    </div>
    <!-- 대시보드 버튼 영역 end -->
    <!-- 위젯 추가 및 드랍 영역 start -->
    <div class="bg-white">
      <!-- 위젯 추가 탭 start -->
      <div class="dashboard-tab">
        <!-- 헤더 start -->
        <div class="tab-header clearfix">
          <div class="header-left fl font-medium text-lg">위젯추가</div>
          <div class="header-right fr">
            <button class="btn_XS white close" type="button" @click="closeWidgetAddTab">닫기</button>
          </div>
        </div>
        <!-- 헤더 end -->
        <!-- 탭 컴포넌트 start -->
        <tabs :tabType="2">
          <tab title="차트">
            <div class="tab-description">
              <div class="text-center">원하는 보고서 위젯을 화면에 드래그 하세요.</div>
              <div style="overflow-y: auto; height: calc(100vh - 284px)">
                <ul id="widget-template" v-if="getWidgetTemplate.length > 0">
                  <template v-for="(item, index) in getWidgetTemplate[0].items">
                    <DraggableWidget :item="item" :key="index" @drag="drag" @dragend="dragend" />
                  </template>
                </ul>
              </div>
            </div>
          </tab>
          <tab title="테이블">
            <div class="tab-description">
              <div class="text-center">원하는 보고서 위젯을 화면에 드래그 하세요.</div>
              <div style="overflow-y: auto; height: calc(100vh - 284px)">
                <ul id="widget-template" v-if="getWidgetTemplate.length > 0">
                  <template v-for="(item, index) in getWidgetTemplate[1].items">
                    <DraggableWidget :item="item" :key="index" @drag="drag" @dragend="dragend" />
                  </template>
                </ul>
              </div>
            </div>
          </tab>
        </tabs>
        <!-- 탭 컴포넌트 end -->
      </div>
      <!-- 위젯 추가 탭 end -->
      <!-- 위젯 드랍 영역 start -->
      <div ref="dragArea">
        <drop @drop="onWidgetDrop" style="min-height: 500px"
          ><!-- min-height 지정 안할 시 위젯 drop 불가 -->
          <div id="content">
            <grid-layout
              ref="gridlayout"
              :layout.sync="gridLayout"
              :col-num="colNum"
              :row-height="10"
              :is-draggable="draggable"
              :is-resizable="resizable"
              :vertical-compact="true"
              :use-css-transforms="true"
            >
              <grid-item
                v-for="item in gridLayout"
                :static="item.static"
                :x="item.x"
                :y="item.y"
                :w="item.w"
                :h="item.h"
                :i="item.i"
                :key="item.i"
                :minW="19"
                :minH="8"
              >
                <Widget
                  :mode="mode"
                  :ref="`widget${item.i}`"
                  :widget="item.obj"
                  :data="table[item.obj.tableName]"
                  :key="item.i"
                  @initDashboard="initDashboard"
                  @onRemoveWidgetLayout="onRemoveWidgetLayout"
                />
              </grid-item>
            </grid-layout>
          </div>
        </drop>
      </div>
      <!-- 위젯 드랍 영역 end -->
    </div>
    <!-- 위젯 추가 및 드랍 영역 end -->
  </div>
</template>
<script>
  import { GridLayout, GridItem } from 'vue-grid-layout';
  import Query from 'devextreme/data/query';
  import { Drag, Drop } from 'vue-drag-drop';
  import Widget from '@/components/report/dashboard/widget.vue';
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import { getResData, isSuccess } from '@/utils/common-lib';
  import DraggableWidget from '@/components/report/dashboard/draggable-widget.vue';
  import SockJS from 'sockjs-client';
  import Stomp from 'webstomp-client';
  import { gsap, Power0 } from 'gsap';
  //위젯 추가 탭에서 위젯 드랍시 위젯을 드랍할 전역 좌표 전역 변수
  let mouseXY = { x: null, y: null };
  let DragPos = { x: null, y: null, w: 1, h: 1, i: null };
  export default {
    name: 'Dashboard',
    components: {
      GridLayout,
      GridItem,
      Drop,
      Drag,
      Widget,
      Tabs,
      Tab,
      DraggableWidget,
    },
    data() {
      return {
        cron: null,
        item: null,
        widgetProperty: null,
        subscription: null,
        wsConnected: false,
        wsReconnectFunc: null,
        wsReconnectFlag: true,
        duration: {
          fixeDuration: '1128',
          cumulativeDuration: '1129',
          recent: '1130',
        },
        templateId: 0,
        layoutStatic: false,
        gridLayout: [],
        draggable: false,
        resizable: false,
        colNum: 100,
        index: 0,
        widgetDropIdx: 0,
        templateWidgets: [],
        savedWidgets: [],
        rotated: false,
        updatedWidgetId: null,
        widgetTemplates: [],
        deletingWidgets: [],
        mode: '',
        widget: {
          cellSize: {
            w: 10, //widget.layout에서 width에 대한 pixel값
            h: 10, //widget.layout에서 height에 대한 pixel값
          },
          maxRowCount: Infinity,
          bubbleUp: true,
          margin: 15,
          outerMargin: 0,
          layout: [],
          fixLayoutOnLoad: true,
        },
        table: {
          // H_AGT_IBG_D: [],
          // H_AGT_IBG_H: [],
          H_IBG_D: [],
          H_IBG_H: [],
          H_AGT_D: [],
          H_PIE_D: [],
        },
        button: {
          startEditMode: {
            isView: true,
          },
          saveWidgetsOfEditMode: {
            isView: false,
          },
          cancelEditMode: {
            isView: false,
          },
          openWidgetAddTab: {
            isView: false,
          },
        },
        tab: {
          selectedIndex: 0,
        },
        widgetTypes: [],
        // 스코어 카드 차트일 경우 설정 팝업에서 조회 기간 유형이 아닌 비교 대상이 나온다.
        // 비교 대상
        subject: {
          yesterday: '1251',
        },
        // 날짜/시간 형식
        dateFormat: {
          daily: 210,
          hour: 213,
        },
        // 드랍영역의 resize 여부
        resizeChecker: null,
      };
    },
    computed: {
      getWidgetTemplate() {
        let widgetTemplates = Query(this.widgetTemplates)
          .groupBy('widgetShapeTypeFl')
          .toArray()
          .sort((a, b) => {
            if (a.key > b.key) return 1;
            if (a.key < b.key) return -1;
            return 0;
          });
        let result = widgetTemplates.map(element => {
          element.items = element.items.map(item => {
            item.chartTypeCode = this.$_getCode('search_type_widget').find(i => i.codeId === item.chartTypeCd);
            return item;
          });
          return element;
        });
        return result;
      },
    },
    methods: {
      /** @description : 오늘 기준의 데이터 조회 시작 날짜 설정*/
      setStartDt(date, subjectType) {
        let year = date.getFullYear();
        let month = ('0' + (date.getMonth() + 1)).slice(-2);
        let day = ('0' + date.getDate()).slice(-2);
        const id = subjectType;

        let startDt = '';
        if (id === this.subject.sameDateLastYear) {
          //이전년도 같은 일자
          startDt = year - 1 + month + day; //
        } else if (id === this.subject.sameDateLastWeek) {
          //이전 주 같은 요일
          date.setDate(date.getDate() - 7);

          year = date.getFullYear();
          month = ('0' + (date.getMonth() + 1)).slice(-2);
          day = ('0' + date.getDate()).slice(-2);

          startDt = year + month + day;
        } else if (id === this.subject.sameDateLastMonth) {
          // 이전 월의 같은 일자
          date.setMonth(date.getMonth() - 1);

          year = date.getFullYear();
          month = ('0' + (date.getMonth() + 1)).slice(-2);
          day = ('0' + date.getDate()).slice(-2);

          startDt = year + month + day;
        } else if (id === this.subject.yesterday) {
          //어제
          date.setDate(date.getDate() - 1);

          year = date.getFullYear();
          month = ('0' + (date.getMonth() + 1)).slice(-2);
          day = ('0' + date.getDate()).slice(-2);

          startDt = year + month + day;
        }

        return startDt;
      },
      /** @description : 텍스트 길이 계산*/
      textLength(str) {
        let len = 0;
        for (var i = 0; i < str.length; i++) {
          if (escape(str.charAt(i)).length == 6) {
            len++;
          }
          len++;
        }
        return len;
      },
      /** @description : 위젯 추가 탭 열기*/
      openWidgetAddTab() {
        const el = document.querySelector('.dashboard-tab');
        gsap.to(el, { opacity: 1, x: -400, ease: Power0.easeNone });
        this.saveWidgetTemplates();
      },
      /** @description : 위젯 추가 탭 닫기*/
      closeWidgetAddTab() {
        const el = document.querySelector('.dashboard-tab');
        gsap.to(el, { opacity: 1, x: 400, ease: Power0.easeNone });
      },
      /** @description : 위젯 템플릿을 조회 후 전역 변수에 저장*/
      async saveWidgetTemplates() {
        let params = {
          sort: '+id',
          viewCd: 1,
        };
        let payload = {
          actionName: 'WIDGETTP_LIST_ALL',
          data: params,
        };
        let res = await this.CALL_REPORT_API(payload);
        if (res.data.header.resCode === 'success') this.widgetTemplates = res.data.data;
      },
      /** @description : 편집모드 시작*/
      startEditMode() {
        let tempLayout = this.gridLayout;
        this.gridLayout = [];

        this.gridLayout = tempLayout.filter(e => {
          e.static = false;
          return e;
        });

        this.draggable = true;
        this.resizable = true;
        this.button.startEditMode.isView = false;
        this.button.saveWidgetsOfEditMode.isView = true;
        this.button.cancelEditMode.isView = true;
        this.button.openWidgetAddTab.isView = true;
        this.mode = 'edit';
      },
      /** @description : 편집모드 시 편집모드 취소*/
      cancelEditMode() {
        this.editEnd();
        this.button.startEditMode.isView = true;
        this.button.saveWidgetsOfEditMode.isView = false;
        this.button.cancelEditMode.isView = false;
        this.button.openWidgetAddTab.isView = false;
        this.deletingWidgets = [];
        this.mode = '';
        this.widget.layout = [];
        this.layoutStatic = true;
        this.gridLayout = [];
        //this._initWidgets();
        this.initDashboard();
      },
      /** @description : 편집모드 시 위젯 저장*/
      async saveWidgetsOfEditMode() {
        let widgetList = [];
        let result;

        if (this.gridLayout.length !== 0) {
          this.gridLayout.filter(gridItem => {
            result = this.widget.layout.map(layoutItem => {
              if (gridItem.i === layoutItem.i) {
                layoutItem.positionX = gridItem.x;
                layoutItem.positionY = gridItem.y;
                layoutItem.width = gridItem.w;
                layoutItem.height = gridItem.h;
              }
              return layoutItem;
            });
          });
          this.widget.layout = result;
        } else {
          this.widget.layout = [];
        }

        //특정 템플릿의 column 셋팅하기
        for (let element of this.widget.layout) {
          let id = element.id;
          let regId = null,
            editId = null;

          if (String(element.id).indexOf('temp') > -1) {
            id = null;
            regId = this.$store.getters.getLoginId;
          }

          editId = this.$store.getters.getLoginId;
          let widget = {};

          let period = '';
          if (element.durationType === this.duration.recent) {
            //최근 __일|__개월|__년
            if (element.recent === null || element.recent === undefined) {
              period = element.period;
            } else {
              const recent = element.recent;
              let date = new Date();

              const dateGroupCode = element.dateGroupCode;
              let startDt = '';
              let endDt = '';
              endDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
              if (dateGroupCode === this.dateFormat.daily || dateGroupCode === this.dateFormat.hour) {
                const daysAgo = date.getDate() - recent;
                //date = date.setDate(daysAgo); 이렇게 설정하는 순간 타임스탬프 형식으로 변경됨
                date.setDate(daysAgo);
                startDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
              }
              period = startDt + '~' + endDt;
            }
          } else {
            period = element.period;
          }
          // 값이 없는 경우 템플릿의 값으로 셋팅
          if (!Object.prototype.hasOwnProperty.call(element, 'dateGroupCode')) element.dateGroupCode = element.widgetTp.dateGroupCode;

          let columnNameList = null;
          let columnList = null;

          // , columnNameList가 배열일 때와 문자열일 때를 구분하여 예외 처리
          if (element.columnList !== null && element.columnList !== 'undefined') {
            if (typeof element?.columnList !== 'string') {
              columnList = element?.columnList.join();
            } else {
              columnList = element.columnList;
            }
          }

          if (element.columnNameList !== null && element.columnNameList !== 'undefined') {
            if (typeof element?.columnNameList !== 'string') {
              columnNameList = element?.columnNameList.join();
            } else {
              columnNameList = element.columnNameList;
            }
          }

          widget = {
            id: id,
            title: element.title,
            positionX: element.positionX,
            positionY: element.positionY,
            width: element.width,
            height: element.height,
            period: period,
            regId: regId,
            editId: editId,
            widgetTpId: element.widgetTpId,
            columnList: columnList,
            columnNameList: columnNameList,
            dateGroupCode: element.dateGroupCode,
            sortType: 0,
            durationType: element.durationType,
            subjectType: element.subjectType,
            templateId: this.templateId,
            recent: element.recent,
          };

          if (element.hasOwnProperty('tempId')) {
            delete widget.id;
            delete widget.tempId;
          }
          widgetList.push(widget);
        }
        if (this.deletingWidgets.length > 0) {
          await this.deleteWidgetDataList(this.deletingWidgets);
        }
        await this.updateWidgetDataList(widgetList);
        this.cancelEditMode();
      },
      /** @description : 위젯 저장 메서드에서 호출한 UPDATE API 호출*/
      async updateWidgetDataList(widgetList) {
        let payload = {
          actionName: 'WIDGET_LIST_UPDATE',
          data: widgetList,
          loading: true,
        };
        let res = await this.CALL_REPORT_API(payload);
        if (await isSuccess(res)) {
          this.button.startEditMode.isView = true;
          this.button.saveWidgetsOfEditMode.isView = false;
          this.button.cancelEditMode.isView = false;
          this.button.openWidgetAddTab.isView = false;
          this.deletingWidgets = [];
          this.mode = '';
          this.$nextTick(function () {
            this.widget.layout = [];
          });

          this.$_Toast(await this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        }
      },
      /** @description : 두 날짜의 차이를 조회 후 일자로 반환*/
      getDateDiff(d1, d2) {
        const dateDiff = this.getYmdDiff(d1, d2);
        return Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
      },
      /** @description :  두 날짜의 차이 조회*/
      getYmdDiff(d1, d2) {
        const sy = d1.substring(0, 4);
        const sm = d1.substring(4, 6);
        const sd = d1.substring(6);
        const ey = d2.substring(0, 4);
        const em = d2.substring(4, 6);
        const ed = d2.substring(6);
        const sDate = new Date(sy, sm, sd);
        const eDate = new Date(ey, em, ed);
        const diffDate = sDate.getTime() - eDate.getTime();
        return diffDate;
      },
      /** @description : 편집모드 시 드랍한 위젯을 배열에 추가*/
      async insertWidgetAllData(e) {
        // TODO : idAndQuery 부분 소켓데이터로 변경예정
        if (e.chartType === 'scoreguard') {
          this.updatedWidgetId = 'widget' + e.id;
        }
        const idx = (this.widget.layout.length + 1).toString();
        let layoutData = {
          i: idx,
          id: e.id,
          tempId: 1,
          widgetTpId: e.widgetTpId,
          hidden: false,
          pinned: true,
          positionX: DragPos.x,
          positionY: DragPos.y,
          width: e.width,
          height: e.height,
          title: e.title,
          widgetType: e.widgetTp.widgetShapeTypeFl,
          chartType: e.widgetTp.chartTypeCode.codeValue,
          chartName: e.widgetTp.chartTypeCode.codeNm,
          period: e.period,
          widgetTp: e.widgetTp,
          dateGroupCode: e.dateGroupCode ? e.dateGroupCode : e.widgetTp.dateGroupCode,
          sortType: e.sortType,
          durationType: e.durationType,
          subjectType: e.subjectType,
          columnList: e.columnList,
          columnNameList: e.columnNameList,
          columnGroupList: e.columnGroupList,
          rotated: false,
          templateId: this.templateId,
          tableName: e.tableName,
          recent: e.recent,
          dayStart: e.dayStart,
          dayEnd: e.dayEnd,
        };

        if (
          e.widgetTp.chartTypeCode.codeValue === 'rotatedbar' ||
          e.widgetTp.chartTypeCode.codeValue === 'stackedrotatedbar' || //TODO : rotated를 제일 앞으로 빼고 나머지 chartType을 뒤로 빼기
          e.widgetTp.chartTypeCode.codeValue === 'fullstackedrotatedbar'
        ) {
          layoutData.chartType = e.widgetTp.chartTypeCode.codeValue.replace('rotated', '');
          layoutData.rotated = true;
        }

        this.gridLayout.push({
          x: DragPos.x,
          y: DragPos.y, //이거의 경우 위젯이 몇개 화면에 뿌려져 있는지에 따라서 케이스가 생김, 경우의 수로 따져가며 비교
          w: e.width,
          h: e.height,
          obj: layoutData,
          i: idx, //temp가 안오게 하기 위함
        });
        this.tempE = layoutData;
      },
      /** @description : 대시보드 페이지 진입 이벤트*/
      async initDashboard() {
        // 개별 위젯에 대한 설정 저장 시 initDashboard 메서드가 호출되기 때문에 gridLayout을 비우고 다시 담아야 함
        this.gridLayout = [];
        await this.getWidgetsAsTemplateId();
        // templateId로 조회한 API가 widget이 1개 이상이라면
        if (this.savedWidgets !== null) {
          this.recursiveWidget(this.savedWidgets);
        } else {
          //TODO: 로드할 위젯이 없을 경우 알럿 또는 다른 처리가 필요
        }
      },
      /** @description : 위젯 속성을 재귀적으로 셋팅*/
      async recursiveWidget(savedWidgets) {
        if (savedWidgets.length > 0) {
          let widget = savedWidgets.pop();
          if (widget.durationType === this.duration.recent) await this.changeDate(widget);
          await this.makeWidgetProperty(widget);
          await this.setDashboard();
          this.recursiveWidget(savedWidgets);
        }
      },
      /** @description : templateId에 따른 위젯 데이터 조회*/
      async getWidgetsAsTemplateId() {
        let params = {
          templateId: this.templateId,
        };

        let payload = {
          actionName: 'WIDGET_LIST_ALL',
          loading: true,
          data: params,
        };

        let res = await this.CALL_REPORT_API(payload);

        if (isSuccess(res)) {
          this.savedWidgets = getResData(res);
        }
      },
      /** @description : 최근 조회시 기간을 계산*/
      changeDate(e) {
        //최근조회일 경우
        let startDt = e.period.split('~')[0];
        let endDt = e.period.split('~')[1];
        //템플릿으로 부터 온 최근 __일|__개월|__년
        let date = new Date();
        const dateGroupCode = e.dateGroupCode;

        if (dateGroupCode === this.dateFormat.daily || this.dateFormat.hour) {
          const recent = this.getDateDiff(startDt, endDt);
          endDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
          const daysAgo = date.getDate() - recent;
          date.setDate(daysAgo);
          startDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
        }

        e.period = startDt + '~' + endDt;
        return e;
      },
      /** @description : 위젯별 속성을 구성*/
      async makeWidgetProperty(widget) {
        // parameter : widget은 속성이 구성되어있지 않음

        // 속성 구성 시작---------------------
        // 조회 항목(영어)
        let columnList = [];
        if (widget.columnList !== null) {
          columnList = widget?.columnList?.split(',');
        }
        // 조회항목(한글)
        let columnNameList;
        if (widget.columnNameList !== null) {
          columnNameList = widget?.columnNameList?.split(',');
        }
        // 조회 그룹(영어)
        let groupList = [];
        if (widget.columnGroupList !== null) {
          groupList = widget?.widgetTp.columnGroupList?.split(',');
        }
        // 스코어 카드는 현재 시간과 데이터를 비교하기 떄문에 현재 시간을 구해서 endDt로 조작한다
        if (widget.chartType === 'scoreguard') {
          let date = new Date();
          const endDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYYMMDD');
          widget.period = widget.period.split('~')[0] + '~' + endDt;
        }
        // 차트명을 영어와 한글로 구분
        const chartTypeCd = widget.widgetTp.chartTypeCd;
        const chartObject = this.widgetTypes.find(e => e.codeId === chartTypeCd);
        const chartType = chartObject.codeValue;
        const chartName = chartObject.codeNm;

        //조회 기간
        const dayStart = widget.period.split('~')[0];
        const dayEnd = widget.period.split('~')[1];

        //조회 대상 테이블 설정
        const dateGroupCode = widget.dateGroupCode;
        const suffixes = {
          210: '_D',
          213: '_H',
        };
        const tableName = `${widget.widgetTp.targetQuery}${suffixes[dateGroupCode]}`;
        // 이 widgetProperty에서 만든 형식그대로 widget 컴포넌트에 전달된다
        let localWidgetProperty = {
          id: widget.id,
          widgetTpId: widget.widgetTpId,
          hidden: false,
          pinned: true,
          positionX: widget.positionX,
          positionY: widget.positionY,
          width: widget.width,
          height: widget.height,
          x: widget.positionX,
          y: widget.positionY,
          w: widget.width,
          h: widget.height,
          i: String(widget.id),
          title: widget.title,
          widgetType: widget.widgetTp.widgetShapeTypeFl,
          chartType: chartType,
          chartName: chartName,
          dayStart: dayStart,
          dayEnd: dayEnd,
          period: widget.period,
          widgetTp: widget.widgetTp,
          tableName: tableName,
          dateGroupCode: widget.dateGroupCode ? widget.dateGroupCode : widget.widgetTp.dateGroupCode,
          sortType: widget.sortType,
          durationType: widget.durationType,
          subjectType: widget.subjectType,
          columnList: columnList,
          columnNameList: columnNameList,
          columnGroupList: groupList,
          templateId: this.templateId,
          rotated: false,
          recent: widget.recent,
        };
        // 가로형 바 차트들의 경우에는 차트타입명에서 rotated를 제거하고, rotated 속성을 true로 변경한다
        if (chartName === 'rotatedbar' || chartName === 'stackedrotatedbar' || chartName === 'fullstackedrotatedbar') {
          localWidgetProperty.chartType = widget.widgetTp.chartTypeCode.codeValue.replace('rotated', '');
          localWidgetProperty.rotated = true; // TODO: DB 이전 고려
        }
        // 속성 구성 종료---------------------

        // grid의 layout item 구성(필수 요소 : x, y, w, h, i)
        let item = {
          x: widget.positionX,
          y: widget.positionY,
          w: widget.width,
          h: widget.height,
          i: String(widget.id),
          obj: localWidgetProperty,
          static: true,
        };

        this.item = item;
        this.widgetProperty = localWidgetProperty;

        return widget;
      },
      /** @description : 대시보드에 위젯을 셋팅*/
      async setDashboard() {
        this.gridLayout.push(this.item);
        this.widget.layout.push(this.widgetProperty); //위젯의 속성이나 X,Y축 값 및 series들
        return;
      },
      /** @description : 편집모드 시 위젯 드랍*/
      async onWidgetDrop(e) {
        await this.insertWidgetAllData(e);
        this.$nextTick(() => {
          this.widget.layout.push(this.tempE);
        });
      },
      /** @description : 위젯 삭제 - 1. 삭제할 위젯을 UI(gridLayout, widget.layout)에서 찾아서 삭제*/
      onRemoveWidgetLayout(id) {
        const idToDelete = id;
        //TODO 어떤 상황에서 id가 temp 포함한 값으로 넘어오는 지
        let gridLayoutIdx = this.gridLayout.findIndex(obj => obj.i === String(idToDelete) || obj.i === 'temp' + idToDelete);
        const index = this.widget.layout.findIndex(obj => obj.id === idToDelete);
        if (index !== -1 && gridLayoutIdx !== -1) {
          this.deletingWidgets.push({ id: idToDelete });
        }
        this.widget.layout.splice(index, 1);
        this.gridLayout.splice(gridLayoutIdx, 1);
      },
      /** @description : 위젯 삭제 - 2. 서버에서 삭제*/
      async deleteWidgetDataList(data) {
        let payload = {
          actionName: 'WIDGET_LIST_DELETE',
          data,
          loading: true,
        };
        let res = await this.CALL_REPORT_API(payload);
        if (res.status == '200') {
          if (res.data.header.resCode !== 'success') {
            this.$_Toast(await this.$_lang('CMN_ERROR'));
          }
        } else {
          this.$_Toast(await this.$_lang('CMN_ERROR'));
        }
      },
      /** @description : 편집모드 시작*/
      editStart() {
        this.button.openWidgetAddTab.isView = true;
        this.button.saveWidgetsOfEditMode.isView = true;
        this.button.cancelEditMode.isView = true;
        const conObj = document.querySelector('#container');
        conObj.classList.remove('bottom-line-down');
        // 플로팅 버튼 하단선을 위로 올리기
        conObj.classList.add('bottom-line-up');
        const endObj = document.querySelector('#editEnd');
        endObj.classList.add('show');
        const startObj = document.querySelector('#editStart');
        startObj.classList.add('hide');
      },
      /** @description : 편집모드 종료*/
      editEnd() {
        const conObj = document.querySelector('#container');
        conObj.classList.remove('bottom-line-up');
        conObj.classList.add('bottom-line-down');
        const endObj = document.querySelector('#editEnd');
        endObj.classList.remove('show');
        const startObj = document.querySelector('#editStart');
        startObj.classList.remove('hide');
        this.button.saveWidgetsOfEditMode.isView = false;
        this.button.cancelEditMode.isView = false;
        this.button.openWidgetAddTab.isView = false;
      },
      /** @description : 드래그 이벤트*/
      drag() {
        let parentRect = document.getElementById('content').getBoundingClientRect();
        let mouseInGrid = false;
        if (mouseXY.x > parentRect.left && mouseXY.x < parentRect.right && mouseXY.y > parentRect.top && mouseXY.y < parentRect.bottom) {
          mouseInGrid = true;
        }
        if (mouseInGrid === true && this.gridLayout.findIndex(item => item.i === 'drop') === -1) {
          this.gridLayout.push({
            x: (this.gridLayout.length * 2) % (this.colNum || 12),
            y: this.gridLayout.length + (this.colNum || 12), // puts it at the bottom
            w: 1,
            h: 1,
            i: 'drop',
          });
        }
        let index = this.gridLayout.findIndex(item => item.i === 'drop');
        if (index !== -1) {
          try {
            this.$refs.gridlayout.$children[this.gridLayout.length].$refs.item.style.display = 'none';
          } catch {
            // TODO : 예외처리 추가할 것
          }
          let el = this.$refs.gridlayout.$children[index];
          el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
          let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

          if (mouseInGrid === true) {
            this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1);
            DragPos.i = String(index);
            DragPos.x = this.gridLayout[index].x;
            DragPos.y = this.gridLayout[index].y;
          }
          if (mouseInGrid === false) {
            this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1);
            this.gridLayout = this.gridLayout.filter(obj => obj.i !== 'drop');
          }
        }
      },
      /** @description : 드래그 종료 이벤트*/
      dragend() {
        let parentRect = document.getElementById('content').getBoundingClientRect();
        let mouseInGrid = false;
        if (mouseXY.x > parentRect.left && mouseXY.x < parentRect.right && mouseXY.y > parentRect.top && mouseXY.y < parentRect.bottom) {
          mouseInGrid = true;
        }
        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1);
          this.gridLayout = this.gridLayout.filter(obj => obj.i !== 'drop');
        }
      },
      /** @description : U_CODE 내 위젯 템플릿 타입 조회*/
      getWidgetTps() {
        this.widgetTypes = this.$_getCode('search_type_widget');
      },
      /** @description : 드랍영역 resize 이벤트 감지*/
      resizeObserve() {
        this.resizeChecker = new ResizeObserver(entries => {
          for (let entry of entries) {
            if (this.$refs.gridlayout) {
              this.$nextTick(function () {
                window.dispatchEvent(new Event('resize'));
              });
            }
          }
        });
        this.resizeChecker.observe(this.$refs.dragArea);
      },
      subscribeToTopics() {
        clearInterval(this.wsReconnectFunc);
        this.stompClient = this.$store.getters.getStompClient;
        // TODO: 동적 subscribe 구현
        // this.stompClient.subscribe(`/path/${e.cronNm}`, function (e) {
        //   vm.table[e.cronNm] = JSON.parse(e.body);
        // });
        const vm = this;

        const handleMessage = (message, callback) => {
          console.log('Received message:', message);
          const parsedMessage = JSON.parse(message.body);
          callback(parsedMessage);
        };

        if (this.stompClient && this.stompClient.connected) {
          this.subscription = this.stompClient.subscribe('/sub/dashboard/data', function (e) {
            vm.getData(e.body, 'REPORT_DASHBOARD_' + e.body);
          });
          this.stompClient.ws.onclose = () => {
            console.log('소켓 연결 끊김, 재연결 시도 중...');
            this.wsConnected = false;
            vm.wsReconnection();
          };
        } else {
          vm.wsReconnection();
        }
      },
      wsReconnection() {
        // this.wsConnected = true;
        this.wsReconnectFunc = setInterval(() => {
          // this.$store.dispatch('WEBSOCKET_CONNECT');
          this.subscribeToTopics();
        }, 5000); // 5초 후 재연결 시도
      },
      async getCron() {
        const payload = {
          actionName: 'CRON_LIST',
          data: { cronNm: 'DASHBOARD' },
          loading: false,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.cron = res.data.data;
        }
      },
      async getData(key, actionName) {
        const payload = {
          actionName: actionName,
          loading: true,
        };
        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          this.table[key] = res.data.data;
        }
      },
      async initData() {
        const payload = {
          actionName: 'REPORT_DASHBOARD_SELECT',
          loading: true,
        };
        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          const keys = Object.keys(res.data.data[0]);
          for (let key of keys) {
            this.table[key] = res.data.data[0][key];
          }
        }
      },
    },
    async mounted() {
      // 대시보드 템플릿 ID 설정
      this.templateId = this.$route.params.templateId;

      // 크론 값 조회 및 초기 데이터 조회
      await this.getCron();
      await this.initData();
      this.subscribeToTopics();
      this.resizeObserve();
      //코드별 위젯 종류 셋팅
      this.getWidgetTps();
      await this.initDashboard();
      document.addEventListener(
        'dragover',
        function (e) {
          mouseXY.x = e.clientX;
          mouseXY.y = e.clientY;
        },
        false,
      );
    },
    beforeDestroy() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (this.wsReconnectFunc) {
        clearInterval(this.wsReconnectFunc);
      }
    },
  };
</script>
<!-- button CSS -->
<style>
  #dashboard .dx-popup-content {
    padding: 0px 20px;
  }

  .floating-container {
    position: fixed;
    width: 100px;
    height: 100px;
    bottom: 0;
    right: 0;
    margin: 0px 15px;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .floating-container:hover .floating-button {
    transform: translatey(5px);
    transition: all 0.3s;
  }

  .floating-button {
    width: 65px;
    height: 65px;
    background: var(--themeColor);
    bottom: 0;
    border-radius: 50%;
    left: 0;
    right: 0;
    color: white;
    line-height: 65px;
    text-align: center;
    font-size: 23px;
    z-index: 100;
    margin: 8px 0px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .floating-button.cancel {
    background: white;
    border: 1px solid lightgrey;

    img {
      padding: 0 0 3px 0;
    }
  }

  .floating-button.edit {
    img {
      padding: 0 1px 0px 1px;
    }
  }

  #editEnd {
    visibility: hidden;
    display: none;
    position: absolute;
    opacity: 0;
    transistion: visibility 0s, opacity 0.5s ease;
  }

  .dashboard-button {
    z-index: 1;
    background: white;
    border-radius: 10px 10px 0 0;
    padding-bottom: 0px;
    height: 22px;
  }

  .button-container {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    min-height: 20px;
    margin: 10px 0 5px;
  }

  /* 편집모드 시작/종료 이벤트에 의해 활성화되는 클래스 start */
  .edit-start-container.hide {
    visibility: hidden;
    display: none;
    position: absolute;
    opacity: 0;
    transistion: visibility 0s, opacity 0.5s ease;
  }

  #editEnd.show {
    visibility: visible;
    display: block;
    position: static;
    opacity: 1;
    transistion: visibility 0s, opacity 0.5s ease;
  }

  #container.bottom-line-up {
    bottom: 162px;
  }

  #container.bottonDown {
    bottom: 0px;
  }

  /* 편집모드 시작/종료 이벤트에 의해 활성화되는 클래스 end */
</style>
<!-- tab CSS -->
<style>
  /* edit.vue 컴포넌트 내 tabs.vue 컴포넌트 스타일 */
  .dashboard-tab .page-sub-box {
    padding: 0 15px 80px 15px !important;
  }

  .dashboard-tab #widget-template li {
    width: 150px !important;
  }

  .dashboard-tab .tb_tp {
    float: left !important;
    width: 50% !important;
  }

  .dashboard-tab .tab-description {
    height: 50px;
    margin-bottom: 15px;
    line-height: 50px;
  }

  .dashboard-tab .tab-header {
    height: 50px;
    padding: 0 0;
    margin-top: 30px;
  }

  .dashboard-tab .tab-header .header-left,
  .dashboard-tab .tab-header .header-right {
    height: 50px;
    line-height: 50px;
  }

  #widget-template {
    min-height: 400px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
  }

  #widget-template LI {
    /*float: left;*/
    border-radius: 10px;
    -webkit-box-shadow: 3px 3px 6px 0px rgb(92 92 92 / 10%);
    box-shadow: 3px 3px 6px 0px rgb(92 92 92 / 10%);
    cursor: pointer;
    /*margin-right: 29px;*/
    margin-bottom: 10px;
    width: 120px;
    height: 120px;
    display: inline-block;
  }

  #widget-template LI:nth-child(even) {
    margin-right: 0px;
  }

  #dashboard .dashboard-tab {
    height: 100vh;
    position: fixed;
    top: 50px;
    right: -400px;
    background: #fff;
    z-index: 2;
    width: 400px;
    border: 1px solid #efefef;
    padding: 0 25px !important;
  }
</style>
<!-- vue-grid-layout CSS -->
<style>
  .vue-grid-layout {
    background: #fff;
  }

  .vue-grid-item {
    touch-action: none !important;
  }

  .vue-grid-item:not(.vue-grid-placeholder) {
  }

  .vue-grid-item .resizing {
    opacity: 0.9;
  }

  .vue-grid-item .static {
    background: #cce;
  }

  .vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
  }

  .vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
  }

  .vue-grid-item .minMax {
    font-size: 12px;
  }

  .vue-grid-item .add {
    cursor: pointer;
  }
</style>
<!--확인 필요-->
<style scoped>
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.075);
  }

  ::-webkit-scrollbar {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.075);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

  /* noselect 클래스 소스에 없지만 Widget border에 필요*/
  .noselect {
    border: 2px solid #efefef;
  }

  .ecs-tab-box {
    margin: 0px;
  }

  .grid::before {
    content: '';
    background-size: calc(calc(100% - 5px) / 12) 40px;
    background-image: linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to bottom, lightgrey 1px, transparent 1px);
    height: calc(100% - 5px);
    width: calc(100% - 5px);
    position: absolute;
    background-repeat: repeat;
    margin: 5px;
  }
</style>
