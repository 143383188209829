<!--
  PACKAGE_NAME : src/pages/esp/auth
  FILE_NAME : auth-member-list.vue
  AUTHOR : devyoon91
  DATE : 2024-12-10
  DESCRIPTION : 권한 유저 리스트 컴포넌트
-->
<template>
  <div>
    <esp-dx-data-grid :ref="dataGrid.ref" :data-grid="dataGrid" @selection-changed="handleSelectionChanged" />
    <modal-add-member ref="modalAddAuthMember" @saveAuthMemberModal="handleSaveAuthMemberModal" />
  </div>
</template>

<script>
  import { isSuccess } from '../../../utils/common-lib';
  import ModalAddMember from './modal-add-member.vue';
  import EspDxDataGrid from '../../../components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: { EspDxDataGrid, ModalAddMember },
    props: {
      selectAuthInfo: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        dataGrid: {
          keyExpr: 'id',
          ref: 'authMemberList',
          dataSource: [],
          height: 'calc(100vh - 560.5px)',
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text:
                    this.$_lang('COMPONENTS.USER', { defaultValue: '사용자' }) +
                    ' ' +
                    this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick: () => {
                    this.handleAddAuthMemberModal();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteAuthMember();
                  },
                },
                location: 'before',
              },
            ],
          },
          editing: {
            // 수정모드
            allowAdding: false,
            allowUpdating: false,
            allowDeleting: false,
          },

          page: {
            enabled: false,
          },
          columns: [{ dataField: 'name', caption: '이름' }],
        },
      };
    },
    watch: {
      selectAuthInfo: {
        handler: function (val) {
          this.setAuthMemberList(val.id);
        },
        immediate: true,
      },
    },
    methods: {
      /**
       * @description 사용자 선택 변경 이벤트
       * @param e
       */
      handleSelectionChanged(e) {
        this.dataGrid.selectedRowKeys = e.selectedRowKeys;
      },
      /**
       * @description 사용자 리스트 조회
       * @param authId
       * @return {Promise<void>}
       */
      async setAuthMemberList(authId) {
        if (authId == null) {
          this.dataGrid.dataSource = [];
          return;
        }

        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          data: { authId: authId },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          res.data.data.forEach(row => {
            row.name = `${row.loginNm}(${row.loginId})`;
          });
          this.dataGrid.dataSource = res.data.data;
        }
      },
      /**
       * @description 권한 사용자 삭제
       */
      async deleteAuthMember() {
        const vm = this.dataGrid;

        const delMemberList = vm.selectedRowKeys.map(key => {
          const row = vm.dataSource.find(item => item.id === key);
          row.authId = null;
          return row;
        });

        const payload = {
          actionName: 'MEMBER_AUTH_DELETE',
          data: delMemberList,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '선택한 데이터가 삭제되었습니다.' }));
          await this.initData();
        }
      },
      /**
       * 권한 사용자 추가 모달 팝업
       */
      handleAddAuthMemberModal() {
        // 선택된 권한이 없을 경우
        if (this.selectAuthInfo && this.selectAuthInfo?.id == null) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        this.$refs.modalAddAuthMember.handleOpenModal(this.selectAuthInfo?.id);
      },
      /**
       * @description 권한 사용자 추가 모달 저장 이벤트
       */
      handleSaveAuthMemberModal() {
        this.initData();
      },
      /**
       * @description 데이터 초기화
       */
      async initData() {
        await this.setAuthMemberList(this.selectAuthInfo.id);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
