<!--
  PACKAGE_NAME : src/pages/esp/auth
  FILE_NAME : search-target-page.vue
  AUTHOR : devyoon91
  DATE : 2024-12-12
  DESCRIPTION : 검색 대상 페이지
-->
<template>
  <div>
    <!-- 제목과 버튼 영역 -->
    <div class="header-container">
      <dx-button
        :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
        class="btn_XS default filled txt_S medium"
        :width="60"
        :height="30"
        @click="handleSave"
      />
    </div>

    <div class="dropdown-container">
      <!-- 좌측 드롭다운 -->
      <div class="dropdown-box">
        <div class="dropdown-title">사이트</div>
        <dx-drop-down-box
          v-model="siteDropDown.gridBoxValue"
          :ref="siteDropDown.refName"
          :data-source="siteDropDown.dataSource"
          :display-expr="siteDropDown.displayExpr"
          :value-expr="siteDropDown.valueExpr"
          :defer-rendering="siteDropDown.deferRendering"
          :show-clear-button="siteDropDown.showClearButton"
          :styling-mode="siteDropDown.stylingMode"
          :height="siteDropDown.height"
          :placeholder="siteDropDown.placeholder"
          @value-changed="handleValueChanged($event, siteDropDownDataGrid.refName)"
        >
          <template #content="{}">
            <esp-dx-data-grid
              :data-grid="siteDropDownDataGrid"
              :ref="siteDropDownDataGrid.refName"
              @selection-changed="handleSelectionChanged"
              @content-ready="handleContentReady"
            />
          </template>
        </dx-drop-down-box>
      </div>

      <!-- 우측 드롭다운 -->
      <div class="dropdown-box">
        <div class="dropdown-title">센터</div>
        <dx-drop-down-box
          v-model="tenantDropDown.gridBoxValue"
          :ref="tenantDropDown.refName"
          :data-source="tenantDropDown.dataSource"
          :display-expr="tenantDropDown.displayExpr"
          :value-expr="tenantDropDown.valueExpr"
          :defer-rendering="tenantDropDown.deferRendering"
          :show-clear-button="tenantDropDown.showClearButton"
          :styling-mode="tenantDropDown.stylingMode"
          :height="tenantDropDown.height"
          :placeholder="tenantDropDown.placeholder"
          @value-changed="handleValueChanged($event, tenantDropDownDataGrid.refName)"
        >
          <template #content="{}">
            <esp-dx-data-grid
              :data-grid="tenantDropDownDataGrid"
              :ref="tenantDropDownDataGrid.refName"
              @selection-changed="handleSelectionChanged"
              @content-ready="handleContentReady"
            />
          </template>
        </dx-drop-down-box>
      </div>
    </div>
    <div class="filter-grid-container">
      <esp-dx-data-grid
        v-for="authGroupDataGrid in authGroupDataGrids"
        :key="authGroupDataGrid.gridBindKey"
        :ref="authGroupDataGrid.refName"
        :data-grid="authGroupDataGrid"
        @selection-changed="handleSelectionChanged"
        @content-ready="handleContentReady"
      />
    </div>
  </div>
</template>

<script>
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { isSuccess } from '@/utils/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDropDownBox,
    },
    data() {
      return {
        selectedAuthId: null, // 선택된 권한 ID
        authGroupDataGridHeight: 300, // 공통 그리드 높이
        authGroupDataGrids: [
          {
            dataSource: [],
            visible: true,
            gridBindKey: 'ibgGrid',
            id: 'ibg',
            refName: 'refIbgDataGrid',
            height: this.authGroupDataGridHeight,
            keyExpr: 'value',
            dataSourceDefaultSortColumn: '+label',
            showBorders: true,
            showColumnLines: false,
            toolbarOptions: {
              visible: true, // 툴바 노출 여부
              title: '인입그룹', // 툴바 영역 타이틀 / 타이틀 설정할 경우 툴바 버튼 비노출
            },
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              update: false, // 추가/저장/취소 한번에 설정
              add: false, // 추가 개별 설정
              save: false, // 저장 개별 설정
              cancel: false, // 취소 개별 설정
              delete: false, // 삭제
            },
            page: {
              enabled: false,
            },
            columns: [
              {
                dataField: 'label',
                caption: ' ',
              },
            ],
          },
          {
            dataSource: [],
            visible: true,
            gridBindKey: 'sklGrid',
            id: 'skl',
            refName: 'refSklDataGrid',
            height: this.authGroupDataGridHeight,
            keyExpr: 'value',
            dataSourceDefaultSortColumn: '+label',
            showBorders: true,
            showColumnLines: false,
            toolbarOptions: {
              visible: true, // 툴바 노출 여부
              title: '상담그룹', // 툴바 영역 타이틀 / 타이틀 설정할 경우 툴바 버튼 비노출
            },
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              update: false, // 추가/저장/취소 한번에 설정
              add: false, // 추가 개별 설정
              save: false, // 저장 개별 설정
              cancel: false, // 취소 개별 설정
              delete: false, // 삭제
            },
            page: {
              enabled: false,
            },
            columns: [
              {
                dataField: 'label',
                caption: ' ',
              },
            ],
          },
          {
            dataSource: [],
            visible: true,
            gridBindKey: 'agtteamGrid',
            id: 'agtteam',
            refName: 'refAgtteamDataGrid',
            height: this.authGroupDataGridHeight,
            keyExpr: 'value',
            dataSourceDefaultSortColumn: '+label',
            showBorders: true,
            showColumnLines: false,
            toolbarOptions: {
              visible: true, // 툴바 노출 여부
              title: '팀', // 툴바 영역 타이틀 / 타이틀 설정할 경우 툴바 버튼 비노출
            },
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              update: false, // 추가/저장/취소 한번에 설정
              add: false, // 추가 개별 설정
              save: false, // 저장 개별 설정
              cancel: false, // 취소 개별 설정
              delete: false, // 삭제
            },
            page: {
              enabled: false,
            },
            columns: [
              {
                dataField: 'label',
                caption: ' ',
              },
            ],
          },
          {
            dataSource: [],
            visible: true,
            gridBindKey: 'ivrdnisGrid',
            id: 'ivrdnis',
            refName: 'refIvrdnisDataGrid',
            height: this.authGroupDataGridHeight,
            keyExpr: 'value',
            dataSourceDefaultSortColumn: '+label',
            showBorders: true,
            showColumnLines: false,
            toolbarOptions: {
              visible: true, // 툴바 노출 여부
              title: '대표번호', // 툴바 영역 타이틀 / 타이틀 설정할 경우 툴바 버튼 비노출
            },
            showActionButtons: {
              // 그리드 버튼 노출 설정값
              update: false, // 추가/저장/취소 한번에 설정
              add: false, // 추가 개별 설정
              save: false, // 저장 개별 설정
              cancel: false, // 취소 개별 설정
              delete: false, // 삭제
            },
            page: {
              enabled: false,
            },
            columns: [
              {
                dataField: 'label',
                caption: ' ',
              },
            ],
          },
        ],
        siteDropDown: {
          refName: 'siteDropDownRef',
          dataSource: [],
          displayExpr: 'label',
          valueExpr: 'value',
          deferRendering: false,
          showClearButton: true,
          stylingMode: 'outlined',
          height: '30px',
          placeholder: this.$_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
          gridBoxValue: null,
        },
        siteDropDownDataGrid: {
          visible: true,
          refName: 'siteGrid',
          keyExpr: 'value',
          dataSourceDefaultSortColumn: '+label',
          height: '100%',
          showBorders: true,
          showColumnLines: false,
          showColumnHeaders: false,
          toolbarOptions: {
            visible: false, // 툴바 노출 여부
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            update: false, // 추가/저장/취소 한번에 설정
            add: false, // 추가 개별 설정
            save: false, // 저장 개별 설정
            cancel: false, // 취소 개별 설정
            delete: false, // 삭제
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              dataField: 'label',
            },
          ],
        },
        tenantDropDown: {
          refName: 'tenantDropDownRef',
          dataSource: [],
          displayExpr: 'label',
          valueExpr: 'value',
          deferRendering: false,
          showClearButton: true,
          stylingMode: 'outlined',
          height: '30px',
          placeholder: this.$_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
          gridBoxValue: null,
        },
        tenantDropDownDataGrid: {
          visible: true,
          refName: 'tenantGrid',
          keyExpr: 'value',
          dataSourceDefaultSortColumn: '+label',
          height: '100%',
          showBorders: true,
          showColumnLines: false,
          showColumnHeaders: false,
          toolbarOptions: {
            visible: false, // 툴바 노출 여부
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            update: false, // 추가/저장/취소 한번에 설정
            add: false, // 추가 개별 설정
            save: false, // 저장 개별 설정
            cancel: false, // 취소 개별 설정
            delete: false, // 삭제
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              dataField: 'label',
            },
          ],
        },
        updateData: {}, // 권한 업데이트 대상 객체
        initDataSourceMap: null, // 초기 데이터 소스 맵
        isInInitialization: true, // 초기화 작업 진행 여부
      };
    },
    methods: {
      /**
       * 검색 대상 권한 데이터 조회
       *
       * @param authId
       * @return {Promise<void>}
       */
      async setAuthGroup(authId = null) {
        this.initData(); // 데이터 초기화
        this.selectedAuthId = authId; // 선택된 권한 ID 설정

        if (this.selectedAuthId === null) {
          return;
        }

        const payload = {
          actionName: 'AUTH_GROUP_SEARCH_FILTER_LIST', // 통합 검색대상 권한 조회
          data: {
            authId: this.selectedAuthId,
            permFl: this.$_enums.common.stringUsedFlag.YES.value,
          },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          const authGroupData = res.data.data;
          this.$refs[this.siteDropDownDataGrid.refName].getInstance.option('dataSource', authGroupData?.site);
          this.$refs[this.tenantDropDownDataGrid.refName].getInstance.option('dataSource', authGroupData?.tenant);
          this.setInitDataSourceMap(authGroupData?.site); // 초기 데이터 소스 맵 설정
          this.setInitDataSourceMap(authGroupData?.tenant); // 초기 데이터 소스 맵 설정
          this.authGroupDataGrids.forEach(grid => {
            const refInstance = this.$refs[grid.refName][0];
            const data = authGroupData[grid.id] == null ? [] : authGroupData[grid.id];
            refInstance.getInstance.option('dataSource', data);
            this.setInitDataSourceMap(data); // 초기 데이터 소스 맵 설정
          });
        }
      },
      /**
       * @description 조합키 생성
       * @param groupKey {string} 그룹 키
       * @param rowKey {string} 행 키
       */
      convertInitDataSourceMapKey(groupKey, rowKey) {
        return groupKey + ':' + rowKey;
      },
      /**
       * row 상태 업데이트를 처리하는 유틸리티 함수
       * @param mergeKey {String} 병합키
       * @param initialState {Boolean} 초기 상태
       * @param isSelected {Boolean} 현재 선택 여부
       */
      updateRowState(mergeKey, initialState, isSelected) {
        // 선택 상태가 초기 상태와 같으면 updateData 에서 제거
        if (isSelected === initialState) {
          delete this.updateData[mergeKey];
        } else {
          this.updateData[mergeKey] = { selected: isSelected };
        }
      },
      /**
       * @description 초기 선택 상태 반환
       * @param mergeKey {string} 병합키
       * @return {boolean}
       */
      getInitialSelectionState(mergeKey) {
        const node = this.initDataSourceMap.get(mergeKey);
        if (!node) {
          return false;
        }
        return node.useFl === this.$_enums.common.stringUsedFlag.YES.value;
      },
      /**
       * @description 선택 상태 설정
       * @param component
       * @param rowKeys
       * @param isSelected
       */
      setSelection(component, rowKeys, isSelected) {
        if (rowKeys.length === 0) {
          return;
        }

        const dataSource = component.option('dataSource') || [];
        const rowData = dataSource.filter(data => rowKeys.includes(data.value));

        rowData.map(row => {
          // rowKey 에 해당하는 데이터 가져오기
          const mergeKey = this.convertInitDataSourceMapKey(row?.groupKey, row?.value);

          // 초기 상태 가져오기
          const initialState = this.getInitialSelectionState(mergeKey);

          // 현재 rowKey 상태 업데이트
          this.updateRowState(mergeKey, initialState, isSelected);
        });
      },
      /**
       * Drop Down 값 변경 이벤트 핸들러
       * @param e {Object} 이벤트 데이터
       * @param type {String} 변경 구분 (site, tenant)
       */
      handleValueChanged(e, type) {
        // Clear 처리
        if(e.value === null) {
          this.$refs[type].getInstance.deselectAll();
        }
      },
      /**
       * 선택 변경 이벤트 핸들러
       * @param e {Object} 이벤트 데이터
       */
      handleSelectionChanged(e) {
        if (this.isInInitialization) {
          // 초기화 작업 중이면 무시
          return;
        }

        this.setDropDownValue(); // 드롭다운 값 설정

        if (e.currentSelectedRowKeys?.length > 0) {
          // "선택된 항목"이 있는 경우
          this.setSelection(e.component, e.currentSelectedRowKeys, true);
        }

        if (e.currentDeselectedRowKeys?.length > 0) {
          // "선택 해제된 항목"이 있는 경우
          this.setSelection(e.component, e.currentDeselectedRowKeys, false);
        }

        this.$nextTick(() => {
          this.setDropDownValue(); // 드롭다운 값 설정
        });
      },
      /**
       * @description 드롭다운 값 설정(외부 view)
       */
      setDropDownValue() {
        this.siteDropDown.dataSource = this.$refs[this.siteDropDownDataGrid.refName].getInstance.getSelectedRowsData();
        this.siteDropDown.gridBoxValue = this.siteDropDown.dataSource.length > 0 ? this.siteDropDown.dataSource.map(item => String(item.value)) : null;
        this.tenantDropDown.dataSource = this.$refs[this.tenantDropDownDataGrid.refName].getInstance.getSelectedRowsData();
        this.tenantDropDown.gridBoxValue = this.tenantDropDown.dataSource.length > 0 ? this.tenantDropDown.dataSource.map(item => String(item.value)) : null;
      },
      /**
       * @description 저장 이벤트
       */
      handleSave() {
        this.updateAuthGroupUseFlag();
      },
      /**
       * @description 검색대상 권한 사용여부 업데이트
       * @return {Promise<void>}
       */
      async updateAuthGroupUseFlag() {
        if (Object.keys(this.updateData).length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return;
        }

        const payload = {
          actionName: 'AUTH_GROUP_USE_FL_UPDATE',
          data: Object.entries(this.updateData).map(([mergeKey, { selected }]) => {
            return {
              mergeKey,
              useFl: selected ? this.$_enums.common.stringUsedFlag.YES.value : this.$_enums.common.stringUsedFlag.NO.value,
            };
          }),
          path: this.selectedAuthId,
          loading: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.setAuthGroup(this.selectedAuthId);
        }
      },
      /**
       * @description 초기 데이터 소스 맵 설정 (그룹키 + 행키)
       * @param authGroupDataList {Object} 권한 그룹 데이터
       */
      setInitDataSourceMap(authGroupDataList) {
        if (!authGroupDataList) {
          return;
        }

        if (!this.initDataSourceMap) {
          this.initDataSourceMap = new Map(
            authGroupDataList.map(item => [this.convertInitDataSourceMapKey(item.groupKey, item.value), item]),
          );
        } else {
          authGroupDataList.forEach(item => {
            this.initDataSourceMap.set(this.convertInitDataSourceMapKey(item.groupKey, item.value), item);
          });
        }
      },
      /**
       * @description 컨텐츠 준비 이벤트 핸들러
       * @param e
       */
      async handleContentReady(e) {
        this.isInInitialization = true; // 초기화 작업 진행 중
        this.setDataSelection(e.component);
        this.$nextTick(() => {
          this.setDropDownValue(); // 드롭다운 값 설정
        });
        this.isInInitialization = false; // 초기화 완료
      },
      /**
       * @description 데이터 선택 이벤트
       * @param component {Object} 컴포넌트 인스턴스
       */
      setDataSelection(component) {
        component.clearSelection(); // 선택 해제

        const dataSource = component.option('dataSource');

        if (!dataSource || dataSource.length === 0) {
          return;
        }

        // 필터링된 데이터로 선택 처리 (필요 시 복구)
        const filteredNodeIds = dataSource
          .filter(data => data.useFl === this.$_enums.common.stringUsedFlag.YES.value)
          .map(data => data.value);

        if (filteredNodeIds.length > 0) {
          component.selectRows(filteredNodeIds, false);
        }
      },
      /**
       * @description 컴포넌트 데이터 초기화
       */
      initData() {
        this.selectedAuthId = null;
        this.updateData = {};
        this.initDataSourceMap = null;
        this.isInInitialization = true;
        this.siteDropDown.gridBoxValue = null;
        this.tenantDropDown.gridBoxValue = null;
        this.siteDropDown.dataSource = [];
        this.tenantDropDown.dataSource = [];
      },
    },
    mounted() {
      this.initData();
    },
  };
</script>

<style lang="scss" scoped>
  /* 상단 영역 - 제목과 버튼 배치 */
  .header-container {
    display: flex;
    justify-content: flex-end; /* 오른쪽 끝 */
    align-items: center; /* 세로 정렬 */
    padding: 4px 0 0 0;
  }

  ::v-deep .header-container > .btn_XS {
    padding: 0 10px;
    font-size: 13px;
    min-height: 28px;
    line-height: 26px;
    margin: 0;
  }

  /* 상단 제목 영역 스타일 */
  .header-title {
    font-size: 18px; /* 제목 크기 */
    font-weight: bold;
  }

  /* 드롭다운 컨테이너 - Flex로 좌우 배치 */
  .dropdown-container {
    display: flex;
    align-items: flex-start;
    width: 100%; /* 전체 화면 너비 사용 */
  }

  /* 각각의 드롭다운 박스 */
  .dropdown-box {
    flex: 1;
    box-sizing: border-box;
  }

  .dropdown-box:last-child {
    padding-left: 10px; /* 우측 드롭다운과의 간격 */
  }

  /* 드롭다운에 소제목(설명) 스타일 */
  .dropdown-title {
    padding-bottom: 10px; /* 드롭다운과의 간격 */
    font-weight: 500; /* 강조된 텍스트 */
    font-size: 16px; /* 적절한 크기 조정 */
  }

  .filter-grid-container {
    display: flex; /* Flexbox 활성화 */
    gap: 16px; /* 간격 추가 */
    justify-content: space-between;
    height: 100%;
  }

  .filter-grid-container > .dx-datagrid {
    width: calc(25% - 16px); /* 각 항목이 25% 차지 */
    min-width: 300px; /* 최소 너비 */
  }
</style>
