import InfoPushSetting from '@/pages/euc/info-push/setting';
import InfoPushSettingDeptUpdate from '@/pages/euc/info-push/setting/dept/update.vue';
import InfoPushSettingPatternUpdate from '@/pages/euc/info-push/setting/pattern/update.vue';
import InfoPushSettingUserUpdate from '@/pages/euc/info-push/setting/user/index.vue';

export default function InfoPushSettingRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/setting',
            name: 'InfoPushSetting',
            components: { default: InfoPushSetting, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/setting/dept/update',
            name: 'InfoPushSettingDeptUpdate',
            components: { default: InfoPushSettingDeptUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/setting/pattern/update',
            name: 'InfoPushSettingPatternUpdate',
            components: { default: InfoPushSettingPatternUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/setting/user/update',
            name: 'InfoPushSettingUserUpdate',
            components: { default: InfoPushSettingUserUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}