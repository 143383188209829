<!--
  PACKAGE_NAME : src/pages/euc/phone/request/type
  FILE_NAME : automation.vue
  AUTHOR : jhcho
  DATE : 24. 7. 5.
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="automationGrid" @init-new-row="onInitNewRow" @reorder="onReorder"/>
  </div>
</template>
<script>
import {formatDate, isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {EspDxDataGrid},
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'automationGrid',
        dataSourceDefaultSortColumn: '+automationOrd',
        apiActionNm: {
          select: 'EUC_REQUEST_AUTOMATION_SELECT',
          update: 'EUC_REQUEST_AUTOMATION_UPDATE',
        },
        customEvent: {
          initNewRow: true,
          reorder: true,
        },
        dragging: {
          sortColumn: 'automationOrd',
          allowReordering: true,
          dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
        },
        columns: [
          {
            caption: '상세 유형',
            i18n: 'UC.WORD.DETAILED_TYPE',
            dataField: 'detailId',
            lookup: {
              dataSource: [],
              displayExpr: 'detailNm',
              valueExpr: 'id',
            },
          },
          {
            caption: '자동화 코드',
            i18n: 'UC.WORD.AUTOMATION_CODE',
            dataField: 'automationCode',
            requiredRule: {},
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'automationOrd',
            visible: false,
            requiredRule: {},
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            allowEditing: false,
          },
          {
            caption: '수정일',
            i18n: 'COMPONENTS.MODIFY_DATE',
            dataField: 'editDt',
            allowEditing: false,
            calculateCellValue: data => this.formatDt(data.editDt),
          },
        ],
      },
    };
  },
  methods: {
    async onReorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const newOrderIndex = visibleRows[e.toIndex].data.automationOrd;

      const params = {
        id: e.itemData.id,
        automationOrd: newOrderIndex - 1,
      };

      const payload = {
        actionName: 'EUC_REQUEST_AUTOMATION_UPDATE',
        data: [params],
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.$refs.automationGrid.refreshData();
      }
    },
    /**
     * @description 새로운 행이 초기화될 때 호출되는 메서드
     * @param {Object} data - 새로운 행 데이터
     */
    onInitNewRow(data) {
      data.data.viewFl = 'Y';
    },
    async selectDetailList(sort = '+typeId,+detailOrd') {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_DETAIL_SELECT',
        data: {
          sort,
          viewFl: 'Y',
        },
      };

      const res = await this.CALL_EUC_API(payload);
      this.dataGrid.columns.find(item => item.dataField === 'detailId').lookup.dataSource = res.data?.data || [];
    },
    /** @description: 일자 데이터 출력 */
    formatDt(date) {
      if (date) {
        return formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
  mounted() {
  },
  async created() {
    await this.selectDetailList();
  },
};
</script>
<style scoped></style>