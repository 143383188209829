import InfoPushHistory from '@/pages/euc/info-push/history';
import InfoPushHistoryChart from '@/pages/euc/info-push/history/chart.vue';

export default function InfoPushHistoryRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/history',
            name: 'InfoPushHistory',
            components: { default: InfoPushHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/history/chart',
            name: 'InfoPushHistoryChart',
            components: { default: InfoPushHistoryChart, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}