<!--
  PACKAGE_NAME : src/pages/euc/contact
  FILE_NAME : contact.vue
  AUTHOR : jhcho
  DATE : 2024-06-10
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="contactGrid" @saving="onSaving" />
    <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <component
            ref="modalExcelUploadRef"
            :is="modal.currentComponent"
            :modalData="modal.sendData"
            v-model="modal.contentData"
            :groupList="groupList"
        />
      </template>
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => this.isOpenModal(false)
					}"
      />
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
						width: '120',
						height: '40',
						onClick: () => this.onSaveModal()
					}"
      />
    </DxPopup>
  </div>
</template>
<script>
import { DxSelectBox } from 'devextreme-vue/select-box';
import { isSuccess } from '@/utils/common-lib';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalExcelUpload from '@/pages/euc/contact/modal-excel-upload.vue';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import ClickToCallImg from '@/assets/images/landline3_00.png';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    DxToolbarItem, DxPopup,
    DxSelectBox,
    ModalExcelUpload,
    EspDxDataGrid
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      stylingMode: 'outlined',
      dataGrid: {
        excel: {
          title: this.$_lang('UC.WORD.CONTACT', { defaultValue: '개인연락처' }),
        },
        callApi: 'CALL_EUC_API',
        refName: 'contactGrid',
        dataSourceDefaultSortColumn: '-regDt',
        apiActionNm: {
          select: 'EUC_CONTACT_SELECT',
          delete: 'EUC_CONTACT_DELETE',
        },
        searchParams: {
          regId: this.isRootUser() ? null : this.$store.getters.getLoginId,
        },
        showActionButtons: {
          delete: !this.isRootUser(),
          excel: true,
          csv: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: 'upload',
                width: '40px',
                height: '30px',
                elementAttr: {
                  class: 'mr-1 white light_filled dx-shape-standard',
                },
                onClick: () => {
                  this.onUploadFile();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: 'download',
                text: '양식 다운로드',
                width: '140px',
                height: '30px',
                elementAttr: {
                  class: 'mr-1 white light_filled dx-shape-standard',
                },
                onClick: () => {
                  this.onFormExporting();
                },
              },
              location: 'before',
            },
          ]
        },
        editing: {
          allowUpdating: !this.isRootUser(),
          allowAdding: !this.isRootUser(),
        },
        columns: [
          {
            caption: '사번',
            dataField: 'regId',
            width: 100,
            visible: this.isRootUser(),   // root 사용자만 보이도록 설정
            allowEditing: false,
          },
          {
            caption: '그룹명',
            dataField: 'groupId',
            width: 200,
            lookup: {
              dataSource: [],
              displayExpr: 'groupNm',
              valueExpr: 'id',
              allowClearing: true,
            },
          },
          {
            caption: '이름',
            dataField: 'personNm',
            width: 150,
            requiredRule: {},
          },
          {
            caption: '직위',
            dataField: 'gradeNm',
            width: 130,
          },
          {
            caption: '회사',
            dataField: 'companyNm',
            width: 130,
          },
          {
            caption: '부서명',
            dataField: 'deptNm',
            width: 150,
          },
          {
            caption: '연락처1',
            dataField: 'number1',
            width: 200,
            cellTemplate: (container, options) => {
              if (options.value && !options.row.modified && this.$_getSystemData('use_click_to_call')?.configValue === 'Y') {
                const divEle = document.createElement('div');
                divEle.style.width = '100%';
                divEle.style.display = 'flex';
                divEle.style.alignItems = 'center';
                divEle.style.justifyContent = 'space-between'; // span을 중앙에, img를 오른쪽에 배치

                const spanEle = document.createElement('span');
                spanEle.innerText = options.value;
                spanEle.style.margin = '0 auto'; // span을 중앙 정렬

                const imgEle = document.createElement('img');
                imgEle.src = ClickToCallImg;
                imgEle.style.cursor = 'pointer';
                imgEle.style.height = '20px';
                imgEle.addEventListener('click', () => {
                  this.handleClickToCall(options)
                });

                divEle.append(spanEle, imgEle);
                container.append(divEle);
              } else {
                container.innerText = options?.value || '';
              }
            },
            requiredRule: {},
          },
          {
            caption: '연락처2',
            dataField: 'number2',
            width: 200,
            cellTemplate: (container, options) => {
              if (options.value && !options.row.modified && this.$_getSystemData('use_click_to_call')?.configValue === 'Y') {
                const divEle = document.createElement('div');
                divEle.style.width = '100%';
                divEle.style.display = 'flex';
                divEle.style.alignItems = 'center';
                divEle.style.justifyContent = 'space-between'; // span을 중앙에, img를 오른쪽에 배치

                const spanEle = document.createElement('span');
                spanEle.innerText = options.value;
                spanEle.style.margin = '0 auto'; // span을 중앙 정렬

                const imgEle = document.createElement('img');
                imgEle.src = ClickToCallImg;
                imgEle.style.cursor = 'pointer';
                imgEle.style.height = '20px';
                imgEle.addEventListener('click', () => {
                  this.handleClickToCall(options)
                });

                divEle.append(spanEle, imgEle);
                container.append(divEle);
              } else {
                container.innerText = options?.value || '';
              }
            },
          },
          {
            caption: '메모',
            dataField: 'memo',
          },
        ],
      },
      groupList: []
    }
  },
  watch: {
    groupList: {
      handler: function (val) {
        this.dataGrid.columns.find((item) => item.dataField === 'groupId').lookup.dataSource = val;
      },
      deep: true
    }
  },
  methods: {
    async handleClickToCall(options) {
      const device = this.$store.getters.getUserInfo?.dn;
      if(!device) {
        this.$_Msg('사용중인 내선번호가 없습니다.<br /> 확인 후 다시 시도해주세요.');
        return;
      }

      const msg = [];
      if(options.data.personNm) {
        msg.push(`${options.data.personNm}님의`);
      }

      if(options.value) {
        msg.push(`전화번호`);
        msg.push(`${options.value}으로`);
      }

      const result = await this.$_Confirm(`${msg.join(' ')} 전화를 거시겠습니까?`);
      if(result) {
        const dialNum = options.value.replace(/[^0-9]/g, '');
        const payload = {
          actionName: 'EUC_CLICK_TO_CALL',
          data: {
            id: this.$store.getters.getLoginId,
            device: device,
            dialNum: dialNum.length > 6 ? `9${dialNum}` : dialNum,
            menuId: this.$route.params?.menuId ? this.$route.params.menuId : this.$route.name
          }
        }

        const res = await this.CALL_EUC_API(payload);
        if(isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }), { icon: 'success' });
        } else {
          this.$_Msg(res.data.header?.resMsg || this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
        }
      }
    },
    async onSaving(e) {
      if (e.changes?.length) {
        e.cancel = true;
        const changeData = e.changes.map((item) => {
          const returnData = {
            ...item.data,
            id: item.type === 'insert' ? null : item.key,
          }
          if(returnData?.number1) {
            returnData.number1 = returnData.number1.replace(/[^0-9]/g, '');
          }
          if(returnData?.number2) {
            returnData.number2 = returnData.number2.replace(/[^0-9]/g, '');
          }
          return returnData;
        });

        const payload = {
          actionName: 'EUC_CONTACT_UPDATE',
          data: changeData,
          loading: true,
        }
        const res = await this.CALL_EUC_API(payload);

        if(isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }), { icon: 'success' });
          e.component.cancelEditData();
          this.$refs.contactGrid.refreshData();
        } else {
          this.$_Msg(res.data?.header?.resMsg ?? this.$_lang('COMMON.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }), { icon: 'error' });
        }
      }
    },
    /**
     * 양식 다운로드
     */
    onFormExporting() {
      const title = `양식 다운로드`;
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet(title);
      worksheet.properties.defaultFont = { name: '맑은 고딕' };
      //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
      let columnsArr = [];

      const column = this.$refs.contactGrid.getInstance.getVisibleColumns();
      const columnNames = column
          .filter((item) => item.caption !== undefined)
          .map((item) => item.caption);

      const isRegIdColumn = columnNames.includes('사번');
      if(isRegIdColumn) {
        worksheet.getColumn('G').numFmt = '@';
        worksheet.getColumn('H').numFmt = '@';
      } else {
        worksheet.getColumn('F').numFmt = '@';
        worksheet.getColumn('G').numFmt = '@';
      }
      columnNames.forEach(() => {
        columnsArr.push({ width: 30 });
      });
      worksheet.columns = columnsArr;

      // title 영역 설정
      worksheet.mergeCells(isRegIdColumn ? 'A2:I2' : 'A2:H2');
      const titleCell = worksheet.getCell('A2');
      titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
      titleCell.font = { size: 22, bold: true };
      titleCell.value = '개인연락처 (샘플은 모두 지우고 작성)';

      // cell 테두리 설정
      const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
      // header 영역 설정
      columnNames.forEach((item, index) => {
        const cell = worksheet.getCell(`${String.fromCharCode(65 + index)}4`);
        cell.value = item;
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle,
          top: borderStyle,
        };
      });

      // body 영역 설정
      for(let i = 0; i < 10; i++) {
        columnNames.forEach((item, index) => {
          const bodyCell = worksheet.getCell(`${String.fromCharCode(65 + index)}${5 + i}`);
          if (i % 2 === 0) {
            bodyCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F2F2F2' } };
          }
          bodyCell.alignment = { horizontal: 'center', vertical: 'middle' };
          if(i === 0) {
            let value = ['SI24029', 'ECS Telecom', '홍길동', '팀장', 'ECS Telecom', 'R1', '01012345678', '0234567890', '전화 번호간 (-) 생략'];
            if(!isRegIdColumn) {
              value.shift();
            }
            bodyCell.value = value[index];
          }

          bodyCell.border = {
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
            top: borderStyle,
          };
        });
      }

      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${title}.xlsx`);
      });
    },
    async onSaveModal() {
      const data = await this.$refs.modalExcelUploadRef.getExcelData();
      let cnt = 0;
      while(cnt < data.length) {
        this.$refs.contactGrid.getInstance.addRow();
        cnt++;
      }

      const notFoundGroup = Array.from(new Set(data
          .filter(item => this.groupList.findIndex(group => group.groupNm === item.groupNm) === -1)
          .map(item => item.groupNm)))
          .map(groupNm => ({ id: null, groupNm, viewFl: 'Y', groupOrd: 0 }));

      if(notFoundGroup.length) {
        await this.onSageGroup(notFoundGroup);
      }

      data.forEach((item, index) => {
        if(item?.groupNm) {
          const group = this.groupList.find((group) => group.groupNm === item.groupNm);
          if(group) {
            item.groupId = group.id;
          }
        }

        const fields = this.dataGrid.columns.map((column) => column.dataField);
        fields.forEach(field => {
          this.$refs.contactGrid.getInstance.cellValue(index, field, item[field]);
        });
      });

      this.isOpenModal(false);
    },
    async onSageGroup(groups) {
      const payload = {
        actionName: 'EUC_CONTACT_GROUP_UPDATE',
        data: groups,
        loading: true
      }
      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        await this.selectGroupDataList();
      }
    },
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if(!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
        this.modal.contentData = null;
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, sendData){
      this.modal.currentComponent = componentNm;  //set dynamic component name in modal body slot
      this.modal.initData = componentInitData;  //set init modal templet
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    onUploadFile() {
      this.onOpenModal('modal-excel-upload', {
        title: this.$_lang('UC.MESSAGE.EXCEL_UPLOAD', { defaultValue: '엑셀 등록' }),
        width: 700,
        height: 450,
      });
    },
    async selectGroupDataList(loading = false) {
      const payload = {
        actionName: 'EUC_CONTACT_GROUP_SELECT',
        data: {
          regId: this.$store.getters.getLoginId,
          sort: '+groupOrd',
          viewFl: 'Y',
        },
        loading,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.groupList = res.data.data;
      }
    },
    isRootUser () {
      const rootUsers = this.$_getSystemData('root_users')?.configValue.split(',') || [];
      return rootUsers.includes(this.$store.getters.getLoginId);
    }
  },
  mounted() {
    if(!this.isRootUser()) {
      this.dataGrid.showActionButtons.customButtons.push(
          {
            widget: 'dxButton',
            options: {
              icon: 'upload',
              width: '40px',
              height: '30px',
              elementAttr: {
                class: 'mr-1 white light_filled dx-shape-standard',
              },
              onClick: () => {
                this.onUploadFile();
              },
            },
            location: 'before',
          },
      )
    }
  },
  created() {
    this.selectGroupDataList();
  }
}
</script>
<style scoped>
</style>