import DxButton from "devextreme-vue/button";

export const columns = (vm) => {
    return [
        {
            caption: '사번',
            dataField: 'userNo',
            allowEditing: false,
        },
        {
            caption: '이름',
            dataField: 'userNm',
            allowEditing: false,
        },
        {
            caption: '데이터',
            dataField: '',
            allowEditing: false,
            cellTemplate: (container, options) => {
                const button = new DxButton({
                    propsData: {
                        text: vm.$_lang('COMPONENTS.DETAIL', {defaultValue: '상세보기'}),
                        elementAttr: {class: 'btn_XS default filled'},
                        width: 100,
                        height: 30,
                        onClick: () => {
                            vm.onOpenModal(
                                'ModalDetailData',
                                {
                                    title: '상세보기',
                                    width: '80vw',
                                    height: 'calc(100vh - 100px)',
                                },
                                options.data,
                            );
                        }
                    }
                });

                button.$mount();
                container.append(button.$el);
            },
        },
    ]
}