import SurveyConfig from '@/pages/ewm/surveys/implementation/config.vue';
import SurveyDetail from '@/pages/ewm/surveys/implementation/detail.vue';
import SurveyList from '@/pages/ewm/surveys/implementation/list.vue';

export default function SurveyRoutes(commonComponents) {
  return [
    // Surveys -> Implementation
    {
      path: '/ewm/surveys/implementation/config',
      name: 'SurveyConfig',
      components: { default: SurveyConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/surveys/implementation/detail',
      name: 'SurveyDetail',
      components: { default: SurveyDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/surveys/implementation/list',
      name: 'SurveyList',
      components: { default: SurveyList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}