import BiRoutes from '@/router/report/bi';
import DataReportRoutes from '@/router/report/data-report';
import DashboardRoutes from '@/router/report/dashboard';
import ConfigRoutes from '@/router/report/config';

export default function ReportRoutes(commonComponents) {
  return [
    ...BiRoutes(commonComponents),
    ...DataReportRoutes(commonComponents),
    ...DashboardRoutes(commonComponents),
    ...ConfigRoutes(commonComponents),
  ];
}
