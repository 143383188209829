<!--
  PACKAGE_NAME : src\pages\cc\skl\group
  FILE_NAME : modal-skl-group
  AUTHOR : hpmoon
  DATE : 2025-03-21
  DESCRIPTION : 운영관리 > CIT > 스킬그룹 관리 > 스킬그룹 등록/수정
-->
<template>
  <dx-popup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :max-height="modal.maxHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="handleCloseModal"
  >
    <template #content>
      <dx-scroll-view>
        <div class="page-sub-box">
          <table class="table_form line-bin">
            <colgroup>
              <col style="width: 130px" />
              <col style="width: auto" />
            </colgroup>

            <tbody>
              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('', { defaultValue: '사이트/센터' }) }} <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <dx-select-box
                    v-model="formData.siteId"
                    :placeholder="$_lang('COMMON.WORD.SITE', {defaultValue: '사이트'})"
                    :items="$store.getters.getSiteList"
                    display-expr="siteNm"
                    value-expr="id"
                    :show-clear-button="config.showClearButton"
                    :styling-mode="config.stylingMode"
                    :width="150"
                  >
                    <dx-validator validation-group="validationGroupName">
                      <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                    { value: $_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}), defaultValue: '[사이트] 은/는 필수값 입니다' })" />
                    </dx-validator>
                  </dx-select-box>

                  <dx-select-box
                    v-model="formData.tenantId"
                    :placeholder="$_lang('COMMON.WORD.TENANT', {defaultValue: '센터'})"
                    :items="$store.getters.getTenantList"
                    display-expr="tenantNm"
                    value-expr="id"
                    :show-clear-button="config.showClearButton"
                    :styling-mode="config.stylingMode"
                    :width="150"
                  >
                    <dx-validator validation-group="validationGroupName">
                      <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                    { value: $_lang('COMMON.WORD.TENANT', {defaultValue: '센터'}), defaultValue: '[센터] 은/는 필수값 입니다' })" />
                    </dx-validator>
                  </dx-select-box>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('', { defaultValue: '스킬그룹명' }) }} <span class="icon_require">필수항목</span></label>
                </th>
                <td>
                  <dx-text-box
                    v-model="formData.sklGroupNm"
                    :max-length="limitNumberTexts.maxLengths.sklGroupNm"
                    :styling-mode="config.stylingMode"
                    class="mar_ri10"
                    :width="300"
                  >
                    <dx-validator validation-group="validationGroupName">
                      <dx-async-rule :reevaluate="false" :validationCallback="validateSklGroupNm"
                                   :message="$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                                 { value: $_lang('', { defaultValue: '스킬그룹명' }), defaultValue: '[스킬그룹명] 값은 중복될 수 없습니다.' })"
                      />
                      <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: $_lang('', {defaultValue: '스킬그룹명'}), defaultValue: '[스킬그룹명] 은/는 필수값 입니다' })"
                      />
                    </dx-validator>
                  </dx-text-box>
                  <span>
                    {{
                      limitNumberTexts.textLengths.sklGroupNm
                        ? limitNumberTexts.textLengths.sklGroupNm
                        : formData.sklGroupNm
                          ? formData.sklGroupNm.length
                          : 0
                    }}
                  </span>/{{ limitNumberTexts.maxLengths.sklGroupNm }}
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('', { defaultValue: '순서' }) }}</label>
                </th>
                <td>
                  <dx-number-box
                    v-model="formData.sklGroupOrd"
                    :min="1"
                    :show-spin-buttons="true"
                    validation-message-position="right"
                    class="mar_ri10"
                    :width="200"
                    :styling-mode="config.stylingMode"
                    format="#"
                  >
                    <dx-validator validation-group="validationGroupName">
                      <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                    { value: $_lang('', {defaultValue: '순서'}), defaultValue: '[순서] 은/는 필수값 입니다' })" />
                    </dx-validator>
                  </dx-number-box>
                </td>
              </tr>

              <tr>
                <th scope="row">
                  <label for="label5">{{ $_lang('', { defaultValue: '사용여부' }) }}</label>
                </th>
                <td>
                  <dx-switch @value-changed="handleChangedViewFl($event)" :value="getViewFl" class="mar_ri10" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </dx-scroll-view>
    </template>

    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.saveType === 'add'
              ? this.$_lang('COMPONENTS.REGISTER', { defaultValue: '등록' })
              : this.$_lang('COMPONENTS.EDIT', { defaultValue: '수정' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            validationGroup: 'validationGroupName',
            onClick: saveModal,
          }"
    />
    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: handleCloseModal,
          }"
    />
  </dx-popup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
  import { DxScrollView } from "devextreme-vue/scroll-view";
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxNumberBox } from "devextreme-vue/number-box";
  import DxSwitch from "devextreme-vue/switch";
  import { DxValidator, DxRequiredRule, DxAsyncRule } from 'devextreme-vue/validator';
  import { isSuccess, isEmpty } from "@/utils/common-lib";


  export default {
    components: {
      DxPopup,
      DxToolbarItem,
      DxScrollView,
      DxSelectBox,
      DxTextBox,
      DxNumberBox,
      DxSwitch,
      DxValidator,
      DxRequiredRule,
      DxAsyncRule,
    },

    props: {
      isOpen: Boolean,
      saveType: String,
      contentData: Object,
    },

    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },

    data() {
      return {
        modal: {
          title: this.saveType === 'add'
            ? this.$_lang('', { defaultValue: '스킬그룹 등록' })
            : this.$_lang('', { defaultValue: '스킬그룹 수정' }),
          minWidth: '600',
          width: '600',
          maxHeight: '100vh',
          height: '350',
          dragEnabled: true,
          resizeEnabled: true,
          closeOnOutsideClick: false,
          showCloseButton: true,
        },

        config: {
          stylingMode: 'outlined' //[outlined, filled, underlined]
        },

        formData: {
          id: null,         // ID
          siteId: null,     // 사이트
          tenantId: null,   // 센터
          sklGroupNm: null, // 스킬그룹명
          sklCnt: null,     // 스킬 개수
          sklGroupOrd: null,       // 순서
          viewFl: 'Y',     // 사용여부
        },

        limitNumberTexts: {
          textLengths: {
            sklGroupNm: 0
          },
          maxLengths: {
            sklGroupNm: 30,
          },
        },
      };
    },

    computed: {
      /** @description: 사용여부 */
      getViewFl() {
        return this.formData.viewFl === 'Y';
      },
    },

    methods: {
      /** @description 모달 저장 */
      async saveModal(e) {
        if (!e.validationGroup.validate().isValid) {
          e.validationGroup.validate().validators.forEach((validator) => {
            validator.$element().addClass("dx-state-focused");
          });
          return;
        }

        if (!await this.validateSklGroupNm(e)) return false;

        const payload = {
          actionName: 'CC_SKL_GROUP_MERGE',
          data: [this.formData],
          loading: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$emit('saveModal');
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 스킬그룹명 중복 체크 메서드 */
      async validateSklGroupNm(e) {
        if(!isEmpty(this.contentData.sklGroupNm) && this.contentData.sklGroupNm === this.formData.sklGroupNm) {
          return true;
        }

        let payload = {
          actionName: 'CC_SKL_GROUP_LIST',
          data: {
            sklGroupNm: this.formData.sklGroupNm
          },
          loading: false,
        }
        let res = await this.CALL_CC_API(payload);
        if (isSuccess) {
          if (res.data.data.length !== 0) {
            if (e.validationGroup) e.validationGroup.validate().validators[0].$element().addClass("dx-state-focused");
            if (e.validator) e.validator?.$element().addClass("dx-state-focused");
          }
          return res.data.data.length === 0;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description: 사용여부 이벤트 */
      handleChangedViewFl(e) {
        if (e.value) this.formData.viewFl = 'Y';
        else this.formData.viewFl = 'N';
      },

      /** @description 모달 닫기 */
      handleCloseModal() {
        this.$emit('closeModal');
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.formData = JSON.parse(JSON.stringify({ ...this.formData, ...this.contentData }));
    },
  };
</script>

<style lang="scss" scoped>
  .page-sub-box {
    padding: 0 !important;
  }
</style>
