<template>
	<transition>
		<div class="grid-box">
			<esp-dx-data-grid :data-grid="dataGrid" ref="chooseSheet"></esp-dx-data-grid>
		</div>
	</transition>
</template>

<script>
import {cloneObj, isSuccess} from '@/utils/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
	components: {EspDxDataGrid},
	props: {},
	watch: {},
	data() {
		return {
			codes: {
				examSheetType: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
			},
			dataGrid: {
				keyExpr: 'id',
				refName: 'chooseSheet',
				showMenuColumns: true, //컬럼 표시
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false, //행 배경색
				hoverStateEnabled: true, //마우스커서 행 강조
				dataSource: [],
				callApi: 'CALL_EWM_API',
				// width:'200',     // 주석처리시 100%
				height: '450',
				showActionButtons: {
					// 그리드 버튼 노출 설정값
					select: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				sorting: {
					mode: 'multiple', // single multiple
				},
				scrolling: {
					// 미사용시 주석처리
					mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
				},
				remoteOperations: {
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: false,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: false, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: false,
					allowDeleting: false,
					allowAdding: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '시험타입',
						dataField: 'examSheetType',
						alignment: 'center',
						visible: true,
						width: 150,
						allowEditing: true,
						allowSorting: false,
						allowHeaderFiltering: true,
						lookup: {
							dataSource: this.$_enums.ewm.examSheetType.values,
							displayExpr: 'label',
							valueExpr: 'value',
						},
					},
					{
						caption: '시험지명',
						dataField: 'examNm',
						alignment: 'center',
						visible: true,
						allowEditing: true,
						allowSorting: false,
						allowHeaderFiltering: false,
					},
					{
						caption: '문항수',
						dataField: 'sheetRegCnt',
						alignment: 'center',
						visible: true,
						width: 110,
						allowEditing: true,
						allowSorting: false,
						allowHeaderFiltering: false,
					},
					{
						caption: '총점',
						dataField: 'totalScore',
						alignment: 'center',
						visible: true,
						width: 110,
						allowEditing: true,
						allowSorting: false,
						allowHeaderFiltering: false,
					},
					{
						caption: '출제횟수',
						dataField: 'scheCnt',
						alignment: 'center',
						visible: true,
						width: 110,
						allowEditing: true,
						allowSorting: false,
						allowHeaderFiltering: false,
					},
				],
			},
		};
	},
	computed: {},
	methods: {
		/** @description: 데이터 조회 메서드 */
		selectDataList(sort = '-id') {
			const vm = this;
			this.dataGrid.dataSource = new CustomStore({
				key: 'id',
				async load(loadOptions) {
					let params = vm.$_getDxDataGridParam(loadOptions);
					if (!params.sort) params.sort = sort;

					let payload = {
						actionName: 'EWM_EXAM_SHEET_LIST',
						data: params,
						loading: false,
						useErrorPopup: true,
					};
					const res = await vm.CALL_EWM_API(payload);
					if (isSuccess(res)) {
						let data = res.data.data.filter(f => f.sheetRegCnt > 0);
						const rtnData = {
							data: data,
							totalCount: data.length,
						};
						vm.$refs.chooseSheet.totalCount = rtnData.totalCount;
						return rtnData;
					} else {
						return () => {
							throw 'Data Loading Error';
						};
					}
				},
			});
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.$watch(
			() => {
				return this.$refs.chooseSheet.selectedRowsData;
			},
			val => {
				val = cloneObj(val);
				this.$emit('input', { val });
			},
		);
	},
};
</script>
