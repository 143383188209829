<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : running-test-work
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 실험 설정 > 실험 추가
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div>
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.TEST_NAME', { defaultValue: '실험 이름' }) }} <span class="icon_require">필수항목</span></label>
            </th>
            <td>
              <DxTextBox
                v-model="formData.name"
                :max-length="limitNumberTexts.maxLengths.name"
                :styling-mode="config.stylingMode"
                class="mar_ri10"
                :width="200"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxAsyncRule :validation-callback="checkValidName"
                               :message="$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                               { value: $_lang('LLM_TESTER.WORD.TEST_NAME', { defaultValue: '실험 이름' }), defaultValue: '[실험 이름] 값은 중복될 수 없습니다.' })" />
                  <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                { value: $_lang('LLM_TESTER.WORD.TEST_NAME', { defaultValue: '실험 이름' }), defaultValue: '[실험 이름] 은/는 필수값 입니다' })" />
                </DxValidator>
              </DxTextBox>
              <span>
              {{
                  limitNumberTexts.textLengths.name
                    ? limitNumberTexts.textLengths.name
                    : formData.name
                      ? formData.name.length
                      : 0
                }}
            </span>/{{ limitNumberTexts.maxLengths.name }}
            </td>
          </tr>

          <tr>
            <td colspan="2" style="text-align: center">
              {{ $_lang('LLM_TESTER.MESSAGE.RUNNING_TEST_ALERT1', { defaultValue: '실험 진행에는 다소 시간이 걸릴 수 있습니다.' }) }}
              <br>
              {{ $_lang('LLM_TESTER.MESSAGE.RUNNING_TEST_ALERT2', { defaultValue: '실험을 추가 하시겠습니까?' }) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.$_lang('COMPONENTS.ADD', { defaultValue: '추가' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            validationGroup: 'validationGroupName',
            onClick: runningTest,
          }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: closeModal,
          }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxValidator, DxRequiredRule, DxAsyncRule } from 'devextreme-vue/validator';

  export default {
    components: {
      DxAsyncRule,
      DxPopup,
      DxToolbarItem,
      DxTextBox,
      DxValidator,
      DxRequiredRule,
    },

    props: {
      isOpen: Boolean,
      project_id: String,
      system_prompt_id: String,
      evaluation_prompt_id: String,
    },

    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },

    data() {
      return {
        modal: {
          title: this.$_lang('LLM_TESTER.WORD.ADD_TEST', { defaultValue: '실험 추가' }),
          minWidth: '500',
          width: '500',
          minHeight: '250',
          height: '250',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: false,
        },

        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },

        formData: {
          project_id: null,
          name: null,
          system_prompt_id: null,
          evaluation_prompt_id: null,
        },

        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            name: 30,
          },
        },
      };
    },

    computed: {},

    methods: {
      /** @description 실험 추가 */
      async runningTest(e) {
        if (!e.validationGroup.validate().isValid) {
          e.validationGroup.validate().validators.forEach((validator) => {
            validator.$element().addClass("dx-state-focused");
          });
          return;
        }

        if (!await this.checkValidName(e)) return false;

        let payload = {
          actionName: 'LLM_TESTER_JOB_RUNNING',
          data: this.formData,
          loading: true,
        };

        let res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          await this.$router.push({ path: '/ai/llm-tester/test-work/list', query: { id: this.project_id } });
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 실험 이름 중복 체크 메서드 */
      async checkValidName(e) {
        let payload = {
          actionName: 'LLM_TESTER_JOB_EXIST',
          data: [{
            project_id: this.formData.project_id,
            name: this.formData.name,
          }],
          loading: false,
        }
        let res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          if (res.data) {
            if (e.validationGroup) e.validationGroup.validate().validators[0].$element().addClass("dx-state-focused");
            if (e.validator) e.validator?.$element().addClass("dx-state-focused");
          }
          return !res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.formData.project_id = this.project_id;
      this.formData.system_prompt_id = this.system_prompt_id;
      this.formData.evaluation_prompt_id = this.evaluation_prompt_id;
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },
  };
</script>

<style lang="scss" scoped>
</style>