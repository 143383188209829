import EditDashboard from '@/pages/report/dashboard/edit.vue';
import DashboardManage from '@/pages/report/dashboard/manage';
import Dashboard from '@/pages/report/dashboard/index';

export default function DashboardRoutes(commonComponents) {
  return [
    {
      path: '/dashboard/:menuId',
      name: 'Dashboard',
      components: { default: Dashboard, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/dashboard/manage',
      name: 'DashboardManage',
      components: { default: DashboardManage, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/dashboard/edit/:templateId',
      name: 'EditDashboard',
      components: { default: EditDashboard, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
  ];
}
