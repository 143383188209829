import InfoPushSite from '@/pages/euc/info-push/site.vue';

export default function InfoPushSiteRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/site',
            name: 'InfoPushSite',
            components: { default: InfoPushSite, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}