import AlarmConfig from '@/pages/esp/system/alarm/config.vue';
import CodeList from '@/pages/esp/system/code/list.vue';
import HolidayList from '@/pages/esp/system/holiday/list.vue';
import MenuList from '@/pages/esp/system/menu/list.vue';
import AllowedIPList from '@/pages/esp/system/security/allowed-ip.vue';
import ServerRoutingList from '@/pages/esp/system/server/routing.vue';
import SystemSettingList from '@/pages/esp/system/setting/list.vue';
import SystemSettingOperationList from '@/pages/esp/system/setting/operation.vue';
import SiteList from '@/pages/esp/system/site/list.vue';
import TenantList from '@/pages/esp/system/tenant/list.vue';
import ThemeConfig from '@/pages/esp/system/theme/config.vue';

export default function SystemRoutes(commonComponents) {
  return [
    {
      path: '/esp/system/alarm/config',
      name: 'AlarmConfig',
      components: { default: AlarmConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/code/list',
      name: 'CodeList',
      components: { default: CodeList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/holiday/list',
      name: 'HolidayList',
      components: { default: HolidayList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/menu/list',
      name: 'MenuList',
      components: { default: MenuList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/security/allowed-ip',
      name: 'AllowedIPList',
      components: { default: AllowedIPList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/server/routing',
      name: 'ServerRoutingList',
      components: { default: ServerRoutingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/setting/list',
      name: 'SystemSettingList',
      components: { default: SystemSettingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/setting/operation',
      name: 'SystemSettingOperationList',
      components: { default: SystemSettingOperationList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/site/list',
      name: 'SiteList',
      components: { default: SiteList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/tenant/list',
      name: 'TenantList',
      components: { default: TenantList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/theme/config',
      name: 'ThemeConfig',
      components: { default: ThemeConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
