<!--
  PACKAGE_NAME : src/pages/cc/cti/team-category/modal
  FILE_NAME : modal-team-category.vue
  AUTHOR : hmlee
  DATE : 2024-07-02
  DESCRIPTION : 1차 or 2차 그룹 등록 모달 컴포넌트
-->
<template>
  <esp-dx-modal-popup
    :option="{
      title: modal.title,
      width: modal.width,
      height: modal.height,
      minWidth: modal.minWidth,
      minHeight: modal.minHeight,
      closeOnOutsideClick: modal.closeOnOutsideClick,
      validationGroup: modal.validationGroup,
      useSaveBtnDisabled: modal.useSaveBtnDisabled,
    }"
    :isOpen="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <table class="table_form line-bin">
        <caption>
          <strong>{{ $_lang('COMPONENTS.GROUP', {defaultValue: '그룹'}) }}</strong>
        </caption>
        <colgroup>
          <col style="width:110px;">
          <col style="width:auto;">
        </colgroup>

        <tbody>
        <tr v-if="formData.depth === 1">
          <th scope="row">
            <label for="label5">
              {{ $_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}) }}
              <span class="icon_require"></span>
            </label>
          </th>
          <td>
            <dx-select-box
              :placeholder="config.selectBox.placeholder"
              :show-clear-button="config.selectBox.showClearButton"
              :styling-mode="config.stylingMode"
              :width="config.selectBox.width"
              :items="$store.getters.getSiteList"
              display-expr="siteNm"
              value-expr="id"
              :value="formData.siteId"
              class="w-full"
            >
              <dx-validator :validation-group="modal.validationGroup">
                <dx-required-rule
                  :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '사이트' })"
                />
              </dx-validator>
            </dx-select-box>
          </td>
        </tr>
        <tr v-else>
          <th scope="row">
            <label for="label5">
              {{ $_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}) }}
            </label>
          </th>
          <td>
            {{ getSiteNm }}
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label5">
              {{ formData.depth }}
              {{
                $_lang('CC.WORD.TH_GROUP_NAME', {defaultValue: '차 그룹명'})
              }}
              <span class="icon_require"></span>
            </label>
          </th>
          <td>
            <dx-text-box
              v-model="formData.ctgNm"
              :max-length="limitNumberTexts.maxLengths.ctgNm"
              :styling-mode="config.stylingMode"
              class="mar_ri10"
              :width="250"
              @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'ctgNm')"
            >
              <dx-validator :validation-group="modal.validationGroup">
                <dx-async-rule
                  :reevaluate="false"
                  :validationCallback="checkDuplicateCtgNm"
                  :message="$_lang('CC.MESSAGE.DUPLICATE_NAME', { defaultValue: '이미 등록되어 있는 명칭입니다.'})"
                />
                <DxRequiredRule
                  :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '그룹명' })"
                />
              </dx-validator>
            </dx-text-box>
            {{
              limitNumberTexts.textLengths.ctgNm ? limitNumberTexts.textLengths.ctgNm : (formData.ctgNm ? formData.ctgNm.length : 0)
            }}/{{ limitNumberTexts.maxLengths.ctgNm }}자
          </td>
        </tr>
        <tr>
          <th scope="row">
            <label for="label3">{{ $_lang('COMPONENTS.USE_STATUS', {defaultValue: '사용여부'}) }}</label>
          </th>
          <td>
            <dx-switch @value-changed="handleChangedViewFl($event)" :value="getViewFl"/>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxValidator, DxRequiredRule, DxAsyncRule} from 'devextreme-vue/validator';
import {DxSelectBox} from "devextreme-vue/select-box";
import DxSwitch from "devextreme-vue/switch";

import {isSuccess} from "@/utils/common-lib";

export default {
  components: {
    EspDxModalPopup,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxAsyncRule,
    DxSelectBox,
    DxSwitch,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    categoryData: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      modal: {
        title: this.getModalTitle(),
        minWidth: '300',
        width: '510',
        minHeight: '300',
        height: '300',
        closeOnOutsideClick: false,
        validationGroup: 'validationGroupModal',
        useSaveBtnDisabled: false, // 저장 중복 클릭 방지
      },
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        selectBox: {
          placeholder: `${ this.$_lang('COMMON.WORD.SITE', { defaultValue: '사이트'}) }
                        ${ this.$_lang('COMPONENTS.WORD.SELECT', { defaultValue: '선택'}) }`,
          showClearButton: true,
          width: 250,
        },
      },
      formData: {
        id: null,
        parentId: null,
        siteId: null,
        ctgNm: null,
        depth: null,
        viewFl: 'Y',
        agtteamCtgOrd: null,
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          ctgNm: 20,
        },
      },
    }
  },
  computed: {
    /** @description: 사용여부 */
    getViewFl() {
      return this.formData.viewFl === 'Y';
    },
    /** @description: 사이트명 조회 */
    getSiteNm() {
      return this.$store.getters.getSiteList?.find(d => d.id === this.formData.siteId)?.siteNm;
    }
  },
  methods: {
    /** @description: 모달 타이틀 */
    getModalTitle() {
      if (this.formData?.depth === 1) {
        return this.$_lang('CC.WORD.REGISTER_1ST_GROUP', {defaultValue: '1차 그룹 등록'});
      } else {
        return this.$_lang('CC.WORD.REGISTER_2ST_GROUP', {defaultValue: '2차 그룹 등록'});
      }
    },
    /** @description: 모달 저장 */
    async handleSaveModal(e) {
      //저장 중복 클릭 방지 체크
      if (this.modal.useSaveBtnDisabled) {
        return false;
      }
      this.modal.useSaveBtnDisabled = true; // 저장 중복 클릭 방지 설정

      const payload = {
        actionName: 'CC_AGTTEAM_CATEGORY_MERGE',
        data: [this.formData],
      }

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.$emit('saveModal');
        this.modal.useSaveBtnDisabled = false; // 저장 중복 클릭 방지 해제
      }
    },
    /** @description: 모달 닫기 */
    handleCloseModal() {
      this.$emit('closeModal');
    },
    /** @description : 카테고리명 중복 체크 */
    async checkDuplicateCtgNm(e) {
      const params = {ctgNm: e.value, parentId: this.formData.parentId};
      const payload = {
        actionName: 'CC_AGTTEAM_CATEGORY_LIST',
        data: params,
      }

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res) && res.data.data.length > 0) { //그룹명 중복 데이터가 존재하면
        return false;
      } else {
        return true;
      }
    },
    /** @description: 사용여부 이벤트 */
    handleChangedViewFl(e) {
      if (e.value) {
        this.formData.viewFl = 'Y';
      } else {
        this.formData.viewFl = 'N';
      }
    },
    /** @description: 데이터 초기화 */
    initData(){
      // 초기값 설정
      const initFormData = {
        id: null,
        parentId: -1,
        siteId: this.$store.getters.getSiteList?.[0]?.id || '',
        ctgNm: '',
        depth: 1,
        viewFl: 'Y',
        agtteamCtgOrd: 0,
      };

      this.formData = {
        ...(this.categoryData || initFormData),
        viewFl: 'Y',
        siteId: this.$store.getters.getSiteList?.[0]?.id || '',
      };
    },
  },
  created() {
    this.initData();
  },
}
</script>
