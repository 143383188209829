<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/policy
  FILE_NAME : inception.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="blockGrid"/>
    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="blockModalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isOpened="modal.isOpened"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: $_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
						width: '120',
						height: '40',
						onClick: () => onModalSave()
					}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => isOpenModal(false)
					}"
      />
    </DxPopup>
  </div>
</template>
<script>
import {isSuccess} from "@/utils/common-lib";
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";
import ModalBlock from './modal-block.vue';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    DxPopup,
    DxToolbarItem,
    ModalBlock,
    EspDxDataGrid
  },
  props: {
    deptList: {
      type: Array,
      default: () => []
    },
    userList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        sendData: null,
      },
      dataGrid: {
        callApi: 'CALL_EUC_API',
        disableTotalCount: true,
        excel: {
          title: this.$_lang('UC.WORD.BLOCKING_POLICY', {defaultValue: '차단 정책'})
        },
        refName: 'blockGrid',
        dataSourceDefaultSort: '-regDt',
        apiActionNm: {
          select: 'EUC_BLOCK_POLICY_SELECT',
          delete: 'EUC_BLOCK_POLICY_DELETE'
        },
        showActionButtons: {
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '추가',
                elementAttr: {class: 'btn_XS default filled add1'},
                width: 60,
                height: 30,
                onClick: () => {
                  this.$refs.blockGrid.getInstance.clearSelection();
                  const title = '차단정책설정 추가';
                  const sendData = {
                    userList: this.userList,
                    deptList: this.deptList
                  }
                  this.onOpenModal(
                      'ModalBlock',
                      {
                        title,
                        width: '50vw',
                        height: 'calc(100vh - 400px)',
                        buttons: {
                          save: {text: this.$_lang('COMPONENTS.SAVE', {defaultValue: '저장'})},
                          cancel: {text: this.$_lang('COMPONENTS.CLOSE', {defaultValue: '닫기'})},
                        },
                      },
                      sendData
                  );
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '변경',
                elementAttr: {class: 'btn_XS default filled'},
                width: 60,
                height: 30,
                onClick: () => {
                  const selectedRowsData = this.$refs.blockGrid.getInstance.getSelectedRowsData();
                  if (!selectedRowsData?.length) {
                    this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
                  } else if (selectedRowsData.length > 1) {
                    this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT', {defaultValue: '하나의 대상만 선택해 주세요.'}));
                  } else {
                    const title = '차단정책설정 변경';
                    const sendData = {
                      userList: this.userList,
                      deptList: this.deptList,
                      selectedData: selectedRowsData[0]
                    }
                    this.onOpenModal(
                        'ModalBlock',
                        {
                          title,
                          width: '50vw',
                          height: 'calc(100vh - 400px)',
                          buttons: {
                            save: {text: this.$_lang('COMPONENTS.SAVE', {defaultValue: '저장'})},
                            cancel: {text: this.$_lang('COMPONENTS.CLOSE', {defaultValue: '닫기'})},
                          },
                        },
                        sendData
                    );
                  }
                },
              },
              location: 'before',
            },
          ],
        },
        page: {
          enabled: false,
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '그룹명',
            i18n: 'COMPONENTS.GROUP_NAME',
            dataField: 'policyNm',
            allowEditing: false,
          },
          {
            caption: '구분',
            i18n: 'COMPONENTS.TYPE',
            dataField: 'policyGb',
            allowEditing: false,
            lookup: {
              dataSource: [
                {
                  label: '허용',
                  value: 'ALLOW'
                },
                {
                  label: '차단',
                  value: 'INCEP'
                },
              ],
              displayExpr: 'label',
              valueExpr: 'value'
            }
          },
          {
            multiHeaderNm: '적용대상',
            i18n: 'UC.WORD.APPLY_TARGET',
            columns: [
              {
                caption: '부서',
                i18n: 'COMPONENTS.DEPT',
                dataField: 'targetDept',
                allowEditing: false,
                cellTemplate: (container, options) => {
                  const textElement = document.createElement('span');
                  textElement.textContent = options.value;
                  textElement.style.minHeight = '100px';
                  textElement.style.whiteSpace = 'normal';
                  textElement.style.wordBreak = 'break-word';
                  textElement.style.overflowWrap = 'break-word';

                  container.appendChild(textElement);
                }
              },
              {
                caption: '개인',
                i18n: 'COMPONENTS.INDIVIDUAL',
                dataField: 'targetUser',
                allowEditing: false,
                cellTemplate: (container, options) => {
                  const textElement = document.createElement('span');
                  textElement.textContent = options.value;
                  textElement.style.minHeight = '100px';
                  textElement.style.whiteSpace = 'normal';
                  textElement.style.wordBreak = 'break-word';
                  textElement.style.overflowWrap = 'break-word';

                  container.appendChild(textElement);
                }
              },
            ]
          }
        ],
      },
    }
  },
  methods: {
    async onModalSave() {
      const policyUserList = this.$refs.blockModalRef.plicyUserList;
      const policyDeptList = this.$refs.blockModalRef.plicyDeptList;
      const selectedRowsData = this.$refs.blockGrid.getInstance.getSelectedRowsData();
      const params = {
        policyNm: this.$refs.blockModalRef.policyNm,
        policyGb: this.$refs.blockModalRef.policyGb,
        blockDetailPolicyTempList: [...policyUserList, ...policyDeptList]
      }

      if (selectedRowsData?.length) {
        const selectedRowData = selectedRowsData[0];
        params.id = selectedRowData.id;
        params.serno = selectedRowData.serno;
      }

      const payload = {
        actionName: 'EUC_BLOCK_POLICY_UPDATE',
        data: params,
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', {defaultValue: '정상적으로 처리되었습니다.'}), {icon: 'success'});
        this.isOpenModal(false);
        this.$refs.blockGrid.getInstance.refresh();
      } else {
        this.$_Msg(res.data.header?.resMsg || this.$_lang('COMMON.MESSAGE.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}), {icon: 'error'});
      }

    },
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      if (!bool) {
        this.$refs.blockModalRef.clearForm();
      }
      this.modal.isOpened = bool;
    },
  },
}
</script>
<style scoped>

</style>