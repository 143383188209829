import CiscoDevice from '@/pages/euc/device/cisco/index.vue';
import CiscoDeviceDetail from '@/pages/euc/device/cisco/detail.vue';
import CiscoSuperCopy from '@/pages/euc/device/cisco/supercopy.vue';

export default function CiscoRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/cisco',
            name: 'CiscoDevice',
            components: { default: CiscoDevice, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/device/cisco/detail',
            name: 'CiscoDeviceDetail',
            components: { default: CiscoDeviceDetail, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/device/cisco/supercopy',
            name: 'CiscoSuperCopy',
            components: { default: CiscoSuperCopy, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}