<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_infopush_text"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_infopush_text')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes.siteId"
            placeholder="사이트 선택"
            :items="getSiteList"
            display-expr="siteNm"
            value-expr="siteId"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('siteId', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="infoPushTextGrid" @init-new-row="onInitNewRow" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;
  //
  export default {
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    props: {
      isModal: Boolean,
    },
    data() {
      return {
        infoPushSiteList: [],
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {
            siteNm: '',
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            siteId: null,
          },
          paramsData: null,
        },
        dataGrid: {
          refName: 'infoPushTextGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          apiActionNm: {
            select: 'INFOPUSH_TEXT_LIST_ALL',
            update: 'INFOPUSH_TEXT_UPDATE',
            delete: 'INFOPUSH_TEXT_DELETE',
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true,
          },
          showActionButtons: {
            delete: true,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트',
              dataField: 'siteId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              editorType: 'dxSelectBox',
              cellTemplate: (container, options) => {
                const selectbox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.infoPushSiteList,
                    displayExpr: 'siteNm',
                    valueExpr: 'siteId',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    disabled: this.isModal,
                    onValueChanged: e => {
                      this.$refs.infoPushTextGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectbox.$mount();
                container.append(selectbox.$el);
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '제목',
              dataField: 'textTitle',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '기본값',
              dataField: 'textValue',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '질의문 필드',
              dataField: 'query',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: this.$_getCode('infopush_text'),
                displayExpr: 'codeNm',
                valueExpr: 'codeValue',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '타이틀',
              dataField: 'jsonkeyCd',
              width: 150,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: this.$_getCode('infopush_jsonkey'),
                displayExpr: 'codeNm',
                valueExpr: 'codeId',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '순서',
              dataField: 'textOrd',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'asc',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '사용 여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              }
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getSiteList() {
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        if (params?.siteId) {
          this.setCustomTypes('siteId', params.siteId);
        } else {
          this.setCustomTypes('siteId', null);
        }

        return [{ siteId: null, site: '전체', siteNm: '전체' }, ...this.infoPushSiteList];
      },
    },
    methods: {
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      getSelectedTextData() {
        return this.$refs.infoPushTextGrid.selectedRowsData[0];
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'INFOPUSH_TEXT_LIST_ALL',
              data: {
                params,
              },
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header?.totalCount;
            }
            return rtnData;
          },
        });
      },
      async getInfoPushSiteList() {
        const payload = {
          actionName: 'INFOPUSH_SITE_LIST_ALL',
          data: {
            params: {
              viewFl: this.$_enums.common.stringUsedFlag.YES.value,
              sort: '+siteOrd',
            },
          },
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          if (res.data.data.length) {
            this.infoPushSiteList = res.data.data;
          } else {
            this.infoPushSiteList = [];
          }
        }
      },
      changeDataGrid() {
        this.dataGrid.showActionButtons.delete = false;
        this.dataGrid.editing.allowUpdating = false;
        this.dataGrid.editing.allowAdding = false;
        this.dataGrid.selecting.mode = 'single';
        this.dataGrid.columns.forEach(column => (column.allowEditing = false));
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.getInfoPushSiteList();
        vm = this;
        if (this.isModal) {
          // modal일 경우 편집 기능 없애기 위함
          this.changeDataGrid();
        }
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
