<template>
  <div>
    <table class="table_form line-bin fl" style="width: 100%">
      <thead style="border-top: 1px solid; border-bottom: 1px solid; border-color: rgba(229, 231, 235)">
      <tr>
        <th>{{ $_lang('UC.WORD.EXCHANGER') }}</th>
        <td>{{ getCmNm(this.deviceInfo.cmname) }}</td>
        <th>{{ $_lang('UC.WORD.DEVICE_NAME') }}</th>
        <td>{{ this.deviceInfo.name }}</td>
      </tr>
      </thead>
    </table>
    <table class="table_form line-bin">
      <tbody>
        <tr>
          <td class="lineGrid">
            <esp-dx-data-grid
                :data-grid="dataGrid"
                ref="lineGrid"
                :auto-width="true"
                :selected-row-keys="dataGrid.selectedRowKeys"
            ></esp-dx-data-grid>
          </td>
        </tr>
      </tbody>
    </table>
    <DxPopup
        :show-title="true"
        :title="modal.title"
        :width="modal.width"
        :height="modal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <ModalAddLine
          :isOpened="this.modal.isOpened"
          :cmList="this.cmList"
          :partitionList="this.partitionList"
          :cssList="this.cssList"
          :pickupGroupList="this.pickupGroupList"
          @closeModal="closeModal"
      ></ModalAddLine>
    </DxPopup>
  </div>
</template>
<script>
import { DxPopup } from 'devextreme-vue/popup';
import { isSuccess } from '@/utils/common-lib';
import ModalAddLine from '../line/modal-add-line.vue';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    DxPopup,
    ModalAddLine,
    EspDxDataGrid
  },
  props: {
    option: {
      default: () => {
        return {
          width: '50%',
          height: '85%',
          minWidth: null,
          minHeight: null,
          saveBtnVisible: false,
          cancelBtnVisible: true,
        };
      },
      type: Object,
    },

    deviceInfo: {
      type: Object,
    },
    rowInfo: {
      type: Object,
    },
    isOpened: {
      type: Boolean,
    },
    cmList: {
      type: Array,
    },
    partitionList: {
      type: Array,
    },
    cssList: {
      type: Array,
    },
    pickupGroupList: {
      type: Array,
    },
  },
  watch: {
    async isOpened() {
      if (this.isOpened) {
        await this.selectDataList();
        this.searchType.paramsData = {};
      } else {
        this.resetGridDataSource();
      }
    },
  },
  data() {
    return {
      modal: {
        isOpened: false,
        title: this.$_lang('UC.WORD.LINE_ADD'),
        width: '60%',
        height: '600',
      },
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'lineGrid',
        dataSourceDefaultSortColumn: '+regDt,+id',
        apiActionNm: {
          select: 'EUC_LINE_SELECT'
        },
        customEvent: {
          rowPrepared: true,
          contentReady: true,
        },
        showActionButtons: {
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.ADD'),
                elementAttr: { class: 'btn_XS default filled add1' },
                width: 80,
                height: 30,
                onClick: () => {
                  this.isOpenModal(true);
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('UC.WORD.LINE_SYNC'),
                elementAttr: { class: 'btn_XS default filled ' },
                width: 80,
                height: 30,
                onClick: () => {
                  this.onSyncLine();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('UC.WORD.APPLY'),
                elementAttr: { class: 'btn_XS default filled ' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.onSetLine();
                },
              },
              location: 'before',
            }
          ],
        },
        columns: [
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            allowEditing: false,
            calculateDisplayCellValue: rowData => this.getCmNm(rowData?.cmNm),
          },
          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'pattern',
            allowEditing: false,
          },
          {
            caption: '표시(경고이름)',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            allowEditing: false,
          },
          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            allowEditing: false,
          },
          {
            caption: '발신검색',
            i18n: 'UC.WORD.CSS',
            dataField: 'css',
            allowEditing: false,
          },
          {
            caption: '당겨받기',
            i18n: 'UC.WORD.PICKUPGROUP',
            dataField: 'callpickupgroupname',
            allowEditing: false,
          },
        ],
      },
      stylingMode: 'outlined', //outlined, underlined, filled
    };
  },
  methods: {
    /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
    setCustomTypes(key, value) {
      this.searchType.customTypes[key] = value;
    },
    /** @description 그리드 데이터 초기화 */
    resetGridDataSource() {
      this.dataGrid.dataSource = [];
      this.$refs.lineGrid.clearSelection();
    },
    /**@description : 라인 적용버튼 클릭 */
    async onSetLine() {
      const selectedRowCnt = this.$refs.lineGrid.selectedRowsData?.length;
      if (selectedRowCnt > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
      } else if (selectedRowCnt === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED'));
      } else if (selectedRowCnt === 1) {
        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE');
        if (!(await this.$_Confirm(confirm))) {
          return;
        }
        const lineData = this.$refs.lineGrid.selectedRowsData[0];
        this.$emit('closeLine', this.rowInfo, lineData);
      } else {
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER'));
      }
    },
    /**@description : 내선관리 모달 Open/Close */
    isOpenModal(bool) {
      this.modal.isOpened = bool;
    },
    /**@description : 내선관리 모달 close 콜백함수 */
    async closeModal(flag) {
      if (flag) {
        await this.isOpenModal(false);
        await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
        await this.selectDataList();
      }
    },
    /**
     * @description 전화기 전체 동기화
     */
    async onSyncLine() {
      const confirm = this.$_lang('UC.MESSAGE.START_SYNC');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }
      for (const item of this.cmList) {
        const payload = {
          actionName: 'EUC_LINE_SYNC',
          data: {
            cmNm: item.cmNm,
            loginId: this.$store.getters.getLoginId
          },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS'));
          await this.$refs.lineGrid.getGridInstance.refresh();
        } else {
          if (res.data.data?.length) {
            await this.$_Msg('교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg);
          } else {
            await this.$_Msg(res.data.header.resMsg);
          }
        }
      }
    },
    /** @description 교환기 이름 한글 변환 메서드
     *  @param {String} cm Name
     *  @returns {String} 한글명
     */
    getCmNm(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
  },
};
</script>