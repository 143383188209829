<!--
  PACKAGE_NAME : src/pages/cc/ivr/ment/modal-listen.vue
  FILE_NAME : modal-listen
  AUTHOR : hmlee
  DATE : 2025-03-14
  DESCRIPTION : 듣기 팝업
--> 
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight ? modal.minHeight : null"
    :height="modal.height ? modal.height : null"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
        <div v-if="error" class="error-message">{{ errorMessage }}</div>
        <audio ref="audioElement" v-else controls class="audio-player">
          <source :src="audioUrl" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
    </template>
  </DxPopup>
</template>

<script>

import {DxPopup} from "devextreme-vue/popup";

export default {
  components: {
    DxPopup,
  },

  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    mentId: {
      type: Number,
      required: true
    },
  },

  watch: {

  },
  computed: {
    /** @description: 모달 타이틀 출력 여부 */
    showTitle() {
      return !!this.modal.title;
    },
    /** @description: 음성 파일 경로 */
    getAudioSrc() {
      return '/cc/api/v1/ivr/ment/audio/download/10015'
      //return this.audioSrc;
    }
  },
  data() {
    return {
      modal: {
        title: this.$_lang('CC.WORD.MENT', { defaultValue: '멘트' })
          +' '+this.$_lang('COMPONENTS.LISTENING', { defaultValue: '듣기' }),
        minWidth: '300',
        width: '400',
        minHeight: '130',
        //height: '400',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
      },
      audioUrl: '',
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    /** @description: 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },
    /** @description : 오디오 재생 */
    async playAudio() {
      const payload = {
        actionName: 'CC_IVR_MENT_AUDIO_STREAM',
        data: { id: this.mentId },
        responseType: 'blob',
        useErrorPopup: false,
      };

      const res = await this.CALL_CC_API(payload);
      if (res.status === 200) {
        // 이전 Blob URL이 있으면 해제
        if (this.audioUrl) {
          URL.revokeObjectURL(this.audioUrl);
        }

        // Blob에서 URL 생성
        const blob = new Blob([res.data], {type: 'audio/wav'});
        this.audioUrl = URL.createObjectURL(blob);

        if (this.$refs.audioElement) {
          this.$refs.audioElement.load();
          this.$refs.audioElement.play();
        }
      } else {
        this.$_Msg(this.$_lang('CC.MESSAGE.FAIL_IVR_MENT_AUDIO_STREAM', { defaultValue: 'IVR 멘트 오디오 듣기 중 오류가 발생하였습니다.' }));
      }
    },
  },
  created() {},
  mounted() {
    this.playAudio();
  },
}
</script>

<style scoped>
.audio-player {
  display: block;
  width: 100%;
}
</style>