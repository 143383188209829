import DownloadFile from '@/pages/esp/history/download-file.vue';
import ExcelLookupHistory from '@/pages/esp/history/excel-lookup-history.vue';
import LoginHistory from '@/pages/esp/history/login.vue';
import WorkHistory from '@/pages/esp/history/work.vue';
import ExcelHistoryDetail from '@/pages/esp/history/excel-history-detail.vue';
import ExcelHistoryTargetDetail from "@/pages/esp/history/excel-history-target-detail.vue";

export default function HistoryRoutes(commonComponents) {
  return [
    {
      path: '/esp/history/download-file',
      name: 'DownloadFile',
      components: { default: DownloadFile, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/excel-lookup-history',
      name: 'ExcelLookupHistory',
      components: { default: ExcelLookupHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/login',
      name: 'LoginHistory',
      components: { default: LoginHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/work',
      name: 'WorkHistory',
      components: { default: WorkHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/excel-history-detail',
      name: 'ExcelHistoryDetail',
      components: { default: ExcelHistoryDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/excel-history-target-detail',
      name: 'ExcelHistoryTargetDetail',
      components: { default: ExcelHistoryTargetDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
