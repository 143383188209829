<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\playground
  FILE_NAME : query-result
  AUTHOR : hpmoon
  DATE : 2024-12-04
  DESCRIPTION : AI > LLM > Playground Chat > 질의 결과
-->
<template>
  <div class="page-sub-box right-box-back mar_ri11 mar_b20">
    <div class="query messages">
      <div ref="queryMessage" class="message" v-html="printQuery" />
    </div>

    <div v-if="queryStatus" class="answer messages">
      <div class="message">
        <div v-if="loadingVisible" class="typing-indicator"><span></span><span></span><span></span></div>
        <div v-else v-html="printContent" />
      </div>
      <span v-if="!loadingVisible">
        {{ timeTaken }}
        <a class="show-btn" @click="modal.showPrompt.visible = true">
          {{ $_lang('LLM_TESTER.WORD.SHOW_PROMPT', { defaultValue: '프롬프트 보기' }) }}
        </a>
        <a v-if="showDocumentBtn" class="show-btn" @click="toggleDocument">
          {{ getDocumentText }}
        </a>
      </span>
    </div>

    <EspDxDataGrid ref="dataGridContainer" class="mar_to10 fade-in-out" style="display: none;" :data-grid="dataGrid" />


    <!-- 프롬프트 보기 -->
    <ShowPrompt
      v-if="modal.showPrompt.visible"
      :is-open="modal.showPrompt.visible"
      :contentData="modal.showPrompt.contentData"
      @closeModal="modal.showPrompt.visible = false"
    />

    <!-- 검색 문서 보기 -->
    <ShowDocument
      v-if="modal.showDocument.visible"
      :is-open="modal.showDocument.visible"
      :contentData="modal.showDocument.contentData"
      @closeModal="modal.showDocument.visible = false"
    />
  </div>
</template>

<script>
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";
  import ShowPrompt from "@/pages/ai/llm-tester/playground/show-prompt.vue";
  import ShowDocument from "@/pages/ai/llm-tester/playground/show-document.vue";
  import { cloneObj } from "@/utils/common-lib";

  export default {
    components: {
      EspDxDataGrid,
      ShowPrompt,
      ShowDocument,
    },

    props: {
      formData: Object,
      query: String,
      search_flag: Boolean,
    },

    data() {
      return {
        dataGrid: {
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: true, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showRowLines: true, //컬럼 가로선 유무
          disableTotalCount: true,
          dataSource: [],
          height: '100%',
          filterRow: { // 행 검색 필터 / true가 기본
            visible: false,
          },
          headerFilter: {
            visible: false
          },
          remoteOperations: { filtering: true, sorting: true, grouping: true, paging: true },
          pager: { visible: false },
          editing: { allowUpdating: false, allowDeleting: false, allowAdding: false },
          columns: [
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              width: 50,
              alignment: 'center',
              visible: true,
              cellTemplate: (container, options) => {
                container.append(options.rowIndex + 1);
              },
            },
            {
              caption: '문서 이름',
              i18n: 'LLM_TESTER.WORD.DOCUMENT_NAME',
              dataField: 'title',
              alignment: 'left',
              visible: true,
              cellTemplate: (container, options) => {
                let aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  this.modal.showDocument.contentData = options.data;
                  this.modal.showDocument.visible = true;
                });
                container.append(aTag);
              },
            },
            {
              caption: '청크 순서',
              i18n: 'LLM_TESTER.WORD.CHUNK_ORDER',
              width: 100,
              dataField: 'chunk_order',
              alignment: 'center',
              visible: true,
            },
          ],
        },
        showDocumentBtn: false, // 검색 문서 보기 표출여부
        queryStatus: true, // 질의 성공여부
        documentGridVisible: false, // 검색 문서 Grid 표출여부
        content: '', // 질의 내용
        timeTaken: '', // 소요시간
        loadingVisible: true, // 로딩바 표출여부
        scrollViewHeight: '0px', // ScrollView 높이
        modal: {
          showPrompt: {
            visible: false,
            contentData: null,
          },
          showDocument: {
            visible: false,
            contentData: null,
          },
        },
      };
    },

    computed: {
      /** @description 질의 내용 반환 */
      printQuery() {
        return this.query.replaceAll('\n', '<br/>');
      },

      /** @description 답변 내용 반환 */
      printContent() {
        return this.content.replaceAll('\n', '<br/>');
      },

      /** @description 검색 문서 토글 문자열 반환 */
      getDocumentText() {
        return this.documentGridVisible
          ? this.$_lang('LLM_TESTER.WORD.CLOSE_DOCUMENT', { defaultValue: '검색 문서 닫기' })
          : this.$_lang('LLM_TESTER.WORD.SHOW_DOCUMENT', { defaultValue: '검색 문서 보기' });
      },
    },

    methods: {
      /** @description 질의 수행 */
      async queryExecute() {
        const payload = {
          actionName: 'LLM_TESTER_PLAYGROUND_QUERY',
          data: cloneObj(this.formData),
          loading: false,
        };
        payload.data.query = this.query;

        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.content = res.data.content;
          this.timeTaken = this.convertSecToMMSSFF(res.data.elapsed_time.total);
          this.dataGrid.dataSource = res.data.chunks;
          this.modal.showPrompt.contentData = res.data.prompt;
          this.loadingVisible = false;
          this.$emit('loading', this.loadingVisible);
        } else {
          if (res.data?.detail === 'Data is not found') {
            this.queryStatus = false;
            this.$emit('disconnectSession');
            this.$_Msg(this.$_lang('LLM_TESTER.MESSAGE.DISCONNECT_SESSION_ALERT', { defaultValue: 'Playground 세션이 종료되었습니다. <br/>사용하시려면 페이지를 재진입 해주세요.' }));
          } else {
            this.queryStatus = false;
            const container = this.$refs.queryMessage;
            const failBox = document.createElement('div');
            failBox.classList.add('fail-box');
            failBox.innerHTML = `❌`;
            container.appendChild(failBox);
            this.loadingVisible = false;
            this.$emit('loading', this.loadingVisible);
            //this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          }
        }
      },

      /** @description 문서 보기 토글 */
      toggleDocument() {
        const container = this.$refs.dataGridContainer.$el;

        if (this.documentGridVisible) {
          container.classList.remove("visible");
          setTimeout(() => {
            container.classList.remove("block");
          }, 500);
        } else {
          container.classList.add("block");
          setTimeout(() => {
            container.classList.add("visible");
          }, 10);
        }

        this.documentGridVisible = !this.documentGridVisible;
      },

      /**
       * @description 시간초 MM:SS.FF 변환 메서드
       * @param {number} seconds 초 단위
       * @returns {string} (MM:SS.FF)
       * */
      convertSecToMMSSFF(seconds) {
        const milliseconds = Math.floor((seconds % 1) * 100);
        const totalSeconds = Math.floor(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        const formattedMilliseconds = String(milliseconds).padStart(2, '0');

        return `(${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds})`;
      }
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    async created() {
      this.showDocumentBtn = this.search_flag;
      await this.queryExecute();
    },
  };
</script>

<style lang="scss" scoped>
  .mar_ri11 {
    margin-right: 11px !important;
  }

  .right-box-back {
    background-color: #FAFAFA !important;
  }

  .messages {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
    max-width: 80%;
    word-wrap: break-word;
  }

  .answer {
    align-items: flex-start;
  }

  .answer .message {
    background: #d6e8fa;
    position: relative;
  }

  .answer .message:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #d6e8fa;
    border-bottom-right-radius: 15px;
  }
  .answer .message:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    border-bottom-right-radius: 10px;
    background-color: #FAFAFA;
  }

  .query {
    align-items: flex-end;
  }

  .query .message {
    color: white;
    background-color: #717171;
    position: relative;
  }

  .query .message:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: #717171;
    border-bottom-left-radius: 15px;
  }

  .query .message:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    border-bottom-left-radius: 10px;
    background-color: #FAFAFA;
  }

  ::v-deep {
    .fail-box {
      position: absolute;
      left: -20px;
      transform: translateY(-65%);
    }
  }

  .typing-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
  }

  .typing-indicator span {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 0 5px;
    background-color: white;
    border-radius: 50%;
    animation: typing 1.5s infinite ease-in-out;
  }

  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes typing {
    0%, 80%, 100% {
      transform: scale(0);
      opacity: 0.3;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .show-btn {
    font-weight: 500;
    cursor:pointer;
    padding-left: 1em;
  }

  .fade-in-out {
    transition: opacity 0.5s ease, visibility 0.5s ease;
    opacity: 0;
    visibility: hidden;
  }

  .fade-in-out.visible {
    opacity: 1;
    visibility: visible;
  }

  .block {
    display: block !important;
  }
</style>