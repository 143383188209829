<template>
  <div></div>
</template>

<script>
  export default {
    mounted() {},
  };
</script>

<style scoped></style>
