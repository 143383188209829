<!--
  PACKAGE_NAME : src/pages/cc/cti/team-category
  FILE_NAME : index.vue
  AUTHOR : hmlee
  DATE : 2024-07-02
  DESCRIPTION : 팀그룹 카테고리 관리 화면
-->
<template>
  <div class="page-sub-box tree-box">
    <!-- 트리 상위 버튼 -->
    <div class="tree-box">
      <div class="top-btn inline-flex justify-between w-full">
        <dx-button
          :text="$_lang('CC.WORD.REGISTER_1ST_GROUP', { defaultValue: '1차 그룹 등록' })"
          type="button"
          class="btn_XS default filled add1"
          :height="30"
          @click="handleAddRootGroup"
        />
      </div>
    </div>

    <!-- 트리 리스트 -->
    <category-list ref="categoryList"
                  :category-level="getCategoryLevel"
                  @selectRow="handleSelectRow" @clickRow="handleClickRow"
                  @initClickRow="handleInitClickRow" />

    <!-- 우측 레이아웃 선택한 데이터 폼 영역 -->
    <Form ref="form" :clicked-row-data="treeList.focusedRowData" :category-level="getCategoryLevel"
          :child-items="treeList.childItems" />

    <!-- 팀 카테고리 등록 -->
    <modal-team-category
      v-if="modal.teamCategory.visible"
      :is-open="modal.teamCategory.visible"
      :category-data="modal.teamCategory.data"
      @saveModal="handleSaveModal('teamCategory')"
      @closeModal="handleCloseModal('teamCategory')"
    />

    <!-- 팀 추가 -->
    <modal-team
      v-if="modal.team.visible"
      :is-open="modal.team.visible"
      :team-data="modal.team.data"
      @saveModal="handleSaveModal('team')"
      @closeModal="handleCloseModal('team')"
    />
  </div>
</template>

<script>
import ModalTeamCategory from '@/pages/cc/cti/team-category/modal/modal-team-category.vue';
import ModalTeam from '@/pages/cc/cti/team-category/modal/modal-team.vue';
import CategoryList from '@/pages/cc/cti/team-category/category-list.vue';
import Form from '@/pages/cc/cti/team-category/form.vue';
import {DxButton} from "devextreme-vue/button";
import {isSuccess} from "@/utils/common-lib";

export default {
  components: {
    ModalTeamCategory,
    ModalTeam,
    CategoryList,
    Form,
    DxButton,
  },
  props: {},
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        isViewFl: true,
      },
      modal: {
        isOpened: false,
        teamCategory: {
          visible: false,
          data: {},
        },
        team: {
          visible: false,
          data: {},
        },
      },
      treeList: {
        childItems: [],
        selectedRowkeys: [],
        focusedRowData: null,
      },
    }
  },
  computed: {
    /** @description: 현재 그룹 카테고리 레벨 값 조회
     *                현재 구조는 level 2 or 3까지 가능
     *                시스템설정에서 지정한 그룹 카테고리 레벨이 없으면 default는 3 */
    getCategoryLevel() {
      let categoryLevel = this.$store.getters.getSystemCodeList.find(d => d.configKey === 'team_category_level');
      if (categoryLevel) {
        categoryLevel = Number(categoryLevel.configValue);
      } else {
        categoryLevel = 3;
      }
      return categoryLevel;
    },
    /** @description: 1depth 최대 순서값 조회 */
    getMaxOrdBy1Depth() {
      return this.$refs.categoryList.$refs.treeList.getItems.length;
    }
  },
  methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(teamCategory, team)
     * @param settingData 모달로 넘길 설정 데이터 */
    handleOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(teamCategory, team)
     */
    async handleSaveModal(settingType) {
      this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

      await this.$refs.categoryList.$refs.treeList.handleSelectData();

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /**
     * @description : 팝업 닫기
     * @param settingType : 설정 타입(teamCategory, team)
     */
    async handleCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description: 1차 그룹 추가 */
    handleAddRootGroup() {
      this.handleOpenModal(
        'teamCategory',
        {
          depth: 1,
          parentId: -1,
          agtteamCtgOrd: this.getMaxOrdBy1Depth + 1,
        }
      )
    },
    /** @description: 카테고리 로우 리스트 선택 이벤트 */
    handleSelectRow(rowData) {
      if (rowData) {
        this.treeList.selectedRowkeys = rowData;
      }
    },
    /** @description : 카테고리 로우 데이터 클릭 이벤트 */
    handleClickRow(event) {
      if (event) {
        this.treeList.focusedRowData = event.data;

        // 클릭한 행의 아이템 찾기
        const clickedItem = this.$refs.categoryList.$refs.treeList.treeListConfig.dataSource.filter(item => item.parentId === event.data.id);

        // 하위 데이터 체크하여 설정
        this.treeList.childItems = (clickedItem?.length > 0) ? clickedItem : [];

        this.treeList.rowElement = event.rowElement;
      }
    },
    /** @description : 카테고리 로우 데이터 클릭 초기화 */
    handleInitClickRow() {
      this.treeList.focusedRowData = {};
    },
    /** @description : 취소 버튼 클릭시 메서드 */
    handleCancelForm() {
      this.$refs.categoryList.initPage(); //페이지 초기화
    },
    /** @description: 저장 메서드 */
    async updateData(dataList) {
      const payload = {
        actionName: 'CC_AGTTEAM_CATEGORY_MERGE',
        data: dataList,
      }

      const team = this.$_lang('CC.WORD.TEAM', {defaultValue: '팀'});
      const category = this.$_lang('COMPONENTS.CATEGORY', {defaultValue: '카테고리'});
      const cfmUpdateInfo = this.$_lang('CC.MESSAGE.CFM_UPDATE_INFO', {defaultValue: '정보를 수정 하시겠습니까?'});

      if (await this.$_Confirm(`${team} ${category} ${cfmUpdateInfo}`)) {
        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.$refs.categoryList.initPage();
        }
      }
    },
  },
}
</script>

<style scoped>
.wrap main .content .page-sub-box.tree-box {
  padding-top: 20px;
  padding-bottom: 10px;
}
</style>


