import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * @description 보고서 유형 Enum 클래스
 */
class ReportType extends EnumEntry {
  DAILY = {
    ...EnumMap,
    key: 'REPORT_TYPE_DAILY',
    value: 'daily',
    label: '일별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.DAILY.value === value;
    },
  };

  MONTHLY = {
    ...EnumMap,
    key: 'REPORT_TYPE_MONTHLY',
    value: 'monthly',
    label: '월별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.MONTHLY.value === value;
    },
  };

  HOUR = {
    ...EnumMap,
    key: 'REPORT_TYPE_HOUR',
    value: 'hour',
    label: '시간별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.HOUR.value === value;
    },
  };

  I15 = {
    ...EnumMap,
    key: 'REPORT_TYPE_I15',
    value: 'i15',
    label: '15분별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.I15.value === value;
    },
  };

  I30 = {
    ...EnumMap,
    key: 'REPORT_TYPE_MIN_30',
    value: 'i30',
    label: '30분별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.I30.value === value;
    },
  };

  WEEKDAY = {
    ...EnumMap,
    key: 'REPORT_TYPE_WEEKDAY',
    value: 'weekday',
    label: '요일별',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.WEEKDAY.value === value;
    },
  };

  DAYTIME = {
    ...EnumMap,
    key: 'REPORT_TYPE_DAYTIME',
    value: 'daytimes',
    label: '일+시간',
    group: 'REPORT_TYPE',
    group_label: '보고서 유형',
    equals: value => {
      return this.DAYTIME.value === value;
    },
  };

  values = [this.DAILY, this.MONTHLY, this.HOUR, this.I15, this.I30, this.DAYTIME, this.WEEKDAY];

  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 보고서 Query 유형 Enum 클래스
 */
class XmlType extends EnumEntry {
  WIZARD_TEMPLATE = {
    ...EnumMap,
    key: 'XML_TYPE_WIZARD_TEMPLATE',
    value: 'wizard-template',
    label: '위자드템플릿',
    group: 'XML_TYPE',
    group_label: '보고서 XML 유형',
    equals: value => {
      return this.WIZARD_TEMPLATE.value === value;
    },
  };

  BI_REPORT = {
    ...EnumMap,
    key: 'XML_TYPE_BI_REPORT',
    value: 'bi-report',
    label: 'BI 보고서',
    group: 'XML_TYPE',
    group_label: '보고서 XML 유형',
    equals: value => {
      return this.BI_REPORT.value === value;
    },
  };

  REPORT_TARGET = {
    ...EnumMap,
    key: 'XML_TYPE_REPORT_TARGET',
    value: 'report-target',
    label: '조회대상',
    group: 'XML_TYPE',
    group_label: '보고서 XML 유형',
    equals: value => {
      return this.REPORT_TARGET.value === value;
    },
  };

  values = [this.WIZARD_TEMPLATE, this.BI_REPORT, this.REPORT_TARGET];

  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 보고서 작업 유형 Enum 클래스
 */
class WorkType extends EnumEntry {
  SEARCH = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'search',
    label: '조회',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.SEARCH.value === value;
    },
  };

  DEFAULT_EXCEL = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'default-excel',
    label: '기본 다운로드',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.DEFAULT_EXCEL.value === value;
    },
  };

  LARGE_DATA_EXCEL = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'large-data-excel',
    label: '대용량 다운로드',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.LARGE_DATA_EXCEL.value === value;
    },
  };

  TARGET_EXCEL = {
    ...EnumMap,
    key: 'REPORT_WORK_TYPE',
    value: 'target-excel',
    label: '대상별 다운로드',
    group: 'WORK_TYPE',
    group_label: '작업 유형',
    equals: value => {
      return this.TARGET_EXCEL.value === value;
    },
  };

  values = [this.SEARCH, this.LARGE_DATA_EXCEL, this.DEFAULT_EXCEL, this.TARGET_EXCEL];

  findByValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value);
  };
}

/**
 * @description 모든 코드 반환
 * @return {*[]}
 */
function getEnumAllCodes() {
  return [].concat(new ReportType().values, new XmlType().values);
}

export default {
  reportType: new ReportType(),
  xmlType: new XmlType(),
  workType: new WorkType(),
  getEnumAllCodes: getEnumAllCodes(),
};
