<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준년월</div>
              <DxDateBox
                :edit-enabled="false"
                :styling-mode="stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.startDt"
                type="date"
                display-format="yyyy-MM"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                maxLength="7"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayStartChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxDateBox
                :styling-mode="stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.endDt"
                type="date"
                display-format="yyyy-MM"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="7"
                :min="searchType.customTypes.startDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayEndChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>

      <div class="flex space-x-4">
        <div class="w-2/5">
          <esp-dx-data-grid
            :data-grid="kpiScheGrid"
            ref="kpiScheGrid"
            key-expr="kpiPerformScheId"
            :selected-row-keys="kpiScheGridSelectedRowKeys"
            @row-click="onRowClick"
          />
        </div>

        <div class="w-3/5">
          <div class="mt-5">
            <div class="head-btn-left">
              <DxButton text="기준추가" :width="90" :height="30" class="btn_XS default filled add1" @click="onAddStandard" />
              <DxButton text="삭제" icon="" class="btn_XS white light_filled trash" :width="60" :height="30" @click="onDeleteStandard" />
            </div>
            <div class="h-32 mt-6">
              <!-- 전체 섹션 -->
              <div v-if="this.kpiPerformSche.dataSource && this.kpiPerformSche.dataSource.length > 0">
                <table class="metrics-tbl">
                  <tbody>
                    <tr>
                      <template v-for="item in this.kpiPerformSche.dataSource">
                        <td
                          v-if="item.metrics.length && item.metrics.length > 1"
                          :colspan="item.metrics.length"
                          :key="item.kpiPerformScheStdRegId"
                          class="subtitles"
                        >
                          {{ item.performStdNm }}({{ calculateTotalScore(item.metrics) }})
                        </td>
                        <td v-else rowspan="2" :key="item.kpiPerformScheStdRegId + '_rowmerged'" class="subtitles">
                          {{ item.metrics[0].metricsNm }}({{ item.metrics[0].maxScore }})
                        </td>
                      </template>
                      <td rowspan="2" class="subtitles">총점({{ this.totalMaxScore }})</td>
                    </tr>
                    <tr>
                      <template v-for="item in this.kpiPerformSche.dataSource">
                        <template v-if="item.metrics.length && item.metrics.length > 1">
                          <td
                            v-for="metric in item.metrics"
                            :key="`${item.kpiPerformScheStdRegId}_${metric.kpiPerformMetricsId}`"
                            class="subtitles"
                          >
                            {{ metric.metricsNm }}({{ metric.maxScore }})
                          </td>
                        </template>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="h-28 flex justify-center items-center">
                <span>데이터가 없습니다.</span>
              </div>
              <!-- 전체 섹션 -->
            </div>
          </div>
          <div>
            <esp-dx-data-grid :data-grid="kpiTargetGrid" :ref="kpiTargetGrid.refName" @saving="e => onTargetSave(e)" />
          </div>
        </div>
      </div>

      <!-- Agent Modal -->
      <modal-add-agent
        :isOpen="modal.agent.popupVisible"
        :showModalTitle="true"
        :selectedIdList="modal.agent.selectedIdList"
        @closeModal="onClose(false)"
        @saveModal="onSave"
      />
      <!-- Agent Modal -->

      <!-- Modal Layer -->
      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component
              :is="modal.currentComponent"
              :contentData="modal.contentData"
              :searchParams="modal.performStd.searchParams"
              v-model="modal.contentData"
            ></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.hasOwnProperty('save')
                : false
              : false
          "
          :options="{
            elementAttr: {
              class: 'default filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.text
                : ''
              : '',
            width: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.width
                : 120
              : 120,
            height: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('save')
                ? modal.initData.buttons.save.height
                : 40
              : 40,
            onClick: e => {
              onConfirmModal(e);
            },
          }"
        />
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.width
                : 120
              : 120,
            height: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.height
                : 40
              : 40,
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </DxPopup>
      <!-- /Modal Layer -->
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { getPastFromToday, isSuccess } from '@/utils/common-lib';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import ModalPerformStandard from '@/pages/ewm/kpi/implementation/perform-sche/modal-perform-standard.vue';
  import ModalEwmAdd from '@/pages/ewm/productivity/implementation/evaluation-schedule/modal-ewm-add';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxSelectBox,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      DxPopup,
      DxToolbarItem,
      ModalAddAgent,
      ModalPerformStandard,
      ModalEwmAdd,
    },
    data() {
      return {
        codeMap: {},
        totalMaxScore: 0,
        codes: {
          addscoreTypeId: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          addscoreTypeDetailId: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            startDt: getPastFromToday(5, 'months'),
            endDt: getPastFromToday(-5, 'months'),
          },
          paramsData: {},
        },
        modal: {
          isOpened: false,
          initData: {},
          contentData: null,
          componentName: '',
          agent: {
            popupVisible: false,
            selectedIdList: [],
            targetSelectedIdList: [],
          },
          performStd: {
            selectedIdList: [],
            searchParams: {},
          },
        },
        kpiScheGridSelectedRowKeys: [],
        kpiScheGrid: {
          refName: 'kpiScheGrid',
          keyExpr: 'kpiPerformScheId',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellHoverChanged: false,
            cellPrepared: false,
            checkDataBeforeSaving: false,
            initNewRow: true,
            rowClick: true,
            rowInserted: false,
            rowInserting: false,
            saving: true,
            selectionChanged: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '복사생성',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 70,
                  height: 30,
                  onClick() {
                    vm.onCopyScheGrid();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onAddScheGrid();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteScheData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            // allowUpdating: options => {
            // 	if (options.row.rowType == 'data' && options.row.data.magamFl === this.$_enums.common.stringUsedFlag.YES.value) {
            // 		return false;
            // 	} else {
            // 		return true;
            // 	}
            // }, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowUpdating: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '아이디',
              dataField: 'kpiPerformScheId',
              visible: false,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '기준년월',
              width: 140,
              dataField: 'ymd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              dataType: 'date',
              format: 'yyyy-MM',
              editorOptions: {
                dataType: 'date',
                calendarOptions: {
                  zoomLevel: 'year',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'year',
                },
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '성과실적계획명',
              dataField: 'scheNm',
              alignment: 'left',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
              cellTemplate: (container, options) => {
                if (options.data.useFl === this.$_enums.common.stringUsedFlag.NO.value) {
                  let aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    vm.onUpdateData(options.data);
                  });
                  container.append(aTag);
                } else {
                  container.append(options.value);
                }
              },
            },
            {
              caption: '배점',
              width: 110,
              dataField: 'score',
              alignment: 'center',
              allowFiltering: true,
              allowHeaderFiltering: false,
              allowEditing: false,
            },
            {
              caption: '마감여부',
              width: 110,
              dataField: 'magamFl',
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.magamFl === this.$_enums.common.stringUsedFlag.NO.value) {
                  return '미완료';
                } else {
                  return '마감완료';
                }
              },
              /* calculateSortValue: data => {
            return gridData.columnDataSource.useFl.find(e => data.viewFl == e.cd).nm;
          }, */
              /* cellTemplate: (container, options) => {
            const switchBtn = new DxSwitch({
              propsData: {
                value: options.value === 'Y',
                disabled: options.data.useFl === 'Y' ? true : false,
                readOnly: true,
              },
            });
            switchBtn.$mount();
            container.append(switchBtn.$el);
          },
          editCellTemplate: (container, options) => {
            const switchBtn = new DxSwitch({
              propsData: {
                value: options.data.viewFl === 'Y',
                disabled: options.data.useFl === 'Y' ? true : false,
                onValueChanged: e => {
                  const viewFlVal = e.value ? 'Y' : 'N';
                  options.setValue(viewFlVal);
                },
              },
            });
            switchBtn.$mount();
            container.append(switchBtn.$el);
          }, */
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'value',
                valueExpr: 'value',
              },
            },
          ],
        },
        kpiPerformSche: {
          dataSource: [],
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        kpiTargetGrid: {
          refName: 'kpiTargetGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 570px)', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            initNewRow: true,
            saving: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '상담사추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick() {
                    vm.onAddTarget();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteTarget();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서명',
              dataField: 'pathNmFull',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              calculateDisplayCellValue: rowData => {
                return `${rowData.pathNmFull.replace(/‡/g, '>')}`;
              },
            },
            {
              caption: '상담사',
              dataField: 'agtid',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
            },
            {
              caption: '상담사',
              dataField: 'agtidAndNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: true,
              allowHeaderFiltering: true,
              calculateCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtid}]`;
              },
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      /** @description: 수정가능여부체크 */
      canModify() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();

        if (selectedRowsData.length < 1) {
          return false;
        }

        return selectedRowsData[0].magamFl != this.$_enums.common.stringUsedFlag.YES.value;
      },
      /** @description: 계획선택여부체크 */
      isSelectedSchedule() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();
        return selectedRowsData.length > 0;
      },
      calculateTotalScore(metrics) {
        return metrics.reduce((total, metric) => total + metric.maxScore, 0);
      },
      /** @description : scheGrid 데이터 검색 메서드 */
      async selectDataList() {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        this.$refs.kpiScheGrid.getInstance.deselectAll();
        this.$refs.kpiScheGrid.getInstance.cancelEditData();

        this.kpiPerformSche.dataSource = [];
        this.kpiPerformSche.selectedRowKeys = [];
        this.kpiTargetGrid.dataSource = [];

        const payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_SELECT',
          data: {
            startDt: vm.searchType.customTypes.startDt,
            endDt: vm.searchType.customTypes.endDt,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.kpiScheGrid.dataSource = res.data.data;
        }
      },
      /** @description : kpiScheGrid row copy 메서드 */
      async onCopyScheGrid() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DUPLICATE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_INSERT',
          path: '/' + selectedRowsData[0].kpiPerformScheId,
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      // /** @description : kpiScheGrid 데이터 추가 시 기본 값 적용하기 위함 */
      // onInitNewRow(e) {
      // 	e.data.magamFl = this.$_enums.common.stringUsedFlag.NO.value;
      // 	e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
      // 	e.data.useFl = this.$_enums.common.stringUsedFlag.NO.value;
      // },
      onAddScheGrid() {
        this.onOpenModal(
          'ModalEwmAdd',
          {
            title: '성과실적계획관리 추가',
            buttons: {
              save: { text: '저장' },
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '400',
          },
          {
            columns: this.kpiScheGrid.columns,
          },
        );
      },
      onUpdateData(data) {
        this.onOpenModal(
          'ModalEwmAdd',
          {
            title: '성과실적계획관리 수정',
            buttons: {
              save: { text: '수정' },
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '400',
          },
          {
            columns: this.kpiScheGrid.columns,
            formData: {
              id: data.kpiPerformScheId,
              ymd: data.ymd,
              name: data.scheNm,
            },
          },
        );
      },
      /** @description : kpiScheGrid row save 메서드 */
      async onSaveSche(e) {
        let payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_INSERT',
          data: {
            sches: e,
          },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : kpiScheGrid row delete 메서드 */
      async onDeleteScheData() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData()[0];

        if (!selectedRowsData) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (selectedRowsData.magamFl == 'Y') {
          this.$_Msg(`마감된 데이터는 수정할 수 없습니다.`);
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        let kpiPerformScheIds = [];
        kpiPerformScheIds.push({ kpiPerformScheId: selectedRowsData.kpiPerformScheId });

        const payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_DELETE',
          data: {
            data: {
              kpiPerformScheIds: kpiPerformScheIds,
            },
          },
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onKpiScheGridSelectionChanged(e) {
        if (e.selectedRowsData.length > 0) {
          this.onRowClick({ data: e.selectedRowsData[0] });
        }
      },
      /** @description : kpiScheGrid row 선택 메서드 */
      async onRowClick(row) {
        if (!row.data.kpiPerformScheId) {
          return;
        }

        this.$refs.kpiScheGrid.selectedRowsData = row.data;
        this.$refs.kpiScheGrid.getInstance.selectRows([row.data.kpiPerformScheId], false);

        const payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_SELECT',
          path: '/' + row.data.kpiPerformScheId,
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.kpiPerformSche.dataSource = res.data.data[0].kpiPerformScheStdRegs;
          this.kpiPerformSche.selectedRowKeys = res.data.data[0].kpiPerformScheStdRegs.map(d => d.kpiPerformStdId);
          this.kpiTargetGrid.dataSource = res.data.data[0].kpiPerformScheTargets;

          this.totalMaxScore = this.kpiPerformSche.dataSource.reduce(
            (sum1, current1) => sum1 + current1.metrics.reduce((sum2, current2) => sum2 + current2.maxScore, 0),
            0,
          );

          const kpiScheIdx = this.kpiScheGrid.dataSource.findIndex(itm => itm.kpiPerformScheId == row.data.kpiPerformScheId);

          if (this.totalMaxScore != this.kpiScheGrid.dataSource[kpiScheIdx].score) {
            this.$set(this.kpiScheGrid.dataSource[kpiScheIdx], 'score', this.totalMaxScore);
            this.$refs.kpiScheGrid.getInstance.selectRows([row.data.kpiPerformScheId], false);
          }
        }
      },
      /** @description: 기준 저장 */
      async onSaveStandard(kpiPerformStdIds) {
        const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;

        if (kpiPerformStdIds.length && kpiPerformStdIds.length < 1) {
          return;
        }

        if (!selectedRowsData) {
          this.$_Msg('계획을 선택 하세요');
          return;
        }

        // 선택 된 순서로 regOrder를 갖는다.
        let params = [];
        kpiPerformStdIds.some((id, idx) => {
          params.push({
            kpiPerformStdId: id,
            regOrder: ++idx,
          });
        });

        //let regOrder = this.kpiPerformSche.dataSource.length ? this.kpiPerformSche.dataSource.length + 1 : 1;
        //kpiPerformStdIds.forEach(el => {
        //  const kpiPerformStdIdIdx = this.kpiPerformSche.dataSource.findIndex(fi => fi.kpiPerformStdId === el.kpiPerformStdId);
        //  if (kpiPerformStdIdIdx < 0) {
        //    datas.push({
        //      kpiPerformStdId: el.kpiPerformStdId,
        //      regOrder: regOrder++,
        //    });
        //  }
        //});

        // 기존 계획에 등록 된 기준을 모두 삭제 한 뒤 새로 추가 한다.
        let payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_REG_INSERT',
          path: '/' + selectedRowsData.kpiPerformScheId,
          data: {
            stds: params,
          },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          //this.selectDataList();
          this.onRowClick({ data: selectedRowsData });
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: 기준삭제 */
      async onDeleteStandard() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData()[0];

        if (!this.isSelectedSchedule()) {
          this.$_Msg('계획을 선택하세요');
          return;
        }

        if (!this.canModify()) {
          this.$_Msg(`마감된 계획 입니다. 수정할 수 없습니다.`);
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        let kpiPerformScheId = selectedRowsData.kpiPerformScheId;

        const payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_REG_STD_DELETE',
          path: '/' + kpiPerformScheId,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.onRowClick({ data: selectedRowsData });
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: 계획에 등록 된 기준 아이디 목록 조회 */
      getRegisteredStdList() {
        return this.kpiPerformSche.dataSource.map(m => m.kpiPerformStdId);
      },

      /** @description: 기준 추가 modal 열기 */
      onAddStandard() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();

        if (!this.isSelectedSchedule()) {
          this.$_Msg('계획을 선택하세요');
          return;
        }

        if (!this.canModify()) {
          this.$_Msg(`마감된 계획 입니다. 수정할 수 없습니다.`);
          return;
        }

        const registeredStdList = this.getRegisteredStdList();

        if (selectedRowsData[0].kpiPerformScheId) {
          this.onOpenModal(
            'ModalPerformStandard',
            {
              title: '성과실적기준 추가',
              buttons: {
                save: { text: '저장' },
                cancel: { text: '닫기' },
              },
              width: '900',
              height: '700',
            },
            registeredStdList,
          );
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.modal.performStd.searchParams = {
          startDt: vm.searchType.customTypes.startDt,
          endDt: vm.searchType.customTypes.endDt,
        };
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 기준 추가 저장 */
      async onConfirmModal() {
        if (
          this.modal.currentComponent === 'ModalEwmAdd' &&
          (this.modal.contentData.columns || this.modal.contentData.ymd == null || this.modal.contentData.name == null)
        ) {
          return;
        }

        if (this.modal.currentComponent === 'ModalPerformStandard' && this.modal.contentData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (this.modal.currentComponent === 'ModalPerformStandard') {
          const registeredStdList = this.modal.contentData;
          let kpiPerformStdIds = registeredStdList.map(i => i.kpiPerformStdId);
          this.onSaveStandard(kpiPerformStdIds);
        } else {
          let data = [];
          data.push({
            kpiPerformScheId: this.modal.contentData.id,
            scheNm: this.modal.contentData.name,
            ymd: this.modal.contentData.ymd,
            viewFl: this.$_enums.common.stringUsedFlag.YES.value,
            useFl: this.$_enums.common.stringUsedFlag.YES.value,
          });
          this.onSaveSche(data);
        }

        this.isOpenModal(false);
      },
      async onDeleteTarget() {
        //const selectedRowsData = this.$refs.kpiTargetGrid.selectedRowsData;

        if (!this.isSelectedSchedule()) {
          this.$_Msg('계획을 선택하세요');
          return;
        }

        if (!this.canModify()) {
          this.$_Msg(`마감된 계획 입니다. 수정할 수 없습니다.`);
          return;
        }

        const selectedRowsData = this.$refs.kpiTargetGrid.getInstance.getSelectedRowsData();

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const kpiPerformScheTargetIds = selectedRowsData.map(x => x.kpiPerformScheTargetId);

        let datas = [];
        kpiPerformScheTargetIds.forEach(i => {
          datas.push({ kpiPerformScheTargetId: i });
        });

        const payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_TARGET_DELETE',
          data: {
            data: {
              kpiPerformScheTargetIds: datas,
            },
          },
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));

          //this.onRowClick({ data: { kpiPerformScheId: this.$refs.kpiScheGrid.selectedRowsData.kpiPerformScheId } });
          this.onRowClick({ data: this.$refs.kpiScheGrid.getInstance.getSelectedRowsData()[0] });
        }
      },
      /** @description : 추가 메서드 */
      onAddTarget() {
        //const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();

        if (selectedRowsData.length < 1) {
          this.$_Msg('계획을 선택하세요');
          return;
        }

        if (!this.canModify()) {
          this.$_Msg(`마감된 계획 입니다. 수정할 수 없습니다.`);
          return;
        }

        if (selectedRowsData[0].kpiPerformScheId) {
          this.modal.agent.popupVisible = true;
          this.modal.agent.selectedIdList = this.kpiTargetGrid.dataSource.map(m => m.agtid);
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
      },
      onClose() {
        this.modal.agent.popupVisible = false;
        this.modal.agent.selectedIdList = [];
      },
      // 대상자 등록
      async onSave(value) {
        const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;

        let params = [];
        value.forEach(i => {
          if (this.kpiTargetGrid.dataSource.findIndex(f => f.agtid == i.agtid) < 0) {
            let atg = {
              agtNm: i.agtNm,
              agtid: i.agtid,
              pathNmFull: i.deptNmPath,
            };
            params.push(atg);
          }
        });

        let payload = {
          actionName: 'EWM_PERFORMANCE_SCHE_TARGET_INSERT',
          data: {
            kpiPerformScheId: selectedRowsData.kpiPerformScheId,
            targets: params,
          },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.onRowClick({ data: { kpiPerformScheId: this.$refs.kpiScheGrid.selectedRowsData.kpiPerformScheId } });
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }

        this.onClose();
      },
      onDayStartChanged(e) {
        this.searchType.customTypes.startDt = e.value.slice(0, 6) + '01';
      },
      onDayEndChanged(e) {
        this.searchType.customTypes.endDt = e.value.slice(0, 6) + '29';
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_addscore_type');
      },
      onTargetSave(e) {},
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {});
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
<style lang="css" scoped>
  /* 전체 섹션 */
  .section {
    display: flex;
    justify-content: space-between;
    border: 1px solid #ddd;
    background-color: #ddd;
  }

  /* 대표 제목 그룹 */
  .group {
    width: 48%;
    border-right: 1px solid silver;
  }

  /* 대표 제목 스타일 */
  .group h1 {
    text-align: center;
    padding: 10px;
  }

  /* 밑줄 스타일 */
  .group h1.borderBottom {
    border-bottom: 1px solid silver;
  }

  /* 테이블 스타일 */
  .metrics-tbl {
    width: 100%;
    border-collapse: collapse;
    background-color: #f9f9f9;
  }

  /* 테이블 헤더 스타일 */
  .metrics-tbl th {
    border: 1px solid silver;
    padding: 10px;
    text-align: center;
  }

  /* 테이블 데이터 스타일 */
  .metrics-tbl td {
    border: 1px solid silver;
    padding: 10px;
    text-align: center;
  }
</style>
