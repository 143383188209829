import CdrRoutes from "@/router/euc/device/cdr";
import CiscoRoutes from "@/router/euc/device/cisco";
import AvayaRoutes from "@/router/euc/device/avaya";
import GroupRoutes from "@/router/euc/device/group";
import HuntRoutes from "@/router/euc/device/hunt";
import JabberRoutes from "@/router/euc/device/jabber";
import LineRoutes from "@/router/euc/device/line";
import LogRoutes from "@/router/euc/device/log";
import PickupRoutes from "@/router/euc/device/pickup";
import MyDeviceRoutes from "@/router/euc/device/my-device";

export default function DeviceRoutes(commonComponents) {
    return [
        ...MyDeviceRoutes(commonComponents),
        ...CdrRoutes(commonComponents),
        ...CiscoRoutes(commonComponents),
        ...AvayaRoutes(commonComponents),
        ...GroupRoutes(commonComponents),
        ...HuntRoutes(commonComponents),
        ...JabberRoutes(commonComponents),
        ...LineRoutes(commonComponents),
        ...LogRoutes(commonComponents),
        ...PickupRoutes(commonComponents)
    ]
}