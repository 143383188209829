<!--
  PACKAGE_NAME : src/pages/ewm/hr/work/schedule/popup
  FILE_NAME : modal-modify-schedule.vue
  AUTHOR : devyoon91
  DATE : 2024-06-19
  DESCRIPTION : 대직 처리 팝업
-->
<template>
  <div>
    <DxPopup
      :ref="modifySchedulePopup.ref"
      :title="modifySchedulePopup.title"
      :visible="isOpen"
      :show-title="true"
      :min-width="modifySchedulePopup.minWidth"
      :width="modifySchedulePopup.width"
      :min-height="modifySchedulePopup.minHeight"
      :height="modifySchedulePopup.height"
      :resize-enabled="false"
      :drag-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      @hiding="closeModal"
    >
      <template #content>
        <div>
          <div>
            <table class="table_form line-bin">
              <colgroup>
                <col style="width: 20%" />
                <col style="width: 30%" />
                <col style="width: 20%" />
                <col style="width: 30%" />
              </colgroup>
              <tbody>
                <tr>
                  <th scope="row">
                    <label for="label5">신청 상담원</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    <div v-if="appointmentData.agentData.agtNm">
                      {{ appointmentData.agentData.agtNm }}({{ appointmentData.agentData.agtid }})
                    </div>
                  </td>
                  <th scope="row">
                    <label for="label5">대직 상담원</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    <div v-if="targetAgentData.agtNm">{{ targetAgentData.agtNm }}({{ targetAgentData.agtid }})</div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label5">부서</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    {{ appointmentData.agentData.deptNmPath }}
                  </td>
                  <th scope="row">
                    <label for="label5">부서</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    {{ targetAgentData.deptNmPath }}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label5">현재 일반근무</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    <div v-if="appointmentData.agentData.workBasicNm">
                      {{ appointmentData.agentData.workBasicNm }}/ {{ appointmentData.agentData.workDt }}/
                      {{ appointmentData.agentData.workBasicStartTime }} ~
                      {{ appointmentData.agentData.workBasicEndTime }}
                    </div>
                  </td>
                  <th scope="row">
                    <label for="label5">현재 일반근무</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    <div v-if="targetAgentData.workBasicNm">
                      {{ targetAgentData.workBasicNm }}/ {{ targetAgentData.workDt }}/ {{ targetAgentData.workBasicStartTime }} ~
                      {{ targetAgentData.workBasicEndTime }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <label for="label5">변경 일반근무</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    <div class="font-bold" v-if="targetAgentData.workBasicNm">
                      {{ targetAgentData.workBasicNm }}/ {{ targetAgentData.workDt }}/ {{ targetAgentData.workBasicStartTime }} ~
                      {{ targetAgentData.workBasicEndTime }}
                    </div>
                  </td>
                  <th scope="row">
                    <label for="label5">변경 일반근무</label>
                  </th>
                  <td colspan="1" class="clearfix">
                    <div class="font-bold" v-if="targetAgentData.workBasicNm && appointmentData.agentData.workBasicNm">
                      {{ appointmentData.agentData.workBasicNm }}/ {{ appointmentData.agentData.workDt }}/
                      {{ appointmentData.agentData.workBasicStartTime }} ~
                      {{ appointmentData.agentData.workBasicEndTime }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mt-5">
            <DxDateBox
              v-model="workDate"
              :styling-mode="workDateBox.stylingMode"
              :width="workDateBox.width"
              :type="workDateBox.type"
              :dateSerializationFormat="workDateBox.dateSerializationFormat"
              :display-format="workDateBox.displayFormat"
              :invalidDateMessage="workDateBox.invalidDateMessage"
              :disabled="workDateBox.disabled"
              :hint="workDateBox.hint"
              :onValueChanged="onDateBoxValueChanged"
              :min="today"
            >
              <DxValidator>
                <DxRequiredRule :message="$_lang('COMMON.MESSAGE.PLEASE_SELECT_DATE', { defaultValue: '날짜를 선택해주세요.' })" />
              </DxValidator>
            </DxDateBox>
          </div>
          <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @row-click="onRowClick" />
          <div class="page-sub-box mt-3">
            <div class="bottom-btn-wrap text-center">
              <DxButton
                text="저장"
                :width="120"
                :height="40"
                class="default filled txt_S medium"
                :use-submit-behavior="true"
                @click="saveModal"
              />
              <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="closeModal" />
            </div>
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import DxPopup from 'devextreme-vue/popup';
  import { isSuccess } from '@/utils/common-lib';
  import { DxButton } from 'devextreme-vue/button';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import moment from 'moment';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    components: { EspDxDataGrid, DxDateBox, DxValidator, DxRequiredRule, DxButton, DxPopup },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-modify-schedule',
          nextPageName: '',
          prevPageName: '',
          agentData: {},
          workCategory: {},
          submitList: [],
        }),
      },
    },
    data() {
      return {
        today: moment().format('YYYYMMDD'),
        workDate: moment().format('YYYYMMDD'),
        workDateBox: {
          width: '110',
          type: 'date',
          stylingMode: 'outlined',
          dateSerializationFormat: 'yyyyMMdd',
          displayFormat: 'yyyy.MM.dd',
          invalidDateMessage: this.$_lang('COMMON.MESSAGE.INVALID_DATE_FORMAT', {
            defaultValue: '날짜 형식이 올바르지 않습니다.',
          }),
          disabled: false,
          hint: this.$_lang('COMMON.MESSAGE.PLEASE_SELECT_DATE', { defaultValue: '날짜를 선택해주세요.' }),
        },
        modifySchedulePopup: {
          ref: 'modifySchedulePopup',
          title: '대직 처리',
          minWidth: '850',
          width: '850',
          minHeight: '720',
          height: '720',
        },
        targetAgentData: {
          agtNm: '',
          agtid: '',
          deptNmPath: '',
          workBasicNm: '',
          workBasicStartTime: '',
          workBasicEndTime: '',
          workDt: '',
          workOverTimeNm: '',
          workOverTimeStartTime: '',
          workOverTimeEndTime: '',
        },
        dataGrid: {
          keyExpr: 'scheduleId', // 그리드 키값
          refName: 'modifyScheduleGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          excel: {
            title: '엑셀파일명', // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          focusedRowEnabled: false, // 포커스 행 표시 유무
          allowColumnReordering: false, // 컬럼 위치 변경 유무
          allowColumnResizing: false, // 컬럼 사이즈 조절 유무
          columnResizingMode: 'widget', // 컬럼 사이즈 조절 모드 : ['nextColumn', 'widget']
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          showBorders: false, // 그리드 테두리 유무
          showColumnHeaders: true, // 컬럼 헤더 유무
          showColumnLines: false, // 컬럼 세로선 유무
          showRowLines: true, // 컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색 교차 유무
          dataSource: [], // 그리드 데이터
          // width:'200',   // 주석처리시 100%
          height: '330', // 주석처리시 100%
          apiActionNm: {}, // api 호출시 사용할 액션명(ESP - API URL 관리) : ['update', 'merge', 'delete', 'select']
          scrolling: {
            mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            cellPrepared: false, // 셀 이벤트
            rowClick: true, // 행 클릭 이벤트
            toolbarPreparing: false, // 툴바 이벤트
          },
          showActionButtons: {
            excel: false, // 엑셀 다운로드 버튼
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          grouping: {
            contextMenuEnabled: false, // 그룹핑 컨텍스트 메뉴 사용유무
            autoExpandAll: false, // 그룹핑시 전체 펼침 여부
            allowCollapsing: true, // 그룹핑시 접기 허용 여부
            expandMode: 'rowClick', // 그룹핑 펼침 모드 : ['rowClick', 'buttonClick']
          },
          groupPanel: {
            visible: false, // 그룹패널 표시 여부
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: true, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false, // 페이징 사용 유무
            // pageSize: 10,
            // pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, // 페이지 사이즈 선택 버튼 표시 여부
            allowedPageSizes: [], // 페이지 사이즈 선택 버튼에 표시할 항목
            displayMode: 'compact', // 페이저 표시 모드 : ['adaptive', 'compact', 'full']
            showInfo: false, // 페이지 정보 표시 여부
            showNavigationButtons: false, // 페이지 이동 버튼 표시 여부
          },
          filterRow: {
            visible: true, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
            mode: 'batch', // 행 편집 모드 : ['cell', 'row', 'batch']
            startEditAction: 'click', // 행 편집 시작 액션 : ['click', 'dblClick']
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          selecting: {
            mode: 'single', // 행 선택 모드 : ['none', 'single', 'multiple']
            selectAllMode: 'page', // 전체 선택 모드 : ['page', 'allPages']
            showCheckBoxesMode: 'onClick', // 체크박스 표시 모드 : ['none', 'onClick', 'onLongTap', 'always']
            deferred: false, // 선택 지연 여부
            allowSelectAll: false, // 전체 선택 허용 여부
          },
          searchPanel: {
            visible: false, // 검색 패널 표시 여부
          },
          columns: [
            {
              caption: '상담원',
              dataField: 'agent',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: true,
              calculateCellValue: this.mergeAgentInfo,
            },
            {
              caption: '부서',
              dataField: 'deptNmPath',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: true,
              allowGrouping: true,
            },
            {
              caption: '일반근무유형',
              dataField: 'workBasicNm',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
            },
            {
              dataType: 'date',
              format: 'yyyy-MM-dd',
              caption: '근무일자',
              dataField: 'workDt',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowFiltering: false,
            },
            {
              caption: '일반근무시간',
              dataField: 'workBasicTime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: rowData => {
                return this.mergeWorkTime(rowData, this.$_enums.ewm.workCategory.BASIC.value);
              },
            },
            {
              caption: '시간외근무시간',
              dataField: 'workOvertime',
              visible: true,
              sortOrder: 'none',
              alignment: 'center',
              allowHeaderFiltering: false,
              allowFiltering: true,
              allowGrouping: false,
              calculateCellValue: rowData => {
                return this.mergeWorkTime(rowData, this.$_enums.ewm.workCategory.OVER_TIME.value);
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    watch: {
      isOpen() {
        this.workDate = moment().format('YYYYMMDD');
        this.targetAgentData = {};
        this.onSearch();
      },
    },
    methods: {
      /**
       * 초기화
       */
      init() {
        this.workDate = moment().format('YYYYMMDD');
        this.targetAgentData = {};
        this.appointmentData.agentData = {};
      },
      /**
       * 팝업 닫기 이벤트
       */
      closeModal() {
        this.init();
        this.$emit('closeModal');
      },
      /**
       * 저장 이벤트
       */
      async saveModal() {
        if (!this.targetAgentData || Object.keys(this.targetAgentData).length === 0) {
          this.$_Toast(this.$_lang('EWM.MESSAGE.SELECT_COUNSELOR_TO_MODIFY'));
          return;
        }

        const payLoad = {
          actionName: 'EWM_WORK_SCHEDULE_SWAP',
          data: {
            prevScheduleId: this.appointmentData.agentData.scheduleId,
            nextScheduleId: this.targetAgentData.scheduleId,
          },
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payLoad);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
          this.appointmentData.agentData = {};
          this.targetAgentData = {};
          this.init();
          this.$emit('saveModal');
        }
      },
      /**
       * 상담원 정보 합치기
       * @param rowData
       * @return {string}
       */
      mergeAgentInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.agtNm} (${rowData.agtid})`;
      },
      /**
       * @description : 근무시간 병합
       * @param rowData
       * @param workCategory
       * @return {string}
       */
      mergeWorkTime(rowData, workCategory) {
        if (rowData === null || rowData === undefined) {
          return '';
        }

        let timeStr = '';
        if (workCategory === this.$_enums.ewm.workCategory.BASIC.value) {
          timeStr = `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
        } else if (rowData?.workOverTimeStartTime && rowData?.workOverTimeEndTime) {
          timeStr = `${rowData.workOverTimeStartTime} ~ ${rowData.workOverTimeEndTime}`;
        }
        return timeStr;
      },
      /**
       * 날짜 변경 이벤트
       * @param e
       */
      onDateBoxValueChanged(e) {
        this.workDate = e.value;
        this.targetAgentData = {};
        this.onSearch();
      },
      /**
       * 근무스케줄 조회
       * @return {Promise<*>}
       */
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'scheduleId',
          async load() {
            const payload = {
              actionName: 'EWM_WORK_SCHEDULE_LIST',
              data: {
                workDt: vm.workDate,
                excludeAgtidList: [vm.appointmentData.agentData.agtid],
              },
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            } else {
              vm.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /**
       * 행 클릭 이벤트
       * @param e
       */
      onRowClick(e) {
        this.targetAgentData = e.data;
      },
    },
    created() {},
    async mounted() {
      await this.onSearch();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-datagrid-header-panel {
      margin-bottom: 10px;
      padding: 10px 0 0 0;
    }
  }
</style>
