<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="pickupGrid"/>
    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.title"
        :width="modal.width"
        :height="modal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <ModalAddPickupEtc
          :isOpened="this.modal.isOpened"
          :cmList="this.cmList"
          :partitionList="this.partitionList"
          @closeModal="closeModal"
      />
    </DxPopup>
    <DxPopup
        v-model="infoModal.isOpened"
        :show-title="true"
        :title="infoModal.title"
        :width="infoModal.width"
        :height="infoModal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="infoModal.isOpened"
        @hiding="isOpenInfoModal(false)"
    >
      <ModalInfoPickup
          :isOpened="this.infoModal.isOpened"
          :rowInfo="this.rowInfo"
          :cmList="this.cmList"
          :type="this.type"
          :partitionList="this.partitionList"
          @closeModal="closeInfoModal"
      />
    </DxPopup>
  </div>
</template>

<script>
import {DxPopup} from 'devextreme-vue/popup';
import ModalAddPickupEtc from './modal-add-pickup-etc.vue';
import ModalInfoPickup from './modal-info-pickup.vue';
import {isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    EspDxDataGrid,
    DxPopup,
    ModalAddPickupEtc,
    ModalInfoPickup,
  },
  props: {
    cmList: {
      type: Array,
    },
    partitionList: {
      type: Array,
    },
  },
  data() {
    return {
      password: '',
      rePassword: '',
      modal: {
        isOpened: false,
        title: '개별 당겨받기 설정',
        width: '50%',
        height: '650',
      },
      infoModal: {
        isOpened: false,
        title: '개별 당겨받기 정보',
        width: '50%',
        height: '750',
      },
      stylingMode: 'outlined', //outlined, underlined, filled
      type: '',
      rowInfo: {},
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: '당겨받기 목록',
        },
        refName: 'pickupGrid',
        dataSourceDefaultSortColumn: '+callpickupGroupOrd, +id',
        apiActionNm: {
          select: 'EUC_PICKUP_LIST_ALL',
          delete: 'EUC_PICKUP_DELETE',
        },
        customEvent: {
          initNewRow: true,
        },
        showActionButtons: {
          excel: true,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '추가',
                elementAttr: {class: 'btn_XS default filled '},
                height: 30,
                onClick: () => {
                  this.isOpenModal(true);
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '당겨받기 동기화',
                elementAttr: {class: 'btn_XS default filled '},
                height: 30,
                onClick: () => {
                  this.onSyncPickupGroup();
                },
              },
              location: 'before',
            }
          ],
        },
        editing: {
          allowUpdating: false,
          allowAdding: false,
        },
        columns: [
          {
            caption: '교환기',
            dataField: 'cmNm',
            allowEditing: false,
            calculateCellValue: row => this.getCmNm(row?.cmNm),
          },
          {
            caption: '구분',
            dataField: 'type',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const spanTag = document.createElement('span');
              if (options.value === 'DEPT') {
                spanTag.innerText = '부서';
              } else {
                spanTag.innerText = '개별';
              }
              container.append(spanTag);
            },
          },
          {
            caption: '그룹명',
            dataField: 'name',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = options.value;

              aTag.addEventListener('click', async () => {
                // await this.onSelectData(options.data, type);
                await this.setType(options.data.type);
                await this.setRowInfo(options.data);
                await this.isOpenInfoModal(true);
              });
              container.append(aTag);
            },
          },
          {
            caption: '그룹번호',
            dataField: 'pattern',
            allowEditing: false,
          },
          {
            caption: '설명',
            dataField: 'description',
            allowEditing: false,
          },
          {
            caption: '파티션',
            dataField: 'routepartitionname',
            allowEditing: false,
          },
          {
            caption: '등록 내선',
            dataField: 'count',
            allowEditing: false,
          },
          {
            caption: '수정일',
            dataField: 'editDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.regDt),
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(data) {
      this.modal.isOpened = data;
    },
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenInfoModal(data) {
      this.infoModal.isOpened = data;
    },
    /**@description : 당겨받기관리 모달 close 콜백함수 */
    async closeModal(flag) {
      if (flag) {
        this.isOpenModal(false);
      }
    },
    /**@description : 당겨받기 리스트 모달 close 콜백함수 */
    closeInfoModal() {
      this.isOpenInfoModal(false);
    },
    setType(data) {
      this.type = data;
      this.infoModal.title = this.type === 'DEPT' ? '부서별 당겨받기 정보' : '개별 당겨받기 정보';
    },
    setRowInfo(data) {
      this.rowInfo = data;
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /** @description : 교환기 이름 변환 메서드 */
    getCmNm(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
    /** @description : 당겨받기 동기화 메서드 */
    async onSyncPickupGroup() {
      const confirm = this.$_lang('UC.MESSAGE.START_SYNC');

      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      const promises = this.cmList.map(async (item) => {
        const payload = {
          actionName: 'EUC_PICKUP_SYNC',
          data: {
            cmNm: item.cmNm,
            loginId: this.$store.getters.getLoginId
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$refs.pickupGrid.getInstance.refresh();
        } else {
          const msg = res.data.data.length === 0
              ? res.data.header.resMsg
              : '교환기 : ' + item.cmNm + ' ' + res.data.header.resMsg;
          await this.$_Msg(msg);
        }
      });

      await Promise.all(promises);
    },
  },
};
</script>
