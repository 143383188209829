var render = function render(){var _vm=this,_c=_vm._self._c;return _c('dx-popup',{attrs:{"show-title":true,"title":_vm.modal.title,"min-width":_vm.modal.minWidth,"width":_vm.modal.width,"max-height":_vm.modal.maxHeight,"height":_vm.modal.height,"drag-enabled":_vm.modal.dragEnabled,"resize-enabled":_vm.modal.resizeEnabled,"hide-on-outside-click":_vm.modal.closeOnOutsideClick,"show-close-button":_vm.modal.showCloseButton,"visible":_vm.isOpen},on:{"hiding":_vm.handleCloseModal},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('dx-scroll-view',[_c('div',{staticClass:"page-sub-box"},[_c('table',{staticClass:"table_form line-bin"},[_c('colgroup',[_c('col',{staticStyle:{"width":"130px"}}),_c('col',{staticStyle:{"width":"auto"}})]),_c('tbody',[_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('', { defaultValue: '사이트/센터' }))+" "),_c('span',{staticClass:"icon_require"},[_vm._v("필수항목")])])]),_c('td',[_c('dx-select-box',{attrs:{"placeholder":_vm.$_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}),"items":_vm.$store.getters.getSiteList,"display-expr":"siteNm","value-expr":"id","show-clear-button":_vm.config.showClearButton,"styling-mode":_vm.config.stylingMode,"width":150},model:{value:(_vm.formData.siteId),callback:function ($$v) {_vm.$set(_vm.formData, "siteId", $$v)},expression:"formData.siteId"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: _vm.$_lang('COMMON.WORD.SITE', {defaultValue: '사이트'}), defaultValue: '[사이트] 은/는 필수값 입니다' })}})],1)],1),_c('dx-select-box',{attrs:{"placeholder":_vm.$_lang('COMMON.WORD.TENANT', {defaultValue: '센터'}),"items":_vm.$store.getters.getTenantList,"display-expr":"tenantNm","value-expr":"id","show-clear-button":_vm.config.showClearButton,"styling-mode":_vm.config.stylingMode,"width":150},model:{value:(_vm.formData.tenantId),callback:function ($$v) {_vm.$set(_vm.formData, "tenantId", $$v)},expression:"formData.tenantId"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: _vm.$_lang('COMMON.WORD.TENANT', {defaultValue: '센터'}), defaultValue: '[센터] 은/는 필수값 입니다' })}})],1)],1)],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('', { defaultValue: '스킬그룹명' }))+" "),_c('span',{staticClass:"icon_require"},[_vm._v("필수항목")])])]),_c('td',[_c('dx-text-box',{staticClass:"mar_ri10",attrs:{"max-length":_vm.limitNumberTexts.maxLengths.sklGroupNm,"styling-mode":_vm.config.stylingMode,"width":300},model:{value:(_vm.formData.sklGroupNm),callback:function ($$v) {_vm.$set(_vm.formData, "sklGroupNm", $$v)},expression:"formData.sklGroupNm"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-async-rule',{attrs:{"reevaluate":false,"validationCallback":_vm.validateSklGroupNm,"message":_vm.$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                               { value: _vm.$_lang('', { defaultValue: '스킬그룹명' }), defaultValue: '[스킬그룹명] 값은 중복될 수 없습니다.' })}}),_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                { value: _vm.$_lang('', {defaultValue: '스킬그룹명'}), defaultValue: '[스킬그룹명] 은/는 필수값 입니다' })}})],1)],1),_c('span',[_vm._v(" "+_vm._s(_vm.limitNumberTexts.textLengths.sklGroupNm ? _vm.limitNumberTexts.textLengths.sklGroupNm : _vm.formData.sklGroupNm ? _vm.formData.sklGroupNm.length : 0)+" ")]),_vm._v("/"+_vm._s(_vm.limitNumberTexts.maxLengths.sklGroupNm)+" ")],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('', { defaultValue: '순서' })))])]),_c('td',[_c('dx-number-box',{staticClass:"mar_ri10",attrs:{"min":1,"show-spin-buttons":true,"validation-message-position":"right","width":200,"styling-mode":_vm.config.stylingMode,"format":"#"},model:{value:(_vm.formData.sklGroupOrd),callback:function ($$v) {_vm.$set(_vm.formData, "sklGroupOrd", $$v)},expression:"formData.sklGroupOrd"}},[_c('dx-validator',{attrs:{"validation-group":"validationGroupName"}},[_c('dx-required-rule',{attrs:{"message":_vm.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                  { value: _vm.$_lang('', {defaultValue: '순서'}), defaultValue: '[순서] 은/는 필수값 입니다' })}})],1)],1)],1)]),_c('tr',[_c('th',{attrs:{"scope":"row"}},[_c('label',{attrs:{"for":"label5"}},[_vm._v(_vm._s(_vm.$_lang('', { defaultValue: '사용여부' })))])]),_c('td',[_c('dx-switch',{staticClass:"mar_ri10",attrs:{"value":_vm.getViewFl},on:{"value-changed":function($event){return _vm.handleChangedViewFl($event)}}})],1)])])])])])]},proxy:true}])},[_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'default filled txt_S medium' },
          text: this.saveType === 'add'
            ? this.$_lang('COMPONENTS.REGISTER', { defaultValue: '등록' })
            : this.$_lang('COMPONENTS.EDIT', { defaultValue: '수정' }),
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          validationGroup: 'validationGroupName',
          onClick: _vm.saveModal,
        }}}),_c('dx-toolbar-item',{attrs:{"widget":"dxButton","toolbar":"bottom","location":"center","visible":true,"options":{
          elementAttr: { class: 'white filled txt_S medium' },
          text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          width: '120',
          height: '40',
          onClick: _vm.handleCloseModal,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }