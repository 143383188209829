<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2024-11-13
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 실험 설정
-->
<template>
  <div>
    <div class="page-sub-box pad_top10">
      <div class="p-2 space-y-4 border-1 border-gray-300 bg-gray-100 mar_b10">
        <table>
          <tbody>
          <tr class="flex w-full">
            <td class="flex w-1/4 pl-6">
              <div class="pr-2">{{ $_lang('LLM_TESTER.WORD.PROJECT', { defaultValue: '프로젝트' }) }}</div>
              <div class="pl-2" style="border-left: 2px solid rgba(209, 213, 219, 1);">{{ project_name }}</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
        <Tab :title="this.$_lang('LLM_TESTER.WORD.SYSTEM_PROMPT', {defaultValue : '시스템 프롬프트'})">
          <SystemPrompt ref="systemPromptRef" :project_id="project_id" />
        </Tab>
        <Tab :title="this.$_lang('LLM_TESTER.WORD.QUERY_SET', {defaultValue : '질의 세트'})">
          <QuestionConfig ref="questionConfigRef" :project_id="project_id" />
        </Tab>
        <Tab :title="this.$_lang('LLM_TESTER.WORD.MODEL', {defaultValue : '모델'})">
          <ModelConfig ref="modelConfigRef" :project_id="project_id" />
        </Tab>
        <Tab :title="this.$_lang('LLM_TESTER.WORD.EVALUATION_PROMPT', {defaultValue : '평가 프롬프트'})">
          <EvaluationPrompt ref="evaluationPromptRef" :project_id="project_id" />
        </Tab>
      </Tabs>
    </div>

    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <h2 class="hidden">일반 버튼</h2>
        <div class="bottom-btn-wrap">
          <DxButton
            :text="this.$_lang('LLM_TESTER.WORD.ADD_TEST', {defaultValue : '실험 추가'})"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            :use-submit-behavior="true"
            @click="onClickRunningTest"
          />
          <DxButton
            :text="this.$_lang('COMPONENTS.RETURN', {defaultValue : '돌아가기'})"
            :width="120"
            :height="40"
            class="white filled txt_S medium"
            @click="onClickGoToList"
          />
        </div>
      </div>
    </section>

    <!-- 실험 추가 팝업 -->
    <RunningTestWork
      v-if="modal.runningTest.visible"
      :is-open="modal.runningTest.visible"
      :project_id="project_id"
      :system_prompt_id="$refs.systemPromptRef.selectedPrompt.id"
      :evaluation_prompt_id="$refs.evaluationPromptRef.selectedPrompt.id"
      @closeModal="onCloseModal('runningTest')"
    />
  </div>
</template>

<script>
  import { DxButton } from "devextreme-vue/button";
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import SystemPrompt from '@/pages/ai/llm-tester/work-config/system-prompt.vue';
  import QuestionConfig from '@/pages/ai/llm-tester/work-config/question-config.vue';
  import ModelConfig from '@/pages/ai/llm-tester/work-config/model-config.vue';
  import EvaluationPrompt from '@/pages/ai/llm-tester/work-config/evaluation-prompt.vue';
  import RunningTestWork from "@/pages/ai/llm-tester/work-config/running-test-work.vue";

  export default {
    components: {
      RunningTestWork,
      DxButton,
      Tabs,
      Tab,
      SystemPrompt,
      QuestionConfig,
      ModelConfig,
      EvaluationPrompt,
    },

    data() {
      return {
        project_id: null,
        project_name: null,
        tabIndex: 0,
        modal: {
          runningTest: {
            visible: false,
          },
        },
      };
    },

    computed: {},

    watch: {
      tabIndex(index) {
        this.$nextTick(() => {
          switch (index) {
            case 1:
              this.$refs.questionConfigRef.setGridHeight();
              break;
            case 2:
              this.$refs.modelConfigRef.setGridHeight();
              break;
          }
        });
      }
    },

    methods: {
      /** @description 탭 클릭시 저장값 체크및 index 저장 */
      async tabSelectedIndex(index) {
        if (index !== 0) {
          if (this.$refs.systemPromptRef.isChangeContent) {
            if (await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.UNSAVED_PROMPT_MOVE_ALERT', { defaultValue: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?' }))) {
              this.$refs.systemPromptRef.selectedPrompt.content = this.$refs.systemPromptRef.originContent;
              this.$refs.systemPromptRef.isChangeContent = false;
            } else {
              this.$refs.tabs.selectTab(0);
              return;
            }
          }
        }

        if (index !== 3) {
          if (this.$refs.evaluationPromptRef.isChangeContent) {
            if (await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.UNSAVED_PROMPT_MOVE_ALERT', { defaultValue: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?' }))) {
              this.$refs.evaluationPromptRef.selectedPrompt.content = this.$refs.evaluationPromptRef.originContent;
              this.$refs.evaluationPromptRef.isChangeContent = false;
            } else {
              this.$refs.tabs.selectTab(3);
              return;
            }
          }
        }

        this.tabIndex = index;
      },

      /** @description 실험 추가 클릭 */
      onClickRunningTest() {
        const message = this.getCheckSettingMessage();
        if (message.length === 0) {
          this.modal['runningTest'].visible = true;
        } else {
          this.$_Msg(message.join('<br/>'));
        }
      },

      /** @description 돌아가기 클릭 */
      async onClickGoToList() {
        if (this.$refs.systemPromptRef.isChangeContent || this.$refs.evaluationPromptRef.isChangeContent) {
          if (!await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.UNSAVED_PROMPT_MOVE_ALERT', { defaultValue: '작업한 프롬프트 내용이 저장되지 않았습니다. <br>이동 하시겠습니까?' }))) {
            return;
          }
        }
        await this.$router.push('/ai/llm-tester/list');
      },

      /** @description 필수 셋팅 값 알림 메세지 반환 메서드 */
      getCheckSettingMessage() {
        let message = [];
        if (!this.$refs.systemPromptRef.isSelectedPrompt) {
          message.push(`${ this.$_lang('LLM_TESTER.WORD.SYSTEM_PROMPT', { defaultValue: '시스템 프롬프트' }) } :
          ${ this.$_lang('LLM_TESTER.MESSAGE.UNSET_PROMPT', { defaultValue: '프롬프트가 선택되어 있지 않습니다.' }) }`);
        }

        if (this.$refs.questionConfigRef.dataGrid.dataSource.length === 0) {
          message.push(`${ this.$_lang('LLM_TESTER.WORD.QUERY_SET', { defaultValue: '질의 세트' }) } :
          ${ this.$_lang('LLM_TESTER.MESSAGE.UNSET_CONFIG', { defaultValue: '설정값이 존재하지 않습니다.' }) }`);
        }

        if (this.$refs.modelConfigRef.dataGrid.dataSource.length === 0) {
          message.push(`${ this.$_lang('LLM_TESTER.WORD.MODEL', { defaultValue: '모델' }) } :
          ${ this.$_lang('LLM_TESTER.MESSAGE.UNSET_CONFIG', { defaultValue: '설정값이 존재하지 않습니다.' }) }`);
        }

        if (!this.$refs.evaluationPromptRef.isSelectedPrompt) {
          message.push(`${ this.$_lang('LLM_TESTER.WORD.EVALUATION_PROMPT', { defaultValue: '평가 프롬프트' }) } :
          ${ this.$_lang('LLM_TESTER.MESSAGE.UNSET_PROMPT', { defaultValue: '프롬프트가 선택되어 있지 않습니다.' }) }`);
        }

        return message;
      },

      /**
       * @description 팝업 닫기
       * @param modalType 팝업 모달 타입(runningTest)
       * */
      onCloseModal(modalType) {
        this.modal[modalType].visible = false;
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.project_id = this.$route.query.id;
      this.project_name = this.$route.query.name;
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {

    },
  };
</script>

<style lang="scss" scoped>
  .border-1 {
    border-width: 1px;
  }
  ::v-deep {
    .ecs-large-tab {
      display: flex;
    }

    .ecs-large-tab > ul > li:first-child {
      margin: 0;
    }

    .ecs-large-tab > ul {
      flex-direction: column;
      text-align: center;
    }

    .ecs-large-tab > ul > li {
      width: 100% !important;
      height: 60px !important;
      text-align: left !important;
      margin: 0 20px 0 0 !important;
    }

    .ecs-large-tab > ul > li::after {
      width: 5px;
      height: 100% !important;
      left: unset;
      right: 0;
      background-color: unset;
    }

    .ecs-tab-box {
      margin: 0 0 0 0 !important;
    }

    .page-sub-box {
      width: 100% !important;
    }

    .dx-datagrid-header-panel {
      padding-top: 0;
    }
  }
</style>
