import InfoPushActionRoutes from '@/router/euc/info-push/action';
import InfoPushContentRoutes from '@/router/euc/info-push/content';
import InfoPushHistoryRoutes from "@/router/euc/info-push/history";
import InfoPushImagesRoutes from "@/router/euc/info-push/images";
import InfoPushSettingRoutes from "@/router/euc/info-push/setting";
import InfoPushSiteRoutes from "@/router/euc/info-push/site";
import InfoPushTextRoutes from "@/router/euc/info-push/text";

export default function InfoPushRoutes(commonComponents) {
    return [
        ...InfoPushActionRoutes(commonComponents),
        ...InfoPushContentRoutes(commonComponents),
        ...InfoPushHistoryRoutes(commonComponents),
        ...InfoPushImagesRoutes(commonComponents),
        ...InfoPushSettingRoutes(commonComponents),
        ...InfoPushSiteRoutes(commonComponents),
        ...InfoPushTextRoutes(commonComponents),
    ];
}