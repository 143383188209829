<template>
	<div>
		<div class="page-sub-box ewm">
			<div class="tree-grid">
				<div class="space-y-6 pt-8">
					<div class="flex gap-2">
						<DxButton
							text="조회"
							class="btn_M box-btn-search default filled"
							type="button"
							:height="30"
							@click="selectTargetDataList"
						/>
						<DxButton 
							class="btn_XS green ligt_filled" 
							:height="30" 
							icon="export"
							@click="downloadExcel">
						</DxButton>
					</div>
					<div>
						<DxTreeList
							key-expr="codeId"
							parent-id-expr="parentId"
							:root-value="940"
							ref="workStatusGrid"
							:data-source="workStatusData.dataSource"
							:allow-column-resizing="true"
							:column-resizing-mode="'nextColumn'"
							:show-borders="false"
							:show-column-headers="true"
							:show-column-lines="true"
							:show-row-lines="true"
							:row-alternation-enabled="false"
							:hover-state-enabled="true"
							:word-wrap-enabled="true"
							:no-data-text="this.$_lang('CMN_NO_DATA')"
							:expanded-row-keys="workStatusData.expandedRowKeys"
							:auto-expand-all="true"
							width="100%"
						>
							<DxLoadPanel :enabled="true" />

							<DxColumn caption="부서정보" data-field="codeNm" :allowEditing="false" :allow-sorting="true" width="220" />
							<DxColumn caption="근속기간">
								<DxColumn
									caption="3개월 미만"
									data-field="threeMonth"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="6개월 미만"
									data-field="sixMonth"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="1년 미만"
									data-field="oneYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="2년 미만"
									data-field="twoYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="3년 미만"
									data-field="threeYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="5년 미만"
									data-field="fiveYear"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="5년 이상"
									data-field="fiveYearMore"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
								<DxColumn
									caption="합계"
									data-field="totalCount"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="true"
								/>
							</DxColumn>
							<DxColumn
								caption="평균재직기간"
								data-field="avgPeriodString"
								alignment="center"
								:allowEditing="false"
								:allow-sorting="true"
							/>
						</DxTreeList>
					</div>
				</div>
			</div>

			<!-- Modal Layer -->
			<DxPopup
				:show-title="true"
				:title="modal.initData ? modal.initData.title : null"
				:min-width="modal.initData ? modal.initData.width : null"
				:width="modal.initData ? modal.initData.width : null"
				:min-height="modal.initData ? modal.initData.height : null"
				:height="modal.initData ? modal.initData.height : null"
				:drag-enabled="true"
				:resize-enabled="true"
				:show-close-button="true"
				:hide-on-outside-click="false"
				v-model="modal.isOpened"
				:visible="modal.isOpened"
				@hiding="isOpenModal(false)"
				class="text-left"
			>
				<template #content>
					<div>
					<component  
						ref="reasonModalRef"
						v-model="modal.contentData"
						:is="modal.currentComponent"
						:contentData="modal.contentData"
						:isModal="modal.isModal"
					></component>
					</div>
				</template>
				<DxToolbarItem
					widget="dxButton"
					toolbar="bottom"
					location="center"
					:visible="true"
					:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
					width: '120',
					height: '40',
					onClick: () => this.isOpenModal(false),
					}"
				/>
				<DxToolbarItem
					widget="dxButton"
					toolbar="bottom"
					location="center"
					:visible="true"
					:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
					width: '120',
					height: '40',
					onClick: () => this.onDownloadReason(),
					}"
				/>
			</DxPopup>
			<!-- /Modal Layer --> 
		</div>
	</div>
</template>

<script>
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { DxButton } from 'devextreme-vue/button';
import { DxColumn, DxLoadPanel } from 'devextreme-vue/data-grid';
import { DxTreeList } from 'devextreme-vue/tree-list';
import { isSuccess, formatDate } from '@/utils/common-lib';

let vm = null;

export default {
	components: {
		DxButton,
		DxColumn,
		DxLoadPanel,
		DxTreeList,
		DxPopup,
		DxToolbarItem,
		ModalDownloadReason,
	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			codes: {
				dept1: {
					dataSource: [],
				},
				dept2: {
					dataSource: [],
				},
				dept3: {
					dataSource: [],
				},
			},
			modal: {
				isOpened: false,
				initData: {},
				contentData: null,
				componentName: '',
				currentComponent: null,
			},
			deptData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				expandedRowKeys: [],
				isLoaded: true,
			},
			workStatusData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				expandedRowKeys: [],
				isLoaded: true,
			},
			targetGridData: {
				excel: {
					title: '재직기간별통계'
				},
				dataSource: [],
				selectedRowsData: [],
				selectedRowKeys: [],
				expandedRowKeys: [],
				isLoaded: true,
			},
		};
	},
	computed: {},
	methods: {
		downloadExcel(e) {
			const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
			e = this.makeSaveHistory(e);
			if (useDownReason) {
				e.cancel = true;
				this.onOpenModal(
					'ModalDownloadReason',
					{
						title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
						width: '600',
						height: '400',
					},e,
				);
			} else {
				this.onExporting(e);
			}
		},
		makeSaveHistory(event, reason = '') {
			const user = {
				userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
				userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
				deptNm: this.$store.getters.getUserInfo?.deptNm || '',
				gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
			};

			const payload = {
				actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
				data: [
					{
						...user,
						reason,
						fileType: 'EXCEL',
						fileNm: this.title?.title,
					},
				],
				loading: false,
			};

			event.onSaveHistory = async fileNm => {
				payload.data[0].fileNm = fileNm || this.targetGridData.excel?.title;
				return await this.CALL_API(payload);
			};
			return event;
		},
		onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
				this.modal.contentData = null;
			}
		},
		onDownloadReason() {
			let event = this.modal.contentData;
			const reason = this.$refs.reasonModalRef.reason;
			if (reason.trim() === '') {
				this.$_Msg(
					this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
						defaultValue: '다운로드 사유를 입력하세요.',
					}),
				);
				event.cancel = true;
			} else {
				event = this.makeSaveHistory(event, reason);
				event.cancel = false;
				this.onExporting(event);
				this.isOpenModal(false);
			}
		},
		onExporting(e) {
			const grid = this.$refs.workStatusGrid.instance;
  			const columnsInfo = grid.getVisibleColumns();

			const title = this.targetGridData.excel.title;
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet(title);

			const columnsArr = columnsInfo.map(col => ({
				width: col.cellwidth || 30,
				header: col.caption, 
				key: col.dataField 
			}));
			worksheet.columns = columnsArr;

			const today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

			const headerStyle = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
			const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
			const border = {
				bottom: borderStyle,
				left: borderStyle,
				right: borderStyle,
				top: borderStyle,
			};
			const alignment = { horizontal: 'center', vertical: 'middle' };
			const dataBackgroundStyle = {
				type: 'pattern',
				pattern: 'solid',
				fgColor: { argb: 'F2F2F2' },
				bgColor: { argb: 'F2F2F2' },
			};

			const titleRow = worksheet.addRow([title]);
			titleRow.height = 40;
			worksheet.mergeCells(1, 1, 1, columnsInfo.length);
			titleRow.getCell(1).font = { size: 22, bold: true };
			titleRow.getCell(1).alignment = alignment;

			const headerRow = worksheet.addRow(columnsInfo.map(col => col.caption));
			headerRow.eachCell((cell) => {
				cell.fill = headerStyle;
				cell.border = border;
				cell.alignment = alignment;
			});

			const data = grid.getDataSource().store().load();
			console.log('엑셀로 내보낼 데이터:', data);

			grid.getDataSource().store().load().then(data => {

				data.forEach((item, index) => {
					const rowData = columnsInfo.map(col => {
					const value = item[col.dataField];
						return value !== undefined ? value : '';
					});

					const row = worksheet.addRow(rowData);

					if ((index + 1) % 2 === 0) {
						row.eachCell(cell => {
							cell.fill = dataBackgroundStyle;
						});
					}

					row.eachCell(cell => {
						cell.border = border;
						cell.alignment = alignment;
					});
				});

				return workbook.xlsx.writeBuffer();
			})
			.then(buffer => {
				const fileName = `${title}_${today}.xlsx`;
				saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
				e.onSaveHistory(fileName);
			})

			e.cancel = true;
		},
		/** @description : 조회 */
		async selectTargetDataList() {
			this.$refs['workStatusGrid'].instance.beginCustomLoading();

			const payload = {
				actionName: 'EWM_AGENT_SEARCH',
				loading: false,
			};
			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				const filteredData = res.data.data.filter(item => item.userStateCd != 'RETIRED' && item.delFl == 'N');
				const currentDate = new Date();
				filteredData.forEach(d => {
					const joinDate = new Date(d.joinDt); //입사일자
					const differenceInTime = currentDate - joinDate;
					const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24) + 1);
					d.totalPeriod = differenceInDays; //전체 일수
					const months = Math.floor(differenceInDays / 30);
					d.monthsPeriod = months; //개월
					d.center = parseInt(d.deptCdPath.slice(0, 3)); //센터
					d.department = parseInt(d.deptCdPath.slice(4, 7)); //파트
					d.team = parseInt(d.deptCdPath.slice(8, 11)); //팀
				});
				this.targetGridData.dataSource = filteredData;
			}

			this.deptData.dataSource.forEach(w => {
				w.threeMonth = 0;
				w.sixMonth = 0;
				w.oneYear = 0;
				w.twoYear = 0;
				w.threeYear = 0;
				w.fiveYear = 0;
				w.fiveYearMore = 0;
				w.totalCount = 0;
				w.avgPeriod = 0;

				this.targetGridData.dataSource.forEach(t => {
					if (t.center === w.codeId) {
						w.totalCount += 1;
						w.avgPeriod += t.totalPeriod;
						switch (true) {
							case t.monthsPeriod < 3:
								w.threeMonth += 1;
								break;
							case t.monthsPeriod < 6:
								w.sixMonth += 1;
								break;
							case t.monthsPeriod < 12:
								w.oneYear += 1;
								break;
							case t.monthsPeriod < 24:
								w.twoYear += 1;
								break;
							case t.monthsPeriod < 36:
								w.threeYear += 1;
								break;
							case t.monthsPeriod < 48:
								w.fiveYear += 1;
								break;
							case t.monthsPeriod > 47:
								w.fiveYearMore += 1;
								break;
							default:
								break;
						}
					} else if (t.department === w.codeId) {
						w.totalCount += 1;
						w.avgPeriod += t.totalPeriod;
						switch (true) {
							case t.monthsPeriod < 3:
								w.threeMonth += 1;
								break;
							case t.monthsPeriod < 6:
								w.sixMonth += 1;
								break;
							case t.monthsPeriod < 12:
								w.oneYear += 1;
								break;
							case t.monthsPeriod < 24:
								w.twoYear += 1;
								break;
							case t.monthsPeriod < 36:
								w.threeYear += 1;
								break;
							case t.monthsPeriod < 48:
								w.fiveYear += 1;
								break;
							case t.monthsPeriod > 47:
								w.fiveYearMore += 1;
								break;
							default:
								break;
						}
					} else if (t.team === w.codeId) {
						w.totalCount += 1;
						w.avgPeriod += t.totalPeriod;
						switch (true) {
							case t.monthsPeriod < 3:
								w.threeMonth += 1;
								break;
							case t.monthsPeriod < 6:
								w.sixMonth += 1;
								break;
							case t.monthsPeriod < 12:
								w.oneYear += 1;
								break;
							case t.monthsPeriod < 24:
								w.twoYear += 1;
								break;
							case t.monthsPeriod < 36:
								w.threeYear += 1;
								break;
							case t.monthsPeriod < 48:
								w.fiveYear += 1;
								break;
							case t.monthsPeriod > 47:
								w.fiveYearMore += 1;
								break;
							default:
								break;
						}
					}

					if (w.totalCount < 1) {
						w.avgPeriodString = `-`;
					} else {
						const totalMonths = Math.floor(w.avgPeriod / w.totalCount / 30);

						if (totalMonths < 11) {
							w.avgPeriodString = `${totalMonths}개월`;
						} else {
							const totalYears = Math.floor(totalMonths / 12);
							const remainingMonths = totalMonths % 12;
							w.avgPeriodString = `${totalYears}년${remainingMonths > 0 ? '개월' : ''}`;
						}
					}
				});
			});

			this.workStatusData.dataSource = this.deptData.dataSource;

			this.$refs['workStatusGrid'].instance.endCustomLoading();
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_hr_dept');
		},
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.codes.dept1.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 2);
				this.codes.dept2.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 3);
				this.codes.dept3.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);

				this.deptData.dataSource = [...this.codes.dept3.dataSource, ...this.codes.dept2.dataSource, ...this.codes.dept1.dataSource];
			});
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			this.selectTargetDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>
