import InfoPushText from '@/pages/euc/info-push/text.vue';

export default function InfoPushTextRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/text',
            name: 'InfoPushText',
            components: { default: InfoPushText, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}