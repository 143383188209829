import PerformSchedule from '@/pages/ewm/kpi/implementation/perform-sche/index.vue';
import PerformStandard from '@/pages/ewm/kpi/implementation/perform-std/index.vue';
import MetricsResult from '@/pages/ewm/kpi/report/metrics-result/index.vue';
import AddScore from '@/pages/ewm/kpi/setting/addscore/index.vue';
import AddScoreTarget from '@/pages/ewm/kpi/setting/addscore-target/index.vue';
import PerformStandardSetting from '@/pages/ewm/kpi/setting/perform-std/index.vue';

export default function KpiRoutes(commonComponents) {
  return [
    // KPI Implementation
    {
      path: '/ewm/kpi/implementation/perform-sche',
      name: 'PerformSchedule',
      components: { default: PerformSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/implementation/perform-std',
      name: 'PerformStandard',
      components: { default: PerformStandard, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // KPI Report
    {
      path: '/ewm/kpi/report/metrics-result',
      name: 'MetricsResult',
      components: { default: MetricsResult, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // KPI Setting
    {
      path: '/ewm/kpi/setting/addscore',
      name: 'AddScore',
      components: { default: AddScore, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/setting/addscore-target',
      name: 'AddScoreTarget',
      components: { default: AddScoreTarget, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/setting/perform-std',
      name: 'PerformStandardSetting',
      components: { default: PerformStandardSetting, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}