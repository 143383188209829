var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full"},[_c('table',{staticClass:"table_form line-bin"},[_vm._m(0),_c('thead',{staticClass:"sub_title_txt"},[_c('tr',[_c('td',{staticClass:"flex items-center"},[(_vm.redisData)?_c('color-badge',{attrs:{"color":"green"}},[_vm._v("양호")]):_c('color-badge',{attrs:{"color":"red"}},[_vm._v("불량")]),_c('h2',[_vm._v("서버 정보")]),_c('button',{staticClass:"btn-icon",staticStyle:{"border":"0"},attrs:{"type":"button"},on:{"click":_vm.selectDataList}},[_c('img',{attrs:{"src":require("@/assets/images/icon/refresh_icon.png"),"alt":"새로고침 아이콘"}})])],1)])]),(_vm.redisData)?_c('tbody',[_c('tr',[_vm._m(1),_c('td',[_vm._v(" "+_vm._s(_vm.redisData.redisVersion)+" ")]),_vm._m(2),_c('td',[_vm._v(" "+_vm._s(_vm.redisData.redisMode)+" ")]),_vm._m(3),_c('td',[_vm._v(" "+_vm._s(_vm.redisData.processId)+" ")]),_vm._m(4),_c('td',[_vm._v(" "+_vm._s(_vm.redisData.os)+" ")])]),_c('tr',[_vm._m(5),_c('td',[_vm._v(" "+_vm._s(_vm.redisData.tcpPort)+" ")]),_vm._m(6),_c('td',[_vm._v(" "+_vm._s(Number(_vm.redisData.uptimeInDays).toLocaleString())+"일 ")]),_vm._m(7),_c('td',[_vm._v(" "+_vm._s(_vm.redisData.configFile)+" ")]),_vm._m(8),_c('td',[_vm._v(" "+_vm._s(Number(_vm.redisData.connectedClients).toLocaleString())+" ")])]),_c('tr',[_vm._m(9),_c('td',[_vm._v(" "+_vm._s(Number(_vm.bytesToGB(_vm.redisData.totalSystemMemory)).toLocaleString())+"GB ")]),_vm._m(10),_c('td',[_vm._v(" "+_vm._s(Number(_vm.bytesToMB(_vm.redisData.usedMemory)).toLocaleString())+"MB ")]),_vm._m(11),_c('td',[_vm._v(" "+_vm._s(Number(_vm.redisData.totalConnectionsReceived).toLocaleString())+" ")]),_vm._m(12),_c('td',[_vm._v(" "+_vm._s(Number(_vm.redisData.totalCommandsProcessed).toLocaleString())+" ")])])]):_c('tbody',[_vm._m(13)])]),(_vm.redisData)?_c('RedisItems'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('colgroup',[_c('col',{staticStyle:{"width":"150px"}}),_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"150px"}}),_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"150px"}}),_c('col',{staticStyle:{"width":"auto"}}),_c('col',{staticStyle:{"width":"150px"}}),_c('col',{staticStyle:{"width":"auto"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("Redis 버전")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("운영 모드")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("PID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("운영체제")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("TCP 포트")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("실행 시간")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("설정 파일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("연결된 클라이언트")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("시스템 메모리")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("사용중인 메모리")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("요청된 명령 수")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('label',[_vm._v("처리된 명령 수")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"8"}},[_vm._v("Redis 연결에 실패하였습니다.")])])
}]

export { render, staticRenderFns }