import BulkWorkTimer from '@/pages/euc/bulk-work/timer.vue';
import BulkWorkHistory from '@/pages/euc/bulk-work/history.vue';

export default function BulkWorkRoutes(commonComponents) {
    return [
        {
            path: '/euc/bulk-work/timer',
            name: 'BulkWorkTimer',
            components: { default: BulkWorkTimer, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/bulk-work/history',
            name: 'BulkWorkHistory',
            components: { default: BulkWorkHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}