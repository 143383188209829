<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/policy
  FILE_NAME : exception.vue
  AUTHOR : jhcho
  DATE : 2024-06-11
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="exceptionGrid" />
    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="exceptionModalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isOpened="modal.isOpened"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: $_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
						width: '120',
						height: '40',
						onClick: () => onModalSave()
					}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => isOpenModal(false)
					}"
      />
    </DxPopup>
  </div>
</template>
<script>
import Search from '@/components/common/search.vue';
import {
  formatDate,
  isSuccess
} from '@/utils/common-lib';
import {DxSelectBox} from "devextreme-vue/select-box";
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";
import ModalException from './modal-exception.vue';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  props: {
    deptList: {
      type: Array,
      default: () => []
    },
    userList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DxToolbarItem, DxPopup,
    DxSelectBox,
    Search,
    ModalException,
    EspDxDataGrid
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        sendData: null,
      },
      stylingMode: 'outlined',
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: this.$_lang('UC.WORD.EXCEPTION_POLICY', { defaultValue: '예외 정책' })
        },
        refName: 'exceptionGrid',
        dataSourceDefaultSort: '-regDt',
        apiActionNm: {
          select: 'EUC_EXCEPTION_POLICY_SELECT',
          delete: 'EUC_EXCEPTION_POLICY_DELETE'
        },
        showActionButtons: {
          excel: true,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '추가',
                elementAttr: { class: 'btn_XS default filled add1' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.$refs.exceptionGrid.getInstance.clearSelection();
                  const title = '예외 정책 설정';
                  const sendData = {
                    userList: this.userList,
                    deptList: this.deptList
                  }
                  this.onOpenModal(
                      'ModalException',
                      {
                        title,
                        width: '50vw',
                        height: 'calc(100vh - 400px)',
                        buttons:{
                          save : { text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }) },
                          cancel : { text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }) },
                        },
                      },
                      sendData
                  );
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '변경',
                elementAttr: { class: 'btn_XS default filled' },
                width: 60,
                height: 30,
                onClick: () => {
                  const selectedRowsData = this.$refs.exceptionGrid.getInstance.getSelectedRowsData();
                  if(!selectedRowsData?.length) {
                    this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
                  } else if(selectedRowsData.length > 1) {
                    this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT', {defaultValue: '하나의 대상만 선택해 주세요.'}));
                  } else {
                    const title = '예외 정책 변경';
                    const sendData = {
                      userList: this.userList,
                      deptList: this.deptList,
                      selectedData: selectedRowsData[0]
                    }
                    this.onOpenModal(
                        'ModalException',
                        {
                          title,
                          width: '50vw',
                          height: 'calc(100vh - 400px)',
                          buttons:{
                            save : { text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }) },
                            cancel : { text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }) },
                          },
                        },
                        sendData
                    );
                  }
                },
              },
              location: 'before',
            },
          ]
        },
        page: {
          enabled: true,
        },
        editing: {
          allowUpdating: false,
          allowAdding: false,
        },
        columns: [
          {
            caption: '구분',
            i18n: 'COMPONENTS.TYPE',
            dataField: 'exceptGd',
            allowEditing: false,
            lookup: {
              dataSource: [
                {
                  label: 'IPT 예외 사용자',
                  value: 'CTC'
                },
                {
                  label: '차이니즈월',
                  value: 'CHW'
                },
              ],
              displayExpr: 'label',
              valueExpr: 'value'
            }
          },
          {
            multiHeaderNm: '예외 설정',
            i18n: 'UC.WORD.EXCEPTION_SETTING',
            columns: [
              {
                caption: '사용자',
                i18n: 'UC.WORD.USER',
                dataField: 'targetUser',
                allowSorting: false,
                allowEditing: false,
              },
              {
                caption: '부서코드',
                i18n: 'UC.WORD.DEPT_CODE',
                dataField: 'deptCd',
                allowEditing: false,
              },
            ]
          },
          {
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: (data) => this.formatDt(data?.regDt),
          },
        ],
      },
    }
  },
  methods: {
    async onModalSave() {
      const exceptGd = this.$refs.exceptionModalRef.exceptGd;
      const userCd = this.$refs.exceptionModalRef.userCd;
      const deptCd = this.$refs.exceptionModalRef.deptCd;
      const params = {
        exceptGd,
        userCd,
        deptCd
      }

      const selectedRowsData = this.$refs.exceptionGrid.getInstance.getSelectedRowsData();
      if(selectedRowsData?.length) {
        const selectedRowData = selectedRowsData[0];
        params.id = selectedRowData.id;
      }

      const payload = {
        actionName: 'EUC_EXCEPTION_POLICY_UPDATE',
        data: params,
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', {defaultValue: '정상적으로 처리되었습니다.'}), {icon: 'success'});
        this.isOpenModal(false);
        this.$refs.exceptionGrid.getInstance.refresh();
      } else {
        this.$_Msg(res.data.header?.resMsg || this.$_lang('COMMON.MESSAGE.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}), {icon: 'error'});
      }
    },
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      if(!bool) {
        this.$refs.exceptionModalRef.clearForm();
      }
      this.modal.isOpened = bool;
    },
    /** @description: 일자 데이터 출력 */
    formatDt(date) {
      if (date) {
        return formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      } else {
        return '-';
      }
    },
  },
}
</script>
<style scoped></style>