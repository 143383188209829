<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\playground
  FILE_NAME : show-document
  AUTHOR : hpmoon
  DATE : 2024-12-04
  DESCRIPTION : 운영관리 > LLM_TESTER > Playground Chat > 검색 문서 보기
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="$emit('closeModal')"
  >
    <template #content>
      <div>
        <table id="tableForm" class="table_form line-bin">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: calc(50vh - 130px)" />
            <col style="width: 130px" />
            <col style="width: calc(50vh - 130px)" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.SEARCH_DOCUMENT_NAME', { defaultValue: '검색 문서명' }) }}</label>
            </th>
            <td>
              {{ contentData.title }}
            </td>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.CHUNK_ORDER', { defaultValue: '청크 순서' }) }}</label>
            </th>
            <td>
              {{ contentData.chunk_order }}
            </td>
          </tr>
          </tbody>
        </table>
        <DxScrollView class="dx-texteditor dx-editor-outlined mar_to10" width="100%" :height="scrollViewHeight">
          <div class="pad_5" v-html="convertDocument" />
        </DxScrollView>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
            width: '120',
            height: '40',
            onClick: () => {
              this.$emit('closeModal');
            },
          }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
  import { DxScrollView } from "devextreme-vue/scroll-view";

  export default {
    components: {
      DxScrollView,
      DxPopup,
      DxToolbarItem,
    },

    props: {
      isOpen: Boolean,
      contentData: Object,
    },

    data() {
      return {
        modal: {
          title: this.$_lang('LLM_TESTER.WORD.SHOW_DOCUMENT', { defaultValue: '검색 문서 보기' }),
          minWidth: '900',
          width: '900',
          minHeight: '785',
          height: '785',
          dragEnabled: true,
          resizeEnabled: true,
          closeOnOutsideClick: false,
          showCloseButton: true,
        },
        scrollViewHeight: '0px', // ScrollView 높이
      };
    },

    computed: {
      /** @description 문서 내용 변환 */
      convertDocument() {
        return this.contentData.body.replaceAll("\n", "<br/>")
      },
    },

    methods: {
      /** @description Element 높이 계산 메서드 */
      heightElement(e) {
        const divElement = document.querySelector(e);
        const computedStyle = window.getComputedStyle(divElement);
        const divHeight = divElement.offsetHeight;
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return divHeight + marginTop + marginBottom;
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {

    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {
      this.scrollViewHeight = `calc(643px - ${ this.heightElement('#tableForm') }px )`;
    },
  };
</script>

<style lang="scss" scoped>
  .pad_5 {
    padding: 5px;
  }
</style>