<template>
  <div :class="bgStyleClass" style="height: 100%">
    <div id="wrap" ref="wrapRef" class="wrap" v-if="!hasSingleRouterView">
      <!-- Header -->
      <router-view name="top" />

      <!-- side pannel icon -->
      <router-view name="left" />

      <!-- Main contents -->
      <main :class="dynamicMainClass">
        <div class="content">
          <div class="h-14 w-full theme-background" />
          <div class="themes-content-wrap">
            <div class="themes-content-wrap-inner">
              <div v-if="changedColor()" class="themes-header-txt-bg" />
              <keep-alive exclude="Dashboard">
                <router-view :key="$route.params?.menuId" />
              </keep-alive>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div id="popLayer" class="popDim"></div>
    <router-view name="singlePage" />
  </div>
</template>

<script>
  import { EventBus } from '@/event-bus';

  export default {
    name: 'App',
    components: {},
    data() {
      return {};
    },
    computed: {
      // 현재 라우트에서 `singlePage` 컴포넌트가 있는지 확인하는 계산 속성
      hasSingleRouterView() {
        return !!this.$route.matched.find(record => record.components && record.components.singlePage);
      },
      isRootPath() {
        return this.$route.path === '/';
      },
      bgStyleClass() {
        return this.isRootPath ? 'bgstyle-02' : '';
      },
      dynamicMainClass() {
        return {
          'main-dash': this.isRootPath,
          'height-over': true,
        };
      },
    },
    methods: {
      /**
       * 탭 닫을 시 keep-alive 캐시에서 제거
       * @param menuId 메뉴 ID
       */
      removeKeepAliveCacheByMenuId(menuId) {
        this.$children.forEach((child, index) => {
          const aliveKey = child.$vnode.key;
          if (aliveKey === menuId) {
            const keepAliveInstance = child.$vnode.parent.componentInstance;
            if (keepAliveInstance?.cache[aliveKey]) {
              delete keepAliveInstance.cache[aliveKey]; // 캐시 제거
              keepAliveInstance.keys = keepAliveInstance.keys.filter(key => key !== aliveKey);
            }
            this.$children.splice(index, 1); // 배열에서 제거 (UI 갱신 보장)
          }
        });
      },
      /**
       * 하위 컴포넌트에서 ref 를 사용하기 위함
       * */
      getWrapElement() {
        return this.$refs.wrapRef;
      },
      /**
       * 로딩바를 보여주거나 숨긴다.
       * @param isShow
       * @param text 로딩바 텍스트 (not required)
       */
      showLoadPanel(isShow, text = '로딩중...') {
        if (isShow) {
          // 전체화면에 한다. (body)
          this.$_Loader.show(text);
        } else {
          this.$_Loader.hide();
        }
      },
      /**
       * app:errMsgOn 이벤트를 통해 에러 메시지를 띄워준다.
       * @param msg
       */
      showErrMsg(msg) {
        this.$_Msg(msg);
      },
      /**
       * 테마 색상 변경
       * @return {string}
       */
      changedColor() {
        // 가상 클래스 요소 얻기
        let root = document.querySelector(':root');
        // window.getComputedStyle 메서드를 이용하면, 해당 요소에 전역적으로 적용된 모든 형태의 style을 반환
        let variables = getComputedStyle(root);

        let themeColor;
        // 테마 색상이 있을 경우
        if (this.$store.getters.getThemeSetting?.themeColor) {
          themeColor = this.$store.getters.getThemeSetting?.themeColor;
        } else {
          themeColor = variables.getPropertyValue('--themeColor');
        }
        document.documentElement.style.setProperty('--themeColor', themeColor);

        let testHex = themeColor;
        this.convertHexToRGBA(testHex, 0.2);
        return themeColor;
      },
      /**
       * 테마 색상에 투명도를 적용한다.
       * @param hexCode
       * @param opacity
       * @return {string}
       */
      applyOpacity(hexCode, opacity) {
        let hex = hexCode.replace('#', '');

        if (hex.length === 3) {
          hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);

        /* Backward compatibility for whole number based opacity values. */
        if (opacity > 1 && opacity <= 100) {
          opacity = opacity / 100;
        }

        const hexToRgb = `${r},${g},${b},${opacity}`;
        return 'rgba(' + hexToRgb + ')';
      },
      /**
       * 테마 색상을 rgba로 변환한다.
       * @param hexCode
       * @param opacity
       */
      convertHexToRGBA(hexCode, opacity) {
        document.documentElement.style.setProperty('--opacityThemeColor', this.applyOpacity(hexCode, opacity));
      },
      /**
       * favicon 설정
       */
      setFavicon() {
        const faviconImg = this.$store.getters.getThemeSetting?.faviconImg;

        let faviconPath = '';
        if (faviconImg) {
          faviconPath = this.$_getAttachFileURL(null, faviconImg);
        } else {
          faviconPath = require('@/assets/images/ecss_ico1.png');
          this.applyFavicon(faviconPath);
          // 이미지가 없을 경우, early return
          return;
        }

        const testImage = new Image();
        testImage.onload = () => {
          // 이미지 로드 성공 시
          this.applyFavicon(faviconPath);
        };

        testImage.onerror = () => {
          // 이미지 로드 실패 시
          this.applyFavicon(require('@/assets/images/ecss_ico1.png'));
        };
        testImage.src = faviconPath;
      },
      /**
       * favicon 적용
       *
       * @param path favicon 경로
       */
      applyFavicon(path) {
        const link = document.getElementById('faviconId');
        if (link) {
          link.href = path;
        } else {
          const newLink = document.createElement('link');
          newLink.id = 'faviconId';
          newLink.rel = 'icon';
          newLink.href = path;
          document.head.appendChild(newLink);
        }
      },

      /**
       * 테마 색상을 투명도와 함께 반환한다.
       * @param opacity 투명도
       * @param callback 콜백
       */
      getThemeColorWithOpacity({ opacity = 1 }, callback) {
        callback(this.applyOpacity(this.changedColor(), opacity));
      },
      /**
       * 초기 설정
       */
      async init() {
        this.setFavicon(); // favicon 설정
        const encryptionTypeConfig = this.$store.getters.getSystemCodeList.find(system => system.configKey === 'encryption_type');
        this.$store.commit('setEncryptionType', encryptionTypeConfig?.configValue || 'SHA256'); // 로그인 암호화 타입 설정

        EventBus.$on('app:progress', (isShow, text) => this.showLoadPanel(isShow, text));
        EventBus.$on('app:errMsgOn', this.showErrMsg);
        EventBus.$on('app:removeKeepAliveCacheByMenuId', this.removeKeepAliveCacheByMenuId);

        //테마색 리턴해주는 EventBus
        EventBus.$on('app:getColorFromThemeWithOpacity', this.getThemeColorWithOpacity);
      },
    },
    async created() {
      await this.init(); // 초기 설정
      this.$log.debug('ESP Environment check', process.env);
      this.$log.debug('ESP APP Version check ::', this.$espAppVersion);
      this.$log.debug('ESP i18n check ::', this.$_i18n);
    },
    mounted() {},
    destroyed() {
      EventBus.$off('app:progress', this.showLoadPanel);
      EventBus.$off('app:errMsgOn', this.showErrMsg);
      EventBus.$off('app:removeKeepAliveCacheByMenuId', this.removeKeepAliveCacheByMenuId);
      EventBus.$off('app:getColorFromThemeWithOpacity', this.getThemeColorWithOpacity);
    },
  };
</script>

<style>
  .dx-loadpanel-wrapper {
    z-index: 2000 !important;
  }

  :root {
    --themeColor: #164371;
    --themeColorDark: #143c65; /* 10% 더 어두운 */
    --themeColorLight: #185180; /* 10% 더 밝은 */
    --themeColorHover: rgba(22, 67, 113, 0.05); /* 테마 색상에 투명도 적용 */
    --opacityThemeColor: 42, 59, 171, 0.2;
  }
</style>

<style lang="scss">
  .theme-background {
    background-color: var(--themeColor);
  }
  .notification.n-light {
    margin: 10px;
    margin-bottom: 0;
    border-radius: 3px;
    font-size: 13px;
    padding: 10px 20px;
    color: #495061;
    background: #eaf4fe;
    border: 1px solid #d4e8fd;

    .notification-title {
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 10px;
      color: #2589f3;
    }
  }

  .custom-template {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: left;
    font-size: 13px;
    margin: 5px;
    margin-bottom: 0;
    align-items: center;
    justify-content: center;

    &,
    & > div {
      box-sizing: border-box;
    }

    .custom-template-icon {
      flex: 0 1 auto;
      color: #15c371;
      font-size: 32px;
      padding: 0 10px;
    }

    .custom-template-close {
      flex: 0 1 auto;
      padding: 0 20px;
      font-size: 16px;
      opacity: 0.2;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .custom-template-content {
      padding: 10px;
      flex: 1 0 auto;

      .custom-template-title {
        letter-spacing: 1px;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }

  .v-fade-left-enter-active,
  .v-fade-left-leave-active,
  .v-fade-left-move {
    transition: all 0.5s;
  }

  .v-fade-left-enter,
  .v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(-500px) scale(0.2);
  }

  .v-fade-right-enter-active,
  .v-fade-right-leave-active,
  .v-fade-right-move {
    transition: all 0.5s;
  }

  .v-fade-right-enter,
  .v-fade-right-leave-to {
    opacity: 0;
    transform: translateX(500px) scale(0.2);
  }
</style>
