<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid"/>
  </div>
</template>

<script>
import {isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  components: {
    EspDxDataGrid,
  },
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      stateList: [],
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'deviceGrid',
        excel: {
          title: this.$_lang('UC.WORD.DEVICE_INFO', {defaultValue: '전화 정보'}),
        },
        keyExpr: 'userId',
        dataSourceDefaultSortColumn: '+userId',
        apiActionNm: {
          select: 'EUC_USER_STATE_SELECT',
        },
        showActionButtons: {
          delete: false,
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '사번',
            i18n: 'UC.WORD.USER_NO',
            dataField: 'userId',
            allowEditing: false,
          },
          {
            caption: '이름',
            i18n: 'COMPONENTS.NAME',
            dataField: 'userNm',
            allowEditing: false,
          },
          {
            caption: '부서명',
            i18n: 'COMPONENTS.DEPT_NAME',
            dataField: 'deptNm',
            allowEditing: false,
          },
          {
            caption: '전화번호',
            i18n: 'COMPONENTS.PHONE_NUMBER',
            dataField: 'deviceDn',
            allowEditing: false,
          },
          {
            caption: '장치이름',
            i18n: 'UC.WORD.DEVICE_NAME',
            dataField: 'deviceMac',
            allowEditing: false,
          },
          {
            caption: '상태',
            i18n: 'UC.WORD.STATUS',
            dataField: 'deviceSt',
            allowEditing: false,
            calculateDisplayCellValue: rowData => this.stateList.find(d => d.codeValue === rowData?.deviceSt)?.description || '',
          },
          {
            caption: '직원 IP',
            i18n: 'UC.WORD.STAFF_IP',
            dataField: 'userIp',
            allowEditing: false,
          },
          {
            caption: '서버 IP',
            i18n: 'UC.WORD.SERVER_IP',
            dataField: 'serverIp',
            allowEditing: false,
          },
          {
            caption: '상태변경시간',
            i18n: 'UC.WORD.STATUS_CHANGE_TIME',
            dataField: 'stChTm',
            calculateCellValue: (rowData) => this.formatDt(rowData?.stChTm),
          },
          {
            caption: '로그인시간',
            i18n: 'UC.WORD.LOGIN_TIME',
            dataField: 'loginTm',
            calculateCellValue: (rowData) => this.formatDt(rowData?.loginTm),
          },
        ],
      },
    };
  },
  methods: {
    /** @description IPT CODE 가져오는 메서드 */
    async selectIptCode(sort = '+codeOrd') {
      const payload = {
        actionName: 'EUC_CODE_SELECT',
        data: { sort, codeKey: 'devicestate', viewFl: 'Y' },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.stateList = res.data.data.filter(item => item.parentId !== null);
      }
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
  created() {
    this.selectIptCode();
  },
};
</script>
