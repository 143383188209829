<template>
  <div class="header-tab flex items-center justify-between select-none">
    <div class="is-active-left-show px-32 flex-shrink-0">
      <!-- 메뉴바 고정일 시 같은 너비로 조정하기 위한 박스-->
    </div>
    <!-- 좌우 이동 버튼 -->
    <div class="tab-navigation flex items-center gap-2 px-2 flex-shrink-0">
      <span class="mdi mdi-chevron-left text-lg tab-button cursor-pointer" @click="scrollTabs('left')" title="탭 왼쪽으로 이동"></span>
      <span class="mdi mdi-chevron-right text-lg tab-button cursor-pointer" @click="scrollTabs('right')" title="탭 오른쪽으로 이동"></span>
    </div>

    <!-- 탭 목록 -->
    <div class="tabs-container flex gap-2 overflow-x-hidden min-w-0 flex-1" ref="tabsContainer" @wheel="handleWheelScroll">
      <div
        v-for="(tab, index) in tabs"
        :key="tab.name"
        :title="tab.breadcrumb"
        :class="['flex', 'items-center', 'gap-1', 'drag-tab-item', { 'drag-tab-item--active': activeTab === tab.name }]"
        draggable="true"
        @dragstart="handleTabDragStart($event, index)"
        @dragend="handleTabDragEnd($event)"
        @dragover.prevent
        @dragenter.prevent="handleTabDragEnter($event, index)"
        @dragleave="handleTabDragLeave($event)"
        @drop="handleTabDrop($event, index)"
        @click="switchTab(tab)"
        :data-index="index"
      >
        <!-- 잠금 아이콘 -->
        <img
          v-if="tab.name !== 'HOME'"
          :src="getIconSrc(tab.locked ? 'tab_lock' : 'tab_unlock')"
          alt="Lock/Unlock"
          class="cursor-pointer tab-button"
          @click.stop="toggleTabLock(tab)"
          title="탭 잠금/해제"
        />
        <!-- 탭 라벨 -->
        <span v-if="tab.name !== 'HOME'">{{ tab.label }}</span>
        <!-- 홈 아이콘 (컬러 변경 때문에 svg 통째로 넣었는데, 수정 필요) -->
        <svg v-else width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.16663 15.8332V8.58987C4.16663 8.37654 4.2144 8.17459 4.30996 7.98404C4.40551 7.79348 4.53718 7.63654 4.70496 7.5132L9.19246 4.11487C9.42746 3.93543 9.69579 3.8457 9.99746 3.8457C10.2991 3.8457 10.5691 3.93543 10.8075 4.11487L15.295 7.51237C15.4633 7.6357 15.595 7.79293 15.69 7.98404C15.7855 8.17459 15.8333 8.37654 15.8333 8.58987V15.8332C15.8333 16.0565 15.7502 16.2513 15.5841 16.4174C15.418 16.5835 15.2233 16.6665 15 16.6665H12.18C11.9888 16.6665 11.8288 16.6021 11.7 16.4732C11.5711 16.3438 11.5066 16.1838 11.5066 15.9932V12.019C11.5066 11.8285 11.4422 11.6688 11.3133 11.5399C11.1838 11.4104 11.0238 11.3457 10.8333 11.3457H9.16663C8.97607 11.3457 8.81635 11.4104 8.68746 11.5399C8.55801 11.6688 8.49329 11.8285 8.49329 12.019V15.994C8.49329 16.1846 8.42885 16.3443 8.29996 16.4732C8.17107 16.6021 8.01135 16.6665 7.82079 16.6665H4.99996C4.77663 16.6665 4.5819 16.5835 4.41579 16.4174C4.24968 16.2513 4.16663 16.0565 4.16663 15.8332Z"
            :style="{ fill: 'var(--themeColor)' }"
          />
        </svg>
        <!-- 닫기 아이콘 -->
        <img
          v-if="tab.name !== 'HOME'"
          src="@/assets/images/tab_clear.svg"
          alt="Close"
          class="cursor-pointer tab-button"
          @click.stop="closeTab(tab)"
        />
      </div>
    </div>

    <!-- 전체 탭 잠금/해제 및 일괄 닫기 아이콘 -->
    <div class="tab-controls flex gap-2 items-center justify-between px-4 flex-shrink-0 ml-auto">
      <img
        src="@/assets/images/tab_all_clear.svg"
        alt="Clear All"
        class="cursor-pointer tab-button"
        @click="closeAllTabs"
        :title="tabsLocked ? '잠금 상태에서 사용할 수 없습니다' : '모든 탭 닫기'"
      />
      <img
        :src="getIconSrc(tabsLocked ? 'tab_all_lock' : 'tab_all_unlock')"
        alt="Lock/Unlock All"
        class="cursor-pointer tab-button"
        @click="toggleAllTabsLock"
        title="전체 탭 잠금/해제"
      />
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/event-bus';

  export default {
    name: 'SystemTab',
    watch: {
      $route(to) {
        if (!to.name === null) return;
        this.addMenuTab(to);
      },
    },
    data() {
      return {
        mainInfo : null,
        tabs: [
          {
            name: 'HOME',
            label: 'Home',
            path: '/', // HOME 탭 경로
            locked: true,
            breadcrumb: '홈',
            rootMenuId: null,
            currentMenuId: null,
            cacheMenuIds: [],
          },
        ],
        activeTab: 'HOME',
        tabsLocked: false,
        draggedTabIndex: null,
        dragenterIndex: null,
      };
    },
    methods: {
      /**
       * @description 메뉴 정보를 통해 브레드크럼을 생성합니다.
       * @param menu
       * @return {string}
       */
      getBreadCrumb(menu) {
        if (!menu) return 'HOME'; // menu가 없는 경우(HOME 탭) 처리

        const menuArray = [menu];
        while (menu.menuDepth > 1) {
          menu = this.getMenuById(menu.parentId);
          menuArray.unshift(menu);
        }
        return menuArray.map(v => v.menuNm).join(' > ');
      },
      /**
       * @description 메뉴 ID로 메뉴 정보를 조회합니다.
       * @param id
       * @return {*}
       */
      getMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      /**
       * @description 메뉴 path로 메뉴 정보를 조회합니다.
       * @param path
       * @return {*}
       */
      getMenuByPath(path) {
        return this.$store.getters.getMenuList.find(menu => menu.pageUrl === path);
      },
      /**
       * 아이콘 파일명을 통해 아이콘 경로를 반환합니다.
       * @param {String} fileName - 아이콘 파일명
       * @returns {String} 아이콘 경로
       */
      getIconSrc(fileName) {
        return require(`@/assets/images/${fileName}.svg`);
      },

      /**
       * @description 메뉴 탭 추가 처리
       * @param to $route
       */
      addMenuTab(to) {
        const menuId = to.meta?.menuId;
        const currentMenu = menuId ? this.getMenuById(menuId) : this.getMenuByPath(to.path);
        if (!currentMenu) return;

        // mainInfo 와 비교해서 메인 페이지 인지
        if (this.mainInfo.find(page => page.menuId === menuId)) {
          this.activeTab = 'HOME';
          return;
        }

        const existingTab = this.findExistingTab(menuId);
        if (existingTab) {
          this.handleExistingTab(existingTab, to, menuId);
          return;
        }

        this.createNewTab(currentMenu, to);
      },

      /**
       * @description 기존 탭 처리
       */
      handleExistingTab(tab, to, menuId) {
        // 현재 메뉴가 루트 메뉴인 탭으로 돌아가는 경우
        if (tab.rootMenuId === menuId) {
          this.clearChildrenCache(tab, menuId);
        }
        this.updateCurrentTab(to, tab, menuId);
      },

      /**
       * @description 새 탭 생성 처리
       */
      createNewTab(currentMenu, to) {
        const parentMenu = this.getMenuById(currentMenu.parentId);

        if (this.shouldCreateParentTab(parentMenu)) {
          const parentTab = this.createParentTab(parentMenu);
          this.updateCurrentTab(to, parentTab, currentMenu.id);
        } else {
          this.handleViewingTab(currentMenu, to);
        }
      },

      /**
       * @description 하위 메뉴 캐시 정리
       */
      clearChildrenCache(tab, currentMenuId) {
        tab.cacheMenuIds.forEach(childMenuId => {
          if (childMenuId !== currentMenuId) {
            EventBus.$emit('app:removeKeepAliveCacheByMenuId', childMenuId);
          }
        });
        tab.cacheMenuIds = [];
      },

      /**
       * @description 상위 메뉴 탭 생성 여부 확인
       */
      shouldCreateParentTab(parentMenu) {
        return parentMenu && parentMenu.menuTypeCd === this.$_enums.common.menuType.NORMAL_PAGE.value;
      },

      /**
       * @description 상위 메뉴 탭 생성
       */
      createParentTab(parentMenu) {
        this.handleViewingTab(parentMenu, {
          name: `${parentMenu.menuTypeCd}-${parentMenu.id}`,
          fullPath: parentMenu.pageUrl,
          meta: { menuId: parentMenu.id },
        });
        return this.tabs.find(tab => tab.rootMenuId === parentMenu.id);
      },

      /**
       * @description 기존 탭 찾기
       */
      findExistingTab(menuId) {
        return (
          this.tabs.find(tab => tab.rootMenuId === menuId) || this.tabs.find(tab => tab.rootMenuId === this.getMenuById(menuId)?.parentId)
        );
      },

      /**
       * @description 현재 탭 정보를 업데이트합니다.
       * @param to $route
       * @param tab 현재 탭
       * @param menuId 메뉴 ID
       */
      updateCurrentTab(to, tab, menuId) {
        tab.currentMenuId = menuId;
        tab.name = to.name;
        tab.path = to.fullPath;
        // HOME 탭인 경우 menuId가 null이므로 getBreadCrumb에 null이 전달됨
        tab.breadcrumb = this.getBreadCrumb(menuId ? this.getMenuById(menuId) : null);
        if (!tab.cacheMenuIds.includes(menuId) && menuId) {
          tab.cacheMenuIds.push(menuId);
        }
        this.activeTab = tab.name;
      },

      /**
       * @description 탭 생성 또는 업데이트를 처리합니다.
       */
      handleViewingTab(menu, route) {
        const tabName = route.name; // route name 사용

        // 새 탭 추가
        if (!this.tabs.find(tab => tab.name === tabName)) {
          this.tabs.push({
            name: tabName,
            label: menu.menuNm,
            path: route.fullPath,
            rootMenuId: menu.id,
            cacheMenuIds: [menu.id],
            locked: false,
            breadcrumb: this.getBreadCrumb(menu),
            currentMenuId: menu.id, // 현재 활성화된 메뉴 ID
          });

          this.$nextTick(() => {
            this.scrollToEnd();
          });
        }
        this.activeTab = tabName;
      },

      /**
       * 현재 활성화된 탭을 업데이트합니다.
       * @param {Object} tab - 활성화할 탭 객체
       */
      switchTab(tab) {
        if (this.activeTab === tab.name) return;

        this.activeTab = tab.name;
        if (tab.name === 'HOME') {
          this.$router.push('/');
        } else {
          this.$router.push({
            name: tab.name,
          });
        }
      },
      /**
       * 특정 탭을 닫습니다.
       * @param {Object} tab - 닫을 탭 객체
       */
      closeTab(tab) {
        if (tab.locked) return;

        // menuId를 기반으로 캐시 제거
        tab.cacheMenuIds.forEach(menuId => {
          EventBus.$emit('app:removeKeepAliveCacheByMenuId', menuId);
        });

        const index = this.tabs.findIndex(t => t.name === tab.name);
        if (index !== -1) {
          this.tabs.splice(index, 1);
          if (this.activeTab === tab.name) {
            // 이전 탭으로 이동 (없으면 HOME으로)
            const prevTab = index > 0 ? this.tabs[index - 1] : this.tabs[0];
            this.activeTab = prevTab.name;

            // HOME인 경우와 아닌 경우를 구분하여 처리
            if (prevTab.name === 'HOME') {
              this.$router.push('/');
            } else {
              this.$router.push({ name: prevTab.name });
            }
          }
        }
      },

      /**
       * 모든 탭을 닫습니다.
       */
      closeAllTabs() {
        this.tabs = this.tabs.filter(tab => {
          if (!(tab.name === 'HOME' || tab.locked)) {
            // menuId를 기반으로 캐시 제거
            tab.cacheMenuIds.forEach(menuId => {
              EventBus.$emit('app:removeKeepAliveCacheByMenuId', menuId);
            });
            return false;
          }
          return true;
        });

        const existTab = this.tabs.find(tab => tab.name === this.activeTab);
        if (!existTab) {
          const lastTabIndex = this.tabs.length - 1;
          this.$router.push(this.tabs[lastTabIndex].path);
        }
      },
      /**
       * 특정 탭의 잠금 상태를 토글합니다.
       * @param {Object} tab - 잠금 상태를 변경할 탭 객체
       */
      toggleTabLock(tab) {
        tab.locked = !tab.locked;
      },
      /**
       * 모든 탭의 잠금 상태를 토글합니다.
       */
      toggleAllTabsLock() {
        this.tabsLocked = !this.tabsLocked;
        this.tabs.forEach(tab => {
          tab.locked = this.tabsLocked;
        });
      },
      /**
       * 탭을 좌우로 스크롤합니다.
       * @param {String} direction - 스크롤 방향 (left 또는 right)
       */
      scrollTabs(direction) {
        const container = this.$el.querySelector('.tabs-container');
        const scrollAmount = 100; // 한 번에 스크롤할 픽셀 수

        if (direction === 'left') {
          container.scrollLeft -= scrollAmount;
        } else if (direction === 'right') {
          container.scrollLeft += scrollAmount;
        }
      },
      /**
       * 탭 목록을 우측 끝으로 스크롤합니다.
       */
      scrollToEnd() {
        const container = this.$el.querySelector('.tabs-container');
        if (container) {
          container.scrollLeft = container.scrollWidth; // 스크롤을 가장 오른쪽으로 이동
        }
      },
      /**
       * 휠 스크롤 이벤트를 처리합니다.
       * @param event
       */
      handleWheelScroll(event) {
        if (event.deltaY !== 0) {
          this.$refs.tabsContainer.scrollLeft += event.deltaY;
        }
      },
      /**
       * 탭 드래그 시작 시 호출되는 메서드
       */
      handleTabDragStart(event, index) {
        if (this.tabs[index].name === 'HOME' || this.tabs[index].locked) {
          event.preventDefault();
          return;
        }
        this.draggedTabIndex = index;
        event.target.classList.add('dragging');
        event.dataTransfer.effectAllowed = 'move';
      },

      /**
       * 탭 드래그 종료 시 호출되는 메서드
       */
      handleTabDragEnd() {
        this.clearDragClasses();
        this.draggedTabIndex = null;
        this.dragenterIndex = null;
      },

      /**
       * 탭 드래그 진입 시 호출되는 메서드
       */
      handleTabDragEnter(event, index) {
        if (this.draggedTabIndex === null || index === this.draggedTabIndex) return;
        if (this.tabs[index].name === 'HOME' || this.tabs[index].locked) return;

        this.dragenterIndex = index;
        event.target.classList.add('drag-over');
      },

      /**
       * 탭 드래그 이탈 시 호출되는 메서드
       */
      handleTabDragLeave(event) {
        event.target.classList.remove('drag-over');
      },

      /**
       * 탭 드롭 시 호출되는 메서드
       */
      handleTabDrop(event, index) {
        event.preventDefault();

        if (this.draggedTabIndex === null || index === this.draggedTabIndex) {
          this.clearDragClasses();
          return;
        }

        if (this.tabs[index].name === 'HOME' || this.tabs[index].locked) {
          this.clearDragClasses();
          return;
        }

        // 탭 위치 변경
        const draggedTab = this.tabs[this.draggedTabIndex];
        const updatedTabs = [...this.tabs];
        updatedTabs.splice(this.draggedTabIndex, 1);
        updatedTabs.splice(index, 0, draggedTab);
        this.tabs = updatedTabs;

        this.clearDragClasses();
        this.draggedTabIndex = null;
        this.dragenterIndex = null;
      },

      /**
       * 드래그 관련 클래스를 초기화합니다.
       */
      clearDragClasses() {
        const elements = document.querySelectorAll('.drag-tab-item');
        elements.forEach(el => {
          el.classList.remove('dragging');
          el.classList.remove('drag-over');
        });
      },
    },
    mounted() {
        this.mainInfo = this.$store.getters.getUserMainPages;
        this.addMenuTab(this.$route);
    },
    created() {},
  };
</script>

<style lang="scss" scoped>
  .header-tab {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f6f8fa; /* 전체 배경색 */
    border-top: 1px solid #ebedf0; /* 상단 테두리 */

    .tab-navigation {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .mdi {
        font-size: 1.5rem;
        cursor: pointer;
        line-height: 1;
        transition: color 0.2s ease;

        &:hover {
          color: var(--themeColor);
        }
      }

      /* 탭 네비게이션 구분선 */
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 50%; /* 선 길이 */
        background-color: #e0e0e0;
      }
    }

    .tabs-container {
      flex-grow: 1; /* 탭 리스트가 가능한 최대 공간 차지 */
      overflow-x: hidden;
      display: flex;
      align-items: center;
      gap: 0.5rem; /* 간격을 줄여 균형 조정 */

      .drag-tab-item {
        position: relative; /* 구분선을 위한 위치 설정 */
        padding: 5px 10px; /* 탭 내부 여백 */
        font-size: 11px;
        font-weight: 600;
        color: #333;
        cursor: pointer;
        flex-shrink: 0; /* 탭이 줄어들지 않도록 설정 */
        transition: all 0.2s ease;

        &:hover {
          color: var(--themeColor);
          background-color: var(--opacityThemeColor);
        }

        /* 활성화된 탭 상단 보더 */
        &--active {
          background-color: var(--opacityThemeColor);
          span {
            font-weight: 500; /* 굵기 추가 */
            color: var(--themeColor); /* 테마 컬러 */
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-color: var(--themeColor);
          }
        }

        /* 탭 사이 구분선 */
        &::after {
          content: '';
          position: absolute;
          top: 50%; /* 중앙 정렬 */
          right: -0.25rem; /* 구분선을 살짝 안쪽으로 조정 */
          transform: translateY(-50%); /* 수직 중앙 정렬 */
          width: 1px; /* 선의 두께 */
          height: 50%; /* 선의 길이 */
          background-color: #e0e0e0; /* 연한 회색 */
        }

        /* 마지막 탭 구분선 유지 */
        &:last-child::after {
          display: block;
        }

        &[draggable='true'] {
          user-select: none;
        }

        &.dragging {
          opacity: 0.4;
          background-color: var(--opacityThemeColor);
          border-radius: 4px;
          position: relative;
          z-index: 1000;
        }

        &.drag-over {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: -2px;
            top: 0;
            bottom: 0;
            width: 2px;
            background-color: var(--themeColor);
          }
        }
      }
    }
  }

  /* 잠금장치 및 삭제 버튼 공통 스타일 */
  .tab-button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s ease, background-color 0.3s ease;
    border-radius: 50%; /* 테두리를 완전히 둥글게 */
  }

  /* Hover 상태 */
  .tab-button:hover {
    color: var(--themeColor);
    background-color: var(--opacityThemeColor);
  }

  /** 좌측 메뉴 열렸을 때 메뉴 width 만큼 활성화 시키는 역할 */
  #wrap.menuitemshow.left-show .is-active-left-show {
    display: block;
  }

  #wrap .is-active-left-show {
    display: none;
  }
</style>
