<!--
  PACKAGE_NAME : src/pages/cc/ivr/dnis/modal-ibg.vue
  FILE_NAME : modal-ibg
  AUTHOR : hmlee
  DATE : 2024-09-02
  DESCRIPTION : 인입그룹 설정 모달
-->
<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="handleCloseModal"
  >
    <template #content>
      <div class="flex flex-row">
        <div class="basis-2/5 bg-white relative">
          <div class="flex flex-row absolute top-5 z-50">
            <h2 class="text-lg font-semibold">미설정 인입그룹</h2>
            <DxButton
              template="<span class='mdi mdi-sync'></span>"
              :height="20"
              type="button"
              :hint="$_lang('CC.MESSAGE.RESET_IBG', { defaultValue: '인입그룹 설정 초기화' })"
              class="btn_XXXS white outlined ml-2"
              @click="handleReset"
            />
          </div>
          <esp-dx-data-grid calss="relative" :data-grid="beforeDataGrid" :ref="beforeDataGrid.refName" @row-click="handleClickSelectedRow" />
        </div>
        <div class="basis-1/5 flex flex-col justify-center px-5 space-y-4">
          <DxButton
            template="<span class='mdi mdi-chevron-right'></span>"
            :height="20"
            type="button"
            :hint="$_lang('COMPONENTS.ADD_SETTING', { defaultValue: '설정 추가' })"
            :disabled="button.disabled.clickedArrowType === 2"
            class="btn_XXXS white outlined"
            @click="handleSetSelectedIbg"
          />
          <DxButton
            template="<span class='mdi mdi-chevron-left'></span>"
            :height="20"
            type="button"
            :hint="$_lang('COMPONENTS.REMOVE_SETTING', { defaultValue: '설정 해제' })"
            :disabled="button.disabled.clickedArrowType === 1"
            class="btn_XXXS white outlined"
            @click="handleRemoveSelectedIbg"
          />
        </div>
        <div class="basis-2/5 bg-white relative">
          <h2 class="text-lg font-semibold absolute top-5 z-50">설정 인입그룹</h2>
          <esp-dx-data-grid :data-grid="afterDataGrid" :ref="afterDataGrid.refName" @row-click="handleClickSelectedRow" />
        </div>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :disabled="modal.toolbar.disabledSaving"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
        width: '120',
        height: '40',
        useSubmitBehavior: true,
        onClick: this.handleSaveModal,
      }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'white filled txt_S medium',
        },
        text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
        width: '120',
        height: '40',
        onClick: this.handleCloseModal,
      }"
    />
  </DxPopup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: {
      EspDxDataGrid,
      DxPopup,
      DxToolbarItem,
      DxButton,
    },
    props: {
      isOpen: {
        default: false,
        type: Boolean,
      },
      ibgData: {
        default: null,
        type: Object,
      },
    },
    watch: {},
    computed: {
      /** @description: 모달 타이틀 출력 여부 */
      showTitle() {
        return !!this.modal.title;
      },
    },
    data() {
      return {
        modal: {
          title: this.$_lang('CC.WORD.IBG_SETTING', { defaultValue: '인입그룹 설정' }),
          minWidth: '300',
          width: '850',
          minHeight: '300',
          height: '580',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          hideOnOutsideClick: false,
          toolbar: {
            disabledSaving: false, // 저장 중복 클릭 방지
          }
        },
        button: {
          disabled: {
            clickedArrowType: 0, // 0: 초기화(화살표 버튼 안눌렀을 때), 1: 설정추가(오른쪽화살표), 2: 설정해제(왼쪽화살표)
          },
        },
        ibgSettingChangedRows: [], //설정 인입그룹에 설정 추가된(변경된 로우) 데이터
        dnisIbgList: [], //설정 인입그룹에 추가된 데이터(미설정 인입그룹에서 제외하기 위해 필요)
        beforeDataGrid: {
          refName: 'beforeIbgGrid',
          dataSource: [],
          //width:'350',     // 주석처리시 100%
          height: '440', // 주석처리시 100%
          customEvent: {
            rowClick: true,
          },
          showActionButtons: {
            update: false, // 추가/저장/취소
            delete: false, // 삭제
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              caption: 'id',
              dataField: 'id',
              visible: false,
              allowEditing: false,
            },
            {
              caption: '인입그룹',
              i18n: 'CC.WORD.IBG',
              dataField: 'ibg',
              allowEditing: false,
            },
            {
              caption: '인입그룹명',
              i18n: 'CC.WORD.IBG_NAME',
              dataField: 'ibgNm',
              allowEditing: false,
            },
          ],
        },
        afterDataGrid: {
          refName: 'afterIbgGrid',
          dataSource: [],
          //width:'350',     // 주석처리시 100%
          height: '440', // 주석처리시 100%
          customEvent: {
            rowClick: true,
          },
          showActionButtons: {
            update: false, // 추가/저장/취소
            delete: false, // 삭제
          },
          page: {
            enabled: false,
          },
          columns: [
            {
              caption: 'id',
              dataField: 'id',
              visible: false,
              allowEditing: false,
            },
            {
              caption: '인입그룹',
              i18n: 'CC.WORD.IBG',
              dataField: 'ibg',
              allowEditing: false,
            },
            {
              caption: '인입그룹명',
              i18n: 'CC.WORD.IBG_NAME',
              dataField: 'ibgNm',
              allowEditing: false,
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 모달 저장 */
      async handleSaveModal() {
        //인입그룹 설정이 없는 경우
        if (this.button.disabled.clickedArrowType === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //저장 중복 클릭 방지 체크
        if( this.modal.toolbar.disabledSaving ) {
          return false;
        }
        this.modal.toolbar.disabledSaving = true; // 저장 중복 클릭 방지 설정

        let dnisIbgDatas = [];
        let actionName = '';
        if (this.button.disabled.clickedArrowType === 1) {
          //인입그룹 설정 추가
          dnisIbgDatas = this.ibgSettingChangedRows.map(ibg => {
            return {
              ibgId: ibg.id,
              dnisId: this.ibgData.id,
            };
          });
          actionName = 'CC_IVR_DNIS_IBG_MERGE';
        } else if (this.button.disabled.clickedArrowType === 2) {
          //인입그룹 설정 해제(삭제)
          dnisIbgDatas = this.ibgSettingChangedRows;
          actionName = 'CC_IVR_DNIS_IBG_DELETE';
        }

        const payload = {
          actionName: actionName,
          data: dnisIbgDatas,
        };

        const res = await this.CALL_CC_API(payload);

        if (isSuccess(res)) {
          this.$emit('saveModal');
          this.modal.toolbar.disabledSaving = false; // 저장 중복 클릭 방지 해제
        }
      },
      /** @description: 모달 닫기 */
      handleCloseModal() {
        this.$emit('closeModal');
      },
      /** @description: DNIS 인입그룹 조회 */
      async selectDnisIbgList() {
        const payload = {
          actionName: 'CC_IVR_DNIS_IBG_LIST',
          data: { dnisId: this.ibgData.id },
        };

        const res = await this.CALL_CC_API(payload);
        let rstData = [];
        if (isSuccess(res)) {
          rstData = res.data.data;
        }
        return rstData;
      },
      /** @description: 미설정 인입그룹 데이터 조회 */
      async selectBeforeDataList(sort = '+id') {
        //인입그룹 설정 전 그리드 데이터
        this.beforeDataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async(loadOptions) => {
            const params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            //미설정 인입그룹 그리드에서 매핑된 인입그룹 제외
            if (this.dnisIbgList.length > 0) {
              const dnisIbgIds = this.dnisIbgList.map(dnisIbg => dnisIbg.ibgId);
              params.id = `<>${dnisIbgIds}`; //ibg id
            }

            params.tenantId = this.ibgData.tenantId; //센터
            params.viewFl = 'Y';

            const payload = {
              actionName: 'CC_IBG_LIST',
              data: params,
            };

            const res = await this.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              this.$refs[this.beforeDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 설정 인입그룹 데이터 조회 */
      async selectAfterDataList(sort = '+id') {
        //인입그룹 설정 후 그리드 데이터
        this.afterDataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async(loadOptions) => {
            const params = this.$_getDxDataGridParam(loadOptions);

            if (!params.sort) params.sort = sort;

            //dnisId로 조회
            params.dnisId = this.ibgData.id;

            const payload = {
              actionName: 'CC_IVR_DNIS_IBG_LIST',
              data: params,
            };

            const res = await this.CALL_CC_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
              this.$refs[this.afterDataGrid.refName].totalCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 인입그룹 설정 초기화 */
      handleReset(e) {
        //그리드 편집 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getInstance.cancelEditData();

        //그리도 선택 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.deselectAll();
        this.$refs[this.afterDataGrid.refName].getInstance.deselectAll();

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].refreshData();
        this.$refs[this.afterDataGrid.refName].refreshData();

        //설정/해제 버튼 초기화
        this.button.disabled.clickedArrowType = 0;

        //설정 인입그룹에 추가된 데이터 초기화
        this.ibgSettingChangedRows = [];
      },
      /** @description: 그리드 클릭시 체크되는 이벤트 */
      handleClickSelectedRow(e) {
        const selectedRowKeys = e.component.getSelectedRowKeys();
        const index = selectedRowKeys.indexOf(e.key);

        if (index === -1) { //선택되지 않은 경우 추가
          selectedRowKeys.push(e.key);
        } else { //선택된 경우 삭제
          selectedRowKeys.splice(index, 1);
        }

        e.component.selectRows(selectedRowKeys);
      },
      /** @description: 인입그룹 설정 추가 */
      handleSetSelectedIbg(e) {
        //미설정 인입그룹 선택된 데이터
        const selectedRowsData = this.$refs[this.beforeDataGrid.refName].getInstance.getSelectedRowsData();

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 추가 버튼 클릭시
        this.button.disabled.clickedArrowType = 1;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getInstance.cancelEditData();

        //설정 인입그룹에 추가
        this.ibgSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          this.$refs[this.afterDataGrid.refName].getInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.afterDataGrid.refName].getInstance.cellValue(index, key, value);
          });
        });

        //미설정 인입그룹에서 선택한 데이터 삭제
        const keys = selectedRowsData.map(item => {
          return this.$refs[this.beforeDataGrid.refName].getInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.beforeDataGrid.refName].getInstance.deleteRow(keyIdx);
        });
      },
      /** @description: 인입그룹 설정 해제 */
      handleRemoveSelectedIbg(e) {
        //설정 인입그룹 선택된 데이터
        const selectedRowsData = this.$refs[this.afterDataGrid.refName].getInstance.getSelectedRowsData();

        if (selectedRowsData.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        //설정 해제 버튼 클릭시
        this.button.disabled.clickedArrowType = 2;

        //그리드 초기화
        this.$refs[this.beforeDataGrid.refName].getInstance.cancelEditData();
        this.$refs[this.afterDataGrid.refName].getInstance.cancelEditData();

        //미설정 인입그룹에 추가
        this.ibgSettingChangedRows = selectedRowsData;
        selectedRowsData.forEach(row => {
          this.$refs[this.beforeDataGrid.refName].getInstance.addRow();
        });
        selectedRowsData.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs[this.beforeDataGrid.refName].getInstance.cellValue(index, key, value);
          });
        });

        //설정 인입그룹에서 선택한 데이터 삭제
        const keys = selectedRowsData.map(item => {
          return this.$refs[this.afterDataGrid.refName].getInstance.getRowIndexByKey(item.id);
        });
        keys.forEach(keyIdx => {
          this.$refs[this.afterDataGrid.refName].getInstance.deleteRow(keyIdx);
        });
      },
    },
    async created() {
      this.dnisIbgList = await this.selectDnisIbgList(); //설정 인입그룹에 추가된 데이터(미설정 인입그룹에서 제외하기 위해 필요)
      await this.selectAfterDataList(); //설정 인입그룹 조회
      await this.selectBeforeDataList(); //미설정 인입그룹 조회
    },
  };
</script>
