<template>
	<div class="container content-themes">
		<div class="page-sub-box clearfix ui-glid-box per33 ui-33" style="padding-bottom: 0px !important;">
			<table class="table_form line-bin">
				<thead class="sub_title_txt">
					<tr>
						<td colspan="3">
							<h2>부서 등록</h2>
						</td>
					</tr>
				</thead>
				<colgroup>
					<col style="width:250px;">
					<col style="width:auto;">
				</colgroup>

				<tbody>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>사이트</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="siteId"
								placeholder="사이트 선택"
								:items="infoPushSiteList"
								display-expr="siteNm"
								value-expr="siteId"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>Tenant</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="tenantId"
								placeholder="Tenant 선택"
								:items="tenantList"
								display-expr="tenantNm"
								value-expr="id"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>수신시 설정</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="inContentId"
								:items="contentList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
							/>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<label for="label01">
								<span>발신시 설정</span>
								<span class="icon_require">필수항목</span>
							</label>
						</th>
						<td class="clearfix">
							<DxSelectBox
								v-model="outContentId"
								:items="contentList"
								display-expr="description"
								value-expr="id"
								:styling-mode="stylingMode"
								:width="300"
								:height="30"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="page-sub-box clearfix ui-glid-box per33 ui-33">
			<div v-if="siteId && tenantId" style="display: flex; height: 500px;">
				<DepthTreeBox
					v-for="v in dept.options"
					:ref="`dept${v.depth}`"
					:is="DepthTreeBox"
					:title="v.name"
					:key="v.depth"
					:dataName="`dept${v.depth}`"
					:nextDataName="v.hasNext ? `dept${v.depth + 1}` : undefined"
					:treeDatas="target[(v.depth === 1 || v.isMulti ? '' : 'v_') + `dept${v.depth}`]"
					:disabled="false"
					@setCheckedItems="setCheckedItems"
				/>
			</div>
		</div>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton text="저 장" :width="120" :height="40" class="default filled txt_S medium" :onClick="() => onSave()" />
					<DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" :onClick="() => $_goPrePage()" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxColumn, DxEditing, DxLoadPanel, DxPager, DxPaging, DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
import {cloneObj, isSuccess} from '../../../../../utils/common-lib';
import { DxSwitch } from 'devextreme-vue/switch';
import DepthTreeBox from '../../../../../components/report/depth-tree-box.vue';

export default {
	components: {
		DepthTreeBox,
		DxSwitch,
		DxPaging,
		DxTreeList,
		DxColumn,
		DxSelection,
		DxPager,
		DxLoadPanel, DxEditing,
		DxSelectBox,
		DxButton,
	},
	props: {},
	watch: {
		infoPushSiteList: function(newVal) {
			if(newVal.length) {
				this.siteId = newVal[0].siteId;
			}
		},
		siteId: function(newVal) {
			this.tenantList = this.$store.getters.getTenantList.filter((tenant) => tenant.siteId === newVal);
		},
		tenantList: function(newVal) {
			if(newVal.length) {
				this.tenantId = newVal[0].id;
			}
		},
		tenantId: function async (newVal) {
			this.selectDeptList(newVal);
		}
	},
	computed: {},
	data() {
		return {
			reqParams: null,
			infoPushSiteList: [],
			target: {
				//부서 리스트
				deptList: [],
				dept1: [],
				dept2: [],
				dept3: [],
				dept4: [],
				v_dept1: [],
				v_dept2: [],
				v_dept3: [],
				v_dept4: [],
				chk_dept1: [],
				chk_dept2: [],
				chk_dept3: [],
				chk_dept4: [],
				//단일 리스트(Custom)
				v_item1: '',
				v_item2: '',
				v_item3: '',
				v_item4: '',
				customList: [],
			},
			PAGE_DATA: {
				//API
				DEPT_API: '',
				/***** components setting start ****/
				checkBtnInfo: null,
				inputBtnInfo: null,
				treeListOptions: [],
				reportGroupRadios: [],
				//STATUS
				filter: {
					selectedRowKeys: [0, 1, 3], // 0, 1, 3
				},
				/***** components setting end by report.js ****/

				//STYLE
				dxStyled: 'outlined',
			},
			DepthTreeBox: 'DepthTreeBox',
			pageList: [],
			totalCount: 0,
			isSubDept: false,
			siteId: null,
			tenantId: null,
			inContentId: null,
			outContentId: null,
			contentList: [],
			deptList: {},
			tenantList: [],
			viewCd: false,
			stylingMode: 'outlined',
			config: {
				pageSetting: {
					config: {},
				},
			},
			dept: {
				numbers: [1, 2, 3, 4],	// options 의 depth 만큼
				options: [
					{
						depth: 1,
						name: '청',
						hasNext: true,

					},
					{
						depth: 2,
						name: '서',
						hasNext: true,
					},
					{
						depth: 3,
						name: '과',
						hasNext: true,
					},
					{
						depth: 4,
						name: '팀',
						hasNext: false,
					},
				],
			},
			tree: {
				editing: {
					allowUpdating: false,
					mode: 'batch',
					startEditAction: 'click',
					selectTextOnEditStart: false,
				},
				keyExpr: 'deptId',
				parentIdExpr: 'parentId',
				refName: 'deptTreeRef',
				pager: {
					visible: true, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [],
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				paging: {
					enabled: true,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				recursive: true,
				columns: [
					{
						caption: '부서명',
						dataField: 'deptNm',
						alignment: 'left',
						allowEditing: false,
						width: 150,
						height: 40,
					},
					{
						caption: '부서명 전체',
						dataField: 'nameTree',
						alignment: 'left',
						allowEditing: false,
						height: 40,
						cellTemplate: (container, options) => {
							const values = options.value.split('|');
							const deptTree = values.join(' ');
							container.append(deptTree);
						},
					},
				],
			},

		};
	},
	methods: {
		setCheckedItems(name, nextName, items) {
			const selectedItemKeys = items.map(item => item.VALUE);
			this.target[`chk_${name}`] = selectedItemKeys;
			if (nextName !== undefined) {
				const navigations = items.map(v => ({
					...v,
					isNav: true,
					PARENT_ID: -1,
					TEXT: v.nameTree ? v.nameTree.replace(/\|/g, ' > ') : v.TEXT,
				}));

				const nextDatas = this.target[nextName].filter(v => selectedItemKeys.find(v2 => v2 === v.PARENT_ID));
				this.target[`v_${nextName}`] = [...navigations, ...nextDatas];
			}
		},
		async onSave() {
			let targets = [];
			this.dept.numbers.forEach((number) => {
				targets = targets.concat(this.target[`chk_dept${number}`])
			});

			let parents = [];
			targets.forEach((deptId) => {
					const data = this.target.deptList.find((data) => data.VALUE === deptId);

					if(data.PARENT_ID > 0) {
						parents.push(data.PARENT_ID)
					}
			});

			if(!this.siteId) {
				this.$_Msg('사이트를 선택해주세요.');
				return;
			} else if(!this.tenantId) {
				this.$_Msg('Tenant를 선택해주세요.');
				return;
			} else if(!this.inContentId && !this.outContentId) {
				this.$_Msg('설정 유형 선택이 잘못되었습니다.');
				return;
			} else if(!targets.length) {
				this.$_Msg('부서를 선택해주세요..');
				return;
			}

			const insertTarget = [];
			const updateTarget = [];

			targets.forEach((deptId) => {
				const data = this.reqParams.find((data) => data.deptId === deptId);
				if(data) {
					// 있으면 UPDATE
					updateTarget.push(data.id);
				} else {
					// 없으면 INSERT
					const data = {
						siteId: this.siteId,
						tenantId: this.tenantId,
						deptId: deptId,
						inContentId: this.inContentId,
						outContentId: this.outContentId
					}
					if(parents.includes(deptId)) {
						// 상위 값이기 때문에 ContentId null
						data.inContentId = null;
						data.outContentId = null;
					}
					insertTarget.push(data);
				}
			});

			const callAPI = [];
			if(insertTarget?.length) {
				const insertPayload = {
					actionName: 'INFOPUSH_DEPT_INSERT',
					data: {
						data: insertTarget
					},
					loading: true
				};

				callAPI.push(await this.CALL_API(insertPayload));
			}

			if(updateTarget?.length) {
				const updatePayload = {
					data: {
						data: {
							ids: updateTarget,
							inContentId: this.inContentId || 0,
							outContentId: this.outContentId || 0
						}
					},
					actionName: 'INFOPUSH_DEPT_CONTENT_UPDATE',
					loading: true
				};
				callAPI.push(await this.CALL_API(updatePayload));
			}

			if(callAPI.length) {
				Promise.all(callAPI).then(async (res) => {
					const fail = res.filter((r) => {
						return !isSuccess(r);
					});
					if(fail.length) {
						this.$_Msg(this.$_lang("CMN_ERROR"))
					} else {
						await this.$_Msg(this.$_lang("CMN_SUC_UPDATE"))
						this.$_goPrePage();
					}
				});
			}
		},
		async selectDeptList(tenantId) {
			const params = {
				tenantId,
				sort: '+depth,+deptOrd'
			};

			const payload = {
				actionName: 'REPORT_OBJECT_LIST_ALL',
				data: {
					params: {
						name: 'GROUP',
						userId: this.$store.getters.getLoginId,
					},
				},
				loading: true,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {

				const resDatas = res.data.data;
				this.target.deptList = resDatas;
				this.dept.numbers.forEach(num => {
					const name = `dept${num}`;
					const filter = this.target.deptList.filter(v => v.DEPTH === num);
					this.target[name] = filter.map((item) => {
						const text = item.PARENT_ID !== -1 ? item.NAMETREE.replaceAll('|', '>') : item.TEXT;
						const data = {
							...item,
							PARENT_ID: item.PARENT_ID,
							VALUE: item.VALUE,
							TEXT: text
						}
						return data
					})
				});
			}
		},
		async selectContentList() {
			const payload = {
				actionName: 'INFOPUSH_CONTENT_LIST_ALL',
				data: {
					params: {
            viewFl: this.$_enums.common.stringUsedFlag.YES.value
					}
				},
				loading: false,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				this.contentList = [{ id: null, description: '미적용' }, ...res.data.data];
			}
		},

		async getInfoPushSiteList() {
			const payload = {
				actionName: 'INFOPUSH_SITE_LIST_ALL',
				data: {
					params: {
            viewFl: this.$_enums.common.stringUsedFlag.YES.value,
						sort: '+siteOrd'
					}
				}
			}
			const res = await this.CALL_API(payload);
			if(isSuccess(res)) {
				this.infoPushSiteList = res.data.data
			}
		},
		onToolbarPreparing(e) {
			const toolbarItems = e.toolbarOptions.items;
			toolbarItems.unshift({
				location: 'after',
				widget: 'dxSelectBox',
				options: {
					width: 150,
					height: 30,
					stylingMode: this.stylingMode,
					items: this.pageList,
					displayExpr: 'codeNm',
					valueExpr: 'codeValue',
					value: this.tree.paging.pageSize,
					onValueChanged: this.onChangePageSize
				}
			});
		},
		onContentReady(e){
			if(e.component.totalCount() >= 0) {
				const totalCount = e.component.totalCount().toLocaleString();
				document.querySelector('.tet-calr1').innerHTML = totalCount;
			}
			if(this.tree.pager.visible) {
				const pageCount = e.component.pageCount().toLocaleString();
				document.querySelector('.dx-pages-count').innerHTML = pageCount;
			}
		},
		onChangePageSize(data){
			if(this.tree.paging.enabled) {
				this.tree.paging.pageSize = data.value;
			}
		},
		async bindPagingData() {
			const pageList = this.$_enums.common.pagingSizeList.values;
			this.pageList = pageList;
			this.tree.pager.allowedPageSizes = pageList.map(d => d.codeValue);
		},
		/**@description : 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {
			this.getInfoPushSiteList();
			this.selectContentList();
		},
		async createdData() {
			this.reqParams = this.$store.getters.getDetailParams.deptData;
			await this.bindPagingData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
	destroyed() {},
};
</script>

<style scoped>
.device-img > img {
	align-self: center;
}

tbody {
    display: table-row-group;
    vertical-align: top;
}

#dropzone-external > * {
    pointer-events: none;
}

.widget-container > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
}

</style>
