<!--
  PACKAGE_NAME : src/pages/cc/cti/ibg-category
  FILE_NAME : index.vue
  AUTHOR : hmlee
  DATE : 2024-07-09
  DESCRIPTION : 인입그룹 카테고리 관리 화면
-->
<template>
  <div class="page-sub-box tree-box">
    <!-- 트리 상위 버튼 -->
    <TopBtnBox ref="topBtnBox" :tree-list="tree.infoList" :selected-row-datas="tree.selectedRowkeys"
               :clicked-row-data="tree.focusedRowData" :clicked-row-element="tree.rowElement"
               @openTree="onOpenTree" @foldTree="onFoldTree" @setOrder="onSetOrder"
               @deleteDatas="onDeleteDatas"/>

    <!-- 트리 리스트 -->
    <CategoryList ref="categoryList" :expanded-row-keys="tree.expandedRowKeys"
                  :category-level="getCategoryLevel"
                  @selectRow="onSelectRow" @clickRow="onClickRow" @setOrder="onSetOrder" @select="onSelect"
                  @initClickRow="onInitClickRow"/>

    <!-- 우측 레이아웃 선택한 데이터 폼 영역 -->
    <Form ref="form" :clicked-row-data="tree.focusedRowData" :category-level="getCategoryLevel"
          :tree-list="tree.infoList"/>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :modalData="modal.sendData"
                     v-model="modal.contentData"></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.hasOwnProperty('save') : !modal.initData.buttons.hasOwnProperty('save') )  : false"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('save') ? modal.initData.buttons.save.text : '' ) : '',
          //type: 'default',
          width: '120',
          height: '40',
          validationGroup: 'validationGroupModal',
          useSubmitBehavior: true,
          onClick: (e) => {
            onConfirmModal(e);
          }
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.hasOwnProperty('cancel') : !modal.initData.buttons.hasOwnProperty('cancel') ) : false"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons') ? ( modal.initData.buttons.hasOwnProperty('cancel') ? modal.initData.buttons.cancel.text : '' ) : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          }
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
import {DxPopup, DxPosition, DxToolbarItem} from 'devextreme-vue/popup';

import {isSuccess} from "@/utils/common-lib";

import ModalIbgCategory from '@/pages/cc/cti/ibg-category/modal/modal-ibg-category.vue';
import ModalIbg from '@/pages/cc/cti/ibg-category/modal/modal-ibg.vue';
import TopBtnBox from "@/pages/cc/cti/ibg-category/top-btn-box.vue";
import CategoryList from '@/pages/cc/cti/ibg-category/category-list.vue';
import Form from '@/pages/cc/cti/ibg-category/form.vue';

export default {
  components: {
    DxPopup,
    DxPosition,
    DxToolbarItem,

    ModalIbgCategory,
    ModalIbg,
    TopBtnBox,
    CategoryList,
    Form,
  },
  props: {},
  watch: {},
  data() {
    return {
      config: {
        stylingMode: 'outlined',    //[outlined, filled, underlined]
        isViewFl: true,
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      tree: {
        infoList: [],
        selectedRowkeys: [],
        expandedRowKeys: [],
        focusedRowData: null,
      },
    }
  },
  computed: {
    /** @description: 현재 그룹 카테고리 레벨 값 조회
     *                현재 구조는 level 2 or 3까지 가능
     *                시스템설정에서 지정한 그룹 카테고리 레벨이 없으면 default는 3 */
    getCategoryLevel() {
      let categoryLevel = this.$store.getters.getSystemCodeList.find(d => d.configKey === 'ibg_category_level');
      if (categoryLevel) {
        categoryLevel = Number(categoryLevel.configValue);
      } else {
        categoryLevel = 3;
      }
      return categoryLevel;
    },
  },
  methods: {
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, sendData) {
      //this.$log.debug(componentNm, componentInitData, sendData);
      this.modal.currentComponent = componentNm;  //set dynamic component name in modal body slot
      this.modal.initData = componentInitData;  //set init modal templet
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    /** @description: 팝업 확인 버튼 이벤트 */
    async onConfirmModal(e) { //click modal save btn : set formData get modal body slot componet data
      //해당 모달 컴포넌트에서 데이터 저장
      let promise = new Promise((resolve, reject) => {
        this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
      });

      promise.then((res) => {
        // use res
        if (res === 200) {
          this.$refs.categoryList.initPage();
        } else {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
        }
      }).catch((err) => {
        // handle error
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
      })

    },
    /** @description: 트리 펼치기 */
    onOpenTree(data) {
      this.tree.expandedRowKeys = data;
    },
    /** @description: 트리 접기 */
    onFoldTree(data) {
      this.tree.expandedRowKeys = data;
    },
    /** @description: 트리 순서 변경 */
    onSetOrder(data) {
      this.tree.infoList = data.list;
      this.$refs.topBtnBox.setSort(data.list, data.targetData);
    },
    /** @description: 카테고리 로우 리스트 선택 이벤트 */
    onSelectRow(rowData) {
      if (rowData) {
        this.tree.selectedRowkeys = rowData;
      }
    },
    /** @description : 카테고리 로우 데이터 클릭 이벤트 */
    onClickRow(event) {
      if (event) {
        this.tree.focusedRowData = event.data;
        this.tree.rowElement = event.rowElement;
      }
    },
    /** @description : 카테고리 인입그룹 로우 데이터 삭제 이벤트 */
    async onDeleteDatas(res) {
      if (isSuccess(res)) {
        this.$refs.categoryList.initPage(); //페이지 초기화
      }
    },
    /** @description : 카테고리 조회 이벤트 */
    onSelect(data) {
      this.tree.infoList = data;
    },
    /** @description : 카테고리 로우 데이터 클릭 초기화 */
    onInitClickRow(data) {
      this.tree.focusedRowData = {};
    },
    /** @description : 취소 버튼 클릭시 메서드 */
    onCancelForm() {
      this.$refs.categoryList.initPage(); //페이지 초기화
    },
    /** @description: 저장 메서드 */
    async updateData(dataList) {
      const payload = {
        actionName: 'CC_IBG_CATEGORY_MERGE',
        data: dataList,
      }

      const ibg = this.$_lang('CC.WORD.IBG', {defaultValue: '인입그룹'});
      const category = this.$_lang('COMPONENTS.CATEGORY', {defaultValue: '카테고리'});
      const cfmUpdateInfo = this.$_lang('CC.MESSAGE.CFM_UPDATE_INFO', {defaultValue: '정보를 수정 하시겠습니까?'});

      if (await this.$_Confirm(`${ibg} ${category} ${cfmUpdateInfo}`)) {
        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.$refs.categoryList.initPage();
        }
      }
    },
  },
  created() {},
  mounted() {},
}
</script>

<style scoped>
.wrap main .content .page-sub-box.tree-box {
  padding-top: 20px;
}
</style>
