<template>
	<DxChart
			id="chart"
			:data-source="dataSource"
			:customize-label="customizeLabel"
	>
		<DxValueAxis :max-value-margin="0.1" />
		<DxCommonSeriesSettings
				argument-field="ymd"
				type="stackedbar"
				hover-mode="allArgumentPoints"
				selection-mode="allArgumentPoints"
		/>
		<DxSeries
				value-field="fail"
				name="실패"
				color="#f5564a"
		/>
		<DxSeries
				value-field="success"
				name="성공"
				color="#97c95c"
		/>
		<DxLegend
				vertical-alignment="bottom"
				horizontal-alignment="center"
		/>
		<DxTooltip
				:enabled="true"
				:customize-tooltip="customizeTooltip"
				:z-index="99"
				location="edge"
		/>
	</DxChart>
</template>

<script>
import {
	DxChart,
	DxCommonSeriesSettings,
	DxLabel,
	DxFormat,
	DxSeries,
	DxLegend,
	DxTooltip,
	DxValueAxis
} from 'devextreme-vue/chart';
import Search from '@/components/common/search.vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import { isSuccess } from '@/utils/common-lib';

let vm = this;

export default {
	props: {
		labelStyle: {
			type: Object
		},
		searchData: {
			type: Object
		},
		dayStart: {
			type: String
		},
		dayEnd: {
			type: String
		}
	},
	components: {
		DxSelectBox,
		DxDateBox,
		Search,
		DxButton,
		DxChart,
		DxCommonSeriesSettings,
		DxLabel,
		DxFormat,
		DxSeries,
		DxLegend,
		DxTooltip,
		DxValueAxis
	},
	computed: {
	},
	data() {
		return {
			chartMode: null,
			originData: [],
			stylingMode: '', //outlined, underlined, filled
			dataSource: null,
			resultList: [],
			dateFormat: null,	 // daily, monthly
		};
	},
	methods: {
		customizeLabel(data) {
			if(data?.seriesName === '성공') {
				const { total, success } = data.data;
				return {
					visible: true,
					position: 'top',
					backgroundColor: 'none',
					font: {
						color: '#000',
						size: this.labelStyle?.size || 18,
						weight: this.labelStyle?.weight || 600
					},
					customizeText() {
						if(total > 0) {
							const value = Math.round((success / total) * 100) + '%';
							return value;
						} else {
							return 0
						}
					},
				}
			}
		},
    customizeTooltip(pointInfo) {
      const text = [];

      text.push(`총 ${pointInfo.total}회중 ${pointInfo.value || 0}회 ${pointInfo.seriesName}`);

      if (pointInfo?.seriesName === '실패') {
        const item = this.dataSource.find((data) => data.ymd === pointInfo.argument);
        const failData = this.originData.filter((data) => data.YMD === item.originYmd && (data.FAIL1_CNT > 0 || data.FAIL2_CNT > 0 || data.FAIL3_CNT > 0 || data.FAIL4_CNT > 0 || data.FAIL5_CNT > 0 || data.FAIL_ETC_CNT > 0));

        failData.forEach((data) => {
          const failCodes = ['FAIL1', 'FAIL2', 'FAIL3', 'FAIL4', 'FAIL5'];

          failCodes.forEach((failCode) => {
            const failCd = data[`${failCode}_CD`];
            const failCnt = data[`${failCode}_CNT`];

            if (failCd && failCnt > 0) {
              const result = this.resultList.find((result) => result.codeValue === failCd);
              text.push(`${result.codeNm} ${failCnt}회`);
            }
          });

          const etcCd = data.FAIL_ETC_CD;
          const etcCnt = data.FAIL_ETC_CNT;

          if (etcCd && etcCnt > 0) {
            text.push(`그 외 오류 ${etcCnt}회`);
          }
        });
      }

      return {
        text: text.join('\n'),
      };
    },
    async selectDataList() {
			const params = {
				dayStart: this.dayStart,
				dayEnd: this.dayEnd,
				report_type: 'daily',
				name: 'H_INFOPUSH_D',
				type: 'master-query'
			};

			const payload = {
				actionName: 'MASTER_QUERY_RESULT_LIST',
				data: { data: params },
				loading: true,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				this.originData = res.data.data;
				let results = res.data.data.reduce((acc, item) => {
					const ymd = this.formatDt(item.YMD);
					const existingItem = acc.find(entry => entry.ymd === ymd);
          if (existingItem) {
            this.incrementResultValues(existingItem, item);
          } else {
            acc.push(this.createResultEntry(item, ymd));
          }

					return acc;
				}, []);

				if(this.chartMode === 'FULL') {
					const dayStart = this.dayStart;
					const dayEnd = this.dayEnd;

					const startDate = this.$_commonlib.moment(dayStart, 'YYYYMMDD');
					const endDate = this.$_commonlib.moment(dayEnd, 'YYYYMMDD');

					const dateArray = [];

					for (let date = startDate; date.isSameOrBefore(endDate); date.add(1, 'days')) {
						const formattedDate = date.format('YYYYMMDD');
						dateArray.push(formattedDate);
					}

					results = dateArray.map((date) => {
						const dateData = results.find((result) => result.originYmd === date);
						if(!dateData) {
							return this.createResultEntry({ TRY: 0, SUCCESS: 0, FAIL: 0 }, this.formatDt(date));
						}
						return dateData;
					});
				}

				this.dataSource = results;
			}
		},
    incrementResultValues(existingItem, item) {
      existingItem.total += item.TRY;
      existingItem.success += item.SUCCESS;
      existingItem.fail += item.FAIL;

      for (let i = 1; i <= 5; i++) {
        const failCd = item[`FAIL${i}_CD`];
        const failCnt = item[`FAIL${i}_CNT`];
        existingItem[`fail${i}_cd`] = failCd;
        existingItem[`fail${i}_cnt`] += failCnt;
      }

      const etcCd = item.FAIL_ETC_CD;
      const etcCnt = item.FAIL_ETC_CNT;
      existingItem.fail_etc_cd = etcCd;
      existingItem.fail_etc_cnt += etcCnt;
    },
    createResultEntry(item, ymd) {
      return {
        ymd,
        originYmd: item.YMD,
        total: item.TRY,
        success: item.SUCCESS,
        fail: item.FAIL,
        fail1_cd: item.FAIL1_CD,
        fail1_cnt: item.FAIL1_CNT,
        fail2_cd: item.FAIL2_CD,
        fail2_cnt: item.FAIL2_CNT,
        fail3_cd: item.FAIL3_CD,
        fail3_cnt: item.FAIL3_CNT,
        fail4_cd: item.FAIL4_CD,
        fail4_cnt: item.FAIL4_CNT,
        fail5_cd: item.FAIL5_CD,
        fail5_cnt: item.FAIL5_CNT,
        fail_etc_cd: item.FAIL_ETC_CD,
        fail_etc_cnt: item.FAIL_ETC_CNT,
      };
    },
		async selectResultList() {
			const payload = {
				actionName: 'EUC_RESULT_LIST_ALL',
				data: {},
				loading: false,
			};
			const res = await this.CALL_EUC_API(payload);
			if(isSuccess(res)) {
				this.resultList = res.data.data;
			}
		},
		formatDt(date) {
			if(date) {
				return this.$_commonlib.formatDate(date, 'YYYYMMDD', this.dateFormat);
			}
		},
		mountedData() {
			vm = this;
			this.selectResultList();
		},
		createdData() {
			this.chartMode = this.searchData?.chartMode || 'DYNAMIC';
			this.stylingMode = this.searchData?.stylingMode || 'outlined';
			this.dateFormat = this.searchData?.dateFormat || 'YYYY-MM-DD'; // 'YYYY-MM-DD', 'DD일'
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
