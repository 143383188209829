export default {
  MESSAGE: {
    APPROVED: '승인 처리되었습니다.',
    UNSAVED: '저장되지 않은 데이터가 있습니다.',
    CHECK_PICKUP_DEPT: '그룹명은 `PG-D_`로 시작되야 합니다.',
    CHECK_PICKUP_ETC: '그룹명은 `PG-P_`로 시작되야 합니다.',
    CHECK_ADD_COUNT: '항목 추가는 10개까지 가능합니다.',
    CHECK_EXCHANGER_TYPE: '교환기는 한종류만 입력가능합니다.',
    START_SYNC: 'DB데이터를 동기화 하시겠습니까?',
    CHANGE_LIST_ORDER: '헌트리스트 순서도 같이 변경됩니다.',
    REQUIRED_SELECT_HUNTPILOT: '헌트파일럿을 먼저 선택해주세요.',
    REQUIRED_SELECT_HUNTLIST: '헌트리스트를 먼저 선택해주세요.',
    REQUIRED_SELECT_LINEGROUP: '회선그룹을 먼저 선택해주세요.',
    PILOT_LIST_ONE_SELECT: '파일럿 / 리스트는 한건씩만 선택해주세요.',
    PILOT_LIST_MISS_MATCH: '해당 헌트리스트는 파일럿에 속하지않습니다. ',
    DESCRIPTION_ENTER: '설명을 입력하세요.',
    NUMBER_ENTER: '숫자만 입력하세요.',
    CHANGED_DATA: '변경된 값이 있습니다.',
    REQUIRED_EXCHANGER_CHECK: '교환기 체크를 먼저 진행해주세요.',
    ENCRYPTED_FILE_UPLOAD_NOT_ALLOWED: '암호화된 파일은 업로드 할 수 없습니다.',
    DN_ENTER: '내선번호를 입력하세요.',
    DEVICE_NAME_ENTER: '장치 이름을 입력하세요.',
    CHECK_MAC_ADDR: '동일한 장치이름이 존재합니다.',
    NO_PERSON_INFO: '로그인한 유저의 인사정보가 없습니다.',
    REQUIRED_TITLE: '제목을 입력해주세요.',
    REQUIRED_APPROVER: '승인자를 선택해주세요.',
    REQUIRED_REQUEST_ITEMS: '신청 항목을 입력해주세요.',
    PLACEHOLDER_EXISTENCE_CONFIRMATION_QUERY: '존재 확인 SQL을 입력해 주세요.',
    PLACEHOLDER_DELETE_QUERY: '삭제 SQL을 입력해 주세요.',
    PLACEHOLDER_FILE_DELETE_DEFAULT_PATH: '파일 삭제시 기본 패스( /home/ecss/api/file 이후, Ex : /report/excel )',
    MAIN_SETTING_MENU_TYPE_ERROR: "메뉴 타입이 올바르지 않습니다. (오직 '일반 페이지'와 '보고서'만 허용됩니다.)",
    NOT_FOUND_AVAYA_DEVICE_FILE: '해당 장치의 파일 데이터가 존재하지 않습니다.',
  },
  WORD: {
    DUE_DATE: '완료 희망일',
    USER_NO: '사번',
    REQUESTER: '신청자',
    APPROVER: '승인자',
    APPROVAL_DATE: '승인일',
    APPROVAL_FL: '승인여부',
    WORKER: '작업자',
    REQUESTER_INFO: '신청자 정보',
    USER_SELECT: '사용자 선택',
    APPROVAL_SELECT: '승인자 선택',
    WORKER_SELECT: '작업자 선택',
    APPLY_TARGET: '적용대상',
    TYPE_MANAGEMENT: '유형 관리',
    DETAILED_TYPE: '상세 유형',
    DETAILED_TYPE_MANAGEMENT: '상세 유형 관리',
    DETAILED_TYPE_COUNT: '상세 유형의 수',
    TYPE: '유형',
    TYPE_NAME: '유형명',
    REQUEST_TYPE: '신청 유형',
    AFTER_LINE: '후선',
    DEPT_ATTRIBUTES: '부서 속성',
    BLOCKING_POLICY: '차단 정책',
    EXCEPTION_POLICY: '예외 정책',
    EXCEPTION_SETTING: '예외 설정',
    ALLOW: '허용',
    BLOCK: '차단',
    EXCHANGER: '교환기',
    DEPT_NAME: '부서명',
    DEPT_CODE: '부서코드',
    PATTERN: '패턴',
    PARTITION: '파티션',
    DEPT_NAME_SEARCH: '부서명 검색',
    UNUSED: '사용 중지',
    VIEW_DEPT_USE: '사용중 부서만 보기',
    CREATE_DEPT_PICKUP: '부서별 당겨받기 생성',
    DEPT_PICKUP_INFO: '부서별 당겨받기 정보',
    CREATE_ETC_PICKUP: '개별 당겨받기 생성',
    CREATE_DEPT: '부서별 생성',
    PICKUP_MANAGE: '당겨받기 관리',
    REQUESTER_SELECT: '요청자 선택',
    SENDER: '발신자',
    SENDER_NUMBER: '발신자 번호',
    SENDER_DEPT: '발신자 부서',
    RECEIVER: '수신자',
    RECEIVER_NUMBER: '수신자 번호',
    RECEIVER_DEPT: '수신자 부서',
    BLOCK_DATE: '차단일',
    BLOCK_END_DATE: '차단 종료일',
    BLACK_LIST: '블랙리스트',
    BLACK_LIST_HISTORY: '블랙리스트 이력',
    INDEFINITE: '무기한',
    PROGRESS_STATUS: '진행 상태',
    DISTRIBUTIONALGORITHM: '배포 알고리즘',
    RNAREVERSIONTIMEOUT: 'RNA 복귀시간 초과',
    HUNTGROUP_SYNC: '헌트그룹 동기화',
    DEVICE_SYNC: '장치 동기화',
    LINE_SYNC: '내선 동기화',
    LINEGROUPDN_SET: '회선번호 설정',
    PILOT_LIST_SET: '파일럿/리스트 추가',
    LIST_LINEGROUP_SET: '리스트/회선그룹 설정',
    LINEGROUP_ADD: '회선그룹 추가',
    CHECK: '조회',
    HUNTPILOT: '파일럿',
    HUNTLIST: '리스트',
    LINEGROUP: '회선그룹',
    LINEGROUPDN: '회선번호',
    SAVE_ORDER: '순서저장',
    TOTAL: '총',
    COUNT: '개',
    ALL_LIST: '전체리스트',
    PREPAGE: '이전페이지',
    MODEL: '모델',
    STATUS: '상태',
    DEVICE_INFO: '전화 정보',
    DEVICE_INFO_DETAIL: '전화 정보 상세',
    LINE_INFO: '회선 정보',
    DEVICE_NAME: '장치이름',
    DEVICEPOOL: '장치풀',
    SOFTKEY: '소프트키',
    NUMBER: '내선번호',
    LABEL: '레이블',
    SERIAL: '시리얼',
    PHONEBUTTON: '버튼',
    APPLY: '적용',
    EXPANSION_MODULE: '확장모듈',
    EXPANSION_MODULE_SET: '확장모듈 설정',
    PICKUPGROUP_SET: '당겨받기 설정',
    LINE_SET: '전화번호 설정',
    CSS: '발신검색',
    FORWARDNO: '착신전환',
    PICKUPGROUP: '당겨받기',
    REC: '녹취',
    REC_PERMISSION: '녹취 권한',
    EXCEL_DOWNLOAD: '엑셀 다운로드',
    EXCEL_UPLOAD: '엑셀 등록',
    EXCHANGER_SELECT: '교환기 조회',
    EXCHANGER_CHECK: '교환기 체크',
    EXCHANGER_APPLY: '교환기 등록',
    INDEX: '순번',
    LINE_ADD: '내선번호 추가',
    TARGET_DEVICE: '장치 대상',
    NEW_DEVICE: '신규 장치',
    CONTACT: '주소록',
    ALERTINGNAME: '표시(경고이름)',
    EXTERNALCALLCONTROLPROFILE: '외부통화제어',
    DISPLAY: '화면',
    DISPLAY_NAME: '화면표시',
    FIRST_NAME: '이름',
    MIDDLE_NAME: '중간이름',
    LASG_NAME: '성',
    CSF: 'PC JABBER',
    BOT: '안드로이드',
    TCT: '아이폰',
    TAB: '태블릿',
    CHECK_EXISTING_DEVICE: '기존 단말 삭제 여부',
    GROUP_NUMBER: '그룹번호',
    REG_NUMBER: '등록 내선',
    LICENSE_STATUS: '라이센스 현황',
    LICENSE_HISTORY: '라이센스 이력',
    LICENSE: '라이센스',
    APUSRASSIGNEDMODEL: '유저등록모델',
    SMARTASSIGNEDMODEL: '스마트등록모델',
    MODEL_SYNC: '모델 동기화',
    LICENSE_SYNC: '라이센스 동기화',
    SYNC: '동기화',
    CALL_MANAGER_GROUP: '콜매니저그룹',
    REGION: '지역',
    DATETIME_SETTING: '시간대',
    CREATE: '생성',
    WORK_ID: '작업 ID',
    RECENT_WORK_HISTORY: '작업 이력',
    EXCEL_FILE: '엑셀 파일',
    OTHERS_FILE: '기타 파일',
    ASCII_ALERTINGNAME: 'Ascii 경고이름',
    HUNTINFO: '헌트정보',
    PILOT_DESCRIPTION: '파일럿 설명',
    LIST_DESCRIPTION: '리스트 설명',
    AUTOMATION_MANAGEMENT: '자동화 관리',
    AUTOMATION_SETTING: '자동화 설정',
    AUTOMATION_CODE: '자동화 코드',
    TRANSFORM_NUMBER: '전환번호',
    EXISTENCE_CONFIRMATION_QUERY: '존재 확인 질의문',
    DELETE_QUERY: '삭제 질의문',
    FILE_DELETE_FLAG: '파일 삭제 유무',
    STORAGE_PERIOD: '보관기간 (개월)',
    DATA_MANAGEMENT_HISTORY: '데이터 관리 이력',
    HOT_LINE: '직통번호',
    MEMO: '메모',
    START_DATE: '시작일',
    END_DATE: '종료일',
    FORWARD_NUMBER: '착신번호',
    RESET: '리셋',
    END_USER_MANAGEMENT: '최종 사용자 관리',
    PC_JABBER_MANAGEMENT: 'PC Jabber 관리',
    MONITORING_FLAG: '모니터링 여부',
    APP_USER: 'APP 유저',
    STAFF_IP: '직원 IP',
    SERVER_IP: '서버 IP',
    STATUS_CHANGE_TIME: '상태 변경 시간',
    LOGIN_TIME: '로그인 시간',
    HER_NUMBER_LIST: 'Her 번호 리스트',
    HER_NUMBER_SETTING: 'Her 번호 설정',
    HUNT_PILOT: '헌트 파일럿',
  },
};
