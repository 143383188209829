<template>
  <div id="container">
    <div class="container-top-wrap">
      <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="cusmain-table-wrap pt-30">
          <ReportDataGrid
            :ref="targetGrid.refName"
            :gridInfo="targetGrid"
            :columns="targetGridColumns"
            :dataSource="reportRowData"
            :originColumns="originColumns"
          />
        </div>
        <div class="cusmain-table-wrap">
          <ReportDataGrid
            :ref="historyGrid.refName"
            :gridInfo="historyGrid"
            :columns="historyColumns"
            :dataSource="historyDatas"
            :originColumns="originColumns"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate } from '@/utils/common-lib';
  import ReportDataGrid from '@/components/report/report-popup-grid.vue';
  export default {
    components: {
      ReportDataGrid,
    },
    props: {
      reportRowData: {
        type: Array,
        default: () => [],
      },
      targetGridColumns: {
        type: Array,
        default: () => [],
      },
      historyColumns: {
        type: Array,
        default: () => [],
      },
      historyDatas: {
        type: Array,
        default: () => [],
      },
      originColumns: {
        type: Array,
        default: () => [],
      },
    },
    watch: {},
    data() {
      return {
        targetGridCount: 0,
        targetGrid: {
          refName: 'targetGrid',
          size: {
            width: '100%',
            height: 100,
          },
          toolbar: {
            filter: false,
            excel: false,
            totalCount: false,
          },
          paging: false,
        },
        historyGridCount: 0,
        historyGrid: {
          refName: 'historyGrid',
          size: {
            width: '100%',
            height: 250,
          },
          scroll: {
            x: true,
            y: true,
          },
          toolbar: {
            filter: false,
            excel: false,
            totalCount: true,
          },
          paging: {
            enabled: true,
            pageSize: 100,
            pageIndex: 0,
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: true,
          },
          rowColor: {},
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        gridRefName: 'grid',
      };
    },
    computed: {
      grid() {
        return this.$refs[this.gridRefName].instance;
      },
    },

    methods: {
      cellTemplateByColumnFmt(fmt) {
        let cellTemplate = null;
        if ('fmDate' === fmt) cellTemplate = 'dateTemplate';
        else if ('fmNumber' === fmt) cellTemplate = 'numberTemplate';
        else if ('fmPercent' === fmt) cellTemplate = 'percentTemplate';
        else if ('fmtime' === fmt) cellTemplate = 'timeTemplate';
        else if ('button' === fmt) cellTemplate = 'buttonTemplate';
        return cellTemplate;
      },
      convertBool(str) {
        return [true, 'true', 'TRUE'].includes(str);
      },
      fmtDate(value) {
        return formatDate(value, 'YYYYMMDD', 'YYYY-MM-DD');
      },
      fmtNumber(value) {
        return parseInt(value).toLocaleString('ko-KR');
      },
      fmtTime(seconds) {
        const hour = this.lPadZero(parseInt(seconds / 3600));
        const min = this.lPadZero(parseInt((seconds % 3600) / 60));
        const sec = this.lPadZero(parseInt(seconds % 60));
        return `${hour}:${min}:${sec}`;
      },
      lPadZero(num) {
        return num < 10 ? '0' + num : num;
      },
    },
  };
</script>

<style scoped>
  .pt-30 {
    padding-top: 30px;
  }
</style>
