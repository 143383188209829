import Contact from '@/pages/euc/contact/index.vue';

export default function ContactRoutes(commonComponents) {
    return [
        {
            path: '/euc/contact',
            name: 'Contact',
            components: { default: Contact, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}