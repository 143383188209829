import Vue from 'vue';
import SockJS from 'sockjs-client';
import Stomp from 'webstomp-client';
import { getStoreInstance } from '@/store';
import { EventBus } from '@/event-bus';

export const WEBSOCKET_CONNECT = async ({ commit }, payload) => {
  let reconnectInterval = 5000; // 재연결 시도 간격 (밀리초)

  const connect = () => {
    try {
      const store = getStoreInstance();
      const userId = store.getters.getLoginId;
      const socket = new SockJS(`${window.location.origin}/esp/ws?userId=` + userId);
      const stompClient = Stomp.over(socket);

      commit('setStompClient', stompClient);

      stompClient.connect({ userId: userId }, function (frame) {
        console.log("@ 소켓 연결 성공");
        commit('setStompClient', stompClient);

        const sessionId = stompClient.ws._transport.url.split('/')[5];
        commit('setSessionId', sessionId);

        stompClient.subscribe('/user/queue/messages', (message) => {
          if(message.body === 'alarm'){
            EventBus.$emit('initAlarm', message);
          }
        });

        // 🔥 전체 메시지 구독
        stompClient.subscribe('/topic/public', function (message) {
        });

        // 연결 손실 처리 및 재연결 시도
        stompClient.ws.onclose = () => {
          console.log("@ 소켓 연결 끊김, 재연결 시도 중...");
          commit('setStompClient', null);
          setTimeout(connect, reconnectInterval); // 재연결 시도
        };
      }, (error) => {
        console.error("@ 소켓 연결 실패", error);
        setTimeout(connect, reconnectInterval); // 재연결 시도
      });
    } catch (error) {
      console.error("@ 소켓 연결 중 예외 발생", error);
      setTimeout(connect, reconnectInterval); // 재연결 시도
    }
  };

  connect();
};