<!--
  PACKAGE_NAME : src/pages/esp/auth
  FILE_NAME : menu-auth-tree-list.vue
  AUTHOR : devyoon91
  DATE : 2024-12-10
  DESCRIPTION : 메뉴권한 트리리스트 컴포넌트
-->
<template>
  <div>
    <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName" @selection-changed="handleSelectionChanged" @saving="handleSave" />
  </div>
</template>

<script>
  import { isSuccess } from '@/utils/common-lib';
  import EspDxTreeList from '../../../components/devextreme/esp-dx-tree-list-v2.vue';
  import { DxSwitch } from 'devextreme-vue/switch';

  export default {
    components: { EspDxTreeList },
    data() {
      return {
        selectedAuthId: null,
        selectedMainPageId: null,
        treeList: {
          keyExpr: 'id',
          refName: 'menuAuthTreeList',
          height: 'calc(100vh - 223px)',
          dataSource: [],
          showActionButtons: {
            sort: false,
            toggleExpand: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
                  elementAttr: { class: 'default filled txt_S medium' },
                  width: 60,
                  height: 30,
                  onClick: e => {
                    this.handleSave(e);
                  },
                },
                location: 'after',
              },
            ],
          },
          filterRow: {
            visible: false,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
          },
          selection: {
            //로우 선택 설정
            allowSelectAll: true, //헤더 체크박스 선택(전체선택) 허용 여부
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple', 'none']
            recursive: true, //상위 노드 선택시 하위 노드도 선택 여부(true: 하위 노드도 선택, false: 하위 노드 선택 안하고 독립적)
          },
          columns: [
            { dataField: 'id', caption: 'id', visible: false },
            { dataField: 'parentId', caption: 'parentId', visible: false },
            { dataField: 'menuNm', caption: '메뉴', alignment: 'left', allowSorting: false },
            {
              dataField: 'mainPageFl',
              caption: '메인페이지 설정',
              alignment: 'center',
              allowSorting: false,
              width: 150,
              cellTemplate: (container, options) => {
                // 메뉴 타입이 일반메뉴 또는 링크인 경우 메인 페이지 변경 불가
                if (
                  this.$_enums.common.menuType.NORMAL_MENU.equals(options.data.menuTypeCd) ||
                  this.$_enums.common.menuType.LINK.equals(options.data.menuTypeCd)
                ) {
                  return;
                }

                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.value === this.$_enums.common.stringUsedFlag.YES.value,
                    onInitialized: e => {
                      e.component.option('value', this.selectedMainPageId === options.data.id);
                    },
                    onValueChanged: async e => {
                      // DB에 저장된 권한 여부 플래그로 메인페이지 설정 여부를 체크
                      const cachedDataSourceMap = this.treeDataSourceMap.get(options.data.id);
                      if (cachedDataSourceMap?.authUseFl === this.$_enums.common.stringUsedFlag.NO.value) {
                        this.$_Toast(
                          this.$_lang('COMMON.MESSAGE.CMN_NO_AUTH', {
                            defaultValue: '권한이 없습니다.',
                            value: `${this.$_lang('COMPONENTS.MENU', { defaultValue: '메뉴' })} `,
                          }),
                        );
                        e.component.option('value', false);
                        return;
                      }

                      const previousId = this.selectedMainPageId;

                      if (e.value === true) {
                        // 기존 선택 비활성화
                        if (previousId !== null) {
                          this.setDataSourceByMainPageFl(previousId, this.$_enums.common.stringUsedFlag.NO.value);
                        }

                        // 새로 선택
                        this.selectedMainPageId = options.data.id;

                        if (await this.updateAuthMainPage()) {
                          this.setDataSourceByMainPageFl(this.selectedMainPageId, this.$_enums.common.stringUsedFlag.YES.value);
                          this.getInstance().refresh();
                        } else {
                          this.selectedMainPageId = previousId; // 이전 값으로 복원
                        }
                      } else if (e.value === false) {
                        if (await this.deleteAuthMainPage()) {
                          this.selectedMainPageId = null;
                          this.setDataSourceByMainPageFl(options.data.id, this.$_enums.common.stringUsedFlag.NO.value);
                          this.getInstance().refresh();
                        }
                      }
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
            },
          ],
        },
        updateData: {}, // 권한 업데이트 대상 객체
        treeDataSourceMap: null, // DataSource Tree Map(캐싱) 하기 위한 객체(초기화 null 선언 필요)
        isInInitialization: false, // 초기화 작업 진행 여부
      };
    },
    methods: {
      /**
       * @description 초기 선택 상태 반환
       * @param rowKey
       * @return {Boolean}
       */
      getInitialSelectionState(rowKey) {
        // find 순차적 사용은 성능저하 문제로 Map 으로 캐싱 처리
        const mapData = this.treeDataSourceMap.get(rowKey);

        if (!mapData) {
          return false;
        }
        return mapData.authUseFl === this.$_enums.common.stringUsedFlag.YES.value;
      },
      /**
       * @description row 상태 업데이트를 처리하는 유틸리티 함수
       * @param rowKey {String|Number} 행 ID
       * @param initialState {Boolean} 초기 상태 객체
       * @param isSelected {Boolean} 현재 선택 여부
       */
      updateRowState(rowKey, initialState, isSelected) {
        // 선택 상태 업데이트(DataSource 업데이트)
        const targetRow = this.getTreeDataSource().find(row => row.id === rowKey);

        if (targetRow) {
          // 데이터 수정(DataSource 업데이트)
          targetRow.authUseFl = isSelected ? 'Y' : 'N';
        }

        // 선택 상태가 초기 상태와 같으면 updateData 에서 제거
        if (isSelected === initialState) {
          delete this.updateData[rowKey];
        } else {
          const node = this.$refs.menuAuthTreeList.getInstance.getNodeByKey(rowKey);
          this.updateData[rowKey] = { selected: isSelected, isParent: node.hasChildren };
        }
      },
      /**
       * @description 선택/해제 로직 처리 함수
       * @param rowKeys {Array} 처리할 rowKeys 배열
       * @param isSelected {Boolean} 선택/해제 여부 (true: 선택, false: 해제)
       */
      async setSelection(rowKeys, isSelected) {
        const treeListInstance = this.$refs.menuAuthTreeList.getInstance; // TreeList 인스턴스

        // rowKeys 처리: 병렬로 처리하여 반복 시간 단축
        await Promise.all(
          rowKeys.map(async rowKey => {
            // 초기 상태 가져오기
            const initialState = this.getInitialSelectionState(rowKey);

            // 현재 rowKey 상태 업데이트
            this.updateRowState(rowKey, initialState, isSelected);

            // 관련된 자식 ID 처리
            const relatedIds = await this.getAllRelatedAuthIds(rowKey);

            // 관련된 항목도 병렬로 처리
            await Promise.all(
              relatedIds.map(async id => {
                const relatedInitialState = this.getInitialSelectionState(id);
                this.updateRowState(id, relatedInitialState, isSelected);
              }),
            );

            // 부모 노드를 처리 (하위 노드 상태에 따라)
            this.handleParentUpdate(rowKey, treeListInstance);
          }),
        );
      },
      /**
       * @description 부모 노드의 상태를 처리
       * @param childKey {String|Number} 현재 업데이트된 자식 노드의 키
       * @param treeListInstance TreeList 인스턴스
       */
      handleParentUpdate(childKey, treeListInstance) {
        const parentKey = this.findParentKey(childKey, treeListInstance.option('dataSource'));

        if (!parentKey) {
          // 부모 노드가 없으면 종료
          return;
        }

        // 부모 노드의 모든 자식 가져오기
        const childNodes = this.findChildKeys(parentKey, treeListInstance.option('dataSource'));

        // 자식 노드 중 선택된 것이 있는지 확인
        const anySelected = childNodes.some(childKey =>
          this.updateData[childKey] ? this.updateData[childKey].selected : this.getInitialSelectionState(childKey),
        );

        // 자식 노드 중 선택된 것이 없는지 확인
        const noneSelected = childNodes.every(childKey =>
          this.updateData[childKey] ? !this.updateData[childKey].selected : !this.getInitialSelectionState(childKey),
        );

        const initialState = this.getInitialSelectionState(parentKey);

        // 부모 노드를 업데이트할지 결정
        if (anySelected) {
          // 자식 노드 일부가 선택된 경우
          this.updateRowState(parentKey, initialState, true); // 부모를 선택으로 업데이트
        } else if (noneSelected) {
          // 모든 자식 노드가 선택 해제된 경우
          this.updateRowState(parentKey, initialState, false); // 부모를 해제로 업데이트
        } else {
          // 해당 되지 않는 경우
          delete this.updateData[parentKey];
        }

        // 재귀적으로 부모 노드의 부모도 처리
        this.handleParentUpdate(parentKey, treeListInstance);
      },
      /**
       * @description 부모 노드의 모든 자식 키를 찾는 함수
       * @param parentKey {String|Number} 부모 노드의 키
       * @param dataSource {Array} 현재 사용 중인 트리 데이터
       * @return {Array} 자식 노드의 키 배열
       */
      findChildKeys(parentKey, dataSource) {
        return dataSource.filter(item => item.parentId === parentKey).map(item => item.id);
      },
      /**
       * @description 주어진 키의 부모 노드를 검색
       * @param childKey {Number|String} 자식 노드 키
       * @param dataSource {Array} 트리 데이터 소스
       * @returns {Number|String|null} 부모 키 (없을 경우 null)
       */
      findParentKey(childKey, dataSource) {
        const childNode = dataSource.find(item => item.id === childKey);
        return childNode ? childNode.parentId : null;
      },
      /**
       * @description 메뉴권한 트리 선택 변경 이벤트 핸들러
       * @param e {Object} 이벤트 데이터
       */
      async handleSelectionChanged(e) {
        if (this.isInInitialization) {
          // 초기화 작업 중이면 무시
          return;
        }

        if (e.currentSelectedRowKeys?.length > 0) {
          // "선택된 항목"이 있는 경우
          await this.setSelection(e.currentSelectedRowKeys, true);
        }

        if (e.currentDeselectedRowKeys?.length > 0) {
          // "선택 해제된 항목"이 있는 경우
          await this.setSelection(e.currentDeselectedRowKeys, false);
        }
      },
      /**
       * @description 현재 권한과 관련된 모든 권한 id들을 반환
       * @param authId
       * @return {Promise<any[]>}
       */
      async getAllRelatedAuthIds(authId) {
        const relatedIds = new Set();
        const queue = [authId]; // 초기 큐에 authId를 추가

        while (queue.length > 0) {
          const currentId = queue.shift(); // 큐에서 현재 id를 꺼냄

          if (relatedIds.has(currentId)) {
            continue; // 이미 처리된 id는 무시
          }

          relatedIds.add(currentId); // 관련된 id Set에 현재 id 추가

          // 현재 id와 관련된 자식 id들을 찾음
          const children = this.getTreeDataSource().filter(node => node.parentId === currentId);

          // 자식 id들을 큐에 추가
          for (const child of children) {
            queue.push(child.id);
          }
        }

        return Array.from(relatedIds); // 중복 배제 후 관계된 id들을 배열로 변환하여 반환
      },
      /**
       * @description 트리 데이터 조회
       * @return {*}
       */
      getTreeDataSource() {
        return this.$refs.menuAuthTreeList.getInstance.option('dataSource');
      },
      /**
       * @description 트리 데이터 설정
       * @param data
       */
      setTreeDataSource(data) {
        this.$refs.menuAuthTreeList.getInstance.option('dataSource', data);
      },
      /**
       * @description 인스턴스 반환
       * @return {*}
       */
      getInstance() {
        return this.$refs.menuAuthTreeList.getInstance;
      },
      /**
       * @description 트리 리스트 초기화
       * @param authId 권한 ID
       * @return {Promise<void>}
       */
      async setAuthMenuTreeList(authId) {
        this.initData(); // 데이터 초기화
        this.selectedAuthId = authId; // 선택된 권한 ID 설정

        const res = await this.getMenuList();

        if (isSuccess(res)) {
          await this.setAuthMainPage(authId); // 메인페이지 권한 설정
          await this.$refs.menuAuthTreeList.getInstance.option('dataSource', res.data.data);
          // DataSource 캐싱 처리
          this.treeDataSourceMap = new Map(res.data.data.map(item => [item.id, JSON.parse(JSON.stringify(item))])); // 참조하지 않도록 깊은 복사
        }

        // onContentReady 이벤트에서 선택 로직을 실행
        this.$nextTick(() => {
          const treeListInstance = this.$refs.menuAuthTreeList.getInstance;
          if (treeListInstance) {
            treeListInstance.option('onContentReady', async () => {
              this.isInInitialization = true; // 초기화 작업 진행 중
              // 데이터 로드 완료 후 진행
              await this.setTreeDataSelection(); // 데이터 row 선택
              this.isInInitialization = false; // 초기화 완료
            });
          }
        });
      },
      /**
       * @description 메인페이지 설정
       * @param authId {String} 권한 ID
       * @return {Promise<void>}
       */
      async setAuthMainPage(authId) {
        const payload = {
          actionName: 'AUTH_MENU_MAIN_PAGE',
          data: {
            authId: authId,
            targetId: 'ALL',
          },
          useErrorPopup: false,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.selectedMainPageId = res.data.data[0].menuId;
        }
      },
      /**
       * @description 메뉴 리스트 조회
       * @return {Promise<void>}
       */
      async getMenuList() {
        const payload = {
          actionName: 'MENU_LIST_ALL',
          data: {
            authId: this.selectedAuthId,
            viewFl: this.$_enums.common.stringViewFlag.YES.value,
            authPermFl: this.$_enums.common.stringUsedFlag.YES.value,
            isCompact: true, // 간략 조회 여부
          },
          useErrorPopup: true,
        };
        return await this.CALL_API(payload);
      },
      /**
       * @description 트리 데이터 선택
       * @return {Promise<void>}
       */
      async setTreeDataSelection() {
        this.$refs.menuAuthTreeList.getInstance.clearSelection();
        const dataSource = await this.getTreeDataSource();

        // 부모노드를 선택하면 자식노드도 같이 선택 되므로 자식노드만 선택 하도록 처리
        const selectChildrenNodeIds = dataSource
          .filter(d => {
            const node = this.$refs.menuAuthTreeList.getInstance.getNodeByKey(d.id);

            if (!node || !node.data) {
              return false;
            }
            // 자식 노드가 없고 사용 여부가 'Y'인 경우
            return !node.hasChildren && node.data.authUseFl === this.$_enums.common.stringUsedFlag.YES.value;
          })
          .map(d => d.id);
        await this.$refs.menuAuthTreeList.getInstance.selectRows(selectChildrenNodeIds, false);
      },
      /**
       * @description 저장 이벤트
       * @param e
       */
      async handleSave(e) {
        // 메뉴 권한 저장(성공 후 메인페이지 권한 설정)
        if (await this.updateAuthMenuUseFlag()) {
          await this.setAuthMenuTreeList(this.selectedAuthId); // 메뉴권한 다시 조회
        }
      },
      /**
       * @description 권한 메뉴 사용여부 수정
       * @return boolean 성공 여부
       */
      async updateAuthMenuUseFlag() {
        if (Object.keys(this.updateData).length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return false;
        }

        const payload = {
          actionName: 'AUTH_MENU_USE_FL_UPDATE',
          data: Object.entries(this.updateData).map(([menuId, { selected, isParent }]) => {
            return {
              menuId,
              useFl: selected ? this.$_enums.common.stringUsedFlag.YES.value : this.$_enums.common.stringUsedFlag.NO.value,
              isParent,
            };
          }),
          path: this.selectedAuthId,
          loading: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          return true;
        }
        return false;
      },
      /**
       * @description 메인페이지 권한 설정
       * @return {Promise<boolean>}
       */
      async updateAuthMainPage() {
        const payload = {
          actionName: 'AUTH_MENU_MAIN_PAGE_SAVE',
          data: {
            authId: this.selectedAuthId,
            menuId: this.selectedMainPageId,
          },
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          return true;
        }
        return false;
      },
      /**
       * @description 메인페이지 권한 삭제
       * @return {Promise<boolean>}
       */
      async deleteAuthMainPage() {
        const payload = {
          actionName: 'AUTH_MENU_MAIN_PAGE_DELETE',
          data: {
            authId: this.selectedAuthId,
            targetId: 'ALL',
          },
          useErrorPopup: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
          return true;
        }
        return false;
      },
      /**
       * DataSource 업데이트, mainPageFl 플래그 변경
       *
       * @param id
       * @param flag
       */
      setDataSourceByMainPageFl(id, flag) {
        // 선택 상태 업데이트(DataSource 업데이트)
        const targetRow = this.getTreeDataSource().find(row => row.id === id);

        if (targetRow) {
          // 데이터 수정(DataSource 업데이트)
          targetRow.mainPageFl = flag;
        }
      },
      /**
       * @description 컴포넌트 데이터 초기화
       */
      initData() {
        this.selectedAuthId = null;
        this.selectedMainPageId = null;
        this.treeList.dataSource = [];
        this.updateData = {};
        this.treeDataSourceMap = null;
        this.isInInitialization = false;
      },
    },
    mounted() {
      this.initData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .bigtabBox.on {
    height: 100%;
  }

  ::v-deep .fr_wrap {
    height: 100%;
    margin-top: 20px !important;
    margin-left: 10px;
  }
</style>
