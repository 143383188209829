import MenuUsed from '@/pages/esp/summary/menu-used.vue';

export default function SummaryRoutes(commonComponents) {
  return [
    {
      path: '/esp/summary/menu-used',
      name: 'MenuUsed',
      components: { default: MenuUsed, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
