import WorkerList from '@/pages/euc/phone/worker/list.vue';
import WorkerRequest from '@/pages/euc/phone/worker/request.vue';

export default function WorkerRoutes(commonComponents) {
    return [
        /**
         * @description 전화 신청에 대한 작업 목록 페이지
         * */
        {
            path: '/euc/phone/worker/list',
            name: 'WorkerList',
            components: { default: WorkerList, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 전화 신청에 대한 작업 상세 페이지
             * */
            path: '/euc/phone/worker/request',
            name: 'WorkerRequest',
            components: { default: WorkerRequest, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ]
}