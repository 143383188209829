<!--
  PACKAGE_NAME : src\components\report\search-box.vue
  FILE_NAME : search-box.vue
  AUTHOR : kwmoon
  DATE : 2024-06-19
  DESCRIPTION : 위자드 보고서 상단 검색 박스
-->
<template>
  <div class="pt-3">
    <!--   검색 컴포넌트 컨테이너(모달 제외)  -->
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <!-- START: 기간 / 대상 보고서 유형 -->
      <div>
        <div class="mb-3 flex justify-between">
          <div>
            <!-- 기간설정 -->
            <button
              ref="dateLayerBtn"
              :class="styles.offLayerBtn"
              style="height: 29px"
              @click="toggleHiddenSection($event, 'dateHiddenLayer')"
            >
              기간 <span :class="styles.offUpDown"></span>
            </button>
            <div class="ui-datepicker-item mx-2" @click="toggleHiddenSection($event, 'dateHiddenLayer', 'dateLayerBtn')">
              <input type="text" class="cursor-pointer calr dx-outline" :value="selectedDateMessage" style="width: 192px" readonly />
            </div>
            <span class="mr-10 cursor-pointer" @click="toggleHiddenSection($event, 'dateHiddenLayer', 'dateLayerBtn')">
              <input
                type="text"
                class="cursor-pointer dx-outline"
                :value="selectedDaysAndTimeMessage"
                style="width: 255px; margin-right: 0 !important"
                readonly
              />
            </span>
            <!-- 대상설정 -->
            <span v-if="visibleTargetButtons.length > 0">
              <button
                v-for="button in visibleTargetButtons"
                :class="styles.offLayerBtn"
                style="height: 30px; margin-right: 0.5rem"
                :ref="`${button.type}LayerBtn`"
                :key="button.type"
                :data-type="button.type"
                @click="toggleHiddenSection($event, `${button.type}HiddenLayer`)"
              >
                {{ button.name }} <span :class="styles.offUpDown"></span>
              </button>

              <input id="selectedSizeMessage" type="text" class="w-[150px] dx-outline mr-0" :value="selectedTargetSizeMessage" readonly />
            </span>

            <!-- 보고서유형 -->
            <span v-show="visibleReportTypeList.length > 0">
              <span class="mx-5">유형</span>
              <span class="top2">
                <DxSelectBox
                  placeholder="보고서 유형"
                  :data-source="visibleReportTypeList"
                  display-expr="label"
                  value-expr="value"
                  v-model="formData.report_type"
                  :styling-mode="styles.dxOutlined"
                  @value-changed="handleChangeReportType"
                  width="100px"
                  :height="30"
                >
                </DxSelectBox>
              </span>
            </span>
          </div>
          <div v-if="isWizardReport">
            <button type="button" class="btn_M box-btn-search h-[29px] min-h-[29px] mr-1" @click="onSearchReport('search')">조회</button>
            <button
              v-show="status.showWindowBtn"
              type="button"
              class="btn_XXS white mr-1"
              style="height: 29px"
              @click="onSearchReport('search', true)"
            >
              <img src="@/assets/images/report/popup_icon.png" /> 새창 조회
            </button>
            <button type="button" class="btn_XXS white px-0.5" style="height: 29px" @click="handleShowSearchCondition($event)">
              <img src="@/assets/images/report/search_manage_icon.png" />
            </button>
            <!-- START: 검색조건 관리 -->
            <popoverSearchCondition
              ref="searchCondition"
              :info="reportInfo"
              :openSaveModal="() => (modal.condition.isShow = true)"
              :onClickSearchCondition="handleSetSearchCondition"
              v-click-outside="e => handleShowSearchCondition(e, false)"
            />
            <!-- 1, 7 authId 최상위 ID 하드코딩시킴  -->
            <button v-show="isXmlEditAuthUser" type="button" class="btn_XXS white ml-1 p-0" style="height: 29px" @click="toggleXmlModal">
              <img src="@/assets/images/report/edit_xml_icon.png" />
            </button>
          </div>
        </div>

        <!-- 캘린더 클릭 시 레이어 출력 -->
        <div ref="dateHiddenLayer" class="mt-3 border-t border-gray-300 hidden">
          <div class="flex flex-wrap justify-between py-2 space-y-6 md:space-y-4 lg:space-y-0">
            <!-- 기간선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">기간 선택</h2>
              <DateBox ref="dateBoxRef" :disabled="false" :changedValue="handleChangeDate" />
            </div>
            <!-- 요일선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">요일 선택</h2>
              <DaysBox ref="daysBoxRef" :disabled="status.days" :changedValue="handleChangedDaysOrTime" />
            </div>
            <!-- 시간선택 -->
            <div class="w-full lg:w-1/3 p-2">
              <h2 class="text-xl font-medium mb-2">시간 선택</h2>
              <TimeBox ref="timeBoxRef" :disabled="status.times" :changedValue="handleChangedDaysOrTime" />
            </div>
          </div>
        </div>

        <!-- END: 기간 / 대상 / 보고서 유형 -->
        <!-- START: 대상[CHECK] 레이어 -->
        <div ref="checkHiddenLayer" class="mt-3 layer-box hidden">
          <div v-if="targetRadioList" v-show="!isMultiSelectable" class="border-t border-gray-300 p-3 flex justify-between">
            <div class="flex items-center space-x-2 w-1/2">
              <!-- 조회기준 텍스트와 SelectBox -->
              <div class="text-base font-medium mx-2">조회기준</div>
              <DxSelectBox
                :items="targets.selectBoxItems"
                placeholder="선택"
                display-expr="label"
                value-expr="index"
                styling-mode="outlined"
                :width="200"
                :value="targets.selectedBoxIndex"
                @selection-changed="handleChangeTargetSelectBox"
              />
              <!-- 조회그룹 (RadioGroup) -->
              <div class="pl-5">
                <DxRadioGroup
                  class="check-type col"
                  ref="targetGroupRadio"
                  :dataSource="targetRadioList"
                  :value="targets.selectedDepth"
                  display-expr="name"
                  value-expr="depth"
                  layout="horizontal"
                  @value-changed="handleChangeTargetRadioValue"
                />
              </div>
            </div>
          </div>
          <!-- 조회그룹 트리리스트 박스 -->
          <div v-if="targetRadioList" class="border-t border-gray-200 h-[450px] flex flex-wrap justify-start gap-2">
            <DepthTreeBox
              class="w-full sm:w-1/2 md:w-1/3 lg:w-1/5"
              v-for="(v, i) in targetRadioList"
              ref="depthTreeBox"
              :title="v.name"
              :key="v.name"
              :is="DepthTreeBox"
              :dataName="`dept${i + 1}`"
              :nextDataName="v.hasNext ? `dept${i + 2}` : undefined"
              :treeDatas="targets[`v_dept${i + 1}`]"
              :disabled="isMultiSelectable ? false : targets[`disabled${i + 1}`]"
              :pageLoaded="pageLoaded"
              @clearCheckedItems="clearCheckedItems(i + 1)"
              @setCheckedItems="handleCheckedItems"
            />
          </div>
        </div>
        <!-- END: 대상[DEPTH CHECK] 레이어 -->

        <!-- options 태그에 설정된 컴포넌트 -->
        <SearchEtcOption ref="etcOptionsRef" :options="etcOptions" />
      </div>
    </div>

    <!-- POPUP(ON, OFF) -->
    <div id="app1">
      <WindowPopup v-model="status.windowPopup" :urls="this.newPopupUrl" :datas="formData" :params="newWindow.params" />
    </div>

    <!-- START: XML 히스토리 및 수정 팝업  -->
    <ESPModalPopup
      :isOpen="modal.isOpenXmlModal"
      :option="modal.xmlModalOption"
      @saveModal="saveXmlModal"
      @closeModal="toggleXmlModal(false)"
    >
      <template #content>
        <XmlEditor
          ref="xmlEditor"
          :options="modal.editorOption"
          :history="modal.xmlHistories"
          :reportId="reportInfo ? reportInfo.reportId : 0"
        />
      </template>
    </ESPModalPopup>
    <!-- END: XML 히스토리 및 수정 팝업  -->

    <!-- START: 검색조건관리 저장 팝업 -->
    <ESPModalPopup
      :option="{
        title: '검색조건 추가',
        width: '500',
        height: '290',
      }"
      :isOpen="modal.condition.isShow"
      @closeModal="() => (modal.condition.isShow = false)"
      @saveModal="saveSearchCondition"
    >
      <template #content>
        <ModalContent ref="searchConditionModalContent" />
      </template>
    </ESPModalPopup>
  </div>
</template>

<script>
  //Dev-Extreme
  import { DxButton } from 'devextreme-vue/button';
  import { DxTagBox } from 'devextreme-vue/tag-box';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxColumn, DxDataGrid, DxEditing, DxPaging, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
  // 조회그룹 컴포넌트
  import DepthTreeBox from './depth-tree-box.vue';
  import ModalUserSearch from './modal-user-search.vue';
  // 제작 컴포넌트
  import WindowPopup from './WindowPopup.vue';
  import ESPModalPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-update';
  //Util
  import moment from 'moment';
  import { convertDateFormat, getPastFromToday, getResData, isEmpty, isSuccess, isTrue } from '@/utils/common-lib';
  import { DxNumericRule } from 'devextreme-vue/form';
  import { DxNumberBox } from 'devextreme-vue/number-box';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import PopoverSearchCondition from '@/components/report/searchbox/condition/popover-search-condition.vue';
  import DateBox from '@/components/report/date-box.vue';
  import TimeBox from '@/components/report/time-box.vue';
  import DaysBox from '@/components/report/days-box.vue';
  import SearchEtcOption from '@/components/report/search-etc-option.vue';
  import ModalContent from '@/components/report/searchbox/condition/modal-add-content.vue';

  //Data
  let vm = null;
  const DEPTH_NUMBERS = [1, 2, 3, 4, 5];
  export default {
    components: {
      ModalContent,
      SearchEtcOption,
      DaysBox,
      TimeBox,
      DateBox,
      PopoverSearchCondition,
      DxRadioGroup,
      DxNumberBox,
      DxNumericRule,
      DxCheckBox,
      DxButton,
      DxDateBox,
      DxSelectBox,
      DepthTreeBox,
      WindowPopup,
      DxTextBox,
      DxDropDownBox,
      DxDataGrid,
      DxColumn,
      DxSelection,
      DxEditing,
      DxPaging,
      DxScrolling,
      DxTagBox,
      DxPopover,
      ModalUserSearch,
      ESPModalPopup,
      XmlEditor,
    },
    watch: {
      pageLoaded() {
        if (this.pageLoaded === false) return;
        this.initLayoutByReportXmlData();
        this.initMounted();
      },
    },
    props: {
      /** 보고서 정보 */
      reportInfo: {
        type: Object,
        required: true,
        default: () => ({}),
      },
      newPopupUrl: String,
      pageLoaded: Boolean,
      getChkDepthTarget: Function,
      getSelectedReportFilterKeys: {
        type: Function,
        default: () => ['1', '2', '4'],
      },
      isWizardReport: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        DepthTreeBox: 'DepthTreeBox',
        /** ESP-r 보고서 최종 파라미터 */
        formData: {
          actionName: 'REPORT_RESULT_LIST',
          endDt: getPastFromToday(0, 'days'),
          startDt: getPastFromToday(0, 'days'),
          days: [1, 2, 3, 4, 5, 6, 7],
          obj: [], // customList, obj${n} 은 동적으로 생길 수 있음
          objKey: null, // 쿼리 실행 시 사용할 조건절 Key (ex. H.AGTID)
          objType: null, // 조회그룹 Key (ex. IPCC_IBG)
          report_type: 'daily',
          // 경로
          solution: '',
          subPath: '',
        },
        /** XML <options> 태그 내 정의 값 */
        etcOptions: [],
        status: { days: false, times: false, showWindowBtn: true, windowPopup: false, openConditionModal: false },
        /** 해당 보고서 옵션 */
        options: { useStorageData: true },
        /** 조회대상 데이터 (5개까지 가능) */
        targets: {
          items: [],
          selectedButtonType: 'check', // check || input
          selectedBoxIndex: 0, // selectBoxItems 에서 선택된 아이템
          selectBoxItems: [], // <targets> 태그 내 <target> 태그 리스트 (index, label, key)
          radioItems: [], // 라디오 옵션에 사용될 아이템
          selectedDepth: 1, // 선택한 라디오 depth 값
          targetMap: {}, // selectItems 에서 선택된 데이터 (조회대상 리스트), ex. { key: [data], key2: [data2] }
          optionMap: {}, // target <option> 태그 속성 값이 들어간 Map 데이터
          v_dept1: [],
          v_dept2: [],
          v_dept3: [],
          v_dept4: [],
          v_dept5: [],
          chk_dept1: [],
          chk_dept2: [],
          chk_dept3: [],
          chk_dept4: [],
          chk_dept5: [],
          disabled1: false,
          disabled2: false,
          disabled3: false,
          disabled4: false,
          disabled5: false,
          // 추가 옵션
          etc: {
            check: [], // 체크박스
            input: [], // 텍스트박스
          },
        },
        /** 보고서 유형 */
        reportType: {
          items: this.$_enums.report.reportType.values.map(v => ({ ...v, visible: true })),
        },
        /** 스타일 */
        styles: {
          dxOutlined: 'outlined',
          onLayerBtn: 'btn_XXS blue2 on',
          onUpDown: 'mdi mdi-chevron-up icon-white',
          offLayerBtn: 'btn_XXS white',
          offUpDown: 'mdi mdi-chevron-down',
        },
        /** 모달 */
        modal: {
          isOpenXmlModal: false,
          xmlModalOption: {
            title: 'XML 관리',
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            type: 'REPORT',
            reportNm: '',
            useRight: true,
            rsWidth: '30%', // 우측 섹션 넓이
          },
          xmlHistories: [],
          condition: {
            isShow: false,
          },
        },
        /** 팝업 데이터 */
        newWindow: { params: {} },
        /** 출력 및 알림 메시지 */
        selectedDaysAndTimeMessage: '', // 기간 출력 ex. 2025/01/01 ~ 2025/01/01
        ERR_MSG_DATE: '선택한 날짜를 확인해주시기 바랍니다.',
        ERR_MSG_DAYS: '선택한 요일을 확인해주시기 바랍니다.',
        ERR_MSG_TIME: '선택한 시간을 확인해주시기 바랍니다.',
        ERR_MSG_TARGET: '대상을 하나 이상 선택해주시기 바랍니다.',
        ERR_MSG_CUSTOM_TARGET: '대상을 추가해주시기 바랍니다.',
        ERR_MSG_MAX_SELECTED_TARGET: '대상은 최대 {n}개까지 선택 가능합니다.',
      };
    },
    computed: {
      /** 조회대상 선택 섹션 */
      targetRadioList() {
        return this.targets.radioItems;
      },
      currentTarget() {
        return this.targets.items.find(item => item.key === this.currentTargetKey);
      },
      /** 선택중인 조회대상 라디오 버튼 뎁스 */
      currentTargetDepth() {
        return this.targets.selectedDepth;
      },
      /** 선택중인 조회대상 Key */
      currentTargetKey() {
        const { selectBoxItems, selectedBoxIndex } = this.targets;
        return selectBoxItems[selectedBoxIndex].value;
      },
      /** 선택중인 죄회대상 Map 데이터 */
      currentTargetMap() {
        const { targetMap } = this.targets;
        return targetMap[this.currentTargetKey];
      },
      /** 조회대상 버튼 (check | input) */
      visibleTargetButtons() {
        const buttons = ['check', 'input'];
        const types = this.targets.items.map(item => item.type);
        const visibleButtons = buttons.filter(str => types.includes(str));

        return visibleButtons.map(button => {
          return {
            type: button,
            name: button === 'check' ? '조회대상' : '직접입력',
          };
        });
      },
      /** 보고서 유형 visible = true 값만 반환 */
      visibleReportTypeList() {
        return this.reportType.items.filter(item => item.visible);
      },
      /** 기간 날짜 출력 */
      selectedDateMessage() {
        const { startDt, endDt } = this.formData;
        const fmStartDt = convertDateFormat(startDt, 'YYYY/MM/DD');
        const fmEndDt = convertDateFormat(endDt, 'YYYY/MM/DD');
        return `${fmStartDt} ~ ${fmEndDt}`;
      },
      /** 선택한 조회 대상 개수 출력 */
      selectedTargetSizeMessage() {
        this.targets.selectedBoxIndex; // 조회대상 셀렉트 박스 변경 감지를 위한 변수
        const depth = this.targets.selectedDepth;
        const selectedBtnType = this.targets.selectedButtonType;
        return this.getSelectedTargetSizeMessage(selectedBtnType, depth);
      },
      /** 트리구조 계층 구조인지, 또는 동시 설정 가능 구조인지 여부 */
      isMultiSelectable() {
        // TODO: 변경된 방식에서 처리 방법에 대해 고민 후 수정 예정
        return false;
      },
      /** XML 편집 권한은 시스템 계정만 가능하므로 시스템 설정에서 계정 추가 가능 */
      isXmlEditAuthUser() {
        const systemCodeList = this.$store.getters.getSystemCodeList;
        const systemValue = systemCodeList.find(v => v.configKey === 'report_xml_edit_login_id');
        if (isEmpty(systemValue)) {
          return false;
        }

        const loginIds = systemValue.configValue.split(',').map(v => v.trim());
        return loginIds.includes(this.$store.getters.getLoginId);
      },
    },
    methods: {
      /** 선택한 조회대상 관련 출력되는 메시지 조회 */
      getSelectedTargetSizeMessage(type, depth) {
        const selectedSize = this.getSelectedTarget(depth)?.length || 0;
        let name = '개별'; // input 기초값
        if (!!this.targetRadioList && type === 'check') {
          name = this.targetRadioList.find(v => v.depth === depth)?.name;
        }

        if (name === undefined) {
          return '-';
        }
        return `${name}: ${selectedSize.toLocaleString('ko-KR')} 개`;
      },
      /** 아이템, 속성을 이용해 속성 값 배열로 반환 */
      getValuesFromKey(items, key) {
        return items.map(item => item[key]);
      },
      /**
       * 트리박스 아이템 선택했을 때 발생하는 이벤트
       * 1. 네비게이션 만들기
       * 2. 선택한 자식 아이템 찾기
       * 3. 페이지데이터 내 lastDepthApi 존재할 시 실행
       * @param name
       * @param nextName
       * @param items
       */
      handleCheckedItems(name, nextName, items) {
        const values = this.getValuesFromKey(items, 'VALUE');
        const nodeIds = this.getValuesFromKey(items, 'NODE_ID') || values; // 기존 XML 에러 방어

        this.targets[`chk_${name}`] = values;
        if (nextName !== undefined) {
          //Next Depth 존재 시 Next Tree 셋팅
          const navigations = items.map(v => ({
            ...v,
            isNav: true,
            PARENT_ID: -1, // 해당 아이템 트리박스 최상단으로 놓기
            TEXT: v.NAMETREE ? v.NAMETREE.replace(/\|/g, '>') : v.TEXT,
          }));
          const nextItems = this.currentTargetMap[nextName].filter(v => nodeIds.find(v2 => v2 === v.PARENT_ID));
          this.targets[`v_${nextName}`] = [...navigations, ...nextItems];
        }
      },
      /** 1~5 순서로 트리박스 초기화, depth 까지 */
      clearCheckedItems(depth = 1) {
        while (DEPTH_NUMBERS.includes(depth)) {
          this.targets[`chk_dept${depth}`].length = 0;
          const depthTreeBoxes = this.$refs.depthTreeBox;
          if (!isEmpty(depthTreeBoxes) && !isEmpty(depthTreeBoxes[depth - 1])) {
            depthTreeBoxes[depth - 1].setSelectedKeys([]);
          }
          depth++;
        }
      },
      /** 설정된 보고서 파라미터 조회 by Key */
      getReportParam(key) {
        return this.formData[key];
      },
      /** 보고서 파라미터 설정 Key, Value */
      setReportParam(key, value) {
        this.formData[key] = value;
      },
      /** 조회 대상 가지고 오는 함수 */
      getSelectedTarget(depth) {
        if ('check' === this.targets.selectedButtonType) {
          return this.targets[`chk_dept${depth}`];
        }

        // 선택한 버튼이 input 타입인 경우
        return this.target.customList;
      },
      validateMessageByParams() {
        // 기간
        const startDt = this.getReportParam('startDt');
        const endDt = this.getReportParam('endDt');
        const isDateError = startDt > endDt;
        if (isDateError) return this.ERR_MSG_DATE;
        // 요일
        const days = this.getReportParam('days');
        if (days.length === 0) return this.ERR_MSG_DAYS;
        // 시간
        const startTime = this.getReportParam('startTime');
        const endTime = this.getReportParam('endTime');
        const isTimeError = startTime > endTime;
        if (isTimeError) return this.ERR_MSG_TIME;

        // 대상선택
        if (this.visibleTargetButtons.length > 0) {
          if ('input' === this.targets.selectedButtonType && this.getSelectedTarget('input').length === 0) {
            return this.ERR_MSG_CUSTOM_TARGET;
          } else if (!this.isMultiSelectable) {
            const depth = this.currentTargetDepth;
            if (this.getSelectedTarget(depth).length === 0) {
              return this.ERR_MSG_TARGET;
            }
          }
        }

        return '';
      },
      /** 설정한 조회대상 최대 개수보다 많은 지 체크 */
      checkMaxTargetData() {
        if (isEmpty(this.targets.selectBoxItems)) {
          return;
        }

        const { maxSelectedCnt } = this.currentTarget;
        const selectedTargetCnt = this.getReportParam('obj').length;
        if (maxSelectedCnt !== undefined && maxSelectedCnt < selectedTargetCnt) {
          this.$_Msg(this.ERR_MSG_MAX_SELECTED_TARGET.replace('{n}', maxSelectedCnt));
          throw new Error('설정한 최대 조회대상 개수보다 더 많이 선택되었습니다.');
        }
      },
      /** 보고서 조회 : 조회대상 초기화 */
      initTargetSearchParam() {
        this.setReportParam('obj', []);
        this.setReportParam('customObj', []);
        DEPTH_NUMBERS.forEach(v => this.setReportParam(`obj${v}`, []));
      },
      /** 보고서 조회 : 날짜 설정 */
      setDateSearchParam() {
        this.setReportParam('startDt', this.getReportParam('startDt'));
        this.setReportParam('endDt', this.getReportParam('endDt'));
      },
      /** 보고서 조회 : 보고서 경로 */
      setXMLPathSearchParam() {
        const { solution, subPath } = this.reportInfo;
        this.setReportParam('solution', solution);
        this.setReportParam('subPath', subPath);
      },
      /**
       * 보고서 조회 전 최종적으로 모든 파라미터 업데이트
       */
      updateAllReportParam(workType) {
        // 보고서 작업 유형
        this.setReportParam('workType', workType);

        // 보고서 경로 값 설정
        this.setXMLPathSearchParam();

        // 보고서 조회대상 초기화
        this.initTargetSearchParam();
        this.setReportParam('objHeader', this.getTargetColumnNames(this.targets.selectedButtonType));

        // 날짜 설정
        this.setDateSearchParam();

        const depth = this.currentTargetDepth;
        //Set obj 1~5
        this.setReportParam('obj', this.getSelectedTarget(depth));
        if (this.isMultiSelectable) {
          DEPTH_NUMBERS.forEach(v => this.setReportParam(`obj${v}`, this.getSelectedTarget(v)));
        }

        this.checkMaxTargetData();

        if (this.visibleTargetButtons.length > 0) {
          this.setReportParam('depth', depth);
          this.setReportParam('objKey', this.getSelectedTargetRadioValue(depth));
          this.setReportParam('objType', this.currentTargetKey);
          this.setReportParam('loginId', this.$store.getters.getLoginId);
        }

        // 옵션 값 설정
        const optionFormData = this.$refs.etcOptionsRef.getOptionFormData();
        Object.keys(optionFormData).forEach(key => this.setReportParam(key, optionFormData[key]));

        // T 테이블 위한 날짜 값 추가
        this.updateCurrentDateParam();
      },
      /** ERS - T 테이블 체크를 위한 파라미터 추가 */
      updateCurrentDateParam() {
        const endYM = this.getReportParam('endDt').substr(0, 6);
        const hasCurrentYM = moment().format('YYYYMM') === endYM ? 'Y' : 'N';
        this.setReportParam('endYM', endYM);
        this.setReportParam('hasCurrentYM', hasCurrentYM);
      },
      /**
       * @description 조회대상(체크, 개별)에 대한 헤더 정보 조회
       * @param type check | input
       * */
      getTargetColumnNames(type) {
        try {
          let options = this.targetRadioList;
          if ('input' === type) {
            options = this.PAGE_DATA.inputBtnInfo.options;
          }

          return options.map(v => v.name);
        } catch (err) {
          return [];
        }
      },
      /**
       * 보고서 조회 기능
       * @param {String} workType 작업 타입
       * @param {Boolean} isNewWindow 새창 여부
       */
      onSearchReport(workType, isNewWindow = false) {
        //Validation
        const errMessage = this.validateMessageByParams();
        if (errMessage.trim()) return this.$_Msg(errMessage);

        //Set Params
        this.updateAllReportParam(workType);

        //조회 [popOn.checked: 새창 / else: 현재 브라우저]
        if (this.$_enums.report.workType.SEARCH.equals(workType) && isNewWindow) {
          this.newWindow.params = this.getReportSearchParams();
          this.status.windowPopup = isNewWindow;
        } else {
          this.toggleHiddenSection(null, '');
          this.$emit('onSearchClick', this.formData, this.getHiddenColumns());
        }
      },
      /** 보고서 결과를 얻기 위한 파라미터 조회 */
      getFinalReportParam() {
        return this.formData;
      },
      /**
       * 보고서 조회 시 파라미터로 보낼 depth 별 숨겨야할 컬럼을 반환
       * status.depth가 아닌 searchParams.depth를 기준으로 반환
       * TODO: invisible 컬럼
       */
      getHiddenColumns() {
        const depth = this.currentTargetDepth;
        const selectedRadioData = this.targetRadioList.find(v => v.depth === depth);
        if (isEmpty(selectedRadioData)) {
          return [];
        }

        return selectedRadioData.invisible;
      },
      /***** [START]검색조건관리 *****/
      openConditionModal(openOrClose) {
        this.status.openConditionModal = openOrClose;
      },
      /**
       * 현재 셋팅 된 파라미터들 값을 search / target / status 로 분리해서 리턴
       * 검색조건관리 및 새로운창 파라미터 셋팅 시 사용
       * 해당 함수를 사용해야 컴포넌트에 값 셋팅이 가능(트리뎁스 포함)
       */
      getReportSearchParams() {
        const searchParamKeys = ['startDt', 'endDt', 'days', 'holi', 'startTime', 'endTime', 'report_type'];
        const searchParamData = searchParamKeys.reduce((acc, key) => {
          acc[key] = this.formData[key];
          return acc;
        }, {});

        // 커스텁(단일) 또는 뎁스 별 선택한 데이터
        let targetData = null;
        if ('input' === this.targets.selectedButtonType) {
          targetData = { customList: this.target.customList };
        } else {
          targetData = DEPTH_NUMBERS.reduce((acc, number) => {
            const key = `chk_dept${number}`;
            const checkedTargetDataList = this.targets[key];
            if (checkedTargetDataList.length > 0) {
              acc[key] = checkedTargetDataList;
            }
            return acc;
          }, {});
          targetData.objType = this.currentTargetKey;
          targetData.depth = this.currentTargetDepth;
          targetData.selectedButtonType = this.targets.selectedButtonType;
        }

        return {
          search: searchParamData,
          target: targetData,
          filter: this.getSelectedReportFilterKeys(),
        };
      },
      /** description: 검색조건관리 리스트 클릭 시 화면 및 파라미터 셋팅 */
      changeSectionBySearch(search) {
        // 요일
        const { days, holi, report_type } = search;
        this.$refs.daysBoxRef.updateFormData(days, holi);
        // 시간
        const { startTime, endTime } = search;
        this.$refs.timeBoxRef.updateFormData(startTime, endTime);

        // 보고서 유형
        this.initReportType(report_type);
      },
      /** @description 조회그룹 변경 기능 */
      changeSectionByTarget(target) {
        // 트리 박스 내 선택한 오브젝트 리스트 셋팅
        if (isEmpty(target)) {
          return;
        }

        const { selectedButtonType, objType, depth } = target;
        this.targets.selectedButtonType = selectedButtonType; // 조회대상 버튼 (check or input) 선택

        // 개별 아이템 선택 시
        if ('input' === selectedButtonType && Object.hasOwn(target, 'customList')) {
          vm.target.customList = target.customList;
          return; // 종료
        }

        // 체크 아이템 선택 시
        const selectedBoxItem = this.targets.selectBoxItems.find(item => item.value === objType);
        if (isEmpty(selectedBoxItem)) {
          return; // 종료 (XML 편집 했을 시 기존 데이터가 없을 수 있음)
        }

        // 조회기준 라디어버튼이 있고, depth 가 여러 개 있는 트리리스트 컴포넌트인 경우
        this.targets.selectedBoxIndex = selectedBoxItem.index;

        const selectedRadio = this.targetRadioList.find(v => v.depth === depth);
        if (selectedRadio) {
          this.$refs.targetGroupRadio.instance.option('value', depth);
        }

        // UI 컴포넌트(트리리스트) 변경 전에 데이테를 셋팅하여 기대하는 동작과 달리 동작하는데,
        // nextTick 처리 불가하여 requestAnimationFrame 사용
        requestAnimationFrame(() => {
          DEPTH_NUMBERS.forEach(depth => {
            const depthTreeBox = vm.$refs.depthTreeBox?.at(depth - 1);
            const values = target[`chk_dept${depth}`];
            if (!isEmpty(depthTreeBox) && !isEmpty(values)) {
              depthTreeBox.setSelectedKeys(this.convertNodeIds(depth, values));
            }
          });
        });
      },
      /** 조회그룹(XML) 데이터 NODE_ID 포맷(DEPTH-VALUE)으로 변경 */
      convertNodeIds(depth, values) {
        return values.map(v => `${depth}-${v}`);
      },
      /** @description 검색 조건 셋팅 */
      handleSetSearchCondition(dxEvent) {
        if (dxEvent.columnIndex !== 0) return;
        const { title, param } = dxEvent.data;
        if ([title, param].includes(undefined)) {
          this.$_Msg('해당 조건이 정상적으로 적용되지 않았습니다.');
        }

        const jsonData = JSON.parse(param);
        const { search, target } = jsonData;
        // 날짜 변경
        const { startDt, endDt } = search;
        this.$refs.dateBoxRef.updateFormData(startDt, endDt);

        this.changeSectionBySearch(search);
        this.changeSectionByTarget(target);

        // 셋팅 후 히든섹션 닫기
        this.toggleHiddenSection(null, '');

        // 리스트 박스 닫고 알림 UP
        this.$refs.searchCondition.toggle(false);
        this.$_Toast(`${title} 조건으로 설정되었습니다.`);
      },
      /** 기간, 조회대상 버튼, 섹션, 컬러 변경 기능 */
      toggleHiddenSection(e, targetID, btnRef) {
        let isOn = true;
        let btnElem = null;
        // ** 1. 클릭한 버튼 컬러 변경 및 대상[그룹, 내선번호 등]일 시 값 세팅
        if (e !== null) {
          // 1. 버튼 on/off 체크
          const target = e.target;
          btnElem = target;
          if ('INPUT' === target.tagName) {
            btnElem = this.$refs[btnRef];
          } else if ('SPAN' === target.tagName) {
            btnElem = target.parentNode;
          }
          isOn = btnElem.classList.contains('on');

          // 2. 대상 버튼 클릭 시 => selectedButtonType, objKey 셋팅
          const { type } = btnElem.dataset;
          if (type !== undefined) {
            this.targets.selectedButtonType = type;
          }
        }

        // ** 2. 버튼 컬러 변경
        const targetBtnRefs = this.visibleTargetButtons.map(v => `${v.type}LayerBtn`);
        const showedBtnList = [...targetBtnRefs, 'dateLayerBtn'];
        showedBtnList.forEach(btnRef => {
          const refBtn = this.$refs[btnRef][0] || this.$refs[btnRef];
          const child = refBtn.lastChild;
          const onOff = !isOn && btnElem === refBtn ? 'on' : 'off';
          refBtn.classList = this.styles[`${onOff}LayerBtn`];
          child.classList = this.styles[`${onOff}UpDown`];
        });

        // ** 3. 레이어 드롭다운 열고 닫는 로직
        const HIDDEN_CLASS = 'hidden';
        showedBtnList.forEach(v => {
          const layerId = v.replace('LayerBtn', 'HiddenLayer');
          const layerRef = this.$refs[layerId];
          if (targetID === layerId) {
            layerRef.classList.toggle(HIDDEN_CLASS);
            if (v === 'checkLayerBtn') {
              this.refreshTreeBoxList();
            }
          } else if (layerRef.classList.contains(HIDDEN_CLASS) === false) {
            layerRef.classList.add(HIDDEN_CLASS);
          }
        });
      },
      /** 컴포넌트 내 블라인드 처리가 없어지지 않아 Refresh 시키는 로직 추가 */
      refreshTreeBoxList() {
        this.$refs.depthTreeBox.forEach(v => {
          v.refreshTreeList();
        });
      },
      handleChangeReportType(e) {
        this.setReportParam('report_type', e.value);
      },
      /** 쿼리에서 사용될 objKey 조회 (targets > target > value 값) */
      getSelectedTargetRadioValue(depth) {
        return this.targetRadioList.find(v => v.depth === depth).value;
      },
      /** 조회대상 라디오(뎁스) 선택 : depth 선택 및 이후 뎁스 트리박스 비활성화 */
      handleChangeTargetRadioValue(e) {
        const depth = e?.value ?? 1;
        this.targets.selectedDepth = depth;
        this.disabledTreeBox(depth);
      },
      /** 현재 선택한 뎁스보다 낮은 경우 트리박스 비활성화 */
      disabledTreeBox(depth) {
        DEPTH_NUMBERS.forEach(v => (this.targets[`disabled${v}`] = !(v <= depth)));
      },
      /** UI 옵션 셋팅 by [ 로컬스토리지, 검색조건(설정값) ] */
      updateSearchBoxBySearchParam(params, isSetStorage = true) {
        const { search, target } = params;
        this.changeSectionBySearch(search);
        if (isSetStorage) {
          this.changeSectionByTarget(target);
        }
      },
      /** [새창보기] 를 통한 스타일 변경, 옵션 셋팅, 조회 실행  */
      setNewWindowStyles() {
        // 새창보기 버튼 제거
        this.status.showWindowBtn = false;
        // DOM 요소 직접 접근
        const wrap = document.querySelector('#wrap');
        const header = document.querySelector('#wrap > header');
        const aside = document.querySelector('#wrap > aside');
        const content = document.querySelector('#wrap > main > .content');

        // 스타일 변경 및 DOM 조작
        if (wrap) {
          wrap.style.padding = '0';
          wrap.classList.remove('left-show');
        }
        if (header) header.remove();
        if (aside) aside.remove();
        if (content) content.style.marginLeft = '0';
      },
      mountedSetNewWindow(popupItems) {
        this.setNewWindowStyles();
        if ([null, undefined].includes(popupItems)) {
          return;
        }

        //컴포넌트 생성 전 셋팅 막기 위해 [nextTick] 사용
        const { options, params } = JSON.parse(popupItems);
        this.updateSearchBoxBySearchParam(options);

        // FormData 셋팅
        this.formData = params;
        this.$emit('onSearchClick', this.formData);
      },
      /** 페이지 진입 시 로컬스토리지 저장된 데이터로 화면 셋팅 */
      mountedSetOptionByLocalStorage() {
        if (!Object.hasOwn(this.reportInfo, 'reportId')) {
          return;
        }

        const storageData = localStorage.getItem(`ESP_REPORT_MENU_${this.reportInfo.reportId}`);
        if (isEmpty(storageData)) {
          this.initReportType();
          return;
        }
        this.updateSearchBoxBySearchParam(JSON.parse(storageData), this.options.useStorageData);
      },
      /** 보고서 타입 설정 (첫번째 옵션으로) */
      initReportType(reportType) {
        const firstReportType = this.visibleReportTypeList.at(0)?.value;
        const hasType = this.visibleReportTypeList.some(v => v.value === reportType);

        // ReportType 이 유효하지 않거나 리스트에 없으면 첫 번째 값 설정
        this.formData.report_type = isEmpty(reportType) || !hasType ? firstReportType : reportType;
      },
      /**
       * 검색 조건 팝오버 토글 기능
       * @param event
       * @param isShow 팝오버 오픈 여부
       * */
      handleShowSearchCondition(event, isShow = !this.$refs.searchCondition.condition.isShow) {
        event.stopPropagation();
        this.$refs.searchCondition.toggle(isShow);
      },
      /** XML 편집 모달 토글 시 동작 */
      async toggleXmlModal(toggleBool = true) {
        if (toggleBool) {
          this.modal.xmlHistories = await this.fetchXmlHistoryByReportId(this.reportInfo?.reportId);
        }
        //toggleBool의 경우 boolean이 아닐 수도 있어서 아래와 같이 처리
        this.modal.isOpenXmlModal = toggleBool ? true : false;
      },
      /** 보고서ID 를 통한 XML 편집 이력 조회 */
      async fetchXmlHistoryByReportId(reportId) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_XML_HISTORY_LIST',
          path: `/${reportId}`,
          loading: true,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
        return [];
      },
      /** 편집한 XML 저장 */
      async saveXmlModal() {
        const { cmEditor: reportXml, description } = this.$refs.xmlEditor;

        if (description.length > 100) {
          return this.$_Msg('설명은 100자 이내로 작성해주시기 바랍니다.');
        }

        //XML, 설명, 레포트 ID
        const params = {
          xmlData: reportXml,
          reportId: this.reportInfo?.reportId,
          description: description,
          useSqlPass: true,
        };

        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_XML_UPDATE',
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        if (isSuccess(res)) {
          this.$emit('init');
          this.initLayoutByReportXmlData();
          this.initMounted();
          await this.toggleXmlModal(false);
          this.$_Toast(this.$_lang('REPORT.MESSAGE.UPDATE_REPORT_INFO', { defaultValue: '보고서 정보가 갱신되었습니다.' }));
          return;
        }

        this.$_Msg(res.data.header.resMsg);
      },
      /** 조회대상 셀렉트 박스 변경 시 실행 되는 핸들러 */
      handleChangeTargetSelectBox(e) {
        this.changeTargetSelectBox(e.selectedItem);
      },
      /** 셀렉트 박스에 선택된 조회대상 변경 시 동작하는 기능 */
      changeTargetSelectBox(selectedItem) {
        if (selectedItem === undefined) {
          return;
        }

        const { index, value } = selectedItem;
        this.targets.selectedBoxIndex = index;
        this.targets.radioItems = this.targets.optionMap[value] || [];
        this.targets.selectedDepth = 1;

        // 트리박스 아이템 변경
        const targetMapData = this.targets.targetMap[value];
        if (!isEmpty(targetMapData)) {
          this.clearCheckedItems(); // 선택한 체크박스 초기화
          this.initViewTargets(); // 실시간 변경 조회그룹 초기화
          this.targets.v_dept1 = targetMapData.dept1; // 뎁스 1은 초기 설정 시 필수
        }
      },
      /** 트리박스에 보여지는 조회대상 초기화 */
      initViewTargets() {
        DEPTH_NUMBERS.forEach(number => {
          this.targets[`v_dept${number}`] = [];
        });
      },
      /** n 개 조회대상(targets) 리스트 데이터 API 호출 */
      async fetchTargetsMapData(targets) {
        if (isEmpty(this.reportInfo)) {
          return;
        }

        // API 리스트 생성
        const { solution, subPath } = this.reportInfo;
        const loginId = this.$store.getters.getLoginId;
        const apiList = targets.map(async ({ value: name }) => {
          const payload = {
            name,
            solution,
            subPath,
            loginId,
          };
          return await this.CALL_REPORT_API({
            actionName: 'REPORT_TARGET_RESULT_LIST',
            data: { data: payload },
            loading: false,
          });
        });

        // 조회그룹 Map 데이터 생성
        const targetMap = {};
        try {
          const response = await Promise.all(apiList);
          response.forEach(({ data }, index) => {
            const targetKey = targets[index].value;
            targetMap[targetKey] = data.data.reduce((acc, cur) => {
              const { TEXT, VALUE, PARENT_ID, DEPTH } = cur;

              // 뎁스 별 배열 초기화
              const depthKey = `dept${DEPTH}`;
              if (isEmpty(acc[depthKey])) {
                acc[depthKey] = [];
              }

              // 뎁스 별 아이템 추가
              const NODE_ID = `${DEPTH}-${VALUE}`;
              acc[depthKey].push({ TEXT, VALUE, PARENT_ID, DEPTH, NODE_ID });
              return acc;
            }, {});
          });
        } catch (e) {
          console.warn(e);
        }
        return targetMap;
      },
      /** 조회대상 option 태그 값 설정 */
      getTargetOptionsMapData(targets = []) {
        const result = {};
        targets.forEach(target => {
          // 조회대상(KEY) 별 옵션 리스트 설정
          const maxDepth = target.options.length;
          result[target.key] = target.options.map((option, index) => {
            const depth = index + 1; // 뎁스
            const hasNext = depth < maxDepth; // 다음 뎁스 존재 여부
            return { value: option.key, name: option.label, depth, hasNext };
          });
        });

        return result;
      },
      /** 조회대상 셀렉트 박스 설정할 옵션 조회 */
      getTargetSelectBoxItems(targets) {
        return targets.map(({ key, label }, index) => {
          if (isEmpty(key) || isEmpty(label)) {
            console.warn(`${index + 1} 번째 <target> 태그 label, key 속성을 확인해주시기 바랍니다.`);
          }

          // 조회대상 셀렉트 박스에 담을 옵션
          return { index: index, value: key, label: label };
        });
      },
      /** selectItems 의 key 와 같은 index 값 설정 */
      getTargetSelectedIndex() {
        try {
          return this.targets.selectBoxItems.at(0).index;
        } catch (e) {
          console.warn('getTargetSelectedIndex', e);
        }
        return 0;
      },
      /** 조회대상(targets) 데이터 설정 (select-box-items, radio-options) */
      async initTargetsData(targets = []) {
        this.targets.items = targets; // 조회대상 셀렉트 박스
        this.targets.selectBoxItems = this.getTargetSelectBoxItems(targets);
        this.targets.selectedBoxIndex = this.getTargetSelectedIndex();

        // 조회대상 데이터
        this.targets.targetMap = (await this.fetchTargetsMapData(this.targets.selectBoxItems)) || {};
        this.targets.optionMap = this.getTargetOptionsMapData(targets);

        const { selectedBoxIndex, selectBoxItems } = this.targets;
        this.changeTargetSelectBox(selectBoxItems[selectedBoxIndex]);
      },
      /** TimeBox 로부터 받은 데이터 처리 함수 */
      updateTimeAndReturnMessage(form) {
        const { startH, startM, endH, endM } = form;
        this.setReportParam('endTime', `${endH}${endM}`);
        this.setReportParam('startTime', `${startH}${startM}`);
        return `(${startH}:${startM} ~ ${endH}:${endM})`;
      },
      /** DaysBox 로부터 받은 데이터 처리 함수 */
      updateDaysAndReturnMessage(items) {
        // 공휴일
        const holidayFl = items.at(7)?.checked ? 'Y' : 'N';
        const holidayMessage = holidayFl === 'N' ? '' : ' 공휴일';
        this.setReportParam('holi', holidayFl);

        // 요일
        const checkedDays = items.filter(v => v.id !== 'HOLI' && v.checked);
        const daysAlts = checkedDays.map(v => v.alt);
        const daysValues = checkedDays.map(v => v.value);
        this.setReportParam('days', daysValues);
        return daysAlts.join(' ') + holidayMessage;
      },
      /** 날짜 변경 시 발생 이벤트 (메시지는 computed 에 정의) */
      handleChangeDate() {
        const { startDt, endDt } = this.$refs.dateBoxRef.getFormData();
        this.setReportParam('startDt', convertDateFormat(startDt, 'YYYYMMDD'));
        this.setReportParam('endDt', convertDateFormat(endDt, 'YYYYMMDD'));
      },
      /** 요일, 공휴일, 시간 변경 시 발생 이벤트 */
      handleChangedDaysOrTime() {
        // 시간
        const timeData = this.$refs.timeBoxRef.getFormData();
        const timeMessage = this.updateTimeAndReturnMessage(timeData);

        // 요일
        const daysDataList = this.$refs.daysBoxRef.getFormDataList();
        const daysMessage = this.updateDaysAndReturnMessage(daysDataList);

        // Input Message 변경
        this.selectedDaysAndTimeMessage = `${daysMessage} ${timeMessage}`;
      },
      /** 검색조건 추가 API 호출 */
      async saveSearchCondition() {
        const modalContentRef = this.$refs.searchConditionModalContent;
        const { title, limitSize } = modalContentRef.getTitleAndLimitSize();

        const selectedReportParams = this.getReportSearchParams();
        if (title !== '' && title.length < limitSize) {
          const res = await this.CALL_REPORT_API({
            actionName: 'REPORT_SEARCH_PARAM_INSERT',
            data: {
              title: title,
              menuId: this.reportInfo.menuId,
              param: JSON.stringify(selectedReportParams),
            },
            loading: true,
          });

          this.$_Toast(isSuccess(res) ? '검색 조건이 저장되었습니다.' : '작업실패');
          if (isSuccess(res)) {
            modalContentRef.init();
            this.modal.condition.isShow = false;
            await this.$refs.searchCondition.asyncGetSearchCondition(); // 재조회
          }
        } else {
          const validMsg = title === '' ? '조건검색명은 필수입니다.' : '50자 이하로 작성해주시기 바랍니다.';
          this.$_Toast(validMsg);
        }
      },
      /** 모달 정보 셋팅 */
      initSetXmlModalInfo() {
        if (isEmpty(this.reportInfo)) {
          return;
        }
        const { menuNm, reportId } = this.reportInfo;
        this.modal.editorOption.reportNm = menuNm;
        this.modal.editorOption.reportId = reportId;
      },
      /** 기타 옵션 화면 설정 */
      initEtcOptions() {
        if (isEmpty(this.reportInfo)) {
          return;
        }

        this.etcOptions = this.reportInfo?.options || [];
      },
      /** setPageLayout 다음 실행 */
      async initMounted() {
        await this.initTargetsData(this.reportInfo.targets); // 조회대상 관련 셋팅
        this.handleChangeTargetRadioValue(); // 라디오 버튼 선택 및 트리박스 비활성화
        this.handleChangedDaysOrTime(); // 요일 및 공휴일 초기화
        this.initSetXmlModalInfo(); // 모달 정보 셋팅
        this.initEtcOptions(); // 보고서 기타 옵션 셋팅
        this.initByStorageData(); // 새창 또는 로컬스토리지 데이터 셋팅
      },
      /** 초기 진입 또는 새창열기 시 활용할 데이터 셋팅 */
      initByStorageData() {
        const windowData = sessionStorage.getItem(window.name);
        if (windowData) {
          // ** 새창보기 (sessionStorage Name 값이 Null이 아닐 시 동작)
          this.mountedSetNewWindow(windowData);
          return;
        }
        // 페이지 첫 접근 시 로컬스토리지 데이터 셋팅
        this.mountedSetOptionByLocalStorage();
      },
      /** 기간, 요일, 시간 초기 셋팅 처리 */
      initLayoutByReportXmlData() {
        if (isEmpty(this.reportInfo)) {
          return;
        }
        // 기간
        const { periodDay, periodMonth, periodYear, periodRange } = this.reportInfo;

        //기간
        const periodData = {
          DAY: periodDay,
          MONTH: periodMonth,
          YEAR: periodYear,
          RANGE: periodRange,
        };

        if (!isEmpty(this.$refs.dateBoxRef)) {
          this.$refs.dateBoxRef.updatePeriodButtonVisible(periodData);
        }

        // 보고서유형
        const { reportDay, reportMonth, reportHour, reportMin30, reportMin15, reportDayHour, reportWeekDay } = this.reportInfo;

        //유형
        const reportTypeMap = {
          daily: reportDay,
          monthly: reportMonth,
          hour: reportHour,
          i15: reportMin30,
          i30: reportMin15,
          daytimes: reportDayHour,
          weekday: reportWeekDay,
        };

        this.reportType.items.forEach(item => {
          item.visible = isTrue(reportTypeMap[item.value]);
        });

        const { days, times } = this.reportInfo;
        this.status.days = !isTrue(days);
        this.status.times = !isTrue(times);
      },
    },
    mounted() {
      this.initMounted();
    },
    created() {
      vm = this;
      this.initLayoutByReportXmlData();
    },
    destroyed() {},
  };
</script>

<style scoped>
  .dx-outline {
    border: 1px solid #dcdcdc !important;
    border-radius: 4px;
    background-color: #fbfbfb !important;
    color: #aaaaaa;
  }

  .hidden {
    display: none;
  }
</style>
