<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu-copy.vue
  FILE_NAME : modal-menu-copy
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : 메뉴 복사 팝업 컴포넌트
-->
<template>
  <dx-popup
    :visible="isOpen"
    :show-title="propOption.showTitle"
    :title="propOption.title"
    :min-width="propOption.minWidth"
    :width="propOption.width"
    :min-height="propOption.minHeight"
    :height="propOption.height"
    :resize-enabled="propOption.resizeEnabled"
    :drag-enabled="propOption.dragEnabled"
    :show-close-button="propOption.showCloseButton"
    :hide-on-outside-click="propOption.closeOnOutsideClick"
    @hiding="handleCloseModal"
  >
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label">선택한 메뉴명</div>
        <div class="dx-field-value"> {{ formData.menuNm }} </div>
      </div>
      <div class="dx-field">
        <div class="dx-field-label">하위메뉴 복사</div>
        <div class="dx-field-value"><dx-check-box v-model="formData.isCopyChildren"></dx-check-box></div>
      </div>
    </div>

    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium',
        },
        text: propOption.saveBtnTxt,
        width: '120',
        height: '40',
        onClick: e => handleSaveModal(e),
      }"
    />
    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'white filled txt_XS medium',
        },
        text: propOption.cancelBtnTxt,
        width: '120',
        height: '40',
        onClick: e => handleCloseModal(e),
      }"
    />
  </dx-popup>
</template>

<script>
  import DxCheckBox from 'devextreme-vue/check-box';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import {isSuccess} from "@/utils/common-lib";

  export default {
    components: {
      DxToolbarItem,
      DxCheckBox,
      DxPopup,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      menuData: Object,
    },
    watch: {
      menuData: {
        handler(val) {
          this.formData.menuId = val.id;
          this.formData.menuNm = val.menuNm;
        },
        deep: true,
      },
    },
    data() {
      return {
        formData: {
          menuId: '',
          menuNm: '',
          isCopyChildren: true,
        },
        propOption: {
          showTitle: true,
          title: '메뉴 복사',
          minWidth: 400,
          width: 400,
          minHeight: 250,
          height: 250,
          resizeEnabled: false,
          dragEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: false,
          saveBtnTxt: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          cancelBtnTxt: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
        },
      };
    },
    methods: {
      /**
       * @description 닫기 버튼 클릭 이벤트
       * @param e
       */
      handleCloseModal(e) {
        this.initData();
        this.$emit('closeModal', e);
      },
      /**
       * @description 저장 버튼 클릭 이벤트
       */
      async handleSaveModal() {
        const res = await this.saveCopyMenu(this.formData);
        if (res) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.initData();
          this.$emit('saveModal');
        }
      },
      /**
       * @description 메뉴 복사
       * @param formData
       * @return {Promise<boolean>}
       */
      async saveCopyMenu(formData) {
        const payload = {
          actionName: 'MENU_COPY',
          data: {
            menuId: formData.menuId,
            isCopyChildren: formData.isCopyChildren,
          },
          useErrorPopup: true,
        };
        const res = await this.CALL_API(payload);
        return !!isSuccess(res);
      },
      /**
       * @description 데이터 초기화
       */
      initData() {
        this.formData = {
          menuId: '',
          menuNm: '',
          isCopyChildren: true,
        };
      }
    },
    created() {},
    mounted() {},
  };
</script>
<style scoped></style>
