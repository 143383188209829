<template>
	<transition>
		<div>
			<table class="table_form line-bin">
				<colgroup>
					<col style="width:150px;" />
					<col style="width:auto;" />
				</colgroup>
				<tbody>
					<tr v-for="(item, index) in columns">
						<th scope="row">
							<label for="label01">{{ item.caption }}<span class="icon_require">필수항목</span></label>
						</th>

						<td v-if="index == 0">
							<DxDateBox
								v-model="formData.ymd"
								:styling-mode="stylingMode"
								:width="140"
								type="date"
								dateSerializationFormat="yyyy-MM-dd"
								display-format="yyyy-MM"
								placeholder="선택하세요"
								:calendar-options="{
									zoomLevel: 'year',
									minZoomLevel: 'decade',
									maxZoomLevel: 'year',
								}"
							>
								<DxValidator validation-group="evalSaveValidate">
									<DxRequiredRule message="기준년월은 필수입니다." />
								</DxValidator>
							</DxDateBox>
						</td>
						<td v-if="index == 1">
							<DxTextBox
								v-model="formData.name"
								:max-length="limitNumberTexts.maxLengths.name"
								:styling-mode="stylingMode"
								class="mr-4"
								:show-clear-button="true"
								:width="300"
								@key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'name')"
							>
								<DxValidator validation-group="evalSaveValidate">
									<DxRequiredRule message="계획명은 필수입니다." />
								</DxValidator>
							</DxTextBox>
							{{ formData.name ? formData.name.length : 0 }}/{{ limitNumberTexts.maxLengths.name }}자
						</td>
						<td v-if="index == 2">
							<DxCheckBox class="checkbox" v-model="formData.check" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</transition>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxButton } from 'devextreme-vue/button';

import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';

let vm;

export default {
	components: {
		DxDateBox,
		DxTextBox,
		DxCheckBox,
		DxButton,
		DxValidator,
		DxRequiredRule,
	},
	props: {
		contentData: Object,
	},
	watch: {
		/** @description: 넘어갈 데이터 */
		formData: {
			handler(val) {
				this.$emit('input', val);
			},
			deep: true,
		},
	},
	data() {
		return {
			stylingMode: 'outlined',
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					name: 50,
				},
			},
			columns: [],
			formData: {
				id: null,
				ymd: null,
				name: null,
				check: false,
			},
		};
	},
	computed: {},
	methods: {
		createdData() {
			vm = this;

			this.contentData.columns.forEach(f => {
				if (f.requiredRule) {
					this.columns.push(f);
				}
			});
			this.formData = this.contentData.formData ? this.contentData.formData : this.formData;
		},
		mountedData() {
			if (!validationEngine.validateGroup('evalSaveValidate').isValid) {
				return;
			}
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
