import { render, staticRenderFns } from "./target-exam-sheet-result.vue?vue&type=template&id=64fff7db&scoped=true"
import script from "./target-exam-sheet-result.vue?vue&type=script&lang=js"
export * from "./target-exam-sheet-result.vue?vue&type=script&lang=js"
import style0 from "./target-exam-sheet-result.vue?vue&type=style&index=0&id=64fff7db&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fff7db",
  null
  
)

export default component.exports