<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/policy
  FILE_NAME : modal-inception.vue
  AUTHOR : jhcho
  DATE : 24. 12. 10.
  DESCRIPTION :
-->
<template>
  <div>
    <table class="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
      <colgroup>
        <col style="width: 150px"/>
        <col style="width: 110px"/>
        <col style="width: auto"/>
      </colgroup>
      <tbody class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <th scope="col"
            class="bg-gray-100 dark:bg-gray-700 py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          그룹명
        </th>
        <td colspan="2" class="py-4 px-6 text-left text-gray-700 whitespace-nowrap dark:text-white">
          <DxTextBox
              v-model="policyNm"
              placeholder="그룹명 입력"
              styling-mode="outlined"
          />
        </td>
      </tr>
      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <th scope="col"
            class="bg-gray-100 dark:bg-gray-700 py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          소통여부
        </th>
        <td colspan="2" class="py-4 px-6 text-left text-gray-700 whitespace-nowrap dark:text-white">
          <DxSelectBox
              v-model="policyGb"
              placeholder="소통여부 선택"
              :items="[
                {
                  label: '허용',
                  value: 'ALLOW'
                },
                {
                  label: '차단',
                  value: 'INCEP'
                }
            ]"
              display-expr="label"
              value-expr="value"
              styling-mode="outlined"
          />
        </td>
      </tr>

      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <th scope="col"
            class="bg-gray-100 dark:bg-gray-700 py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          부서
        </th>
        <td class="py-4 pl-6 pr-1 text-left text-gray-700 whitespace-nowrap dark:text-white">
          <DxButton
              text="부서"
              class="btn_XS default filled add1"
              :width="80"
              :height="30"
              @click="handleOpenModal('DEPT')"
          />
        </td>
        <td class="py-4 px-2 text-left text-gray-700 dark:text-white">
          <!-- 선택된 부서 표시 -->
          <span v-for="(dept, index) in policyDeptList" :key="`dept_${dept.id}_${index}`">
            {{ getDeptName(dept) }}{{index === policyDeptList.length - 1 ? '' : ', '}}
          </span>
        </td>
      </tr>

      <tr class="hover:bg-gray-100 dark:hover:bg-gray-700">
        <th scope="col"
            class="bg-gray-100 dark:bg-gray-700 py-3 px-6 tracking-wider text-center text-gray-900 uppercase dark:text-gray-400">
          개인
        </th>
        <td class="py-4 pl-6 pr-1 text-left text-gray-700 whitespace-nowrap dark:text-white">
          <DxButton
              text="조직도"
              class="btn_XS default filled add1"
              :width="80"
              :height="30"
              @click="handleOpenModal('USER')"
          />
        </td>
        <td class="py-4 px-2 text-left text-gray-700 dark:text-white">
          <!-- 선택된 유저 표시 -->
          <span v-for="(user, index) in policyUserList" :key="`user_${user.id}_${index}`">
            {{ getUserNm(user) }}{{index === policyUserList.length - 1 ? '' : ', '}}
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="organizationModalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isOpened="modal.isOpened"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: $_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
						width: '120',
						height: '40',
						onClick: onModalSave
					}"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => {
              isOpenModal(false)
            }
					}"
      />
    </DxPopup>
  </div>

</template>
<script>
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxButton} from 'devextreme-vue/button';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import ModalOrganizationUser from './modal-organization-user.vue';
import ModalOrganizationDept from './modal-organization-dept.vue';

export default {
  components: {
    DxPopup, DxToolbarItem, DxTextBox, DxSelectBox, DxButton,
    ModalOrganizationUser,
    ModalOrganizationDept
  },
  props: {
    modalData: Object,
    isOpened: Boolean,
  },
  data() {
    return {
      policyNm: '',
      policyGb: 'ALLOW',
      policyDeptList: [],
      policyUserList: [],
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        sendData: null,
      },
    }
  },
  watch: {
    isOpened: {
      handler: function (val) {
        if(val) {
          if(this.modalData?.selectedData) {
            this.setInitData();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    getDeptName(dept) {
      return this.modalData.deptList.find((d) => d.deptCode === dept.targetCd)?.deptNm;
    },
    getUserNm(user) {
      const data = this.modalData.userList.find((d) => d.userNo === user.targetCd);
      let result = '';
      if(data?.userNm) {
        result = data.userNm;
      }
      if(data?.dn) {
        if(result?.length) {
          result = `${result}(${data.dn})`;
        } else {
          result = `${user.targetCd}(${data.dn})`;
        }
      }
      if(result === '') {
        result = user.targetCd;
      }
      return result;
    },
    handleOpenModal(type) {
      const sendData = {
        userList: this.modalData.userList,
        deptList: this.modalData.deptList,
        parentComponent: 'ModalBlock',
        selectedData: {}
      }
      if(this.modalData?.selectedData) {
        sendData.selectedData = this.modalData.selectedData;
      }

      if(this.policyDeptList?.length || this.policyUserList?.length) {
        sendData.selectedData = {
          ...sendData.selectedData,
          blockDetailPolicyTempList: [
            ...this.policyDeptList,
            ...this.policyUserList
          ]
        }
      }

      const componentNm = type === 'DEPT' ? 'ModalOrganizationDept' : 'ModalOrganizationUser';
      const title = type === 'DEPT' ? '부서 선택' : '사용자 선택';
      this.onOpenModal(
          componentNm,
          {
            title: title,
            width: '50vw',
            height: 'calc(100vh - 100px)',
          },
          sendData
      );
    },
    async onModalSave() {
      const instance = this.$refs.organizationModalRef.getInstance();
      const selectedRows = await instance.getSelectedRowsData('all');
      if(this.modal.currentComponent === 'ModalOrganizationDept') {
        this.policyDeptList = selectedRows.map((d) => {
          return {
            targetCd: d.deptCode,
            targetGb: 'DEPT',
            targetTitle: `KBSTCK_${d.deptCode}`,
            policyId: this.modalData?.selectedData?.id || null,
          }
        });
      } else if(this.modal.currentComponent === 'ModalOrganizationUser') {
        console.log('selectedRows : ', selectedRows)
        this.policyUserList = selectedRows.map((d) => {
          return {
            targetCd: d.userNo,
            targetGb: 'USER',
            targetTitle: `KBSTCK_${d.userNo}`,
            policyId: this.modalData?.selectedData?.id || null,
          }
        });
      }

      this.isOpenModal(false);
    },
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      if (!bool) {
        this.$refs.organizationModalRef.clearSelection();
      }
      this.modal.isOpened = bool;
    },
    clearForm() {
      this.policyNm = '';
      this.policyGb = 'ALLOW';
      this.policyUserList = [];
      this.policyDeptList = [];
    },
    setInitData() {
      const selectedData = this.modalData.selectedData;
      this.policyNm = selectedData.policyNm;
      this.policyGb = selectedData.policyGb;
      this.policyDeptList = selectedData?.blockDetailPolicyList?.filter((d) => d.targetGb === 'DEPT') || [];
      this.policyUserList = selectedData?.blockDetailPolicyList?.filter((d) => d.targetGb === 'USER') || [];
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>

</style>