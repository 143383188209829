<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu-config.vue
  FILE_NAME : modal-menu-config
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : 메뉴등록 모달 컴포넌트
-->
<template>
  <dx-popup
    :visible="isOpen"
    :show-title="propOption.showTitle"
    :title="propOption.title"
    :min-width="propOption.minWidth"
    :width="propOption.width"
    :min-height="propOption.minHeight"
    :height="propOption.height"
    :resize-enabled="propOption.resizeEnabled"
    :drag-enabled="propOption.dragEnabled"
    :show-close-button="propOption.showCloseButton"
    :hide-on-outside-click="propOption.closeOnOutsideClick"
    @hiding="handleCloseModal"
  >
    <menu-add-default-form
      :ref="menuAddDefaultForm.refName"
      :menu-data="paramData"
      :validation-group-name="menuAddDefaultForm.validationGroupName"
      :show-title="menuAddDefaultForm.showTitle"
      :is-new-menu="menuAddDefaultForm.isNewMenu"
    />
    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'default filled txt_S medium'
        },
        text: propOption.saveBtnTxt,
        width: '120',
        height: '40',
        validationGroup: menuAddDefaultForm.validationGroupName,
        onClick: e => handleSaveModal(e)
      }"
    />
    <dx-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :options="{
        elementAttr: {
          class: 'white filled txt_XS medium'
        },
        text: propOption.cancelBtnTxt,
        width: '120',
        height: '40',
        onClick: e => handleCloseModal(e)
      }"
    />
  </dx-popup>
</template>

<script>
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { isSuccess } from '../../../../utils/common-lib';
  import MenuAddDefaultForm from './menu-add-default-form.vue';

  export default {
    components: {
      MenuAddDefaultForm,
      DxToolbarItem,
      DxPopup,
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false,
      },
      modalOption: {
        type: Object,
        default: () => ({}),
      },
      menuData: Object,
    },
    watch: {
      'modalOption.isRoot': {
        handler(val) {
          if (val) {
            this.initData();
          }
        },
        deep: true,
        immediate: true,
      },
      modalOption: {
        handler(val) {
          this.propOption = { ...this.propOption, ...val };
        },
        deep: true,
        immediate: true,
      },
      menuData: {
        handler(val) {
          this.paramData = {
            parentId: val?.id,
            menuDepth: val?.menuDepth + 1 || 1,
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        menuAddDefaultForm: {
          refName: 'modalMenuConfigFormRef',
          validationGroupName: 'modalMenuConfigFormValidationGroup',
          isNewMenu: true,
          showTitle: false,
        },
        paramData: {
          parentId: null,
          menuDepth: 0,
        },
        propOption: {
          showTitle: true,
          title: '메뉴 등록',
          minWidth: 530,
          width: 530,
          minHeight: 500,
          height: 500,
          resizeEnabled: false,
          dragEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: false,
          saveBtnTxt: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          cancelBtnTxt: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          isRoot: false, // 루트 메뉴 여부
        },
      };
    },
    computed: {},
    methods: {
      /**
       * @description 메뉴 저장
       * @return {Promise<boolean>}
       */
      async saveMenu() {
        const formData = this.$refs[this.menuAddDefaultForm.refName].getFormData();
        const payload = {
          actionName: 'MENU_LIST_SAVE',
          data: [formData],
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        return !!isSuccess(res);
      },
      /**
       * @description 데이터 초기화
       */
      initData() {
        this.$refs[this.menuAddDefaultForm.refName].initData();
      },
      /**
       * @description 취소 버튼 클릭 이벤트
       * @param e
       */
      handleCloseModal(e) {
        this.$emit('closeModal', e);
      },
      /**
       * @description 저장 버튼 클릭 이벤트
       */
      async handleSaveModal(e) {
        // 유효성 검사
        if (!e.validationGroup.validate().isValid) {
          return false;
        }

        const result = await this.saveMenu(e);
        if (result) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$refs[this.menuAddDefaultForm.refName].initData();
          this.$emit('saveModal');
        }
      },
    },
    mounted() {},
  };
</script>

<style scoped></style>
