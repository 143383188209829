<template>
  <div>
    <div class="ewm page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <div class="flex space-x-2 inner items-center">
            <div>기준년월</div>
            <div class="ui-datepicker-item">
              <DxDateBox
                styling-mode="outlined"
                :width="100"
                v-model="searchType.customTypes.startDt"
                type="date"
                date-serialization-format="yyyyMMdd"
                display-format="yyyy-MM"
                date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                maxLength="7"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayStartChanged"
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>
            <div>~</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                :width="100"
                v-model="searchType.customTypes.endDt"
                type="date"
                date-serialization-format="yyyyMMdd"
                display-format="yyyy-MM"
                date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
                maxLength="7"
                :min="searchType.customTypes.startDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayEndChanged"
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>
            <div class="pl-2">성과실적계획</div>
            <div>
              <DxSelectBox
                placeholder="선택하세요"
                v-model="selected.kpiPerformScheId"
                :items="scheList"
                display-expr="scheNm"
                value-expr="kpiPerformScheId"
                :styling-mode="stylingMode"
                :width="250"
                :height="30"
                :no-data-text="this.$_lang('CMN_NO_DATA')"
                @value-changed="onAddSche"
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="성과실적계획은 필수입니다." />
                </DxValidator>
              </DxSelectBox>
            </div>
            <div class="pl-2">
              <DxCheckBox class="checkbox" text="점수 보정 표기 여부" @value-changed="onChangedExistEditScore"></DxCheckBox>
            </div>

            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
          </div>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @saving="e => onSave(e)" @toolbar-preparing="onToolbarPreparing" />

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { getPastFromToday, isSuccess } from '@/utils/common-lib';
import ModalUseList from '@/pages/ewm/hr/management/vacation-setting/modal-use-list.vue';
import { DxColumn, DxEditing, DxLoadPanel, DxPager, DxPaging, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

let vm = this;

export default {
  components: {
    EspDxDataGrid,
    DxPaging,
    DxPager,
    DxScrolling,
    DxLoadPanel,
    DxColumn,
    DxSelection,
    DxEditing,
    DxButton,
    DxDateBox,
    DxSelectBox,
    DxCheckBox,
    DxPopup,
    DxPosition,
    DxToolbarItem,
    DxRequiredRule,
    DxValidator,
    ModalUseList,
  },
  data() {
    return {
      checkBoxValue: false,
      performGrid: {
        dataSource: [],
      },
      magamName: '마감',
      customButtons: [],
      stylingMode: 'outlined', //outlined, underlined, filled
      scheList: [],
      results: null,
      saves: [],
      magamFl: null,
      isCalProc: false,
      totalScoreHeader: 0,
      headerIds: [
        {
          kpiPerformStdId: null,
          kpiPerformMetricsId: null,
        },
      ],
      selected: {
        kpiPerformScheId: null,
        kpiPerformScheNm: null,
      },
      searchType: {
        customTypes: {
          startDt: getPastFromToday(11, 'months'),
          endDt: getPastFromToday(0, 'days'),
        },
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      masterData: [],
      toolbarItems: [], //툴바 버튼 관련
      dataGrid: {
        refName: 'performResultGrid',
        allowColumnResizing: true, //컬럼 사이즈 허용
        columnResizingMode: 'widget',
        columnAutoWidth: true,
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        scrolling: {
          mode: 'standard',
        },
        callApi: 'CALL_EWM_API',
        apiActionNm: {},
        customEvent: {
          toolbarPreparing: true, // 툴바 이벤트
        },
        showActionButtons: {
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          //expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        /* selecting: {
        mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
        selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
        showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
      }, */
        columns: [],
      },
    };
  },
  computed: {
    magamButtonText() {
      return this.magamFl === 'Y' ? '마감' : '마감해제';
    },
  },
  methods: {
    onAddSche(e) {
      const kpiPerformScheId = e.value;
      this.selected.kpiPerformScheId = kpiPerformScheId;

      const sche = this.scheList.find(sche => sche.kpiPerformScheId == kpiPerformScheId);
      this.magamFl = sche ? sche.magamFl : null;
      this.selected.kpiPerformScheNm = sche ? sche.scheNm : null;

      //this.$refs.performResultGrid.getInstance.option('toolbar.items', this.toolbarItems);
      this.$refs.performResultGrid.getInstance.repaint();

      if (e.value) {
        this.onSearch();
      } else {
        this.resetPerformResultGrid();
      }
    },
    onChangedExistEditScore(e) {
      this.checkBoxValue = e.value;
      this.$refs.performResultGrid.getInstance.repaint();
    },
    getMagamFlStatus() {
      return this.magamFl;
    },
    onToolbarPreparing(e) {
      this.toolbarItems = [];
      this.toolbarItems.unshift(
        {
          widget: 'dxButton',
          options: {
            text: '실적계산',
            elementAttr: {class: 'btn_XS default filled'},
            width: 70,
            height: 30,
            disabled: !this.selected.kpiPerformScheId || vm.magamFl == 'Y',
            onClick: function () {
              vm.onCalc();
            },
          },
          location: 'before',
        },
        {
          widget: 'dxButton',
          options: {
            text: '저장',
            elementAttr: {class: 'btn_XS default filled'},
            width: 70,
            height: 30,
            disabled: !this.selected.kpiPerformScheId || !this.isCalProc || vm.magamFl == 'Y',
            onClick() {
              vm.onSave();
            },
          },
          location: 'before',
        },
        {
          widget: 'dxButton',
          options: {
            text: '취소',
            visible: false,
            elementAttr: {class: 'btn_XS default filled'},
            width: 70,
            height: 30,
            onClick() {
              vm.$refs.performResultGrid.getInstance.cancelEditData();
            },
          },
          location: 'before',
        },
        {
          widget: 'dxButton',
          options: {
            id: 'magam',
            text: '마감',
            disabled: !this.selected.kpiPerformScheId || !this.isCalProc || vm.magamFl == 'Y',
            visible: vm.magamFl != 'Y',
            elementAttr: {class: 'btn_XS default filled'},
            width: 70,
            height: 30,
            onClick() {
              vm.onDeadline();
            },
          },
          location: 'before',
        },
        {
          widget: 'dxButton',
          options: {
            id: 'magamClose',
            text: '마감해제',
            visible: vm.magamFl == 'Y',
            elementAttr: {class: 'btn_XS default filled'},
            width: 70,
            height: 30,
            onClick() {
              vm.onDeadlineClose();
            },
          },
          location: 'before',
        },
      );

      e.toolbarOptions.items = this.toolbarItems;
    },
    /** 검색 이벤트 */
    async onSearch() {
      this.resetPerformResultGrid();

      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      /* if (!this.selected.kpiPerformScheId) {
      this.$_Toast('성과실적계획을 선택해 주세요.');
      return;
    } */

      this.findAllStdRegByScheId()
        .then(headerAndTarget => {
          this.makeGridHeader(headerAndTarget);
          return headerAndTarget;
        })
        .then(headerAndTarget => {
          let targets = headerAndTarget.kpiPerformScheTargets;
          vm.bindResultRow(targets);
        });
    },

    async bindResultRow(targets) {
      const kpiPerformScheId = this.selected.kpiPerformScheId;
      this.isCalProc = false;

      const payload = {
        actionName: 'EWM_PERFORMANCE_RESULT_SELECT',
        path: '/' + kpiPerformScheId,
        loading: false,
      };

      const res = await vm.CALL_EWM_API(payload);
      if (isSuccess(res)) {
        const executions = res?.data?.data?.[0]?.executions || [];
        const targetScoreRanks = res?.data?.data?.[0]?.targetScoreRanks || [];

        if (executions.length > 0) {
          this.isCalProc = true;
        }

        const rows = targets.map(target => {
          const agtid = target.agtid;
          const targetScoreRank = targetScoreRanks.find(t => t.agtid === agtid) || {};
          const executionsForTarget = executions.filter(exe => exe.agtid === agtid);

          const modifiedExecutions = executionsForTarget.reduce((acc, exe) => {
            const id = `id_${exe.kpiPerformScheId}_${exe.kpiPerformStdId}_${exe.kpiPerformMetricsId}`;
            const editId = `editId_${exe.kpiPerformScheId}_${exe.kpiPerformStdId}_${exe.kpiPerformMetricsId}`;

            return {
              ...acc,
              [id]: exe.score,
              [editId]: exe.editScore,
            };
          }, {});

          return {
            ...target,
            ...modifiedExecutions,
            ranking: targetScoreRank.ranking ? targetScoreRank.ranking : null,
            totalScore: targetScoreRank.totalScore ? targetScoreRank.totalScore : null,
          };
        });
        this.$set(this.dataGrid, 'dataSource', rows);
      }

      this.$refs.performResultGrid.getInstance.repaint();
    },
    columnOption(column) {
      const returnColumn = {
        caption: column.caption,
        dataField: column.dataField,
        alignment: 'center',
        visible: true,
        allowEditing: false,
        allowFiltering: true,
        allowHeaderFiltering: false,
        allowSorting: true,
      };

      if (column.alignment) returnColumn['alignment'] = column.alignment;
      if (column.allowHeaderFiltering) returnColumn['allowHeaderFiltering'] = column.allowHeaderFiltering;
      if (column.allowEditing) returnColumn['allowEditing'] = column.allowEditing;
      if (column.calculateCellValue) returnColumn['calculateCellValue'] = column.calculateCellValue;
      if (column.calculateDisplayValue) returnColumn['calculateDisplayValue'] = column.calculateDisplayValue;
      if (column.dataType) returnColumn['dataType'] = column.dataType;
      if (column.visible) returnColumn['visible'] = column.visible;

      return returnColumn;
    },
    /** @description : header 생성 */
    async makeGridHeader(response) {
      const kpiPerformScheId = this.selected.kpiPerformScheId;
      let headerResults = response.kpiPerformScheStdRegs;

      let baseColumns = [
        vm.columnOption({
          caption: '부서',
          dataField: 'pathNmFull',
          allowHeaderFiltering: true,
          //calculateDisplayValue: rowData => `${rowData.pathNmFull}`,
        }),
        vm.columnOption({
          caption: '상담사',
          dataField: 'agtNm',
          allowHeaderFiltering: true,
          calculateCellValue: rowData => `${rowData.agtNm} (${rowData.agtid})`,
        }),
      ];

      if (headerResults.length > 0) {
        vm.headerIds = [];
      }
      const middleColumns = headerResults.map(headerData => {
        /* headerData.metrics.some(m => {
        const ids = {
          kpiPerformStdId: headerData.kpiPerformStdId,
          kpiPerformMetricsId: m.kpiPerformMetricsId,
        };
        vm.headerIds.push(ids);
      }); */
        for (const m of headerData.metrics) {
          const ids = {
            kpiPerformStdId: headerData.kpiPerformStdId,
            kpiPerformMetricsId: m.kpiPerformMetricsId,
          };
          vm.headerIds.push(ids);
        }

        return headerData.metrics.length > 1
          ? vm.createMultiColumn(headerData, kpiPerformScheId)
          : vm.createMergedColumn(headerData, kpiPerformScheId);
      });

      const lastColumns = [
        vm.columnOption({
          caption: `총점(${this.totalScoreHeader})`,
          dataField: 'totalScore',
          dataType: 'number',
          allowHeaderFiltering: false,
          //calculateCellValue: rowData => `${rowData.totalScore}`,
        }),
        vm.columnOption({
          caption: '순위',
          dataField: 'ranking',
          dataType: 'number',
          allowHeaderFiltering: true,
          //calculateCellValue: rowData => `${rowData.ranking}`,
        }),

        //vm.columnOption('총점', 'totalScore', rowData => `${rowData.totalScore}`, false),
        //vm.columnOption('순위', 'ranking', rowData => `${rowData.ranking}`, true),
      ];

      const multiColumns = baseColumns.concat(middleColumns, lastColumns);

      //this.$set(this.dataGrid, 'columns', multiColumns);

      this.$refs.performResultGrid.getInstance.option('columns', multiColumns);
    },
    /** @description : Grid 멀티컬럼 생성 */
    createMultiColumn(headerData, kpiPerformScheId) {
      const {kpiPerformStdId, metrics} = headerData;

      // 지표 총 합
      let totalMetricsScore = 0;

      // 하위컬럼 생성
      const columns = metrics.map(metric => {
        if (typeof metric.maxScore === 'number' && !isNaN(metric.maxScore)) {
          totalMetricsScore += metric.maxScore;
        }

        let editId = `editId_${kpiPerformScheId}_${kpiPerformStdId}_${metric.kpiPerformMetricsId}`;
        let dataFieldId = `id_${kpiPerformScheId}_${kpiPerformStdId}_${metric.kpiPerformMetricsId}`;
        return this.createColumn(metric, dataFieldId, editId);
      });

      /* return {
      multiHeaderNm: `${headerData.performStdNm}[${totalMetricsScore}]`,
      columns: columns,
    }; */
      this.totalScoreHeader += totalMetricsScore;

      return {
        caption: `${headerData.performStdNm}[${totalMetricsScore}]`,
        columns,
      };
    },
    /** @description : Grid 멀티컬럼 생성 */
    createMergedColumn(headerData, kpiPerformScheId) {
      const {kpiPerformStdId, metrics} = headerData;

      // 하위컬럼 생성
      const columns = metrics.map(metric => {
        if (typeof metric.maxScore === 'number' && !isNaN(metric.maxScore)) {
          this.totalScoreHeader += metric.maxScore;
        }

        let editId = `editId_${kpiPerformScheId}_${kpiPerformStdId}_${metric.kpiPerformMetricsId}`;
        let dataFieldId = `id_${kpiPerformScheId}_${kpiPerformStdId}_${metric.kpiPerformMetricsId}`;
        return this.createColumn(metric, dataFieldId, editId);
      });

      return columns[0];
    },
    /** @description : Grid 멀티컬럼의 하위컬럼 생성 */
    createColumn(metrics, dataFieldId, editId) {
      return {
        caption: metrics.metricsNm + '[' + metrics.maxScore + ']',
        alignment: 'center',
        dataField: dataFieldId,
        dataType: 'number',
        visible: true,
        allowEditing: this.magamFl != 'Y',
        allowHeaderFiltering: false,
        calculateCellValue: rowData => {
          return rowData[editId] ? rowData[editId] : rowData[dataFieldId];
        },
        calculateDisplayValue: rowData => {
          if (vm.checkBoxValue && rowData[editId]) {
            return rowData[dataFieldId] + ' -> ' + rowData[editId];
          }
          //return rowData[dataFieldId];
          return rowData[editId] ? rowData[editId] : rowData[dataFieldId];
        },
        setCellValue(newData, value, currentRowData) {
          /* if (currentRowData[editId]) {
          newData[dataFieldId] = currentRowData[editId];
        } */
          newData[editId] = value;
        },
      };
    },
    resetPerformResultGrid() {
      this.totalScoreHeader = 0;
      if (this.dataGrid.dataSource.length > 0) {
        this.dataGrid.dataSource = [];
      }

      this.$refs.performResultGrid.getInstance.option('columns', []);
    },
    async findAllStdRegByScheId() {
      if (!this.selected.kpiPerformScheId) {
        this.$_Msg('성과실적계획을 선택해 주세요.');
        return;
      }
      const payload = {
        actionName: 'EWM_PERFORMANCE_RESULT_STD_SELECT',
        path: '/' + this.selected.kpiPerformScheId,
        loading: false,
      };
      const res = await vm.CALL_EWM_API(payload);
      if (isSuccess(res)) {
        return res?.data?.data?.[0];
      }
    },
    async onDeadline() {
      if (!this.selected.kpiPerformScheId) {
        this.$_Msg('성과실적계획을 선택해 주세요.');
        return;
      }

      if (this.magamFl == 'Y') {
        this.$_Msg('마감되었습니다.');
        return;
      }

      if (
        !(await this.$_Confirm(`마감시 계획 '${this.selected.kpiPerformScheNm}' 에 대해 <br/>실적계산과 저장 버튼이 비활성화 됩니다`, {
          title: `<h2>성과실적실행</h2>`,
        }))
      ) {
        return;
      }
      const payload = {
        actionName: 'EWM_PERFORMANCE_SCHE_UPDATE',
        path: '/' + this.selected.kpiPerformScheId + '/magam/' + 'Y',
        loading: false,
      };

      const res = await vm.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.magamFl = 'Y';
        this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
        await this.onSearch();
        this.$_Toast('마감이 완료되었습니다.');

        const dataGridInstance = this.$refs.performResultGrid.getInstance;
        dataGridInstance.repaint();
      }
    },

    async onDeadlineClose() {
      if (!this.selected.kpiPerformScheId) {
        this.$_Msg('성과실적계획을 선택해 주세요.');
        return;
      }
      if (
        !(await this.$_Confirm('마감해제 하시겠습니까? <br/> 해제 시 계산 및 수정작업 진행을 할 수 있습니다.', {
          title: `<h2>성과실적실행</h2>`,
        }))
      ) {
        return;
      }
      const payload = {
        actionName: 'EWM_PERFORMANCE_SCHE_UPDATE',
        path: '/' + this.selected.kpiPerformScheId + '/magam/' + 'N',
        loading: false,
      };

      const res = await vm.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.magamFl = 'N';
        this.$_Toast(this.$_lang('CMN_SUC_UPDATE'));
        await this.onSearch();
        this.$_Toast('마감 해제가 완료되었습니다.');
        const dataGridInstance = this.$refs.performResultGrid.getInstance;
        dataGridInstance.repaint();
      }
    },
    /** @description : 계획 데이터 조회 */
    async selectScheduleList() {
      this.selected.kpiPerformScheId = null;

      const payload = {
        actionName: 'EWM_PERFORMANCE_SCHE_SELECT',
        data: {
          startDt: vm.searchType.customTypes.startDt,
          endDt: vm.searchType.customTypes.endDt,
        },
        loading: false,
      };

      const res = await vm.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.scheList = res.data.data;
      }
    },

    async onCalc() {
      if (this.magamFl == 'Y') {
        this.$_Msg('마감되었습니다.');
        return;
      }
      if (!this.selected.kpiPerformScheId) {
        this.$_Msg('성과실적계획을 선택해 주세요.');
        return;
      }
      if (
        !(await this.$_Confirm('실적계산을 진행하시겠습니까? \n 진행 시 설정한 모든 데이터는 초기화 됩니다. ', {
          title: `<h2>성과실적실행</h2>`,
        }))
      ) {
        return;
      }

      const kpiPerformScheId = this.selected.kpiPerformScheId;
      const gridDatasources = this.dataGrid.dataSource;

      let results = [];
      gridDatasources.some(datasource => {
        const agtid = datasource.agtid;
        vm.headerIds.some(id => {
          results.push({
            kpiPerformScheId: kpiPerformScheId,
            kpiPerformStdId: id.kpiPerformStdId,
            kpiPerformMetricsId: id.kpiPerformMetricsId,
            agtid: agtid,
          });
        });
      });

      const payload = {
        actionName: 'EWM_PERFORMANCE_RESULT_INSERT',
        data: {
          kpiPerformScheId: kpiPerformScheId,
          results: results,
        },
        loading: false,
      };

      const res = await vm.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast('실적계산이 완료 되었습니다.');
        vm.$refs.performResultGrid.getInstance.cancelEditData();
        await this.onSearch();
      }
    },
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = data;
      this.isOpenModal(true);
    },
    /** @description: 팝업 오픈 체크 메서드 */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    async onSave() {
      if (!this.selected.kpiPerformScheId) {
        this.$_Msg('성과실적계획을 선택해 주세요.');
        return;
      }

      if (this.magamFl == 'Y') {
        this.$_Msg('마감되었습니다.');
        return;
      }

      if (!this.isCalProc) {
        this.$_Msg('실적계산을 먼저 실행하세요.');
        return;
      }

      if (
        !(await this.$_Confirm('변경한 점수를 저장하시겠습니까? \n 변경 시 총점과 순위가 변경됩니다. ', {
          title: `<h2>성과실적실행</h2>`,
        }))
      ) {
        return;
      }

      const gridInstance = this.$refs.performResultGrid.getInstance;
      const getDataSource = gridInstance.getController('editing');
      const changes = getDataSource.getChanges();

      let saves = [];
      changes.some(change => {
        const newData = change.data;
        const oldData = change.key;

        let keys = Object.keys(newData);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];

          const ids = key.split('_');
          const kpiPerformScheId = ids[1];
          const kpiPerformStdId = ids[2];
          const kpiPerformMetricsId = ids[3];
          const agtid = oldData.agtid;
          saves.push({
            kpiPerformScheId: kpiPerformScheId,
            kpiPerformMetricsId: kpiPerformMetricsId,
            kpiPerformStdId: kpiPerformStdId,
            agtid: agtid,
            score: newData[key],
          });
        }
      });

      const payload = {
        actionName: 'EWM_PERFORMANCE_RESULT_INSERT',
        path: '/' + this.selected.kpiPerformScheId,
        data: {
          saves: saves,
        },
        loading: false,
      };

      const res = await vm.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        await this.onSearch();
        vm.$refs.performResultGrid.getInstance.cancelEditData();
        this.$_Toast('점수반영이 완료 되었습니다.');
      }
    },
    onDayStartChanged(e) {
      const changed = e.value.slice(0, 6) + '01';
      this.searchType.customTypes.startDt = changed;
      this.selectScheduleList();
    },
    onDayEndChanged(e) {
      const changed = e.value.slice(0, 6) + '29';
      this.searchType.customTypes.endDt = changed;
      this.selectScheduleList();
    },
    /** @description : 셀렉트 박스 초기화 */
    async initCodeMap() {
    },
    /** @description : 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      vm = this;

      this.initCodeMap().then(() => {
      });

      this.selectScheduleList();
    },
    /** @description : 라이프사이클 mounted시 호출되는 메서드 */
    mountData() {
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountData();
  },
};
</script>
<style scoped>
</style>
