<template>
  <aside @mouseleave="handleAsideMouseLeave">
    <div class="side-top right_log">
      <button type="button" data-menu="" @click="toggleSidePannel">
        <img src="@/assets/images/lnb_icon1.png" alt="ESP" />
      </button>
    </div>
    <!-- side pannel icon -->
    <div class="pannel-area" style="height: calc(100% - 60px)">
      <dx-scroll-view width="100%" height="100%">
        <ul>
          <li
            v-for="item in treeMenuList"
            :key="item.id"
            :class="[item.menuIcon, { sel: item.selected }]"
            @click="goLinkUrl(item, 'click')"
            @mouseover="goLinkUrl(item, 'mouseover')"
          >
            <button type="button" class="side-obj" :data-menu="item.id" :class="{ sel: item.selected }">
              <img
                :src="getIconSrc(item.menuIcon)"
                alt=""
                class="lnb_icon_box filter-themes"
                :style="{ filter: item.menuIcon === 'menu-favorite' ? 'none' : '' }"
              />
              <span class="m_txt">{{ item.menuNm }}</span>
            </button>
          </li>
        </ul>
      </dx-scroll-view>
    </div>
    <!-- side pannel 모음 -->
    <div class="pannel-group">
      <div v-for="item in treeMenuList" :key="item.menuIcon + item.id">
        <div
          :id="`side_${item.id}`"
          :class="[item.menuTypeCd === enums.common.menuType.LINK.value ? '' : 'side-pannel', item.selected ? 'show' : '']"
        >
          <div class="pannel-cont">
            <dx-scroll-view width="100%" height="100%">
              <div class="tbl-acco">
                <dl>
                  <div v-for="data in item.children" :key="data.menuNm + 'title' + data.id">
                    <dt
                      v-if="data.menuTypeCd === enums.common.menuType.NORMAL_PAGE.value"
                      class="nothing"
                      :class="{ sel: data.selected }"
                      @click="onSideDtClick(data, item)"
                    >
                      <a @click="goMenuUrl(data, item, $event)">
                        <span class="title">
                          {{ data.menuNm }}
                          <span
                            v-if="data.favoriteFl && item.id !== 999999999"
                            @click="handleFavoriteMenu($event, data, false)"
                            class="star-check"
                          ></span>
                          <span
                            v-else-if="!data.favoriteFl && item.id !== 999999999"
                            @click="handleFavoriteMenu($event, data, true)"
                            class="star-uncheck"
                          ></span>
                        </span>
                      </a>
                    </dt>
                    <div v-else>
                      <dt :class="{ sel: data.selected }" @click="onSideDtClick(data, item)">
                        <a>
                          <span class="title">{{ data.menuNm }}</span>
                        </a>
                      </dt>
                      <transition name="slide">
                        <dd v-show="data.selected" :key="data.menuNm + 'submenu' + data.id">
                          <span
                            v-for="menu in data.children"
                            :key="data.id + menu.id"
                            class="cursor-pointer"
                            :class="{ on: menu.selected }"
                          >
                            <a @click="goMenuUrl(menu, data, $event)">
                              {{ menu.menuNm }}
                              <span
                                v-if="menu.favoriteFl && data.id !== 999999999"
                                @click="handleFavoriteMenu($event, menu, false)"
                                class="star-check"
                              ></span>
                              <span
                                v-else-if="!menu.favoriteFl && data.id !== 999999999"
                                @click="handleFavoriteMenu($event, menu, true)"
                                class="star-uncheck"
                              ></span>
                            </a>
                          </span>
                        </dd>
                      </transition>
                    </div>
                  </div>
                </dl>
              </div>
            </dx-scroll-view>
          </div>
        </div>
        <div id="app1" :key="'app1' + item.id">
          <window-popup v-model="windowPopup" :urls="url">팝업창의 내용입니다.</window-popup>
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import { isEmpty, isSuccess } from '@/utils/common-lib';
  import WindowPopup from './window-popup.vue';
  import enums from '@/configs/enums';

  export default {
    components: { DxScrollView, WindowPopup },
    data() {
      return {
        drawer: true,
        menuList: null,
        selectedItems: null,
        animationDuration: 300,
        multiple: true,
        collapsible: true,
        windowPopup: false,
        url: '',
        treeMenuList: [],
        menuIcons: [],
        favoriteMenuChgFl: false,
      };
    },
    computed: {
      enums() {
        return enums;
      },
    },
    watch: {
      '$store.getters.getFavoriteMenu': {
        handler(newValue, oldValue) {
          if (newValue?.length || oldValue?.length) {
            if (this.favoriteMenuChgFl) {
              this.favoriteMenuChgFl = false;
              return;
            }
            this.treeMenuList = this.setTreeMenuList();
          }
        },
        deep: true,
      },
      '$route.path'() {
        this.$nextTick(() => {
          this.treeMenuList = this.setTreeMenuList();
        });
      },
    },
    methods: {
      /**
       * @description 메뉴 데이터 트리 구조로 변환
       * @return {Array}
       */
      setTreeMenuList() {
        const currentPath = this.$route.path;
        const menuId = this.$route.params.menuId;
        let menuList = this.getMergedMenuList();
        menuList = this.setSelectedState(menuList); // selected 상태 추가
        this.setSelectParentAndCurrentMenus(menuList, menuId, currentPath); // 현재 메뉴와 상위 메뉴 선택

        // 메뉴 데이터를 트리 구조로 변환하여 저장 후 반환
        this.menuList = this.convertToTreeStructure(menuList);
        return this.menuList;
      },
      /**
       * @description 즐겨찾기 메뉴와 스토어의 메뉴 리스트를 병합
       * @return {Array}
       */
      getMergedMenuList() {
        let menuList = this.$store.getters.getMenuList;
        menuList.map(menu => {
          menu.favoriteFl = false;
        });
        if (this.$store.getters.getFavoriteMenu?.length) {
          return [...this.setFavoriteMenu(menuList), ...menuList];
        }
        return menuList;
      },
      /**
       * @description 메뉴 권한 필터와 기본 상태(selected 초기화) 적용
       * @param menuList
       * @return {Array}
       */
      setSelectedState(menuList) {
        return menuList
          .filter(menu => menu.authUseFl === 'Y') // 권한 사용 여부 체크
          .map(menu => ({ ...menu, selected: false })); // 선택 상태 초기화
      },
      /**
       * @description 현재 메뉴와 모든 상위 메뉴를 선택 처리 (n-depth 지원)
       * @param menuList
       * @param menuId
       * @param currentPath
       */
      setSelectParentAndCurrentMenus(menuList, menuId, currentPath) {
        if (!currentPath || currentPath === '/') {
          this.$parent.getWrapElement().classList.remove('left-show'); // 경로 없을 시 메뉴 숨김
          menuList[0].selected = true;
          return;
        }

        const targetMenu = menuList.find(menu => menu.id === menuId || menu.pageUrl === currentPath);

        if (targetMenu) {
          this.setSelectedParentMenus(menuList, targetMenu); // 상위 메뉴 선택
          this.setUnSelectedMenus(menuList, targetMenu); // 형제 메뉴 선택 해제
        }
      },
      /**
       * @description 재귀적으로 상위 메뉴를 선택하는 로직 (n-depth 지원)
       * @param menuList
       * @param target
       */
      setSelectedParentMenus(menuList, target) {
        let current = target;

        while (current) {
          current.selected = true; // 현재 메뉴 선택
          current = menuList.find(menu => menu.id === current.parentId); // 상위 메뉴 탐색
        }
      },
      /**
       * @description 동일 parentId를 가지는 형제 메뉴 선택 해제
       * @param menuList
       * @param selectedMenu
       */
      setUnSelectedMenus(menuList, selectedMenu) {
        const siblings = menuList.filter(menu => menu.parentId === selectedMenu.parentId);

        siblings.forEach(menu => {
          if (menu.id !== selectedMenu.id) {
            menu.selected = false;
          }
        });
      },
      /**
       * @description 메뉴 데이터를 트리 구조로 변환
       * @param menuList
       * @return {items}
       */
      convertToTreeStructure(menuList) {
        return this.makeTreeData(menuList);
      },
      // 클릭 시 메뉴 on 제거, 메뉴영역 활성화시엔 토글
      toggleSidePannel() {
        this.$parent.getWrapElement().classList.toggle('left-show');
      },

      /**
       * @description 1Depth 선택 이벤트
       * @param menu 1Depth menu Data
       * @param eventType click or mouseover
       */
      goLinkUrl(menu, eventType) {
        if (eventType === 'mouseover' && this.$store.getters.getIsShowMyLayerLayout) {
          return;
        } else if (eventType === 'click') {
          if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl) {
            this.windowPopup = true;
            this.url = menu.pageUrl.replace('#user#', this.$store.getters.getLoginId);
          }
        }

        this.$parent.getWrapElement().classList.add('left-show');

        this.treeMenuList.forEach(child => {
          if (child !== menu) {
            child.selected = false;
          } else {
            child.selected = true;

            for (let i = child.children.length - 1; i >= 0; i--) {
              if (child.children[i].selected) {
                break;
              }

              if (i === 0 && child.children[i].menuTypeCd === enums.common.menuType.NORMAL_MENU.value) {
                child.children[i].selected = true;
              }
            }
          }
        });
      },

      /**
       * @description 2Depth 선택 이벤트
       * @param menu 2Depth menu Data
       * @param parent 상위 메뉴 Data
       */
      onSideDtClick(menu, parent) {
        if (menu.menuTypeCd === this.enums.common.menuType.NORMAL_PAGE.value) {
          menu.selected = true;
        } else {
          menu.selected = !menu.selected;
        }

        parent.children.forEach(child => {
          if (child !== menu) {
            child.selected = false;
          }
        });
      },

      /**
       * @description 3Depth 선택 이벤트
       * @param menu 3Depth menu Data
       * @param parent 상위 메뉴 Data
       * @param event
       */
      goMenuUrl(menu, parent, event) {
        if (event.ctrlKey) {
          this.windowPopup = true;
          this.url = menu.pageUrl;
        } else {
          // 메뉴 이동 시, 하이라이트 추가
          menu.selected = true;
          parent.children.forEach(child => {
            if (child !== menu) {
              child.selected = false;
            }
          });

          if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl != null) {
            // 링크 메뉴
            this.windowPopup = true;
            this.url = this.makeUrl(menu.pageUrl);
          } else {
            this.$router.push(menu.pageUrl);
          }
        }
      },

      /**
       * @description 즐겨찾기 메뉴 추가/삭제 처리
       * @param event 이벤트객체
       * @param menu 선택메뉴
       * @param favoriteFl 즐겨찾기여부
       */
      async handleFavoriteMenu(event, menu, favoriteFl) {
        event.stopPropagation();

        const payload = {
          actionName: `MENU_FAVORITE_${favoriteFl ? 'SAVE' : 'DELETE'}`,
          data: [{ menuId: menu.id }],
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);

        if (isSuccess(res)) {
          this.favoriteMenuChgFl = true;
          await this.$store.dispatch('INIT_FAVORITE_MENU');
          menu.favoriteFl = favoriteFl;
          const favoriteMenu = this.makeTreeData(this.setFavoriteMenu(this.$store.getters.getMenuList))[0];
          if (this.$store.getters.getFavoriteMenu.length === 0 && this.treeMenuList[0].id === 99999999) {
            this.treeMenuList.shift();
          } else if (this.treeMenuList[0].id === 99999999) {
            this.treeMenuList[0] = favoriteMenu;
          } else {
            this.treeMenuList = [...[favoriteMenu], ...this.treeMenuList];
          }
        } else {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /**
       * @description leftmenu mouseleave 이벤트
       */
      handleAsideMouseLeave() {
        if (!this.$store.getters.getIsShowMyLayerLayout) {
          this.$parent.getWrapElement().classList.remove('left-show');
        }
      },

      /**
       * @description 즐겨찾기 메뉴 셋팅
       * @param menuList 메뉴리스트
       */
      setFavoriteMenu(menuList) {
        const favoriteParentMenu = {
          id: 99999999,
          menuDepth: 1,
          menuNm: '즐겨찾기',
          menuOrd: -1,
          menuTypeCd: enums.common.menuType.NORMAL_MENU.value,
          menuIcon: 'menu-favorite',
          viewFl: enums.common.stringViewFlag.YES.value,
          parentId: null,
          authUseFl: enums.common.stringViewFlag.YES.value,
        };
        const favoriteParentMenu2 = {
          id: 999999999,
          menuDepth: 2,
          menuNm: '나의 즐겨찾기',
          menuOrd: -1,
          menuTypeCd: enums.common.menuType.NORMAL_MENU.value,
          menuIcon: null,
          viewFl: enums.common.stringViewFlag.YES.value,
          parentId: favoriteParentMenu.id,
          authUseFl: enums.common.stringViewFlag.YES.value,
        };

        return this.$store.getters.getFavoriteMenu
          .map(item => item.menuId)
          .reduce(
            (result, id) => {
              const findMenu = menuList.find(menu => menu.id === id);
              if (!isEmpty(findMenu)) {
                result.push({ ...findMenu, parentId: favoriteParentMenu2.id, menuDepth: 3 });
                findMenu.favoriteFl = true;
              }
              return result;
            },
            [favoriteParentMenu, favoriteParentMenu2],
          );
      },
      getStoreData(keys) {
        const storeState = this.$store.state;
        const result = {};
        keys.forEach(key => {
          if (Object.prototype.hasOwnProperty.call(storeState['user'], key)) {
            result[key] = storeState['user'][key];
          }
        });
        return result;
      },
      getUrl(url, type) {
        const obj = new URL(url);
        return obj[type];
      },
      makeQuery(data) {
        const params = new URLSearchParams(data);
        return params.toString();
      },
      makeUrl(url) {
        const keys = this.getUrl(url, 'search').replace('?', '').split(',');
        const hardQueryString = keys.filter(key => key.indexOf('=') > -1);
        const hardKeys = hardQueryString.reduce((map, item) => {
          const [key, value] = item.split('=');
          map[key] = value;
          return map;
        }, {});
        const data = this.getStoreData(keys);
        const queryString = this.makeQuery({ ...data, ...hardKeys });
        const port = this.getUrl(url, 'port') ? ':' + this.getUrl(url, 'port') : '';

        console.log('##############################################');
        console.log(
          this.getUrl(url, 'protocol') + '//' + this.getUrl(url, 'hostname') + port + this.getUrl(url, 'pathname') + '?' + queryString,
        );
        return this.getUrl(url, 'protocol') + '//' + this.getUrl(url, 'hostname') + port + this.getUrl(url, 'pathname') + '?' + queryString;
      },
      /**
       * 메뉴 아이콘 SRC 반환
       *
       * @param value
       * @return {*}
       */
      getIconSrc(value) {
        if (!value) {
          return '';
        }

        const icon = this.menuIcons.find(icon => icon.value === value);
        if (icon) {
          return icon?.src;
        } else if (value === 'menu-favorite') {
          return require('@/assets/images/menu-favorite.svg');
        }
        return '';
      },
      /**
       * @description : 메뉴 리스트를 받아와서 TreeData 객체로 변환하는 함수
       *                사용 페이지 : /layouts/LeftMenu.vue
       * @param {*} items
       * @param {*} id
       * @param {*} link
       * @returns items
       */
      makeTreeData(items, id = null, link = 'parentId') {
        return items.filter(item => item[link] === id).map(item => ({ ...item, children: this.makeTreeData(items, item.id) }));
      },
    },
    mounted() {
      this.menuIcons = this.$_theme.menuIcons;
      this.treeMenuList = this.setTreeMenuList();
    },
  };
</script>

<style scoped>
  .wrap .pannel-group {
    display: none;
  }

  .wrap.left-show .pannel-group {
    display: block;
  }

  .side-obj:after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 14px;
    width: 44px;
    height: 1px;
    background: #e9ecef;
  }

  .slide-enter-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 500px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
    padding: 0 8px 0 17px !important;
  }
</style>
