<!--
  PACKAGE_NAME : src/pages/report/bi/db-data-table.vue
  FILE_NAME : db-data-table.vue
  AUTHOR : kwmoon
  DATE : 2024-09-17
  DESCRIPTION : BI 데이터 시트 추가 팝업 내 DB 데이터 영역
-->
<template>
  <table class="table_form line-bin">
    <colgroup>
      <col style="width: 150px" />
      <col style="width: auto" />
    </colgroup>
    <tbody>
      <tr>
        <th class="th-bold">BI 데이터</th>
        <td>
          <DxSelectBox
            class="mx-3"
            placeholder="BI 데이터 선택"
            :items="biDataList"
            display-expr="description"
            v-model="selectedBiId"
            value-expr="id"
            :styling-mode="stylingMode"
            :width="280"
            :height="32"
          >
            <DxValidator validation-group="modalValidationGroup2">
              <DxRequiredRule message="BI 데이터를 선택해주세요." />
            </DxValidator>
          </DxSelectBox>
        </td>
      </tr>
      <tr>
        <th class="th-bold">조회 기간</th>
        <td>
          <DateRangeBox ref="dateRangeBox" label="" :startDt="form.startDt" :endDt="form.endDt" />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import moment from 'moment/moment';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import { getResData, isSuccess } from '@/utils/common-lib';

  export default {
    components: {
      DateRangeBox,
      DxSelectBox,
      DxValidator,
      DxRequiredRule,
    },
    data() {
      return {
        form: {
          loginId: this.$store.getters.getLoginId,
          startDt: moment().format('YYYYMMDD'),
          endDt: moment().format('YYYYMMDD'),
          solution: '',
          subPath: '',
          reportType: '',
        },
        // BI 데이터
        selectedBiId: null,
        // BI XML 정의된 데이터 목록
        biDataList: [],
        stylingMode: 'outlined',
      };
    },
    methods: {
      /** 공통 관리 데이터 bi-report 타입 리스트 조회 */
      async fetchBiDataList() {
        const res = await this.CALL_REPORT_API({
          actionName: 'MASTER_QUERY_LIST',
          data: {
            type: 'bi-report',
          },
          loading: false,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }

        throw new Error('BI 데이터 리스트를 불러오는데 실패하였습니다.');
      },
      /** BiData 셋팅 */
      setBiDataList(list) {
        this.biDataList = list;
      },
      /** id 기준 BI 데이터 조회 */
      getBiDataFromId(id) {
        return this.biDataList.find(v => v.id === id);
      },
      /** Form 키, 값 셋팅  */
      setFormData(key, value) {
        this.form[key] = value;
      },
      /** 유효성 검증 */
      validate() {
        if (this.selectedBiId === null) {
          const errorMessagae = 'BI 데이터를 선택해주시기 바랍니다.';
          this.$_Msg(errorMessagae);
          throw new Error(errorMessagae);
        }
      },
      /**
       * @description 설정한 값 반환
       * @return { type, name, solution, subPath, reportType, startDt, endDt, description }
       * */
      getFormData() {
        this.validate();

        const biData = this.getBiDataFromId(this.selectedBiId);
        this.setFormData('type', 'bi-report');
        this.setFormData('name', biData.name);
        this.setFormData('description', biData.description);
        this.setFormData('solution', biData.solution);
        this.setFormData('subPath', biData.subPath);
        this.setFormData('reportType', biData.type);
        this.setFormData('startDt', this.$refs.dateRangeBox.getStartDate());
        this.setFormData('endDt', this.$refs.dateRangeBox.getEndDate());

        return this.form;
      },
    },
    async mounted() {
      const biDataList = await this.fetchBiDataList();
      this.setBiDataList(biDataList);
    },
    create() {},
    beforeMount() {},
  };
</script>
<style scoped>
  .th-bold {
    font-weight: 400;
  }
</style>
