<template>
  <div class="page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="page_search_box line_bottom_1px">
        <slot name="before"></slot>
        <div class="inner alL">
          <div class="ui-datepicker-item">
            <DxDateBox
              styling-mode="outlined"
              :width="120"
              v-model="searchType.customTypes.dayStart"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <div class="ui-datepicker period">
            <span class="dash">~</span>
          </div>
          <div class="ui-datepicker-item">
            <DxDateBox
              styling-mode="outlined"
              :width="120"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              display-format="yyyy.MM.dd"
              @value-changed="onChangeSearchDay"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
            >
            </DxDateBox>
          </div>
          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
        </div>
        <slot name="after"></slot>
      </div>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid" />
  </div>
</template>
<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { getPastFromToday, isSuccess } from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;
  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
    },
    watch: {},
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
          gradeList: null,
        },
        stylingMode: 'outlined', //outlined, underlined, filled

        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            menuSearchDropdown: {
              selectedRowsData: [],
              selectedRowKeys: [],
            },
            dayStart: getPastFromToday(30, 'days'),
            dayEnd: getPastFromToday(0, 'days'),
            regDt: null,
          },
          paramsData: null,
        },
        dataGrid: {
          callApi: 'CALL_EUC_API',
          excel: {
            title: 'MCID 장애이력',
          },
          refName: 'deviceGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '650', // 주석처리시 100%
          apiActionNm: {},
          showActionButtons: {
            excel: true,
            customButtons: [],
          },
          customEvent: {
            checkDataBeforeSaving: true,
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '모델',
              dataField: 'device.model',
              width: 120,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '내선번호',
              dataField: 'device.uniqueId',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowExporing: true,
              fixed: false,
              fixedPosition: 'left',
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  vm.onUpdateData(options.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '교환기',
              dataField: 'svrAes.aes.cm.cmNm',
              width: 120,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
            },
            {
              caption: 'IP',
              dataField: 'ip',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '포트',
              dataField: 'port',
              width: 200,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '에러 메시지',
              dataField: 'description',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '에러 발생일',
              dataField: 'regDt',
              width: 250,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: pageData -> pageSetting apiActionNm 가져오기 */
      getApiActionNm() {
        return this.config.pageSetting.config?.apiActionNm || null;
      },
    },
    methods: {
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        this.dataGrid.showActionButtons.customButtons = this.getCustomButtons();
        vm = this;
      },
      getCustomButtons() {
        const buttons = [];

        const isRestart = this.$_getSystemData('use_device_restart').configValue === 'true';
        if (isRestart) {
          buttons.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: '재시작',
              elementAttr: { class: 'btn_XS default filled ' },
              width: 80,
              height: 30,
              onClick: () => {
                this.onDeviceRestart();
              },
            },
            location: 'before',
          });
        }
        return buttons;
      },

      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.onChangeSearchDay();
        this.selectDataList();
      },
      /** @description : 단말기 재시작 실행 처리 메서드 */
      onDeviceRestart() {
        const selectedRowsData = this.$refs.deviceGrid.selectedRowsData;

        if (!selectedRowsData.length) {
          this.$_Msg('단말기를 선택해주세요.');
          return false;
        }

        const adimPort = this.$_getSystemData('adim_port')?.configValue;
        const groups = selectedRowsData.reduce((acc, obj) => {
          const { svrAes, appuser } = obj.device;
          const { svr, aes } = svrAes;
          const url = `${svr?.svrIp}:${adimPort}_${aes?.cm?.cmNm}_${appuser}`;
          acc[url] = acc[url] ?? [];
          acc[url].push(obj);
          return acc;
        }, {});

        let apiArry = [];
        for (const url in groups) {
          const params = url.split('_');
          const payload = {
            actionName: 'EUC_DEVICE_RESTART',
            data: {
              url: params[0],
              params: {
                INTERVAL: '5',
                STATIONS: groups[url].map(item => item.device?.uniqueId),
                CM: params[1],
                APPUSER: params[2],
              },
            },
            loading: false,
          };
          apiArry.push(this.CALL_EUC_API(payload));
        }
        Promise.all(apiArry).then(() => this.$_Toast('등록 되었습니다.'));
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        let params = this.searchType.paramsData || {};

        const payload = {
          actionName: 'EUC_MCID_HISTORY_LIST_ALL',
          data: params,
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);

        if (isSuccess(res)) {
          this.dataGrid.dataSource = res.data.data;
        }
      },

      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },

      /** @description : 수정 페이지 이동 */
      async onUpdateData(data) {
        const { device } = data;
        const { svrIp } = device.svr;

        const efgwPort = this.$_getSystemData('efgw_port')?.configValue;
        const payload = {
          actionName: 'EUC_DEVICE_DETAIL_INFO',
          data: {
            params: {
              url: `${svrIp}:${efgwPort}`,
              uniqueId: device.uniqueId,
            },
          },
          loading: false,
        };

        const res = await this.CALL_EUC_API(payload);
        const { result, fileData } = res.data;

        if (res.status === 200) {
          if (result === 'success') {
            this.$store.commit('setDetailParams', { deviceData: device, fileData });
            this.$router.push({ path: '../../device/detail' });
          } else if (result === 'not found') {
            this.$_Msg('해당 장치의 파일 데이터가 존재하지 않습니다.');
          } else {
            this.$_Msg('오류가 발생했습니다.');
          }
        } else {
          this.$_Msg('요청이 실패하였습니다.');
        }
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .page_search_box .inner div {
    display: inline-block;
  }
  .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
</style>