import AgentAppointmentList from '@/pages/ewm/hr/agent/appointment/list.vue';
import AgentManagementList from '@/pages/ewm/hr/agent/management/list.vue';
import HRComplaint from '@/pages/ewm/hr/complaint/index.vue';
import ManagementInterview from '@/pages/ewm/hr/management/interview/index.vue';
import ReportAttendance from '@/pages/ewm/hr/management/report/attendance/index.vue';
import ReportResignation from '@/pages/ewm/hr/management/report/resignation/index.vue';
import ReportWorkStats from '@/pages/ewm/hr/management/report/work-stats/index.vue';
import ReportWorktimeEdit from '@/pages/ewm/hr/management/report/worktime-edit/index.vue';
import ReportWorktimeSchedule from '@/pages/ewm/hr/management/report/worktime-schedule/index.vue';
import VacationSetting from '@/pages/ewm/hr/management/vacation-setting/index.vue';
import VacationType from '@/pages/ewm/hr/management/vacation-type/index.vue';
import WorkInfoList from '@/pages/ewm/hr/work/info/list.vue';
import WorkScheduleList from '@/pages/ewm/hr/work/schedule/list.vue';
import WorkTypeList from '@/pages/ewm/hr/work/type/list.vue';

export default function EwmHRRoutes(commonComponents) {
  return [
    // HR -> Agent
    {
      path: '/ewm/hr/agent/appointment/list',
      name: 'AgentAppointmentList',
      components: { default: AgentAppointmentList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/agent/management/list',
      name: 'AgentManagementList',
      components: { default: AgentManagementList, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // HR -> Complaint
    {
      path: '/ewm/hr/complaint',
      name: 'HRComplaint',
      components: { default: HRComplaint, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // HR -> Management
    {
      path: '/ewm/hr/management/interview',
      name: 'ManagementInterview',
      components: { default: ManagementInterview, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/attendance',
      name: 'ReportAttendance',
      components: { default: ReportAttendance, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/resignation',
      name: 'ReportResignation',
      components: { default: ReportResignation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/work-stats',
      name: 'ReportWorkStats',
      components: { default: ReportWorkStats, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/worktime-edit',
      name: 'ReportWorktimeEdit',
      components: { default: ReportWorktimeEdit, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/worktime-schedule',
      name: 'ReportWorktimeSchedule',
      components: { default: ReportWorktimeSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/vacation-setting',
      name: 'VacationSetting',
      components: { default: VacationSetting, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/vacation-type',
      name: 'VacationType',
      components: { default: VacationType, ...commonComponents },
      meta: { requiresAuth: true },
    },

    // HR -> Work
    {
      path: '/ewm/hr/work/info/list',
      name: 'WorkInfoList',
      components: { default: WorkInfoList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/work/schedule/list',
      name: 'WorkScheduleList',
      components: { default: WorkScheduleList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/work/type/list',
      name: 'WorkTypeList',
      components: { default: WorkTypeList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}