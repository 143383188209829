<!--
  PACKAGE_NAME : src\pages\ewm\hr\work\schedule\modal-page-worktype.vue
  FILE_NAME : appointment-selection-worktype
  AUTHOR : bykim
  DATE : 2024-01-18
  DESCRIPTION : 근무스케줄 근무유형 그리드 페이지
-->
<template>
  <div class="modal-container">
    <div :hidden="hiddenForm">
      <table class="table_form line-bin">
        <colgroup>
          <col style="width: 140px" />
          <col style="width: auto" />
          <col style="width: 140px" />
          <col style="width: auto" />
        </colgroup>
        <tbody>
          <tr>
            <th scope="row">
              <label for="label5">상담원</label>
            </th>
            <td colspan="1" class="clearfix">
              {{ agentState.agtNm }}
            </td>
            <th scope="row">
              <label for="label5">부서</label>
            </th>
            <td colspan="1" class="clearfix">
              {{ agentState.deptNmPath }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label5">근무일</label>
            </th>
            <td colspan="1" class="clearfix">{{ agentState.workDt }} ({{ agentState.workDtDayStr }})</td>
            <th scope="row">
              <label for="label5">일반근무</label>
            </th>
            <td colspan="1" class="clearfix" v-if="agentState.workBasicNm">
              {{ agentState.workBasicNm }}/ {{ agentState.workBasicStartTime }} ~ {{ agentState.workBasicEndTime }}
            </td>
          </tr>
          <tr>
            <th scope="row">
              <label for="label5">시간외근무</label>
            </th>
            <td colspan="1" class="clearfix" v-if="agentState.workOverTimeNm">
              {{ agentState.workOverTimeNm }}/ {{ agentState.workOverTimeStartTime }} ~
              {{ agentState.workOverTimeEndTime }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
    <div class="toolbar-bottom">
      <div class="toolbar-item">
        <dx-button
          text="이 전"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="handleBtnPagePrev"
          :visible="button.prev.visible"
        />
        <dx-button
          text="등 록"
          :width="120"
          :height="40"
          class="default filled txt_S medium"
          :use-submit-behavior="true"
          @click="handleBtnPageNext"
        />
        <dx-button text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="handleBtnPageClose" />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    components: { EspDxDataGrid, DxButton },
    props: {
      appointmentData: {
        default: () => ({
          deptCdList: [],
          agtIdList: [],
          startDate: null,
          endDate: null,
          pageName: 'modal-page-worktype',
          nextPageName: '',
          prevPageName: 'modal-page-select-agent',
          agentData: {},
          workCategory: {},
        }),
      },
    },
    data() {
      return {
        codeMap: {},
        isShowPage: false,
        hiddenForm: false, // 폼 컨텐츠 숨김 여부
        button: {
          prev: {
            visible: false,
          },
        },
        dataGrid: {
          keyExpr: 'workTypeId', // 그리드 키값
          refName: 'workTypeGridRef', // 그리드 컴포넌트 참조명
          callApi: 'CALL_EWM_API', // 그리드 데이터 호출 API
          dataSource: [], // 그리드 데이터
          height: this.hiddenForm ? 502 : 390, // 주석처리시 100%
          showActionButtons: {
            delete: false,
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          columnChooser: {
            enabled: false, // 컬럼 선택 팝업 표시 여부
          },
          loadPanel: {
            enabled: false, // 로딩 패널 표시 여부
          },
          sorting: {
            mode: 'multiple', // 정렬 모드 : ['none', 'single', 'multiple']
          },
          page: {
            enabled: false, // 페이징 사용 유무
          },
          filterRow: {
            visible: false, // 필터 행 표시 여부
          },
          headerFilter: {
            visible: false, // 헤더 필터 표시 여부
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false, // 삭제 버튼을 없애고 싶으면 false설정(Row 마다 휴지통 생성)
          },
          columns: [
            {
              caption: '근무명',
              dataType: 'string',
              dataField: 'workNm',
              alignment: 'left',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {},
            },
            {
              caption: '월',
              dataField: 'mondayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '화',
              dataField: 'tuesdayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '수',
              dataField: 'wednesdayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '목',
              dataField: 'thursdayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '금',
              dataField: 'fridayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '토',
              dataField: 'saturdayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '일',
              dataField: 'sundayWorkCd',
              allowHeaderFiltering: false,
              allowFiltering: false,
              lookup: {
                dataSource: this.$_enums.ewm.workStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, options) => this.dayCellTemplate(container, options),
            },
            {
              caption: '근무시간',
              dataField: 'workTime',
              allowHeaderFiltering: false,
              allowFiltering: false,
              calculateCellValue: e => {
                return `${e.workStartTime} ~ ${e.workEndTime}`;
              },
            },
          ], // 컬럼 정보
        },
        agentState: {
          agtNm: '',
          deptNmPath: '',
          workDt: '',
          workDtDayStr: '',
          workBasicNm: '',
          workBasicStartTime: '',
          workBasicEndTime: '',
          workOverTimeNm: '',
          workOverTimeStartTime: '',
          workOverTimeEndTime: '',
        },
      };
    },
    methods: {
      /**
       * @description 다음 페이지 이동
       */
      handleBtnPageNext() {
        let workTypeIdList = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowKeys();
        if (workTypeIdList.length === 0) {
          this.$_Toast('선택된 근무유형이 없습니다.');
          return;
        }

        let propData = this.appointmentData;
        propData.workTypeId = Array.isArray(workTypeIdList) ? workTypeIdList[0] : workTypeIdList;
        this.$emit('save', propData);
      },
      /**
       * @description 이전 페이지 이동
       */
      handleBtnPagePrev() {
        let propData = this.appointmentData;
        propData.prevPageName = 'modal-page-select-agent';
        propData.submitList = [];
        this.$emit('pagePrev', propData);
      },
      /**
       * @description 페이지 닫기
       */
      handleBtnPageClose() {
        this.$emit('pageClose');
      },
      /**
       * @description : 요일 셀렉트 박스 템플릿
       * */
      dayCellTemplate(container, options) {
        let element = document.createElement('div');
        element.innerHTML = options.text;
        element.style.fontWeight = 'bold';

        if (options.text === this.$_enums.ewm.workStatus.WORK_DAY.label) {
          element.style.color = '#089027';
        } else if (options.text === this.$_enums.ewm.workStatus.DAY_OFF.label) {
          element.style.color = '#f7665f';
        }
        container.append(element);
      },
      async onSearch() {
        let vm = this;
        let excludeWorkTypeId = [];
        if (
          vm.appointmentData?.agentData?.workBasicId &&
          this.$_enums.ewm.workCategory.BASIC.value === vm.appointmentData.workCategory.value
        ) {
          excludeWorkTypeId.push(vm.appointmentData.agentData.workBasicId);
        } else if (
          vm.appointmentData?.agentData?.workOverTimeId &&
          this.$_enums.ewm.workCategory.OVER_TIME.value === vm.appointmentData.workCategory.value
        ) {
          excludeWorkTypeId.push(vm.appointmentData.agentData.workOverTimeId);
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'workTypeId',
          async load() {
            const payload = {
              actionName: 'EWM_WORK_TYPE_LIST',
              data: {
                workCategory: vm.appointmentData.workCategory.value,
              },
              useErrorPopup: true,
            };

            if (excludeWorkTypeId.length > 0) {
              payload.data.workTypeId = `<>${excludeWorkTypeId.join(',')}`;
            }

            if (vm.appointmentData?.agentData?.workDt) {
              payload.data.workDt = (vm.appointmentData?.agentData?.workDt).replace('-', '').replace('-', '');
            }

            const res = await vm.CALL_EWM_API(payload);
            if (isSuccess(res)) {
              return res.data.data;
            }
          },
          insert(values) {
            //return false;
          },
          update(key, values) {
            //return false;
          },
          totalCount: opts => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
    },
    created() {},
    mounted() {
      this.onSearch();
      this.hiddenForm = this.appointmentData.agentData === undefined || this.appointmentData.agentData === null;

      this.agentState.agtNm = this.appointmentData?.agentData?.agtNm;
      this.agentState.deptNmPath = this.appointmentData?.agentData?.deptNmPath;
      this.agentState.workDt = this.appointmentData?.agentData?.workDt;
      this.agentState.workDtDayStr = this.appointmentData?.agentData?.workDtDayStr;
      this.agentState.workBasicNm = this.appointmentData?.agentData?.workBasicNm;
      this.agentState.workBasicStartTime = this.appointmentData?.agentData?.workBasicStartTime;
      this.agentState.workBasicEndTime = this.appointmentData?.agentData?.workBasicEndTime;
      this.agentState.workOverTimeNm = this.appointmentData?.agentData?.workOverTimeNm;
      this.agentState.workOverTimeStartTime = this.appointmentData?.agentData?.workOverTimeStartTime;
      this.agentState.workOverTimeEndTime = this.appointmentData?.agentData?.workOverTimeEndTime;

      // 이전 페이지가 있을 경우 이전 버튼 활성화
      if (this.appointmentData.prevPageName !== undefined && this.appointmentData.prevPageName !== '') {
        this.button.prev.visible = true;
      }
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ecs-tab-box {
      margin: 0 5px;
    }

    .page-sub-box {
      padding: 5px;
    }
  }

  .modal-container {
    display: flex;
    flex-direction: column; /* 위에서 아래로 정렬 */
    height: 100%; /* 컨테이너가 부모에 꽉 차도록 설정 */
  }

  .toolbar-bottom {
    margin-top: auto; /* 푸터 영역처럼 항상 맨 아래로 위치 */
    padding-top: 10px; /* 추가 여백 */
    display: flex; /* Flexbox 적용 */
    justify-content: center; /* 자식 요소(버튼들)를 수평 중앙 정렬 */
  }

  .toolbar-item {
    gap: 8px;
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
  }
</style>
