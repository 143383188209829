<template>
	<div class="page-sub-box ewm">
		<esp-dx-data-grid :data-grid="dataGrid" ref="boardGrid"></esp-dx-data-grid>
	</div>
</template>

<script>
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import {formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

let vm = null;

export default {
	components: {
    	EspDxDataGrid,
		DxDateBox,
		DxButton,
		DxTextBox,

		DxValidator,
		DxValidationGroup,
		DxRequiredRule,
	},
	props: {},
	watch: {},
	data() {
		return {
			codes: {
				categoryCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			dataGrid: {
				keyExpr: 'id',
				refName: 'boardGrid',
				allowColumnResizing: true, //컬럼 사이즈 허용
				showBorders: false, //border 유무
				showColumnHeaders: true, //컬럼 헤더 유무
				showColumnLines: false, //컬럼 세로선 유무
				showRowLines: true, //컬럼 가로선 유무
				rowAlternationEnabled: false,
				hoverStateEnabled: true,
				dataSource: [],
				// width:'200',     // 주석처리시 100%
				height: 'calc(100vh - 370px)', // 주석처리시 100%
				showActionButtons: {
					// 그리드 버튼 노출 설정값
					customButtons: [
						{
							widget: 'dxButton',
							sortIndex: 10,
							options: {
							icon: '',
							text: '조회',
							elementAttr: { class: 'btn_XS default filled search' },
							width: 60,
							height: 30,
							onClick() {
								vm.selectDataList();
							},
							},
							location: 'before',
						},
						// {
						// 	widget: 'dxButton',
						// 	options: {
						// 		icon: '',
						// 		text: '추가',
						// 		elementAttr: { class: 'btn_XS default filled add1' },
						// 		width: 60,
						// 		height: 30,
						// 		onClick() {
						// 			vm.onAddData();
						// 		},
						// 	},
						// 	location: 'before',
						// },
						// {
						// 	widget: 'dxButton',
						// 	options: {
						// 		icon: '',
						// 		text: '삭제',
						// 		elementAttr: { class: 'btn_XS white light_filled trash' },
						// 		width: 60,
						// 		height: 30,
						// 		onClick() {
						// 			vm.onDeleteData();
						// 		},
						// 	},
						// 	location: 'before',
						// },
					],
				},
				callApi: 'CALL_EWM_API',
				grouping: {
					contextMenuEnabled: false,
					autoExpandAll: false,
					allowCollapsing: false,
					expandMode: 'rowClick', // rowClick or buttonClick
				},
				groupPanel: {
					visible: false,
				},
				columnChooser: {
					enabled: false, // 컬럼 Chooser 버튼 사용유무
				},
				loadPanel: {
					enabled: true, // 로딩바 표시 유무
				},
				scrolling: {
					// 미사용시 주석처리
					mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
				},
				remoteOperations: {
					filtering: false,
					sorting: false,
					grouping: false,
					paging: false,
				},
				paging: {
					// scrolling 미사용시만 적용됨
					enabled: false,
					pageSize: 10,
					pageIndex: 0, // 시작페이지
				},
				pager: {
					visible: false, //페이저 표시 여부
					showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
					allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
					displayMode: 'compact', //표시 모드 : ['full', 'compact']
					showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
					showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
				},
				filterRow: {
					visible: true,
				},
				headerFilter: {
					visible: true,
				},
				editing: {
					allowUpdating: false,
					allowDeleting: false,
					allowAdding: false,
					mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
					startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
					selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
				},
				selecting: {
					mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
					selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
					showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
				},
				columns: [
					{
						caption: '번호',
						dataField: 'ord',
						alignment: 'center', // left center right
						visible: true,
						width: 80,
						allowHeaderFiltering: true,
						allowSorting: true,
						cellTemplate: (container, options) => {
							const today = new Date();
							const startDate = new Date(options.data.typeValidFrDt);
							const endDate = new Date(options.data.typeValidToDt);

							if(options.data.typeCd == 1 && today >= startDate && today <= endDate){ 
								const stateEl = document.createElement('div');

								stateEl.textContent = '중요';
								stateEl.style.display = 'inline-block';
								stateEl.style.padding = '4px 10px';
								stateEl.style.border = '2px solid #008000'; 
								stateEl.style.borderRadius = '8px'; 
								stateEl.style.backgroundColor = '#b2f2bb'; 
								stateEl.style.color = '#008000'; 
								stateEl.style.fontWeight = 'bold'; 
								stateEl.style.fontSize = '14px';
								stateEl.style.textAlign = 'center';

								container.append(stateEl);
							}else{
								container.textContent = options.value || '';
							}
						},
					},
					{
						caption: '카테고리',
						dataField: 'categoryCd',
						alignment: 'center', // left center right
						visible: true,
						width: 130,
						allowHeaderFiltering: true,
						allowSorting: true,
						lookup: {},
					},
					{
						caption: '제목',
						dataField: 'title',
						alignment: 'left', // left center right
						visible: true,
						allowHeaderFiltering: false,
						allowSorting: true,
						cellTemplate: (container, options) => {
							let aTag = document.createElement('a');
							aTag.innerText = options.value;
							aTag.addEventListener('click', () => {
								vm.onDetailData(options.data);
							});
							container.append(aTag);
						},
					},
					{
						caption: '댓글',
						dataField: 'commentCnt',
						alignment: 'center', // left center right
						visible: true,
						width: 80,
						allowHeaderFiltering: false,
						allowSorting: true,
					},
					{
						caption: '첨부',
						dataField: 'fileGroupId',
						alignment: 'center', // left center right
						visible: true,
						width: 90,
						allowHeaderFiltering: false,
						allowFiltering: false,
						allowSorting: false,
						cellTemplate: (container, options) => {
							if (!options.data.fileGroupId) {
								let aTag = document.createElement('div');
								aTag.innerText = `-`;
								container.append(aTag);
							} else {
								const button = new DxButton({
									propsData: {
										icon: 'download',
										value: options.data.fileGroupId,
										onClick: () => {
											vm.onDownData(options.data.fileGroupId);
										},
									},
								});
								button.$mount();
								container.append(button.$el);
							}
						},
					},
					{
						caption: '등록자',
						dataField: 'regNm',
						alignment: 'center',
						visible: true,
						width: 150,
						allowHeaderFiltering: false,
						allowSorting: true,
						calculateCellValue: rowData => {
							return `${rowData.regNm} [${rowData.regId}]`;
						},
					},
					{
						caption: '등록일시',
						dataField: 'regDt',
						alignment: 'center',
						visible: true,
						width: 150,
						//sortOrder: 'desc',
						allowHeaderFiltering: false,
						allowSorting: true,
						dataType: 'date',
						format: 'yyyy-MM-dd HH:mm:ss',
					},
				],
			},
		};
	},
	computed: {},
	methods: {
		onDetailData(data) {
			const index = this.dataGrid.dataSource.findIndex(i => i.id === data.id);
			let params = { total: this.dataGrid.dataSource, index: index, personalCheck: true };
            console.log(params)
			this.$store.commit('setDetailParams', params);
			this.$router.push({ path: '/ewm/op/board/detail' });
		},
		async onDownData(fileGroupId) {
			const fileList = await this.$_getListAttachFile(fileGroupId);

			if (!fileList || fileList.length === 0) {
				this.$_Msg('다운로드 할 파일이 없습니다.');
				return;
			}
			
			fileList.forEach(fileInfo => {
				this.$_downloadAttachFile(fileInfo.fileGroupId, fileInfo.fileName);
			});
		},
		async onSseCheck(){
      		//임시 sse 연결 (삭제시 전체)
			const ssePayload = {
				actionName: 'EWM_SSE_CONNECT_USERS',
				loading: false,
			};
			const sseRes = await vm.CALL_EWM_API(ssePayload);
			if (isSuccess(sseRes)) {
				// console.log('sseeeeeeeeeeeeeeeeeeeConnectUsersssssssss',sseRes.data.data);
			}
		},
		/** @description : 데이터 검색 메서드 */
		async selectDataList() {
			const payload = {
				actionName: 'EWM_PERSONAL_BOARD_LIST',
				data: {
					loginId: this.$store.getters.getLoginId,
				},
				loading: false,
			};   

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.dataGrid.dataSource = res.data.data;
			}
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_op_board_categorycd');
		},
		/** @description : 라이프사이클 created 호출되는 메서드 */
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.codes.categoryCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_op_board_categorycd'], 2);
				this.$refs.boardGrid.getInstance.columnOption('categoryCd', 'lookup', this.codes.categoryCd);
			});
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			this.selectDataList();
		},
	},
	created() {
		this.$router.afterEach((to, from) => {
			if (from.path === '/ewm/op/board/detail') {
				this.selectDataList(); 
			}
		});
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>