<!--
  PACKAGE_NAME : src/pages/cc/ivr/schedule/config/holiday.vue
  FILE_NAME : holiday
  AUTHOR : hmlee
  DATE : 2024-10-8
  DESCRIPTION : 운영스케줄관리 설정 - 공휴일설정 컴포넌트
-->
<template>
	<div>
    <div class="pb-1">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="handleInitNewRow" @saving="handleSaving"/>
    </div>

    <!-- 휴일 불러오기 팝업 -->
    <modal-holidaytp
      v-if="modal.holidaytp.visible"
      :is-open="modal.holidaytp.visible"
      :holidaytp-list="modal.holidaytp.data"
      @saveModal="handleSaveModal('holidaytp', $event)"
      @closeModal="handleCloseModal('holidaytp')"
    />
	</div>
</template>

<script>
import ModalHolidaytp from '@/pages/cc/ivr/schedule/config/modal-holidaytp.vue';
import { getPastFromToday } from '@/utils/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import { DxSwitch } from 'devextreme-vue/switch';
import moment from "moment/moment";

export default {
	components: {
    EspDxDataGrid,
    DxSwitch,
		ModalHolidaytp,
	},
	props: {
    /** @description: 선택된 요일 */
    checkedDays: {
      type: Array,
    },
    /** @description: 공휴일 설정 데이터 */
    holidayList: {
      type: Array,
    }
  },
  data() {
    return {
      modal: {
        holidaytp: {
          visible: false,
          data: {},
        },
      },
      isCheckedHoliday: false, // 공휴일 운영 여부
      formCheckedDays: [], // props 로컬 데이터
      formHolidayList: [], // props 로컬 데이터
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'holidayGrid',
        dataSourceDefaultSortColumn: '+holidayDt',
        dataSource: [],
        height: 'calc(100vh - 550px)', // 주석처리시 100%
        customEvent: {
          initNewRow: true, // 신규 행 추가 이벤트
          saving: true, // 신규 행 추가 이벤트
        },
        showActionButtons: { // 그리드 버튼 노출 설정값
          add: true,
          save: false,
          cancel: true,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                elementAttr: { class: 'btn_XS white light_filled trash' },
                width: 60,
                height: 30,
                onClick: () => this.handleDeleteData(),
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('COMMON.WORD.LOAD_HOLIDAY', { defaultValue: '휴일 불러오기' }),
                elementAttr: { class: 'btn_XS white light_filled' },
                height: 30,
                onClick: () => {
                  const holidayList = this.getHolidayList();
                  this.handleOpenModal('holidaytp', holidayList);
                }
              },
              location: 'before',
            },
          ],
        },
        page: {
          enabled: false,
        },
        columns: [
          {
            caption: 'ID',
            dataField: 'id',
            width: '150',
            visible: false,
          },
          {
            caption: '유형',
            i18n: 'COMMON.WORD.TYPE',
            dataField: 'holidayType',
            width: '150',
            lookup: {
              dataSource: this.$_enums.common.holidayType.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            requiredRule: {},
            tooltip: '고정 휴일 : 매년 일자 변동이 없는 휴일\n변동 휴일 : 음력, 임시 공휴일 등 매년 일자가 변경되는 휴일',
          },
          {
            caption: '공휴일명',
            i18n: 'COMMON.WORD.HOLIDAY_NAME',
            dataField: 'holidayNm',
            requiredRule: {},
          },
          {
            caption: '공휴일',
            i18n: 'COMMON.WORD.HOLIDAY',
            dataField: 'holidayDt',
            dataType: 'date',
            format: 'yyyy.MM.dd',
            requiredRule: {},
            cellTemplate: (container, options) => {
              const dateValue = moment(options.value, 'YYYYMMDD');
              const condition = options.data.holidayType === this.$_enums.common.holidayType.FIXED.value;
              const formattedDate = condition ? dateValue.format('MM.DD') : dateValue.format('YYYY.MM.DD');
              container.append(formattedDate);
            },
          },
          {
            caption: '운영여부',
            i18n: 'COMMON.WORD.WORK_FLAG',
            dataField: 'workFl',
            width: '10%',
            visible: this.isCheckedHoliday,
            allowEditing: false,
            calculateCellValue: this.workFl,
            cellTemplate: (container, options) => {
              const switchBtn = new DxSwitch({
                propsData: {
                  value: options.value,
                  onValueChanged: e => {
                    const workFl = e.value
                      ? this.$_enums.cc.stringHolidayWorkFlag.YES.value
                      : this.$_enums.cc.stringHolidayWorkFlag.NO.value;
                    options.component.cellValue(options.rowIndex, 'workFl', workFl);
                  },
                },
              });
              switchBtn.$mount();
              container.append(switchBtn.$el);
            },
          },
        ],
      },
    };
	},
  watch: {
    /** @description: 선택된 요일 */
    checkedDays: {
      handler(newValue) {
        this.formCheckedDays = [...newValue];
        this.setWorkFl();
      },
    },
    /** @description: 공휴일 설정 데이터 */
    holidayList: {
      handler(newValue) {
        this.formHolidayList = JSON.parse(JSON.stringify(newValue));
        if (this.$refs[this.dataGrid.refName]) {
          this.$refs[this.dataGrid.refName].getInstance.option('dataSource', this.formHolidayList);
        }
      },
      immediate: true,
    },
  },
	methods: {
    /** @description: 공휴일 리스트 가져오기 */
    getHolidayList() {
      return this.$refs[this.dataGrid.refName].getInstance.getVisibleRows()?.map(row => row.data)
        .map((holiday) => { //날짜 포맷
          holiday.holidayDt = moment(holiday.holidayDt).format('YYYYMMDD');
          return { ...holiday };
        });
    },
    /** @description: 공휴일 운영 visible 여부 */
    setWorkFl() {
      const holidayGrid = this.$refs[this.dataGrid.refName]?.getInstance;
      if (this.formCheckedDays.some(day => day === this.$_enums.common.integerDayOfWeek.HOLIDAY.value)) {
        this.isCheckedHoliday = true;
      } else {
        this.isCheckedHoliday = false;
      }
      holidayGrid.columnOption('workFl', { visible: this.isCheckedHoliday }); //운영여부 항목 visible 설정
    },
    /** @description: 팝업 열기
     * @param settingType 설정 타입(holidaytp)
     * @param settingData 모달로 넘길 설정 데이터 */
    handleOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(holidaytp)
     */
    async handleSaveModal(settingType, holidaytpList) {
      holidaytpList.map(holiday => {
        holiday.holidayType = holiday.holidayTypeCd;
        holiday.holidayDt = holiday.holidayTm;
        holiday.id = null;

        return { ...holiday };
      });

      holidaytpList.forEach(row => {
        this.$refs[this.dataGrid.refName].getInstance.addRow();
      });

      holidaytpList.forEach((obj, index) => {
        Object.entries(obj).forEach(([key, value]) => {
          this.$refs[this.dataGrid.refName].getInstance.cellValue(index, key, value);
        });
      });

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};

      this.$refs[this.dataGrid.refName].refreshData();
    },
    /** @description : 팝업 닫기
     * @param settingType : 설정 타입(notice, dnis)
     **/
    handleCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description: 그리드 행 추가시 초기 설정 */
    handleInitNewRow(e) {
      e.data.holidayType = this.$_enums.common.holidayType.FIXED.value;
      e.data.holidayDt = getPastFromToday(0, 'days');
      if (this.isCheckedHoliday) { //운영여부 설정
        e.data.workFl = false;
      }
    },
    /** @description: 그리드 저장 이벤트 */
    handleSaving() {
      const holidayList = this.getHolidayList();
      this.$emit('saving', holidayList);
    },
		/** @description : 공휴일설정/특별운영시간설정 휴일 삭제 메서드 */
		async handleDeleteData() {
      const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
      if (selectedRowsData.length === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return;
      }

      //삭제된 데이터 제외하여 다시 설정
      const listData = this.$refs[this.dataGrid.refName].getInstance.getVisibleRows()
        .map(d => d.data).filter(gridData => !selectedRowsData.map(rowData => rowData.id).includes(gridData.id));
      this.$refs[this.dataGrid.refName].getInstance.option('dataSource', listData);
		},
		/** @description: 공휴일설정 운영여부 출력 */
		workFl(rowData) {
			return rowData.workFl === this.$_enums.cc.stringHolidayWorkFlag.YES.value;
		},
	},
  mounted() {
    this.setWorkFl();  //공휴일 운영여부
  },
};
</script>
