import Vue from 'vue';
import EspLoader from '@/components/common/esp-loader.vue';

const DEFAULT_POSITION = 'body';
const DEFAULT_PROPS = {
  visible: false,
  hideOnOutsideClick: true
};
const loaders = new Map();

export default {
  show(text = '', selector = DEFAULT_POSITION, props = {}) {
    const loader = this.getOrCreateLoader(selector, props);
    loader.show(text);
  },

  hide(selector = DEFAULT_POSITION) {
    const loader = loaders.get(selector);
    if (loader) {
      loader.hide();
    }
  },

  getOrCreateLoader(selector, props) {
    if (!loaders.has(selector)) {
      const loader = new Vue({
        ...EspLoader,
        parent: window.app, // 전역 Vue 인스턴스와 연결
        propsData: {
          ...DEFAULT_PROPS,
          ...props,
          targetSelector: selector,
        },
      }).$mount();

      // 타겟 엘리먼트 찾기
      const targetEl = document.querySelector(selector);
      if (targetEl) {
        // 타겟 엘리먼트 안에 로더 추가
        targetEl.appendChild(loader.$el);
      } else {
        // 타겟을 찾지 못한 경우 body에 추가
        document.body.appendChild(loader.$el);
      }

      loaders.set(selector, loader);
    }
    return loaders.get(selector);
  },
};
