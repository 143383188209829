import DxDateBox from 'devextreme/ui/date_box';
import DxTextBox from 'devextreme/ui/text_box';
import DxSelectBox from 'devextreme/ui/select_box';
import {loadMessages, locale} from 'devextreme/localization';
import koMessages from "@/configs/devextreme/ko.json"; // DevExpress 관련 설정
import '@/assets/css/dx.material.emerald.light.compact.css';

const DevExtremePlugin = {
  install(Vue, {store, i18n}) {

    // 한국어 메시지를 DevExtreme에 로드(기본적으로 한국어가 없으므로 커스텀한 json 로드해야함)
    loadMessages(koMessages);

    DxSelectBox.defaultOptions({
      options: {
        placeholder: i18n.t('COMPONENTS.SELECT', { defaultValue: '선택' }),
      },
    });

    // TextBox 기본 옵션 설정
    DxTextBox.defaultOptions({
      options: {
        stylingMode: 'outlined',
      },
    });

    // DxDateBox 기본 옵션 설정
    DxDateBox.defaultOptions({
      options: {
        onInitialized: (e) => {
          const element = e.element;

          // https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxDateBox/Configuration/#disabledDates
          // 커스텀 캘린더 클래스가 없는 경우에만 추가적인 설정 적용(사용자가 직접 설정할떄 해당 클리스 적용!)
          if (!element.classList.contains('custom-calendar')) {
            e.component.option('calendarOptions', {
              cellTemplate: container => {
                // 기본적으로 기본 텍스트 출력
                let span = document.createElement('span');
                span.innerText = container.text;

                // month 뷰인 경우에만 추가적인 스타일링 로직 적용(휴일 및 오늘 날짜)
                if (container.view === 'month') {
                  const date = container.date;
                  const formatDate = date.toISOString().split('T')[0];
                  const today = new Date().toISOString().split('T')[0];

                  if (formatDate === today) {
                    span.classList.add('today');
                    return span;
                  }

                  if (date.getDay() === 6) {
                    span.classList.add('weekend');
                    return span;
                  }

                  // vuex store에서 휴일 정보를 가져와서 휴일인 경우 스타일을 변경
                  const matchedHoliday = getMatchedHoliday(date, store.getters.getHolidayMap);

                  if (date.getDay() === 0 || matchedHoliday) {
                    span.classList.add('holiday');

                    if (matchedHoliday?.field) {
                      span.setAttribute('title', matchedHoliday.field);
                    }
                  }
                }

                return span;
              },
            });
          }
        }
      },
    });

    // DevExtreme 구성 요소를 전역으로 등록
    Vue.component('DxTextBox', DxTextBox);
    Vue.component('DxDateBox', DxDateBox);
    Vue.component('DxSelectBox', DxSelectBox);

    // 브라우저의 언어 설정에 기반한 로케일 설정
    // TODO : 브라우저 언어 설정 기반 대신 사용자 설정 기반으로 변경되도록 수정필요.
    const browserLanguage = navigator.language.split('-')[0]; // 예: 'ko-KR' -> 'ko'
    console.log('browserLanguage:::', browserLanguage);
    locale(browserLanguage); // DevExtreme 관련 로케일 설정
  },
};

const getDateToFormattedDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 0부터 시작하므로 +1 필요
  const day = String(date.getDate()).padStart(2, '0'); // 1자리일 경우 0 추가
  return `${year}${month}${day}`;
};

/**
 * @description
 * @param date
 * @param holidayMap
 * @return {*|null}
 */
const getMatchedHoliday = (date, holidayMap) => {
  if (!holidayMap) {
    return null;
  }

  const formattedDate = getDateToFormattedDate(date);
  return holidayMap.get(formattedDate);
};

export default DevExtremePlugin;