import { EventBus } from '@/event-bus';
import { espChatApi } from '@/api';
import {getRoutingInfo, setRoutingPath} from './esp';
import { otherResponseConsoleLogger } from '../../api/error-handle';

/**
 * Esp Chat API 호출
 * @param state
 * @param payload
 * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<any, Record<string, any>>|AuthenticatorResponse>}
 * @constructor
 */
export const CALL_ESP_CHAT_API = async ({ state }, payload) => {
	let res;
	try {
		const routingInfo = getRoutingInfo({ state }, payload);

		// API 호출 시 필요한 정보
		const requestParam = {
			host: routingInfo.host,
			path: setRoutingPath(routingInfo?.path, payload?.path),
			timeout: payload.timeout || routingInfo.timeout,
			method: routingInfo.method,
			actionName: payload.actionName,
			data: payload.data,
			responseType: payload.responseType,
			headers: payload.headers,
		};

		payload.loading && EventBus.$emit('app:progress', true);

		res = await espChatApi.callApi(requestParam);

		otherResponseConsoleLogger(payload.actionName, res);

		payload.loading && EventBus.$emit('app:progress', false);
		return res;
	} catch (error) {
		if (payload.loading) EventBus.$emit('app:progress', false);
		return error.response || error;
	}
};
