<!--
  PACKAGE_NAME : src\pages\esp\system\code\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 공통코드 설정
-->
<template>
  <div class="page-container">
    <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName" @init-new-row="handleInitNewRow" @cell-prepared="handleCellPrepared" />
  </div>
</template>

<script>
  import EspDxTreeList from '@/components/devextreme/esp-dx-tree-list-v2.vue';
  import { isSuccess } from '@/utils/common-lib';

  export default {
    components: {
      EspDxTreeList,
    },
    data() {
      return {
        treeList: {
          keyExpr: 'codeId',
          refName: 'codeRefName',
          sortKey: 'codeOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          dataSourceDefaultSortColumn: '+codeOrd,+codeNm', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          showColumnLines: true, // 컬럼 라인 표시 여부
          apiActionNm: {
            select: 'CODE_LIST_ALL',
            update: 'CODE_LIST_MERGE',
          },
          showActionButtons: {
            select: true, // 조회
            delete: false, // 삭제 / false가 기본 / 버튼 노출시 멀티 셀렉션 모드로 변경
            sort: false, // 순서 저장 / true가 기본
            toggleExpand: true, // 목록 펼치기/접기 / true가 기본
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => this.deleteCode(),
                },
                location: 'before',
                sortIndex: 55,
              },
            ],
          },
          editing: {
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selection: {
            allowSelectAll: false, //헤더 체크박스 선택(전체선택) 허용 여부
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple', 'none']
            recursive: true, //상위 노드 선택시 하위 노드도 선택 여부(true: 하위 노드도 선택, false: 하위 노드 선택 안하고 독립적)
          },
          columns: [
            {
              i18n: 'COMPONENTS.CODE_VALUE',
              caption: '코드명',
              dataField: 'codeNm',
              alignment: 'left',
              requiredRule: {},
            },
            {
              i18n: 'COMPONENTS.CODE_VALUE',
              caption: '코드값',
              dataField: 'codeValue',
              requiredRule: {},
            },
            {
              i18n: 'COMPONENTS.GROUP_KEY',
              caption: '그룹키',
              dataField: 'codeKey',
              validationRules: [
                {
                  type: 'required',
                  message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                    value: this.$_lang('COMPONENTS.GROUP_KEY', { defaultValue: '그룹키' }),
                    defaultValue: '[그룹키] 은/는 필수값 입니다.',
                  }),
                },
                {
                  type: 'pattern',
                  pattern: /^[a-z_]+$/,
                  message: this.$_lang('COMMON.MESSAGE.LOWER_CASE_AND_UNDERSCORE_ONLY', {
                    defaultValue: '소문자와 언더스코어(_)만 입력 가능합니다.',
                  }),
                },
                {
                  type: 'custom',
                  validationCallback: e => {
                    // 'root_'만 입력한 경우
                    if (e.value.startsWith('root_') && e.value === 'root_') {
                      e.rule.message = this.$_lang('COMMON.MESSAGE.ENTER_ROOT_GROUP_KEY', {
                        defaultValue: 'ROOT 코드는 root_로 시작하는 그룹키가 필요합니다.',
                      });
                      return false;
                    }

                    // ROOT 코드인 경우
                    if (e.data.parentId === -1) {
                      e.rule.message = this.$_lang('COMMON.MESSAGE.ENTER_ROOT_GROUP_KEY', {
                        defaultValue: 'ROOT 코드는 root_로 시작하는 그룹키가 필요합니다.',
                      });
                      return e.value.startsWith('root_');
                    }

                    const allRows = this.$refs.codeRefName.getInstance.option('dataSource');
                    const currentParentId = e.data.parentId; // 현재 행의 parentId
                    const currentCodeKey = e.value; // 현재 행의 codeKey

                    // parentId가 같은 모든 행 필터링
                    const sameParentRows = allRows.filter(row => row.parentId === currentParentId && row.codeId !== e.data.codeId);

                    // 같은 parentId 그룹의 codeKey가 모두 동일한지 확인
                    const allCodeKeysMatch = sameParentRows.every(row => row.codeKey === currentCodeKey);

                    if (!allCodeKeysMatch) {
                      e.rule.message = this.$_lang('COMMON.MESSAGE.GROUP_KEYS_MUST_BE_SAME', {
                        defaultValue: '같은 그룹의 그룹키는 모두 동일해야 합니다.',
                      });
                      return false;
                    }
                    return true;
                  },
                  message: this.$_lang('COMMON.MESSAGE.VALIDATION_ERROR', { defaultValue: '유효성 검사 오류.' }),
                },
              ],
            },
            {
              i18n: 'COMPONENTS.ORDER',
              caption: '순서',
              dataField: 'codeOrd',
              dataType: 'number',
              requiredRule: {},
            },
            {
              i18n: 'COMPONENTS.USE_STATUS',
              caption: '사용여부',
              dataField: 'useFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              i18n: 'COMPONENTS.DESCRIPTION',
              caption: '설명',
              dataField: 'description',
            },
          ],
        },
      };
    },
    methods: {
      /**
       * @description 신규 행 초기화
       * @param e
       */
      handleInitNewRow(e) {
        e.data.useFl = this.$_enums.common.stringUsedFlag.YES.value;

        // ROOT 코드인 경우 코드값을 ROOT로 설정
        if (e.data.parentId === -1) {
          e.data.codeValue = 'ROOT';
          e.data.codeKey = 'root_';
          e.data.codeOrd = -1;
        }
      },
      /**
       * @description 셀 준비 이벤트
       * @param e
       */
      handleCellPrepared(e) {
        // ROOT 코드인 경우
        if (e.rowType === 'data' && e.data.parentId === -1) {
          // 코드값 편집 불가('ROOT'로 고정), 순서 편집 불가(-1로 고정)
          if (e.column.dataField === 'codeValue' || e.column.dataField === 'codeOrd') {
            e.cellElement.style.pointerEvents = 'none'; // 편집 불가
            e.cellElement.style.backgroundColor = '#f5f5f5';
            e.cellElement.style.color = '#888';
          }
        }
      },
      /**
       * @description 코드 삭제
       */
      async deleteCode() {
        const selectedCodes = this.$refs.codeRefName.getInstance.getSelectedRowKeys('all');

        if (selectedCodes.length === 0) {
          this.$_Msg(this.$_lang('CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다' }));
          return;
        }

        const payload = {
          actionName: 'CODE_LIST_DELETE',
          data: selectedCodes,
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
          await this.$refs.codeRefName.handleSelectData();
        }
      },
    },
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  .page-container {
    background: #fff;
    padding: 20px 20px 0 20px;
    border-radius: 10px 10px 0 0;
    z-index: 3;
  }
</style>
