import OpBoard from '@/pages/ewm/op/board/index.vue';
import OpBoardConfig from '@/pages/ewm/op/board/config.vue';
import OpBoardDetail from '@/pages/ewm/op/board/detail.vue';

export default function OpRoutes(commonComponents) {
  return [
    // OP -> Board
    {
      path: '/ewm/op/board',
      name: 'OpBoard',
      components: { default: OpBoard, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/op/board/config',
      name: 'OpBoardConfig',
      components: { default: OpBoardConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/op/board/detail',
      name: 'OpBoardDetail',
      components: { default: OpBoardDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}