import { render, staticRenderFns } from "./exception.vue?vue&type=template&id=25c7256f&scoped=true"
import script from "./exception.vue?vue&type=script&lang=js"
export * from "./exception.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c7256f",
  null
  
)

export default component.exports