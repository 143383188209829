<template>
  <header>
    <div class="header fr">
      <div class="header flex items-center justify-between w-full h-full">
        <div class="flex items-center h-full">
          <div
            class="w-full h-full bg-no-repeat bg-center bg-auto cursor-pointer flex items-center px-2"
            style="width: 320px"
            @click="$_goMainPage"
          >
            <img v-if="getLogoUrl" :src="getLogoUrl" style="max-width: 200px; max-height: 30px" @error="setDefaultLogo" alt="로고 이미지" />
            <top-esp-logo v-else alt="로고 이미지" />
          </div>
          <!-- 검색 -->
          <search-area />
        </div>

        <div class="header-inner flex items-center">
          <div class="header-logo">
            <img
              v-if="getCustomerLogoUrl"
              width="60"
              height="20"
              :src="getCustomerLogoUrl"
              @error="setDefaultCustomerLogo"
              alt="Customer Logo Image"
            />
            <img v-else src="@/assets/images/gnb_logo.png" />
          </div>
          <button
            type="button"
            class="btn-profile"
            :class="{ sel: profile.show }"
            title="내 프로필"
            style="display: flex; align-items: center; justify-content: center; height: auto; width: auto; left: -140px"
            @click="profile.show = !profile.show"
          >
            {{ $store.getters.getLoginId }}({{ $store.getters.getLoginNm }})
          </button>
          <!--프로필 클릭 팝업 정의 -->
          <div v-if="profile.show" class="my-profile show" v-click-outside="() => profile.show = false">
            <h3>{{ $store.getters.getLoginId }}({{ $store.getters.getLoginNm }})</h3>
            <ul :class="`clearfix grid gap-x-1 ${getAuthType === 'DEFAULT' ? 'grid-cols-2' : 'grid-cols-1'}`">
              <li v-if="getAuthType === 'DEFAULT'" class="w-full">
                <a class="cursor-pointer" @click="openProfile(true)">프로필 관리</a>
              </li>
              <li class="w-full">
                <a class="cursor-pointer" tabindex="-1" @click="logout">로그아웃</a>
              </li>
            </ul>
          </div>
          <div
            class="header-logo h-7 pr-3 dx-tooltip-target"
            style="left: -140px"
            data-toggle="tooltip"
            :title="this.sessionExpirationHoverMsg"
          >
            <!-- Lottie 컨테이너 -->
            <div
              ref="sessionLottieContainer"
              style="width: 26px; display: inline-block; height: 26px; line-height: 26px; vertical-align: middle"
            ></div>
            <!-- 기본 이미지 -->
            <img
              v-show="!isLoadedSessionLottieImg"
              src="@/assets/images/timer.png"
              width="26"
              height="auto"
              alt="로그인 타임아웃 이미지"
              style="position: absolute; top: 0; left: 0"
            />
            <span
              class="w-14 pl-2 inline-block text-left"
              style="position: relative; height: 26px; line-height: 26px; vertical-align: middle"
            >{{ getTokenExpirationTime }}</span
            >
          </div>
          <!-- 검색 -->
          <dx-button v-if="seconds < 10" class="btn-icon total-search" :height="28" @click="showExcelProgressbar" text="엑셀로딩"
          >엑셀
          </dx-button>

          <!-- 팝업 -->
          <esp-dx-modal-popup
            :option="{
              title: '내 정보관리',
              width: '700',
              height: '450',
              minWidth: '700',
              minHeight: '300',
            }"
            :isOpen="profile.status"
            @closeModal="openProfile(false)"
            @saveModal="saveProfile"
          >
            <template #content>
              <MyProfile ref="myProfile" />
            </template>
          </esp-dx-modal-popup>
          <!-- 팝업 -->

          <dx-popup
            :visible="popupVisible"
            :drag-enabled="false"
            :hide-on-outside-click="true"
            :show-close-button="false"
            :show-title="true"
            :width="300"
            :height="180"
            container=".dx-viewport"
            title="엑셀 다운로드 상태창"
          >
            <dx-position at="top" my="center" collision="fit" />
            <dx-toolbar-item widget="dxButton" toolbar="top" locate-in-menu="always" />
            <dx-toolbar-item widget="dxButton" toolbar="bottom" location="before" />
            <dx-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closeButtonOptions" />
            <dx-progress-bar
              id="progress-bar-status"
              :class="{ complete: seconds === 0 }"
              :min="0"
              :max="10"
              :status-format="statusFormat"
              :value="progressValue"
              width="90%"
            />
          </dx-popup>

          <!-- 알림창 -->
          <div class="header-right">
            <button type="button" class="btn-alim" title="알림" style="margin-left: 5px" @click="handleAlarmShow()">
              <span>알림</span>
              <i class="badge" v-if="hasUncheckedItems">{{ alarm.uncheckedList.length }}</i>
            </button>
            <!--검색 영역 오른쪽 컨포넌트 적용시 영역 정의-->
          </div>
          <alarm-drop-down-panel
            ref="myAlarm"
            @AlarmUnCheckedList="handleAlarmUnCheckedList"
            @UpdateAlarmChecked="handleUpdateAlarmChecked"
            v-click-outside="() => handleAlarmShow(false)"
          ></alarm-drop-down-panel>

          <div class="header-layout">
            <!--검색 영역 오른쪽 컨포넌트 적용시 영역 정의-->

            <button type="button" class="btn-layout" title="레이아웃" @click="toggleMyLayerLayoutPopup">
              <span class="hidden">레이아웃</span>
            </button>
            <transition name="slide">
              <div ref="element" v-if="isShowMyLayerLayout" class="layout-wrap" v-click-outside="closeMyLayerLayoutPopup">
                <div class="layout-header">
                  <div class="layout-header-title">레이아웃 설정</div>
                  <button type="button" class="btn-close-layout"><span class="hidden">닫기</span></button>
                </div>
                <div class="layout-cont" style="flex-flow: wrap">
                  <p>메뉴영역고정</p>
                  <div class="locker_switch_box clearfix">
                    <dx-switch @value-changed="handleSwitchChange" :value="isLayoutCheck" />
                  </div>
                  <div style="align-items: center; width: 100%; display: flex; top: 20px">
                    <p>메뉴 즐겨찾기</p>
                    <button class="btn_XS white" type="button" @click="handleOpenFavoriteMenuModal">설정</button>
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <!-- ESP Chat -->
          <div class="header-esp-chat">
            <button id="espChatBtn" type="button" title="ESP Chat" style="margin-left: 5px" @click="handleEspChatShow">
              <svg id="espChatSvg" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="espChatPath" d="M20 8C26.6276 8 32 13.3724 32 20C32 26.6276 26.6276 32 20 32C17.8138 32.0034 15.6686 31.4065 13.7984 30.2744L10.16 31.3448C9.95111 31.4063 9.72951 31.4103 9.51851 31.3565C9.30751 31.3027 9.11489 31.1931 8.96092 31.0391C8.80695 30.8851 8.6973 30.6925 8.6435 30.4815C8.58971 30.2705 8.59375 30.0489 8.65521 29.84L9.72561 26.2016C8.59407 24.3311 7.99723 22.1861 8.00001 20C8.00001 13.3724 13.3724 8 20 8ZM15.8 18.2C15.3226 18.2 14.8648 18.3896 14.5272 18.7272C14.1896 19.0648 14 19.5226 14 20C14 20.4774 14.1896 20.9352 14.5272 21.2728C14.8648 21.6104 15.3226 21.8 15.8 21.8C16.2774 21.8 16.7352 21.6104 17.0728 21.2728C17.4104 20.9352 17.6 20.4774 17.6 20C17.6 19.5226 17.4104 19.0648 17.0728 18.7272C16.7352 18.3896 16.2774 18.2 15.8 18.2ZM24.2 18.2C23.7226 18.2 23.2648 18.3896 22.9272 18.7272C22.5896 19.0648 22.4 19.5226 22.4 20C22.4 20.4774 22.5896 20.9352 22.9272 21.2728C23.2648 21.6104 23.7226 21.8 24.2 21.8C24.6774 21.8 25.1352 21.6104 25.4728 21.2728C25.8104 20.9352 26 20.4774 26 20C26 19.5226 25.8104 19.0648 25.4728 18.7272C25.1352 18.3896 24.6774 18.2 24.2 18.2Z" fill="var(--themeColor)"/>
              </svg>
              <span class="hidden">ESP Chat</span>
            </button>
          </div>
          <EspChatPanel
            ref="espChat"
            v-click-outside="(e) => handleEspChatShow(e, false, true)"
          />
        </div>
      </div>
      <!-- 시스템 탭 -->
      <system-tab />
    </div>
    <esp-dx-modal-popup
      :option="{
        title: '메뉴 즐겨찾기 설정',
        width: '750',
        height: '750',
        minWidth: '750',
        minHeight: '750',
        closeOnOutsideClick: false,
      }"
      :isOpen="menuFavorite.status"
      @closeModal="handleOpenFavoriteMenuModal"
      @saveModal="handleSaveFavoriteMenuModal"
    >
      <template #content>
        <div style="height: 600px; overflow-y: auto">
          <menu-favorite ref="menuFavorite" />
        </div>
      </template>
    </esp-dx-modal-popup>
  </header>
</template>

<script>
  import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
  import EspDxModalPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import SearchArea from '@/layouts/search-area.vue';
  import AlarmDropDownPanel from '@/pages/esp/system/alarm/drop-down-panel.vue';
  import EspChatPanel from '@/pages/ai/esp-chat/esp-chat-panel.vue';
  import MyProfile from '@/pages/esp/user/profile.vue';
  import MenuFavorite from '@/pages/esp/system/menu/favorite.vue';
  import { DxProgressBar } from 'devextreme-vue/progress-bar';
  import { SOCKET_ACTIONS } from '@/event/socket-action';
  import sessionTimeGif from '@/assets/images/session_time.json';
  import lottie from 'lottie-web';
  import { DxButton } from 'devextreme-vue/button';
  import DxSwitch from 'devextreme-vue/switch';
  import SystemTab from '@/layouts/system-tab';
  import TopEspLogo from '@/layouts/top-esp-logo.vue';

  export default {
    name: 'Top',
    components: {
      TopEspLogo,
      SystemTab,
      DxSwitch,
      DxButton,
      DxPopup,
      DxPosition,
      DxToolbarItem,
      DxProgressBar,
      MyProfile,
      MenuFavorite,
      EspDxModalPopup,
      AlarmDropDownPanel,
      SearchArea,
      EspChatPanel,
    },
    watch: {
      // 토큰 만료 시간시 로그아웃
      tokenExpirationTime(newValue) {
        if (newValue === 0) {
          this.$_Msg(this.$_lang('FORCE_LOGOUT'), { defaultValue: '일정한 시간동안 미사용하여 로그아웃 되었습니다.' });
          this.logout();
        }
      },
    },
    data() {
      return {
        seconds: 10,
        inProgress: false,
        wsConnected: false,
        wsReconnectFunc: null,
        popupVisible: false,
        closeButtonOptions: {
          text: 'Close',
          onClick: () => {
            this.popupVisible = false;
          },
        },
        profile: {
          show: false,
          status: false,
        },
        menuFavorite: {
          status: false,
        },
        alarm: {
          timer: 1500,
          throttle: {},
          uncheckedList: [],
        },
        isShowMyLayerLayout: false,
        isLayoutCheck: false,
        customLogoErrorHandled: false, // 커스텀 로고 이미지 에러 핸들링 여부
        logoErrorHandled: false, // 로고 이미지 에러 핸들링 여부
        tokenExpirationTime: 0, // 토큰 만료 남은 시간(초)
        maxTokenExpirationTime: null, // 최대 토큰 유효 시간(초)
        idleTime: 0, // 서버 토큰 갱신 프로세스 유휴 시간(초)
        tokenTimer: null,
        idleTimer: null,
        showLogoutWarning: false,
        // lottie 이미지 사용,
        sessionLottieImg: null,
        isLoadedSessionLottieImg: false,
        sessionExpirationHoverMsg: this.$_lang('COMMON.WORD.REMAINING_SESSION_TIME', {
          defaultValue: '남은 로그인 시간',
        }),
      };
    },

    computed: {
      /**
       * 로그인 만료 시간 조회
       * @return {string}
       */
      getTokenExpirationTime() {
        if (this.tokenExpirationTime <= 0) {
          return '00:00';
        }

        const hour = Math.floor(this.tokenExpirationTime / 3600);
        const minutes = Math.floor((this.tokenExpirationTime % 3600) / 60);
        const seconds = this.tokenExpirationTime % 60;

        // 시간이 있을경우, 시간단위까지 표출
        return [hour > 0 ? String(hour).padStart(2, '0') : null, String(minutes).padStart(2, '0'), String(seconds).padStart(2, '0')]
          .filter(Boolean)
          .join(':');
      },
      getAuthType() {
        return this.$_getSystemData('auth_type')?.configValue || 'DEFAULT';
      },
      /**
       * 토큰 만료 임박시 경고 시간
       * @return {string}
       */
      getTokenWarningTime() {
        const tokenWarningTime = this.$_getSystemData('token_warning_time')?.configValue;
        return tokenWarningTime ?? 300; // configValue 없을시 5분
      },
      /**
       * 고객 로고 이미지 URL
       * @return {string|string|string}
       */
      getCustomerLogoUrl() {
        const customerLogo = this.$store.getters.getThemeSetting?.customerLogo;
        return customerLogo ? this.$_getAttachFileURL(null, customerLogo) : '';
      },
      /**
       * 로고 이미지 URL
       * @return {string|string|string}
       */
      getLogoUrl() {
        const logoImg = this.$store.getters.getThemeSetting?.logoImg;
        return logoImg ? this.$_getAttachFileURL(null, logoImg) : '';
      },
      progressValue() {
        return 10 - this.seconds;
      },
      hasUncheckedItems() {
        return this.alarm.uncheckedList.length > 0;
      },
    },
    methods: {
      /**
       * 토큰 만료 시간 조회
       * @return {moment.Moment}
       */
      getCookieExpirationTime() {
        const token = this.$store.getters.getAccessToken;

        if (token) {
          const decodeToken = this.$jwt.decode(token);
          return this.$_commonlib.moment.unix(decodeToken.exp);
        }
      },
      // 상단 토큰 타이머 초기화
      initTokenExpirationTimer() {
        const expirationTime = this.getCookieExpirationTime();
        if (!expirationTime) {
          return;
        }
        // 토큰 만료시간 초기화
        this.initMaxTokenExpirationTime();

        this.tokenTimer = setInterval(() => this.updateTimer(), 1000);
        this.idleTimer = setInterval(() => this.idleTime++, 1000);

        // 토큰 만료시간 갱신 이벤트 추가
        ['keypress', 'click'].forEach(event => {
          document.addEventListener(event, this.resetTokenExpirationTime);
        });
      },
      // 토큰 만료시간 초기화
      initMaxTokenExpirationTime() {
        const configValue = this.$_getSystemData('token_expiation_time')?.configValue;
        this.maxTokenExpirationTime = configValue || 3600; // configValue 없을시 1시간
        this.tokenExpirationTime = this.maxTokenExpirationTime;
      },
      // 토큰 타이머 업데이트
      updateTimer() {
        // 토큰 만료시간 감소
        this.tokenExpirationTime = Math.max(0, this.tokenExpirationTime - 1);

        // 경고 시간 도달 시 로그아웃 경고 표시
        if (this.tokenExpirationTime > 0 && this.tokenExpirationTime < this.getTokenWarningTime && !this.showLogoutWarning) {
          this.showLogoutWarning = true;
          this.warningLogoutConfirm();
        }
      },
      // 토큰 만료 임박 시 경고 메시지 표시
      async warningLogoutConfirm() {
        if (
          await this.$_Confirm(
            this.$_lang('WARNING_FORCE_LOGOUT', { defaultValue: '잠시후에 자동 로그아웃됩니다. 로그인을 계속 유지하시겠습니까?' }),
          )
        ) {
          this.resetTokenExpirationTime();
          setTimeout(() => {
            this.showLogoutWarning = false;
          }, 2000);
        } else {
          await this.logout();
        }
      },
      statusFormat(ratio) {
        return `진행률 : ${ratio * 100}%`;
      },
      openMenu() {
        // 메뉴 element 가져오기
        const menu = document.querySelector('#myMenu');

        // 오픈여부 확인
        if (!menu.classList.contains('open')) {
          // 백드롭 항목 추가
          const backdrop = document.createElement('div');
          backdrop.className = 'backdrop backdrop-menu show';
          menu.parentNode.appendChild(backdrop);

          // 백드롭 클릭 시 메뉴 닫기
          backdrop.addEventListener('click', () => {
            this.closeMenu();
          });

          // 윈도우 크기에 맞게 높이 조절
          menu.style.height = window.innerHeight + 'px';

          // 메뉴 위치 결정
          let menuPosition = 'side-menu';
          if (menu.classList.contains('menu-right')) {
            menuPosition = 'side-menu-right';
            window.menu.position = 'right';
            const headers = document.getElementsByClassName('header');
            for (const header of headers) {
              if (!header.classList.contains('side-menu-right')) {
                header.classList.add('side-menu-right');
              }
            }
          }

          // 부모 요소에 메뉴 위치 클래스 추가
          const parent = menu.parentNode;
          if (parent.classList.contains('body')) {
            parent.classList.add(menuPosition);
          } else {
            document.getElementsByTagName('body')[0].classList.add(menuPosition);
          }
        }

        // 메뉴 요소에 open 클래스 추가
        menu.classList.add('open');
        // 메뉴 열려있는지 확인하는 이벤트 추가
        const openMenuEvent = new CustomEvent('openMenu', {
          detail: {
            menu: menu,
          },
        });
        document.dispatchEvent(openMenuEvent);

        // "firedCloseMenu" 이벤트가 트리거될 때 메뉴를 닫는 리스너 추가
        document.addEventListener('firedCloseMenu', () => {
          this.closeMenu();
        });
      },
      closeMenu() {
        const menu = document.querySelector('#myMenu');
        let backdrop = document.querySelector('.backdrop-menu');

        if (!menu.classList.contains('open')) return false;

        let closeMenuEvent = new CustomEvent('closeMenu', {
          detail: { menu },
        });
        document.dispatchEvent(closeMenuEvent);
        menu.classList.remove('open');
        backdrop.remove();

        const headers = document.getElementsByClassName('header');
        for (const header of headers) {
          header.classList.remove('side-menu-right');
        }

        const parentElement = menu.parentNode;
        if (parentElement.classList.contains('body')) {
          parentElement.classList.remove('side-menu');
        } else {
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('side-menu');
        }
      },
      openProfile(flag = true) {
        this.$refs.myProfile.resetAllInfo();
        this.profile.status = flag;
      },
      /**
       * @description 프로필 저장
       * @return {Promise<void>}
       */
      async saveProfile() {
        const closeFlag = await this.$refs.myProfile.updateProfile();
        if (closeFlag) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          if (this.$refs.myProfile.changePwdFlag) {
            await this.logout();
          } else {
            this.openProfile(false);
          }
        }
      },
      /**
       * @description 메뉴 즐겨찾기 모달 설정 버튼 클릭 이벤트
       */
      handleOpenFavoriteMenuModal(e) {
        this.$refs.menuFavorite.initData();
        this.menuFavorite.status = !this.menuFavorite.status;
      },
      /**
       * @description 메뉴 즐겨찾기 저장
       * @return {Promise<void>}
       */
      async handleSaveFavoriteMenuModal(e) {
        const { closeFlag, error, isChanged = false } = await this.$refs.menuFavorite.handleSaveFavoriteMenu();
        if (!closeFlag) {
          this.$_Msg(error);
          return;
        }
        if (isChanged) {
          await this.$store.dispatch('INIT_FAVORITE_MENU');
          await this.$_Msg(this.$_lang('CMN_SUC_UPDATE', { defaultValue: '정상적으로 변경되었습니다.' }));
        }
        this.handleOpenFavoriteMenuModal();
      },

      // profile end
      onButtonClick() {
        if (!this.inProgress) {
          if (this.seconds === 0) {
            this.seconds = 10;
          }
        }

        this.inProgress = !this.inProgress;
      },

      showExcelProgressbar() {
        this.popupVisible = true;
        this.onButtonClick();
      },

      showToastMsgByAlarm(alarm) {
        const myAlarm = this.$refs.myAlarm;

        if (this.excludeAlarmNoti(alarm)) {
          return;
        }

        this.$_Toast(myAlarm.templateAlarmTitle(alarm), { position: 'bottom-end', timer: this.alarm.timer, icon: 'info' });
      },
      excludeAlarmNoti(alarm) {
        let progressBar = alarm?.actionList?.find(action => action.actionType === 'PROGRESS_BAR') ?? undefined;
        return !!(progressBar && !(progressBar?.actionContent === 0 || progressBar?.actionContent === 100));
      },
      updateAlarmUncheckedCount(alarm) {
        const updateKey = alarm.updateKey;

        if (this.excludeAlarmNoti(alarm)) {
          return;
        }

        if (!this.alarm.uncheckedList.includes(updateKey)) {
          this.alarm.uncheckedList.push(updateKey);
        }
      },

      /**
       * 로그 아웃
       * @return {Promise<void>}
       */
      async logout() {
        await this.$store.dispatch('LOGOUT', {
          data: {
            loginId: this.$store.getters.getLoginId,
          },
        });
      },
      async wsConnect() {
        clearInterval(this.wsReconnectFunc);
        this.$store.dispatch('WEBSOCKET_CONNECT');
        this.stompClient = this.$store.getters.getStompClient;

        if (this.stompClient && this.stompClient.connected) {
          this.wsConnected = true;
          this.stompClient.ws.onclose = () => {
            console.log('소켓 연결 끊김, 재연결 시도 중...');
            this.wsConnected = false;
            this.wsReconnection();
          };
        } else {
          this.wsConnected = false;
          this.wsReconnection();
        }
      },
      wsDisconnect() {
        if (this.$store.getters.getStompClient && this.$store.getters.getStompClient.connected) {
          this.wsConnect();
        }
      },
      onAlarmMessageReceived(msg) {
        const myAlarm = this.$refs.myAlarm;
        const alarmList = JSON.parse(msg.body) ?? [];

        alarmList.forEach(alarm => {
          // 모달이 켜지있지 않으면 카운트올림
          if (!(myAlarm.alarmInfo?.isOpen ?? false)) {
            // 여기에 unchecked updateKey를 담고 그거에대한 길이로한다.
            this.updateAlarmUncheckedCount(alarm);
          }

          this.showToastMsgByAlarm(alarm);

          // insert 이벤트추가
          myAlarm.handleInsertNewAlarm(alarm);
        });
      },
      onStoreMessageReceived(msg) {
        const data = JSON.parse(msg.body);
        const { eventNm, payload } = data;
        if (typeof SOCKET_ACTIONS[eventNm] === 'function') {
          SOCKET_ACTIONS[eventNm](JSON.parse(payload));
        }
      },
      // 알림 체크 이벤트
      // FIXME: 알림 체크하는 로직 변경해야함 - 25/04/03 허민기 체크
      sendAlarmCheckMessage(ids) {
        const loginId = this.$store.getters.getLoginId ? this.$store.getters.getLoginId : 'unknown';

        if (this.stompClient && this.stompClient.connected) {
          const msg = { ids, loginId };
          this.stompClient.send('/app/alarm/update', JSON.stringify(msg), {});
        }
      },
      startWsReconnect() {
        this.wsReconnectFunc = setInterval(() => {
          if (!this.wsConnected) {
            this.wsConnect();
          }
        }, 5000);
      },
      wsReconnection() {
        this.wsConnected = true;
        this.wsReconnectFunc = setInterval(() => {
          const stompClient = this.$store.getters.getStompClient;
          if (stompClient?.connected) {
            console.log('Connection established');
          } else {
            this.wsConnect();
          }
        }, 5000); // 5초 후 재연결 시도
      },
      setUi() {
        // 쿠키에 저장된 스타일 설정 부여
        this.isLayoutCheck = this.readCookie('layerChecked') === 'true';
        this.toggleMyLayerLayout();
        // session 시간간체크 이미지 추가
        this.setSessionLottieUI();
      },
      // LottieUI 셋팅
      setSessionLottieUI() {
        try {
          this.sessionLottieImg = lottie.loadAnimation({
            container: this.$refs.sessionLottieContainer,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: sessionTimeGif,
          });

          this.sessionLottieImg.addEventListener('DOMLoaded', () => {
            this.isLoadedSessionLottieImg = true;
          });
        } catch (error) {
          console.error('setSessionLottieUI error', error);
          this.isLoadedSessionLottieImg = false;
        }
      },
      // 레이아웃 설정 팝업 표출
      toggleMyLayerLayoutPopup() {
        this.isShowMyLayerLayout = !this.isShowMyLayerLayout;
      },
      closeMyLayerLayoutPopup() {
        this.isShowMyLayerLayout = false;
      },
      /**
       * @description 메뉴 고정 여부 변경
       * @param e
       */
      handleSwitchChange(e) {
        this.isLayoutCheck = e.value;
        this.toggleMyLayerLayout();
        this.saveLayerLayoutToCookie();
      },
      // 레이아웃 설정 결과 토글
      toggleMyLayerLayout() {
        const wrap = document.querySelector('#wrap');
        const isRouteRoot = this.$route.path === '/';

        this.$store.commit('setIsShowMyLayerLayout', this.isLayoutCheck);

        wrap.classList.toggle('menuitemshow', this.isLayoutCheck);
        wrap.classList.toggle('left-show', this.isLayoutCheck && !isRouteRoot);
      },
      // 레이아웃 설정 시 쿠키 저장
      saveLayerLayoutToCookie() {
        const expirationDate = new Date();
        expirationDate.setMonth(expirationDate.getMonth() + 1); // 만료기간 한달
        document.cookie = `layerChecked=${this.isLayoutCheck}; expires=${expirationDate.toUTCString()}; path=/`;
      },

      // 페이지 호출 시 쿠키 읽어서 레이아웃 설정
      readCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) {
          return parts.pop().split(';').shift();
        }
      },
      // 사용자 이벤트 발생 시 토큰 만료시간 갱신
      resetTokenExpirationTime() {
        if (this.idleTime > 30) {
          // idleTime으로 서버 요청건수 조절(30초)
          this.$store.dispatch('REFRESH_TOKEN');
          this.idleTime = 0;
        } else {
          this.tokenExpirationTime = this.maxTokenExpirationTime;
        }
      },

      // alarm 관련추가 메소드
      handleAlarmUnCheckedList(list = []) {
        this.alarm.uncheckedList = [...list];
      },

      handleUpdateAlarmChecked({ ids }) {
        if (ids?.length) {
          this.sendAlarmCheckMessage(ids);
        }
      },
      handleAlarmShow(flag = !this.$refs.myAlarm?.alarmInfo?.isOpen) {
        this.$refs.myAlarm.toggleAlarm(flag);
      },

      /** ESP Chat Panel Show & Close */
      handleEspChatShow(e, flag = !this.$refs.espChat?.isOpen, outsideClick = false) {
        if (outsideClick) {
          if (e.target === document.querySelector('body') ||
            e.target === document.querySelector('#wrap') ||
            e.target === document.querySelector('#espChatBtn') ||
            e.target === document.querySelector('#espChatSvg') ||
            e.target === document.querySelector('#espChatPath') ||
            (
              document.querySelector('.dx-overlay-wrapper.dx-loadpanel-wrapper.dx-overlay-shader') !== null &&
              document.querySelector('.dx-overlay-wrapper.dx-loadpanel-wrapper.dx-overlay-shader').contains(e.target)
            )) {
            return;
          }
        }
        this.$refs.espChat.toggleEspChat(flag);
      },

      /**
       * 커스텀 로고 이미지 로드 실패 시 기본 이미지로 변경
       * @param e
       */
      setDefaultCustomerLogo(e) {
        if (!this.customLogoErrorHandled) {
          // 기본 이미지 못 불러올시 반복 호출 중지 로직
          e.target.src = require('@/assets/images/gnb_logo.png');
          this.customLogoErrorHandled = true;
        }
      },
      /**
       * 로고 이미지 로드 실패 시 기본 이미지로 변경
       * @param e
       */
      setDefaultLogo(e) {
        if (!this.logoErrorHandled) {
          // 기본 이미지 못 불러올시 반복 호출 중지 로직
          e.target.src = require('@/assets/images/ecslogo_top.png');
          this.logoErrorHandled = true;
        }
      },
    },
    async created() {
      await this.wsConnect();
      const encryptionTypeConfig = this.$store.getters.getSystemCodeList.find(system => system.configKey === 'encryption_type');
      this.$store.commit('setEncryptionType', encryptionTypeConfig?.configValue || 'SHA256'); // 로그인 암호화 타입 설정
    },
    mounted() {
      this.initTokenExpirationTimer();
      this.setUi();
    },
    beforeDestroy() {
      clearInterval(this.tokenTimer);
      clearInterval(this.idleTimer);
      ['keypress', 'click'].forEach(event => {
        document.removeEventListener(event, this.resetTokenExpirationTime);
      });
      clearInterval(this.wsReconnectFunc);
      this.wsDisconnect();
    },
  };
</script>
