<template>
  <div id="container">
    <div class="container-top-wrap">
      <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="cusmain-table-wrap pt-7">
          <ReportDataGrid :ref="targetGrid.refName" :gridInfo="targetGrid" :columns="targetGridColumns" :dataSource="reportRowData" />
          <DxTextArea :value="log" :styling-mode="config.stylingMode" :height="320" :readOnly="true" class="mt-5 mar_ri10 alB" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDate } from '@/utils/common-lib';
  import ReportDataGrid from '@/components/report/report-popup-grid.vue';
  import { DxTextArea } from 'devextreme-vue/text-area';
  export default {
    components: {
      DxTextArea,
      ReportDataGrid,
    },
    props: {
      reportRowData: {
        type: Array,
        default: () => [],
      },
      targetGridColumns: {
        type: Array,
        default: () => [],
      },
      log: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        targetGridCount: 0,
        targetGrid: {
          refName: 'targetGrid',
          size: {
            width: '100%',
            height: 100,
          },
          toolbar: {
            filter: false,
            excel: false,
            totalCount: false,
          },
          paging: false,
        },
        logBox: '',
        stylingMode: 'outlined', //outlined, underlined, filled
        gridRefName: 'grid',
      };
    },
    computed: {
      grid() {
        return this.$refs[this.gridRefName].instance;
      },
    },
    methods: {
      cellTemplateByColumnFmt(fmt) {
        let cellTemplate = null;
        if ('fmDate' === fmt) cellTemplate = 'dateTemplate';
        else if ('fmNumber' === fmt) cellTemplate = 'numberTemplate';
        else if ('fmPercent' === fmt) cellTemplate = 'percentTemplate';
        else if ('fmtime' === fmt) cellTemplate = 'timeTemplate';
        else if ('button' === fmt) cellTemplate = 'buttonTemplate';
        return cellTemplate;
      },
      convertBool(str) {
        return [true, 'true', 'TRUE'].includes(str);
      },
      fmtDate(value) {
        return formatDate(value, 'YYYYMMDD', 'YYYY-MM-DD');
      },
      fmtNumber(value) {
        return parseInt(value).toLocaleString('ko-KR');
      },
      fmtTime(seconds) {
        const hour = this.lPadZero(parseInt(seconds / 3600));
        const min = this.lPadZero(parseInt((seconds % 3600) / 60));
        const sec = this.lPadZero(parseInt(seconds % 60));
        return `${hour}:${min}:${sec}`;
      },
      lPadZero(num) {
        return num < 10 ? '0' + num : num;
      },
    },
    mounted() {
      this.logBox = this.log;
    },
  };
</script>
