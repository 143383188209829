import Pickup from '@/pages/euc/device/pickup';

export default function PickupRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/pickup',
            name: 'Pickup',
            components: { default: Pickup, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}