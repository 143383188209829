import Vue from 'vue';

/**
 * @description $_getDxDataGridParam 에서 사용되는 filter 적용
 * @param field
 * @param operator
 * @param value
 * @return {*|string}
 */
const setFilter = (field, operator, value) => {
  switch (operator) {
    case 'contains':
      return `%${value}%`;
    case '=':
      return value;
    case 'notcontains':
      return `!%${value}`;
    case 'startswith':
      return `${value}%`;
    case 'endswith':
      return `%${value}`;
    case '<>':
      return `<>${value}`;
    case '<=':
      return `<=${value}`;
    case '>=':
      return `>=${value}`;
    case '<':
      return `<${value}`;
    case '>':
      return `>${value}`;
  }
};

/**
 * @description dxValidator의 onValidated 이벤트 핸들러에서 발생하는  <br/>
 * 이벤트 객체를 원본 데이터(OriginalData) 객체로 변환합니다.
 * 작업이력 로그를 남기기 위해 필요한 객체를 만드는데 사용합니다.
 * @param e dxValidator의 이벤트 객체
 * @param originalData 원본 데이터 객체(사용자정의)
 */
export const convertValidatorEventToOriginalData = (e, originalData) => {
  // https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxValidator/Configuration/#onValidated 참고
  // 위 링크에서 e 객체의 속성을 확인할 수 있습니다. (동일한 객체만 변환 가능)
  const key = e.key;
  if (!originalData[key] && e?.oldData) {
    originalData[key] = { ...e.oldData };
  }
  return originalData;
};

/**
 * @description dxDataGrid의 onSaving 이벤트 핸들러에서 발생하는 이벤트 객체를 변환합니다.  <br/>
 * 작업이력 로그를 남기기 위해 필요한 객체를 만드는데 사용합니다.  <br/>
 * api 에서 해당 데이터가 update, insert 인지 확인할 수 있도록 isAdding 속성을 추가합니다.
 *
 * @param e dxDataGrid의 onSaving 이벤트 핸들러에서 발생하는 이벤트 객체
 * @param keyExpr dxDataGrid의 keyExpr 속성 값 (기본값: 'id')
 * @param originalData 원본 데이터 객체(사용자정의)
 * @return {{data: *[], workLog: {content: {}, preContent: {}}}}
 */
export const convertGridChangeEventToWorkLogData = (e, originalData = {}, keyExpr = 'id') => {
  // https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onSaving 참고
  // 위 링크에서 e 객체의 속성을 확인할 수 있습니다. (동일한 객체만 변환 가능)
  // https://js.devexpress.com/Vue/Documentation/ApiReference/Common_Types/grids/#DataChangeType 참고

  // 작업이력을 axios 요청으로 전송하기 위한 데이터 객체(호출 중간에 workLog 객체를 인터셉트하여 사용함)
  const payloadData = {
    data: [],
    workLog: { content: {}, preContent: {} },
  };

  e.changes.forEach((change, index) => {
    let dataKey = change.key; // 'insert' 타입일 경우 key 값이 dx에서 자동 생성됨(꼭 필요한 경우만 사용)
    let dataMap = change.data;

    if (change.type === 'update') {
      // 변경 전 데이터 확인
      const oldData = originalData[dataKey];
      dataMap = Object.assign({}, oldData, change.data);
      dataMap.isAdding = false; // 신규 추가 여부

      // 변경 전후의 내용을 기록
      payloadData.workLog.preContent[index] = oldData;
      payloadData.workLog.content[index] = dataMap;
    } else if (change.type === 'insert') {
      // 신규 생성 타입
      dataKey = null; // 신규 생성 시 key 값이 없음
      dataMap.isAdding = true; // 신규 추가 여부
      payloadData.workLog.preContent[index] = {};
      payloadData.workLog.content[index] = dataMap;
    } else if (change.type === 'remove') {
      // 해당 이벤트는 row에 휴지통 버튼을 활성화 한 경우 발생
      // 삭제 타입
      dataMap.isAdding = false; // 신규 추가 여부
      const oldData = originalData[dataKey];
      if (oldData) {
        payloadData.workLog.preContent[index] = oldData;
        payloadData.workLog.content[index] = {};
      }
    }

    dataMap[keyExpr] = dataKey;
    payloadData.data.push(dataMap);
  });

  return payloadData;
};

/**
 * @description dxDataGrid의 onSaving 이벤트 핸들러에서 발생하는 이벤트 객체를 변환합니다.  <br/>
 * 'update' 이벤트 발생 시, 변경된 데이터만을 추출하지 않고 전체 데이터를 추출합니다. <br/>
 * api 에서 해당 데이터가 update, insert 인지 확인할 수 있도록 isAdding 속성을 추가합니다.
 *
 * @param e dxDataGrid의 onSaving 이벤트 핸들러에서 발생하는 이벤트 객체
 * @param keyExpr dxDataGrid의 keyExpr 속성 값 (기본값: 'id')
 * @param hasManualId 수동으로 id를 입력하는 경우 true로 설정 (기본값: false)
 * @return {*[]}
 */
export const convertGridChangeEventToMergedData = (e, keyExpr = 'id', hasManualId = false) => {
  // https://js.devexpress.com/Vue/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onSaving 참고
  // 위 링크에서 e 객체의 속성을 확인할 수 있습니다. (동일한 객체만 변환 가능)
  // https://js.devexpress.com/Vue/Documentation/ApiReference/Common_Types/grids/#DataChangeType 참고

  const data = [];

  e.changes.forEach(change => {
    let dataKey = change.key; // 'insert' 타입일 경우 key 값이 dx에서 자동 생성됨(꼭 필요한 경우만 사용)
    let dataMap = change.data;

    if (change.type === 'update') {
      e.component
        .byKey(dataKey)
        .then(data => {
          dataMap = Object.assign(data, change.data);
        })
        .catch(error => {
          console.error(error);
        });
      dataMap.isAdding = false; // 신규 추가 여부
    } else {
      dataMap[keyExpr] = hasManualId ? dataMap[keyExpr] : null; // 수동으로 id를 입력하는 경우 입력된 key 값 사용, 아닌 경우 null(서버에서 생성)
      dataMap.isAdding = true; // 신규 추가 여부
    }
    data.push(dataMap);
  });
  return data;
};

/**
 * Vue 컴포넌트를 동적으로 생성하고 마운트하는 유틸리티 함수
 * @param {HTMLElement} container - 컴포넌트를 마운트할 컨테이너 요소
 * @param {Component} Component - 마운트할 Vue 컴포넌트
 * @param {Object} props - 컴포넌트에 전달할 props
 * @param {Object} events - 컴포넌트에 바인딩할 이벤트 핸들러
 * @param {Vue} context - Vue 인스턴스 컨텍스트 (this)
 * @returns {Vue} 마운트된 Vue 컴포넌트 인스턴스
 */
export const mountComponent = (container, Component, props = {}, events = {}, context = null) => {
  const element = document.createElement('div');
  container.append(element);

  const ComponentClass = Vue.extend(Component);
  return new ComponentClass({
    propsData: props,
    methods: events,
    parent: context,
  }).$mount(element);
};

/**
 * @description : dxDataGrid 에서 각 옵션에 해당하는 params 데이터를 반환
 * @param {*} loadOptions
 * @returns
 */
export const getDxDataGridParam = loadOptions => {
  let params = {};
  const options = ['take', 'skip', 'requireTotalCount', 'requireGroupCount', 'sort', 'filter', 'totalSummary', 'group', 'groupSummary'];

  options.forEach(i => {
    if (i in loadOptions && loadOptions[i]) {
      switch (i) {
        case 'take':
          params.pagesize = loadOptions[i];
          break;

        case 'skip':
          params.currentpage = Math.round(loadOptions[i] / params.pagesize + 1);
          break;

        case 'sort':
          params.sort = loadOptions[i].map(el => `${el.desc ? '-' : '+'}${el.selector}`).join(',');
          break;

        case 'group':
          params.selector = loadOptions[i][0].selector;
          break;

        case 'filter':
          if (Array.isArray(loadOptions[i][0])) {
            let depth1Op = loadOptions[i][1];

            loadOptions[i].forEach(el => {
              if (Array.isArray(el)) {
                let depth2Op = el[1];

                el.forEach(d => {
                  if (Array.isArray(d)) {
                    params[d[0]] = setFilter(d[0], d[1], d[2]);

                    if (depth2Op === 'or' && d[1] === '=') {
                      params[d[0]] += params[d[0]] && params[d[0]].split(',').length === 1 ? 'and,' + d[2] : ',' + d[2];
                    }
                  }
                });
              } else {
                params[el[0]] = setFilter(el[0], el[1], el[2]);

                if (depth1Op === 'or' && el[1] === '=') {
                  params[el[0]] += params[el[0]] && params[el[0]].split(',').length === 1 ? 'and,' + el[2] : ',' + el[2];
                }
              }
            });
          } else {
            params[loadOptions[i][0]] = setFilter(loadOptions[i][0], loadOptions[i][1], loadOptions[i][2]);
          }
          break;
      }
    }
  });

  if (!params.currentpage) {
    params.currentpage = 1;
  }

  Object.keys(params).forEach(el => {
    if (isNaN(params[el])) {
      params[el] = params[el].replace('undefined,', '');
    }
  });

  return params;
};

/**
 * @description : onRowClick 이벤트 dx-single-selection 클래스 추가 <br>
 * dxDataGrid의 onRowClick 이벤트 핸들러에서 발생하는 이벤트 객체를 변환합니다.
 *
 * @param e
 */
export const setGridSingleSelection = (e) => {
  let allSingleSelectionElement = [...e.element.getElementsByClassName('dx-single-selection')];

  // single-selection 초기화
  allSingleSelectionElement?.forEach(rowElement => {
    rowElement.classList.remove('dx-single-selection');
  });

  // 단일선택 색상 설정 class 추가
  e.rowElement.classList.add('dx-single-selection');
}


