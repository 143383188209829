import ForecastingList from '@/pages/ai/forecasting/list.vue';
import ForecastingReport from '@/pages/report/ewm/forecasting-report.vue';
import ForecastingModelHistory from '@/pages/report/ewm/forecasting-model-history.vue';

export default function ConfigRoutes(commonComponents) {
  return [
    {
      path: '/ai/forecasting/list',
      name: 'ForecastingList',
      components: { default: ForecastingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/ewm/forecasting-report',
      name: 'ForecastingReport',
      components: { default: ForecastingReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/ewm/forecasting-model-history',
      name: 'ForecastingModelHistory',
      components: { default: ForecastingModelHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
