<template>
	<transition>
		<div class="conts-row">
			<table class="table-style fir">
				<tr>
					<th>교육계획명</th>
					<td colspan="3">
						{{ formData.scheNm }}
					</td>
				</tr>
				<tr>
					<th>교육기간</th>
					<td colspan="3">
						{{ formatDate(formData.ymdStart) }}
						~
						{{ formatDate(formData.ymdEnd) }}
					</td>
				</tr>
				<tr>
					<th>강의명</th>
					<td colspan="3">
						{{ formData.eduNm }}
					</td>
				</tr>
				<tr>
					<th>강의시간</th>
					<td>
						{{ formatTime(formData.subjectTimeStart) }}
						~
						{{ formatTime(formData.subjectTimeEnd) }}
					</td>
					<th>강의유형</th>
					<td>
						{{ getEduTypeList(formData.typeCd) }}
					</td>
				</tr>
				<tr>
					<th>교육자료</th>
					<td>
						<template v-if="formData.fileId === null">
							{{ '-' }}
						</template>
						<template v-else>
							<DxButton
								text="파일 다운로드"
								class="download"
								width="120"
								height="30"
								@click="downloadFile(formData.fileId)"
							/>
						</template>
					</td>
					<th>참여정보</th>
					<td>
						{{ getJoinCheck(formData.joinCd) }}
					</td>
				</tr>
			</table>

			<table class="table-style sec">
				<tr>
					<th>피드백 요청내용</th>
					<th>피드백 응답내용</th>
				</tr>
				<tr>
					<td>
						<DxTextArea :height="200" v-model="formData.feedbakRequest"> </DxTextArea>
					</td>
					<td>
						<DxTextArea :height="200" v-model="formData.feedbakResponse" :read-only="true"> </DxTextArea>
					</td>
				</tr>
			</table>
		</div>
	</transition>
</template>

<script>
import { DxTextArea } from 'devextreme-vue/text-area';
import { DxButton } from 'devextreme-vue/button';
import {formatDate} from "@/utils/common-lib";

export default {
	components: {
		DxTextArea,
		DxButton,
	},
	props: {
		contentData: Object,
	},
	watch: {},
	data() {
		return {
			formData: {},
		};
	},
	computed: {},
	methods: {
		getEduTypeList(typeCd) {
			let eduType = this.$_getCode('ewm_edu_education_type');
			let result = '';
			eduType.forEach(item => {
				if (item.codeId === typeCd) {
					result = item.codeNm;
				}
			});
			return result;
		},
		getJoinCheck(joinCheck) {
			let join = this.$_getCode('join');
			let result = '';
			join.forEach(item => {
				if (item.codeId === joinCheck) {
					result = item.codeNm;
				}
			});
			return result;
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDate(date) {
			return formatDate(date, 'YYYYMMDD', 'YYYY-MM-DD');
		},
		formatTime(timeString) {
			const hours = timeString.substr(0, 2);
			const minutes = timeString.substr(2, 2);

			return `${hours}:${minutes}`;
		},
	},
	mounted() {
    this.formData = this.contentData;
  },
};
</script>

<style scoped>
.table-style.sec {
	padding-top: 30px;
}

.table-style th,
.table-style td {
	border: 1px solid gray;
	padding: 5px;
	background-color: white;
	height: 100%;
}
</style>
