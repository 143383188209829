<!--
  PACKAGE_NAME : src/pages/cc/ivr/notice/list.vue
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2024-08-01
  DESCRIPTION : 공지사항 관리 리스트
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName"></esp-dx-data-grid>
      </div>
    </div>

    <!-- 공지사항 설정 -->
    <ModalListenNotice
      v-if="modal.notice.visible"
      :is-open="modal.notice.visible"
      :notice-file="modal.notice.data"
      @closeModal="onCloseModal('notice')"
    />

    <!-- 대표번호 설정 -->
    <ModalDnis
      v-if="modal.dnis.visible"
      :is-open="modal.dnis.visible"
      :dnis-data="modal.dnis.data"
      @saveModal="onSaveModal('dnis')"
      @closeModal="onCloseModal('dnis')"
    />
  </div>
</template>

<script>
import {
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxDataGrid,
  DxEditing,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxButton } from 'devextreme-vue/button';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { formatDate, isSuccess } from '@/utils/common-lib';
import { DxPopover } from 'devextreme-vue/popover';
import ModalListenNotice from '@/pages/cc/ivr/notice/modal-listen-notice.vue';
import ModalDnis from "@/pages/cc/ivr/notice/modal-dnis.vue";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import {mountComponent} from "@/utils/devextreme-util";
import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
import EspDeleteButton from "@/components/common/buttons/esp-delete-button.vue";

export default {
  components: {
    EspDxDataGrid,
    DxDataGridx: DxDataGrid,
    DxPopover,
    DxColumn,
    DxScrolling,
    DxPaging,
    DxPager,
    DxEditing,
    DxSelection,
    DxColumnChooser,
    DxColumnFixing,
    DxTextBox,
    DxButton,
    DxSelectBox,
    ModalListenNotice,
    ModalDnis,
  },

  props: {},

  watch: {},

  data() {
    return {
      modal: {
        notice: {
          visible: false,
          data: {},
        },
        dnis: {
          visible: false,
          data: {},
        },
      },
      popover: {
        width: 600,
        height: 500,
        showTitle: true,
        title: this.$_lang('CC.WORD.DINS_NOTICE', { defaultValue: '공지사항으로 관리중인 대표번호' }),
        showCloseButton: true,
        position: 'bottom',
        showEvent: 'mouseenter',
        hideEvent: 'mouseleave',
      },
      dnisList: [], // 대표번호 리스트
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'noticeGrid',
        keyExpr:'id',
        allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: true, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        dataSourceDefaultSortColumn: '-regDt', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
        dataSource: [],
        // width:'200',                                     // 주석처리시 100%
        // height:'500',                                    // 주석처리시 100%
        apiActionNm: {},
        showActionButtons: {
          select: true,
          copy: false,
          delete: false,
          customButtons: [
            {
              template: (data, index, element) => {
                mountComponent(
                  element,
                  EspDxButton,
                  {
                    i18n: 'COMPONENTS.REGISTER',
                    prefixIcon: 'add1',
                  },
                  {
                    handleClick: () => this.onAddData()
                  },
                  this,
                );
              },
              location: 'before',
            },
            {
              template: (data, index, element) => {
                mountComponent(
                  element,
                  EspDeleteButton,
                  {},
                  {
                    handleClick: () => this.onDeleteData()
                  },
                  this,
                );
              },
              location: 'before',
            },
          ],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        // scrolling: {                                     // 미사용시 주석처리
        //     mode: 'virtual'                              //스크롤 모드 : ['infinite', 'standard', 'virtual']
        // },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: true,
          grouping: false,
          paging: true,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: true,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: true, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '공지사항',
            i18n: 'CC.WORD.NOTICE',
            dataField: 'noticeNm',
            width: '15%',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            cellTemplate: (container, options) => {
              let aTag = document.createElement('a');
              aTag.innerText = options.value;
              aTag.addEventListener('click', () => {
                this.onUpdateData(options.data.id);
              });
              container.append(aTag);
            },
          },
          {
            caption: '노출기간',
            i18n: 'CC.WORD.DISPLAY_PERIOD',
            dataField: 'startDt',
            width: '20%',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            calculateCellValue: this.startDt,
          },
          {
            caption: '종료여부',
            i18n: 'CC.WORD.END_TYPE',
            dataField: 'endTypeFl',
            width: '10%',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowGrouping: false,
            lookup: {
              dataSource: this.$_enums.cc.stringEndTypeFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
          {
            caption: '미리듣기',
            i18n: 'COMPONENTS.PRE_LISTENING',
            dataField: 'listenNotice',
            alignment: 'center', // left center right
            visible: true,
            width: '10%',
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              let button = new DxButton({
                propsData: {
                  elementAttr: { class: 'btn-icon listen' },
                  value: options.data,
                  onClick: () => {
                    this.onClickListenNotice(options.data.contents);
                  },
                },
              });
              button.$mount();
              container.append(button.$el);
            },
          },
          {
            caption: '대표번호',
            i18n: 'CC.WORD.MAIN_NUMBER',
            dataField: 'dnisNotices',
            width: '10%',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            calculateCellValue: (rowData) => {
              if (!rowData.dnisNotices) return 0;
              return rowData.dnisNotices.length;
            },
            cellTemplate: (container, options) => {
              if( options.value > 0 ) {
                //링크 클릭시 팝업
                let aTag = document.createElement('a');
                aTag.innerText = `${options.value}개`;
                aTag.addEventListener('click', () => {
                  this.onOpenModal('dnis', options.data); //대표번호 설정 팝업 오픈
                });

                container.append(aTag);
              }else {
                let button = new DxButton({
                  propsData: {
                    text: this.$_lang('COMPONENTS.SETTING', { defaultValue: '설정' }),
                    elementAttr: { class: 'btn_XS white light_filled' },
                    width: 60,
                    height: 30,
                    value: options.data.id,
                    onClick: () => {
                      this.onOpenModal('dnis', options.data); //인입그룹 설정 팝업 오픈
                    },
                  },
                });
                button.$mount();
                container.append(button.$el);
              }
            },
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowGrouping: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            width: '10%',
            alignment: 'center',
            visible: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
          },
          {
            caption: '최근 수정일시',
            i18n: 'COMPONENTS.RECENT_MODIFY_DATE',
            dataField: 'editDt',
            alignment: 'center',
            visible: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            calculateCellValue: this.editDt,
          },
          {
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
            dataField: 'regId',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
          },
          {
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE',
            dataField: 'regDt',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            calculateCellValue: this.regDt,
          },
        ],
      },
      dnisDataGrid: { //대표번호 항목 팝오버 그리드
        callApi: 'CALL_CC_API',
        refName: 'dnisGrid',
        keyExpr:'id',
        allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true, //inline속성 느낌
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        hoverStateEnabled: true,
        dataSourceDefaultSortColumn: '+ivrDnisOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
        dataSource: [],
        // width:'200',                                     // 주석처리시 100%
        // height:'500',                                    // 주석처리시 100%
        apiActionNm: {},
        showActionButtons: {
          select: false,
          copy: false,
          delete: false,
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        // scrolling: {                                     // 미사용시 주석처리
        //     mode: 'virtual'                              //스크롤 모드 : ['infinite', 'standard', 'virtual']
        // },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: true,
          grouping: false,
          paging: true,
        },
        paging: {
          // scrolling 미사용시만 적용됨
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '사이트',
            i18n: 'COMMON.WORD.SITE',
            dataField: 'siteId',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: 'siteNm',
              valueExpr: 'id',
            },
          },
          {
            caption: '센터',
            i18n: 'COMMON.WORD.TENANT',
            dataField: 'tenantId',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: 'tenantNm',
              valueExpr: 'id',
            },
          },
          {
            caption: 'DNIS명',
            i18n: 'CC.WORD.DNIS_TITLE',
            dataField: 'dnisNm',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            fixedPosition: 'center', // left or right
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            width: 130,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowFiltering: true,
            allowGrouping: false,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
        ],
      },
    };
  },

  computed: {

  },

  methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(notice, ibg, setting)
     * @param settingData 모달로 넘길 설정 데이터 */
    onOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    async onSaveModal(settingType) {
      this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
      if( settingType === 'dnis' ){
        await this.selectDnisList();
      }

      this.$refs[this.dataGrid.refName].refreshData();
    },
    /** @description : 팝업 닫기
     * @param settingType : 설정 타입(notice, dnis)
     **/
    onCloseModal(settingType) {
      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description : 공지사항 미리듣기 이벤트
     * @params contents : 공지사항 내용 */
    onClickListenNotice(contents) {
      // TODO : API에서 TTS 연동 후 wav 파일명 전달 ex) sample.wav
      this.modal.notice.noticeFile = 'sample-15s.wav';
      this.modal.notice.visible = true;
    },
    /** @description : 팝오버 표시 */
    showPopover(tag, dnisNotices) {
      const popover = new DxPopover({
        propsData: {
          target: tag,
          width: this.popover.width,
          height: this.popover.height,
          showTitle: this.popover.showTitle,
          title: this.popover.title,
          showCloseButton: this.popover.showCloseButton,
          position: this.popover.position,
          showEvent: this.popover.showEvent,
          contentTemplate: (container) => {
            this.dnisDataGrid.dataSource = dnisNotices;

            const dataGrid = new DxDataGrid({
              propsData: this.dnisDataGrid,
            });
            dataGrid.$mount();
            container.append(dataGrid.$el);
          },
        }
      });
      popover.$mount();
      tag.appendChild(popover.$el);
    },
    /** @description: 데이터 조회 메서드 */
    selectDataList() {
      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        load: async(loadOptions) => {
          const params = this.$_getDxDataGridParam(loadOptions);

          if (!params.sort) {
            params.sort = this.dataGrid.dataSourceDefaultSortColumn;
          }

          const payload = {
            actionName: 'CC_IVR_NOTICE_LIST',
            data: params,
          };

          const res = await this.CALL_CC_API(payload);
          let rtnData = {
            data: [],
            totalCount: 0,
          };
          if (isSuccess(res)) {
            let noticeList = res.data.data;
            noticeList.forEach(notice => {
              const dnisNotices = this.dnisList.filter(dnis => dnis.noticeId === notice.id);
              if( dnisNotices.length > 0 ) {
                notice.dnisNotices = dnisNotices;
              }
            });
            rtnData = {
              data: noticeList,
              totalCount: res.data.header.totalCount,
            };
            this.$refs[this.dataGrid.refName].totalCount = rtnData.totalCount;
          }
          return rtnData;
        },
      });
    },
    /** @description : 대표번호 리스트 조회 메서드 */
    async selectDnisList() {
      const payload = {
        actionName: 'CC_IVR_DNIS_LIST',
        useErrorPopup: true,
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.dnisList = res.data.data;
      }
    },
    /** @description : 데이터 삭제 메서드 */
    async onDeleteData() {
      const selectedRowsData = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
      if (selectedRowsData.length === 0) {
        return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      }

      //게시중인 공지사항 확인
      if (selectedRowsData.some(row => row.dnisNotices?.length > 0)) {
        return this.$_Msg(this.$_lang('CC.MESSAGE.NO_DELETE_DNIS_NOTICE', { defaultValue: '대표번호에 게시 중인 공지사항이 있어 삭제할 수 없습니다.'}) );
      }

      if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '공지사항 정보를 삭제하시겠습니까?' })) ) {
        const payload = {
          actionName: 'CC_IVR_NOTICE_DELETE',
          data: selectedRowsData,
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
          this.$refs[this.dataGrid.refName].refreshData();
        }
      }
    },
    /** @description: 노출기간 출력 */
    startDt(rowData){
      if( rowData.startDt ) {
        const startDt = formatDate(rowData.startDt, 'YYYYMMDD', 'YYYY-MM-DD');
        const endDt = formatDate(rowData.endDt, 'YYYYMMDD', 'YYYY-MM-DD');
        const startTime = formatDate(rowData.startTime, 'HHmm', 'HH:mm');
        const endTime = formatDate(rowData.endTime, 'HHmm', 'HH:mm');
        return `${startDt} ${startTime} ~ ${endDt} ${endTime}`;
      }else {
        return '-';
      }
    },
    /** @description : 수정일시 출력 */
    editDt(rowData) {
      if (rowData.editDt !== null) return formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
      else return '-';
    },
    /** @description : 등록일시 출력 */
    regDt(rowData) {
      return formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
    },
    /** @description : 공지사항 등록 페이지 이동 */
    onAddData() {
      this.$router.push('/cc/ivr/notice/config');
    },
    /** @description : 공지사항 수정 페이지 이동 */
    onUpdateData(id) {
      this.$store.commit('setDetailParams', { id });
      this.$router.push({ path: '/cc/ivr/notice/config' });
    },
  },
  async mounted() {
    await this.selectDnisList();
    await this.selectDataList();
  },
  activated() {
    this.$refs[this.dataGrid.refName].refreshData();
  }
};
</script>
