import { EnumEntry, EnumMap } from '@/configs/enums/util';

/**
 * @description 공지사항 - 종료여부 Enum 클래스
 */
class StringEndTypeFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'CC_STRING_END_TYPE_FLAG_YES',
    value: 'Y',
    label: '전화종료',
    group: 'CC_STRING_END_TYPE_FLAG',
    group_label: '종료여부(문자열)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'CC_STRING_END_TYPE_FLAG_NO',
    value: 'N',
    label: '계속전화',
    group: 'CC_STRING_END_TYPE_FLAG',
    group_label: '종료여부(문자열)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 종료여부 탐색 후 값 반환
   * @param value
   * @return {*|string|string} 일치하지 않을 경우 빈 문자열 반환
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description 운영스케줄 - 공휴일설정 운영여부 Enum 클래스
 */
class StringHolidayWorkFlag extends EnumEntry {
  YES = {
    ...EnumMap,
    key: 'CC_STRING_HOLIDAY_WORK_FLAG_YES',
    value: 'Y',
    label: '운영',
    group: 'CC_STRING_HOLIDAY_WORK_FLAG',
    group_label: '공휴일 운영여부(문자열)',
    equals: value => {
      return this.YES.value === value;
    },
  };

  NO = {
    ...EnumMap,
    key: 'CC_STRING_HOLIDAY_WORK_FLAG_NO',
    value: 'N',
    label: '미운영',
    group: 'CC_STRING_HOLIDAY_WORK_FLAG',
    group_label: '공휴일 운영여부(문자열)',
    equals: value => {
      return this.NO.value === value;
    },
  };

  values = [this.YES, this.NO];

  /**
   * @description 공휴일 운영여부 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

/**
 * @description Ivr 멘트 - 파일 타입 Enum 클래스
 */
class IvrMentFileType extends EnumEntry {
  WAV = {
    ...EnumMap,
    key: 'CC_IVR_MENT_FILE_TYPE_WAV',
    value: 'wav',
    label: 'WAV',
    group: 'CC_IVR_MENT_FILE_TYPE',
    group_label: 'IVR 멘트 파일 타입',
    equals: value => {
      return this.WAV.value === value;
    },
  };

  TTS = {
    ...EnumMap,
    key: 'CC_IVR_MENT_FILE_TYPE_TTS',
    value: 'tts',
    label: 'TTS',
    group: 'CC_IVR_MENT_FILE_TYPE',
    group_label: 'IVR 멘트 파일 타입',
    equals: value => {
      return this.TTS.value === value;
    },
  };

  values = [this.WAV, this.TTS];

  /**
   * @description IVR 멘트 파일 타입 탐색 후 값 반환
   * @param value
   * @return {*|string|string}
   */
  getValue = value => {
    if (!value) {
      return '';
    }
    return this.values.find(item => item.value === value)?.value || '';
  };
}

function getEnumAllCodes() {
  return [].concat(new StringEndTypeFlag().values, new StringHolidayWorkFlag().values, new IvrMentFileType().values);
}

export default {
  stringEndTypeFlag: new StringEndTypeFlag(),
  stringHolidayWorkFlag: new StringHolidayWorkFlag(),
  ivrMentFileType: new IvrMentFileType(),
  getEnumAllCodes: getEnumAllCodes(),
};
