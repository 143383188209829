<template>
  <div>
    <table class="table_form" style="width: 100%; border-top: 0">
      <tbody>
        <tr>
          <esp-dx-data-grid
            :data-grid="dataGrid"
            ref="lineAddGrid"
            :auto-width="true"
            @init-new-row="onInitNewRow"
          ></esp-dx-data-grid>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { isSuccess } from '@/utils/common-lib';
  import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

  export default {
    components: {
      EspDxDataGrid,
    },
    props: {
      option: {
        default: () => {
          return {
            width: '80%',
            height: '85%',
            minWidth: null,
            minHeight: null,
            saveBtnVisible: false,
            cancelBtnVisible: true,
          };
        },
        type: Object,
      },

      deviceInfo: {
        type: Object,
      },
      serverInfo: {
        type: String,
      },
      isOpened: {
        type: Boolean,
      },
      authority: {
        type: String,
      },
      cmList: {
        type: Array,
      },
      partitionList: {
        type: Array,
      },
      cssList: {
        type: Array,
      },
      pickupGroupList: {
        type: Array,
      },
    },
    watch: {
      deviceInfo() {},
      async isOpened() {
        if (!this.isOpened) {
          this.refreshGrid();
        }
      },
    },
    data() {
      return {
        rowCnt: 0,
        dataGrid: {
          refName: 'lineAddGrid',
          dataSource: [],
          keyExpr: 'id',
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            initNewRow: true,
            saving: true,
          },
          showActionButtons: {
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onDeleteLine();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '생성',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onCreateLine();
                  },
                },
                location: 'after',
              }
            ],
          },
          page: {
            enabled: false,
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true,
            allowAdding: true,
          },
          columns: [
            {
              caption: '교환기',
              i18n: 'UC.WORD.EXCHANGER',
              dataField: 'cmNm',
              width: 100,
              allowEditing: false,
              requiredRule: {},
              cellTemplate: async (container, options) => {
                if (this.cmList.length === 1) {
                  options.value = this.cmList[0].cmNm;
                }
                const selectBox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_lang('COMPONENTS.SELECT'),
                    items: this.cmList,
                    displayExpr: 'description',
                    valueExpr: 'cmNm',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectBox.$mount();
                container.append(selectBox.$el);
              },
            },
            {
              caption: '내선번호',
              i18n: 'UC.WORD.NUMBER',
              dataField: 'pattern',
              width: 150,
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: function (e) {
                    return !isNaN(e.value);
                  },
                  message: this.$_lang('UC.MESSAGE.NUMBER_ENTER'),
                },
              ],
            },
            {
              caption: 'LABEL',
              i18n: 'UC.WORD.LABEL',
              dataField: 'label',
              requiredRule: {},
            },
            {
              caption: '파티션',
              i18n: 'UC.WORD.PARTITION',
              dataField: 'routepartitionname',
              width: 200,
              allowEditing: false,
              requiredRule: {},
              cellTemplate: async (container, options) => {
                const selectBox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_lang('COMPONENTS.SELECT'),
                    items: this.partitionList,
                    displayExpr: 'name',
                    valueExpr: 'name',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectBox.$mount();
                container.append(selectBox.$el);
              },
            },
            {
              caption: '발신검색',
              i18n: 'UC.WORD.CSS',
              dataField: 'css',
              width: 200,
              allowEditing: false,
              requiredRule: {},
              cellTemplate: async (container, options) => {
                const selectBox = new DxSelectBox({
                  propsData: {
                    placeholder: this.$_lang('COMPONENTS.SELECT'),
                    items: this.cssList,
                    displayExpr: 'name',
                    valueExpr: 'name',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectBox.$mount();
                container.append(selectBox.$el);
              },
            },

            {
              caption: '당겨받기',
              i18n: 'UC.WORD.PICKUPGROUP',
              dataField: 'callpickupgroupname',
              width: 150,
              allowEditing: false,
              cellTemplate: async (container, options) => {
                const selectBox = new DxSelectBox({
                  propsData: {
                    placeholder: '선택',
                    items: this.pickupGroupList,
                    displayExpr: 'name',
                    valueExpr: 'name',
                    value: options.value,
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.lineAddGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                });
                selectBox.$mount();
                container.append(selectBox.$el);
              },
            },
          ],
        },
      };
    },
    methods: {
      /** @description 그리드 신규 row 추가 시 이벤트 */
      onInitNewRow(e) {
        //교환기 정보 하나만 있을 경우 값 픽스
        if (this.cmList.length === 1) {
          e.data.cmNm = this.cmList[0].cmNm;
        }
        e.data.id = this.rowCnt;
        this.rowCnt++;
        this.$refs.lineAddGrid.getInstance.refresh();
      },
      /** @description 그리드 데이터 초기화 */
      refreshGrid() {
        this.$set(this.dataGrid, 'dataSource', []);
        this.$refs.lineAddGrid.getInstance.cancelEditData();
        this.rowCnt = 0;
      },
      async onDeleteLine() {
        const data = this.$refs.lineAddGrid.getInstance.getSelectedRowsData();
        if (!data?.length) {
          await this.$_Msg('삭제할 데이터가 없습니다.');
          return false;
        }
        const keys = data.map(item => this.$refs.lineAddGrid.getInstance.getRowIndexByKey(item.id));
        keys.forEach(item => {
          this.$refs.lineAddGrid.getInstance.deleteRow(item);
        });
      },
      /**@description 내선 등록 함수 */
      async onCreateLine() {
        if (this.$refs.lineAddGrid.hasEditData()) {
          await this.$_Msg('수정중인 항목이있습니다. ');
          return;
        }

        const data = this.$refs.lineAddGrid.getInstance.getDataSource()._items;
        if (!data?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_DATA'));
          return;
        } else if (data.length > 10) {
          this.$_Msg(this.$_lang('UC.MESSAGE.CHECK_ADD_COUNT'));
        }

        let flag = true;
        let prevCm = '';
        for (let i = 0; i < data.length; i++) {
          if (i === 0) {
            prevCm = data[i].cmNm;
            continue;
          }
          if (prevCm !== data[i].cmNm) {
            flag = false;
          }
          prevCm = data[i].cmNm;
        }

        if (!flag) {
          this.$_Msg(this.$_lang('UC.MESSAGE.CHECK_EXCHANGE_TYPE'));
          return;
        }

        const confirm = this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' });
        if (!(await this.$_Confirm(confirm))) {
          return;
        }

        const payload = {
          actionName: 'EUC_LINE_INSERT',
          data: {
            linelist: data,
            loginId: this.$store.getters.getLoginId
          },
          loading: true,
        };
        const res = await this.CALL_EUC_API(payload);
        if (isSuccess(res)) {
          await this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', { defaultValue: '정상적으로 처리되었습니다.' }));
          this.$emit('closeModal', true);
        } else {
          await this.$_Msg(res.data.header.resMsg);
        }
      },
    },
  };
</script>
