<!--
  PACKAGE_NAME : src/pages/report/config/modal-xml-editor.vue
  FILE_NAME : modal-xml-editor
  AUTHOR : hmlee
  DATE : 2025-03-06
  DESCRIPTION : XML 에디터 모달
-->
<template>
  <esp-dx-modal-popup
    :option="{
      title: option.title,
      width: option.width,
      height: option.height,
      minWidth: option.minWidth,
      minHeight: option.minHeight,
      closeOnOutsideClick: option.hideOnOutsideClick,
      useSaveBtnDisabled: option.useSaveBtnDisabled,
    }"
    :isOpen="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <div class="flex gap-x-8 items-stretch">
        <!-- [START] 좌측 영역 -->
        <div class="editor-style" :class="editorOption.reportId ? 'w-4/6' : 'w-full'">
          <!-- 헤더 -->
          <div class="flex justify-between">
            <h3 class="text-xl font-medium mt-1.5">
              {{ formData.description }}
            </h3>
            <div class="flex pb-2 space-x-1">
              <esp-dx-button color="white" mode="outlined" i18n="COMPONENTS.RESET" @click="initXmlEditor" />
              <esp-dx-button color="white" mode="outlined" i18n="COMPONENTS.PRETTIFY_CODE" @click="prettierEditorData('xml')" />
            </div>
          </div>

          <!-- 에디터 -->
          <div class="editor-height">
            <codemirror-editor ref="xmlEditor" v-model="cmEditor" beautyType="html" />
          </div>
        </div>
        <!-- [END] 좌측 영역 -->

        <!-- [START] 히스토리 및 설명 영역 -->
        <div v-show="editorOption.reportId" class="w-2/6">
          <h3 class="text-xl font-medium mt-1.5">작업 이력</h3>
          <div class="py-2">
            <div>
              <esp-dx-data-grid
                :data-grid="dataGrid"
                :ref="dataGrid.refName"
                @content-ready="selectFirstRow"
                @selection-changed="handlerClickRow"
                @cell-hover-changed="handleCellHoverChanged"
              />
              <!-- 설명 팝오버 -->
              <dx-popover width="auto" position="bottom" :target="popover.target" :visible="popover.visible" :hide-on-outside-click="true">
                <p>{{ popover.content }}</p>
              </dx-popover>
            </div>
            <div class="py-2">
              <dx-text-area
                width="100%"
                :height="120"
                :max-length="100"
                class="mar_ri10 alB"
                v-model="description"
                :styling-mode="stylingMode"
                @key-up="keyupDescription($event)"
              />
              <div class="mt-05 text-end">{{ descriptionLength }}/100 자</div>
            </div>
          </div>
        </div>
        <!-- [END] 히스토리 및 설명 영역 -->
      </div>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
  import EspDxModalPopup from '@/components/devextreme/esp-dx-modal-popup';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import EspDxButton from '@/components/devextreme/esp-dx-button.vue';
  import { DxPopover } from 'devextreme-vue/popover';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import CodemirrorEditor from '@/components/codemirror/codemirror-xml-editor';
  import { vkbeautify } from '@/plugins/vkbeautify';
  import CustomStore from 'devextreme/data/custom_store';
  import { isSuccess, formatDate, getResData } from '@/utils/common-lib';

  export default {
    components: {
      EspDxModalPopup,
      EspDxDataGrid,
      EspDxButton,
      DxPopover,
      DxTextArea,
      CodemirrorEditor,
    },
    props: {
      isOpen: {
        // 모달 오픈 여부
        default: false,
        type: Boolean,
      },
      option: {
        // 모달 옵션
        type: Object,
        default: () => {
          return {
            title: 'XML 편집기',
            width: '900',
            height: '580',
            minWidth: '300',
            minHeight: '300',
            hideOnOutsideClick: false,
            useSaveBtnDisabled: false,
          };
        },
      },
      editorOption: {
        // 에디터 옵션
        type: Object,
        default: () => {
          return {
            type: 'REPORT', // type: ['REPORT', 'MASTER']
            reportId: null,
            reportType: null,
            name: null,
            solution: null,
            subPath: null,
            description: null,
          };
        },
      },
    },
    watch: {},
    data() {
      return {
        stylingMode: 'outlined', //[outlined, filled, underlined]
        formData: {}, // 폼 데이터
        cmEditor: null, // CodeMirror 에디터 데이터
        historyId: null, // 선택된 히스토리 ID
        description: null, // 선택된 히스토리 설명
        historyCount: 0, // 히스토리 개수
        modal: {
          title: 'XML 편집기',
          minWidth: '300',
          width: '900',
          minHeight: '300',
          height: '580',
          hideOnOutsideClick: false,
        },
        popover: {
          target: null,
          content: '',
          visible: false,
        },
        dataGrid: {
          callApi: 'CALL_REPORT_API',
          refName: 'historyGrid',
          dataSource: [],
          dataSourceDefaultSortColumn: '-regDt', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순
          showBorders: true,
          showColumnLines: false,
          height: '60vh',
          apiActionNm: {},
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: false,
            delete: false,
            customButtons: [],
          },
          toolbarOptions: {
            visible: false,
          },
          sorting: {
            mode: 'none',
          },
          page: {
            enabled: false,
          },
          filterRow: {
            visible: false,
          },
          editing: {
            allowUpdating: false,
          },
          selecting: {
            mode: 'single',
          },
          columns: [
            {
              caption: '작업자',
              i18n: 'COMPONENTS.WORKER',
              dataField: 'editId',
              width: '100',
            },
            {
              caption: '설명',
              i18n: 'COMPONENTS.DESCRIPTION',
              dataField: 'description',
              alignment: 'left',
            },
            {
              caption: '작업일시',
              i18n: 'COMPONENTS.WORK_DATE_TIME',
              dataField: 'editDt',
              calculateCellValue: this.formatEditDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: 설명 길이 */
      descriptionLength() {
        if ([undefined, null].includes(this.description)) return 0;
        return this.description.length;
      },
    },
    methods: {
      /** @description: 모달 저장 */
      async handleSaveModal() {
        // 초깃값 '등록'
        let actionName = 'MASTER_QUERY_SQL_INSERT';

        // Param
        const params = { sqlXml: this.cmEditor, type: this.formData.reportType };
        if (this.editorOption.reportId) {
          // 수정시
          // parameter
          params.name = this.formData.name;
          params.solution = this.formData.solution;
          params.subPath = this.formData.subPath;
          params.description = this.formData.description;
          actionName = 'MASTER_QUERY_SQL_UPDATE';
        }

        const res = await this.CALL_REPORT_API({
          actionName,
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        const { resCode, resMsg } = res.data.header;
        const confirmMsg = this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {
          defaultValue: '정상적으로 저장되었습니다.',
        });
        if (isSuccess(res) && (await this.$_Msg(confirmMsg))) {
          this.$emit('saveModal');
        } else if (resCode !== 'XML012') {
          this.$_Msg(resMsg);
        } else if (resCode === 'XML012' && (await this.$_Confirm(`${resMsg} 강제로 저장시키겠습니까?`))) {
          const forceRes = await this.CALL_REPORT_API({
            actionName: actionName,
            data: { ...params },
            loading: true,
          });

          if (isSuccess(forceRes) && (await this.$_Msg(confirmMsg))) {
            this.$emit('saveModal');
          }
        }
      },
      /** @description: 모달 닫기 */
      handleCloseModal() {
        this.$emit('closeModal');
      },
      /** @description: 쿼리 조회 */
      async getSqlByXmlFile() {
        const type = this.formData.reportType;
        const name = this.formData.name;
        const solution = this.formData.solution;
        const subPath = this.formData.subPath;
        const params = { type, name, solution, subPath };
        const payload = {
          actionName: 'MASTER_QUERY_SQL_SELECT',
          data: params,
          loading: true,
          useErrorPopup: false,
        };

        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          try {
            this.cmEditor = getResData(res).at(0).sql;
          } catch (e) {}
        }
      },
      /** @description: 쿼리 히스토리 조회 */
      async getXmlFileHistoryById() {
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          load: async loadOptions => {
            const params = this.$_getDxDataGridParam(loadOptions);

            params.id = this.formData.reportId;

            const payload = {
              actionName: 'MASTER_QUERY_SQL_HISTORY',
              data: params,
            };

            const res = await this.CALL_REPORT_API(payload);
            let rtnData = {
              data: [],
              totalCount: 0,
            };
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.data.length,
              };

              this.$refs[this.dataGrid.refName].totalCount = rtnData.totalCount;
              this.historyCount = rtnData.totalCount;
            }
            return rtnData;
          },
        });
      },
      /** @description: 수정일시 출력 */
      formatEditDt(rowData) {
        const rowIndex = this.$refs[this.dataGrid.refName].getItems.indexOf(rowData);
        if (rowIndex === 0) {
          return '사용중';
        } else {
          return formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
        }
      },
      /** @description: 첫번째 행 설정 */
      selectFirstRow(e) {
        const firstRowElement = e.component.getRowElement(0);
        if (firstRowElement && firstRowElement.length > 0) {
          firstRowElement[0].click(); // 첫 번째 행 클릭 이벤트 실행
        }
      },
      /** @description: 히스토리 클릭 이벤트 */
      async handlerClickRow({ selectedRowsData }) {
        if (selectedRowsData.length === 0) return;
        const { id, description } = selectedRowsData[0];
        const xmlData = this.getXmlData(selectedRowsData[0]);
        if (xmlData) {
          this.setHistoryId(id);
          this.setDescription(description || '');
          this.cmEditor = xmlData;
        }
      },
      /** @description: xml 데이터 조회 */
      getXmlData(data) {
        // TODO: 보고서, 마스터쿼리 컬럼 달라서 생긴 문제로 임시 생성. 변경(통일) 필요
        return data.xmlData || data.sqlXml; // 보고서: xmlData | 공통: sqlXml;
      },
      /** @description: 히스토리 ID 설정 */
      setHistoryId(id) {
        this.historyId = id;
      },
      /** @description: 설명 설정 */
      setDescription(description) {
        this.description = description;
      },
      /** @description: xml 에디터 초기화 */
      initXmlEditor() {
        const data = this.$refs[this.dataGrid.refName].getItems.find(v => v.id === this.historyId);
        if (data) {
          this.cmEditor = this.getXmlData(data);
        } else {
          this.cmEditor = '';
        }
      },
      /** @description: 셀 호버 이벤트 */
      handleCellHoverChanged(e) {
        this.popover.visible = false;
        if (e.rowType === 'data' && e.eventType === 'mouseover') {
          const rowData = this.$refs[this.dataGrid.refName].getItems.find(v => v.id === e.data.id);
          if (rowData && rowData.description) {
            this.popover.visible = true;
            this.popover.target = e.cellElement;
            this.popover.content = rowData.description;
          }
        }
      },
      /** @description: 설명 입력 이벤트 */
      keyupDescription(e) {
        this.description = e.event.currentTarget.value;
      },
      /** @description: xml 코드 정리 */
      prettierEditorData(type) {
        try {
          this.cmEditor = vkbeautify[type](this.cmEditor)
            .split('\n')
            .filter(v => v.trim() !== '')
            .join('\n');
        } catch (e) {
          console.log(e);
        }
      },
      /** @description: 데이터 초기화 */
      initData() {
        // 초기값 설정
        const initFormData = {
          type: 'REPORT',
          reportId: null,
          reportType: null,
          name: null,
          solution: null,
          subPath: null,
          description: '',
        };

        this.formData = {
          ...(this.editorOption || initFormData),
        };
      },
    },
    async created() {},
    async mounted() {
      await this.initData();
      if (this.editorOption.reportId) {
        // 수정
        await this.getSqlByXmlFile(); // 쿼리 조회
        await this.getXmlFileHistoryById(); // 쿼리 히스토리 조회
      }
    },
    destroyed() {},
  };
</script>
<style scoped>
  .editor-height {
    height: 70vh;
  }

  .mt-05 {
    margin-top: 5px;
  }

  .text-end {
    text-align: end;
  }
</style>
