<!--
  PACKAGE_NAME : src/pages/euc/contact
  FILE_NAME : group.vue
  AUTHOR : jhcho
  DATE : 2024-06-10
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="groupGrid" @init-new-row="onInitNewRow" @reorder="onReorder" />
  </div>
</template>
<script>
import {formatDate, isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid
  },
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: `${this.$_lang('UC.WORD.CONTACT', { defaultValue: '개인연락처' })} ${this.$_lang('COMPONENTS.GROUP', { defaultValue: '그룹' })}`,
        },
        refName: 'groupGrid',
        dataSourceDefaultSortColumn: '+groupOrd',
        apiActionNm: {
          select: 'EUC_CONTACT_GROUP_SELECT',
          update: 'EUC_CONTACT_GROUP_UPDATE',
          delete: 'EUC_CONTACT_GROUP_DELETE',
        },
        searchParams: {
          regId: this.isRootUser() ? null : this.$store.getters.getLoginId,
        },
        showActionButtons: {
          save: !this.isRootUser(),
          delete: !this.isRootUser(),
          excel: true,
        },
        editing: {
          allowUpdating: !this.isRootUser(),
          allowAdding: !this.isRootUser(),
        },
        columns: [
          {
            caption: '사번',
            dataField: 'regId',
            width: 100,
            visible: this.isRootUser(),   // root 사용자만 보이도록 설정
            allowEditing: false,
          },
          {
            caption: '순서',
            dataField: 'groupOrd',
            visible: false,
          },
          {
            caption: '그룹명',
            dataField: 'groupNm',
            width: 250,
            requiredRule: {},
          },
          {
            caption: '설명',
            dataField: 'description',
          },
          {
            caption: '사용여부',
            dataField: 'viewFl',
            width: 130,
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value'
            },
          },
          {
            caption: '등록일시',
            dataField: 'regDt',
            width: 200,
            allowEditing: false,
            calculateCellValue: (data) => this.formatDt(data.regDt),
          },
        ],
      },
    }
  },
  methods: {
    async reorder(e) {
      const visibleRows = e.component.getVisibleRows();
      const newOrderIndex = visibleRows[e.toIndex].data.groupOrd;

      const params = {
        id: e.itemData.id,
        groupOrd: newOrderIndex,
      };

      const payload = {
        actionName: 'EUC_CONTACT_GROUP_UPDATE',
        data: [params],
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        e.component.refresh();
      }
    },
    onReorder(e) {
      e.promise = this.reorder(e);
    },
    /**
     * @description 새로운 데이터의 개수를 반환
     * @param {Array} renderingData - 렌더링 데이터 배열
     * @returns {number} 새로운 데이터의 개수
     */
    getNewDataCount(renderingData) {
      return renderingData.filter((item) => typeof item.key !== 'number')?.length || 0;
    },
    /**
     * @description 아이템의 최대 순서를 반환
     * @param {Array} items - 아이템 배열
     * @returns {number} 아이템의 최대 순서
     */
    getMaxItemOrd(items) {
      return items?.length ? Math.max(...items.map(item => item.groupOrd)) : 0;
    },
    onInitNewRow(e) {
      const renderingData = e.component.getVisibleRows();
      const newDataCount = this.getNewDataCount(renderingData);
      const items = this.$refs.groupGrid.getItems;
      const maxItemOrd = this.getMaxItemOrd(items);

      e.data.groupOrd = maxItemOrd + newDataCount + 1;
      e.data.viewFl = 'Y';
    },
    /** @description: 일자 데이터 출력 */
    formatDt(date){
      if(date) {
        return formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    isRootUser () {
      const rootUsers = this.$_getSystemData('root_users')?.configValue.split(',') || [];
      return rootUsers.includes(this.$store.getters.getLoginId);
    }
  },
}
</script>
<style scoped>

</style>