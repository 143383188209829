<!--
  PACKAGE_NAME : src/pages/euc/chineseWall/policy
  FILE_NAME : modal-organization-dept.vue
  AUTHOR : jhcho
  DATE : 24. 12. 10.
  DESCRIPTION :
-->
<template>
  <DxTreeList
      id="deptList"
      ref="deptList"
      :data-source="tree.deptList"
      :root-value="-1"
      :expanded-row-keys="tree.expandedRowKeys"
      :selected-row-keys="tree.selectedRowkeys"
      :focused-row-key="tree.focusedRowKey"
      :auto-expand-all="true"
      :show-column-headers="true"
      :show-row-lines="true"
      :show-column-lines="false"
      :show-borders="false"
      :column-auto-width="true"
      key-expr="id"
      parent-id-expr="parentId"
      height="calc(100% - 50px)"
      @selection-changed="onSelectionChanged"
  >
    <DxSearchPanel
        placeholder=""
        :visible="true"
        :searchVisibleColumnsOnly="true"
        :width="300"
    />
    <DxSelection :recursive="tree.recursive" :mode="tree.selectionMode"  :allow-select-all="true" />
    <DxScrolling mode="virtual"/>
    <DxColumn
        caption="부서"
        data-field="deptNm"
        alignment="left"
        :visible="true"
    />
  </DxTreeList>
</template>
<script>
import {DxColumn, DxScrolling, DxSearchPanel, DxSelection, DxTreeList} from "devextreme-vue/tree-list";

export default {
  components: {DxTreeList, DxColumn, DxScrolling, DxSearchPanel, DxSelection},
  props: {
    modalData: Object,
    isOpened: Boolean,
  },
  data() {
    return {
      tree: {
        originDeptList: [],
        deptList: [],
        sortList: [],
        expandedRowKeys: [],
        selectedRowkeys: [],
        focusedRowKey: null,
        selectedRowsData: [],
        recursive: true,
        selectionMode: 'multiple', // 'all' or 'excludeRecursive' or 'leavesOnly'
        allowDropInsideItem: false,
        allowReordering: true,
        showDragIcons: true,
        selectedData: null,
      },
    }
  },
  watch: {
    isOpened: {
      handler: function (val) {
        if (val) {
          this.setTreeList();
        }
      },
      immediate: true
    }
  },
  methods: {
    onSelectionChanged(e) {
      if(this.modalData?.selectionMode === 'single') {
        if(!e.currentSelectedRowKeys?.length) {
          this.tree.selectedRowsData = e.selectedRowsData.filter((item) => item !== e.currentDeselectedRowKeys[0]);
        } else {
          if (e.currentSelectedRowKeys.length === 1) {
            const currentSelectedRowKey = e.currentSelectedRowKeys;
            e.component.selectRows(currentSelectedRowKey);
            if (e.selectedRowsData.length > 1) {
              this.tree.selectedRowsData = e.selectedRowsData.filter((item) => item.id === e.currentSelectedRowKeys[0]);
            } else if (e.selectedRowsData.length === 1) {
              this.tree.selectedRowsData = e.selectedRowsData;
            }
          }
        }
      } else {
        this.tree.selectedRowsData = e.selectedRowsData;
      }
    },
    setTreeList(){
      console.log('this.modalData.deptList : ', this.modalData.deptList)
      this.tree.selectedRowkeys = [];
      const data = this.modalData.deptList.map(d => {
        const id = d.deptId;
        return { ...d, id };
      });
      this.tree.originDeptList = data;
      this.tree.deptList = data.filter(d => d.viewCd === 1);
      if(this.modalData?.selectedData) {
        if(this.modalData.parentComponent === 'ModalBlock') {
          const blockDetailPolicyList = this.modalData?.selectedData?.blockDetailPolicyList?.filter((d) => d.targetGb === 'DEPT');
          this.tree.selectedRowkeys = blockDetailPolicyList?.map((d) => {
            const deptData = data.find((dept) => dept.deptCode === d.targetCd);
            return deptData?.id;
          }).filter((d) => d !== undefined);
        } else if(this.modalData.parentComponent === 'ModalException') {
          const deptData = this.tree.deptList.find((dept) => dept.deptCode === this.modalData.selectedData.deptCd);
          this.tree.selectedRowkeys = [deptData?.id];
        }
      }
    },
    clearSelection() {
      this.tree.selectedRowkeys = [];
      this.tree.selectedRowsData = [];
    },
    getInstance() {
      return this.$refs.deptList.instance;
    }
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>

</style>