<!--
  PACKAGE_NAME : src/pages/euc/device/hunt
  FILE_NAME : /huntManage.vue
  AUTHOR : jhsim
  DATE : 2024-05-01
  DESCRIPTION : 헌트관리
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="page-sub-box" style="padding: 0">
      <div class="w-full pt-4">
        <DxButton
            @click="onSyncHuntgroup"
            text="헌트 동기화"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
            styling-mode="contained"
            type="default"
        />
        <DxButton
            @click="selectAllList"
            :text="this.$_lang('UC.WORD.CHECK')"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fl"
            styling-mode="contained"
            type="default"
        />
        <DxButton
            :text="this.$_lang('UC.WORD.LINEGROUPDN_SET')"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
            styling-mode="contained"
            type="default"
            @click="onClickListLineGroupDn"
        />
        <DxButton
            :text="this.$_lang('UC.WORD.LIST_LINEGROUP_SET')"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
            styling-mode="contained"
            type="default"
            @click="onClickListLineGroup"
        />
        <DxButton
            :text="this.$_lang('UC.WORD.PILOT_LIST_SET')"
            :height="30"
            class="dx-widget dx-button dx-button-mode-text dx-button-normal dx-button-has-text btn_XS default filled fr"
            styling-mode="contained"
            type="default"
            @click="isOpenModal(true, 'pilotlist')"
        />
      </div>

      <table class="table_form line-bin" cellspacing="5">
        <colgroup>
          <col style="width: 20%"/>
          <col style="width: 20%"/>
          <col style="width: 15%"/>
          <col style="width: 45%"/>
        </colgroup>
        <tr>
          <td style="padding: 0 6px">
            <esp-dx-data-grid
                :data-grid="huntPilotGrid"
                ref="huntPilotGrid"
                @selection-changed="onSelectionChanged($event, 'huntpilot')"
                @reorder="onChangePilotOrd"
            >
            </esp-dx-data-grid>
          </td>
          <td style="padding: 0 6px">
            <esp-dx-data-grid
                :data-grid="huntListGrid"
                ref="huntListGrid"
                @selection-changed="onSelectionChanged($event, 'huntlist')"
            ></esp-dx-data-grid>
          </td>
          <td style="padding: 0 6px">
            <esp-dx-data-grid
                :data-grid="lineGroupGrid"
                ref="lineGroupGrid"
                @selection-changed="onSelectionChanged($event, 'linegroup')"
                @reorder="onChangeLineOrd"
            ></esp-dx-data-grid>
          </td>
          <td style="padding: 0 6px">
            <esp-dx-data-grid
                :data-grid="lineGroupDnGrid"
                ref="lineGroupDnGrid"
                @selection-changed="onSelectionChanged($event, 'linegroupDn')"
                @reorder="onChangeLineDnOrd"
            ></esp-dx-data-grid>
          </td>
        </tr>
      </table>
      <DxPopup
          :show-title="true"
          :title="modal.pilotlist.title"
          :width="modal.pilotlist.width"
          :height="modal.pilotlist.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :hide-on-outside-click="false"
          :visible="modal.pilotlist.isOpened"
          @hiding="isOpenModal(false, 'pilotlist')"
      >
        <ModalAddPilotList
            :cmList="this.cmList"
            :partitionList="this.partitionList"
            :callManagerList="this.callManagerList"
            :isOpened="this.modal.pilotlist.isOpened"
            @closeModal="closeModal('pilotlist')"
        ></ModalAddPilotList>
      </DxPopup>
      <DxPopup
          :show-title="true"
          :title="modal.linegroup.title"
          :width="modal.linegroup.width"
          :height="modal.linegroup.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :hide-on-outside-click="false"
          :visible="modal.linegroup.isOpened"
          @hiding="isOpenModal(false, 'linegroup')"
      >
        <ModalAddLineGroup
            :cmList="this.cmList"
            :isOpened="this.modal.linegroup.isOpened"
            @closeModal="closeModal('linegroup')"
        ></ModalAddLineGroup>
      </DxPopup>
      <DxPopup
          :show-title="true"
          :title="modal.listline.title"
          :width="modal.listline.width"
          :height="modal.listline.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :hide-on-outside-click="false"
          :visible="modal.listline.isOpened"
          @hiding="isOpenModal(false, 'listline')"
      >
        <ModalSetListLineGroup
            :cmList="this.cmList"
            :rowInfo="this.rowInfo"
            :isOpened="this.modal.listline.isOpened"
            @closeModal="closeModal('listline')"
        ></ModalSetListLineGroup>
      </DxPopup>
      <DxPopup
          :show-title="true"
          :title="modal.linegroupDn.title"
          :width="modal.linegroupDn.width"
          :height="modal.linegroupDn.height"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="true"
          :hide-on-outside-click="false"
          :visible="modal.linegroupDn.isOpened"
          @hiding="isOpenModal(false, 'linegroupDn')"
      >
        <ModalSetLineDn
            :cmList="this.cmList"
            :pickupGroupList="this.pickupGroupList"
            :rowInfo="this.rowInfo"
            :lineGroupId="this.rowInfo.linegroup.id"
            :isOpened="this.modal.linegroupDn.isOpened"
            @closeModal="closeModal('linegroupDn')"
        ></ModalSetLineDn>
      </DxPopup>
    </div>
  </div>
</template>
<!-- toolbar -->
<script>
import {DxButton} from 'devextreme-vue/button';
import {DxPopup} from 'devextreme-vue/popup';
import {isSuccess} from '@/utils/common-lib';
import ModalAddPilotList from './modal-add-pilotlist.vue';
import ModalAddLineGroup from './modal-add-linegroup.vue';
import ModalSetListLineGroup from './modal-set-listlinegroup.vue';
import ModalSetLineDn from './modal-set-linedn.vue';
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  components: {
    EspDxDataGrid,
    DxButton,
    DxPopup,
    ModalAddPilotList,
    ModalAddLineGroup,
    ModalSetListLineGroup,
    ModalSetLineDn,
  },
  props: {
    cmList: {
      type: Array,
    },
  },
  data() {
    return {
      dragData: {
        huntpilot: [],
        linegroup: [],
        linegroupDn: [],
      },
      flag: {
        huntpilot: false,
        linegroup: false,
        linegroupDn: false,
      },
      lineGroupId: '0',
      contentReady: false,
      modal: {
        pilotlist: {
          isOpened: false,
          title: this.$_lang('UC.WORD.PILOT_LIST_SET'),
          width: '70%',
        },
        listline: {
          isOpened: false,
          title: this.$_lang('UC.WORD.LIST_LINEGROUP_SET'),
          width: '70%',
        },
        linegroup: {
          isOpened: false,
          // title: '회선그룹 추가',
          title: this.$_lang('UC.WORD.LINEGROUP_ADD'),
          width: '40%',
        },
        linegroupDn: {
          isOpened: false,
          // title: '회선번호 설정',
          title: this.$_lang('UC.WORD.LINEGROUPDN_SET'),
          width: '50%',
        },
      },
      partitionList: [],
      pickupGroupList: [],
      callManagerList: [],
      rowInfo: {
        pilot: {},
        list: {},
        linegroup: {},
        linegroupDn: [],
      },
      stylingMode: 'outlined',
      huntPilotGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'huntPilotGrid',
        toolbarOptions: {
          visible: true, // 툴바 노출 여부
          title: this.$_lang('UC.WORD.HUNTPILOT'), // 툴바 영역 타이틀 / 타이틀 설정할 경우 툴바 버튼 비노출
        },
        dataSourceDefaultSortColumn: '+huntpilotOrd',
        apiActionNm: {
          select: 'EUC_HUNTPILOT_SELECT',
        },
        dragging: {
          sortColumn: 'huntpilotOrd',
          allowReordering: true,
          dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
        },
        page: {
          enabled: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        columns: [
          {
            caption: '패턴',
            i18n: 'UC.WORD.PATTERN',
            dataField: 'pattern',
            allowEditing: false,
          },
          {
            caption: '설명',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            allowEditing: false,
          },
        ],
      },
      huntListGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'huntListGrid',
        toolbarOptions: {
          visible: true,
          title: this.$_lang('UC.WORD.HUNTLIST'),
        },
        dataSourceDefaultSortColumn: '+huntlistOrd',
        apiActionNm: {
          select: 'EUC_HUNTLIST_LIST_ALL',
        },
        searchParams: {
          huntpilotId: 0
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        page: {
          enabled: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        columns: [
          {
            caption: '이름',
            i18n: 'COMPONENTS.NAME',
            dataField: 'name',
            allowEditing: false,
          },
          {
            caption: '설명',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            allowEditing: false,
          },
        ],
      },
      lineGroupGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'lineGroupGrid',
        toolbarOptions: {
          visible: true,
          title: this.$_lang('UC.WORD.LINEGROUP'),
        },
        selectedRowCnt: 0,
        totalCount: 0,
        apiActionNm: {
          select: 'EUC_LINE_GROUP_SELECT',
        },
        searchParams: {
          huntlistId: 0
        },
        dragging: {
          sortColumn: 'selectionorder',
          allowReordering: true,
          dropFeedbackMode: 'push', // 설정하면 dragging 할때 기존리스트가 아래로 움직이는 효과
        },
        page: {
          enabled: false,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '이름',
            i18n: 'COMPONENTS.NAME',
            dataField: 'name',
            allowEditing: false,
          },
        ],
      },
      lineGroupDnGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'lineGroupDnGrid',
        toolbarOptions: {
          visible: true,
          title: this.$_lang('UC.WORD.LINEGROUPDN'),
        },
        dataSourceDefaultSortColumn: '+lineselectionorder',
        apiActionNm: {
          select: 'EUC_LINE_GROUP_DN_SELECT',
        },
        searchParams: {
          linegroupId: 0
        },
        dragging: {
          sortColumn: 'lineselectionorder',
          allowReordering: true,
          dropFeedbackMode: 'push',
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '패턴',
            i18n: 'UC.WORD.PATTERN',
            dataField: 'pattern',
            allowEditing: false,
          },
          {
            caption: '파티션',
            i18n: 'UC.WORD.PARTITION',
            dataField: 'routepartitionname',
            allowEditing: false,
          },
          {
            caption: '전체리스트',
            i18n: 'UC.WORD.ALL_LIST',
            dataField: 'list',
            allowEditing: false,
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 헌트 파일럿 리스트 조회 */
    async selectHuntPilot(sort = '+huntpilotOrd') {
      this.huntPilotGrid.dataSource = new CustomStore({
        key: 'id',
        load: async loadOptions => {
          const params = this.$_getDxDataGridParam(loadOptions);
          if (!params.sort) {
            params.sort = sort;
          }

          if (params?.currentpage) {
            delete params['currentpage'];
          }
          const payload = {
            actionName: 'EUC_HUNTPILOT_SELECT',
            data: params,
            loading: false,
          };

          const rtnData = {
            data: [],
            totalCount: 0,
          };

          const res = await this.CALL_EUC_API(payload);
          if (isSuccess(res)) {
            this.flag.huntpilot = typeof params.pattern != 'undefined' || typeof params.description != 'undefined';

            rtnData.data = res.data.data;
            rtnData.totalCount = res.data.header.totalCount;
            // 드래깅 데이터 초기화
            this.dragData.huntpilot = [];
            await this.$refs.huntPilotGrid.clearSelection();
            await this.repaintAllGrid();
          }
          return rtnData;
        },
      });
    },
    /** @description : 헌트리스트 리스트 조회 */
    async selectHuntList(pilotId) {
      this.$set(this.huntListGrid.searchParams, 'huntpilotId', pilotId);
      this.$refs.huntListGrid.refreshData();
    },
    /** @description : 회선그룹 리스트 조회 */
    async selectLineGroup(huntListId) {
      this.$set(this.lineGroupGrid.searchParams, 'huntlistId', huntListId);
      this.$refs.lineGroupGrid.refreshData();
    },
    /** @description : 회선번호 리스트 조회 */
    async selectLineGroupDn(lineGroupId) {
      this.$set(this.lineGroupDnGrid.searchParams, 'linegroupId', lineGroupId);
      this.$refs.lineGroupDnGrid.refreshData();
    },
    /**@description 헌트관련 데이터(헌트파일럿 , 헌트리스트 , 라인그룹 , 라인넘버) 동기화 */
    async onSyncHuntgroup() {
      const confirm = this.$_lang('UC.MESSAGE.START_SYNC');
      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      const requests = this.cmList.map(async (item) => {
        const payload = {
          actionName: 'EUC_HUNTGROUP_SYNC',
          data: {
            cmNm: item.cmNm,
            loginId: this.$store.getters.getLoginId,
            type: 'RELATED'
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (!isSuccess(res)) {
          const errorMessage = res.data.data?.length
              ? `교환기 : ${item.cmNm} ${res.data.header.resMsg}`
              : res.data.header.resMsg;
          await this.$_Msg(errorMessage);
        }
        return res;
      });

      const results = await Promise.all(requests);
      if (results.some(isSuccess)) {
        await this.selectHuntPilot();
      }
    },
    /**@description      그리드 selectRow 이벤트 함수
     * @param e
     * @param type
     */
    onSelectionChanged(e, type) {
      switch (type) {
        case 'huntpilot':
          this.huntPilotGrid.selectedRowCnt = e.selectedRowsData.length;
          if (!this.huntPilotGrid?.selectedRowCnt) {
            this.resetDataGrid('huntpilot');
          } else {
            if (typeof e.selectedRowKeys[0] === 'number') {
              this.selectHuntList(e.selectedRowKeys);
            } else {
              const selectRowKeys = e.selectedRowsData.map(item => item.id);
              this.selectHuntList(selectRowKeys);
            }
          }
          break;
        case 'huntlist':
          this.huntListGrid.selectedRowCnt = e.selectedRowsData.length;
          if (!this.huntListGrid?.selectedRowCnt) {
            this.resetDataGrid('huntlist');
          } else {
            if (typeof e.selectedRowKeys[0] === 'number') {
              this.selectLineGroup(e.selectedRowKeys);
            } else {
              const selectRowKeys = e.selectedRowsData.map(item => item.id);
              this.selectLineGroup(selectRowKeys);
            }
          }
          break;
        case 'linegroup':
          this.lineGroupGrid.selectedRowCnt = e.selectedRowsData.length;
          if (!this.lineGroupGrid?.selectedRowCnt) {
            this.resetDataGrid('linegroup');
          } else {
            if (typeof e.selectedRowKeys[0] === 'number') {
              this.selectLineGroupDn(e.selectedRowKeys);
            } else {
              const selectRowKeys = e.selectedRowsData.map(item => item.id);
              this.selectLineGroupDn(selectRowKeys);
            }
          }
          break;
        case 'linegroupDn':
          this.lineGroupDnGrid.selectedRowCnt = e.selectedRowsData.length;
          break;
        default:
          break;
      }
      this.repaintAllGrid();
    },

    /** @description  그리드 순서 변경 이벤트
     * @param e
     * @param type
     */
    onReorder(e, type) {
      if (type === 'huntpilot') {
        const data = this.dragData.huntpilot?.length ? this.dragData.huntpilot : this.getGridDataSource('huntPilotGrid');
        const visibleRows = e.component.getVisibleRows();
        const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
        const fromIndex = data.findIndex(item => item.id === e.itemData.id);

        const newDataSource = [...data];
        newDataSource.splice(fromIndex, 1);
        newDataSource.splice(toIndex, 0, e.itemData);

        if (fromIndex < toIndex) {
          // 아래로 이동할 때
          for (let i = fromIndex; i <= toIndex; i++) {
            newDataSource[i].huntpilotOrd = i + 1;
          }
        } else {
          // 위로 이동할 때
          for (let i = toIndex; i <= fromIndex; i++) {
            newDataSource[i].huntpilotOrd = i + 1;
          }
        }
        // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
        newDataSource.forEach((item, index) => {
          item.huntpilotOrd = index + 1;
        });
        this.dragData.huntpilot = newDataSource;
        this.huntPilotGrid.dataSource = newDataSource;
      }
    },
    /** @description   refs 별 그리드데이터 리턴하는 함수
     *  @param {String}  refs 이름
     *  @returns {Array} 그리드 dataSource
     */
    getGridDataSource(refs) {
      return this.$refs[refs].getItems;
    },
    /**@description      헌트 파일럿 그리드 순서 변경 이벤트
     * @param e
     */
    onChangePilotOrd(e) {
      const data = this.dragData.huntpilot?.length ? this.dragData.huntpilot : this.getGridDataSource('huntPilotGrid');
      const visibleRows = e.component.getVisibleRows();
      const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
      const fromIndex = data.findIndex(item => item.id === e.itemData.id);

      const newDataSource = [...data];
      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      if (fromIndex < toIndex) {
        // 아래로 이동할 때
        for (let i = fromIndex; i <= toIndex; i++) {
          newDataSource[i].huntpilotOrd = i + 1;
        }
      } else {
        // 위로 이동할 때
        for (let i = toIndex; i <= fromIndex; i++) {
          newDataSource[i].huntpilotOrd = i + 1;
        }
      }

      // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
      newDataSource.forEach((item, index) => {
        item.huntpilotOrd = index + 1;
      });
      this.dragData.huntpilot = newDataSource;
      this.huntPilotGrid.dataSource = newDataSource;
    },
    /**@description      회선그룹 그리드 순서 변경 이벤트
     * @param e
     */
    onChangeLineOrd(e) {
      const selectRows = this.$refs.huntListGrid.getInstance.getSelectedRowsData();
      if (selectRows?.length === 0) {
        this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTLIST'));
        return;
      } else if (selectRows.length > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        return;
      }

      const data = this.dragData.linegroup?.length ? this.dragData.linegroup : this.getGridDataSource('lineGroupGrid');
      const visibleRows = e.component.getVisibleRows();
      const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
      const fromIndex = data.findIndex(item => item.id === e.itemData.id);

      const newDataSource = [...data];
      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);
      if (fromIndex < toIndex) {
        // 아래로 이동할 때
        for (let i = fromIndex; i <= toIndex; i++) {
          newDataSource[i].selectionorder = i + 1;
        }
      } else {
        // 위로 이동할 때
        for (let i = toIndex; i <= fromIndex; i++) {
          newDataSource[i].selectionorder = i + 1;
        }
      }

      // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
      newDataSource.forEach((item, index) => {
        item.selectionorder = index + 1;
      });
      this.dragData.linegroup = newDataSource;
      this.lineGroupGrid.dataSource = newDataSource;
    },

    /**@description      회선번호 그리드 순서 변경 이벤트
     * @param e
     */
    onChangeLineDnOrd(e) {
      const selectRows = this.$refs.lineGroupGrid.getInstance.getSelectedRowsData();
      if (selectRows?.length === 0) {
        this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_LINEGROUP'));
        return;
      } else if (selectRows.length > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        return;
      }

      const data = this.dragData.linegroupDn?.length ? this.dragData.linegroupDn : this.getGridDataSource('lineGroupDnGrid');
      const visibleRows = e.component.getVisibleRows();
      const toIndex = data.findIndex(item => item.id === visibleRows[e.toIndex].data.id);
      const fromIndex = data.findIndex(item => item.id === e.itemData.id);

      const newDataSource = [...data];
      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      if (fromIndex < toIndex) {
        // 아래로 이동할 때
        for (let i = fromIndex; i <= toIndex; i++) {
          newDataSource[i].lineselectionorder = i + 1;
        }
      } else {
        // 위로 이동할 때
        for (let i = toIndex; i <= fromIndex; i++) {
          newDataSource[i].lineselectionorder = i + 1;
        }
      }

      // 최종적으로 전체 배열의 ord 값을 순서대로 재설정
      newDataSource.forEach((item, index) => {
        item.lineselectionorder = index + 1;
      });
      this.dragData.linegroupDn = newDataSource;
      this.lineGroupDnGrid.dataSource = newDataSource;
    },
    /**
     * @description  리스트/회선그룹 설정 버튼 클릭 이벤트 ,  파일럿 , 리스트 1건씩 선택 후 실행 가능.
     */
    async onClickListLineGroup() {
      const selectPilotRows = this.$refs.huntPilotGrid.getInstance.getSelectedRowsData();
      const selectListRows = this.$refs.huntListGrid.getInstance.getSelectedRowsData();
      if (!selectPilotRows?.length) {
        this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTPILOT'));
        return;
      } else if (selectPilotRows?.length > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        return;
      }

      if (!selectListRows?.length) {
        this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_HUNTLIST'));
        return;
      } else if (selectListRows?.length > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        return;
      }

      if (selectListRows?.length === 1 && selectPilotRows?.length === 1) {
        this.rowInfo.pilot = selectPilotRows;
        this.rowInfo.list = selectListRows;
        this.isOpenModal(true, 'listline');
      }
    },
    /**
     * @description  회선번호 설정 버튼 클릭 이벤트 ,  회선번호 1건 선택 후 실행 가능.
     */
    async onClickListLineGroupDn() {
      const selectLineGroupRows = this.$refs.lineGroupGrid.getInstance.getSelectedRowsData();
      const selectHuntPilotRows = this.$refs.huntPilotGrid.getInstance.getSelectedRowsData();
      if (!selectLineGroupRows?.length) {
        this.$_Msg(this.$_lang('UC.MESSAGE.REQUIRED_SELECT_LINEGROUP'));
        return;
      } else if (selectLineGroupRows?.length > 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT'));
        return;
      }

      if (selectLineGroupRows?.length === 1) {
        const lineGroupDnRows = this.$refs.lineGroupDnGrid.getItems;
        this.rowInfo.pilot = selectHuntPilotRows[0];
        this.rowInfo.linegroup = selectLineGroupRows[0];
        this.rowInfo.linegroupDn = lineGroupDnRows;
        this.isOpenModal(true, 'linegroupDn');
      }
    },
    /**@description      모달 on of 스위치
     * @param data
     * @param type
     */
    isOpenModal(data, type) {
      this.modal[type].isOpened = data;
    },
    /**@description      모달 on of 스위치
     * @param type       모달 창 종류 hunt , linegroup , linegroupdn
     */
    async closeModal(type) {
      switch (type) {
        case 'pilotlist': // 파일럿/리스트 추가 모달 종료
          this.isOpenModal(false, 'pilotlist');
          break;
        case 'listline': // 리스트/회선그룹 설정 모달 종료
          this.isOpenModal(false, 'listline');
          this.rowInfo.pilot = {};
          this.rowInfo.list = {};
          break;
        case 'linegroup': // 회선그룹 추가 모달 종료
          this.isOpenModal(false, 'linegroup');
          break;
        case 'linegroupDn': //회선번호 추가 모달 종료
          this.isOpenModal(false, 'linegroupDn');
          this.rowInfo.linegroup = {};
          this.rowInfo.pilot = {};
          break;
        default:
          break;
      }
    },
    /**@description 파티션 목록 가져오는 메서드 */
    async selectPartitionList(sort = 'regDt,id') {
      const payload = {
        actionName: 'EUC_PARTITION_SELECT',
        data: {
          sort,
          viewFl: 'Y',
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.partitionList = res.data.data || [];
      }
    },
    async selectPickupGroupList(sort = 'regDt,id') {
      const payload = {
        actionName: 'EUC_PICKUPGROUP_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.pickupGroupList = res.data.data || [];
      }
    },
    /**@description 하위 그리드 초기화 dataSource , selectedRowCnt , totalCnt
     * @param type
     */
    resetDataGrid(type) {
      const resetMap = {
        huntpilot: ['huntListGrid', 'lineGroupGrid', 'lineGroupDnGrid'],
        huntlist: ['lineGroupGrid', 'lineGroupDnGrid'],
        linegroup: ['lineGroupDnGrid'],
      };

      (resetMap[type] || []).forEach(grid => {
        this.$set(this[grid], 'dataSource', []);
      });
    },
    /**@description 전체 조회 */
    async selectAllList() {
      this.huntListGrid.dataSource = [];
      this.lineGroupGrid.dataSource = [];
      this.lineGroupDnGrid.dataSource = [];
      this.dragData.huntpilot = [];
      this.dragData.linegroup = [];
      this.dragData.linegroupDn = [];
      await this.$refs.huntPilotGrid.clearFilter();
      await this.$refs.huntListGrid.clearFilter();
      await this.$refs.lineGroupGrid.clearFilter();
      await this.$refs.lineGroupDnGrid.clearFilter();
      await this.selectHuntPilot();
    },
    /**@description 그리드 전체 repaint */
    async repaintAllGrid() {
      this.$refs.huntPilotGrid.repaintData();
      this.$refs.huntListGrid.repaintData();
      this.$refs.lineGroupGrid.repaintData();
    },
    /**@description IPT CODE 가져오는 메서드 */
    async selectIptCode(sort = '+codeOrd') {
      const payload = {
        actionName: 'EUC_CODE_SELECT',
        data: {
          sort,
          codeKey: 'callmanagergroup',
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.callManagerList = res.data.data.filter(item => item.parentId !== null);
      }
    },
    /** @description : 라이프사이클 mounted시 호출되는 메서드 */
    async mountData() {
      await this.selectPartitionList();
      await this.selectPickupGroupList();
      await this.selectIptCode();
      await this.selectHuntPilot();
    },
  },
  mounted() {
    this.mountData();
  },
};
</script>
<style scoped>
.sub_new_style01 .page_search_box .inner div {
  display: inline-block;
}

.table_form tbody td {
  border: 1px solid #ddd;
  /* padding: 10px 20px; */
}

.table_form tbody th {
  border: 1px solid #ddd;
  /* padding: 10px 20px; */
}
</style>
