<!--
  PACKAGE_NAME : src/pages/esp/monitoring/redis
  FILE_NAME : modal-detail-data.vue
  AUTHOR : jhcho
  DATE : 25. 2. 12.
  DESCRIPTION :
-->
<template>
  <div class="w-full h-full">
    <CodemirrorEditor
        v-if="codeValue"
        beautyType="js"
        :value="codeValue"
        :readOnly="true"
    />
  </div>
</template>
<script>
import CodemirrorEditor from "@/components/codemirror/codemirror-editor.vue";
export default {
  props: {
    contentData: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  components: {
    CodemirrorEditor
  },
  data() {
    return {
      codeValue: null,
    }
  },
  watch: {
    contentData: {
      handler(val) {
        if (val) {
          if(typeof val === 'string') {
            this.codeValue = val;
          } else {
            this.codeValue = JSON.stringify(val);
          }
        } else {
          this.codeValue = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>