import Mcid from '@/pages/euc/device/log/mcid.vue';

export default function LogRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/log/mcid',
            name: 'Mcid',
            components: { default: Mcid, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}