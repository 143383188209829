import EducationImplementationSchedule from '@/pages/ewm/education/implementation/schedule/index.vue';
import EducationImplementationStatus from '@/pages/ewm/education/implementation/status/index.vue';
import EducationReportCompletion from '@/pages/ewm/education/report/completion/index.vue';
import EducationSettingSubject from '@/pages/ewm/education/setting/subject/index.vue';

export default function EducationRoutes(commonComponents) {
  return [
    {
      path: '/ewm/education/implementation/schedule',
      name: 'EducationImplementationSchedule',
      components: { default: EducationImplementationSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/education/implementation/status',
      name: 'EducationImplementationStatus',
      components: { default: EducationImplementationStatus, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/education/report/completion',
      name: 'EducationReportCompletion',
      components: { default: EducationReportCompletion, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/education/setting/subject',
      name: 'EducationSettingSubject',
      components: { default: EducationSettingSubject, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}