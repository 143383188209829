<!--
  PACKAGE_NAME : src/pages/esp/monitoring/redis
  FILE_NAME : redis-items.vue
  AUTHOR : jhcho
  DATE : 25. 2. 12.
  DESCRIPTION :
-->
<template>
  <div class="w-full h-full">
    <table class="table_form line-bin">
      <colgroup>
        <col style="width:auto;"/>
      </colgroup>
      <thead class="sub_title_txt">
      <tr>
        <td class="flex items-center">
          <h2 class="pr-4" style="height: 30px">데이터 목록</h2>
          <DxSelectBox
              v-model="selectKey"
              placeholder="Key 선택"
              :items="getKeys"
              display-expr="key"
              value-expr="key"
              :styling-mode="stylingMode"
              :width="120"
              :height="30"
          />
          <DxButton
              text="조회"
              type="normal"
              :styling-mode="stylingMode"
              class="btn_XS default filled txt_S medium ml-4"
              @click="selectRedisItems"
          />
        </td>
      </tr>
      </thead>
      <tbody>
      <tr>
        <esp-dx-data-grid :data-grid="dataGrid" ref="redisGrid"/>
      </tr>
      </tbody>
    </table>

    <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              :is="modal.currentComponent"
              :contentData="modal.contentData"
              v-model="modal.contentData">
          </component>
        </div>
      </template>
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: '닫기',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
      />
    </DxPopup>
  </div>
</template>
<script>
import {isSuccess} from "@/utils/common-lib";
import {DxSelectBox} from "devextreme-vue/select-box";
import DxButton from "devextreme-vue/button";
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";
import ModalDetailData from "./modal-detail-data.vue";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

import Columns from './columns';

export default {
  components: {
    DxToolbarItem,
    DxPopup,
    DxButton,
    DxSelectBox,
    ModalDetailData,
    EspDxDataGrid
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      keys: [],
      selectKey: null,
      items: [],
      stylingMode: 'outlined',
      dataGrid: {
        initialDataSource: false,
        refName: 'redisGrid',
        apiActionNm: {},
        searchParams: {},
        showActionButtons: {
          delete: false,
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [],
      },
    };
  },
  watch: {},
  computed: {
    getKeys() {
      return this.keys;
    },
  },
  methods: {
    onOpenModal(componentNm, componentInitData, contentData) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = contentData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      if (!bool) {
        this.modal.contentData = null;
      }
      this.modal.isOpened = bool;
    },
    getColumns() {
      switch (this.selectKey) {
        case 'user':
          return Columns.UserColumns(this);
        default:
          return [];
      }
    },
    async selectRedisItems() {
      if (this.selectKey === null) {
        this.$_Msg('Key를 선택해주세요.', {icon: 'warning'});
        return;
      }

      const columns = this.getColumns();
      if (!columns?.length) {
        this.$_Msg('준비중입니다.')
        return;
      }
      this.$set(this.dataGrid, 'columns', columns);

      const payload = {
        actionName: 'REDIS_ITEMS_SELECT',
        data: {
          key: this.selectKey,
        },
        loading: false,
      }

      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.$set(this.dataGrid, 'dataSource', res.data.data?.[0]?.values);
      }
    },
    async selectRedisKeys() {
      const payload = {
        actionName: 'REDIS_KEYS_SELECT',
        data: {},
        loading: true,
      }
      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.keys = res.data.data;
      }
    },
  },
  mounted() {
    this.selectRedisKeys();
  },
}
</script>