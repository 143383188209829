<!--
  PACKAGE_NAME : src/pages/euc/device/jabber
  FILE_NAME : jabber-list.vue
  AUTHOR : jhcho
  DATE : 25. 2. 10.
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="jabberGrid"/>
  </div>
</template>

<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  props: {
    cmList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    EspDxDataGrid
  },
  data() {
    return {
      modal: {
        isOpened: false,
        rowInfo: {},
        title: 'Smart Copy',
        width: '60%',
        height: '550',
      },
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        excel: {
          title: this.$_lang('UC.WORD.PC_JABBER_MANAGEMENT', {defaultValue: 'PC Jabber 관리'}),
        },
        callApi: 'CALL_EUC_API',
        refName: 'jabberGrid',
        dataSourceDefaultSort: '+regDt,+id',
        apiActionNm: {
          select: 'EUC_JABBER_SELECT',
        },
        showActionButtons: {
          excel: true,
          delete: false,
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            allowEditing: false,
            calculateCellValue: rowData => rowData?.cmNm || '',
          },
          {
            caption: '사번',
            i18n: 'UC.WORD.USER_NO',
            dataField: 'userid',
            allowEditing: false,
          },
          {
            caption: '화면표시',
            i18n: 'UC.WORD.DISPLAY_NAME',
            dataField: 'displayname',
            allowEditing: false,
          },
          {
            caption: '부서',
            i18n: 'UC.WORD.DEPARTMENT',
            dataField: 'department',
            allowEditing: false,
          },
          {
            caption: 'PC JABBER',
            dataField: 'csf',
            alignment: 'center',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: async (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = options.value;
              aTag.addEventListener('click', () => {
                this.$store.commit(
                    'setDetailParams',
                    {
                      deviceData: {
                        cmNm: options.data.cmNm,
                        name: options.data.csf,
                        regDt: ''
                      }
                    });
                this.$router.push({path: '/euc/device/cisco/detail'});
              });
              container.append(aTag);
            },
          },
          {
            caption: '시작일시',
            dataField: 'startDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.startDt),
          },
          {
            caption: '종료일시',
            dataField: 'endDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.endDt),
          },
          {
            caption: '생성일시',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.regDt),
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
};
</script>