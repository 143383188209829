<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\work-config
  FILE_NAME : evaluation-prompt-add
  AUTHOR : hpmoon
  DATE : 2024-09-13
  DESCRIPTION : AI > LLM > LLM Tester > 프로젝트 실험 설정 > 평가 프롬프트 > 새 프롬프트 추가
-->
<template>
  <DxPopup
    :show-title="true"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight"
    :height="modal.height"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :hide-on-outside-click="modal.closeOnOutsideClick"
    :show-close-button="modal.showCloseButton"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
      <div>
        <table class="table_form line-bin">
          <colgroup>
            <col style="width: 130px" />
            <col style="width: auto" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('COMPONENTS.TITLE', {defaultValue: '제목'}) }} <span class="icon_require">필수항목</span></label>
            </th>
            <td>
              <DxTextBox
                v-model="formData.name"
                :max-length="limitNumberTexts.maxLengths.name"
                :styling-mode="config.stylingMode"
                class="mar_ri10"
                :width="600"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxAsyncRule :validation-callback="checkValidName"
                               :message="$_lang('COMMON.MESSAGE.NOT_DUPLICATE_VALUE_IS',
                               { value: $_lang('COMPONENTS.TITLE', { defaultValue: '제목' }), defaultValue: '[제목] 값은 중복될 수 없습니다.' })"
                  />
                  <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                { value: $_lang('COMPONENTS.TITLE', {defaultValue: '제목'}), defaultValue: '[제목] 은/는 필수값 입니다' })"
                  />
                </DxValidator>
              </DxTextBox>
              <span>
              {{
                  limitNumberTexts.textLengths.name
                    ? limitNumberTexts.textLengths.name
                    : formData.name
                      ? formData.name.length
                      : 0
                }}
            </span>/{{ limitNumberTexts.maxLengths.name }}
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.CONTENT', {defaultValue: '내용'}) }} <span class="icon_require">필수항목</span></label>
            </th>
            <td>
              <DxTextArea
                v-model="formData.content"
                :max-length="limitNumberTexts.maxLengths.content"
                :styling-mode="config.stylingMode"
                class="mar_ri10 alB"
                :width="600"
                :height="300"
                @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'content')"
              >
                <DxValidator validation-group="validationGroupName">
                  <DxRequiredRule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: $_lang('LLM_TESTER.WORD.CONTENT', {defaultValue: '내용'}), defaultValue: '[내용] 은/는 필수값 입니다' })" />
                </DxValidator>
              </DxTextArea>
              <span>
              {{
                  limitNumberTexts.textLengths.content
                    ? limitNumberTexts.textLengths.content
                    : formData.content
                      ? formData.content.length
                      : 0
                }}
            </span>/{{ limitNumberTexts.maxLengths.content }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>

    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'default filled txt_S medium' },
            text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
            width: '120',
            height: '40',
            useSubmitBehavior: true,
            validationGroup: 'validationGroupName',
            onClick: saveModal,
          }"
    />
    <DxToolbarItem
      widget="dxButton"
      toolbar="bottom"
      location="center"
      :visible="true"
      :options="{
            elementAttr: { class: 'white filled txt_S medium' },
            text: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
            width: '120',
            height: '40',
            onClick: closeModal,
          }"
    />
  </DxPopup>
</template>

<script>
  import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import DxTextArea from "devextreme-vue/text-area";
  import {DxValidator, DxRequiredRule, DxAsyncRule} from 'devextreme-vue/validator';

  export default {
    components: {
      DxPopup,
      DxToolbarItem,
      DxTextBox,
      DxTextArea,
      DxValidator,
      DxRequiredRule,
      DxAsyncRule,
    },

    props: {
      isOpen: Boolean,
      project_id: String
    },

    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },

    data() {
      return {
        modal: {
          title: this.$_lang('LLM_TESTER.WORD.EVALUATION_PROMPT_ADD', { defaultValue: '새 평가 프롬프트 추가' }),
          minWidth: '900',
          width: '900',
          minHeight: '500',
          height: '500',
          dragEnabled: true,
          resizeEnabled: true,
          showCloseButton: true,
          closeOnOutsideClick: false,
        },
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },
        formData: {
          project_id: null,
          name: null,
          content: null,
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            name: 30,
            content: 2000,
          },
        },
      };
    },

    computed: {
    },

    methods: {
      /** @description 모달 저장 */
      async saveModal(e) {
        if (!e.validationGroup.validate().isValid) {
          e.validationGroup.validate().validators.forEach((validator) => {
            validator.$element().addClass("dx-state-focused");
          });
          return;
        }

        if (!await this.checkValidName(e)) return false;

        let payload = {
          actionName: 'LLM_TESTER_EVALUATION_PROMPT_SAVE',
          data: this.formData,
          loading: true,
        };

        let res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.$emit('saveModal');
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 제목 중복 체크 메서드 */
      async checkValidName(e) {
        let payload = {
          actionName: 'LLM_TESTER_EVALUATION_PROMPT_EXIST',
          data: [{
            project_id: this.formData.project_id,
            name: this.formData.name,
          }],
          loading: false,
        }
        let res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          if (res.data) {
            if (e.validationGroup) e.validationGroup.validate().validators[0].$element().addClass("dx-state-focused");
            if (e.validator) e.validator?.$element().addClass("dx-state-focused");
          }
          return !res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 모달 닫기 */
      closeModal() {
        this.$emit('closeModal');
      },

      createdData() {
        this.formData.project_id = this.project_id;
      },

      /** @description 라이프사이클 mounted 호출되는 메서드 */
      mountedData() {

      },
    },

    created() {
      this.createdData();
    },

    mounted() {
      this.mountedData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-placeholder {
      padding-left: 20px;
    }
    .item-context > div {
      padding-left: 10px;
      float: left;
    }
  }
</style>