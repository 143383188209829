<!--
  PACKAGE_NAME : src\pages\report\ewm
  FILE_NAME : forecasting-report
  AUTHOR : hpmoon
  DATE : 2024-10-25
  DESCRIPTION : 보고서 > 예측 > 예측 조회
-->
<template>
  <div class="page-sub-box sub_new_style01 sub_ui_box1">
    <!-- 검색영역 -->
    <div class="page_search_box line_bottom_1px mar_b0">
      <div class="inner clearfix">
        <table class="line-bin">
          <colgroup>
            <col style="width: 70px" />
            <col style="width: 140px" />
            <col :style="`width: ${config.dateWidth}px`" />
            <col style="width: 70px" />
            <col style="width: 150px" />
            <col style="width: 250px" />
            <col style="width: auto" />
          </colgroup>
          <tbody>
            <tr>
              <th scope="row">
                <label for="label01">{{ $_lang('COMPONENTS.SEARCH_DATE', {defaultValue: '조회 기간'}) }}</label>
              </th>
              <td>
                <DxRadioGroup
                  class="check-type col"
                  :items="config.searchDateType.items"
                  :value="getSearchDateType"
                  :layout="config.searchDateType.layout"
                  display-expr="codeNm"
                  @value-changed="onChangedSearchDateType"
                />
              </td>
              <td>
                <div v-show="isDaySelected" class="ui-datepicker period fl mar_ri10">
                  <div class="ui-datepicker-item">
                    <DxDateBox
                      :edit-enabled="false"
                      :styling-mode="config.stylingMode"
                      :width="120"
                      v-model="startDate"
                      type="date"
                      display-format="yyyy-MM-dd"
                      :dateOutOfRangeMessage="$_lang('COMMON.MESSAGE.SEARCH_DATE_RANGE_ERROR', {defaultValue: '종료일은 시작일보다 크거나 같아야 합니다.'})"
                      :height="34"
                    >
                    </DxDateBox>
                  </div>
                  <div class="dash">~</div>
                  <div class="ui-datepicker-item">
                    <DxDateBox
                      :styling-mode="config.stylingMode"
                      :width="120"
                      v-model="endDate"
                      type="date"
                      display-format="yyyy-MM-dd"
                      :dateOutOfRangeMessage="$_lang('COMMON.MESSAGE.SEARCH_DATE_RANGE_ERROR', {defaultValue: '종료일은 시작일보다 크거나 같아야 합니다.'})"
                      :height="34"
                    >
                    </DxDateBox>
                  </div>
                </div>
                <div v-show="!isDaySelected">
                  <div>
                    <DxSelectBox
                      v-model="startYear"
                      :data-source="config.periodYear"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="config.stylingMode"
                      width="90px"
                      :height="30"
                    />
                    <span class="px-1"></span>
                    <DxSelectBox
                      v-model="startMonth"
                      :data-source="config.periodMonth"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="config.stylingMode"
                      width="80px"
                      :height="30"
                    />
                    <span class="px-5">~</span>
                    <DxSelectBox
                      v-model="endYear"
                      :data-source="config.periodYear"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="config.stylingMode"
                      width="90px"
                      :height="30"
                    />
                    <span class="px-1"></span>
                    <DxSelectBox
                      v-model="endMonth"
                      :data-source="config.periodMonth"
                      display-expr="name"
                      value-expr="value"
                      :styling-mode="config.stylingMode"
                      width="80px"
                      :height="30"
                    />
                  </div>
                </div>
              </td>

              <th scope="row">
                <label for="label01">{{ $_lang('COMPONENTS.SEARCH_TARGET', {defaultValue: '조회 대상'}) }}</label>
              </th>
              <td>
                <DxRadioGroup
                  class="check-type col"
                  :items="config.searchGroupType.items"
                  :value="getSearchGroupType"
                  :layout="config.searchGroupType.layout"
                  @value-changed="onChangedSearchGroupType"
                />
              </td>
              <td>
                <DxSelectBox
                  placeholder="선택"
                  :data-source="sklList"
                  display-expr="sklNm"
                  value-expr="id"
                  v-model="searchSkl"
                  :styling-mode="config.stylingMode"
                  :show-clear-button="true"
                  :disabled="config.searchGroupType.value === 0"
                  :width="240"
                  :height="30"
                />
              </td>

              <td>
                <DxButton :text="$_lang('COMPONENTS.SEARCH', {defaultValue: '검색'})" class="btn_M box-btn-search" type="button" :height="34" @click="selectDataList" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- 검색영역 -->

    <!-- 예측 그래프 영역 -->
    <div class="chart-style line_bottom_1px">
      <DxChart
        width="100%"
        id="chart"
        :sticky-hovering="false"
        :data-source="this.dataGrid.dataSource"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings argument-field="dt_date" type="line">
          <DxPoint hover-mode="allArgumentPoints" :size="8" />
        </DxCommonSeriesSettings>
        <DxArgumentAxis :visual-range="initialRange">
          <DxLabel :customize-text="customizeArgumentText" />
        </DxArgumentAxis>
        <DxValueAxis :tick-interval="1000">
          <DxLabel format="fixedPoint" />
        </DxValueAxis>
        <DxSeries value-field="forecasted_offered_calls" :name="$_lang('FORECASTING.WORD.FORECASTED_OFFER', {defaultValue: '예측 콜'})" color="#4e91e0" />
        <DxSeries value-field="actual_offered_calls" :name="$_lang('FORECASTING.WORD.ACTUAL_OFFER', {defaultValue: '실제 콜'})" color="#f4a533" />
        <DxScrollBar :visible="true" position="bottom" />
        <DxZoomAndPan argument-axis="both" />
        <DxLegend
          position="outside"
          vertical-alignment="bottom"
          horizontal-alignment="right"
          :row-item-spacing="1"
          :customizeItems="customizeLegendItems"
        />
        <DxExport :enabled="false" />
        <DxTooltip :enabled="true" :customize-tooltip="customizeTooltip" />
      </DxChart>
    </div>
    <!-- 예측 그래프 영역-->

    <!-- 예측 상세 영역 -->
    <div class="fl">
      <esp-dx-data-grid :data-grid="dataGrid" id="forecastingGrid" ref="forecastingGrid" />
    </div>
    <!-- 예측 상세 영역 -->

    <!-- 이벤트 유형 선택 -->
    <EventTypeSetting
      v-if="modal.eventTypeSetting.visible"
      :is-open="modal.eventTypeSetting.visible"
      :data="modal.eventTypeSetting.data"
      @saveModal="onSaveModal('eventTypeSetting')"
      @closeModal="onCloseModal('eventTypeSetting')"
    />

    <!-- 이벤트 전체 이력 -->
    <EventHistory
      v-if="modal.eventHistory.visible"
      :is-open="modal.eventHistory.visible"
      :data="modal.eventHistory.data"
      @saveModal="onSaveModal('eventHistory')"
      @closeModal="onCloseModal('eventHistory')"
    />
  </div>
</template>

<script>
  import {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxPoint,
    DxLabel,
    DxArgumentAxis,
    DxValueAxis,
    DxScrollBar,
    DxZoomAndPan,
    DxLegend,
    DxExport,
    DxTooltip,
  } from 'devextreme-vue/chart';
  import DxDateBox from 'devextreme-vue/date-box';
  import DxButton from 'devextreme-vue/button';
  import DxRadioGroup from 'devextreme-vue/radio-group';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import EventTypeSetting from "@/pages/report/ewm/event-type-setting.vue";
  import EventHistory from "@/pages/report/ewm/event-history.vue";

  import { formatDate, isEmpty } from "@/utils/common-lib";
  import moment from 'moment/moment';
  import ExcelJS from "exceljs";
  import saveAs from 'file-saver';
  import { exportDataGrid } from "devextreme/excel_exporter";

  export default {
    components: {
      DxChart,
      DxSeries,
      DxCommonSeriesSettings,
      DxPoint,
      DxLabel,
      DxArgumentAxis,
      DxValueAxis,
      DxScrollBar,
      DxZoomAndPan,
      DxLegend,
      DxExport,
      DxTooltip,
      DxDateBox,
      DxButton,
      DxRadioGroup,
      DxSelectBox,
      EspDxDataGrid,
      EventTypeSetting,
      EventHistory,
    },
    data() {
      return {
        config: {
          stylingMode: 'outlined', //outlined, underlined, filled,
          dateWidth: 280,
          periodYear: [],
          periodMonth: [],
          searchDateType: {
            layout: 'horizontal',
            items: this.$_getCode('forecasting_frequency'),
            value: 'daily',
          },
          searchGroupType: {
            layout: 'horizontal',
            items: [
              { id: 0, text: this.$_lang('COMPONENTS.ALL', {defaultValue: '전체'}) },
              { id: 1, text: this.$_lang('FORECASTING.WORD.BY_SKILL', {defaultValue: '스킬별'}) },
            ],
            value: 0,
          },
        },
        dataGrid: {
          refName: 'forecastingGrid',
          excel: {
            title: this.$_lang('FORECASTING.WORD.SEARCHING_FORECAST', {defaultValue: '예측 조회'}), // 엑셀 다운로드 시 파일명
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'500',     // 주석처리시 100%
          // height: '300', // 주석처리시 100%
          apiActionNm: {},
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
          },
          showActionButtons: {
            //툴바 버튼 / 순서(sortIndex) 설정 default : [ 복사: 10, 조회 20, 추가: 30, 저장: 40, 취소: 50, 삭제: 60, 커스텀 버튼: 70번대(71, 72, 73..), 엑셀다운로드: 100 ]
            customButtons: [
              //커스텀 버튼
              {
                widget: 'dxButton',
                sortIndex: 10,
                options: {
                  text: this.$_lang('FORECASTING.WORD.EXCEL', {defaultValue: '엑셀'}),
                  showText: 'always',
                  elementAttr: { id: 'excelButton', class: 'esp-dx-button btn_XS green filled excel' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.onClickExcelDownload();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                sortIndex: 20,
                options: {
                  icon: '',
                  text: this.$_lang('FORECASTING.WORD.ALL_EVENTS_HISTORY', {defaultValue: '이벤트 전체 이력'}),
                  elementAttr: { id: 'eventHistoryButton', class: 'btn_XS white light_filled' },
                  height: 30,
                  onClick: () => {
                    this.onClickEventHistory();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '스킬 그룹',
              i18n: 'FORECASTING.WORD.SKILL_GROUP',
              dataField: 'skill_id',
              // width: 300,
              height: 40,
              alignment: 'center', // left center right
              visible: false,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              lookup: {
                dataSource: [],
                displayExpr: "sklNm",
                valueExpr: "id"
              },
            },
            {
              caption: '날짜',
              i18n: 'COMPONENTS.DATE',
              dataField: 'dt_date',
              // width: 100,
              height: 40,
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              allowSorting: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'left', // left or right
              calculateCellValue: data => this.formatDt(data.dt_date),
            },
            {
              caption: '예측 콜',
              i18n: 'FORECASTING.WORD.FORECASTED_OFFER',
              dataField: 'forecasted_offered_calls',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
              cellTemplate: (container, option) => {
                container.append(parseInt(option.value).toLocaleString('ko-KR'));
              },
            },
            {
              caption: '실제 콜',
              i18n: 'FORECASTING.WORD.ACTUAL_OFFER',
              dataField: 'actual_offered_calls',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
              cellTemplate: (container, option) => {
                if (!isEmpty(option.value)) {
                  container.append(parseInt(option.value).toLocaleString('ko-KR'));
                }
              },
            },
            {
              caption: '예측 근무 시간',
              i18n: 'FORECASTING.WORD.FULL_TIME_EQUIVALENT',
              dataField: 'full_time_equivalent',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowSorting: true,
              cellTemplate: (container, option) => {
                container.append(parseInt(option.value / 3600).toLocaleString('ko-KR'));
              },
            },
            {
              caption: '이벤트 ID',
              dataField: 'event.id',
              height: 40,
              alignment: 'center',
              visible: false,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
            },
            {
              caption: '이벤트 유형',
              i18n: 'FORECASTING.WORD.EVENT_TYPE',
              dataField: 'event.code',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: true,
              lookup: {
                dataSource: [],
                displayExpr: "codeNm",
                valueExpr: "codeValue"
              },
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                let button;

                const displayText = options.column.lookup.calculateCellValue(options.value);

                if (isEmpty(displayText)) {
                  if (!isEmpty(options.data.event?.id ?? null)) {
                    if (options.data.forecasted_offered_calls - options.data.actual_offered_calls > 0){
                      button = new DxButton({
                        propsData: {
                          elementAttr: { class: 'btn-icon white filled pull-down' },
                          value: options.data,
                          onClick: () => {
                            this.onOpenModal('eventTypeSetting', options.data);
                          },
                        },
                      });
                      button.$mount();
                    } else if (options.data.forecasted_offered_calls - options.data.actual_offered_calls < 0){
                      button = new DxButton({
                        propsData: {
                          elementAttr: { class: 'btn-icon white filled pull-up' },
                          value: options.data,
                          onClick: () => {
                            this.onOpenModal('eventTypeSetting', options.data);
                          },
                        },
                      });
                      button.$mount();
                    }
                  } else {
                    aTag.innerText = '-';
                  }
                } else if (options.data.forecasted_offered_calls - options.data.actual_offered_calls > 0) {
                  aTag.innerText = displayText;
                  aTag.setAttribute('style', 'color: blue !important; font-weight: unset;');
                } else if (options.data.forecasted_offered_calls - options.data.actual_offered_calls < 0) {
                  aTag.innerText = displayText;
                  aTag.setAttribute('style', 'color: red !important; font-weight: unset;');
                }

                if (isEmpty(button)) {
                  aTag.addEventListener('click', () => {
                    this.onOpenModal('eventTypeSetting', options.data);
                  });
                  container.append(aTag);
                } else {
                  container.append(button.$el);
                }
              },
            },
          ],
        },
        isDaySelected: true,
        isDaySearched: true,
        // TODO : 데모 시연을 위한 임시 기본 조회 일자 처리
        // startDate: this.currentByFormat('YYYY-MM-DD', -7, 'days'),
        // endDate: this.currentByFormat('YYYY-MM-DD', 15, 'days'),
        // startYear: this.currentByFormat('YYYY', -3, 'months'),
        // startMonth: this.currentByFormat('MM', -3, 'months'),
        // endYear: this.currentByFormat('YYYY', 6, 'months'),
        // endMonth: this.currentByFormat('MM', 6, 'months'),
        startDate: '2024-08-15',
        endDate: '2024-09-13',
        startYear: '2024',
        startMonth: '08',
        endYear: '2024',
        endMonth: '09',
        sklList: [],
        searchSkl: '',
        initialRange: [],
        modal: {
          eventTypeSetting: {
            visible: false,
            data: '',
          },
          eventHistory: {
            visible: false,
          }
        },
      };
    },
    computed: {
      getSearchDateType() {
        return this.config.searchDateType.items.find(d => d.codeValue === this.config.searchDateType.value);
      },
      getSearchDateTypeId() {
        return this.getSearchDateType.codeValue;
      },
      getSearchGroupType() {
        return this.config.searchGroupType.items.find(d => d.id === this.config.searchGroupType.value);
      },
      getSearchGroupTypeId() {
        return this.getSearchGroupType.id;
      },
    },
    methods: {
      /** @description 데이터 조회 메서드 */
      async selectDataList() {
        let start_date;
        let end_date;
        if (this.isDaySelected) {
          start_date = this.startDate;
          end_date = this.endDate;
        } else {
          start_date = this.startYear + '-' + this.startMonth + '-01';
          end_date = this.endYear + '-' + this.endMonth + '-01';
        }

        let dateRange;
        if (this.isDaySelected) {
          dateRange = moment(end_date).diff(moment(start_date), 'days')+1
          if (dateRange < 1) {
            this.$_Msg(this.$_lang('CC.MESSAGE.START_DATE_OUT_OF_RANGE', { defaultValue: '시작일은 종료일보다 작거나 같아야 합니다.' }));
            return;
          } else if (dateRange > 30) {
            this.$_Msg(this.$_lang('FORECASTING.MESSAGE.CHECK_30_DAYS', { defaultValue: '최대 30일 조회만 가능합니다.' }));
            return;
          }
        } else {
          dateRange = moment(end_date).diff(moment(start_date), 'months')+1
          if (dateRange < 1) {
            this.$_Msg(this.$_lang('CC.MESSAGE.START_DATE_OUT_OF_RANGE', { defaultValue: '시작일은 종료일보다 작거나 같아야 합니다.' }));
            return;
          } else if (dateRange > 12) {
            this.$_Msg(this.$_lang('FORECASTING.MESSAGE.CHECK_12_MONTHS', { defaultValue: '최대 12개월 조회만 가능합니다.' }));
            return;
          }
        }

        if (this.config.searchGroupType.value === 1 && isEmpty(this.searchSkl)) {
          this.$_Msg(this.$_lang('FORECASTING.MESSAGE.PLEASE_SELECT_SKILL', { defaultValue: '스킬별 조회시 스킬을 선택하여 주세요.' }));
          return;
        }

        this.isDaySearched = this.isDaySelected;

        const payload = {
          actionName: 'FORECASTING_LIST',
          data: {
            frequency: this.config.searchDateType.value,
            start_date,
            end_date,
            base_data: 'forecast'
          },
          loading: true,
        }

        if (!isEmpty(this.searchSkl)) {
          payload.data.skill_ids = this.searchSkl
        }

        const res = await this.CALL_FORECASTING_API(payload);
        if(res.status === 200) {
          this.dataGrid.dataSource = res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR'));
        }

        // 스킬 그룹 Column visible 처리
        this.dataGrid.columns[0].visible = this.config.searchGroupType.value !== 0;

        // 이벤트 유형 Column visible 처리
        this.dataGrid.columns[6].visible = this.config.searchGroupType.value === 0 && this.isDaySearched;

        if (this.config.searchGroupType.value === 0 && this.isDaySearched) {
          document.getElementById('eventHistoryButton').classList.remove('d-none')
        } else {
          document.getElementById('eventHistoryButton').classList.add('d-none');
        }
      },

      /** @description 조회 기간 라디오 버튼 클릭 이벤트 */
      onChangedSearchDateType(e) {
        this.$set(this.config.searchDateType, 'value', e.value.codeValue);
        if (this.getSearchDateTypeId === 'daily') {
          this.config.dateWidth = 280;
          this.isDaySelected = true;
        } else {
          this.config.dateWidth = 370;
          this.isDaySelected = false;
        }
      },

      /** @description 조회 대상 라디오 버튼 클릭 이벤트 */
      onChangedSearchGroupType(e) {
        this.$set(this.config.searchGroupType, 'value', e.value.id);
        this.searchSkl = '';
      },

      /** @description 엑셀 다운로드 클릭 이벤트 */
      onClickExcelDownload() {
        const gridInstance = this.$refs.forecastingGrid.getInstance
        const title = this.dataGrid.excel.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        const columnsArr = [];
        this.dataGrid.columns.forEach(() => {
          columnsArr.push({ width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30 });
        });
        worksheet.columns = columnsArr;

        const today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: gridInstance,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }

              // 예측 근무 시간 시간 처리
              if (gridCell.column.dataField === 'full_time_equivalent') {
                excelCell.value = Math.floor(excelCell.value / 3600);
              }

              // 이벤트 유형 처리
              if (gridCell.column.dataField === 'event.code') {

                excelCell.value = gridCell.column.lookup.calculateCellValue(gridCell.value);

                if (isEmpty(excelCell.value)) {
                  if (!isEmpty(gridCell.data.event?.id ?? null)) {
                    if (gridCell.data.forecasted_offered_calls - gridCell.data.actual_offered_calls > 0) {
                      excelCell.value = '↓';
                      excelCell.font = {
                        bold: true,
                        color: { argb: 'FF0000FF' }
                      };
                    } else if (gridCell.data.forecasted_offered_calls - gridCell.data.actual_offered_calls < 0) {
                      excelCell.value = '↑';
                      excelCell.font = {
                        bold: true,
                        color: { argb: 'FFFF0000' }
                      };
                    }
                  }
                } else if (gridCell.data.forecasted_offered_calls - gridCell.data.actual_offered_calls > 0) {
                  excelCell.font = {
                    color: { argb: 'FF0000FF' }
                  };
                } else if (gridCell.data.forecasted_offered_calls - gridCell.data.actual_offered_calls < 0) {
                  excelCell.font = {
                    color: { argb: 'FFFF0000' }
                  };
                }
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(() => {
            const fileName = `${title}_${today}.xlsx`;
            workbook.xlsx.writeBuffer().then(buffer => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
            });
            return fileName;
          });
      },

      /** @description 이벤트 이력 클릭 이벤트 */
      onClickEventHistory() {
        this.onOpenModal('eventHistory');
      },

      /**
       * @description 팝업 열기
       * @param modalType 팝업 모달 타입(eventTypeSetting, eventHistory)
       * @param data props data
       * */
      onOpenModal(modalType, data) {
        this.modal[modalType].visible = true;
        if (!isEmpty(data)) {
          this.modal[modalType].data = data;
        }
      },

      /**
       * @description 팝업 저장
       * @param modalType 팝업 모달 타입(eventTypeSetting, eventHistory)
       * */
      onSaveModal(modalType) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        this.selectDataList();
        this.modal[modalType].visible = false;
      },

      /**
       * @description 팝업 닫기
       * @param modalType 팝업 모달 타입(eventTypeSetting, eventHistory)
       * */
      onCloseModal(modalType) {
        this.modal[modalType].visible = false;
      },

      /** @description 날짜 변환 메서드 */
      formatDt(date) {
        let format = 'YYYY-MM-DD';
        if (!this.isDaySearched) {
          format = 'YYYY-MM';
        }
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', format);
      },

      /** @description 현재 날짜 변환 메서드 */
      currentByFormat(format, date = 0, type = "days") {
        if(date > 0)
          return moment().add(date, type).format(format);
        else
          return moment().subtract(Math.abs(date), type).format(format);
      },

      /** @description 날짜 선택 Priod 셋팅 */
      setPriodMonthAndYear() {
        const months = new Array(12).fill().map((v, i) => {
          const month = i + 1;
          const value = month < 10 ? `0${month}` : month.toString();
          return { name: `${month}월`, value };
        });

        const years = [];
        const reportStartDt = this.$_getSystemData('report_start_dt')?.configValue || null;
        const ersInitYear = reportStartDt.substring(0, 4);
        let year = moment().add(1, 'years').format('YYYY');
        while (ersInitYear <= year) {
          years.push({ name: year + '년', value: year + '' });
          year--;
        }
        this.config.periodMonth = months;
        this.config.periodYear = years;
      },

      /** @description 차트 ToolTip 커스텀 */
      customizeTooltip(pointInfo) {
        return {
          html: `
        <div id="forecasting-report-tooltip">
            <div class='forecasting-report-tooltip-header'>${this.formatDt(pointInfo.argumentText)}</div>
            <div class='forecasting-report-tooltip-body'>
                <div class='series-name'><span class='top-series-name'>${pointInfo.seriesName}</span>: </div>
                <div class='value-text'><span class='top-series-value'>${pointInfo.valueText
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></div>
            </div>
        </div>
        `,
        };
      },

      /** @description 차트 Legend 클릭 이벤트 */
      onLegendClick({ target: series }) {
        if (series.isVisible()) {
          series.hide();
        } else {
          series.show();
        }
      },

      /** @description 차트 ArgumentText 커스텀 */
      customizeArgumentText(e) {
        return this.formatDt(e.value);
      },

      /** @description 차트 Legend 커스텀 */
      customizeLegendItems(items) {
        items.forEach(item => {
          item.marker.size = 8;
        });
      },

      /** @description 라이프사이클 created시 호출되는 메서드 */
      async creatData() {
        this.setPriodMonthAndYear();

        // TODO : sklList 데모를 위한 임시 하드코딩. 추후에 실제 스킬 불러와야함
        this.sklList = [
          { id: '국제선 주간/항공권', sklNm: '국제선 주간 항공권' },
          { id: '국내선/한국어', sklNm: '국내선 한국어' },
          { id: '국제선 주간/한국어', sklNm: '국제선 주간 한국어' },
          { id: '국제선 야간/한국어', sklNm: '국제선 야간 한국어' },
          { id: '국제선 주간/IT 서비스', sklNm: '국제선 주간 IT 서비스' },
          { id: '국제선 주간/우수회원', sklNm: '국제선 주간 우수회원' },
          { id: '국제선 야간/항공권', sklNm: '국제선 야간 항공권' },
          { id: '국내선/우수회원', sklNm: '국내선 우수회원' },
          { id: '국제선 야간/우수회원', sklNm: '국제선 야간 우수회원' },
          { id: '국내선/영어', sklNm: '국내선 영어' },
          { id: '국내선/중국어', sklNm: '국내선 중국어' },
          { id: '국내선/일본어', sklNm: '국내선 일본어' },
          { id: '국제선 주간/영어', sklNm: '국제선 주간 영어' },
          { id: '국제선 야간/영어', sklNm: '국제선 야간 영어' },
        ];

        this.dataGrid.columns[0].lookup.dataSource = this.sklList;

        this.dataGrid.columns[6].lookup.dataSource = this.$_getCode('forecasting_event');
      },
    },
    created() {
      this.creatData();
    },
    mounted() {
      this.selectDataList();

    },
  };
</script>

<style>
  .dxc-tooltip:has(#forecasting-report-tooltip) {
    z-index: 3;
  }

  .forecasting-report-tooltip-header {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
  }

  .forecasting-report-tooltip-body {
    width: 130px;
  }

  .forecasting-report-tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 86px;
  }

  .forecasting-report-tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
  }
</style>

<!-- content div min-width 지정을 위해 필요 -->
<style lang="scss" scoped>
  ::v-deep {
    .d-none {
      display: none;
    }
  }

  .page-sub-box.sub_new_style01.sub_ui_box1 {
    padding-bottom: 0 !important;
  }

  .h2-title-style {
    font-size: 18px;
    color: #545454;
    font-weight: 500;
    border-top: 1px solid #ebebeb;
    padding: 20px 0 10px 3px;
  }

  .chart-style {
    padding: 10px 0 0 10px;
  }

  .mar_b0 {
    margin-bottom: 0;
  }

  .px-1 {
    padding: 0 1px;
  }

  .px-5 {
    padding: 0 5px;
  }

  #chart {
    height: 260px;
    margin-bottom: 20px;
  }
</style>
